import moment from 'moment';
import _ from 'lodash';

import api from './api';
import schedulingService from './schedulingService';
import { getOriginApi } from 'services/utils';
import { hydrateAppointments, hydrateAppointment } from './transformers/appointmentTransformers';

class AppointmentsService {
  getCurrentAppointment() {
    return this.currentAppointment();
  }

  currentAppointment() {
    return api.appointments.getCurrentAppointment().then(hydrateAppointment);
  }

  delete(id) {
    return api.appointments.cancelAppointment(id);
  }

  appointments() {
    return api.appointments.getAppointments().then(hydrateAppointments);
  }

  switchToPhone(id) {
    return api.appointments.switchToAppointmentByPhone(id);
  }

  switchModality(id, params) {
    return api.appointments.switchAppointmentModality(id, params);
  }

  indicateInterestInFollowUp(id) {
    return api.appointments.indicateInterestInFollowUp(id);
  }

  getICalCalendarLink(appointmentId) {
    const id = appointmentId || schedulingService.getBookedAppointmentId();
    if (!id) return Promise.reject();
    const accessToken = api.getToken();
    return `${getOriginApi()}/appointments/${id}/ical?_token=${accessToken}`;
  }

  getCalendar(calendar, appointmentId) {
    const id = appointmentId || schedulingService.getBookedAppointmentId();
    if (!id) return Promise.reject();
    const accessToken = api.getToken();
    return api.appointments.getCalendarUrl(id, calendar, accessToken);
  }

  goToWaitingRoom(id) {
    return api.appointments.getWaitingRoomUrl(id).then(data => data.url);
  }

  isChangeable(appointment) {
    return !!(
      !_.isEmpty(appointment) &&
      appointment.latestStatus.status === 'booked' &&
      moment().add(24, 'h') < appointment.mStartTime
    );
  }

  canSwitchToPhone(appointment) {
    return (
      !_.isEmpty(appointment) &&
      appointment.latestStatus.status === 'booked' &&
      !appointment.byPhone
    );
  }

  canSwitchToVideo(appointment) {
    return (
      !_.isEmpty(appointment) && appointment.latestStatus.status === 'booked' && appointment.byPhone
    );
  }

  requestAssistance(appointmentId, message) {
    return api.appointments.requestAssistance(appointmentId, message);
  }

  completeGCNSurvey(appointmentId) {
    return api.appointments.completeGCNSurvey(appointmentId);
  }
}

export default new AppointmentsService();
