import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { getCardImage } from '../../../../services/utils';
import { isNil } from 'lodash';
import './StepsPanel.scss';

class StepItem extends Component {
  static propTypes = {
    numStep: PropTypes.number.isRequired,
    done: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func,
    icon: PropTypes.string,
  };

  render() {
    const { numStep, done, header, active, onClickHandler, icon } = this.props;
    const stepImage = getCardImage(icon);
    return (
      <div
        className={classnames('step-item', { active }, { 'step-done': !active && done })}
        onClick={() => onClickHandler(numStep)}>
        {!isNil(stepImage) ? (
          <div className="step-image">
            <img src={stepImage} alt="" />
            <div className="header">{header}</div>
          </div>
        ) : (
          header
        )}
      </div>
    );
  }
}

export default StepItem;
