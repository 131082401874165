import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import './StepsPanel.scss';

class StepItem extends Component {
  static propTypes = {
    numStep: PropTypes.number.isRequired,
    done: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func,
  };

  render() {
    const { numStep, done, header, active, onClickHandler } = this.props;

    return (
      <div
        className={classnames('step-item', { active }, { 'step-done': !active && done })}
        onClick={() => onClickHandler(numStep)}>
        <div className="step-item-header">{header}</div>
        <div className={classnames({ 'step-done': !active && done })}>
          {!active && done && <i className="ok glyphicon glyphicon-ok" />}
        </div>
      </div>
    );
  }
}

export default StepItem;
