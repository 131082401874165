import modalNames from 'constants/ModalNames';

const SET_MODAL_MODE = 'gm/modals/SET_MODAL_MODE';

export const defaultModals = {
  [modalNames.phoneAppointment]: { open: false },
  [modalNames.videoAppointment]: { open: false },
  [modalNames.appointment]: { open: false },
  [modalNames.cancelAppointment]: { open: false },
  [modalNames.webinarNotAvailable]: { open: false },
  [modalNames.couponInput]: { open: false },
  [modalNames.serviceInfo]: { open: false },
  [modalNames.uploadDocuments]: { open: false },
  [modalNames.videoModal]: { open: false },
  [modalNames.bookAppointmentErrorModal]: { open: false },
  [modalNames.PioneerConfigrationModal]: { open: false },
};

function modalsReducer(state = defaultModals, action = {}) {
  switch (action.type) {
    case SET_MODAL_MODE:
      return {
        ...state,
        [action.payload.name]: { ...state[action.payload.name], ...action.payload.mode },
      };

    default:
      return state;
  }
}

export function setModalMode(name, mode = {}) {
  return { type: SET_MODAL_MODE, payload: { name, mode } };
}

export default modalsReducer;
