import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResultsPage from './ResultsPage/ResultsPage';
import SummaryPage from './SummaryPage/SummaryPage';
import ResponsiveStepsPanel from 'components/widgets/SCP/StepsPanel/ResponsiveStepsPanel';
import { loadDocumentTypes, loadDocuments } from 'ducks/documents';
import { loadAppointments } from 'ducks/appointments';
import gaTrack, { GA_TR_MY_HEALTH_PAGE } from '../../../../../services/gaTrack';
import { removeAction, DOCUMENT_VIEW } from 'ducks/misc/redirectActions';
import documentsService from 'services/api/documentsService';
import _ from 'lodash';
import './MyHealthPage.scss';

const RESULTS_PAGE_INDEX = 1;
const SUMMARY_PAGE_INDEX = 0;

class MyHealthPage extends Component {
  static tabItems = [
    { header: 'Genome Medical Documents', done: false },
    { header: 'Documents Uploaded by You', done: false },
  ];

  state = {
    activeStepIndex: SUMMARY_PAGE_INDEX,
  };

  componentDidMount() {
    const { redirectActions, dispatch } = this.props;
    gaTrack(GA_TR_MY_HEALTH_PAGE);
    this.props.dispatch(loadDocumentTypes());
    this.props.dispatch(loadDocuments());
    this.props.dispatch(loadAppointments());
    if (
      redirectActions.actions[DOCUMENT_VIEW] &&
      redirectActions.actions[DOCUMENT_VIEW] === RESULTS_PAGE_INDEX
    ) {
      dispatch(removeAction(DOCUMENT_VIEW));
      this.setPageIngex(RESULTS_PAGE_INDEX);
    }
  }

  componentDidUpdate(prevProps) {
    const { redirectActions, dispatch } = this.props;
    if (
      redirectActions.actions[DOCUMENT_VIEW] &&
      _.get(this, ['props', 'documents', 'length'], 0) > 0
    ) {
      dispatch(removeAction(DOCUMENT_VIEW));
      this.setPageIngex(SUMMARY_PAGE_INDEX);
    }

    if (
      this.props.loaded &&
      prevProps.loaded !== this.props.loaded &&
      this.isDocumentUnderScanning()
    ) {
      setTimeout(() => {
        this.props.dispatch(loadDocuments());
      }, 500);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(removeAction(DOCUMENT_VIEW));
  }

  isDocumentUnderScanning = () => {
    const documents = [...this.props.documents];

    for (let document of documents) {
      if (_.isNull(document.isMalicious)) return true;
    }

    return false;
  };

  downloadDocument = id => {
    return documentsService.downloadDocument(id);
  };

  getDocumentLink = id => {
    return documentsService.downloadDocument(id);
  };

  setPageIngex = pageIndex => this.setState({ activeStepIndex: pageIndex });

  render() {
    const { appointments, documents, documentTypes, patientDocuments, gmDocuments } = this.props;
    const selectorProps = {
      children: MyHealthPage.tabItems,
      activeStepIndex: this.state.activeStepIndex,
      showAll: true,
      onClickHandler: this.setPageIngex,
    };
    const titles = MyHealthPage.tabItems || [];
    const activeIdx = _.get(this, 'state.activeStepIndex', null) || 0;
    const mobileTitle = _.get(titles, `${activeIdx}.header`, null) || 'Documents';
    return (
      <div className="scp-my-health-page">
        <h2 className="mobile-title">{mobileTitle}</h2>
        <div className="scp-my-health-container full-height">
          <ResponsiveStepsPanel {...selectorProps} />
          <div className="scp-my-health-step-view">
            {this.state.activeStepIndex === RESULTS_PAGE_INDEX && (
              <ResultsPage
                {...this.props}
                documents={patientDocuments}
                downloadDocument={this.downloadDocument.bind(this)}
                getDocumentLink={this.getDocumentLink.bind(this)}
              />
            )}
            {this.state.activeStepIndex === SUMMARY_PAGE_INDEX && (
              <SummaryPage
                appointments={appointments}
                documents={documents}
                documentTypes={documentTypes}
                gmDocuments={gmDocuments}
                downloadDocument={this.downloadDocument.bind(this)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

MyHealthPage.propTypes = {
  appointments: PropTypes.object.isRequired,
  uploadModal: PropTypes.object.isRequired,
  uploadingStatus: PropTypes.object.isRequired,
  frontInsuranceCardStatus: PropTypes.object.isRequired,
  backInsuranceCardStatus: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  removingFiles: PropTypes.array.isRequired,
  documentTypes: PropTypes.array,
};

const mapStateToProps = ({ documents, user, modals, appointments, redirectActions }) => ({
  appointments: appointments,
  me: user.me,
  documentTypes: documents.documentTypes,
  documents: documents.data,
  loaded: documents.loaded,
  removingFiles: documents.removingFiles,
  uploadModal: modals.uploadDocuments,
  uploadingStatus: {
    uploading: documents.uploading,
    uploadingProgress: documents.uploadingProgress,
    uploadingResult: documents.uploadingResult,
    removingFiles: documents.removingFiles,
  },
  frontInsuranceCardStatus: {
    uploading: documents.insuranceCardfrontuploaing,
    uploadingProgress: documents.insuranceCardfrontUploadingProgress,
    uploadingResult: documents.insuranceCardFrontUploadingResult,
  },
  backInsuranceCardStatus: {
    uploading: documents.insuranceCardbackuploaing,
    uploadingProgress: documents.insuranceCardbackUploadingProgress,
    uploadingResult: documents.insuranceCardbackUploadingResult,
  },
  redirectActions,
  patientDocuments: _.filter(documents.data || [], document => {
    return (
      document &&
      document.creator &&
      document.creator.id &&
      user.me &&
      document.creator.id == user.me.id
    );
  }),
});

const mergeProps = (stateProps, dispatchProps) => {
  const {
    appointments,
    me,
    documentTypes,
    documents,
    loaded,
    removingFiles,
    uploadModal,
    uploadingStatus,
    frontInsuranceCardStatus,
    backInsuranceCardStatus,
    redirectActions,
    patientDocuments,
  } = stateProps;
  const { dispatch } = dispatchProps;

  const gmDocuments = _.filter(documents, doc => {
    const docId = doc.id;

    const isPatientDoc = _.find(patientDocuments || [], d => {
      return d.id == docId;
    });

    return !isPatientDoc;
  });

  const sortedPatientDocuments = _.orderBy(
    patientDocuments || [],
    ['createdAt', item => item.type && item.type.name],
    ['desc', 'asc']
  );

  return {
    dispatch,
    appointments,
    me,
    documentTypes,
    documents,
    loaded,
    removingFiles,
    uploadModal,
    uploadingStatus,
    frontInsuranceCardStatus,
    backInsuranceCardStatus,
    redirectActions,
    patientDocuments: sortedPatientDocuments || [],
    gmDocuments,
  };
};

export default connect(mapStateToProps, null, mergeProps)(MyHealthPage);
