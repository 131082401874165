import React, { Component, Fragment } from 'react';

import MailtoLink from 'components/widgets/Link/MailtoLink';
import { CONTACT_PHONE } from 'services/constants';
import './Advisor.scss';

class Advisor extends Component {
  get text() {
    return (
      <div className="advisor-text">
        If you’re unsure or need some help as you answer these questions, please contact us either
        at the chat box below, at <strong>{CONTACT_PHONE}</strong> <MailtoLink />.
      </div>
    );
  }

  get aboutAdvisor() {
    return '';
  }

  get photo() {
    return '';
  }

  get desktopView() {
    return (
      <div className="advisor-container">
        <div className="advisor-content">
          {this.aboutAdvisor}
          {this.text}
        </div>
        {this.photo}
      </div>
    );
  }

  get mobileView() {
    return (
      <div className={'mob-advisor-container'}>
        <div className="advisor">
          {this.photo}
          {this.aboutAdvisor}
        </div>
        {this.text}
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.desktopView}
        {this.mobileView}
        <div className="clearfix" />
      </Fragment>
    );
  }
}

export default Advisor;
