import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './NavigatorFooter.scss';

class NavigatorFooter extends React.Component {
  render() {
    const percentage = _.get(this, ['props', 'zingtree', 'progressPercentage'], null);

    return (
      <footer className="scp-navigator-footer">
        <div className="navigator-footer-content">
          <p className="copyright-text">
            Copyright ©. Genome Medical, Inc. 2020. All rights reserved.
          </p>
        </div>
        {percentage > 0 && (
          <div className="progress-percentage">
            <div style={{ width: `${percentage}%` }} />
          </div>
        )}
      </footer>
    );
  }
}

NavigatorFooter.propTypes = {
  zingtree: PropTypes.object.isRequired,
};

export default connect(({ zingtree }) => ({ zingtree }), null)(NavigatorFooter);
