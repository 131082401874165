import PropTypes from 'prop-types';
import React, { Component,Fragment } from 'react';
import classnames from 'classnames';


import './Toolbar.scss';

class Toolbar extends Component {
  static propTypes = {
    left: PropTypes.node,
    right: PropTypes.node,
  };

  render() {
    const { left } = this.props;
    return (
      <Fragment>
        <div className="location-info">Enter Location Info</div>
      <div className={classnames("calendar-toolbar v2")}>
        <div className="left">{left}</div>
      </div>
      </Fragment>

    );
  }
}

export default Toolbar;
