//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

class SingleSelectButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //bindings
  }
  //functions

  render() {
    const buttonClass = get(this, 'props.canSaveValue', false)
      ? 'singleSelectSave'
      : 'singleSelectSave singleSelectSaveDisabled';
    const containerClass = get(this, 'props.shouldHaveDropShadow', false)
      ? 'singleSelectBottomRowContainer scrollableModalDropShadowBottom'
      : 'singleSelectBottomRowContainer';
    return (
      <div className={containerClass}>
        <div className="singleSelectReset" onClick={this.props.resetInternalValue}>
          Reset
        </div>
        <div className={buttonClass} onClick={this.props.saveValue}>
          Save
        </div>
      </div>
    );
  }
}

export default SingleSelectButtons;
