import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import ModalDialog from '../../ModalDialog';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './InfoModal.scss';

const InfoModal = ({
  className,
  title,
  description,
  footer,
  submitText = 'OK',
  cancelText,
  submitCallback,
  cancelCallback,
}) => {
  return (
    <ModalDialog
      modalHeader={title}
      roleModal="scp-dialog"
      className={classnames('scp-info-modal', 'md850', className)}
      show={true}
      onHide={cancelCallback || submitCallback}>
      <div className="info-body">
        {description ? <div className="info-desc">{description}</div> : <br />}
        <div className="buttons">
          {cancelText && (
            <span className="cancel-button" onClick={cancelCallback}>
              {cancelText}
            </span>
          )}
          <ButtonV2 onClick={submitCallback}>{submitText}</ButtonV2>
          {footer && <div className="info-footer">{footer}</div>}
        </div>
      </div>
    </ModalDialog>
  );
};

InfoModal.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  submitCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  footer: PropTypes.node,
};

export default InfoModal;
