import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import Button from 'components/forms/controls/Button';
import CheckboxGroup from 'components/forms/controls/CheckboxGroup';

class DeclineForm extends Component {
  static caption = 'Please confirm';
  static requiredTrue = value => (value ? undefined : DeclineForm.caption);
  static items = [
    {
      value: 'opt_out',
      label:
        "I no longer wish to participate in my employers' program and do not want to pursue genetic testing.",
    },
  ];
  render() {
    const { handleSubmit, submitting, onCancel } = this.props;

    return (
      <form className="option-form" onSubmit={handleSubmit}>
        <div className="forgot-fields">
          <Field
            name="ethnicBack"
            type="text"
            className="mt40"
            label={DeclineForm.caption}
            component={CheckboxGroup}
            items={DeclineForm.items}
            validate={[DeclineForm.requiredTrue]}
          />
        </div>
        <div className="buttons-container">
          <span className="cancel-btn" onClick={onCancel}>
            Cancel
          </span>
          <Button type="submit" className="submit-btn" disabled={submitting}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

DeclineForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default reduxForm({
  form: 'decline-form',
})(DeclineForm);
