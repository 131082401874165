import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import minimizedLogo from './logo-minimized.png';
import gaTrack, { GA_TR_PATIENT_EDUCATION_PAGE } from 'services/gaTrack';
import EducationList from './EducationList';
import PageTitle from 'components/widgets/PageTitle/PageTitle';
import RBAC from 'components/high-order-components/RBAC/RBAC';
import ResponsiveStepsPanel from 'components/widgets/SCP/StepsPanel/ResponsiveStepsPanel';
import './EducationPage.scss';

const CANCER_SCREENING = 0;
const REPRODUCTIVE_SCREENING = 1;

class EducationPage extends Component {
  static tabItems = [
    { header: 'Cancer Screening', done: false },
    { header: 'Reproductive Screening', done: false },
  ];

  state = {
    stage: CANCER_SCREENING,
  };

  handleStage = stage => {
    this.setState({ stage });
  };

  componentDidMount() {
    gaTrack(GA_TR_PATIENT_EDUCATION_PAGE);
  }

  render() {
    const { stage } = this.state;
    const selectorProps = {
      children: EducationPage.tabItems,
      activeStepIndex: this.state.stage,
      showAll: true,
      onClickHandler: this.handleStage,
    };
    return (
      <div className="education-page-container">
        <PageTitle title="Education" />
        <div className="education-page">
          <ResponsiveStepsPanel {...selectorProps} />
          <ul className="education-header">
            <li
              className={`${stage == CANCER_SCREENING ? 'active' : ''}`}
              onClick={() => this.handleStage(CANCER_SCREENING)}>
              <a href="javascript:;">
                {'Cancer Screening'}
                <div className="line" />
              </a>
            </li>
            <li
              className={`${stage == REPRODUCTIVE_SCREENING ? 'active' : ''}`}
              onClick={() => this.handleStage(REPRODUCTIVE_SCREENING)}>
              <a href="javascript:;">
                {'Reproductive Screening'}
                <div className="line" />
              </a>
            </li>
          </ul>

          {/*Cancer screening*/}
          {stage == CANCER_SCREENING && (
            <EducationList
              //Top left section content
              featuredTitle="Why should I consider genetic screening?"
              featuredDescription="This collection of educational videos can help you better understand many of the important concepts and issues around the option of genetic screening."
              //Top right section content
              featuredVideoImage={require('./cancer/cancer-feature-video.png')}
              featuredVideoURL="https://vimeo.com/475135155/da2922f88b"
              featuredVideoTitle="Introduction: Genetic and inherited causes of cancer"
              featuredVideoDescription="Whether due to a past personal or family history of cancer, you may be interested in learning more about the genetic, or inherited, causes for cancer."
              featuredVideoDate="Last updated Jul 1, 2020"
              featuredVideoDuration="0:09"
              //feature videos
              featuredVideos={[
                {
                  name: 'Genome Medical',
                  title: 'Impact on family members',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/feature1.png'),
                  videoURL: 'https://vimeo.com/475465985/6ca8488d45',
                  videoDuration: '1:29',
                },
                {
                  name: 'Genome Medical',
                  title: 'Medical management',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/feature2.png'),
                  videoURL: 'https://vimeo.com/475543375/9543e40380',
                  videoDuration: '1:32',
                },
              ]}
              //top videos
              topVideos={[
                {
                  title: 'Genome Medical',
                  name: 'Genetics of Cancer',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top1.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475144279/02c551df48',
                  videoDuration: '0:50',
                },
                {
                  title: 'Genome Medical',
                  name: 'Hereditary Cancer',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top2.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475219130/3fb40806d8',
                  videoDuration: '0:52',
                },
                {
                  title: 'Genome Medical',
                  name: 'Familial Cancer',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top3.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475221404/57bec6f178',
                  videoDuration: '0:30',
                },
                {
                  title: 'Genome Medical',
                  name: 'Gene risk categories',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top4.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475226333/216f8f598c',
                  videoDuration: '1:12',
                },
                {
                  title: 'Genome Medical',
                  name: 'Genetic testing concepts',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top5.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475251613/494e24dcd3',
                  videoDuration: '4:23',
                },
                {
                  title: 'Genome Medical',
                  name: 'Medical management',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top6.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475543375/9543e40380',
                  videoDuration: '1:32',
                },
                {
                  title: 'Genome Medical',
                  name: 'Impact on family members',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top7.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475465985/6ca8488d45',
                  videoDuration: '1:29',
                },
                {
                  title: 'Genome Medical',
                  name: 'Insurance issues',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top8.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475312436/babedd4363',
                  videoDuration: '1:08',
                },
                {
                  title: 'Genome Medical',
                  name: 'Family history of prostate cancer',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top9.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475340983/c316bc3974',
                  videoDuration: '0:35',
                },
                {
                  title: 'Genome Medical',
                  name: 'General insurance / Payment Information',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top10.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475457454/14eb78cbc3',
                  videoDuration: '0:29',
                },
                {
                  title: 'Genome Medical',
                  name: 'Known mutation in your family',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top11.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475452669/db20858fd4',
                  videoDuration: '0:42',
                },
                {
                  title: 'Genome Medical',
                  name: 'Understanding your family history',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./cancer/top12.png'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/475460922/112389aa05',
                  videoDuration: '1:48',
                },
              ]}
            />
          )}

          {/*Reproductive screening*/}
          {stage == REPRODUCTIVE_SCREENING && (
            <EducationList
              //Top left section content
              featuredTitle="Why should I consider genetic screening?"
              featuredDescription="This collection of educational videos can help you better understand many of the important concepts and issues around the option of genetic carrier screening."
              //Top right section content
              featuredVideoImage={require('./reproductive/feature-video.png')}
              featuredVideoURL="https://vimeo.com/431622955/e3c6d0d5fc"
              featuredVideoTitle="Carrier screening: An overview"
              featuredVideoDescription="A deeper dive into what it means to be a genetic carrier, the different types of carrier screening, and what results this type of screening can reveal."
              featuredVideoDate="Last updated Jul 1, 2020"
              featuredVideoDuration="0:53"
              //feature videos
              featuredVideos={[
                {
                  name: 'Genome Medical',
                  title: 'General genetics',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./reproductive/feature1.jpg'),
                  videoURL: 'https://vimeo.com/431562536/1ce04af921',
                  videoDuration: '1:27',
                },
                {
                  name: 'Genome Medical',
                  title: 'What is the purpose of genetic testing?',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./reproductive/feature2.jpg'),
                  videoURL: 'https://vimeo.com/431616762/10f357a976',
                  videoDuration: '1:21',
                },
                {
                  name: 'Genome Medical',
                  title: 'Carrier screening: An overview',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./reproductive/top3.jpg'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/431622955/e3c6d0d5fc',
                  videoDuration: '0:53',
                },
              ]}
              //top videos
              topVideos={[
                {
                  title: 'Genome Medical',
                  name: 'General genetics',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./reproductive/top1.jpg'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/431562536/1ce04af921',
                  videoDuration: '1:27',
                },
                {
                  title: 'Genome Medical',
                  name: 'What is the purpose of genetic testing?',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./reproductive/top2.jpg'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/431616762/10f357a976',
                  videoDuration: '1:21',
                },
                {
                  title: 'Genome Medical',
                  name: 'Carrier screening: An overview',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./reproductive/top3.jpg'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/431622955/e3c6d0d5fc',
                  videoDuration: '0:53',
                },
                {
                  title: 'Genome Medical',
                  name: 'Expanded carrier screening',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./reproductive/top4.jpg'),
                  image2: minimizedLogo,
                  videoURL: 'https://vimeo.com/431628192/19a2914406',
                  videoDuration: '1:23',
                },
                {
                  title: 'Erynn Gordon, MS, CGC',
                  name: 'What is genetic counseling?',
                  date: 'Last updated Jul 1, 2020',
                  image: require('./reproductive/top5.jpg'),
                  image2: require('./erynn-gordon.jpg'),
                  videoURL: 'https://vimeo.com/423824007/385d752906 ',
                  videoDuration: '2:17',
                },
              ]}
            />
          )}
        </div>
      </div>
    );
  }
}

EducationPage.propTypes = {};

export default RBAC(['scp'], connect()(withRouter(EducationPage)));
