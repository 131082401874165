import api from './api';


class GeneticTestService {
  getLabTestInfo(labName,testName,partnerID) {
    return api.genetictest.getLabTestInfo(labName,testName,partnerID);
  }

}

export default new GeneticTestService();
