export const orderStatuses = {
  awaitingApproval: 'awaiting_review',
  approved: 'approved',
  sentToLab: 'sent_to_lab',
  resultsReady: 'results_ready',
  cancelled: 'order_canceled',
  rejected: 'rejected',
  null: 'null',
};

export const testOrderCardTitles = {
  [orderStatuses.awaitingApproval]: 'We have received your test order request',
  [orderStatuses.approved]: 'Your test is authorized!',
  [orderStatuses.sentToLab]: 'Your test order has been sent to the lab',
  [orderStatuses.resultsReady]: 'Your test results are ready!',
  [orderStatuses.cancelled]: 'Your test order has been canceled',
  [orderStatuses.null]: null,
};

export const testOrderCardDescriptions = {
  [orderStatuses.awaitingApproval]:
    'We have received your request.  One of our doctors will review your information and we will update you once processed.',
  [orderStatuses.approved]:
    'After reviewing your test order request, our doctor has authorized your test for us to place your test order.',
  [orderStatuses.sentToLab]:
    'Your Genome Medical physician has reviewed and authorized your test request. Your next step will be to submit a sample for genetic testing to the lab. We will update you once we’ve received your test results.',
  [orderStatuses.resultsReady]:
    'Your results are back and have been sent to you. Please contact us if you have any questions.',
  [orderStatuses.cancelled]:
    'Our doctor has canceled your test order request. We will contact you soon to discuss further actions.',
  [orderStatuses.null]: null,
};

export const testOrderDetailPageTitles = {
  [orderStatuses.awaitingApproval]: 'Our Physician Is Reviewing Your Information',
  [orderStatuses.approved]: 'Your Test Order Has Been Authorized',
  [orderStatuses.sentToLab]: 'Your Test Has Been Ordered',
  [orderStatuses.resultsReady]: 'Your Test Result Is Ready',
  [orderStatuses.cancelled]: 'Your Test Has Been Canceled',
  [orderStatuses.null]: null,
};

export const testOrderDetailProgressBar = {
  [orderStatuses.awaitingApproval]: {
    step1: { className: 'done', text: 'Request your Test Order' },
    step2: { className: 'current', text: 'Doctor reviews your request' },
    step3: { className: '', text: 'Genome Medical doctor places your test order' },
  },
  [orderStatuses.approved]: {
    step1: { className: 'done', text: 'Request your Test order' },
    step2: { className: 'done', text: 'Doctor reviews your request' },
    step3: { className: 'current', text: 'Genome Medical doctor places your test order' },
  },
  [orderStatuses.sentToLab]: {
    step1: { className: 'done', text: 'Request your Test order' },
    step2: { className: 'done', text: 'Doctor reviews your request' },
    step3: { className: 'done', text: 'Genome Medical doctor places your test order' },
  },
  [orderStatuses.resultsReady]: {
    step1: { className: 'done', text: 'Request your Test order' },
    step2: { className: 'done', text: 'Doctor reviews your request' },
    step3: { className: 'done', text: 'Genome Medical doctor places your test order' },
  },
  [orderStatuses.cancelled]: {
    step1: { className: 'done', text: 'Request your Test order' },
    step2: { className: 'cancel', text: 'Test order canceled' },
    step3: { className: '', text: 'Genome Medical doctor places your test order' },
  },
  [orderStatuses.null]: {
    step1: { className: '', text: '' },
    step2: { className: '', text: '' },
    step3: { className: '', text: '' },
  },
};
