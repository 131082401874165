import enumsService from 'services/api/enums';
import insuranceService from 'services/api/insuranceService';
import { showLoader, closeLoader } from './ui';

const GET_RACES_LOADING = 'gm/enums/GET_RACES_LOADING';
const GET_RACES_SUCCESS = 'gm/enums/GET_RACES_SUCCESS';
const GET_RACES_FAILURE = 'gm/enums/GET_RACES_FAILURE';
const GET_ETHNICITIES_LOADING = 'gm/enums/GET_ETHNICITIES_LOADING';
const GET_ETHNICITIES_SUCCESS = 'gm/enums/GET_ETHNICITIES_SUCCESS';
const GET_ETHNICITIES_FAILURE = 'gm/enums/GET_ETHNICITIES_FAILURE';

const GET_PLAN_TYPES_SUCCESS = 'gm/enums/GET_PLAN_TYPES_SUCCESS';
const GET_PLAN_TYPES_FAILURE = 'gm/enums/GET_PLAN_TYPES_FAILURE';
const GET_PLAN_TYPES_LOADING = 'gm/enums/GET_PLAN_TYPES_LOADING';

const GET_COUNTRY_STATE_LOADING = 'gm/enums/GET_COUNTRY_STATE_LOADING';
const GET_COUNTRY_STATE_SUCCESS = 'gm/enums/GET_COUNTRY_STATE_SUCCESS';
const GET_COUNTRY_STATE_FAILURE = 'gm/enums/GET_COUNTRY_STATE_FAILURE';

const defaultState = {
  races: {
    loading: false,
    data: [],
    error: null,
  },
  ethnicities: {
    loading: false,
    data: [],
    error: null,
  },
  planTypes: {
    loading: false,
    data: [],
    error: null,
  },
  countryState: {
    loading: false,
    data: [],
    error: null,
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case GET_RACES_LOADING:
      return {
        ...state,
        races: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case GET_RACES_SUCCESS:
      return {
        ...state,
        races: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_RACES_FAILURE:
      return {
        ...state,
        races: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case GET_ETHNICITIES_LOADING:
      return {
        ...state,
        ethnicities: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case GET_ETHNICITIES_SUCCESS:
      return {
        ...state,
        ethnicities: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_ETHNICITIES_FAILURE:
      return {
        ...state,
        ethnicities: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case GET_PLAN_TYPES_LOADING:
      return {
        ...state,
        planTypes: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case GET_PLAN_TYPES_SUCCESS:
      return {
        ...state,
        planTypes: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_PLAN_TYPES_FAILURE:
      return {
        ...state,
        planTypes: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
      case GET_COUNTRY_STATE_LOADING:
      return {
        ...state,
        countryState: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case GET_COUNTRY_STATE_SUCCESS:
      return {
        ...state,
        countryState: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_COUNTRY_STATE_FAILURE:
      return {
        ...state,
        countryState: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

const getRaceEnumsLoading = () => ({
  type: GET_RACES_LOADING,
});

const getRaceEnumsSuccess = payload => ({
  type: GET_RACES_SUCCESS,
  payload,
});

const getRaceEnumsFailure = payload => ({
  type: GET_RACES_FAILURE,
  payload,
});

export const getRaceEnums = () => dispatch => {
  dispatch(showLoader());
  dispatch(getRaceEnumsLoading());
  return enumsService
    .getRaces()
    .then(data => {
      dispatch(getRaceEnumsSuccess(data));
      dispatch(closeLoader());
      return data;
    })
    .catch(error => {
      dispatch(getRaceEnumsFailure(error));
      dispatch(closeLoader());
      throw error;
    });
};

const getEthnicityEnumsLoading = () => ({
  type: GET_ETHNICITIES_LOADING,
});

const getEthnicityEnumsSuccess = payload => ({
  type: GET_ETHNICITIES_SUCCESS,
  payload,
});

const getEthnicityEnumsFailure = payload => ({
  type: GET_ETHNICITIES_FAILURE,
  payload,
});

export const getEthnicityEnums = () => dispatch => {
  dispatch(showLoader());
  dispatch(getEthnicityEnumsLoading());
  return enumsService
    .getEthnicities()
    .then(data => {
      dispatch(getEthnicityEnumsSuccess(data));
      dispatch(closeLoader());
      return data;
    })
    .catch(error => {
      dispatch(getEthnicityEnumsFailure(error));
      dispatch(closeLoader());
      throw error;
    });
};

const getPlanTypesLoading = () => ({
  type: GET_PLAN_TYPES_LOADING,
});

const getPlanTypesSuccess = payload => ({
  type: GET_PLAN_TYPES_SUCCESS,
  payload,
});

const getPlanTypesFailure = payload => ({
  type: GET_PLAN_TYPES_FAILURE,
  payload,
});

export const getPlanTypesEnums = () => dispatch => {
  dispatch(getPlanTypesLoading());
  return insuranceService
    .getInsurancePlanTypes()
    .then(data => {
      dispatch(getPlanTypesSuccess(data));
      return data;
    })
    .catch(error => {
      dispatch(getPlanTypesFailure(error));
      throw error;
    });
};

const getCountryStateLoading = () => ({
  type: GET_COUNTRY_STATE_LOADING,
});

const getCountryStateSuccess = payload => ({
  type: GET_COUNTRY_STATE_SUCCESS,
  payload,
});

const getCountryStateFailure = payload => ({
  type: GET_COUNTRY_STATE_FAILURE,
  payload,
});

export const getCountryState = (partnerUUID) => dispatch => {
  dispatch(showLoader());
  dispatch(getCountryStateLoading());
  return enumsService
    .getCountryState(partnerUUID)
    .then(data => {
      dispatch(getCountryStateSuccess(data));
      dispatch(closeLoader());
      return data;
    })
    .catch(error => {
      dispatch(getCountryStateFailure(error));
      dispatch(closeLoader());
      throw error;
    });
};
