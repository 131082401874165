import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Field } from 'redux-form';

class WizardStepForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    children: PropTypes.node,
    alwaysEnabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    initialValues: {},
  };

  renderField(child, index) {
    const { initialValues, alwaysEnabled } = this.props;
    return (
      <Field
        {...child.props}
        disabled={!alwaysEnabled && initialValues[child.props.name] !== undefined}
        key={index}
      />
    );
  }

  renderChildren(children) {
    if (Children.count(children) === 1) {
      return children.type === Field ? this.renderField(children, 0) : children;
    }
    return children.map(
      (child, index) =>
        !_.isEmpty(child) &&
        (child.type === Field ? this.renderField(child, index) : this.renderChild(child, index))
    );
  }

  renderChild(child, key) {
    const { children } = child && child.props;
    if (Children.count(children) > 0) {
      return React.cloneElement(child, { children: this.renderChildren(children), key });
    }
    return child;
  }

  render() {
    const { children, onSubmit, className } = this.props;
    return (
      <form onSubmit={onSubmit} className={className}>
        {this.renderChildren(children)}
      </form>
    );
  }
}

export default WizardStepForm;
