import RedirectAfterLogin from './Actions/RedirectAfterLogin';
import RedirectToDashboard from './Actions/RedirectToDashboard';
import RedirectToWebinar from './Actions/RedirectToWebinar';
import Context from './Context';

class Application {
  actions: Array = [];
  context: Context = null;

  constructor(actions: [] = null) {
    if (actions) {
      this.actions = actions;
    }
  }

  /**
   * Set active application context.
   *
   * @param router Object
   * @param store Object
   * @param layoutType string
   *
   * @returns {Application} Object
   */
  for(router: Object, store: Object, layoutType: string) {
    this.context = new Context(router, store, layoutType);
    return this;
  }

  /**
   * Execute update cycle with current application context.
   *
   * @return void
   */
  update() {
    if (this.context === null) throw new Error('Context not specified.');

    this.actions.forEach(a => a.canExecute(this.context) && a.execute(this.context));
  }
}

export default new Application([
  new RedirectAfterLogin(),
  new RedirectToDashboard(),
  new RedirectToWebinar(),
]);
