import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import includes from 'lodash/includes';

export default (componentType, ChildComponent) => {
  class HOC extends Component {
    render() {
      const { me: { isSCP }, ...otherProps } = this.props;
      const userType = isSCP ? 'scp' : 'nonScp';

      return (
        <Fragment>
          {includes(componentType, userType) ? <ChildComponent {...otherProps} /> : null}
        </Fragment>
      );
    }
  }

  HOC.propTypes = {
    me: PropTypes.object.isRequired,
  };

  const mapStateToProps = state => ({
    me: state.user.me,
  });

  return connect(mapStateToProps)(HOC);
};
