export default {
  generalError: 'Oops: something went wrong.',
  updatePassword: 'Your password has been updated.',
  updateProfile: 'Your profile has been updated.',
  updateConsent: 'Your consent has been updated successfully.',
  updatePayment: 'Payment method has been updated.',
  wrongWaitingRoom:
    'This appointment is already completed or appointment not found.' +
    ' You will be redirected to the home page after several seconds.',
  registerDefaultError:
    'Oops: something went wrong. Your UserID is not found or already in use on this server.',
  rejectFiles:
    "Sorry, some files haven't been uploaded. Max file size was probably exceeded. Max file size is 50MB.",
};
