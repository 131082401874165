import PropTypes from 'prop-types';
import React from 'react';
import './FancyPageFrame.scss';

export default function FancyPageFrame({ title, children }) {
  return (
    <div className="fancy-page-frame">
      <div className="fancy-title-block">
        <img
          className="ribbon-img"
          src={require('images/gray-ribbon.png')}
          srcSet={`${require('images/gray-ribbon@2x.png')} 2x`}
        />
        <div className="fancy-title">{title}</div>
      </div>
      <div className="content-block">{children}</div>
    </div>
  );
}

FancyPageFrame.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
