import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router';
import './NavigatorHeader.scss';
import gmiLogo from '../../../images/logo.svg';
import authService from '../../../services/api/authService';
class NavigatorHeader extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  onTouchMove(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  setDropOutData = () => {
    const partnerId = authService.getAffiliationId();
    authService.saveSCPDropOutData({
      partnerId: partnerId,
      checkUserExist: true,
    });
  };

  render() {
    return (
      <div className="scp-navigator-header-container">
        <div className="nav-container">
          <div className="dummy-container" />
          <div className="logo-container">
            <img src={gmiLogo} className="header-logo" />
          </div>
          <div className="login-link-container">
            <p>Already have an account?</p>
            <p>
              <Link to="/login" onClick={this.setDropOutData}>
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

NavigatorHeader.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default compose(connect(null), withRouter)(NavigatorHeader);
