import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadDocumentsModal from 'components/modals/SCP/UploadDocumentsModal/UploadDocumentsModal';
import DocumentCard from './../DocumentCard';
import Button from 'components/forms/controls/Button';
import ConfirmActionModal from 'components/modals/SCP/ConfirmActionModal/ConfirmActionModal';
import documentsService from 'services/api/documentsService';
import {
  deleteDocument,
  uploadDocuments,
  resetUploadingErrors,
  uploadDocumentInsuranceCard,
} from 'ducks/documents';
import { setModalMode } from 'ducks/modals';
import ModalNames from 'constants/ModalNames';
import StatusMessages from 'constants/StatusMessages';
import {
  CreateFileNameForInsuranceCard,
  createNewFileName,
  allowedDocumentUploadTypes,
} from 'services/utils';
import { documentTypesNames, INS_FRONT_CARD, INS_BACK_CARD } from 'constants/DocumentsConstants';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import ProgressBar from 'components/scp/forms/documents/Dropzone/ProgressBar';
import './ResultsPage.scss';
import { Loading, Tooltip } from 'gm-element-react';
import RedWarningIcon from './warning.svg';
import maliciousIcon from './../malicious.png';
import uploadIcon from 'images/common/upload-purple.svg';
import deleteIcon from 'images/common/delete-white.svg';

const BYTES_IN_MB = 1024 * 1024;

class ResultsPage extends Component {
  static insurancecard = {
    accept: 'image/jpeg, image/png,image/gif',
    maxSize: 50 * BYTES_IN_MB,
    autoupload: true,
  };
  state = {
    selectedFiles: [],
    newFileName: null,
    docIdToDelete: undefined,
    frontRejectedfiles: false,
    backRejectedfiles: false,
    uploadingDocument: false,
    expandeddocs: {},
    headerExpanded: false,
    insuranceFrontImage: null,
    insuranceBackImage: null,
  };

  componentDidMount() {
    if (this.InsuranceCardFront && this.InsuranceCardFront.isMalicious != true) {
      documentsService.getDocumentLink(this.InsuranceCardFront.id).then(res => {
        this.setState({ insuranceFrontImage: res });
      });
    }
    if (this.InsuranceCardBack && this.InsuranceCardBack.isMalicious != true) {
      documentsService.getDocumentLink(this.InsuranceCardBack.id).then(res => {
        this.setState({ insuranceBackImage: res });
      });
    }
    window.addEventListener('scroll', this.handleScroll, true);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.loaded && nextProps.me;
  }

  componentDidUpdate(prevProps) {
    if (this.props.loaded && prevProps.loaded !== this.props.loaded) {
      if (this.InsuranceCardFront && this.InsuranceCardFront.isMalicious != true) {
        documentsService.getDocumentLink(this.InsuranceCardFront.id).then(res => {
          this.setState({ insuranceFrontImage: res });
        });
      }
      if (this.InsuranceCardBack && this.InsuranceCardBack.isMalicious != true) {
        documentsService.getDocumentLink(this.InsuranceCardBack.id).then(res => {
          this.setState({ insuranceBackImage: res });
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  deleteDocument = () => {
    this.props.dispatch(deleteDocument(this.state.docIdToDelete));
    this.setState({ docIdToDelete: undefined });
  };

  openModal = () => {
    if (!_.isEmpty(this.state.selectedFiles)) this.resetSelectedFiles();
    this.props.dispatch(resetUploadingErrors());
    this.props.dispatch(setModalMode(ModalNames.uploadDocuments, { open: true }));
  };

  closeModal = () => {
    this.props.dispatch(setModalMode(ModalNames.uploadDocuments, { open: false }));
  };

  resetSelectedFiles = () => {
    this.setState({ selectedFiles: [], newFileName: null });
  };

  setSelectedFiles = filesData => {
    this.props.dispatch(resetUploadingErrors());
    this.setState({ selectedFiles: [...filesData] });
  };

  addSelectedFiles = filesData => {
    this.props.dispatch(resetUploadingErrors());
    this.setState({ selectedFiles: [...filesData] });
  };

  uploadInsurance(filedata, type = INS_FRONT_CARD) {
    if (_.isArray(filedata) && filedata.length > 0) {
      const { documentTypes, me } = this.props;
      const docType =
        documentTypes &&
        _.find(documentTypes, function(doc) {
          return doc && doc.name.toLowerCase() == documentTypesNames.insuranceCard.toLowerCase();
        });
      const filename = filedata[0].name || 'undefined.jpg';
      const ext = filename.substring(filename.lastIndexOf('.'), filename.length) || '';
      const newname = CreateFileNameForInsuranceCard(
        docType,
        _.get(me, 'firstName', ''),
        _.get(me, 'lastName', ''),
        ext,
        type
      );
      if (filedata && filedata.length > 0) {
        this.props.dispatch(uploadDocumentInsuranceCard(filedata[0], docType, newname, type));
      }
    }
  }

  get InsuranceCardFront() {
    const { documents } = this.props;
    return this.InsuranceCardFilter(documents, INS_FRONT_CARD);
  }

  get InsuranceCardBack() {
    const { documents } = this.props;
    return this.InsuranceCardFilter(documents, INS_BACK_CARD);
  }

  uploadSelectedDocuments = values => {
    const { documentTypes, me } = this.props;

    const docTypes =
      documentTypes &&
      _.find(documentTypes, function(doc) {
        return doc && doc.id && doc.id == values.documentTypeId;
      });
    const ext =
      values.fileName.substring(values.fileName.lastIndexOf('.'), values.fileName.length) || '';
    const newfilenames = createNewFileName(
      docTypes,
      _.get(me, 'firstName', ''),
      _.get(me, 'lastName', ''),
      ext
    );
    this.setState({ newFileName: values.fileName, uploadingDocument: true }, () => {
      this.props
        .dispatch(
          uploadDocuments(this.state.selectedFiles, {
            fileNames: newfilenames,
            description: values.description,
            documentTypeId: values.documentTypeId,
          })
        )
        .then(() => {
          this.handleUploadDocument(false);
          this.closeModal();
        })
        .catch(() => {
          this.handleUploadDocument(false);
          this.resetSelectedFiles();
        });
    });
  };

  handleUploadDocument = value => {
    this.setState({ uploadingDocument: value });
  };

  InsuranceCardFilter = (documents, type = INS_FRONT_CARD) => {
    const filterstring =
      documentTypesNames.insuranceCard.toLowerCase() + '-' + type.toLowerCase() + '-';
    const filtercards = documents
      ? _.filter(documents, function(doc) {
          return (
            doc &&
            doc.type &&
            doc.type.name &&
            doc.type.name.toLowerCase() == documentTypesNames.insuranceCard.toLowerCase() &&
            doc.fileName.toLowerCase().indexOf(filterstring) !== -1
          );
        })
      : [];
    const sorteddocument = filtercards ? _.sortBy(filtercards, val => val.createdAt) : [];
    const insuranceCard = _.last(sorteddocument);
    return insuranceCard;
  };

  isRemoving = doc => {
    const { removingFiles } = this.props;
    return _(removingFiles).includes(doc.id);
  };

  filterDocumentList = () => {
    const { documents, me } = this.props;
    if (_.isArray(documents)) {
      if (_.get(me, 'showInsuranceCardToPP', true)) {
        return documents.filter(doc => {
          return !this.isLatestInsuranceCard(doc);
        });
      }
      return documents;
    }
    return [];
  };

  hasNoDocs = () => {
    const documents = this.filterDocumentList();
    return _.isEmpty(documents);
  };

  getOnlyFileName = () => {
    const onlyFile = _(this.state.selectedFiles).first();
    return onlyFile && onlyFile.name;
  };

  onPopoverClick = id => {
    this.setState({ docIdToDelete: id });
    this.dummydiv.click(); // required to hide the popover
  };

  handleScroll = () => {
    this.dummydiv.click(); // required to hide the popover
  };

  isLatestInsuranceCard = doc => {
    const latestinsurnacecardid = [];
    if (this.InsuranceCardBack && this.InsuranceCardBack.id)
      latestinsurnacecardid.push(this.InsuranceCardBack.id);
    if (this.InsuranceCardFront && this.InsuranceCardFront.id)
      latestinsurnacecardid.push(this.InsuranceCardFront.id);
    return (
      doc &&
      doc.type &&
      doc.type.name &&
      doc.type.name.toLowerCase() === documentTypesNames.insuranceCard.toLowerCase() &&
      doc.id &&
      _.find(latestinsurnacecardid, d => d == doc.id)
    );
  };

  renderDocumentCards() {
    const documents = this.filterDocumentList();
    return (
      <div className="results-cards-table">
        {Array.isArray(documents) &&
          documents.map(doc => {
            const docId = doc.id;
            return (
              <DocumentCard
                document={doc}
                variant={'patient'}
                key={docId}
                downloadDocument={this.props.downloadDocument}
                onDelete={this.onPopoverClick}
              />
            );
          })}
      </div>
    );
  }

  deleteInsuranceCard = (e, id) => {
    e.stopPropagation();
    this.onPopoverClick(id);
  };

  //TODO: implement "Download All Files" functionality
  render() {
    const {
      loaded,
      me,
      uploadModal,
      uploadingStatus,
      documentTypes,
      frontInsuranceCardStatus,
      backInsuranceCardStatus,
    } = this.props;
    const backboxclasses = !this.InsuranceCardBack
      ? 'uploaddoc border-box back-border-box '
      : 'uploaddoc border-box uplpad-img-radius';
    const frontboxclasses = !this.InsuranceCardFront
      ? 'uploaddoc border-box back-border-box '
      : 'uploaddoc border-box uplpad-img-radius';
    const showInsuranceCardToPP = _.get(me, 'showInsuranceCardToPP', true);
    return (
      <div className="scp-results-page-block ">
        <div className="scp-myheath-header">Documents Uploaded By You</div>
        {_.get(me, 'showInsuranceCardToPP', true) && (
          <div className="insurance-page-block">
            <h3 className="Your-insurance-card">Your Insurance Card</h3>
            <div className="insurance-upload-block" data-hj-suppress>
              <div className="upload-card">
                <span className="uploaddoctitle">Front</span>
                {_.isNull(_.get(this.InsuranceCardFront, ['isMalicious'], false)) ? (
                  <Loading
                    text="Scanning for viruses"
                    loading={true}
                    className={classnames(frontboxclasses, 'scanning')}>
                    <div className="card-img">
                      {!_.isNil(this.state.insuranceFrontImage) && (
                        <img className="doc-img" src={this.state.insuranceFrontImage} />
                      )}
                    </div>
                  </Loading>
                ) : (
                  <Dropzone
                    className={classnames(
                      frontboxclasses,
                      {
                        'is-malicious': _.get(this.InsuranceCardFront, ['isMalicious'], false),
                      },
                      {
                        'is-uploaded-image': this.InsuranceCardFront,
                      }
                    )}
                    autoupload={ResultsPage.insurancecard.autoupload}
                    multiple={false}
                    maxSize={ResultsPage.insurancecard.maxSize}
                    accept={ResultsPage.insurancecard.accept}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      this.setState({ frontRejectedfiles: !_.isEmpty(rejectedFiles) });
                      this.uploadInsurance(acceptedFiles, INS_FRONT_CARD);
                    }}>
                    {!this.InsuranceCardFront ? (
                      <div>
                        <div className="uploaddoc-dropblock">
                          <div className="text-center">
                            {' '}
                            <img className="cloud-img" src={require('images/upload.svg')} />
                          </div>
                          {frontInsuranceCardStatus &&
                            frontInsuranceCardStatus.uploading && (
                              <ProgressBar
                                className="dropzone-progressbar"
                                style={{ zIndex: 1000 }}
                                percent={
                                  (frontInsuranceCardStatus &&
                                    frontInsuranceCardStatus.uploadingProgress) ||
                                  0
                                }
                                strokeColor={undefined}
                                trailColor={undefined}
                              />
                            )}
                          <div className="uploaddoctextContainerDesktop">
                            {' '}
                            <p className="uploaddoctext">
                              Drop file here or{' '}
                              <a href="javascript:;" className="uploaddoclink">
                                click to upload
                              </a>
                            </p>
                          </div>
                          <div className="uploaddoctextContainerMobile">
                            <div className="uploaddoctextContainerMobileText">
                              Upload&nbsp;
                              <span className="uploaddoctextContainerMobileTextBold">
                                front&nbsp;
                              </span>
                              image
                            </div>
                            <div className="uploaddoctextContainerMobileCTA">Browse Photos</div>
                          </div>
                        </div>
                      </div>
                    ) : _.get(this.InsuranceCardFront, ['isMalicious'], false) ? (
                      <div className="card-img">
                        <Tooltip
                          className="insurance-card-virus-tooltip"
                          placement="top"
                          content="This file contains a virus and is blocked"
                          effect="dark">
                          <img
                            className="malicious-icon"
                            src={maliciousIcon}
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </Tooltip>
                        {frontInsuranceCardStatus &&
                          frontInsuranceCardStatus.uploading && (
                            <ProgressBar
                              className="dropzone-progressbar upload-progressbar"
                              style={{ zIndex: 1000 }}
                              percent={
                                (frontInsuranceCardStatus &&
                                  frontInsuranceCardStatus.uploadingProgress) ||
                                0
                              }
                              strokeColor={undefined}
                              trailColor={undefined}
                            />
                          )}
                        <div className="update-image">
                          <div className="text-center hoverdoc-block">
                            {' '}
                            <img className="camera-img" src={require('images/camera.svg')} />
                          </div>
                          <p className="dochover-text">New Image</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card-img">
                        {!_.isNil(this.state.insuranceFrontImage) && (
                          <img className="doc-img" src={this.state.insuranceFrontImage} />
                        )}
                        {frontInsuranceCardStatus &&
                          frontInsuranceCardStatus.uploading && (
                            <ProgressBar
                              className="dropzone-progressbar upload-progressbar"
                              style={{ zIndex: 1000 }}
                              percent={
                                (frontInsuranceCardStatus &&
                                  frontInsuranceCardStatus.uploadingProgress) ||
                                0
                              }
                              strokeColor={undefined}
                              trailColor={undefined}
                            />
                          )}
                        <div className="update-image">
                          <div className="text-center hoverdoc-block">
                            {' '}
                            <img className="camera-img" src={require('images/camera.svg')} />
                          </div>
                          <p className="dochover-text">New Image</p>
                        </div>
                        <div
                          className="delete-image"
                          onClick={e =>
                            this.deleteInsuranceCard(e, _.get(this.InsuranceCardFront, ['id']))}>
                          <img className="delete-icon" src={deleteIcon} />
                          <p className="dochover-text">Delete Image</p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}
                {((frontInsuranceCardStatus &&
                  frontInsuranceCardStatus.uploadingResult &&
                  frontInsuranceCardStatus.uploadingResult.status === false) ||
                  this.state.frontRejectedfiles) && (
                  <div className="error-div">
                    <span className="error">
                      {(frontInsuranceCardStatus.uploadingResult &&
                        frontInsuranceCardStatus.uploadingResult.error) ||
                        (this.state.frontRejectedfiles && StatusMessages.rejectFiles)}
                    </span>
                  </div>
                )}
              </div>
              <div className="upload-card">
                <span className="uploaddoctitle">Back</span>
                {_.isNull(_.get(this.InsuranceCardBack, ['isMalicious'], false)) ? (
                  <Loading
                    text="Scanning for viruses"
                    loading={true}
                    className={classnames(backboxclasses, 'scanning')}>
                    <div className="card-img">
                      {!_.isNil(this.state.insuranceBackImage) && (
                        <img className="doc-img" src={this.state.insuranceBackImage} />
                      )}
                    </div>
                  </Loading>
                ) : (
                  <Dropzone
                    className={classnames(
                      backboxclasses,
                      {
                        'is-malicious': _.get(this.InsuranceCardBack, ['isMalicious'], false),
                      },
                      {
                        'is-uploaded-image': this.InsuranceCardBack,
                      }
                    )}
                    autoupload={ResultsPage.insurancecard.autoupload}
                    multiple={false}
                    maxSize={ResultsPage.insurancecard.maxSize}
                    accept={ResultsPage.insurancecard.accept}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      this.setState({ backRejectedfiles: !_.isEmpty(rejectedFiles) });
                      this.uploadInsurance(acceptedFiles, INS_BACK_CARD);
                    }}>
                    {!this.InsuranceCardBack ? (
                      <div>
                        <div className="uploaddoc-dropblock">
                          <div className="text-center">
                            {' '}
                            <img className="cloud-img" src={require('images/upload.svg')} />
                          </div>
                          {backInsuranceCardStatus &&
                            backInsuranceCardStatus.uploading && (
                              <ProgressBar
                                className="dropzone-progressbar"
                                style={{ zIndex: 1000 }}
                                percent={
                                  (backInsuranceCardStatus &&
                                    backInsuranceCardStatus.uploadingProgress) ||
                                  0
                                }
                                strokeColor={undefined}
                                trailColor={undefined}
                              />
                            )}
                          <div className="uploaddoctextContainerDesktop">
                            {' '}
                            <p className="uploaddoctext">
                              Drop file here or{' '}
                              <a href="javascript:;" className="uploaddoclink">
                                click to upload
                              </a>
                            </p>
                          </div>
                          <div className="uploaddoctextContainerMobile">
                            <div className="uploaddoctextContainerMobileText">
                              Upload&nbsp;
                              <span className="uploaddoctextContainerMobileTextBold">
                                back&nbsp;
                              </span>
                              image
                            </div>
                            <div className="uploaddoctextContainerMobileCTA">Browse Photos</div>
                          </div>
                        </div>
                      </div>
                    ) : _.get(this.InsuranceCardBack, ['isMalicious'], false) ? (
                      <div className="card-img">
                        <Tooltip
                          className="insurance-card-virus-tooltip"
                          placement="top"
                          content="This file contains a virus and is blocked"
                          effect="dark">
                          <img
                            className="malicious-icon"
                            src={maliciousIcon}
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </Tooltip>
                        {backInsuranceCardStatus &&
                          backInsuranceCardStatus.uploading && (
                            <ProgressBar
                              className="dropzone-progressbar upload-progressbar"
                              style={{ zIndex: 1000 }}
                              percent={
                                (backInsuranceCardStatus &&
                                  backInsuranceCardStatus.uploadingProgress) ||
                                0
                              }
                              strokeColor={undefined}
                              trailColor={undefined}
                            />
                          )}
                        <div className="update-image">
                          <div className="text-center hoverdoc-block">
                            {' '}
                            <img className="camera-img" src={require('images/camera.svg')} />
                          </div>
                          <p className="dochover-text">New Image</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card-img">
                        {!_.isNil(this.state.insuranceBackImage) && (
                          <img className="doc-img" src={this.state.insuranceBackImage} />
                        )}
                        {backInsuranceCardStatus &&
                          backInsuranceCardStatus.uploading && (
                            <ProgressBar
                              className="dropzone-progressbar upload-progressbar"
                              style={{ zIndex: 1000 }}
                              percent={
                                (backInsuranceCardStatus &&
                                  backInsuranceCardStatus.uploadingProgress) ||
                                0
                              }
                              strokeColor={undefined}
                              trailColor={undefined}
                            />
                          )}
                        <div className="update-image">
                          <div className="text-center hoverdoc-block">
                            {' '}
                            <img className="camera-img" src={require('images/camera.svg')} />
                          </div>
                          <p className="dochover-text">New Image</p>
                        </div>
                        <div
                          className="delete-image"
                          onClick={e =>
                            this.deleteInsuranceCard(e, _.get(this.InsuranceCardBack, ['id']))}>
                          <img className="delete-icon" src={deleteIcon} />
                          <p className="dochover-text">Delete Image</p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}
                {((backInsuranceCardStatus &&
                  backInsuranceCardStatus.uploadingResult &&
                  backInsuranceCardStatus.uploadingResult.status === false) ||
                  this.state.backRejectedfiles) && (
                  <div className="error-div">
                    <span className="error">
                      {(backInsuranceCardStatus.uploadingResult &&
                        backInsuranceCardStatus.uploadingResult.error) ||
                        (this.state.backRejectedfiles && StatusMessages.rejectFiles)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="results-actions-bar other-doc-block">
          <div className={`other-doc-header ${showInsuranceCardToPP ? '' : 'hide-insurance-card'}`}>
            <span className="Title">Other Documents</span>
            <Button onClick={this.openModal} className="results-upload-btn" type="submit">
              <span className="btn-inner-container">
                <span className="btn-icon">
                  <img src={uploadIcon} />
                </span>
                <span>Upload File</span>
              </span>
            </Button>
          </div>
          {loaded ? me && this.renderDocumentCards() : null}
          {loaded &&
            this.hasNoDocs() && <div className="no-docs-warn">You have no attached documents</div>}
          <ConfirmActionModal
            roleModal={'scp-modal'}
            show={!_.isNil(this.state.docIdToDelete)}
            confirmText="Delete File"
            header="Are you sure you want to delete this file?"
            onConfirm={this.deleteDocument}
            onCancel={() => this.setState({ docIdToDelete: undefined })}
            modalHeader={
              <div className="header-text">
                <img src={RedWarningIcon} /> <span>{'Delete File'}</span>
              </div>
            }
          />
          <UploadDocumentsModal
            roleModal={'scp-modal'}
            show={uploadModal && uploadModal.open}
            onCancel={this.closeModal}
            dropzoneOnDrop={this.addSelectedFiles}
            setAcceptedFiles={this.setSelectedFiles}
            onRemove={this.deleteDocument}
            uploadingStatus={uploadingStatus}
            onSubmit={this.uploadSelectedDocuments}
            documentTypes={
              documentTypes && documentTypes.filter(d => allowedDocumentUploadTypes(d))
            }
            initialValues={{ fileName: this.getOnlyFileName() }}
            newFileNames={[this.state.newFileName]}
            allFieldsEnable={!_.isEmpty(this.state.selectedFiles)}
            modalHeader={<div className="header-text">Upload Document</div>}
            uploadingDocument={this.state.uploadingDocument}
          />
        </div>
        <div key={'dummydiv'} ref={el => (this.dummydiv = el)} />
      </div>
    );
  }
}

ResultsPage.propTypes = {
  uploadModal: PropTypes.object.isRequired,
  uploadingStatus: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  removingFiles: PropTypes.array.isRequired,
  documentTypes: PropTypes.array,
  frontInsuranceCardStatus: PropTypes.object.isRequired,
  backInsuranceCardStatus: PropTypes.object.isRequired,
};

export default ResultsPage;
