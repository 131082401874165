import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import './BreadcrumbProgress.scss';
import ArrowRight from './ArrowRight.js';
import api from 'services/api/api';
import MobileMenu from '../header/MobileMenu/MobileMenu';
import GrailStatusEnums from 'constants/GrailStatus';
import gmiLogo from '../../../images/minimized_logo.png';
const BreadcrumbProgress = ({
  activeStage,
  workflow,
  className,
  isSchedulingWizardPage = false,
  location = {},
  user = {},
  onLogout,
}) => {
  const stages = _(workflow).reduce((result, o, i) => {
    result[o.id] = i;
    return result;
  }, {});

  const activeStageIndex = activeStage ? stages[activeStage] : 0;

  // for mobile
  const actualStages = workflow.filter(o => !o.noProgress);
  const stagesCount = actualStages.length;
  let currentStageIndex = 0;
  let stageName = '';
  for (const stage of actualStages) {
    currentStageIndex++;
    if (stage.id === activeStage) {
      stageName = stage.name;
      break;
    }
  }

  const getNavigationItems = () => {
    const pathname = _.get(location, ['pathname'], '');

    if ((api.hasToken() && !isLoggedIn()) || !api.hasToken()) return [];
    if (!hideMenuAndProfile) return [];

    const items = [
      {
        name: 'Home',
        path: '/patient/home',
        active: pathname === '/patient/home',
      },
      {
        name: 'Education',
        path: '/patient/education',
        active: pathname === '/patient/education',
        hidden: api.isNonSCPUser(),
      },
      {
        name: 'Documents',
        path: '/patient/results',
        active: pathname === '/patient/results',
      },
      {
        name: 'Appointments',
        path: '/patient',
        active:
          ['/patient', '/patient/reschedule', '/patient/questionnaire'].includes(pathname) ||
          isSchedulePath(pathname),
      },
    ];

    return items.filter(item => !item.hidden).map(item =>
      Object.assign(item, {
        active: item.active === undefined ? pathname.includes(item.path) : item.active,
      })
    );
  };

  const isSchedulePath = path => {
    return /^\/scheduling/.test(path);
  };

  const isLoggedIn = () => {
    return !!user.me && !user.holdLoggedIn;
  };

  const logout = () => {
    onLogout();
  };

  const hideMenuAndProfile = () => {
    return (
      (user &&
        user.me &&
        (user.me.grailStatus != GrailStatusEnums.QUALIFICATION_REQUIRED &&
          user.me.grailStatus != GrailStatusEnums.SWITCH_AFFILIATION)) ||
      !user.me.affiliationIsIllumina
    );
  };

  return (
    <div>
      <div className={classnames(className, 'scp-schedule-breadcrumb')}>
        {workflow.filter(o => !o.noProgress).map((o, i) => {
          const isDone = stages[o.id] < activeStageIndex;
          return (
            <div
              className={classnames('breadcrump__item', {
                breadcrump__item__active: o.id === activeStage,
                breadcrump__item__done: isDone,
              })}
              key={o.id}>
              <span className="breadcrump__text">{o.name}</span>
              {i + 1 != stagesCount ? (
                <span className="breadcrump__nav">
                  <ArrowRight className="arrow-right" width="28" height="28" />
                </span>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="scp-mob-schedule-breadcrumb">
        <div
          className={classnames(
            'm-breadcrumb-content',
            isSchedulingWizardPage ? 'm-scheduling-breadcrumb-content' : ''
          )}>
          {!isSchedulingWizardPage ? (
            <div className="step" style={{display:'none'}}>
              <span className="step-label">
                Step {currentStageIndex}/{stagesCount}{' '}
              </span>
              <span className="step-name">{stageName}</span>
            </div>
          ) : (
            <Fragment>
              <div className="logo">
                <img src={gmiLogo} className="header-logo" />
              </div>
              <div className="step" style={{display:'none'}} >
                <span className="step-name">
                  {currentStageIndex}. {stageName}
                </span>
              </div>
              <div className="menu">
                {
                  <MobileMenu
                    items={getNavigationItems()}
                    isLoggedIn={isLoggedIn()}
                    onLogout={() => logout()}
                    pathname={_.get(location, ['pathname'], '')}
                    hideMenuAndProfile={hideMenuAndProfile}
                  />
                }
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <div
        className="mob-schedule-appointment-progress-bar"
        style={{ width: `${100 * currentStageIndex / stagesCount}%` }}
      />
    </div>
  );
};

BreadcrumbProgress.propTypes = {
  activeStage: PropTypes.string,
  workflow: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

export default BreadcrumbProgress;
