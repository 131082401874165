import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import { initialize as initializeForm } from 'redux-form';
import _ from 'lodash';
import authService from 'services/api/authService';
import webinarService from 'services/api/webinarService';
import WizardProgress from 'components/registration/WizardProgress';
import ClientInformationForm from 'components/forms/WizardForm/ClientInformationForm/ClientInformationForm';
import SimpleSignUpForm from 'components/forms/WizardForm/SimpleSignUpForm/SimpleSignUpForm';
import ThanksForSchedulingForm from 'components/forms/WizardForm/ThanksForSchedulingForm/ThanksForSchedulingForm';
import PaymentInformationForm, {
  PAYMENT_FORM_NAME,
} from 'components/forms/WizardForm/PaymentInformationForm/PaymentInformationForm';
import api from 'services/api/api';
import {
  setStage,
  submitAccount,
  submitClientInformation,
  submitPaymentInfo,
  bookWebinar,
} from 'ducks/registrationWizard';
import schedulingService from 'services/api/schedulingService';
import {
  webinarScheduling,
  CREATE_ACCOUNT,
  STAGE_WEBINAR_CLIENT_INFO,
  STAGE_WEBINAR_PAYMENT_INFO,
  STAGE_WEBINAR_ALMOST_DONE,
} from 'services/wizardWorkflows';
import { InvitaeReferral, CounsylReferral } from 'services/misc/ReferralSource';
import WebinarNotAvailableHOC from 'components/high-order-components/WebinarNotAvailableHOC/WebinarNotAvailableHOC';
import fetchHOC from '../../../../components/high-order-components/FetchHOC';
import { loaderSession } from 'ducks/ui';
import track, {
  TR_WEBINAR_PERSONAL_INFO_SUBMITTED,
  TR_WEBINAR_BILLING_INFO_SUBMITTED,
  TR_WEBINAR_REGISTRATION_OPEN_INVITAE,
  TR_WEBINAR_REGISTRATION_OPEN_COUNSYL,
} from 'services/track';
import { prepareDataForConfirmEmailPage, isWebinarEnabled } from 'services/utils';
import './RegistrationWizardPage.scss';

export class WebinarRegistrationWizardPage extends Component {
  state = {
    hasWebinar: undefined,
    hasUser: undefined,
  };

  componentDidMount() {
    const { registrationWizard, partners, webinar, partner, step } = this.props;
    const activeStage = registrationWizard.activeStage;
    this.setStateFromProps();
    if (activeStage && activeStage.search(/WEBINAR/i) < 0) {
      // mixed workflow. why? in this case we clear the previous workflow and nullify everything
      this.props.clearEverything();
      window.location.reload();
    }
    if (!partners.data && !partners.loading) partners.fetch();
    if (!webinar.data && !webinar.loading) webinar.fetch(this.props.params.partner);

    if (step === 'personal') {
      const openTrackingEvents = {
        invitae: TR_WEBINAR_REGISTRATION_OPEN_INVITAE,
        counsyl: TR_WEBINAR_REGISTRATION_OPEN_COUNSYL,
      };
      if (openTrackingEvents[partner]) track(openTrackingEvents[partner]);
    }

    this.goStageForDroppedUser(this.props);
  }

  componentDidUpdate() {
    const { webinar, currentUser } = this.props;
    if (api.hasToken() && webinar.loaded && _.isEmpty(webinar.data))
      browserHistory.push('/patient');

    const updatedUserOrWebinar =
      (!this.state.hasUser && currentUser) || (!this.state.hasWebinar && webinar.data);

    if (updatedUserOrWebinar) {
      this.setStateFromProps();
      this.goStageForDroppedUser();
    }
  }

  isRegistrationOpen() {
    const { webinar, params } = this.props;
    return webinar.fetch(params.partner).then(() => {
      const { data } = this.props.webinar;
      return isWebinarEnabled(data) ? Promise.resolve() : Promise.reject();
    });
  }

  getPartnerReferralSource() {
    const rf = {
      invitae: InvitaeReferral,
      counsyl: CounsylReferral,
    };
    return rf[this.props.params.partner];
  }

  onSubmitAccountInformation(values) {
    const { dispatch, gotoClientInfo } = this.props;
    if (api.hasToken()) {
      return Promise.resolve(gotoClientInfo());
    }
    return loaderSession(dispatch, submitAccount(values, dispatch).then(() => gotoClientInfo()));
  }

  onSubmitClientInformation(values) {
    const { gotoPaymentInfo, gotoAlmostDone } = this.props;
    const { clientData } = this.props.registrationWizard;
    const zeroPrice = this.zeroPrice();
    if (clientData && clientData.phone && clientData.firstName) {
      return Promise.resolve(zeroPrice ? gotoAlmostDone() : gotoPaymentInfo()).then(() => {
        track(TR_WEBINAR_PERSONAL_INFO_SUBMITTED);
      });
    }
    // to check if webinar is still available
    return this.isRegistrationOpen().then(() => this.submitClientInformation(values, zeroPrice));
  }

  submitClientInformation(values, zeroPrice = false) {
    const { dispatch, webinar, params, bookWebinar } = this.props;
    return loaderSession(
      dispatch,
      submitClientInformation(values, dispatch)
        .then(() => (zeroPrice ? bookWebinar(webinar.data[params.partner].id) : Promise.resolve()))
        .then(() =>
          dispatch(
            zeroPrice ? setStage(STAGE_WEBINAR_ALMOST_DONE) : setStage(STAGE_WEBINAR_PAYMENT_INFO)
          )
        )
    );
  }

  onSubmitPaymentInformation(values) {
    const { initializePaymentForm } = this.props;
    const { paymentData } = this.props.registrationWizard;
    // We have to reinitialize the form to hide credit card number after submit
    return this.isRegistrationOpen().then(() => {
      return this.submitPaymentInformation(values).then(() => {
        track(TR_WEBINAR_BILLING_INFO_SUBMITTED);
        initializePaymentForm(paymentData);
      });
    });
  }

  submitPaymentInformation(values) {
    const { dispatch, webinar, gotoAlmostDone, bookWebinar } = this.props;
    return loaderSession(
      dispatch,
      submitPaymentInfo(values, dispatch)
        .then(() => bookWebinar(webinar.data.id))
        .then(() => gotoAlmostDone())
    );
  }

  getWebinarPrice() {
    const { partners, params } = this.props;
    return partners.data
      ? _(partners.data).find(p => p.name.toLowerCase() === params.partner.toLowerCase()).price
      : null;
  }

  zeroPrice() {
    return this.getWebinarPrice() === 0.0;
  }

  setStateFromProps = () => {
    const { currentUser, webinar } = this.props;
    const hasUser = !!currentUser;
    const hasWebinar = !!webinar.data;
    this.setState({
      hasUser,
      hasWebinar,
    });
  };

  goStageForDroppedUser = () => {
    const zeroPrice = this.zeroPrice();
    const { currentUser, gotoClientInfo, gotoPaymentInfo, gotoAlmostDone, webinar } = this.props;
    if (currentUser && currentUser.referral && !currentUser.confirmed) {
      if (!currentUser.firstName) gotoClientInfo();
      else if (webinar && !zeroPrice && !currentUser.hasPaymentMethod) gotoPaymentInfo();
      else if (webinar && (zeroPrice || currentUser.hasPaymentMethod)) gotoAlmostDone();
    }
  };

  goToWebinarGuide = () => {
    browserHistory.push('/webinar');
  };

  renderStage() {
    const { gotoClientInfo, registrationWizard } = this.props;
    const { activeStage, formError, clientData, paymentData } = registrationWizard;
    switch (activeStage) {
      case STAGE_WEBINAR_PAYMENT_INFO:
        return (
          <PaymentInformationForm
            onSubmit={this.onSubmitPaymentInformation.bind(this)}
            toPreviousStep={() => gotoClientInfo()}
            formError={formError}
            initialValues={paymentData}
            customTitle={'Thank you for registering for the webinar.'}
            customDescription={'Please provide your credit card information below.'}
            isWebinar={true}
          />
        );
      case STAGE_WEBINAR_ALMOST_DONE: {
        const { webinar, user, appointment } = this.props;
        const price = this.getWebinarPrice();
        if (!webinar.loaded) {
          return null;
        }
        const fakeAppointment = !_.isEmpty(webinar.data)
          ? prepareDataForConfirmEmailPage(webinar.data, price)
          : null;
        return (
          <ThanksForSchedulingForm
            isWebinar={true}
            appointment={fakeAppointment}
            onSubmit={this.goToWebinarGuide}
            calendars={appointment.calendars}
            newPrice={price}
            isPackage={false}
            requestEmailSended={user.requestEmailSended}
          />
        );
      }
      case STAGE_WEBINAR_CLIENT_INFO:
        return (
          <ClientInformationForm
            onSubmit={this.onSubmitClientInformation.bind(this)}
            formError={formError}
            initialValues={clientData}
            customDescription="We will only use this information to communicate with you about this program. We will never share it with third parties or send you spam. All fields are required."
          />
        );
      case CREATE_ACCOUNT:
      default:
        if (this.props.currentUser) return null;
        return (
          <SimpleSignUpForm
            onSubmit={this.onSubmitAccountInformation.bind(this)}
            formError={formError}
            referral={this.getPartnerReferralSource()}
          />
        );
    }
  }

  render() {
    const { webinar, params } = this.props;
    const { activeStage } = this.props.registrationWizard;
    const workflow = this.zeroPrice()
      ? webinarScheduling.filter(o => o.id !== STAGE_WEBINAR_PAYMENT_INFO)
      : webinarScheduling;
    return (
      <WebinarNotAvailableHOC
        enable={true}
        webinar={webinar.data}
        loadingWebinar={webinar.loading}
        onCloseModal={() => {
          window.location = '/' + params.partner;
        }}
        className="scheduling-page full-height no-footer webinar-registration-wizard">
        <WizardProgress activeStage={activeStage} workflow={workflow} />
        {this.renderStage()}
      </WebinarNotAvailableHOC>
    );
  }
}

WebinarRegistrationWizardPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  partners: PropTypes.object.isRequired,
  webinar: PropTypes.object.isRequired,
  registrationWizard: PropTypes.object.isRequired,
  gotoClientInfo: PropTypes.func.isRequired,
  gotoPaymentInfo: PropTypes.func.isRequired,
  gotoAlmostDone: PropTypes.func.isRequired,
  initializePaymentForm: PropTypes.func.isRequired,
  clearEverything: PropTypes.func.isRequired,
  bookWebinar: PropTypes.func.isRequired,
  selectedService: PropTypes.string,
  formError: PropTypes.string,
  params: PropTypes.object,
  currentUser: PropTypes.object,
  appointment: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const { user, registrationWizard, appointment } = state;
  return {
    registrationWizard,
    user,
    appointment,
    currentUser: authService.getCurrentUser(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  gotoClientInfo: () => dispatch(setStage(STAGE_WEBINAR_CLIENT_INFO)),
  gotoPaymentInfo: () => dispatch(setStage(STAGE_WEBINAR_PAYMENT_INFO)),
  gotoAlmostDone: () => dispatch(setStage(STAGE_WEBINAR_ALMOST_DONE)),
  initializePaymentForm: () => dispatch(initializeForm(PAYMENT_FORM_NAME)),
  clearEverything: () => schedulingService.clearSchedulingData(),
  bookWebinar: partner => dispatch(bookWebinar(partner)),
});

const page = fetchHOC({
  partners: webinarService.getPartners.bind(webinarService),
  webinar: partnerName => webinarService.getPartnerCurrentWebinar(partnerName),
})(withRouter(WebinarRegistrationWizardPage));

export default connect(mapStateToProps, mapDispatchToProps)(page);
