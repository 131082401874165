import PropTypes from 'prop-types';
import React from 'react';

import './FilledCardBlock.scss';

const FilledCardBlock = ({
  paymentMethod: {
    cardBrand,
    cardNumberLast4,
    cardExpirationMonth,
    cardExpirationYear,
  },
  onUpdate
}) => {
  const date = `${('0' + cardExpirationMonth).slice(-2)}/${cardExpirationYear}`;

  return (
    <div className="scp-filled-card-block scp-filled-card-block-layout">
      <div className="credit-card-block credit-card-block-layout">
        <img className="brand-icon" data-brand={cardBrand} data-hj-suppress />
        <div className="first-block" data-hj-suppress>
          <div className="card-info">
            <span className="brand">{cardBrand}</span>{' '}
            <span className="ending">ending in {cardNumberLast4}</span>
          </div>
          <div className="expiry">
            <span className="expiry-value">{date}</span>
          </div>
        </div>
        <div className="second-block">
          <div className="gm-link update-btn" onClick={onUpdate}>
            Update
          </div>
        </div>
      </div>
      <div className="description-block">
        <h3>Credit or Debit Card</h3>
      </div>
    </div>
  );
};

FilledCardBlock.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default FilledCardBlock;
