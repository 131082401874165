import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router';
import warn from 'components/forms/PasswordStrength/warn';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import InputField from 'components/forms/controls/V2/InputField';
import CheckBoxField from 'components/forms/controls/V2/CheckBoxField';
import PhoneNumberField from 'components/forms/controls/V2/PhoneNumberField';
import {
  required,
  email,
  shouldBeTrue,
  matchEmail,
  phoneOrEmpty,
  patientName,
} from 'services/validate';
import './SoftRegistrationForm.scss';
import FullRegIllustration from './full-registration.png';
import Alert from 'components/widgets/Alert/Alert';
import gaTrack, { GA_TR_SOFTREGISTRATION_PAGE } from 'services/gaTrack';

class SoftRegistrationForm extends Component {
  componentDidMount() {
    gaTrack(GA_TR_SOFTREGISTRATION_PAGE);
  }
  static renderCheckbox({ input: { name, value, onChange }, label }) {
    return (
      <div className="gm-form-field gm-form-checkbox">
        <label className="gm-form-label">
          <input type="checkbox" name={name} checked={value} onChange={onChange} />
          {label}
        </label>
      </div>
    );
  }
  onSubmit = values => {
    return this.props.onSubmit(values);
  };
  render() {
    const { handleSubmit, submitting, valid, formError } = this.props;

    return (
      <section className="soft-rgstrtion-body">
        <div className="intro-video-wrapper">
          <img src={FullRegIllustration} alt="full-registration" className="intro-image" />
        </div>
        <div className="form-container">
          <i className="group-logo" />
          <h1>Tell us a little about yourself</h1>
          <span className="rgstrtion-desc">
            If you need to leave in the middle of the education videos, you will be able to use the
            information below to resume wherever you left off.
          </span>
          <form className="soft-registraction-form" onSubmit={handleSubmit(this.onSubmit)}>
            <div className="row-section">
              <div className="col-section col-width-50">
                <div className="label-section required">First Name</div>
                <div className="input-section">
                  <Field
                    name="firstName"
                    type="text"
                    placeholder="John"
                    trim={true}
                    component={InputField}
                    validate={[required('First Name'), patientName]}
                  />
                </div>
              </div>

              <div className="col-section col-width-50">
                <div className="label-section required">Last Name</div>
                <div className="input-section">
                  <Field
                    name="lastName"
                    type="text"
                    placeholder="Smith"
                    trim={true}
                    component={InputField}
                    validate={[required('Last Name'), patientName]}
                  />
                </div>
              </div>
            </div>

            <div className="row-section">
              <div className="col-section">
                <div className="label-section required">Email</div>
                <div className="input-section">
                  <Field
                    name="email"
                    type="email"
                    placeholder="email@email.com"
                    trim={true}
                    component={InputField}
                    validate={[required('Email'), email]}
                  />
                </div>
              </div>
            </div>

            <div className="row-section">
              <div className="col-section">
                <div className="label-section required">Confirm Email</div>
                <div className="input-section">
                  <Field
                    name="confirmEmail"
                    type="email"
                    placeholder="email@email.com"
                    trim={true}
                    component={InputField}
                    validate={[required('Confirm Email'), email]}
                  />
                </div>
              </div>
            </div>
            <div className="row-section">
              <div className="col-section">
                <div className="label-section required">Phone Number</div>
                <div className="input-section">
                  <Field
                    name="phone"
                    type="text"
                    placeholder="+1 (123) 456 - 7890"
                    trim={true}
                    component={PhoneNumberField}
                    mask="+1 (999) 999 - 9999"
                    maskChar={null}
                    validate={[phoneOrEmpty, required('Phone Number')]}
                  />
                </div>
              </div>
            </div>

            <div className="agree">
              <div className="field-section">
                <Field
                  name="agree"
                  type="checkbox"
                  label={
                    <Fragment>
                      <span className="checkmark" />
                      <div className="label-section">
                        I agree to Genome Medical’s{' '}
                        <Link className="privacy-link" href="/privacy" target="_blank">
                          Privacy Policy
                        </Link>{' '}
                        and {' '}
                        <Link className="privacy-link" href="/consent" target="_blank">
                          Informed Consent
                        </Link>
                      </div>{' '}
                    </Fragment>
                  }
                  component={CheckBoxField}
                  validate={shouldBeTrue}
                />
              </div>
            </div>
            {formError && <Alert message={formError} />}
            <ButtonV2 className="submit-button" type="submit" disabled={submitting || !valid}>
              Let's Get Started
            </ButtonV2>
          </form>
        </div>
      </section>
    );
  }
}

SoftRegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
};

export default reduxForm({
  form: 'SoftRegistration',
  validate: matchEmail,
  warn,
})(SoftRegistrationForm);
