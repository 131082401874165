const SURVEY_SCRIPT_LOAD_START = `gm/survey_script/SURVEY_SCRIPT_LOAD_START`;
const SURVEY_SCRIPT_LOAD_SUCCESS = `gm/survey_script/SURVEY_SCRIPT_LOAD_SUCCESS`;
const SURVEY_SCRIPT_LOAD_FAILURE = `gm/survey_script/SURVEY_SCRIPT_LOAD_FAILURE`;

export default (state = { loaded: false, loading: false, error: null }, action = {}) => {
  switch (action.type) {
    case SURVEY_SCRIPT_LOAD_START:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
      };

    case SURVEY_SCRIPT_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case SURVEY_SCRIPT_LOAD_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };

    default:
      return state;
  }
};

export function getSurveyFrontendBaseUrl() {
  if (process.env.GM_ENV === 'production') return 'https://surveys.genomemedical.com';
  if (process.env.GM_ENV === 'qa') return 'https://surveys.qa.genomemedical.com';
  if (process.env.GM_ENV === 'staging') return 'https://surveys.staging.genomemedical.com';
  return 'https://surveys.dev.genomemedical.com';
}

export const loadSurveyScript = () => async dispatch => {
  const surveyScript = document.createElement('script');
  const url = `${getSurveyFrontendBaseUrl()}/genome-survey.js`;
  surveyScript.setAttribute('src', url);
  surveyScript.onload = () => dispatch({ type: SURVEY_SCRIPT_LOAD_SUCCESS });
  surveyScript.onerror = error =>
    dispatch({
      type: SURVEY_SCRIPT_LOAD_SUCCESS,
      payload: error.message || error,
    });
  document.body.appendChild(surveyScript);
};
