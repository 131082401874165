export default {
  passwordAlreadySet: {
    status: 405,
    code: 'password_already_set',
  },
  NotFound: {
    status: 404,
    code: 'not_found',
  },
  ExpiredSendReset: {
    status: 403,
    code: 'expired_send_reset',
  },
  ExpiredReSendReset: {
    status: 403,
    code: 'expired_resend_set',
  },
  Expired: {
    status: 403,
    code: 'expired',
  },
};
