import PropTypes from 'prop-types';
import React from 'react';

class ModalCloseButton extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  render() {
    return <a onClick={this.props.onClose} className="ion-close-round modal-close-button" />;
  }
}

export default ModalCloseButton;
