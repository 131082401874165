import React from 'react';
import Alert from 'components/widgets/Alert/Alert';
import { connect } from 'react-redux';

import './ReleaseNotice.scss';

const ReleaseNotice = ({ showReleaseNotice }) => {
  return (
    showReleaseNotice && (
      <Alert
        type="info"
        fade={true}
        className="release-notice-banner"
        fadeOutTime={10 * 1000}
        message="Looks like the portal has been updated since your last visit. The page will automatically refresh and you will be able to continue your work in a moment."
      />
    )
  );
};

export default connect(({ appmeta }) => ({ showReleaseNotice: appmeta.showReleaseNotice }))(
  ReleaseNotice
);
