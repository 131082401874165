import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Link from 'components/widgets/Link/Link';
import WebinarForm from './WebinarForm';
import DeclineTesting from './DeclineTesting';
import ContactRequest from './ContactRequest';
import RecordNotAvailable from 'components/widgets/RecordNotAvailable/RecordNotAvailable';
import { sendPostAction, loadCurrentWebinar } from 'ducks/webinar';
import {
  POST_WEBINAR_TESTING,
  POST_WEBINAR_NO_TESTING,
  POST_WEBINAR_SCHEDULE_APPOINTMENT,
  POST_WEBINAR_ADDITIONAL_QUESTIONS,
} from 'services/api/webinarService';
import InfoModal from 'components/modals/InfoModal/InfoModal';
import track, {
  TR_POST_WEBINAR_CHOISE_NO_TESTING,
  TR_POST_WEBINAR_CHOISE_TESTING,
  TR_POST_WEBINAR_CHOISE_TALK,
} from 'services/track';
import { loaderSession } from 'ducks/ui';
import './PostWebinarOptions.scss';
import './PostOptionModal.scss';

class PostWebinarOptions extends Component {
  static propTypes = {
    sendPostAction: PropTypes.func.isRequired,
    webinar: PropTypes.object,
    loadCurrentWebinar: PropTypes.func.isRequired,
  };

  state = {
    form: false,
    declineShow: false,
    requestModal: false,
    noRecordingModal: false,
    infoDialogText: null,
    keepFormProgress: false,
    gotoPatientLayout: false,
    questionnaireConfirmationModal: false,
  };

  viewRecordCaption = 'View the webinar again';

  openForm = () => {
    this.setState({ form: true });
    track(TR_POST_WEBINAR_CHOISE_TESTING);
  };

  showDeclineTesting = () => {
    this.setState({ declineShow: true });
    track(TR_POST_WEBINAR_CHOISE_NO_TESTING);
  };
  hideDeclineTesting = () => this.setState({ declineShow: false });
  submitDeclineTesting = () =>
    this.props
      .sendPostAction(this.props.webinar.id, POST_WEBINAR_NO_TESTING)
      .then(() => this.setState({ gotoPatientLayout: true }))
      .then(() => this.sayThankYou());

  showRequestModal = () => {
    this.setState({ requestModal: true });
    track(TR_POST_WEBINAR_CHOISE_TALK);
  };
  hideRequestModal = () => this.setState({ requestModal: false });
  submitContactRequest = ({ action, extra }) =>
    this.props
      .sendPostAction(this.props.webinar.id, action, extra || undefined)
      .then(() => this.props.loadCurrentWebinar())
      .then(() => this.sayThankYou());

  showNoRecording = () => this.setState({ noRecordingModal: true });
  hideNoRecording = () => this.setState({ noRecordingModal: false });

  closeInfoDialog = () => {
    this.setState({ infoDialogText: null });
    if (this.state.gotoPatientLayout) window.location.href = '/patient';
  };

  showQuestionnaireConfirmation = () => this.setState({ questionnaireConfirmationModal: true });
  closeQuestionnaireConfirmation = () => this.setState({ questionnaireConfirmationModal: false });

  sayThankYou = () =>
    this.setState({
      requestModal: false,
      declineShow: false,
      questionnaireConfirmationModal: false,
      infoDialogText: 'Thank you for your submission',
    });

  finishWebinarForm = () =>
    this.props
      .sendPostAction(this.props.webinar.id, POST_WEBINAR_TESTING)
      .then(() => this.sayThankYou());

  backFromWebinarForm = () => this.setState({ form: false, keepFormProgress: true });

  isContactOptionHidden = () =>
    this.props.webinar.postAction === POST_WEBINAR_SCHEDULE_APPOINTMENT ||
    this.props.webinar.postAction === POST_WEBINAR_ADDITIONAL_QUESTIONS;

  renderInternal() {
    const { webinar: { recordingUrl, surveyUrl } } = this.props;
    let form = (this.state.form || this.state.keepFormProgress) && (
      <WebinarForm
        formUrl={surveyUrl}
        finishAction={this.showQuestionnaireConfirmation}
        backAction={this.backFromWebinarForm}
        isHidden={!this.state.form && this.state.keepFormProgress}
      />
    );
    return (
      <div>
        {form}
        {!this.state.form && (
          <div className="post-webinar">
            <h3 className="title">Thanks for completing the educational webinar.</h3>
            <p className="text">
              What would you like to do next? <br /> (please select one)
            </p>
            <div className="options-block">
              <div className="post-option" onClick={this.openForm}>
                <div className="option-text">I'd like to pursue genetic testing</div>
              </div>
              <div className="post-option" onClick={this.showDeclineTesting}>
                <div className="option-text">
                  I've decided that I do not want genetic testing and/or I do not want to continue
                  in this program
                </div>
              </div>
              {!this.isContactOptionHidden() && (
                <div className="post-option" onClick={this.showRequestModal}>
                  <div className="option-text">
                    I'd like more information and/or schedule an appointment with a genetic
                    counselor
                  </div>
                </div>
              )}
            </div>
            <h3 className="title">Not sure yet?</h3>
            <p className="text">
              No problem. When you're ready to take your next step, log back into your Genome
              Medical account and you will be taken back to this page.
            </p>
            <Link
              to={recordingUrl}
              onClick={this.showNoRecording}
              className="text view-again"
              blank>
              {this.viewRecordCaption}
            </Link>
            <DeclineTesting
              show={this.state.declineShow}
              onHide={this.hideDeclineTesting}
              onSubmit={this.submitDeclineTesting}
            />

            <ContactRequest
              show={this.state.requestModal}
              onHide={this.hideRequestModal}
              onSubmit={this.submitContactRequest}
            />
            <RecordNotAvailable show={this.state.noRecordingModal} onHide={this.hideNoRecording} />
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderInternal()}
        {this.state.infoDialogText && (
          <InfoModal title={this.state.infoDialogText} submitCallback={this.closeInfoDialog} />
        )}
        {this.state.questionnaireConfirmationModal && (
          <InfoModal
            title="Please Confirm"
            description={
              <p>
                Before you continue... Did you submit your questionnaire?<br />
                You will not be able to change your answers once you continue.
              </p>
            }
            submitText="Yes, I've submitted my questionnaire"
            submitCallback={this.finishWebinarForm}
            cancelText="Cancel"
            cancelCallback={this.closeQuestionnaireConfirmation}
          />
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    webinar: state.webinar.data,
  }),
  dispatch => ({
    sendPostAction: (id, action, extra) => dispatch(sendPostAction(id, action, extra)),
    loadCurrentWebinar: () => loaderSession(dispatch, dispatch(loadCurrentWebinar())),
  })
)(PostWebinarOptions);
