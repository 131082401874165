import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getCardImage } from '../../../../../services/utils';
import arrowImage from '../../../../../images/common/chevron-large.svg';
import './ProfileSelection.scss';

class ProfileSelection extends Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.array,
    activeStepIndex: PropTypes.number,
    onClickHandler: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    const { onClickHandler, children, className } = this.props;
    return (
      <div className={`profile-selection-container ${className}`}>
        <div className="profile-selection">
          <h3 className="title">Profile</h3>
          <div className="profile-selection-links">
            {(children || []).map((child, i) => (
              <div className="header">
                <div
                  className="header-content"
                  key={`profile-menu-${i}`}
                  onClick={() => onClickHandler(i)}>
                  <span className="text-icon">
                    <span className="icon">
                      <img src={getCardImage(child.icon)} alt="" className="arrow-image" />
                    </span>
                    <span className="text">{child.header}</span>
                  </span>
                  <span className="left-icon">
                    <img src={arrowImage} alt="" className="arrow-image" />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileSelection;
