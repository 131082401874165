import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import 'components/forms/WizardForm/CreateAccountForm/ABNForm.scss';
import ABNForm from './ABNForm';
import gaTrack, { GA_TR_ABN_PAGE } from '../../../../services/gaTrack';
class ABNPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    gaTrack(GA_TR_ABN_PAGE);
  }

  render() {
    const { handleSubmit, onSubmit, fullName, guardianFullName } = this.props;
    return (
      <section className="abn-container">
        <section className="abn-wrapper">
          <section className="abn-header">
            <div className="genome-header-logo">
              <img className="genome-header-img" src={require('images/logo.svg')} />
            </div>
          </section>
          <form
            className="abn-form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            noValidate>
            <ABNForm {...this.props} fullName={fullName} guardianFullName={guardianFullName} />
          </form>
        </section>
      </section>
    );
  }
}

ABNPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
};

export default reduxForm({
  form: 'consentForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ABNPage);
