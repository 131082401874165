//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

export function determineTopDropShadow(bodyElement) {
  if (!bodyElement) return false;
  if ((get(bodyElement, 'scrollHeight', 0) || 0) > (get(bodyElement, 'clientHeight', 0) || 0)) {
    if ((get(bodyElement, 'scrollTop', 0) || 0) > 0) return true;
    else {
      return false;
    }
  } else {
    return false;
  }
}

export function determineBottomDropShadow(bodyElement) {
  if (!bodyElement) return false;
  if ((get(bodyElement, 'scrollHeight', 0) || 0) > (get(bodyElement, 'clientHeight', 0) || 0)) {
    if (
      (get(bodyElement, 'scrollTop', 0) || 0) + (get(bodyElement, 'clientHeight', 0) || 0) !==
      (get(bodyElement, 'scrollHeight', 0) || 0)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
//Components
import SingleSelectTitleRow from './SingleSelectTitleRow.js';
import SingleSelectBody from './SingleSelectBody.js';
import SingleSelectButtons from './SingleSelectButtons.js';

class SingleSelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      internalValue: undefined,
      bodyElement: null,
      topDropShadow: false,
      bottomDropShadow: false,
    };
    //bindings
    this.bodyClickHandler = this.bodyClickHandler.bind(this);
    this.setInternalValue = this.setInternalValue.bind(this);
    this.resetInternalValue = this.resetInternalValue.bind(this);
    this.setBodyElement = this.setBodyElement.bind(this);
    this.determineDropShadow = _.debounce(this.determineDropShadow.bind(this), 100);
    this.saveValue = this.saveValue.bind(this);
    this.canSaveValue = this.canSaveValue.bind(this);
  }
  //functions

  componentDidMount() {
    if (get(this, 'modalElement', null)) {
      document
        .querySelector('body')
        .addEventListener('click', this.bodyClickHandler, { capture: true });
    }
  }

  componentWillUnmount() {
    if (get(this, 'modalElement', null)) {
      document.querySelector('body').removeEventListener('click', this.bodyClickHandler);
    }
  }

  bodyClickHandler(e) {
    let currentNode = e.target;
    let pillElement = get(this, 'props.pillElement', null);
    let modalElement = get(this, 'modalElement', null);
    if (modalElement && pillElement) {
      if (!modalElement.contains(currentNode) && !pillElement.contains(currentNode)) {
        this.props.closeModal();
      }
    }
  }

  setBodyElement(el) {
    if (el) this.setState({ bodyElement: el }, () => this.determineDropShadow(['top', 'bottom']));
  }

  determineDropShadow(sides) {
    if (Array.isArray(sides) && get(this, 'state.bodyElement', null)) {
      sides.forEach(side => {
        let shadow;
        switch (side) {
          case 'top':
            shadow = determineTopDropShadow(get(this, 'state.bodyElement', null));
            this.setState({ topDropShadow: shadow });
            break;
          case 'bottom':
            shadow = determineBottomDropShadow(get(this, 'state.bodyElement', null));
            this.setState({ bottomDropShadow: shadow });
            break;
          default:
            console.log('Unrecognized modal side.');
            break;
        }
      });
    }
  }

  setInternalValue(e) {
    this.setState({ internalValue: e });
  }

  resetInternalValue() {
    this.setState({ internalValue: get(this, 'props.defaultValue', undefined) });
  }

  saveValue() {
    if (this.canSaveValue()) {
      this.props.setStateFunc(get(this, 'state.internalValue', null));
      this.props.closeModal();
    }
  }

  canSaveValue() {
    return get(this, 'props.hasSetStateFunc', false) && this.state.internalValue !== undefined;
  }

  render() {
    return (
      <div className="singleSelectModalContainer" ref={el => (this.modalElement = el)}>
        <SingleSelectTitleRow
          title={get(this, 'props.title', '')}
          closeModal={this.props.closeModal}
          shouldHaveDropShadow={get(this, 'state.topDropShadow', false)}
        />
        <SingleSelectBody
          data={get(this, 'props.data', []) || []}
          internalValue={this.state.internalValue}
          setInternalValue={this.setInternalValue}
          currentValue={get(this, 'props.currentValue', null)}
          setStateFunc={this.props.setStateFunc}
          hasSetStateFunc={get(this, 'props.hasSetStateFunc', false)}
          convertStrsToNums={get(this, 'props.convertStrsToNums', false)}
          setBodyElement={this.setBodyElement}
          scrollHandler={this.determineDropShadow}
        />
        <SingleSelectButtons
          resetInternalValue={this.resetInternalValue}
          saveValue={this.saveValue}
          canSaveValue={this.canSaveValue()}
          shouldHaveDropShadow={get(this, 'state.bottomDropShadow', false)}
        />
      </div>
    );
  }
}

export default SingleSelectModal;
