import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Select from 'react-select';

class CustomComboboxwithIcon extends Component {
  handleChange = ({ value }) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      items,
      placeholder,
      className,
      label,
      disabled,
      onIconClick,
      input: { value },
      meta: { touched, error },
    } = this.props;
    const options = items.map(o => (o.label ? o : { value: o.value, label: o.value }));
    return (
      <div className={classnames('gm-form-field', className, { error: touched && error })}>
        {label.length > 0 && <label className="gm-form-label">{(touched && error) || label}</label>}
        <span className="radio-button-description" onClick={onIconClick}>
          <span variant="secondary">
            <span className="info-outline-icon" />
          </span>
        </span>
        <div className="gm-form-control">
          <Select
            className="gm-select"
            value={value}
            options={options}
            onChange={this.handleChange}
            placeholder={placeholder}
            clearable={false}
            disabled={disabled}
            searchable={false}
            matchPos="start"
          />
        </div>
      </div>
    );
  }
}

CustomComboboxwithIcon.propTypes = {
  items: PropTypes.array,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CustomComboboxwithIcon;
