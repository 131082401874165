import React, { Fragment } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './DateField.scss';
import moment from 'moment';
const DateField = ({
  input: { value, name, onChange, onBlur },
  meta: { touched, error },
  extraclassName,
  placeholder,
  disabledDate,
}) => {
  const iserror = touched && error && !_.isNil(error) && !_.isEmpty(error);
  const currentClassName = classnames(
    'datefield-wrapper',
    iserror ? 'input-error-field' : 'input-field',
    extraclassName
  );
  const normalizevalue = moment(value, 'YYYY-MM-DD', true).isValid()
    ? moment(value, 'YYYY-MM-DD', true)
    : value;
  return (
    <Fragment>
      <div className={currentClassName}>
        <DateTime
          name={name}
          value={normalizevalue}
          onChange={val => {
            onChange(moment.isMoment(val) ? val.format('YYYY-MM-DD') : val);
          }}
          dateFormat={'MM/DD/YYYY'}
          timeFormat={false}
          closeOnTab={true}
          closeOnSelect={true}
          strictParsing={true}
          inputProps={{ placeholder: placeholder, autoComplete: 'no' }}
          isValidDate={disabledDate}
          onBlur={val => {
            onBlur(moment.isMoment(val) ? val.format('YYYY-MM-DD') : val);
          }}
        />
        <div className="input-error">{iserror ? error : ''}</div>
      </div>
    </Fragment>
  );
};

export default DateField;
