import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { hiddenCoupons } from 'services/api/schedulingService';
import { submitCouponCode, setSessionCouponCode, clearCoupon, getNewPrice } from 'ducks/scheduling';

/**
 * This HOC component is a wrapper for other components which interested in automatic coupons applying.
 * This class checks scheduling.sessionCouponCode and if it founds that this code is not applied -
 * it automatically applies it.
 *
 * scheduling.sessionCouponCode is set from sessionStorage for the first time
 * and then it can be changed by only scheduling.setSessionCouponCode function
 * or it can be cleared by scheduling.clearCouponCode function
 */

export function withCouponApplier(WrappedComponent) {
  class CouponApplier extends Component {
    state = {
      applicable: false,
    };

    componentDidMount() {
      if (this.shouldApplyCoupon(this.props)) {
        this.applyCoupon(this.props);
      }
    }

    componentDidUpdate() {
      this.applyCouponIfNeeded();
    }

    shouldApplyCoupon() {
      if (!this.props.scheduling) return false;
      const {
        sessionCouponCode,
        appliedCoupon,
        consultationTypes,
        servicePackages,
        couponError,
      } = this.props.scheduling;
      return Boolean(
        sessionCouponCode &&
          !couponError &&
          (_.isEmpty(appliedCoupon) || appliedCoupon.code !== sessionCouponCode) &&
          !_.isEmpty(consultationTypes) &&
          !_.isEmpty(servicePackages)
      );
    }

    applyCouponIfNeeded() {
      const applicable = this.shouldApplyCoupon();
      if (applicable !== this.state.applicable) {
        this.setState({ applicable }, () => {
          if (applicable) this.applyCoupon();
        });
      }
    }

    applyCoupon = () => {
      const { dispatch, scheduling: { isCheckingCouponCode, sessionCouponCode } } = this.props;
      if (!isCheckingCouponCode) {
        dispatch(submitCouponCode(sessionCouponCode, false));
      }
    };
    setCouponCode = (code, save) => this.props.dispatch(setSessionCouponCode(code, save));
    clearCoupon = () => this.props.dispatch(clearCoupon());

    render() {
      if (!this.props.scheduling) return null;
      const { appliedCoupon, couponError,readOnlyCouponCode } = this.props.scheduling;
      const hiddenCouponApplied = appliedCoupon && hiddenCoupons.includes(appliedCoupon.code);
      const clearCoupon = notForHidden =>
        notForHidden && hiddenCouponApplied ? () => {} : this.clearCoupon();
      /**
       * newPrice make sense only for child components with single price option, not for SelectServicePage
       */
      const newPrice = this.props.dispatch(getNewPrice());
      return (
        <WrappedComponent
          {...this.props}
          appliedCoupon={appliedCoupon}
          couponError={couponError}
          hiddenCouponApplied={hiddenCouponApplied}
          setCouponCode={this.setCouponCode}
          clearCoupon={clearCoupon}
          newPrice={newPrice}
          readOnlyCouponCode={readOnlyCouponCode}
        />
      );
    }
  }

  CouponApplier.propTypes = {
    scheduling: PropTypes.object,
    dispatch: PropTypes.func,
  };

  return CouponApplier;
}
