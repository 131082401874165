import PropTypes from 'prop-types';
import React from 'react';

import './FilledCardBlock.scss';
import holdingAptmntIcon from './holding-appointment.svg';

const FilledCardBlock = ({
  paymentMethod: {
    cardBrand,
    cardNumberLast4,
    cardholderName,
    cardExpirationMonth,
    cardExpirationYear,
  },
  onUpdate,
}) => {
  const date = `${('0' + cardExpirationMonth).slice(-2)}/${cardExpirationYear}`;
  return (
    <div className="filled-card-block filled-card-block-layout">
      <div className="credit-card-block credit-card-block-layout">
        <img className="brand-icon" data-brand={cardBrand} />
        <div className="first-block">
          <div className="card-info">
            <span className="brand">{cardBrand}</span>{' '}
            <span className="ending">ending in {cardNumberLast4}</span>
          </div>
          <div className="name">{cardholderName}</div>
        </div>
        <div className="second-block">
          <div className="expiry">
            <span className="expiry-text">Expiry date</span>{' '}
            <span className="expiry-value">{date}</span>
          </div>
          <div className="gm-link update-btn" onClick={onUpdate}>
            UPDATE
          </div>
        </div>
      </div>
      <div className="description-block">
        <h3>Credit or debit card</h3>
      </div>
      <section className="holding-aptmnt-container">
        <img src={holdingAptmntIcon} className="holding-aptmnt-wrapper" />
        <div className="holding-aptmnt-txt-wrapper">
          <h4>No charge until appointment is completed</h4>
          <p className="holding-aptmnt-text">
            To hold your appointment, please provide your credit card information. You will NOT be
            charged until you have completed your appointment. We request your credit or debit card
            issuer to place an authorization request or hold on your card for $0.50. The primary
            reason for an authorization request or hold is to validate the card you are using for
            payment. Please always check the amount of availability on your card in advance of
            paying by credit or debit card.
          </p>
        </div>
      </section>
    </div>
  );
};

FilledCardBlock.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default FilledCardBlock;
