import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './FancyPageFrame.scss';

export default function FancyPageFrame({ children,className }) {
  return (
    <div className={classnames("fancy-page-frame-V2",className)}>
      <div className="content-block">{children}</div>
    </div>
  );
}

FancyPageFrame.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
