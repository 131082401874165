import React, { Component } from 'react';
import { Field } from 'redux-form';
import InputField from 'components/forms/controls/V2/InputField';
import SelectField from 'components/forms/controls/V2/SelectField';

import { required, validPostalOrZipCode } from 'services/validate';
import { getCountriesOption, getCountryStates } from 'services/utils';


class BillingAddress extends Component {

    render() {
        const {
            handleChange,
            allowedCountries,
            countries,
            states,
            initialState,
            countryIsNonUS
        } = this.props;
        const StateLabel = 'State';
        const zipCodeLabel = 'Zip Code';

        return (
            <div className="bill-add-section">
                <div className="section-header">Billing Address</div>
                <div className="form-row">

                   {countryIsNonUS && <div className="form-col full-col">
                        <div className="label-section required">Country</div>
                        <Field
                            name="addressCountry"
                            component={SelectField}
                            placeholder="Select"
                            options={getCountriesOption(countries, ['US'])}//TODO
                            validate={[required('Country')]}
                            onChange={(e, value) => handleChange(value, 'addressCountry')}
                        />
                    </div>
    }
                </div>
                <div className="form-row">
                    <div className="form-col full-col">
                        <div className="label-section required">Address Line 1</div>
                        <Field
                            name="addressLine1"
                            placeholder="123 Main Street"
                            type="text"
                            component={InputField}
                            validate={[required('Address Line 1')]}
                            onChange={(e, value) => handleChange(value, 'addressLine1')}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-col full-col">
                        <div className="label-section">Address Line 2</div>
                        <Field
                            name="addressLine2"
                            placeholder="Apt 123"
                            type="text"
                            component={InputField}
                            onChange={(e, value) => handleChange(value, 'addressLine2')}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-col city-col">
                        <div className="label-section required">City</div>
                        <Field
                            name="addressCity"
                            type="text"
                            placeholder="Enter"
                            component={InputField}
                            validate={[required('City')]}
                            onChange={(e, value) => handleChange(value, 'addressCity')}
                        />
                    </div>
                    <div className="form-col state-col">
                        <div className="label-section required">{StateLabel}</div>
                        <Field
                            name="addressState"
                            component={SelectField}
                            placeholder="Select"
                            options={getCountryStates(states, initialState.addressCountry, "code", "code")}
                            validate={[required(StateLabel)]}
                            onChange={(e, value) => handleChange(value, 'addressState')}
                            typeahead={true}
                            clearable={true}
                        />
                    </div>
                    <div className="form-col zip-col">
                        <div className="label-section required">{zipCodeLabel}</div>
                        <Field
                            name="addressZipCode"
                            type="text"
                            placeholder="Enter"
                            component={InputField}
                            label="Zipcode"
                            validate={[required(zipCodeLabel), validPostalOrZipCode(zipCodeLabel, 'US')]}
                            onChange={(e, value) => handleChange(value, 'addressZipCode')}
                        />
                    </div>
                </div>
            </div>

        );
    }
}



export default BillingAddress