import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { getConsultationDuration } from 'services/utils';
import Button from 'components/forms/controls/ButtonV2';
import CalendarDay from 'components/widgets/CalendarDay/CalendarDay';
import { getDateFromStr } from 'services/api/transformers/transformersUtils';
import ButtonQuestionnaire from './ButtonQuestionnaire';
import ThreeDotsMenu from 'components/ThreeDotsMenu/ThreeDotsMenu';
import './AppointmentInfoForMobile.scss';

class AppointmentInfoForMobile extends Component {
  state = {
    imageLoadingFailed: false,
  };

  componenDidMount() {}

  componentDidUpdate() {}

  get formattedDate() {
    const { start } = this.props.appointment;
    const formattedDate = moment(start).format('dddd, MMMM D, YYYY');

    return `${formattedDate}`;
  }

  get formattedTime() {
    const { start, timezone } = this.props.appointment;
    const formattedTimeZone = moment()
      .tz(timezone)
      .format('z');
    const formattedStart = moment(start).format('h:mmA');
      return `${formattedStart + ' '} ${formattedTimeZone}`;
  }

  isAppointmentWithin24Hrs = () => {
    const { start, timezone } = this.props.appointment;
    const starttimeOfTimezone = moment.tz(moment(start).format('YYYY-MM-DDTHH:mm:ss'), timezone);
    const currenttimeOfTimezone = moment(new Date()).tz(timezone);
    const duration = moment.duration(starttimeOfTimezone.diff(currenttimeOfTimezone));

    return duration.asHours() <= 24;
  };

  handleImageLoadingError = () => {
    this.setState({ imageLoadingFailed: true });
  };

  getConsultationDurationTime = appointment => {
    const consultation = _.get(appointment, 'consultation', '');
    const duration = getConsultationDuration(consultation);
    return `${duration} Minutes`;
  };

  gotoQuestionnaire = () => {
    browserHistory.push('/patient/questionnaire');
  };

  gotoVideoInstruction = () => {
    this.props.gotoVideoInstruction();
  };

  switchToAptByPhone = () => {
    this.props.openPhoneAppointmentModal();
  };

  switchToAptByVideo = () => {
    this.props.openVideoAppointmentModal();
  };

  rescheduleAppointment = () => {
    this.props.onReschedule();
  };

  cancelAppointment = () => {
    this.props.onCancel();
  };

  showThreeDotMenu = () => {
    const { canSwitchToPhone, isChangeable, canSwitchToVideo } = this.props;

    if (isChangeable || canSwitchToPhone || canSwitchToVideo) {
      return true;
    }
    return false;
  };

  threeDotMenuOptions = () => {
    const { canSwitchToPhone, isChangeable, canSwitchToVideo,user: { me } } = this.props;
    const { isPhoneOnly } = me;

    let options = [];

    if (isChangeable) {
      options.push(
        { click: () => this.rescheduleAppointment(), label: 'Reschedule' },
        { click: () => this.cancelAppointment(), label: 'Cancel Appointment' }
      );
    }
    if (canSwitchToPhone) {
      options.push({ click: () => this.switchToAptByPhone(), label: 'Switch to appt by phone' });
    }
    if (canSwitchToVideo && !isPhoneOnly) {
      options.push({ click: () => this.switchToAptByVideo(), label: 'Switch to appt by video' });
    }
    return options;
  };

  render() {
    const { user: { me }, appointment, canSwitchToPhone } = this.props;

    const { imageLoadingFailed } = this.state;
    const upcoming = !_.isEmpty(appointment) && appointment;
    const isLoading = _.isEmpty(upcoming) || _.isEmpty(me);
    const providerFullName =
      _.get(appointment, 'provider.full_name', '') || _.get(appointment, 'provider.fullName', '');
    const photo = _.get(appointment, 'provider.photoSmall', '');

    if (isLoading) return null;

    return (
      <Fragment>
        <div className="appointment-info-wrapper">
          <section
            className={`confirm-time-container ${this.isAppointmentWithin24Hrs()
              ? 'appointment-within-24Hrs'
              : 'appointment-after-24Hrs'}`}>
            <span className="appointment-day">{this.formattedDate}</span>
            <span className="appointment-time">{this.formattedTime}</span>
          </section>
          <section className="appointment-type-container">
            <div className="consultation-container">
              <span className="consultation-name">
                {_.get(appointment, 'consultation.name', '')}
              </span>
              <span className="consultation-duration">
                {this.getConsultationDurationTime(appointment)}
              </span>
            </div>
            {this.showThreeDotMenu() && (
              <div className="appointment-change-menu-container">
                <ThreeDotsMenu options={this.threeDotMenuOptions()} />
              </div>
            )}
          </section>
          <section className="genetic-counselor-container">
            {imageLoadingFailed ? (
              _.get(appointment, 'startTime', '') ? (
                <CalendarDay
                  date={getDateFromStr(_.get(appointment, 'startTime', ''))}
                  className="photo cal-day"
                />
              ) : null
            ) : (
              <img className="counselor-photo" src={photo} onError={this.handleImageLoadingError} />
            )}
            <div className="counselor-name-container">
              <span className="label-counselor">Your Certified Genetic Counselor</span>
              <span className="counselor-name">{providerFullName}</span>
            </div>
          </section>
        </div>

        <ButtonQuestionnaire />
        {canSwitchToPhone && (
          <Button className="btn-video-instructions" onClick={this.gotoVideoInstruction}>
            View Video Instructions
          </Button>
        )}

        <div className="scp-cancellation-policy-section">
          <div className="cancellation-policy-header">Cancellation Policy</div>
          <div className="cancellation-policy-body">
            Please call <a href="tel:+18776880992">(877) 688-0992</a> or email{' '}
            <a href="mailto: clinical@genomemedical.com"> clinical@genomemedical.com</a> at least 24
            hours prior to your scheduled appointment to notify us of any changes or cancellations.
          </div>
        </div>
      </Fragment>
    );
  }
}

AppointmentInfoForMobile.propTypes = {
  user: PropTypes.object.isRequired,
  appointment: PropTypes.object.isRequired,
  gotoVideoInstruction: PropTypes.func.isRequired,
  openPhoneAppointmentModal: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  appointmentType: PropTypes.string,
  canSwitchToPhone: PropTypes.bool,
  isChangeable: PropTypes.bool,
};

export default AppointmentInfoForMobile;
