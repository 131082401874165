class ExtendableError extends Error {
  constructor(errorData) {
    super(errorData.message || errorData);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this);
    } else {
      this.stack = new Error().stack;
    }
  }
}

export class ResponseError extends ExtendableError {
  constructor(errorData) {
    super(errorData);
    this.name = 'ResponseError';
    this.fullMessage = errorData.fullMessage;
    if (errorData.status) {
      this.status = errorData.status;
      this.data = errorData.data;
      this.fields = errorData.fields;
      this.code = errorData.code;
      this.rawFields = errorData.rawFields;
    }
  }
}
