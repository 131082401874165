import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'components/modals/ModalDialog/ModalDialog';
import Button from 'components/forms/controls/Button';
import './RecordNotAvailable.scss';

const RecordNotAvailable = ({ show, onHide }) => {
  return (
    <Modal className="md850 no-record" show={show} onHide={onHide}>
      <div className="no-record-body">
        <div className="no-record-title">Webinar recording is not ready or not recorded yet</div>
        <div className="no-record-desc">Please check later.</div>
        <Button className="no-record-button" onClick={onHide}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

RecordNotAvailable.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default RecordNotAvailable;
