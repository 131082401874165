import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { CREATE_ACCOUNT } from 'services/wizardWorkflows';
import './WizardProgress.scss';

const WizardProgress = ({ activeStage, workflow, className }) => {
  const stages = _.reduce(
    workflow,
    (result, o, i) => {
      result[o.id] = i;
      return result;
    },
    {}
  );
  const activeStageIndex = activeStage ? stages[activeStage] : 0;
  return (
    <div
      className={classnames('registration-wizard-progress', className, {
        hidden: workflow[activeStageIndex].id === CREATE_ACCOUNT,
      })}>
      <h2>{workflow[activeStageIndex].name}</h2>
      <ul>
        {workflow.filter(o => !o.noProgress).map((o, i) => (
          <li
            className={classnames({
              active: stages[o.id] <= activeStageIndex,
              done: stages[o.id] < activeStageIndex,
            })}
            key={i}>
            <i className="icon ion-checkmark" />
            {o.name}
            <div className="triangle" />
            <div className="triangle triangle-white" />
          </li>
        ))}
      </ul>
    </div>
  );
};

WizardProgress.propTypes = {
  activeStage: PropTypes.string,
  workflow: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

export default WizardProgress;
