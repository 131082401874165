import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';

export default class ReCaptcha extends Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    horisontal: PropTypes.bool,
    className: PropTypes.string,
    bottomError: PropTypes.bool,
  };

  onChange = value => {
    this.props.input.onChange(value);
  };

  render() {
    const { horisontal, label, className, meta: { touched, error }, bottomError } = this.props;
    return (
      <div
        className={classnames('gm-form-field', className, {
          horisontal,
          error: !bottomError && touched && error,
        })}>
        <div className={classnames('gm-form-label', { unstyled: horisontal })}>{label}</div>
        <div className="gm-form-control">
          <ReCAPTCHA sitekey="6Ler2WwUAAAAALUVtRP2bMHC4YjhfD_Gkw3Fv6XD" onChange={this.onChange} />
          {bottomError &&
            touched &&
            error && <label className="gm-form-label label-bottom error-label">{error}</label>}
        </div>
      </div>
    );
  }
}
