import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import './ButtonsGroup.scss';

const buttonAnswers = {
  yn: [{ header: 'Yes' }, { header: 'No' }],
  ynq: [{ header: 'Yes' }, { header: 'No' }, { header: 'I’m not sure' }],
  yndk: [{ header: 'Yes' }, { header: 'No' }, { header: "I don't know" }],
  gender: [{ header: 'Male' }, { header: 'Female' }, { header: 'Other' }],
};

class ButtonsGroup extends Component {
  handleChange = e => this.props.input.onChange(e.target.innerText);

  render() {
    const {
      className,
      label,
      input: { value },
      meta: { touched, error },
      disabled,
      answersType,
    } = this.props;

    const btns = (buttonAnswers[answersType] || buttonAnswers.ynq).map((item, i) => {
      return (
        <div key={i++} className="btn-group" role="group" disabled={disabled}>
          <button
            type="button"
            className={classnames('btn', 'btn-default', {
              'btn-group-active': item.header == value,
            })}>
            {item.header}
          </button>
        </div>
      );
    });

    return (
      <div className={classnames('gm-form-field', className, { error: touched && error })}>
        <label className="gm-form-label">{(touched && error) || label}</label>
        <div
          className={classnames(
            'gm-form-control',
            'btn-group',
            'btn-group-justified',
            'btn-group-panel'
          )}
          role="group"
          aria-label="..."
          onClick={this.handleChange}>
          {btns}
        </div>
      </div>
    );
  }
}

ButtonsGroup.propTypes = {
  input: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  answersType: PropTypes.string,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ButtonsGroup;
