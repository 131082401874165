import PropTypes from 'prop-types';
import React, { Component } from 'react';

import fetchHOC from 'components/high-order-components/FetchHOC';
import webinarService from 'services/api/webinarService';
import SimpleWebinarForm from '../SimpleWebinarForm/SimpleWebinarForm';
import SimpleWebinarMessage from '../SimpleWebinarMessage/SimpleWebinarMessage';
import FancyPageFrame from 'components/widgets/FancyPageFrame/FancyPageFrame';
import Header from 'components/header/Header';
import './SimpleWebinarPage.scss';
import gaTrack, { GA_TR_ACCESS_YOUR_GENOME_PAGE } from '../../../../../services/gaTrack';

class SimpleWebinarPage extends Component {
  static propTypes = {
    webinar: PropTypes.object.isRequired,
  };

  state = { isRegistered: false };

  componentDidMount() {
    this.props.webinar.fetch();
    gaTrack(GA_TR_ACCESS_YOUR_GENOME_PAGE);
  }

  onRegister = () => {
    this.setState({ isRegistered: true });
  };

  renderCustomContent = () => {
    const { data } = this.props.webinar;
    if (this.state.isRegistered)
      return <SimpleWebinarMessage iconClass="success" title="You are successfully registered" />;
    else if (data && data.requestError)
      return <SimpleWebinarMessage iconClass="error" title={data.requestError} />;
    else if (
      data &&
      (data.registrationClosed ||
        (data.maxNumberOfAttendees > 0 && data.numberOfAttendees >= data.maxNumberOfAttendees))
    )
      return (
        <SimpleWebinarMessage
          iconClass="closed"
          title="Registration is full"
          subtitle={
            <div>
              We are sorry. <br />
              The registration for the "Access your Genome program" is currently full.
            </div>
          }
        />
      );
    return null;
  };

  render() {
    const { data, loaded } = this.props.webinar;
    return (
      <div className="simple-webinar">
        <Header onlyLogo={true} customLogoLink="https://www.genomemedical.com/programs/invitae/" />
        <FancyPageFrame title="Register for the access your genome program">
          {loaded && (
            <div className="sw-body">
              {this.renderCustomContent() || (
                <div className="sw-register-form">
                  <SimpleWebinarForm webinarId={data.id} onRegister={this.onRegister} />
                </div>
              )}
            </div>
          )}
        </FancyPageFrame>
      </div>
    );
  }
}

export default partner =>
  fetchHOC({
    webinar: () =>
      webinarService.getPartnerCurrentWebinar(partner).catch(e => {
        return { requestError: (e && e.message) || 'Something went wrong.' };
      }),
  })(SimpleWebinarPage);
