import React, { Component } from 'react';
import { get } from 'lodash';
import { Button, Popover } from 'gm-element-react';
import './ThreeDotsMenu.scss';
import threeDotsImage from 'images/common/three-dots.svg'; //eslint-disable-line

export default class ThreeDotsMenu extends Component {
  state = {
    optionsVisible: false,
  };

  componentDidMount() {
    document
      .querySelector('body')
      .addEventListener('click', this.bodyClickHandler.bind(this), { capture: true });
  }

  componentWillUnmount() {
    document
      .querySelector('body')
      .removeEventListener('click', this.bodyClickHandler.bind(this), { capture: true });
  }

  bodyClickHandler(e) {
    const currentNode = e.target;
    if (this.element && !this.element.contains(currentNode))
      this.setState({ optionsVisible: false });
  }

  toggleVisibility(event) {
    event.stopPropagation();
    this.setState({
      optionsVisible: !this.state.optionsVisible,
    });
  }

  render() {
    const options = get(this, ['props', 'options'], []);
    const containerClassName = 'three-dots-wrapper';

    return (
      <div
        className={containerClassName}
        onClick={event => this.toggleVisibility(event)}
        ref={el => (this.element = el)}>
        <Popover
          visibleArrow={false}
          placement="bottom"
          trigger="manual"
          content={
            options.length ? (
              <ul className="three-dots-menu">
                {options.map(
                  (e, i) =>
                    e && (
                      <li onClick={e.click} key={i}>
                        {e.label}
                      </li>
                    )
                )}
              </ul>
            ) : null
          }
          visible={this.state.optionsVisible}>
          <Button>
            <img src={threeDotsImage} alt="" className="threedotaction" />
          </Button>
        </Popover>
      </div>
    );
  }
}
