import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import CheckBoxField from '../../forms/controls/V2/CheckBoxField';

const CheckboxGroup = props => {
  const { field, onChange = () => {}, options, parentField, validators } = props;

  const parentStr = parentField ? parentField + '.' : '';

  const getCheckboxes = ({ field, options }) => {
    return (
      <Fragment>
        {options.map(o => {
          const { label, value } = o;

          return (
            <div className="profile-page-checkbox" key={`${field}-option-${value}`}>
              <Field
                name={parentStr + value}
                type="checkbox"
                label={
                  <Fragment>
                    <span className="checkmark" />
                    <div className="label-section">{label}</div>
                  </Fragment>
                }
                component={CheckBoxField}
                onChange={onChange}
              />
            </div>
          );
        })}
      </Fragment>
    );
  };

  return (
    <Field
      component={getCheckboxes}
      name={`checkboxes_${field}`}
      props={{
        field,
        options,
      }}
      validate={validators}
    />
  );
};

CheckboxGroup.propTypes = {
  field: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  parentField: PropTypes.string,
  validators: PropTypes.array,
};

export default CheckboxGroup;
