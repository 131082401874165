import { findIndex, isEmpty, sortBy } from 'lodash';

export const hydrateRaces = (data) => {
  data = sortBy(data, ['display_name']).map(({ display_name, secondary_races }) => {
    if (!isEmpty(secondary_races)) {
      secondary_races.push(
        secondary_races.splice(findIndex(secondary_races, ['display_name', 'Other']), 1)[0]
      );
      secondary_races.push(
        secondary_races.splice(
          findIndex(secondary_races, ['display_name', 'Prefer not to say']),
          1
        )[0]
      );
    }

    return {
      label: display_name,
      value: display_name,
      secondary_races: secondary_races.map(({ display_name }) => ({
        label: display_name,
        value: display_name,
      })),
    };
  });

  data.push(data.splice(findIndex(data, ['label', 'Mixed Race']), 1)[0]);
  data.push(data.splice(findIndex(data, ['label', 'Other']), 1)[0]);
  data.push(data.splice(findIndex(data, ['label', 'Prefer not to say']), 1)[0]);

  return data;
};

export const hydrateEthnicities = (data) => {
  return data.map(({ display_name }) => ({
    label: display_name,
    value: display_name,
  }));
};

export const hydrateCountriesState = (data) => {
  return {
    states: data && data.states && sortBy(data.states, ['name']),
    countries: data && data.countries && sortBy(data.countries, ['name']),
  };
};
