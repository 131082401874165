import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { browserHistory, withRouter, Link } from 'react-router';
import { connect } from 'react-redux';
import './SoftLoginContainer.scss';
import { softLogin } from 'ducks/auth/email';
import authService from 'services/api/authService';
import LoginForm from './LoginForm';
import InfoModal from 'components/modals/SCP/InfoModal/InfoModal';
import _ from 'lodash';
import EmailIcon from './email-icon.svg';
import api from 'services/api/api';

const FULL_ACCOUNT_EXISTS_FLOW = 'FULL_ACCOUNT_EXISTS';
const SWITCH_TO_SCP_FLOW = 'SWITCH_TO_SCP';
const DEFAULT_FLOW = 'DEFAULT_FLOW';

class SoftLoginContainer extends Component {
  state = {
    returningUserEmail: null,
    activeFlow: DEFAULT_FLOW,
    uuid: null,
    attempt: 0,
  };

  identifyFlow = res => {
    if (!_.isEmpty(res)) {
      const { fullAccountExists, switchToScp } = res;
      if (fullAccountExists) {
        return FULL_ACCOUNT_EXISTS_FLOW;
      } else if (switchToScp) {
        return SWITCH_TO_SCP_FLOW;
      }
    }
    return DEFAULT_FLOW;
  };

  handleReturningSubmit = () => {
    const { gotoLoginPage, gotoSCPHome, gotoWelcome } = this.props;
    const { activeFlow } = this.state;
    api.clearNavigatorSurvey();

    if (activeFlow == SWITCH_TO_SCP_FLOW) {
      if (api.hasToken() && api.isNonSCPUser()) {
        gotoWelcome();
      } else if (api.hasToken() && api.isSCPUser()) {
        gotoSCPHome();
      } else {
        const { uuid } = this.state;
        authService.saveSCPPreRegistrationData({ uuid, switchToScp: true });
        gotoLoginPage();
      }
    } else if (activeFlow == FULL_ACCOUNT_EXISTS_FLOW) {
      gotoLoginPage();
    }
  };

  onLogin = formData => {
    return this.props
      .dispatch(softLogin(formData))
      .then(data => {
        const nextflow = this.identifyFlow(data);
        if (nextflow == DEFAULT_FLOW) {
          authService.saveSCPPreRegistrationData({ ...formData, ...data });
          api.setEducationSurvey({
            surveySessionId: _.get(data, 'surveySessionId'),
            surveyTreeId: _.get(data, 'surveyTreeId'),
          });
          api.clearNavigatorSurvey();
          this.props.gotoEducationPage();
        } else if (nextflow == FULL_ACCOUNT_EXISTS_FLOW) {
          this.setState({
            returningUserEmail: formData.email,
            activeFlow: FULL_ACCOUNT_EXISTS_FLOW,
          });
        } else if (nextflow == SWITCH_TO_SCP_FLOW) {
          const { uuid } = data;
          this.setState({
            uuid,
            returningUserEmail: formData.email,
            activeFlow: SWITCH_TO_SCP_FLOW,
          });
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };

  get renderReturningUser() {
    return (
      <InfoModal
        className="info-modal"
        title={<span className="info-title">Returning User?</span>}
        description={
          <div>
            The email <span className="info-email">{this.state.returningUserEmail}</span> is already
            registered in our system. Please login.
          </div>
        }
        submitText="Let’s go to Login"
        submitCallback={() => this.handleReturningSubmit()}
        cancelCallback={() =>
          this.setState({
            returningUserEmail: null,
            activeFlow: DEFAULT_FLOW,
            uuid: null,
          })}
      />
    );
  }

  render() {
    const { returningUserEmail } = this.state;
    const { authError } = this.props;
    return (
      <Fragment>
        <div className="soft-login-page">
          {returningUserEmail && this.renderReturningUser}
          <div className="form-wrapper">
            <i className="genome-logo" />
            <span className="login-title">Welcome Back!</span>
            <div className="login-desc">
              Pick up where you left off by filling out your email and name
            </div>
            <LoginForm formError={authError} onSubmit={this.onLogin} />
          </div>
          <div className="bottom-card">
            <img src={EmailIcon} alt="Already have an account?" />
            <div>
              Already have an account?
              <Link to="/">Sign in using email and password</Link>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

SoftLoginContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  selectedStage: PropTypes.string,
};
const mapStateToProps = ({ auth }) => ({
  auth,
});
const mergeProps = (stateProps, dispatchProps) => {
  const { dispatch } = dispatchProps;
  const { auth: { email } } = stateProps;
  const { authError } = email;
  return {
    dispatch,
    authError,
    gotoEducationPage: () => browserHistory.push(`/scp/education`),
    gotoLoginPage: () => browserHistory.push(`/`),
    gotoSCPHome: () => browserHistory.push(`/patient/home`),
    gotoWelcome: () => browserHistory.push(`/welcome`),
  };
};
export default connect(mapStateToProps, null, mergeProps)(withRouter(SoftLoginContainer));
