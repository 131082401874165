import PropTypes from 'prop-types';
import React from 'react';

import './PasswordStrength.scss';

const PasswordStrength = props => {
  const hintClass = `password-strength${props.leftArrow ? ' password-strength-left' : ''}`;
  return (
    <div className={hintClass}>
      <h4>Requirements</h4>
      <div className="circled">
        <span className={'circle ' + (props.warning.length8 ? 'red' : 'green')} />
        <div className="error-text">length at least 8 characters</div>
      </div>
      <div className="circled">
        <span className={'circle ' + (props.warning.number ? 'red' : 'green')} />
        <div className="error-text">at least one number</div>
      </div>
      <div className="circled">
        <span className={'circle ' + (props.warning.specialCharacter ? 'red' : 'green')} />
        <div className="error-text">at least one special character (!,.?@#$%^*/=-+)</div>
      </div>
      <div className="circled">
        <span className={'circle ' + (props.warning.capitalizedLetter ? 'red' : 'green')} />
        <div className="error-text">at least one uppercase letter</div>
      </div>
      <div className="circled">
        <span className={'circle ' + (props.warning.lowercaseLetter ? 'red' : 'green')} />
        <div className="error-text">at least one lowercase letter</div>
      </div>
    </div>
  );
};

PasswordStrength.propTypes = {
  leftArrow: PropTypes.bool,
  warning: PropTypes.object.isRequired,
};

export default PasswordStrength;
