import api from './api';

export default class AppMetaAPI {
  get(): Promise<any> {
    const timestamp = Date.now();
    return api.getPortal(`/app-version-meta.json?q=${timestamp}`).then(res => {
      return res.data;
    });
  }
}
