import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import './BreadcrumbProgress.scss';
import gmiLogo from '../../../../images/minimized_logo.png';
import ArrowRight from '../../../scp/registration/ArrowRight';
const BreadcrumbProgress = ({ activeStage, workflow, className }) => {
  const stages = _(workflow).reduce((result, o, i) => {
    result[o.id] = i;
    return result;
  }, {});

  const activeStageIndex = activeStage ? stages[activeStage] : 0;

  // for mobile
  const actualStages = workflow.filter((o) => !o.noProgress);
  const stagesCount = actualStages.length;
  let currentStageIndex = 0;
  let stageName = '';
  for (const stage of actualStages) {
    currentStageIndex++;
    if (stage.id === activeStage) {
      stageName = stage.name;
      break;
    }
  }

  const activeStageObj = _(workflow).reduce((result, o, i) => {
    result[o.id] = o;
    return result;
  }, {});

  if (activeStageObj[activeStage].noProgress) return <div />;

  return (
    <div>
      <div className={classnames(className, 'scp-schedule-breadcrumb')}>
        {workflow
          .filter((o) => !o.noProgress)
          .map((o, i) => {
            const isDone = stages[o.id] < activeStageIndex;
            return (
              <div
                className={classnames('breadcrump__item', {
                  breadcrump__item__active: o.id === activeStage,
                  breadcrump__item__done: isDone,
                })}
                key={o.id}
              >
                <span className="breadcrump__text">{o.name}</span>
                {i + 1 != stagesCount ? (
                  <span className="breadcrump__nav">
                    <ArrowRight className="arrow-right" width="28" height="28" />
                  </span>
                ) : null}
              </div>
            );
          })}
      </div>
      <div className="scp-mob-schedule-breadcrumb">
        <div className={classnames('m-breadcrumb-content', 'm-scheduling-breadcrumb-content')}>
          <Fragment>
            <div className="logo">
              <img src={gmiLogo} className="header-logo" />
            </div>
          </Fragment>
        </div>
      </div>
      <div
        className="mob-schedule-appointment-progress-bar"
        style={{ width: `${(100 * currentStageIndex) / stagesCount}%` }}
      />
    </div>
  );
};

BreadcrumbProgress.propTypes = {
  activeStage: PropTypes.string,
  workflow: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

export default BreadcrumbProgress;
