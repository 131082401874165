import PropTypes from 'prop-types';
import React from 'react';
import './CheckBox.scss';

class CheckBox extends React.Component {
  render() {
    const { label, input /* meta/*: { touched, error }*/ } = this.props;
    const checkedOpt = { checked: input.value ? true : false };
    return (
      <div className="gm-checbox-control">
        <label htmlFor="cbx" className="label-cbx">
          <input
            id="cbx"
            type="checkbox"
            className="invisible"
            {...checkedOpt}
            onClick={() => {
              input.onChange(!input.value);
            }}
          />
          <div className="checkbox">
            <svg width="20px" height="20px" viewBox="0 0 20 20">
              <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z" />
              <polyline points="4 11 8 15 16 6" />
            </svg>
          </div>
          <span>{label}</span>
        </label>
      </div>
    );
  }
}

CheckBox.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default CheckBox;
