import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppointmentInfo from 'components/widgets/SCP/EventInfo/AppointmentInfo';

import './AppointmentInfoWithCancel.scss';

class AppointmentInfoWithCancel extends Component {
  render() {
    const { onCancel, onReschedule, isChangeable } = this.props;
    return (
      <AppointmentInfo {...this.props}>
        <div className="scp-app-cancel-block">
          {isChangeable &&
              <div>
                <span onClick={onReschedule} className="link reschedule-link">
                  <img
                    src={require('images/common/reschedule.svg')}
                    alt="reschedule-appointment"
                    className="reschedule-icon"
                  />{' '}
                  Reschedule
                </span>
                <span onClick={onCancel} className="link">
                  <img
                    src={require('images/common/x.svg')}
                    alt="cancel-appointment"
                    className="cancel-icon"
                  />
                  Cancel
                </span>
              </div>
            }
        </div>
      </AppointmentInfo>
    );
  }
}

AppointmentInfoWithCancel.propTypes = {
  isChangeable: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  title: PropTypes.string,
  appointment: PropTypes.object.isRequired,
  hidePrice: PropTypes.bool,
  newPrice: PropTypes.number,
  className: PropTypes.string,
};

export default AppointmentInfoWithCancel;
