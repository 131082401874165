import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Calendar from 'components/calendar/Calendar';
import { CreateEmptySlotEvents } from 'components/calendar/CalendarUtils';
import './AppointmentCalendar.scss';

class AppointmentCalendar extends Component {
  static propTypes = {
    slots: PropTypes.array.isRequired,
    reservations: PropTypes.array.isRequired,
    onSlotClick: PropTypes.func.isRequired,
    isShaded: PropTypes.bool,
    date: PropTypes.object.isRequired,
    onDateChanged: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    toPreviousStep: PropTypes.func.isRequired,
  };

  onSlotClick = (sid, start, end) => {
    this.props.onSlotClick(sid, start, end);
  };

  render() {
    const {
      date,
      slots,
      reservations,
      isShaded,
      isLoading,
      onDateChanged,
      isMobile,
      isRescheduling,
      toPreviousStep,
      availableDates,
      isLoadingAvailableDates,
      isPreSelectedFlow
    } = this.props;
    const slotEvents = CreateEmptySlotEvents({
      eventsList: slots,
      reservations,
      onSlotClick: this.onSlotClick,
      startDate: date,
    });
    return (
      <Calendar
        availableDates={availableDates}
        events={slotEvents}
        date={date}
        onDateChanged={onDateChanged}
        isShaded={isShaded}
        isLoading={isLoading}
        isMobile={isMobile}
        flow="scp"
        isRescheduling={isRescheduling}
        toPreviousStep={toPreviousStep}
        isLoadingAvailableDates={isLoadingAvailableDates}
        isPreSelectedFlow={isPreSelectedFlow}
      />
    );
  }
}

export default AppointmentCalendar;
