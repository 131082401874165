import React, { Component } from 'react';
import classnames from 'classnames';
import './TestOrderProgressBar.scss';
import { testOrderDetailProgressBar } from 'constants/TestOrderConstants';
import _ from 'lodash';

class SCPTestOrderProgressBar extends Component {
  render() {
    const { currentScpEncounter } = this.props;
    const status = _.get(currentScpEncounter, ['order', 'status'], null);
    const ProgressBarClasses = testOrderDetailProgressBar[status];
    return (
      <div className="scp-test-order-progressbar-wrapper">
        <ul className="progressbar">
          <li className={classnames(_.get(ProgressBarClasses, ['step1', 'className'], ''))}>
            <span />
            <div className="step-name">{_.get(ProgressBarClasses, ['step1', 'text'], '')}</div>
          </li>
          <li className={classnames(_.get(ProgressBarClasses, ['step2', 'className'], ''))}>
            <span />
            <div className="step-name">{_.get(ProgressBarClasses, ['step2', 'text'], '')}</div>
          </li>
          <li className={classnames(_.get(ProgressBarClasses, ['step3', 'className'], ''))}>
            <span />
            <div className="step-name">{_.get(ProgressBarClasses, ['step3', 'text'], '')}</div>
          </li>
        </ul>
      </div>
    );
  }
}

export default SCPTestOrderProgressBar;
