// @flow
import api, { X_API_AUTHORIZATION_HEADER } from './api';

export default class ServicePackagesApi {
  getPurchasedPackages(): Promise<Array<Object>> {
    return api.get('/service-packages');
  }

  purchasePackage(
    packageId: number,
    couponCode: ?string,
    accessToken: ?string
  ): Promise<Array<Object>> {
    const params = { service_package_id: packageId };
    if (couponCode) params.coupon_code = couponCode;
    return accessToken
      ? api.post('/service-packages/purchase', params, {
          headers: { [X_API_AUTHORIZATION_HEADER]: accessToken },
        })
      : api.post('/service-packages/purchase', params);
  }
}
