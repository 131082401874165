/* @flow */
import _ from 'lodash';
import { localStore } from 'services/storage';
import api from './api';
import getDb from 'services/filesDb';
import {
  sectionProviderInfo,
  sectionPatientInfo,
  sectionAdditionalInfo,
} from 'constants/ReferralsScheme';
import base64 from 'base64-js';

const GM_REFER_STAGE = 'GM_REFER_STAGE';
const GM_REFERRAL_ANSWERS = 'GM_REFERRAL_ANSWERS';
const GM_REFERRAL_CODE = 'GM_REFERRAL_CODE';

export type Answer = {
  text: String,
  section: String,
  sectionKey: String,
  name: String,
  answer: String,
  metadata: ?Object,
};

class ReferralsService {
  getCurrentStage(): String {
    return localStore.get(GM_REFER_STAGE);
  }

  setCurrentStage(stage: String) {
    localStore.set(GM_REFER_STAGE, stage);
  }

  loadAnswers() {
    const answersStr = localStore.get(GM_REFERRAL_ANSWERS);
    return answersStr ? JSON.parse(answersStr) : {};
  }

  saveAnswers(answers: { string: Answer => Answer }) {
    localStore.set(GM_REFERRAL_ANSWERS, JSON.stringify(answers || {}));
  }

  submitAnswers() {
    const answers = this.loadAnswers();
    if (!answers) throw new Error('No answers');
    const rx = /^[\w.,!?"\'’|\/()&$ :;\-+%@#]+$/;
    this.__dehydrateAnswers(answers).map(a => {
      !rx.test(a.question_text) && console.error('Q:  ' + a.question_text); // eslint-disable-line no-console
      !rx.test(a.question_answer) && console.error('A:  ' + a.question_answer); // eslint-disable-line no-console
    });

    const outputAnswers = this.__dehydrateAnswers(answers).filter(
      a => a.question_name !== 'captcha'
    );

    return api
      .post('/patient-referral-form', {
        answers: outputAnswers,
        provider_full_name: answers[sectionProviderInfo].physicianName.answer,
        patient_full_name: answers[sectionPatientInfo].patientName.answer,
        captcha: answers[sectionAdditionalInfo].captcha.answer,
      })
      .then(data => localStore.set(GM_REFERRAL_CODE, data.referral_code));
  }

  submitFiles() {
    const referralCode = localStore.get(GM_REFERRAL_CODE);
    const filesDb = getDb();
    if (!referralCode) throw new Error('Error: referral-code is empty');
    return new Promise((resolve, reject) => {
      filesDb.transaction('r', filesDb.files, () => {
        filesDb.files.toArray(files => {
          Promise.all(
            files.map(file => {
              const formData = new FormData();
              formData.append('file', new Blob([base64.toByteArray(file.file)]), file.name);
              return api.postMultipartFormData(
                `/patient-referral-form/${referralCode}/files`,
                formData,
                () => {}
              );
            })
          )
            .then(resolve)
            .catch(reject);
        });
      });
    });
  }

  submitForm() {
    const referralCode = localStore.get(GM_REFERRAL_CODE);
    if (!referralCode) throw new Error('Error: referral-code is empty');
    return api.post(`/patient-referral-form/${referralCode}/submit`);
  }

  clearEverything() {
    const filesDb = getDb();
    localStore.remove(GM_REFER_STAGE);
    localStore.remove(GM_REFERRAL_ANSWERS);
    localStore.remove(GM_REFERRAL_CODE);
    filesDb.transaction('rw', filesDb.files, () => filesDb.files.clear());
  }

  __dehydrateAnswers = answers =>
    _(answers).values().map(o => Object.values(o)).flatten().value().map(o => ({
      section: o.section,
      question_name: o.name,
      question_text: o.text,
      question_answer: o.answer,
      question_sort_index: o.index,
    }));
}

export default new ReferralsService();
