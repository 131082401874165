import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { testOrderCardTitles, testOrderCardDescriptions } from 'constants/TestOrderConstants';
import RBAC from 'components/high-order-components/RBAC/RBAC';
import './PatientCards.scss';
import testOrderIcon from './test-order.svg';
class TestOrder extends Component {
  static propTypes = {
    status: PropTypes.string,
  };

  get title() {
    const { status } = this.props;

    return testOrderCardTitles[status];
  }

  get description() {
    const { status } = this.props;

    return testOrderCardDescriptions[status];
  }

  render() {
    return (
      <Fragment>
        {this.title && this.description ? (
          <div className="upcoming-appointment">
            <div
              className="content"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                browserHistory.push('/patient/testorder');
              }}>
              <div className="static">
                <div className="left">
                  <div className="tag">
                    <span className="green">Test Order</span>
                  </div>
                  <div className="title">{this.title}</div>
                  <div className="text">{this.description}</div>
                </div>
                <div className="right">
                  <img className="visual-content" src={testOrderIcon} alt="Incomeplete Task" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default RBAC(['scp'], TestOrder);
