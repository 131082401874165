import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';
import classnames from 'classnames';
import {
  required,
  email,
  patientName,
  matchPassword,
  phoneOrEmpty,
  dateOfBirth,
  strongPassword,
  shouldBeBoolean,
} from 'services/validate';
import Alert from 'components/widgets/Alert/Alert';
import warn from 'components/forms/PasswordStrength/warn';
import Button from '../../controls/ButtonV2';
import InputField from '../../controls/V2/InputField';
import SelectField from '../../controls/V2/SelectField';
import CheckBoxField from '../../controls/V2/CheckBoxField';
import PhoneNumberField from '../../controls/V2/PhoneNumberField';
import InputMaskField from '../../controls/V2/InputMaskField';
import 'element-theme-default';
import track, { TR_VISIT_REGISTRATION_PAGE } from 'services/track';
import gaTrack, { GA_TR_VISIT_REGISTRATION_PAGE } from '../../../../services/gaTrack';
import PasswordRequirementsTooltip from '../../PasswordStrength/PasswordRequirementsTooltip';
import { Tooltip } from 'gm-element-react';
import './SignupFormV2.scss';
import RadioButtonGroup from '../../controls/RadioButtonGroup';
import schedulingService from '../../../../services/api/schedulingService';

const relationshipVarients = [
  { label: 'Child', value: 'Child' },
  { label: 'Parent', value: 'Parent' },
  { label: 'Spouse/Domestic Partner', value: 'Spouse/Domestic Partner' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Legal Guardian', value: 'Legal Guardian' },
  { label: 'Other', value: 'Other' },
];


const sexAssignedAtBirthVariants = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
  { label: 'Intersex', value: 'intersex' },
];

class SignUpForm extends Component {
  state = {
    isNotPatient: false,
    guardianRelationship: undefined,
    isMobile:false
  };

  handleIsNotPatient = value => {
    this.setState({ isNotPatient: value });
    if (value) {
      this.resetPatientPhoneField();
    }
    this.resetGuardianFields();
  };

  handleGuardianRelationship = (value) => {
    this.setState({ guardianRelationship: value })
  }

  resetGuardianFields = () => {
    this.props.change('guardianFirstName', '');
    this.props.change('guardianLastName', '');
    this.props.change('guardianPrimaryPhone', '');
    this.props.change('guardianRelationship', '');
    this.props.change('guardianDob', '');
    this.props.untouch('guardianPrimaryPhone', '');
    this.props.untouch('guardianFirstName');
    this.props.untouch('guardianLastName');
    this.props.untouch('guardianRelationship');
    this.props.untouch('guardianDob');
  };

  resetPatientFields = () => {
    this.props.change('firstName', '');
    this.props.change('lastName', '');
    this.props.change('dob', '');
    this.props.change('primaryPhone', '');
    this.props.change('sexAssignedAtBirth', '');
    this.props.untouch('firstName', '');
    this.props.untouch('lastName', '');
    this.props.untouch('dob', '');
    this.props.untouch('primaryPhone', '');
    this.props.untouch('sexAssignedAtBirth', '');
  };

  resetPatientPhoneField = () => {
    this.props.change('primaryPhone', '');
    this.props.untouch('primaryPhone', '');
  };

  componentDidMount() {
    track(TR_VISIT_REGISTRATION_PAGE);
    gaTrack(GA_TR_VISIT_REGISTRATION_PAGE);
    window.scrollTo(0, 0);
    this.setState({
      isNotPatient: _.isUndefined(this.props.isPatientValue)
        ? this.state.isNotPatient
        : this.props.isPatientValue,
    });
    this.setState({ isMobile: schedulingService.isMobile() });
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setViewMode();
  };

  setViewMode() {
    const { isMobile } = this.state;
    if (isMobile && !schedulingService.isMobile()) {
      this.setState({ isMobile: false });
    } else if (!isMobile && schedulingService.isMobile()) {
      this.setState({ isMobile: true });
    }
  }

  consentToTextMessage() {
    return 'By checking this box, I agree to receive automated text notifications from Genome Medical. These notifications may be about appointments, test results, and more.';
  }


  render() {
    const {
      handleSubmit,
      submitting,
      formError,
      valid,
      showMedicareQuestion
    } = this.props;
    const { isNotPatient,isMobile } = this.state;
    const patientLabelPrefix = isNotPatient ? "Patient's" : '';
    let isShowMedicareQuestion = true;
    if (showMedicareQuestion) {
      isShowMedicareQuestion = Boolean(showMedicareQuestion?.toLowerCase() === "true");
      if (!isShowMedicareQuestion) { this.props.change('isMedicareBeneficiary', false); }
    }
    const relationPatientIsSelectedAndNotPatient = this.state.guardianRelationship && this.state.guardianRelationship !== 'Parent';
    return (
      <section className="signup-container">
        <section className="signup-wrapper">
          <section className="signup-header">
            <div className="genome-header-logo">
              <img className="genome-header-img" src={require('images/logo.svg')} />
            </div>
            <div className="genome-header-title">
              <h2 className="signup-title">Get Started</h2>
              <h3 className="signup-desc">
                Tell us about yourself so you can access the services that are right for you.
              </h3>
              <div className="login-here">
                Already have an account?{' '}
                <Link className="privacy-link" to="/login">
                  Sign In
                </Link>
              </div>
            </div>
          </section>
          <section className="signup-body">
            <form
              className="signup-form"
              autoComplete="off"
              onSubmit={handleSubmit(this.props.onSubmit)}
              noValidate>
              <div className="form-section">
                <div className="info-section">
                  <h3 className="header">Login Details</h3>
                </div>
                <div className="body-section">
                  <div className="row-section">
                    <div className="col-section">
                      <div className="label-section required">Email Address</div>
                      <div className="input-section">
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          trim={true}
                          component={InputField}
                          validate={[required('Email'), email]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-section">
                    <div className="col-section">
                      <div style={{ display: 'flex' }}>
                        <div className="label-section required">Password</div>
                        <Tooltip
                          className="set-pwd-tooltip"
                          effect="dark"
                          content={<PasswordRequirementsTooltip />}
                          placement="top">
                          <img className="arrow-left" src={require('images/more-info.svg')} />
                        </Tooltip>
                      </div>
                      <div className="input-section">
                        <Field
                          name="password"
                          type="password"
                          placeholder="Password"
                          trim={true}
                          component={InputField}
                          validate={[required('Password'), strongPassword]}
                        />
                      </div>
                    </div>
                    <div className="col-section">
                      <div className="label-section required">Confirm Password</div>
                      <div className="input-section">
                        <Field
                          name="passwordRepeat"
                          type="password"
                          placeholder="Confirm Password"
                          trim={true}
                          component={InputField}
                          validate={[required('Confirm password')]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="info-section">
                  <h3 className="header">Patient Details</h3>
                </div>
                <div className="body-section">
                  <Fragment>
                    <div className="row-section">
                      <div className="col-section">
                        <div className="label-section required">{`${patientLabelPrefix} First Name`}</div>
                        <div className="input-section">
                          <Field
                            name="firstName"
                            type="text"
                            placeholder={`${patientLabelPrefix} First Name`}
                            trim={true}
                            component={InputField}
                            validate={[required('First Name'), patientName]}
                          />
                          <div className="child-input-section">
                            <Field
                              name="isNotPatient"
                              type="checkbox"
                              label={
                                <Fragment>
                                  <span className="checkmark" />
                                  <div className="label-section">
                                    <span>Check if you <b>are not</b> the patient </span>
                                  </div>
                                </Fragment>
                              }
                              component={CheckBoxField}
                              onChange={(e, value) => {
                                this.handleIsNotPatient(value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-section">
                        <div className="label-section required">{`${patientLabelPrefix} Last Name`}</div>
                        <div className="input-section">
                          <Field
                            name="lastName"
                            type="text"
                            placeholder={`${patientLabelPrefix} Last Name`}
                            trim={true}
                            component={InputField}
                            validate={[required('Last Name'), patientName]}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row-section patient-detail-section">
                      {!isNotPatient && (<div className="col-section">
                        <div className="label-section required">Phone Number</div>
                        <div className="input-section">
                          <Field
                            name="primaryPhone"
                            type="text"
                            placeholder="Phone Number"
                            component={PhoneNumberField}
                            mask="+1 999 999-9999"
                            maskChar={null}
                            validate={[phoneOrEmpty, required('Phone Number')]}
                          />
                          {isMobile &&<div className="child-input-section">
                            <Field
                              name={'primaryPhoneConsentToText'}
                              type="checkbox"
                              label={
                                <Fragment>
                                  <span className="checkmark" />
                                  <div className="label-section">
                                    <span> {this.consentToTextMessage()}</span>
                                  </div>
                                </Fragment>
                              }
                              component={CheckBoxField}
                            />
                          </div>
                      }
                        </div>
                      </div>
                      )}
                      <div className="col-section">
                        <div className="label-section required">{`${patientLabelPrefix} Date of Birth`}</div>
                        <div className="input-section">
                          <Field
                            name="dob"
                            placeholder="MM/DD/YYYY"
                            component={InputMaskField}
                            mask="99/99/9999"
                            maskChar={null}
                            validate={[required('Date of Birth'), dateOfBirth()]}
                          />
                        </div>
                      </div>
                      {!isMobile && !isNotPatient &&  <div className="col-section full-col consent-text">
                        <div className="label-section">Consent to Text</div>
                        <div className="input-section consent-to-text">
                          <Field
                            name={'guardianPrimaryPhoneConsentToText'}
                            type="checkbox"
                            label={
                              <Fragment>
                                <span className="checkmark" />
                                <div className="label-section">{this.consentToTextMessage()}</div>
                              </Fragment>
                            }
                            component={CheckBoxField}
                          />
                        </div>
                    </div>
  }
                      <div className="col-section">
                        <div className="label-section required">{`${patientLabelPrefix} Sex Assigned at Birth`}</div>
                        <div className="input-section">
                          <Field
                            name="sexAssignedAtBirth"
                            type="select"
                            placeholder="Select"
                            component={SelectField}
                            options={sexAssignedAtBirthVariants}
                            validate={[required('Sex Assigned at Birth')]}
                          />
                        </div>
                      </div>

                      {isShowMedicareQuestion === true ? <div className="col-section">
                      <div className="label-section required">
                        {!isNotPatient
                          ? 'Are you covered by Medicare?'
                          : 'Is the patient covered by Medicare?'}
                      </div>
                      <div className="input-section">
                        <Field
                          name="isMedicareBeneficiary"
                          component={RadioButtonGroup}
                          children={[
                            { label: 'Yes', value: true, },
                            { label: 'No', value: false },
                          ]}
                          validate={shouldBeBoolean('Medicare Beneficiary')}
                        />
                      </div>
                    </div> : ''
                    }

                    </div>

                  </Fragment>
                </div>
              </div>
              {isNotPatient && (
                <div className="form-section">
                  <div className="info-section">
                    <h3 className="header">Your Details</h3>
                    <div className="body">
                      You indicated that you are not the patient. In order to
                      schedule an appointment for someone else you must be their
                      parent/legal guardian, or if they are an incapacitated adult
                      you must hold a medical power of attorney and be legally authorized to make medical decisions on their behalf.
                    </div>
                  </div>
                  <div className="body-section">
                    <div className="row-section">
                      <div className="col-section">
                        <div className="label-section required">Your First Name</div>
                        <div className="input-section">
                          <Field
                            name="guardianFirstName"
                            type="text"
                            placeholder="Your First Name"
                            trim={true}
                            component={InputField}
                            validate={[required('Your First Name')]}
                          />
                        </div>
                      </div>
                      <div className="col-section">
                        <div className="label-section required">Your Last Name</div>
                        <div className="input-section">
                          <Field
                            name="guardianLastName"
                            type="text"
                            placeholder="Your Last Name"
                            trim={true}
                            component={InputField}
                            validate={[required('Your Last Name')]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classnames("row-section",{
                      'no-margin':relationPatientIsSelectedAndNotPatient
                    })}>
                      <div className="col-section">
                        <div className="label-section required">Your Phone Number</div>
                        <div className="input-section">
                          <Field
                            name="guardianPrimaryPhone"
                            type="text"
                            placeholder="Phone Number"
                            component={PhoneNumberField}
                            mask="+1 999 999-9999"
                            maskChar={null}
                            validate={[phoneOrEmpty, required('Your Phone Number')]}
                          />
                          {isMobile && <div className="child-input-section">
                            <Field
                              name={'guardianPrimaryPhoneConsentToText'}
                              type="checkbox"
                              label={
                                <Fragment>
                                  <span className="checkmark" />
                                  <div className="label-section">
                                    <span> {this.consentToTextMessage()}</span>
                                  </div>
                                </Fragment>
                              }
                              component={CheckBoxField}
                            />
                          </div>
  }
                        </div>
                      </div>
                      <div className={classnames("col-section",{
                      'no-margin':relationPatientIsSelectedAndNotPatient
                    })}>
                        <div className="label-section required">Relationship to Patient</div>
                        <div className="input-section">
                          <Field
                            name="guardianRelationship"
                            type="select"
                            placeholder="Select"
                            component={SelectField}
                            options={relationshipVarients}
                            validate={[required('Relationship to Patient')]}
                            onChange={(e, value) => this.handleGuardianRelationship(value)}
                          />
                        </div>
                      </div>
                    </div>
                    {relationPatientIsSelectedAndNotPatient&&
                      <div className="row-section">
                        <div className="col-section rltnshp-text full-col">
                          <div>You must provide documentation before the patient’s visit to validate this relationship. Please upload the documentation in the patient portal after scheduling.</div>
                        </div>
                      </div>
                    }

                    {!isMobile && <div className="row-section">
                      <div className="col-section full-col consent-text">
                        <div className="label-section">Consent to Text</div>
                        <div className="input-section consent-to-text">
                          <Field
                            name={'guardianPrimaryPhoneConsentToText'}
                            type="checkbox"
                            label={
                              <Fragment>
                                <span className="checkmark" />
                                <div className="label-section">{this.consentToTextMessage()}</div>
                              </Fragment>
                            }
                            component={CheckBoxField}
                          />
                        </div>
                      </div>
                    </div>
  }
                  </div>
                </div>
              )}
              <div className="signup-footer">
                <Alert message={formError} />
                <div className="sign-button-wrapper">
                  <div className="login-here">
                    <Link className="privacy-link" to="/login">
                      Sign In
                    </Link>{' '}
                    if you already have an account
                  </div>
                  <div className="btn-section">
                    <Button className="forgot-button" type="submit" disabled={submitting || !valid}>
                      <span>Continue</span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </section>
      </section>
    );
  }
}

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  showMedicareQuestion: PropTypes.string,
};

const reduxSignUpForm = reduxForm({
  form: 'sign-up-form',
  validate: values => {
    const errors = {
      ...matchPassword(values),
    };

    return errors;
  },
  warn,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SignUpForm);

// Decorate with connect to read form values
const selector = formValueSelector('sign-up-form'); // <-- same as form name
export default connect(state => {
  const isPatientValue = selector(state, 'isNotPatient');
  return {
    isPatientValue,
  };
})(reduxSignUpForm);
