import React, { Component } from 'react';

import classnames from 'classnames';
import _ from 'lodash';
import Button from 'components/forms/controls/ButtonV2';
import SelectSpecialityRadioGroup from './SelectSpecialityRadioGroup';

class SelectSpeciality extends Component {
  render() {
    const {
      iconClassName,
      isMobile,
      partnerSpecialties,
      selectedSpecialty,
      setTempSepciality,
      handleSpecilitiesBack,
      handleSpecilitiesNext,
      tempSpeciality,
    } = this.props;
    return (
      <div className={classnames('selector-panel v2 speciality-container')}>
        <div className="service-content">
          {!_.isEmpty(iconClassName) && !isMobile ? (
            <div
              className={classnames(
                'icon-contain',
                iconClassName === 'icon-follow-up' ? 'has-follow-up' : ''
              )}
            >
              <div className={classnames('icon-section', iconClassName)} />
            </div>
          ) : (
            ''
          )}
          <div className="name-section">
            <h4 className={classnames('service-name', { 'service-name__no-icon': !iconClassName })}>
              {'Specialties'}
            </h4>
            {!isMobile && (
              <div className="service-description">
                Please select the appropriate specialty for your visit{' '}
              </div>
            )}
          </div>
        </div>
        <div className="appointment-content">
          <SelectSpecialityRadioGroup
            data={partnerSpecialties}
            currentValue={selectedSpecialty}
            onChangeFunc={(value) => setTempSepciality(value)}
          />
        </div>
        <div className="footer-content">
          <div className="service-help">
            Still unsure about which specialty to choose? Call{' '}
            <a href="tel:+18776880992">(877) 688-0992</a> to be scheduled with the right provider.
          </div>
          <div className="btn-container">
            {!isMobile && (
              <div className="outer-button outline">
                <Button
                  className=""
                  variant="secondary"
                  onClick={() => {
                    handleSpecilitiesBack();
                  }}
                >
                  {'Back'}
                </Button>
              </div>
            )}
            <Button
              type="submit"
              className=""
              onClick={() => {
                handleSpecilitiesNext();
              }}
              disabled={_.isEmpty(tempSpeciality) && _.isEmpty(selectedSpecialty)}
            >
              {'Next'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectSpeciality;
