import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ForgotPasswordForm from 'components/forms/ForgotPasswordForm';
//import FancyPageFrame from 'components/widgets/FancyPageFrame/FancyPageFrame';
import { forgotPassword, clearForgotPassword } from 'ducks/auth/password';
import Alert from 'components/widgets/Alert/Alert';
import './ForgotPasswordPage.scss';
import gaTrack, { GA_TR_USER_FORGOT_PAGE } from '../../../../services/gaTrack';

class ForgotPasswordPage extends Component {
  static propTypes = {
    password: PropTypes.object,
    forgotPassword: PropTypes.func.isRequired,
    clearForgotPassword: PropTypes.func.isRequired,
  };

  componentDidMount() {
    gaTrack(GA_TR_USER_FORGOT_PAGE);
  }

  componentWillUnmount() {
    this.props.clearForgotPassword();
  }

  handleSubmit = ({ email, captcha }) => {
    return this.props.forgotPassword({ email, captcha });
  };

  render() {
    const { password } = this.props;
    return (
      <section className="forgot-password-page">
        {password && password.forgotPasswordEmailSent ? (
          <div className="form-wrapper">
            <i className="genome-logo" />
            <span className="forgot-title">Almost done</span>
            <div className="forgot-desc">
              Please check your inbox for an email with instructions to reset the password.
            </div>
            <div className="email-sent-letter">
              <i className="icon ion-ios-email-outline" />
            </div>
          </div>
        ) : (
          <div className="form-wrapper">
            <i className="genome-logo" />
            <span className="forgot-title">Reset Password</span>
            <div className="forgot-desc">
              Enter the email address associated with your account. We will email you a link to
              reset your password
            </div>
            <ForgotPasswordForm onSubmit={this.handleSubmit} />
          </div>
        )}
        <Alert message={password.forgotPasswordError} />
      </section>
    );
  }
}

export default connect(
  state => ({ password: state.auth.password }),
  dispatch => ({
    forgotPassword: ({ email, captcha }) => dispatch(forgotPassword({ email, captcha })),
    clearForgotPassword: () => dispatch(clearForgotPassword()),
  })
)(ForgotPasswordPage);
