import appointmentsService from 'services/api/appointmentsService';

const LOAD = 'gm/appointments/appointments/LOAD';
const LOAD_COMPLETE = 'gm/appointments/LOAD_COMPLETE';
const SET_APPOINTMENTS = 'gm/appointments/SET_APPOINTMENTS';

const initialState = {
  loading: false,
  loaded: false,
  data: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case LOAD_COMPLETE:
      return {
        ...state,
        loading: false,
      };

    case SET_APPOINTMENTS:
      return {
        ...initialState,
        data: action.payload,
        files: action.payload.files,
        loaded: true,
      };

    default:
      return state;
  }
}

export function startLoading() {
  return { type: LOAD };
}

export function setAppointments(appointments) {
  return { type: SET_APPOINTMENTS, payload: appointments };
}

export function loadAppointments() {
  return dispatch => {
    dispatch(startLoading());
    return appointmentsService.appointments().then(appointments => {
      return dispatch(setAppointments(appointments));
    });
  };
}
