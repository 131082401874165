export const CONSULTATION = 'consultation';
export const SERVICE_PACKAGE = 'service_package';

export const TestConsultationType = 'testing_guidance';
export const ReviewConsultationType = 'results_review';
export const FollowUpConsultationType = 'follow_up';
export const CurbsideConsultationType = 'curbside_chat15';
export const GetStartedConsultationType = 'testing_guidance';
export const ExpeditedResultsReviewConsultationType = 'expedited_results_review';

export const TestConsultation = 'test';
export const ReviewConsultation = 'review';
export const FollowUpConsultation = 'follow_up';
export const CurbsideConsultation = 'curbside_chat15';
export const GetStartedConsultation = 'get_started';
export const ExpeditedResultsReviewConsultation = 'expedited_results_review';
const GeneticNavigator = 'Genetic Navigator';

export const CURBSIDE_15_CONSULATION_DURATION = 15;

const hardcodedConsultationDescriptors = {
  [TestConsultation]: {
    urlParameter: TestConsultation,
    type: TestConsultationType,
    productName: GeneticNavigator,
  },
  [ReviewConsultation]: {
    urlParameter: ReviewConsultation,
    type: ReviewConsultationType,
    productName: GeneticNavigator,
  },
  [CurbsideConsultation]: {
    urlParameter: CurbsideConsultation,
    type: CurbsideConsultationType,
    productName: GeneticNavigator,
  },
  [FollowUpConsultation]: {
    urlParameter: FollowUpConsultation,
    type: FollowUpConsultationType,
    title: 'Follow-up consultation',
  },
  [GetStartedConsultation]: {
    urlParameter: GetStartedConsultation,
    type: GetStartedConsultationType,
    title: 'Get Started',
  },
  [ExpeditedResultsReviewConsultation]: {
    urlParameter: ExpeditedResultsReviewConsultation,
    type: ExpeditedResultsReviewConsultationType,
  },
};

export default hardcodedConsultationDescriptors;

export const AllHardcodedConsultations = Object.keys(hardcodedConsultationDescriptors);

export function getHardcodedDescriptorByType(consultationType) {
  return Object.values(hardcodedConsultationDescriptors).find(s => s.type === consultationType);
}
