import storage, { SessionStore } from 'services/storage';
import _ from 'lodash';
import moment from 'moment';
import api from './api';
import {
  hydrateInfo,
  dehydrateAvailability,
  hydrateAvailability,
  dehydrateAvailableDates,
  hydrateAvailableTimes,
} from './transformers/schedulingTransformers';
import { processBookingError } from './transformers/errorTransformers';
const GM_RESERVED_APPOINTMENT = 'GM_RESERVED_APPOINTMENT';
const GM_REASON_FOR_VISIT = 'GM_REASON_FOR_VISIT';
const GM_SCHEDULING_STAGE = 'GM_SCHEDULING_STAGE';
const GM_WIZARD_DATA = 'GM_WIZARD_DATA';
const GM_APPOINTMENT_ID = 'GM_APPOINTMENT_ID';
const GM_RESERVATION_TIME = 'GM_RESERVATION_TIME';
const GM_SELECTED_SERVICE = 'GM_SELECTED_SERVICE';
const GM_PAY_WITH_INSURANCE = 'GM_PAY_WITH_INSURANCE';
const GM_DEFAULT_PAYMENT_MODE = 'GM_DEFAULT_PAYMENT_MODE';
const GM_URL_MEDIUM = 'GM_URL_MEDIUM';
const GM_OUTREACH_APPOINTMENT_FLOW = 'GM_OUTREACH_APPOINTMENT_FLOW';

export const GM_APPLIED_COUPON_CODE = 'GM_APPLIED_COUPON_CODE';
export const GM_HELIX_COUPON_CODE = 'HELIXLANDINGPAGE';

export const reasonForVisitField = 'reasonForVisit';

// list of coupon codes that change the price without crossing old price
export const hiddenCoupons = [GM_HELIX_COUPON_CODE];
export const MAX_MOBILE_WIDTH = 1024;

class SchedulingService {
  getStartOfWeek(date) {
    return moment(date).startOf('week').toDate();
  }
  getStartOfMonth(date) {
    return moment(date).startOf('month').toDate();
  }

  getEndOfWeek(date) {
    return moment(date).endOf('week').toDate();
  }
  getEndOfDay(date) {
    return moment(date).endOf('day').toDate();
  }
  getEndOfMonth(date) {
    return moment(date).endOf('month').toDate();
  }

  isMobile() {
    return window.outerWidth < MAX_MOBILE_WIDTH;
  }

  getCalenderDays() {
    return this.isMobile() ? 3 : 7;
  }

  loadScheduling() {
    return api.scheduling.getAppointmentsInfo().then(hydrateInfo);
  }

  getDateOffset(dateStr) {
    return dateStr.substr(19);
  }

  loadAvailableAppointments(
    startTime,
    timezone,
    consultationId,
    state,
    specialty,
    providerId,
    country
  ) {
    const endTime = this.getEndOfDay(moment(startTime).add(6, 'day'));
    return api.scheduling
      .getAppointmentsAvailability(
        dehydrateAvailability({
          start: startTime,
          end: endTime,
          timezone,
          consultationId,
          state,
          specialty: specialty,
          providerId: providerId,
          country: country,
        })
      )
      .then(hydrateAvailability)
      .then((r) => this.getTimezoneNormalizedSlots(r, timezone, specialty));
  }

  getTimezoneNormalizedSlots(availabilityResponse, timezone, vsee_specialty) {
    const r = _.cloneDeep(availabilityResponse);
    ['availableSlots', 'reservations'].forEach((slotsField) => {
      if (r.availableSlots) {
        r[slotsField] = r[slotsField].map((slot) => {
          let serviceDuration = slot.duration;
          if (!serviceDuration)
            serviceDuration = moment(slot.end).diff(moment(slot.start), 'minutes');
          return {
            ...slot,
            start: moment(slot.start).tz(timezone).format(),
            end: moment(slot.end).tz(timezone).format(),
            duration: serviceDuration,
            vsee_specialty: slot.vsee_specialty || vsee_specialty,
          };
        });
      }
    });
    return r;
  }

  loadAvailableDates(timezone, consultationId, state, specialty, month, providerId) {
    return api.scheduling.getAvailabilityDates(
      dehydrateAvailableDates({
        timezone,
        consultationId,
        state,
        specialty,
        month,
        providerId,
      })
    );
  }
  loadAvailableTimes(date, timezone, consultationId, state, specialty, providerId) {
    return api.scheduling
      .getAvailabilityTimes(
        dehydrateAvailableDates({
          timezone,
          consultationId,
          state,
          specialty,
          date,
          providerId,
        })
      )
      .then(hydrateAvailableTimes)
      .then((r) => this.getTimezoneNormalizedSlots(r, timezone, specialty));
  }

  unreserveAppointment() {
    const reservedAppointment = this.getReservedAppointment();
    this.clearAppointmentData();
    if (reservedAppointment) {
      const token = reservedAppointment.bookingToken;
      return api.appointments.cancelAppointmentTimeSlotReservation({ token });
    }
    return Promise.resolve();
  }

  rescheduleAppointment(id, newAppointment) {
    // convert fake local time to real time in user's timezone
    let params = {
      consultation_id: newAppointment.consultation.id,
      start_time: this.fakeLocalDateToTimezoneDate(newAppointment.start, newAppointment.timezone),
      timezone: newAppointment.timezone,
      state: newAppointment.state,
      vsee_specialty: newAppointment.vsee_specialty,
      by_phone: newAppointment.modality,
      country: newAppointment.country,
    };
    if (newAppointment && newAppointment.provider) {
      params['provider_id'] = newAppointment.provider;
    }

    return api.appointments.rescheduleAppointment(id, params);
  }

  getReasonForVisit = () => SessionStore.get(GM_REASON_FOR_VISIT);
  setReasonForVisit = (reason) => SessionStore.set(GM_REASON_FOR_VISIT, reason);

  getUseInsurance = () => SessionStore.get(GM_PAY_WITH_INSURANCE);
  setUseInsurance = (value) => SessionStore.set(GM_PAY_WITH_INSURANCE, value);
  removeUseInsurance = () => SessionStore.remove(GM_PAY_WITH_INSURANCE);

  reserveAppointment(appointment) {
    const reservationTime = new Date();
    return api.appointments
      .reserveAppointmentTimeSlot({
        consultation_id: appointment.consultation.id,
        start_time: this.fakeLocalDateToTimezoneDate(appointment.start, appointment.timezone),
        timezone: appointment.timezone,
        state: appointment.state,
        vsee_specialty: appointment.vsee_specialty,
        country: appointment.country,
      })
      .then((r) => {
        this.saveReservedAppointment(r.token, appointment);
        this.saveReservationTime(reservationTime, r.duration);
        return r;
      });
  }

  bookAppointment(packageId, values, providerId, modality) {
    const accessToken = api.getToken();
    const reasonForVisit = this.getReasonForVisit() || 'Not set';
    const { bookingToken } = this.getReservedAppointment();
    const couponCode = this.getSavedCouponCode();
    const params = _.omitBy(
      {
        ...values,
        token: bookingToken,
        reason_for_visit: reasonForVisit,
        use_insurance: !!this.getUseInsurance(),
        provider_id: providerId,
        by_phone: modality,
        url_medium: this.getSavedSourceType() ?? null,
      },
      _.isUndefined
    );
    if (packageId === null || packageId === undefined) params.coupon_code = couponCode;

    return api.appointments
      .bookAppointment(params, accessToken)
      .then((data) => {
        this.clearCouponCode();
        this.clearAppointmentData();
        SessionStore.set(GM_APPOINTMENT_ID, data.id);
      })
      .catch(processBookingError);
  }

  saveReservedAppointment(bookingToken, selectedAppointment) {
    SessionStore.set(GM_RESERVED_APPOINTMENT, { bookingToken, ...selectedAppointment });
  }

  getReservedAppointment() {
    const app = SessionStore.get(GM_RESERVED_APPOINTMENT);
    if (app) {
      if (app.start) app.start = new Date(Date.parse(app.start));
      if (app.end) app.end = new Date(Date.parse(app.end));
    }
    return app;
  }

  clearAppointmentData() {
    SessionStore.remove(GM_RESERVATION_TIME);
    SessionStore.remove(GM_RESERVED_APPOINTMENT);
    SessionStore.remove(GM_APPOINTMENT_ID);
  }

  saveReservationTime(time, duration) {
    SessionStore.set(GM_RESERVATION_TIME, { start: +time, duration });
  }

  clearReservationTime() {
    SessionStore.remove(GM_RESERVATION_TIME);
  }

  getSchedulingStage() {
    return SessionStore.get(GM_SCHEDULING_STAGE);
  }

  getReservationTime() {
    return SessionStore.get(GM_RESERVATION_TIME);
  }

  getBookedAppointmentId() {
    return SessionStore.get(GM_APPOINTMENT_ID);
  }

  __setWizardData(data) {
    const prevData = this.getWizardData() || {};
    SessionStore.set(GM_WIZARD_DATA, { ...prevData, ...data });
  }

  setWizardClientData(data) {
    this.__setWizardData({ clientData: data });
  }
  clearWizardClientData() {
    storage.remove(GM_WIZARD_DATA);
  }
  setWizardPaymentData(data) {
    this.__setWizardData({ paymentData: data });
  }

  getWizardData() {
    return SessionStore.get(GM_WIZARD_DATA);
  }

  getDefaultPaymentMode = () => SessionStore.get(GM_DEFAULT_PAYMENT_MODE);
  setDefaultPaymentMode = (value) => SessionStore.set(GM_DEFAULT_PAYMENT_MODE, value);
  removeDefaultPaymentMode = () => SessionStore.remove(GM_DEFAULT_PAYMENT_MODE);

  clearSchedulingData() {
    this.clearRegistrationData();
    this.clearAppointmentData();
    this.clearReservationTime();
    this.clearCouponCode();
  }

  helixCouponIsApplied() {
    return this.getSavedCouponCode() === GM_HELIX_COUPON_CODE;
  }

  resultsReviewCouponIsApplied(webinar) {
    return webinar && this.getSavedCouponCode() === webinar.couponCode;
  }

  getSavedCouponCode() {
    return SessionStore.get(GM_APPLIED_COUPON_CODE);
  }

  saveCouponCode(code) {
    SessionStore.set(GM_APPLIED_COUPON_CODE, code);
  }

  clearCouponCode() {
    SessionStore.remove(GM_APPLIED_COUPON_CODE);
  }

  submitCouponCode(params: Object): Promise<any> {
    return api.scheduling.submitCouponCode(params);
  }

  fakeLocalDateToTimezoneDate(fakeLocalDate, timezone) {
    return moment.tz(moment(fakeLocalDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).format();
  }

  clearRegistrationData() {
    storage.remove(GM_SCHEDULING_STAGE);
    storage.remove(GM_WIZARD_DATA);
    storage.remove(GM_SELECTED_SERVICE);
    storage.remove(GM_REASON_FOR_VISIT);
    storage.remove(GM_PAY_WITH_INSURANCE);
    storage.remove(GM_DEFAULT_PAYMENT_MODE);
  }

  enterCouponCode(code, products, saveCoupon = true) {
    code = code || this.getSavedCouponCode();
    if (!code) return Promise.reject();
    return this.submitCouponCode({
      code,
      products: products.map((p) => ({
        product_id: p.productId,
        product_type: p.productType,
      })),
    }).then(
      (data) => {
        if (code && saveCoupon) {
          this.saveCouponCode(code);
        }
        return {
          result: 'success',
          code,
          items: data
            .filter((o) => o.applicable && o.discount_info)
            .map((o) => ({
              productType: o.product_type,
              productId: o.product_id,
              price: o.discount_info.total_price_after_discount,
              discountValue: o.discount_info.discount_value,
            })),
        };
      },
      (data) => Promise.reject({ fullMessage: data.message })
    );
  }

  getSavedSourceType() {
    return SessionStore.get(GM_URL_MEDIUM);
  }

  saveSourceType(source) {
    SessionStore.set(GM_URL_MEDIUM, source);
  }

  clearSourceType() {
    SessionStore.remove(GM_URL_MEDIUM);
  }

  getOutreachAppointmentData() {
    const result = SessionStore.get(GM_OUTREACH_APPOINTMENT_FLOW);
    return {
      OutreachAppointment: _.get(result, ['OutreachAppointment'], null),
      isOutreachAppointmentFlow: _.get(result, ['isOutreachAppointmentFlow'], false),
    };
  }

  setOutreachAppointmentData(OutreachAppointment) {
    SessionStore.set(GM_OUTREACH_APPOINTMENT_FLOW, {
      isOutreachAppointmentFlow: true,
      OutreachAppointment,
    });
  }

  clearOutreachAppointmentData() {
    SessionStore.remove(GM_OUTREACH_APPOINTMENT_FLOW);
  }
}

export default new SchedulingService();
