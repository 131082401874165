import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import { GetStates } from 'services/geo';
import _ from 'lodash';
const states = GetStates();

class StatePicker extends Component {
  render() {
    const { className, value, onChange, onOpen, onClose, disabled } = this.props;

    return (
      <Select
        className={classnames('gm-select', className)}
        style={_.get(this.props, 'style', {})}
        value={value}
        onChange={onChange}
        placeholder={'AL'}
        onOpen={onOpen}
        onClose={onClose}
        options={states}
        clearable={false}
        searchable={false}
        disabled={disabled}
      />
    );
  }
}

StatePicker.propTypes = {
  className: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default StatePicker;
