import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReleaseNotice from 'components/widgets/SCP/ReleaseVersionNotice/ReleaseNotice';
import Header from 'components/scp/header/Header';
import Footer from 'components/scp/footer/Footer';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';

export class SCPPatientLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className="main-container scp">
        <Header />
        <ReleaseNotice />
        {this.props.children}
        <Footer />
        <GlobalLoader />
      </div>
    );
  }
}

export default SCPPatientLayout;
