import React from 'react';
import _ from 'lodash';
import './EmailAlreadyExist.scss';
import RedWarningIcon from 'containers/pages/public/Scp/TestOrderRequestConfirm/warning-red.svg';

const EmailAlreadyExist = (props) => {

  return (
    <section className="email-exist-wrapper test-request">
      <section className="email-exist-header">
        <div className="genome-header-logo">
          <img className="genome-header-img" src={require('images/logo.svg')} />
        </div>
        <div className="genome-header-title">
          <h2 className="email-exist-title">Get Started</h2>
          <h3 className="email-exist-desc">
            Tell us about yourself so you can access the services that are right for you.
          </h3>
        </div>
      </section>
      <section className="email-exist-body">
        <form
          className="email-exist-form"
          autoComplete="off"
          noValidate
        >
          <div className="form-section">
            <div className="info-section">
              <h3 className="header">
                <img src={RedWarningIcon} className="warning-icon" alt="red-warning" />
                <span>
                We Ran into an Issue
                </span>
              </h3>
            </div>
            <div className="body-section">
            Your email already exists in our system, so we could not process your request. Please call <a href="tel:+18776880992">(877) 688-0992</a> to continue with your genetic test order request

            </div>
          </div>



        </form>
      </section>
    </section>
  )
}


export default EmailAlreadyExist;
