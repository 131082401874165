import React, { Component } from 'react';
import classnames from 'classnames';
import schedulingService from 'services/api/schedulingService';
import './ReservationTimer.scss';

class ReservationTimer extends Component {
  constructor(props) {
    super(props);
    const { start, duration } = schedulingService.getReservationTime();
    const secondsTotal = duration * 60;
    const finish = start + secondsTotal * 1000;
    const secondsLeft = Math.max(0, Math.floor((finish - Date.now()) / 1000));
    this.state = { start, secondsTotal, finish, secondsLeft };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const secondsLeft = Math.floor((this.state.finish - Date.now()) / 1000);
      if (secondsLeft >= 0) {
        this.setState({ secondsLeft });
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getTimerText() {
    const { secondsLeft } = this.state;
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return minutes + ':' + ((seconds < 10 && '0') || '') + seconds;
  }

  render() {
    const { secondsLeft } = this.state;

    return (
      <div className="reservation-timer">
        <div className="timer-pie-container">
          <div className="timer-pie-background" />
          <div className="timer-pie-slice">
            <div className="timer-pie" />
          </div>
          <div className={classnames('timer-text', { 'time-getting-out': secondsLeft < 60 })}>
            {this.getTimerText()}
          </div>
        </div>
        <span className="blue">We are holding your appointment</span>
      </div>
    );
  }
}

ReservationTimer.propTypes = {};

export default ReservationTimer;
