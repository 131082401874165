import api, { X_API_AUTHORIZATION_HEADER } from './api';
import _ from 'lodash';
import { hydrateProvider } from './transformers/schedulingTransformers';
import {
  processPostWebinarActionError,
  processRegisterWebinarError,
} from './transformers/errorTransformers';

export const POST_WEBINAR_TESTING = 'pursue_genetic_testing';
export const POST_WEBINAR_NO_TESTING = 'opt_out';
export const POST_WEBINAR_ADDITIONAL_QUESTIONS = 'additional_questions';
export const POST_WEBINAR_SCHEDULE_APPOINTMENT = 'schedule_appointment';

class WebinarService {
  getPartners() {
    return api.get('/partners').then(data => this.hydratePartners(data));
  }

  getPartnerCurrentWebinar(partnerName) {
    return api
      .get(`/partners/${partnerName}/webinars/current`)
      .then(data => this.hydrateBaseWebinar(data));
  }

  bookWebinar(id) {
    const accessToken = api.getToken();
    return api.post(
      `/webinars/${id}/register`,
      {},
      accessToken ? { headers: { [X_API_AUTHORIZATION_HEADER]: accessToken } } : {}
    );
  }

  loadCurrentWebinar() {
    return api.get('/webinars/current').then(data => this.hydrateWebinar(data));
  }

  postAction(id, action, extra) {
    return api
      .post(`/webinars/${id}/post-action`, {
        post_action: action,
        post_action_feedback: extra,
      })
      .catch(error => processPostWebinarActionError(error));
  }

  indicateInterest(id, params) {
    return api.post(`/webinars/${id}/indicate-interest-in-registering`, params);
  }

  registerWithoutAccount(id, params) {
    return api
      .post(`/webinars/${id}/register-without-account`, this.dehydrateWebinarRegistration(params))
      .catch(e => processRegisterWebinarError(e));
  }

  //---------------------------------------------------------------------------

  hydratePartners = data =>
    data.map(
      ({
        id,
        name,
        price_per_webinar_package,
        self_registration_enabled,
        registration_url,
        display_name,
        program_type,
        soft_reg_required,
        show_medicare_question,
        intro_page_version,
        uuid,
        allowed_countries,
        require_shipping_address
      }) => ({
        id,
        name,
        price: price_per_webinar_package,
        registration_url,
        self_registration_enabled,
        display_name,
        programType: program_type,
        softRegRequired: soft_reg_required,
        showMedicareQuestion: show_medicare_question,
        introPageVersion: intro_page_version,
        uuid,
        allowedCountries:  allowed_countries,
        countryIsNonUS : allowed_countries && _.isArray(allowed_countries) && allowed_countries.find(a=>a != 'US'),
        preSelectedCountry: allowed_countries && this.preSelectedCountry(allowed_countries),
        requireShippingAddress:  require_shipping_address
      })
    );

  preSelectedCountry = (countries) => {
    if (countries && countries.length > 0) {
      return countries[0];
    }
    return 'US';
  }

  hydrateBaseWebinar = data => ({
    id: data.id,
    title: data.title,
    startsAt: data.starts_at,
    registrationExpiresAt: data.registration_expires_at,
    registrationClosed: data.registration_closed,
    maxNumberOfAttendees: data.max_number_of_attendees,
    numberOfAttendees: data.number_of_attendees,
    completed: data.completed,
    timezone: data.timezone,
    presenter: data.presenter ? hydrateProvider(data.presenter) : null,
    postAction: data.post_action,
    postActionFeedback: data.post_action_feedback,
    testResultsAvailable: data.test_results_available,
    couponCode: data.coupon_code,
  });

  hydrateWebinar = data => ({
    ...this.hydrateBaseWebinar(data),
    videoSessionUrl: data.video_session_url,
    recordingUrl: data.recording_url,
    surveyUrl: data.survey_url,
  });

  dehydrateWebinarRegistration = data => ({
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    gender: data.gender,
    dob: data.dob,
    phone: data.phone,
    payment_info: {
      number: data.number,
      cvc: data.cvc,
      name: data.name,
      exp_month: data.exp_month,
      exp_year: data.exp_year,
      address_line1: data.address_line1,
      address_line2: data.address_line2,
      state: data.state,
      city: data.city,
      zip: data.zip,
    },
  });
}

export default new WebinarService();
