import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { changePasswordFormName } from 'ducks/profile';
import InputField from 'components/forms/controls/V2/InputField';
import Button from 'components/forms/controls/ButtonV2';
import PasswordStrength from 'components/forms/PasswordStrength/PasswordStrength';
import warn from 'components/forms/PasswordStrength/warn';
import { required, strongPassword } from 'services/validate';

class ChangePasswordForm extends Component {
  static propTypes = {
    children: PropTypes.element,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    title: PropTypes.string,
  };

  static validate(values) {
    return values.password !== values.confirmPassword
      ? {
          confirmPassword: "Passwords don't match",
        }
      : {};
  }

  renderNewPasswordField = params => {
    delete params.input.value;
    const { meta } = params;
    const { active, touched, warning } = meta;
    const showHint = (active || touched) && _.find(warning, w => w);
    return (
      <div>
        <InputField {...params} />
        {showHint && (
          <div className="password-hint-container">
            <PasswordStrength warning={warning || {}} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { handleSubmit, submitting, title, invalid, onClickHandler } = this.props;
    const oldPasswordLabel = 'Current Password',
      newPasswordLabel = 'New Password',
      confirmLabel = 'Confirm Password';

    return (
      <form className="gm-form scp-reset-pass" onSubmit={handleSubmit}>
        <div className="back" onClick={() => onClickHandler(0)}>
          Back
        </div>
        <p className="form-title">{title}</p>
        <p className="title">{title}</p>
        <div className="form-field">
          <label className="field-title">{oldPasswordLabel}</label>
          <Field
            name="oldPassword"
            type="password"
            component={InputField}
            placeholder="********"
            validate={[required(oldPasswordLabel), strongPassword]}
          />
        </div>
        <div className="form-field">
          <label className="field-title">{newPasswordLabel}</label>
          <Field
            name="password"
            type="password"
            placeholder="********"
            component={this.renderNewPasswordField}
            validate={[required(newPasswordLabel), strongPassword]}
          />
        </div>
        <div className="form-field">
          <label className="field-title">{confirmLabel}</label>
          <Field
            name="confirmPassword"
            type="password"
            component={InputField}
            placeholder="********"
          />
        </div>
        <Button type="submit" className="btn-save" disabled={invalid || submitting}>
          Change Password
        </Button>
      </form>
    );
  }
}

export default reduxForm({
  form: changePasswordFormName,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate: ChangePasswordForm.validate,
  warn,
})(ChangePasswordForm);
