import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Iframe from 'react-iframe';

//import Button from 'components/forms/controls/Button';

class WebinarForm extends Component {
  componentDidMount() {
    window.addEventListener('message', this.handleFrameTasks);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleFrameTasks);
  }

  handleFrameTasks = e => {
    if (e.type === 'message' && e.data === 'survey_completed') {
      this.props.finishAction();
    }
  };

  render() {
    const { formUrl, isHidden, backAction } = this.props;
    return (
      <div className={classnames('webinar-form', { hidden: isHidden })}>
        <div className="buttons">
          <span className="back-button" onClick={backAction}>
            Back
          </span>
        </div>
        <Iframe url={formUrl} position="static" width="100%" />
      </div>
    );
  }
}

WebinarForm.propTypes = {
  formUrl: PropTypes.string.isRequired,
  finishAction: PropTypes.func.isRequired,
  backAction: PropTypes.func.isRequired,
  isHidden: PropTypes.bool,
};

export default WebinarForm;
