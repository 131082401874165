import React, { Component, Fragment } from 'react';
import { Select } from 'gm-element-react';
import classnames from 'classnames';
import _ from 'lodash';
import './SelectField.scss';

class SelectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsEnum: props.options,
    };
  }
  componentDidUpdate(preProps) {
    if (preProps.options != this.props.options) {
      this.setState({ optionsEnum: this.props.options });
    }
  }
  render() {
    const {
      input,
      meta: { touched, error },
      placeholder,
      extraclassName,
      disabled,
      options,
      multiple,
      typeahead,
      clearable,
    } = this.props;
    const { optionsEnum } = this.state;
    const iserror = touched && error && !_.isNil(error) && !_.isEmpty(error);
    const currentClassName = classnames(
      'select-wrapper',
      iserror ? 'input-error-field' : 'input-field',
      extraclassName
    );
    return (
      <Fragment>
        <div className={currentClassName}>
          <Select
            {...input}
            disabled={disabled}
            placeholder={placeholder || 'Select'}
            multiple={multiple}
            filterable={typeahead}
            clearable={disabled?false:clearable}
            filterMethod={value => {
              if (!_.isNil(typeahead) && typeahead) {
                if (_.isEmpty(value) || _.isNil(value)) {
                  this.setState({ optionsEnum: options });
                } else {
                  let _options = options.filter(
                    o => o.label && _.toString(o.label).toLowerCase().startsWith(value.toLowerCase())
                  );
                  this.setState({ optionsEnum: _options });
                }
              }
            }}
            className={classnames(multiple ? 'multi-select' : '')}>
            {(_.isArray(optionsEnum) ? optionsEnum : []).map(el => {
              return <Select.Option key={el.value} label={el.label} value={el.value} />;
            })}
          </Select>
          <div className="input-error">{iserror ? error : ''}</div>
        </div>
      </Fragment>
    );
  }
}
export default SelectField;
