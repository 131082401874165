// @flow
import _ from 'lodash';
import WebinarService from 'services/api/webinarService';
import { hydrateServicePackage } from './schedulingTransformers';
import { hydrateStatus, hydrateStatuses, hydrateAppointmentNano } from './appointmentTransformers';
import { hydratePaymentMethod } from 'models/User';

import type { PaymentExtended, PaymentsExtended } from 'models/User';

export function hydratePayment(payment: Object) {
  if (payment === null) return {};
  const paymentMethod = payment.payment_method && hydratePaymentMethod(payment.payment_method);
  const actualPaymentMethod =
    payment.actual_payment_method && hydratePaymentMethod(payment.actual_payment_method);
  const latestStatus = payment.latest_status && hydrateStatus(payment.latest_status);
  return _.omitBy(
    {
      paymentMethod,
      actualPaymentMethod,
      latestStatus,
      couponCodes: payment.coupon_codes,
      totalCharge: payment.total_charge,
      payorType: payment.payor_type,
    },
    _.isUndefined
  );
}

export function hydratePaymentExtended(payment: Object): PaymentExtended {
  const hydrateWebinar = WebinarService.hydrateWebinar;
  if (payment === null) return {};
  const webinar = payment.webinar && hydrateWebinar(payment.webinar);
  const servicePackage = payment.service_package && hydrateServicePackage(payment.service_package);
  const appointment = payment.appointment && hydrateAppointmentNano(payment.appointment);
  return _.omitBy(
    {
      ...hydratePayment(payment),
      statuses: hydrateStatuses(payment.statuses),
      webinar,
      servicePackage,
      appointment,
    },
    _.isUndefined
  );
}

export function hydratePaymentsExtended(data: Array<Object>): PaymentsExtended {
  return data.map((payment: Object) => hydratePaymentExtended(payment));
}
