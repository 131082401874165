import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'components/modals/ModalDialog/ModalDialog';
import DeclineTestingForm from './DeclineTestingForm';

class DeclineTesting extends Component {
  render() {
    const { show, onHide, onSubmit } = this.props;
    return (
      <Modal className="md850 decline-modal" show={show} onHide={onHide}>
        <div className="decline-body">
          <div className="decline-title">Thank you for participating in this program.</div>
          <div className="decline-desc">
            By submitting this message, you are notifying Genome Medical that you no longer wish to
            participate and that you are declining genetic testing.
          </div>
          <DeclineTestingForm onCancel={onHide} onSubmit={onSubmit} />
        </div>
      </Modal>
    );
  }
}

DeclineTesting.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DeclineTesting;
