import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import WebinarNotAvailable from 'components/modals/WebinarNotAvailable/WebinarNotAvailable';
import { setModalMode } from 'ducks/modals';
import modalNames from 'constants/ModalNames';

class WebinarNotAvailableHOC extends Component {
  static propTypes = {
    webinar: PropTypes.object,
    loadingWebinar: PropTypes.bool,
    webinarLoaded: PropTypes.bool,
    enable: PropTypes.bool,
    className: PropTypes.string,
    onCloseModal: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    webinarNotAvailableModal: PropTypes.object.isRequired,
  };

  state = {
    webinarClosed: undefined,
    modalEnabled: undefined,
  };

  componentDidMount() {
    this.setStateFromProps();
  }

  componentDidUpdate() {
    this.checkWebinar();
    const webinarJustClosed =
      !this.state.webinarClosed && this.isRegistrationClosed(this.props.webinar);
    const modalJustEnabled = !this.state.modalEnabled && this.props.enable;
    if (webinarJustClosed || (modalJustEnabled && this.isRegistrationClosed(this.props.webinar))) {
      this.setStateFromProps();
      this.props.openModal();
    }
  }

  setStateFromProps = () => {
    const webinarClosed = this.isRegistrationClosed(this.props.webinar);
    if (webinarClosed) this.props.openModal();
    this.setState({
      webinarClosed,
      modalEnabled: this.props.enable,
    });
  };

  isRegistrationClosed = webinar =>
    webinar &&
    (webinar.registrationClosed ||
      (webinar.maxNumberOfAttendees > 0 &&
        webinar.numberOfAttendees >= webinar.maxNumberOfAttendees));

  checkWebinar = () => {
    if (this.props.webinarLoaded && !this.props.webinar) window.location = '/';
  };

  closeModal = () => {
    const { closeModal, onCloseModal } = this.props;
    closeModal();
    setTimeout(() => {
      onCloseModal();
    }, 300);
  };

  render() {
    const { className, children, webinar, enable, webinarNotAvailableModal } = this.props;
    const webinarId = webinar ? webinar.id : null;
    return (
      <div className={className}>
        {children}
        <WebinarNotAvailable
          show={!!(enable && webinarNotAvailableModal.open)}
          onHide={this.closeModal}
          webinarId={webinarId}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ modals }) => ({
  webinarNotAvailableModal: modals[modalNames.webinarNotAvailable],
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(setModalMode(modalNames.webinarNotAvailable, { open: true })),
  closeModal: () => dispatch(setModalMode(modalNames.webinarNotAvailable, { open: false })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebinarNotAvailableHOC);
