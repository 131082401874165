import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import '../V2/SimpleWebinarMessage.scss';

const SimpleWebinarMessage = ({ iconClass, title, subtitle, subcomponent }) => {
  return (
    <div className="sw-message-v2">
      <div className="sw-message-logo-v2">
        <img className="sw-message-img-v2" src={require('images/logo.svg')} />
      </div>
      <div className={classnames('sw-message-icon-v2', iconClass)} />
      {title && <div className="sw-message-title-v2">{title}</div>}
      {subtitle && <div className="sw-message-subtitle-v2">{subtitle}</div>}
      {subcomponent && <div className="sw-message-subtitle-v2">{subcomponent}</div>}
    </div>
  );
};

SimpleWebinarMessage.propTypes = {
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subcomponent: PropTypes.object,
};

export default SimpleWebinarMessage;
