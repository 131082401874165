export const TR_VISIT_REGISTRATION_PAGE = 'Visited Registration Page';
export const TR_CALENDAR_PICK = 'Calendar appointment picked';
export const TR_PAYMENT_SUBMIT = 'Payment information submitted';
export const TR_ACC_CREATED = 'Account created';
export const TR_ACC_ACTIVATED = 'Account activated';
export const TR_USER_LOGINED = 'User logined';
export const TR_USER_FORGOT = 'User forgot password';
export const TR_VIEW_SERVICE = 'User clicked view our services';
export const TR_READ_MORE = 'User clicked read more';
export const TR_SUBMIT_CONTACTUS = 'User has submitted contacts';
export const TR_SEE_SPECS = 'User clicked see more specialists';
export const TR_SEE_SPECS_HELIX = 'User clicked see more specialists on Helix landing';
export const TR_BILLING_INFO_SUBMITTED = 'Billing information submitted';
export const TR_HOME_ADDRESS_SUBMITTED = 'Home address information submitted';
export const TR_SHIPPING_ADDRESS_SUBMITTED = 'Shipping address information submitted';
export const TR_APPOINTMENT_CONFIRMATION_SUBMITTED = 'Appointment confirmed';
export const TR_USER_LOGIN = 'User tries to log in';
export const TR_USER_VISIT_LOGIN_PAGE = 'Visited Login Page';

export const TR_WEBINAR_USER_CLICK_CTA_INVITAE = 'User click CTA for Invitae Webinar';
export const TR_WEBINAR_USER_CLICK_CTA_COUNSYL = 'User click CTA for Counsyl Webinar';
export const TR_WEBINAR_REGISTRATION_OPEN_INVITAE = 'User open register page of Invitae Webinar';
export const TR_WEBINAR_REGISTRATION_OPEN_COUNSYL = 'User open register page of Counsyl Webinar';
export const TR_WEBINAR_OPEN_INVITAE = 'User open Invitae Webinar';
export const TR_WEBINAR_OPEN_COUNSYL = 'User open Counsyl Webinar';
export const TR_WEBINAR_PERSONAL_INFO_SUBMITTED = 'Webinar Personal information submitted';
export const TR_WEBINAR_BILLING_INFO_SUBMITTED = 'Webinar Billing information submitted';
export const TR_POST_WEBINAR_CHOISE_NO_TESTING = 'Post-webinar option no testing is selected';
export const TR_POST_WEBINAR_CHOISE_TESTING = 'Post-webinar option yes to testing is selected';
export const TR_POST_WEBINAR_CHOISE_TALK =
  'Post-webinar option want to talk to someone is selected';

export const TR_OPEN_CHAT = 'Chat window opened';

export const TR_WATCH_VIDEO = 'User watched the Video';

export const TR_SURVEY_CLICKED_NEXT_PAGE = 'Survey: User completed page';

export const TR_SCP_ENCOUNTER_ORDER_CONSENT_SUBMITTED =
  'SCP encounter order consent information submitted';

const track = (eventName, data) => {
  if (window.mixpanel) window.mixpanel.track(eventName, data);
  else
    data !== undefined
      ? console.log('TRACK: ' + eventName, data) //eslint-disable-line
      : console.log('TRACK: ' + eventName); //eslint-disable-line
};

// let gaObject = [];
// gaObject[TR_VISIT_REGISTRATION_PAGE] = { event: 'portal page', portalStep: '/register/account' };

// export const gaTrack = eventName => {
//   if (window && window.dataLayer && gaObject[eventName]) {
//     window.dataLayer.push(gaObject[eventName]);
//   }
// };

export default track;
