import store from '../storage';

const GM_REFERRAL_SOURCE = 'GM_REFERRAL_SOURCE';
const GM_WARM_REFERRAL_SOURCE = 'GM_WARM_REFERRAL_SOURCE';
const GM_WARM_REFERRAL_URL = 'GM_WARM_REFERRAL_URL';
const GM_LOGO_LINK = 'GM_LOGO_LINK';
const GM_REFERRAL_PARTNER_NAME = 'GM_REFERRAL_PARTNER_NAME';
const GM_PARTNER_SOFT_REGISTER_OPTIONAL_FLOW = 'GM_PARTNER_SOFT_REGISTER_OPTIONAL_FLOW';
const GM_CONSENT_SOURCE = 'GM_CONSENT_SOURCE';
//----Insurance Company Constant
export const CIGNA_INSURANCE = 'Cigna';
export const KAISER_INSURANCE = 'Kaiser Foundation Health Plan of the Northwest';

//----- back-end constants -----
export const HelixReferral = 'Helix';
export const IlluminaReferral = 'Illumina UYG';
export const FamilyVipReferral = 'FamilyVIP';
export const InvitaeReferral = 'Invitae';
export const CounsylReferral = 'Counsyl';
export const Lynch_LSIPromoReferral = 'Lynch_LSIPromo';
export const DTC_Proactive_CampaignsReferral = 'DTC_Proactive_Campaigns';
export const Cigna_GenericReferral = 'Cigna_Generic';
export const LineagenReferral = 'Lineagen';
export const Circulogene = 'Circulogene';
export const GenentechReferral = 'Genentech';
export const BioMarinReferral = 'BioMarin';
export const Kaiser_NWReferral = 'Kaiser_NW';
export const InvitaeBiopharmaReferral = 'Invitae Biopharma';
export const VermillionReferral = 'Vermillion';
export const NovartisReferral = 'Novartis';
export const PE_Viacord_PartnerName = 'PE_Viacord';

//-----------------------------

export const BackUrls = Object.freeze({
  [HelixReferral]: '/programs/helix/',
  [IlluminaReferral]: '/programs/illumina/',
  [FamilyVipReferral]: '/programs/family-vip/',
  [Lynch_LSIPromoReferral]: '/programs/lynch-syndrome-international/',
  [DTC_Proactive_CampaignsReferral]: '/understanding-genetic-results',
  [Cigna_GenericReferral]: '/programs/Cigna/',
  [LineagenReferral]: '/programs/lineagen/',
  [Circulogene]: '/programs/circulogene/',
  [BioMarinReferral]: '/programs/seizures/',
  [InvitaeBiopharmaReferral]: '/programs/invitae/biopharma/',
  [VermillionReferral]: '/programs/vermillion',
  [NovartisReferral]: '/programs/novartis/',
});

export const webinarPartnersReferrals = [InvitaeReferral, CounsylReferral];

export function setReferralSource(source) {
  store.useSession().set(GM_REFERRAL_SOURCE, source);
}

export function getReferralSource() {
  return store.useSession().get(GM_REFERRAL_SOURCE) || undefined;
}

export function clearReferralSource() {
  store.useSession().remove(GM_REFERRAL_SOURCE);
}
export function setWarmReferralSource(source) {
  store.useSession().set(GM_WARM_REFERRAL_SOURCE, source);
}

export function getWarmReferralSource() {
  return store.useSession().get(GM_WARM_REFERRAL_SOURCE) || undefined;
}

export function clearWarmReferralSource() {
  store.useSession().remove(GM_WARM_REFERRAL_SOURCE);
}
export function setWarmReferralURL(source) {
  store.useSession().set(GM_WARM_REFERRAL_URL, source);
}

export function getWarmReferralURL() {
  return store.useSession().get(GM_WARM_REFERRAL_URL) || undefined;
}

export function clearWarmReferralURL() {
  store.useSession().remove(GM_WARM_REFERRAL_URL);
}
export function setLogoLink(source) {
  store.useSession().set(GM_LOGO_LINK, source);
}

export function getLogoLink() {
  return store.useSession().get(GM_LOGO_LINK) || undefined;
}

export function clearLogoLink() {
  store.useSession().remove(GM_LOGO_LINK);
}

export function setReferralPartnerName(source) {
  store.useSession().set(GM_REFERRAL_PARTNER_NAME, source);
}

export function getReferralPartnerName() {
  return store.useSession().get(GM_REFERRAL_PARTNER_NAME) || undefined;
}

export function clearReferralPartnerName() {
  store.useSession().remove(GM_REFERRAL_PARTNER_NAME);
}

export function setPartnerSoftRegisterOptioanlFlow(source) {
  store.useSession().set(GM_PARTNER_SOFT_REGISTER_OPTIONAL_FLOW, source);
}

export function isPartnerSoftRegisterOptionalFlow() {
  return store.useSession().get(GM_PARTNER_SOFT_REGISTER_OPTIONAL_FLOW) || false;
}

export function clearPartnerSoftRegisterOptioanlFlow() {
  store.useSession().remove(GM_PARTNER_SOFT_REGISTER_OPTIONAL_FLOW);
}

export function clearConsentSource() {
  store.useSession().remove(GM_CONSENT_SOURCE);
}
export function setConsentSource(value) {
  store.useSession().set(GM_CONSENT_SOURCE, value);
}

export function getConsentSource() {
  return store.useSession().get(GM_CONSENT_SOURCE) || undefined;
}
