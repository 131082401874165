import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import noApptIcon from './no-appt.png';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';
import { SCH_NEW_APPT_HIGH_PRIORITY } from 'constants/HomeAppointmentCardGoals';

class ScheduleNewAppointment extends Component {
  static propTypes = {
    goal: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      followupAptmntExpand: _.get(this.props, ['goal']) == SCH_NEW_APPT_HIGH_PRIORITY,
    };
  }

  render() {
    return (
      <Fragment>
        <div className="followup-appointment-wrapper new-appointment-wrapper">
          <div
            className="header new-schedule-header"
            onClick={() =>
              this.setState({ followupAptmntExpand: !this.state.followupAptmntExpand })}>
            <span>Schedule new appointment</span>
            <img
              src={require('images/common/arrow-down.svg')}
              alt=""
              className="accordian-icon"
              style={{
                transform:
                  _.get(this, ['state', 'followupAptmntExpand'], false) === true
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
              }}
            />
          </div>
          <div
            className="content"
            style={{ display: this.state.followupAptmntExpand === true ? 'block' : 'none' }}>
            <div className="static">
              <div className="left">
                <div className="text">
                  You have no upcoming appointments. Our Certified Genetic Counselors can guide you
                  in making an informed decision about genetic testing and the implications of those
                  results on your health. Schedule a new appointment today!
                </div>
              </div>
              <div className="right">
                <img className="visual-content" src={noApptIcon} alt="no appointment" />
              </div>
            </div>
            <div className="action-items left">
              <ButtonV2
                path={"/scheduling/select-service"}
                className={
                  _.get(this.props, ['goal']) == SCH_NEW_APPT_HIGH_PRIORITY ? '' : 'outline'
                }>
                Schedule New Appointment
              </ButtonV2>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ScheduleNewAppointment;
