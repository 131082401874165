import { hydrateConsultation } from './schedulingTransformers';
import { hydratePayment } from './paymentsTransformers';

export const hydrateServicePackages = data =>
  (data || []).map(o => ({
    id: o.id,
    name: o.name,
    description: o.description,
    price: o.price,
    purchasedAt: o.purchased_at,
    alias: o.alias,
    consultations: (o.consultations || []).map(hydrateConsultation),
    prepaidConsultations: o.prepaid_consultations
      ? o.prepaid_consultations.map(p => ({
          consultation: hydrateConsultation(p.consultation),
          appointmentId: p.appointment_id,
          consultationId: p.consultation_id,
          payment: hydratePayment(p.payment),
        }))
      : undefined,
  }));
