// @flow
import api from './api';

export default class SchedulingAPI {
  getAppointmentsReasons(): Promise<any> {
    return api.get('/appointments/reasons');
  }

  getAppointmentsProviders(): Promise<any> {
    return api.get('/appointments/providers');
  }

  getAppointmentsConsultations(): Promise<any> {
    return api.get('/appointments/consultations');
  }

  getAppointmentsInfo(): Promise<any> {
    return api.get('/appointments/info');
  }

  getAppointmentsAvailability(params: Object): Promise<any> {
    return api.get('/appointments/availability', { params });
  }

  getAvailabilityDates(params: Object): Promise<any> {
    return api.post('/availability/dates', params);
  }

  getAvailabilityTimes(params: Object): Promise<any> {
    return api.post('/availability/times', params);
  }

  submitCouponCode(params: Object): Promise<any> {
    return api.post('/coupon-codes/info', params);
  }
}
