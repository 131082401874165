import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Alert from 'components/widgets/Alert/Alert';
import classnames from 'classnames';

class UpdateNotification extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    operationResult: PropTypes.oneOf([PropTypes.bool, PropTypes.null]),
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { operationResult } = nextProps.profile;
    const { paymentUpdating } = nextProps.user;

    // check if user profile updated
    const userProfileUpdated = _.isNil(prevState.updateProfileResult) && !_.isNil(operationResult);
    // check if user payment data updated
    const paymentUpdated = !paymentUpdating && prevState.paymentUpdating;

    if (userProfileUpdated) {
      const { operationResult, statusMessage } = nextProps.profile;
      return {
        updateProfileResult: operationResult,
        statusMessage,
      };
    }

    if (paymentUpdated) {
      const { error } = nextProps.user;
      return {
        paymentUpdating,
        updatePaymentResult: _.isNil(error),
        statusMessage: error ? error.message : 'Payment method has been successfully updated',
      };
    }

    if (paymentUpdating !== prevState.paymentUpdating)
      return {
        paymentUpdating,
      };

    return null;
  }

  state = {
    updateProfileResult: this.props.profile.operationResult,
    updatePaymentResult: null,
    paymentUpdating: undefined,
    statusMessage: '',
  };

  componentWillUnmount() {
    this.onCloseAlert();
  }

  onCloseAlert = () => {
    if (this.state.updateProfileResult !== null) {
      this.setState({ updateProfileResult: null });
    }
    if (this.state.updatePaymentResult !== null) {
      this.setState({ updatePaymentResult: null });
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { updateProfileResult, updatePaymentResult } = this.state;
    const fade = _.get(this.props, 'fade', true);
    if (_.isNil(updateProfileResult) && _.isNil(updatePaymentResult)) return null;
    const alertType = updateProfileResult || updatePaymentResult ? 'success' : 'danger';
    return (
      <div
        className={classnames(
          fade ? 'notification-container-animated' : 'notification-container-animated-no'
        )}>
        <Alert
          type={alertType}
          message={this.state.statusMessage}
          onClose={this.onCloseAlert}
          fade={fade}
        />
      </div>
    );
  }
}

export default UpdateNotification;
