//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

import SingleSelectPill from './SingleSelectPill.js';
import SingleSelectModal from './SingleSelectModal.js';
//Styles
import './index.scss';

class SingleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSetStateFunc: false,
      modalOpen: false,
      pillElement: null,
    };
    //bindings
    this.checkHasSetStateFunc = this.checkHasSetStateFunc.bind(this);
    this.findLabel = this.findLabel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setPillElement = this.setPillElement.bind(this);
  }
  //functions

  componentDidMount() {
    this.checkHasSetStateFunc();
  }

  checkHasSetStateFunc() {
    if (this.props.setStateFunc) {
      this.setState({ hasSetStateFunc: true });
    }
  }

  findLabel() {
    const data = get(this, 'props.data', null);
    const currentValue = get(this, 'props.currentValue', null);
    const majorPillTitle = get(this, 'props.majorPillTitle', null);
    if (Array.isArray(data)) {
      let currentOption = _.find(data, el => {
        return get(el, 'internal', '') === currentValue;
      });
      if (majorPillTitle) {
        return `${majorPillTitle} (${get(currentOption, 'pillLabel', '') ||
          get(currentOption, 'external', '') ||
          '--'})`;
      } else {
        return get(currentOption, 'pillLabel', '') || get(currentOption, 'external', '') || '--';
      }
    } else return '--';
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  setPillElement(el) {
    if (el) this.setState({ pillElement: el });
  }

  render() {
    return (
      <div className="singleSelectContainer">
        <SingleSelectPill
          label={this.findLabel()}
          modalOpen={get(this, 'state.modalOpen', false)}
          openModal={this.openModal}
          closeModal={this.closeModal}
          setPillElement={this.setPillElement}
        />
        {get(this, 'state.modalOpen', false) && (
          <SingleSelectModal
            closeModal={this.closeModal}
            title={get(this, 'props.title', '')}
            data={get(this, 'props.data', []) || []}
            currentValue={get(this, 'props.currentValue', null)}
            setStateFunc={this.props.setStateFunc}
            hasSetStateFunc={get(this, 'state.hasSetStateFunc', false)}
            convertStrsToNums={get(this, 'props.convertStrsToNums', false)}
            pillElement={get(this, 'state.pillElement', null)}
            defaultValue={get(this, 'props.defaultValue', undefined)}
          />
        )}
      </div>
    );
  }
}

export default SingleSelect;
