import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getConsultation, GetSpeciality } from 'ducks/scheduling';
import './SelectedServiceDetail.scss';

import _ from 'lodash';
import { getDurationString } from '../../../../services/utils';
import moment from 'moment';

class SelectedServiceDetail extends Component {
  formatSpeciality() {
    const speciality = _.get(this.props.scheduling, 'calendarSpeciality', 'N/A');
    const displaySpeciality = GetSpeciality(speciality);
    return displaySpeciality != null ? displaySpeciality.replace('_', ' ') : displaySpeciality;
  }

  formatTime() {
    const {
      scheduling: { calendarTimezone },
      selectedSlot,
    } = this.props;
    const time = moment(selectedSlot.start).format('h:mm A');
    const formattedTimeZone = moment().tz(calendarTimezone).format('z');
    return `${time} ${formattedTimeZone}`;
  }

  render() {
    const { consultation, selectedSlot, selectedDate, isMobile } = this.props;
    return (
      <div className={classnames('SelectedServiceDetail')}>
        <div className="appointment-detail">Appointment Details</div>
        {!isMobile ? (
          <div>
            <div className="detail-container">
              <div className="detail-item-section">
                <div className="item-label">Consultation Type</div>
                {consultation && <div className="item-value">{consultation.name}</div>}
              </div>

              <div className="detail-item-section">
                <div className="item-label">Specialty</div>
                <div className="item-value">{this.formatSpeciality()}</div>
              </div>

              <div className="detail-item-section">
                <div className="item-label">Duration</div>
                {consultation && (
                  <div className="item-value">{getDurationString(consultation.duration)}</div>
                )}
              </div>
            </div>
            <div className="detail-container">
              <div className="detail-item-section">
                <div className="item-label">Date</div>
                <div className="item-value">
                  {!_.isEmpty(selectedDate) ? moment(selectedDate).format('MMM DD, YYYY') : '--'}
                </div>
              </div>

              <div className="detail-item-section">
                <div className="item-label">Time</div>
                <div className="item-value">
                  {!_.isEmpty(selectedSlot) ? this.formatTime() : '--'}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="detail-container">
              <div className="detail-item-section">
                <div className="item-label">Type:</div>
                {consultation && <div className="item-value">{consultation.name} </div>}
              </div>
            </div>
            <div className="detail-container">
              <div className="detail-item-section">
                <div className="item-label">Duration:</div>
                {consultation && (
                  <div className="item-value">{getDurationString(consultation.duration)}</div>
                )}
              </div>

              <div className="detail-item-section">
                <div className="item-label">Specialty:</div>
                <div className="item-value">{this.formatSpeciality()}</div>
              </div>
            </div>
            <div className="detail-container">
              <div className="detail-item-section">
                <div className="item-label">Date:</div>
                <div className="item-value">
                  {!_.isEmpty(selectedDate) ? moment(selectedDate).format('MMM DD, YYYY') : '--'}
                </div>
              </div>

              <div className="detail-item-section">
                <div className="item-label">Time:</div>
                <div className="item-value">
                  {!_.isEmpty(selectedSlot) ? this.formatTime() : '--'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  scheduling: { selectedServiceDescriptor, selectedProduct, consultationTypes },
  scheduling,
}) => ({
  scheduling,
  consultation: getConsultation(selectedServiceDescriptor, selectedProduct, consultationTypes),
});
export default connect(mapStateToProps)(SelectedServiceDetail);
