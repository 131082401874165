import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import './DaySelect.scss';

export default class DaySelect extends Component {

  showDate = ()=>{
    const {startDate}=this.props;
    const endDate = moment(startDate).add(6,'days');
    return `${moment(startDate).format('MMM. DD, YYYY')} - ${moment(endDate).format('MMM. DD, YYYY')}`
  }

  filterDays = () =>{
    const {startDate,selectedDate,slots}=this.props;
    let dateArray=[];
    let currentDate =startDate;
    for (let i = 0; i < 7; i++) {
      const dates = Object.keys(slots);
      const formatedDate = moment(currentDate).format('YYYY-MM-DD');
      dateArray.push(
        {
          date:moment(currentDate).format('DD'),
          month:moment(currentDate).format('MMM'),
          day:moment(currentDate).format('ddd'),
          disabled:dates.indexOf(formatedDate) == -1,
          active:moment(selectedDate).isSame(currentDate,'day'),
          momentDate:moment(currentDate)
      });

      // Move to the next day
      currentDate= moment(currentDate).add(1, 'day');
  }
  return dateArray;
  }
  getPreviousWeekStartDate = () =>{
    const {startDate}=this.props;
    return moment(startDate).add(-7,'days');
  }

  getNextWeekStartDate = () =>{
    const {startDate}=this.props;
    return moment(startDate).add(7,'day');
  }

  showPrevious = ()=>{
    const {startDate}=this.props;
      return moment().isBefore(moment(startDate),'day')
  }
  render() {
    const {handleNext,handlePrevious,
      updateSlots} =this.props;
    const filterDays=this.filterDays();
    const previousStartDate = this.getPreviousWeekStartDate();
    const nextWeekStartDate = this.getNextWeekStartDate();

    return (
      <div className="day-select-container">
        <div className="choose-a-day">Choose a Day </div>
          <div className="date-time-week-select">
            <div>
              {this.showPrevious() &&<span onClick={()=>handlePrevious(previousStartDate)}>
              <img className="arrow-left" src={require('./chevronLeft.svg')} />
              </span>
  }
              <span className="date">{this.showDate()}</span>

              <span onClick={()=>handleNext(nextWeekStartDate)}>
              <img className="arrow-right" src={require('./chevronRight.svg')} />
              </span>
            </div>
          </div>


        <div className="days-container">
          {filterDays.map((day,i)=>{
            return <div key={`${day.date}-${day.month}-${i}`} className={classnames('days-list',{
              disabled:day.disabled,
              active:day.active
            })} onClick={()=>!day.disabled &&updateSlots(day?.momentDate)}>
            <div className="day-name">{day.day}</div>
            <div className="date-name"><div><span>{day.date}</span>{day.month}</div></div>
        </div>
          })}
        </div>

      </div>
    );
  }
}
