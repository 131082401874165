import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { canShowQuestionnaire } from 'ducks/answers';
// import Button from './components/forms/controls/Button';
import RBAC from 'components/high-order-components/RBAC/RBAC';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './ButtonQuestionnaire.scss';

class ButtonQuestionnaire extends Component {
  gotoQuestionnaire() {
    browserHistory.push('/patient/questionnaire');
  }
  render() {
    const { isLoaded, isReviewQn, showQuestionnaire } = this.props;
    if (!showQuestionnaire) return null;
    const text = isReviewQn ? 'Review Online Questionnaire' : 'Complete Questionnaire';
    const shortText = isReviewQn ? 'Review Questionnaire' :  'Complete Questionnaire';
    const isMobile = window.innerWidth < 768;
    return isLoaded ? (
        (<section className="questionnaire-info">
          {!isReviewQn && <h3>Online Questionnaire</h3>}
          {!isReviewQn && <div className="questionnaire-description">To help us make the most of our time together, please fill out the online questionnaire about your personal and family history. This should take a total of about 5 minutes.</div>}
          <ButtonV2 className="scp-complete-checklist-button" onClick={this.gotoQuestionnaire}>
            {isMobile ? shortText : text}
          </ButtonV2>
        </section>)
    ) : (
      <div className="free-space" />
    );
  }
}

ButtonQuestionnaire.propTypes = {
  isLoaded: PropTypes.bool,
  isReviewQn: PropTypes.bool,
  showQuestionnaire: PropTypes.bool.isRequired,
};

export default RBAC(
  ['nonScp'],
  connect(state => {
    const { answers } = state;
    return {
      isLoaded: answers.loaded,
      isReviewQn: answers.completed,
      showQuestionnaire: canShowQuestionnaire(state),
    };
  })(ButtonQuestionnaire)
);
