import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { Tooltip } from 'gm-element-react';
import CheckBoxField from 'components/forms/controls/V2/CheckBoxField';
import Button from 'components/forms/controls/ButtonV2';
import VideoModal from 'components/modals/SCP/VideoModal/VideoModal';
import './TestReviewConfirm.scss';
import { shouldBeTrue } from 'services/validate';
import gaTrack, { GA_TR_TESTREQUEST_REVIEW_PAGE } from 'services/gaTrack';

export const SCP_TEST_CONFIRM_FORM_NAME = 'scp-test-confirm-form';
const videoURL = 'https://player.vimeo.com/video/475457454?app_id=122963';

class ScpTestReviewConfirm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    //toPreviousStep: PropTypes.function,
    onSubmit: PropTypes.func.isRequired,
    currentScpEncounter: PropTypes.object,
    shippingAddress: PropTypes.object,
    personalInfo: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpenVideo: false,
    };
  }

  componentDidMount() {
    gaTrack(GA_TR_TESTREQUEST_REVIEW_PAGE);
  }

  onSubmit = () => {
    this.props.onSubmit({
      scpOrderConsent: true,
    });
  };

  openVideo = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isOpenVideo: true });
  };

  closeVideo = () => {
    this.setState({ isOpenVideo: false });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      toPreviousStep,
      valid,
      currentScpEncounter,
      shippingAddress,
      personalInfo,
    } = this.props;

    return (
      <div className="scp-test-review-confirm-wrapper">
        <h2 className="review-confirm-header">Review and Confirm</h2>

        <div className="review-info-container" data-hj-suppress>
          <div className="review-info-wrapper lab-detail-wrapper">
            <div className="lbl-section">Lab</div>
            <div className="val-section">
              {_.isNil(_.get(currentScpEncounter, 'labName', '--'))
                ? '--'
                : _.get(currentScpEncounter, 'labName', '--')}
            </div>
          </div>
          <div className="review-info-wrapper">
            <div className="lbl-section">Test Name</div>
            <div className="val-section">
              {_.isNil(_.get(currentScpEncounter, 'testName', '--'))
                ? '--'
                : _.get(currentScpEncounter, 'testName', '--')}
            </div>
          </div>

          <div className="review-info-wrapper ship-address-detail-wrapper">
            <div className="lbl-section">Shipping Address</div>
            {shippingAddress && (
              <div className="val-section">
                {_.get(shippingAddress, 'addressLine1', '')}{' '}
                {!_.isNil(_.get(shippingAddress, 'addressLine2', ''))}
                <br />
                {_.get(shippingAddress, 'addressCity', '')},{' '}
                {_.get(shippingAddress, 'addressState', '')}{' '}
                {_.get(shippingAddress, 'addressZipCode', '')}
              </div>
            )}
          </div>
          <div className="review-info-wrapper">
            <div className="lbl-section">Personal Information</div>
            <div className="val-section">
              {_.get(personalInfo, 'fullName', '')} <br /> {_.get(personalInfo, 'phone', '')}
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(this.onSubmit)} className="test-review-form">
          <div className="confirm-info-container">
            <div className="label-section" style={{ marginBottom: '24px' }}>
              Please confirm the following:
            </div>
            <Field
              name="chkbox1"
              type="checkbox"
              label={
                <Fragment>
                  <span className="checkmark" />
                  <div className="label-section">
                    To the best of my knowledge, the personal and family history information I have
                    entered is accurate.
                  </div>
                </Fragment>
              }
              component={CheckBoxField}
              validate={shouldBeTrue}
            />

            <Field
              name="chkbox2"
              type="checkbox"
              label={
                <Fragment>
                  <span className="checkmark" />
                  <div className="label-section">
                    I understand that a Genome Medical physician or a Genome Medical provider will
                    review the information I have provided and I may be contacted with follow-up
                    questions.
                  </div>
                </Fragment>
              }
              component={CheckBoxField}
              validate={shouldBeTrue}
            />

            <Field
              name="chkbox3"
              type="checkbox"
              label={
                <Fragment>
                  <span className="checkmark" />
                  <div className="label-section">
                    I understand that a test kit will be mailed to me, and it is my responsibility
                    to follow instructions and submit the sample promptly.
                  </div>
                </Fragment>
              }
              component={CheckBoxField}
              validate={shouldBeTrue}
            />

            <Field
              name="chkbox4"
              type="checkbox"
              label={
                <Fragment>
                  <span className="checkmark" />
                  <div className="label-section">
                    I have been offered a genetic counseling session with a Genome Medical Certified
                    Genetic Counselor and have declined this option.
                  </div>
                </Fragment>
              }
              component={CheckBoxField}
              validate={shouldBeTrue}
            />

            <Field
              name="chkbox5"
              type="checkbox"
              label={
                <Fragment>
                  <span className="checkmark" />
                  <div className="label-section">
                    I understand that the{' '}
                    <a className="video-link-testing-cost" onClick={this.openVideo}>
                      cost for this testing
                    </a>, which will be conducted by a separate laboratory, will be my
                    responsibility. I understand I can work directly with the laboratory regarding
                    payment questions or concerns.
                  </div>
                </Fragment>
              }
              component={CheckBoxField}
              validate={shouldBeTrue}
            />
          </div>

          <div className="buttons">
            <Tooltip
              className="request-test-tooltip"
              effect="dark"
              content={'Please acknowledge above to request a test'}
              placement="top"
              disabled={valid}>
              <Button
                className="button btn-request-test"
                type="submit"
                disabled={submitting || !valid}>
                Request Test
              </Button>
            </Tooltip>
            {toPreviousStep && (
              <a className="btn-back" onClick={toPreviousStep}>
                Back
              </a>
            )}
          </div>
        </form>
        <VideoModal
          videoURL={videoURL}
          loop={0}
          show={this.state.isOpenVideo}
          onCancel={this.closeVideo}
        />
      </div>
    );
  }
}

export default reduxForm({
  form: SCP_TEST_CONFIRM_FORM_NAME,
})(ScpTestReviewConfirm);
