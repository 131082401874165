import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import './RadioButtonGroup.scss';

class RadioButtonGroup extends React.Component {
  state = {
    checked: [],
  };

  componentDidMount() {
    const { children, input: { value } } = this.props;
    const checked = children.map(c => (value === '' ? false : c.value === value));
    this.setCheckedArrayState(checked);
  }

  componentDidUpdate(prevProps) {
    const { input: { value }, children } = this.props;
    if (prevProps.input.value !== value) {
      const checked = children.map(c => c.value === value);
      this.setCheckedArrayState(checked);
    }
  }

  setCheckedArrayState = checked => {
    this.setState({ checked });
  };

  toggleChecked = index => {
    const checked = this.props.children.map((c, i) => i === index);
    this.setState({ checked });
  };

  render() {
    const {
      className,
      children,
      input,
      shake,
      label,
      meta: { touched, error },
      input: { name },
      disabled,
    } = this.props;

    return (
      <div className={classnames('gm-radio-control', className)}>
        <label className="gm-radio-label">{label}</label>
        <div className="gm-radio-group">
          {children.map((c, index) => {
            const key = uuidv4();
            return (
              <div className="radio" key={`${key}-${name}-${index}`}>
                <input
                  id={`${key}-${name}-radio-${index}`}
                  key={`${key}-${name}-radio-${index}`}
                  type="radio"
                  name={name}
                  checked={this.state.checked[index] || false}
                  onClick={() => {
                    this.toggleChecked(index);
                    input.onChange(c.value);
                  }}
                  disabled={disabled}
                />
                <label htmlFor={`${key}-${name}-radio-${index}`} className="radio-label">
                  {c.label}
                </label>
                {c.description && (
                  <div className="radio-button-description">
                    <div className={'info-outline-icon' + (shake ? ' shake' : '')} />
                    <div className="description-hint">{c.description}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="gm-radio-control__invalid">{touched && error}</div>
      </div>
    );
  }
}


RadioButtonGroup.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.array,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
};

export default RadioButtonGroup;
