import React from 'react';

import './Footer.scss';
import facebook from './social-facebook.svg';
import linkedin from './social-linkedin.svg';
import twitter from './social-twitter.svg';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-text">
            <div>
              Copyright ©. Genome Medical, Inc. {new Date().getFullYear()}.
              All&nbsp;rights&nbsp;reserved.
            </div>
          </div>
          <div className="links-wrapper">
            <div className="social-networks">
              <div>
                <a href="https://www.facebook.com/genomemedical" target="_blank">
                  <img src={facebook} alt="Facebook" />
                </a>
                <a href="https://www.linkedin.com/company/genome-medical" target="_blank">
                  <img src={linkedin} alt="Linked In" />
                </a>
                <a href="https://twitter.com/@genomemed" target="_blank">
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </footer>
    );
  }
}

export default Footer;
