import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import authService from 'services/api/authService';
import api from 'services/api/api';
import _ from 'lodash';

export class EducationGuard extends Component {
  render() {
    const hastoken = api.hasToken();
    const { children, _currentUser } = this.props;
    if (!hastoken) {
      return <Fragment>{children}</Fragment>;
    } else if (hastoken && !_.isNil(_currentUser)) {
      return <Fragment>{children}</Fragment>;
    }
    return null;
  }
}

export default connect(
  state => {
    const _currentUser = authService.getCurrentUser(state);
    return { _currentUser };
  },
  dispatch => {
    return { dispatch };
  }
)(EducationGuard);
