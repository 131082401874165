import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import './RadioCardField.scss';

class RadioCardField extends React.Component {
  state = {
    checked: [],
  };

  componentDidMount() {
    const { children, input: { value } } = this.props;
    const checked = children.map(c => (value === '' ? false : c.value === value));
    this.setCheckedArrayState(checked);
  }

  setCheckedArrayState = checked => {
    this.setState({ checked });
  };

  toggleChecked = index => {
    const checked = this.props.children.map((c, i) => i === index);
    this.setState({ checked });
  };

  shouldhaveOneSelected = () => {
    const ischecked = _.find(this.state.checked || [], function(state) {
      return state === true;
    });
    return ischecked;
  };

  render() {
    const {
      className,
      children,
      input,
      meta: { touched, error },
      input: { name },
      disabled,
    } = this.props;
    const geneticError = !this.shouldhaveOneSelected() && touched && error;
    return (
      <div className={classnames('scp-gm-radio-card-control', className)}>
        <div className="gm-radio-group">
          {children.map((c, index) => {
            const ischecked = this.state.checked[index];
            const showerror = ischecked && error;
            return (
              <div
                className={classnames('radio', { selected: ischecked, error: showerror })}
                key={`${name}-${index}`}
                onClick={() => {
                  this.toggleChecked(index);
                  input.onChange(c.value);
                }}>
                <div className="radio-label-row">
                  <input
                    id={`${name}-radio-${index}`}
                    key={`${name}-radio-${index}`}
                    type="radio"
                    name={name}
                    checked={ischecked || false}
                    disabled={disabled}
                  />
                  <label htmlFor={`${name}-radio-${index}`} className="radio-label">
                    {c.label}
                  </label>
                </div>
                <div className="radio-desc-row">
                  {c.description && <div className="radio-button-description">{c.description}</div>}
                </div>
                {showerror && <div className="radio-error-row">{error}</div>}
              </div>
            );
          })}
        </div>
        <div className="gm-radio-control__invalid">{geneticError && error}</div>
      </div>
    );
  }
}

RadioCardField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.array,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
};

export default RadioCardField;
