//React & Redux
import React from 'react';

//Images
import xIcon from 'images/close.svg';

//Lodash
import get from 'lodash/get';

class SingleSelectTitleRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //bindings
  }
  //functions

  render() {
    const containerClass = get(this, 'props.shouldHaveDropShadow', false)
      ? 'singleSelectModalTopRow scrollableModalDropShadowTop'
      : 'singleSelectModalTopRow';
    return (
      <div className={containerClass}>
        <div className="singleSelectModalTopRowTitle">
          {get(this, 'props.title', '') || 'Please select'}
        </div>
        <div className="singleSelectModalXIcon">
          <img src={xIcon} onClick={this.props.closeModal} />
        </div>
      </div>
    );
  }
}

export default SingleSelectTitleRow;
