import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import ProgressTrack from 'components/widgets/SCP/ProgressTrack/ProgressTrack';
import GetReadyForYourAppointment from './steps/GetReadyForYourAppointment';
import MeetWithYourGeneticCounselor from './steps/MeetWithYourGeneticCounselor';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import authService from 'services/api/authService';
import schedulingService from 'services/api/schedulingService';
import { loadCurrentAppointment } from 'ducks/appointment';
import {
  fetchMyGuideProgress,
  SCHEDULE_YOUR_APPOINTMENT_STEP,
  GET_READY_FOR_YOUR_APPOINTMENT_STEP,
  MEET_WITH_YOUR_GENETIC_COUNSELOR,
} from 'ducks/guides/guideProvider';
import './MyGuidePage.scss';
import gaTrack, { GA_TR_MY_GUIDE_PAGE } from '../../../../../services/gaTrack';
import classnames from 'classnames';

const FETCHING_INTERVAL_MS = 15000;

const stepTitles = {
  [SCHEDULE_YOUR_APPOINTMENT_STEP]: 'Schedule your appointment',
  [GET_READY_FOR_YOUR_APPOINTMENT_STEP]: 'Get ready for your appointment',
  [MEET_WITH_YOUR_GENETIC_COUNSELOR]: 'Meet with your genetic counselor',
};

const stepComponents = {
  [GET_READY_FOR_YOUR_APPOINTMENT_STEP]: GetReadyForYourAppointment,
  [MEET_WITH_YOUR_GENETIC_COUNSELOR]: MeetWithYourGeneticCounselor,
};

export class MyGuidePage extends Component {
  state = {
    isMobile: false,
    showVideoInstructionForMobile: false,
    showAppointmentInfoForMobile: true,
  };

  static hydrateSteps = (steps, activeStepId) => {
    return steps.map(step => ({
      id: step.id,
      title: stepTitles[step.id],
      done: step.done,
      active: step.id === activeStepId,
    }));
  };

  componentDidMount() {
    gaTrack(GA_TR_MY_GUIDE_PAGE);
    this.checkAppointment();
    this.setState({ isMobile: schedulingService.isMobile() });
    window.addEventListener('resize', this.onResize);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (!!nextProps.activeGuide && !nextProps.activeGuide.fetchingProgress) ||
      this.state.isMobile != nextState.isMobile
    );
  }

  componentDidUpdate() {
    if (this.appointmentLoaded !== this.props.appointmentLoaded) {
      this.checkAppointment();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    window.removeEventListener('resize', this.onResize);
  }

  appointmentLoaded = false;

  checkAppointment() {
    const { appointmentLoaded } = this.props;
    this.appointmentLoaded = appointmentLoaded;
    if (appointmentLoaded) this.startFetchingProgress();
  }

  startFetchingProgress() {
    const { appointment, fetchProgress, activeGuide, loadAppointment } = this.props;
    if (this.interval || _.isEmpty(appointment) || appointment.isComplete) return;
    fetchProgress(appointment);

    this.interval = setInterval(() => {
      if (activeGuide && !activeGuide.fetchingProgress) {
        const needToRequest =
          this.props.activeGuide.activeStepId === MEET_WITH_YOUR_GENETIC_COUNSELOR;
        needToRequest
          ? loadAppointment()
          : Promise.resolve().then(() => fetchProgress(this.props.appointment, this.props.answers));
      }
    }, FETCHING_INTERVAL_MS);
  }

  renderNoAppointment() {
    const { meLoading } = this.props.user;
    const isMobile = _.get(this, ['state', 'isMobile'], false);

    return (
      <div className="scp-my-guide-page">
        <div
          className={classnames('no-appointments full-height no-footer', {
            'is-mobile': isMobile,
          })}>
          {isMobile && <h2 className="title-no-appointment">No Scheduled Appointment</h2>}
          <p className="no-schedule-text">
            You have no scheduled appointments. Would you like to schedule one?
          </p>
          <ButtonV2
            className="schedule-button"
            path={"/scheduling/select-service"}
            disabled={meLoading}>
            Schedule an Appointment
          </ButtonV2>
        </div>
      </div>
    );
  }

  gotoVideoInstructionForMobile = () => {
    this.setState({
      showVideoInstructionForMobile: !this.state.showVideoInstructionForMobile,
      showAppointmentInfoForMobile: !this.state.showAppointmentInfoForMobile,
    });
  };

  gotoAppointmentInfoForMobile = () => {
    this.setState({
      showVideoInstructionForMobile: !this.state.showVideoInstructionForMobile,
      showAppointmentInfoForMobile: !this.state.showAppointmentInfoForMobile,
    });
  };

  onResize = () => {
    this.setViewMode();
  };

  setViewMode() {
    const { isMobile } = this.state;
    if (isMobile && !schedulingService.isMobile()) {
      this.setState({ isMobile: false });
    } else if (!isMobile && schedulingService.isMobile()) {
      this.setState({ isMobile: true });
    }
  }

  render() {
    const { activeGuide, appointment } = this.props;
    const typeString = _.get(appointment, 'byPhone', false) ? 'Phone' : 'Video';

    if (activeGuide && activeGuide.fetchingProgress) return null;

    if (_.isEmpty(appointment) || appointment.isComplete || appointment.isMissed) {
      return this.renderNoAppointment();
    }

    const steps = activeGuide.steps;
    const activeStepId = activeGuide.activeStepId;
    const stepComponent = activeStepId && stepComponents[activeStepId];
    const isMobile = _.get(this, ['state', 'isMobile'], false);
    const isActiveStepMeetWithGeneticCounselor =
      activeStepId == 'gm/my-guide/MEET_WITH_YOUR_GENETIC_COUNSELOR';

    return (
      <div className="scp-my-guide-page" data-hj-suppress>
        <div
          className={classnames('has-appointments full-height no-footer', {
            'is-mobile': isMobile,
          })}>
          {!isActiveStepMeetWithGeneticCounselor &&
            !this.state.showVideoInstructionForMobile && (
              <h3 className="block-title">Your Appointment is Booked!</h3>
            )}

          <div className="my-guide-page-container">
            {stepComponent &&
              React.createElement(stepComponent, {
                isMobile: isMobile,
                gotoAppointmentInfoForMobile: this.gotoAppointmentInfoForMobile,
                gotoVideoInstructionForMobile: this.gotoVideoInstructionForMobile,
                showAppointmentInfoForMobile: this.state.showAppointmentInfoForMobile,
                showVideoInstructionForMobile: this.state.showVideoInstructionForMobile,
              })}
          </div>
        </div>
      </div>
    );
  }
}

MyGuidePage.propTypes = {
  currentUser: PropTypes.object,
  appointment: PropTypes.object,
  activeGuide: PropTypes.object,
  user: PropTypes.object,
  answers: PropTypes.object,
  fetchProgress: PropTypes.func.isRequired,
  loadAppointment: PropTypes.func.isRequired,
  appointmentLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  currentUser: authService.getCurrentUser(state),
  appointment: state.appointment.data,
  appointmentLoaded: state.appointment.loaded && !state.appointment.loading,
  user: state.myGuide.guideProvider,
  activeGuide: state.myGuide[state.myGuide.guideProvider.activeGuideName],
  answers: state.answers,
});

const mapDispatchToProps = dispatch => ({
  fetchProgress: appointment => dispatch(fetchMyGuideProgress(appointment)),
  loadAppointment: () => dispatch(loadCurrentAppointment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyGuidePage);
