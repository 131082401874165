import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import './Calendar.scss';
import SCPPatientsToolbar from './toolbar/SCPPatientsToolbar';
import gmiLoader from '../../images/gmi-loader.gif';
import NewCalendar from './NewCalendar';

const isDevelopment = process.env.GM_ENV === 'development';

export const MIN_HOUR = isDevelopment ? 7 : 7;
export const MAX_HOUR = isDevelopment ? 21 : 21;

class Calendar extends Component {
  static propTypes = {
    events: PropTypes.array.isRequired,
    date: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isShaded: PropTypes.bool,
    white: PropTypes.bool,
    onDateChanged: PropTypes.func,
    isLoading: PropTypes.bool,
    flow: PropTypes.string,
  };

  state = { ...this.defaultState };
  defaultState = {
    selectedDate: null,
    availableSlots: {
      timesOfDay: []

    },
    selectedSlot: {},
    isDateSelected: false,
  };

  getToolbarComponent() {
    return SCPPatientsToolbar;
  }


  updateCalendar = async newState => {
    return new Promise(resolve => {
      this.setState(
        {
          ...this.state,
          ...newState,
        },
        resolve
      );
    });
  };

  clearCalendar = async () => {
    return new Promise(resolve => {
      this.setState({ ...this.defaultState }, resolve);
    });
  };

  render() {
    const {
      events,
      date,
      isShaded,
      isLoading,
      flow,
      isRescheduling,
      toPreviousStep,
      onDateChanged,
      availableDates,
      isLoadingAvailableDates,
      isMobile,
      isPreSelectedFlow
    } = this.props;
    const { selectedDate, availableSlots, selectedSlot, isDateSelected } = this.state;
    const ToolbarComponent = this.getToolbarComponent();
    const slots = events.filter(event => event.type !== 'NA');

    const showToolBar = isPreSelectedFlow || isRescheduling ;

    return (
      <div className="gm-cal-container">
        {showToolBar &&
        <ToolbarComponent
          isArrowVisible={isShaded}
          flow={flow}
          isRescheduling={isRescheduling}
          clearCalendar={this.clearCalendar}
        />
      }

        <NewCalendar
          startDate={this.props.date}
          slots={slots.length ? slots : []}
          onDateChange={onDateChanged}
          isLoading={isLoading}
          toPreviousStep={toPreviousStep}
          isRescheduling={isRescheduling}
          selectedDate={selectedDate}
          availableSlots={availableSlots}
          selectedSlot={selectedSlot}
          isDateSelected={isDateSelected}
          updateCalendar={this.updateCalendar}
          clearCalendar={this.clearCalendar}
          availableDates={availableDates}
          isLoadingAvailableDates={isLoadingAvailableDates}
          isMobile={isMobile}
        />
        {isLoading && <img className="calendar-loader" src={gmiLoader} />}
      </div>
    );
  }
}

export default Calendar;
