import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import Button from 'components/forms/controls/Button';
import YellowHint from 'components/widgets/YellowHint/YellowHint';
import Link from 'components/widgets/Link/Link';
import RecordNotAvailable from 'components/widgets/RecordNotAvailable/RecordNotAvailable';
import WebinarSummary from './WebinarSummary';
import track, { TR_WEBINAR_OPEN_INVITAE } from 'services/track';
import './WebinarInfo.scss';
import EventInfo from './EventInfo';

class WebinarInfo extends Component {
  static propTypes = {
    webinar: PropTypes.object.isRequired,
    finishViewWebinar: PropTypes.func.isRequired,
  };

  state = { noRecordingModal: false, currentDate: moment() };

  componentDidMount() {
    track(TR_WEBINAR_OPEN_INVITAE);
    this.interval = setInterval(() => {
      if (!this.interval) return;
      const currentDate = moment();
      this.setState({ currentDate });
      if (this.canProceedAfterWebinar(currentDate)) clearInterval(this.interval);
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
  }

  showNoRecording = () => this.setState({ noRecordingModal: true });
  hideNoRecording = () => this.setState({ noRecordingModal: false });

  isWebinarStarted = () => {
    if (_.isEmpty(this.props.webinar)) return false;

    return this.state.currentDate.isAfter(moment(this.props.webinar.startsAt));
  };

  canJoinWebinar = () => {
    if (_.isEmpty(this.props.webinar)) return false;
    if (this.canProceedAfterWebinar()) return true;

    return this.state.currentDate.isAfter(
      moment(this.props.webinar.startsAt).subtract(15, 'minute')
    );
  };

  canProceedAfterWebinar = () => {
    if (_.isEmpty(this.props.webinar)) return false;

    return this.state.currentDate.isAfter(moment(this.props.webinar.startsAt).add(45, 'minute'));
  };

  render() {
    const { webinar, finishViewWebinar } = this.props;

    // Refactor DOM structure, get rid of outer div
    return (
      <div className="webinar-info-wrapper">
        <EventInfo
          className="webinar-info"
          title="View the webinar"
          date={webinar.startsAt}
          photo={webinar.presenter.photoBig}>
          <WebinarSummary webinar={webinar} className="webinar-summary" />
          <div className={classnames('webinar-hint', { hidden: this.canProceedAfterWebinar() })}>
            At the event time, join the webinar via your Genome Medical account portal. We hope you
            enjoy the session!
          </div>
          <Button
            href={webinar.videoSessionUrl}
            className={classnames('join-webinar-button', { hidden: this.canProceedAfterWebinar() })}
            target="_blank"
            disabled={!this.canJoinWebinar()}>
            Join Live Webinar
          </Button>
        </EventInfo>
        <div
          className={classnames('download-zoom-hint', { hidden: this.canProceedAfterWebinar() })}>
          <a href="https://zoom.us/download" target="_blank">
            <i className="material-icons icon">file_download</i>
            Download Zoom Client for Meetings
          </a>
          <ul>
            <li>It takes about 1 minute to complete</li>
            <li>You’ll need this software to participate in the webinar</li>
          </ul>
        </div>
        <div className={classnames({ hidden: !this.canProceedAfterWebinar() })}>
          <YellowHint icon={require('images/common/video-player.svg')}>
            <p>Missed the live webinar?</p>
            <p>
              <Link to={webinar.recordingUrl} onClick={this.showNoRecording} blank>
                View webinar recording
              </Link>
            </p>
          </YellowHint>
          {this.isWebinarStarted() && (
            <div className="next-step">
              Viewed the webinar? Click ‘Next’ to complete post webinar questionnaire.
              <Button onClick={finishViewWebinar}>Next</Button>
            </div>
          )}
          <RecordNotAvailable show={this.state.noRecordingModal} onHide={this.hideNoRecording} />
        </div>
      </div>
    );
  }
}

export default WebinarInfo;
