import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomCheckbox from './CustomCheckbox/CustomCheckbox';
import classnames from 'classnames';
import './CheckboxGroup.scss';

class CheckboxGroup extends Component {
  isChecked = item => {
    const { value } = this.props.input;
    return value && value.indexOf(item.name) >= 0;
  };

  handleChange = value => {
    const { input, singleItem } = this.props;
    if (singleItem) return input.onChange(value.name);
    const newValues = this.isChecked(value)
      ? input.value.filter(name => name !== value.name)
      : [...input.value, value.name];
    input.onChange(newValues);
  };

  render() {
    const { singleItem, items, className, label, meta: { touched, error }, disabled } = this.props;

    return (
      <div
        className={classnames('gm-form-field', className, { error: touched && error })}
        disabled={disabled}>
        <label className="gm-form-label">{(touched && error) || label}</label>
        <div className="checkbox-group-container">
          {items &&
            items.map((item, i) => (
              <CustomCheckbox
                className="checkbox-group-item"
                isSingle={singleItem ? 'custom-checkbox-circle' : ''}
                key={i++}
                value={item}
                checked={this.isChecked(item)}
                handleOnClick={this.handleChange}
              />
            ))}
        </div>
      </div>
    );
  }
}

CheckboxGroup.propTypes = {
  singleItem: PropTypes.bool,
  items: PropTypes.array,
  input: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CheckboxGroup;
