import Context from '../Context';
import RedirectAction from './RedirectAction';
import { isWebinar } from 'containers/layouts/LayoutProps';

export default class RedirectToWebinar extends RedirectAction {
  constructor() {
    super(null, '/webinar');
  }

  canExecute(context: Context = null): boolean {
    const { router, state, layoutType } = context.getParams();
    const { me } = state.user;
    return (
      me &&
      isWebinar(state) &&
      layoutType === 'appointment' &&
      !router.location.pathname.startsWith('/patient/schedule')
    );
  }
}
