import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import { updateConsentStatus } from 'ducks/user';
import { ConsentStatus, JOTFORM_URL } from 'constants/CommonConstants';
import { getConsentSource, clearConsentSource } from 'services/misc/ReferralSource';
import gmiLogo from '../../../../images/logo.svg';
import ClinicalTrialConsentForm from '../../../../components/ClinicalTrialConsentForm/ClinicalTrialConsentForm';
import './ClinicalTrailContainer.scss';
import { MANUAL_CONSENT_OUTREACH, removeAction } from '../../../../ducks/misc/redirectActions';

class ClinicalTrialContainer extends Component {
  constructor(props) {
    super(props);
  }
  handleSubmitConsentFrom = (status) => {
    const { dispatch, me, redirectActions } = this.props;
    const isManualOutreach = redirectActions.actions[MANUAL_CONSENT_OUTREACH];
    const userConsentStatus = _.get(me, [
      'latestPatientToReconatctStatusObject',
      'user_consent',
      'status',
    ]);
    const reqObj = {
      consent_version_id: me.activeConsent.latestConsentVersion.id,
      status: status,
    };
    if (
      isManualOutreach &&
      userConsentStatus == ConsentStatus.SKIPPED &&
      status == ConsentStatus.SKIPPED
    ) {
      clearConsentSource();
      dispatch(removeAction(MANUAL_CONSENT_OUTREACH));
    } else {
      dispatch(updateConsentStatus(reqObj)).then((res) => {
        clearConsentSource();
        dispatch(removeAction(MANUAL_CONSENT_OUTREACH));
        return res;
      });
    }
  };

  formURL = () => {
    const {
      me: { hasGuardian, email, firstName, lastName, isUnborn, uuid, activeConsent, usersGuardian },
    } = this.props;
    const ConsentSource = getConsentSource();

    const first = !isUnborn ? firstName : '';
    const last = !isUnborn ? lastName : '';
    const formId = _.get(activeConsent, ['latestConsentVersion', 'formId']);
    const relationship = hasGuardian ? _.get(usersGuardian, 'relationship') : 'Self';
    let url = JOTFORM_URL + formId + '?uuid=' + uuid + '&relationship_to_patient=' + relationship;
    if (first || last) url += `&name=${first} ${last}`;
    if (email) url += `&email=${email}`;
    url += `&consentsource=${ConsentSource ? ConsentSource : 'patient_portal'}`;

    return url;
  };

  isManualConsentSource = () =>{
    const ConsentSource = getConsentSource();
    return (['manual_outreach_email','manual_outreach_sms', 'automated_outreach_email', 'automated_outreach_sms'].includes(ConsentSource));
  }

  render() {
    const { me } = this.props;
    const formId = _.get(me, ['activeConsent', 'latestConsentVersion', 'formId']);

    return (
      <div className="scp-email-verification-soft-reg-container clinical-trail-container">
        <div className="scp-soft-rgstrtion-container">
          <div className="soft-rgstrtion-wrapper qualification-wrapper">
            <section className="grail-quali-container">
              <section className="grail-quali-header v2">
                <div className="genome-header-logo">
                  {/* <img className="genome-header-img" src={require('images/galleri-logo.svg')} /> */}
                  <img className="genome-header-img" src={gmiLogo} />
                </div>
                <div className="genome-header-title">
                  <h2 className="grail-quali-title">PATIENT AUTHORIZATION FOR RECONTACT</h2>
                  <h4 className="v2">
                    While Genome Medical has certain rights under HIPAA to use your data to provide
                    care and for research purposes, we may also contact you for the purposes of
                    clinical trials, treatments or products that may be relevant to your health
                    condition.
                  </h4>
                  {this.isManualConsentSource() &&
                  <>
                  <h4>
                    In the course of doing our work, Genome Medical may learn of clinical trials, treatments, or products that may be relevant to your health. These trials could contribute to advancing medical research.
                  </h4>
                  <h4>
                    Your privacy and trust remain our utmost priorities. If you would like us to contact you about these research or health-related opportunities, please use the form below to sign an authorization. This will allow us to discuss these opportunities with you in more detail.
                  </h4>
                  <h4>
                    We understand that the realm of genetics and clinical trials can be complex. If you have questions or need further information, our dedicated team of genetic counselors is on standby to assist.
                    Call us at <span className="phone">
                      <a href="tel:+18776880992">(877) 688-0992</a>
                    </span>{' '}
                    or send us an email at <a href="mailto: info@genomemedical.com">
                      info@genomemedical.com
                    </a> to get in touch.
                  </h4>
                  </>}
                </div>
              </section>
              <section className="grail-quali-body v2">
                <ClinicalTrialConsentForm
                  onSubmit={() => this.handleSubmitConsentFrom(ConsentStatus.PROCESSING)}
                  formId={formId}
                  url={this.formURL()}
                />
              </section>
            </section>
            <div className="skip-step-section">
              <div
                className="skip-step"
                onClick={() => this.handleSubmitConsentFrom(ConsentStatus.SKIPPED)}
              >
                Skip
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      me: state.user.me,
      redirectActions: state.redirectActions,
    };
  },
  (dispatch) => {
    return { dispatch };
  }
)(ClinicalTrialContainer);
