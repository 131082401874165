import React, { Fragment } from 'react';
import { Input } from 'gm-element-react';
import classnames from 'classnames';
import _ from 'lodash';
import './InputField.scss';

const InputField = ({
  input,
  meta: { touched, error },
  type,
  placeholder,
  trim,
  extraclassName,
  disabled,
  min,
  max
}) => {
  const iserror = touched && error && !_.isNil(error) && !_.isEmpty(error);
  const currentClassName = classnames(
    'input-wrapper',
    iserror ? 'input-error-field' : 'input-field',
    extraclassName
  );
  return (
    <Fragment>
      <div className={currentClassName}>
        <Input {...input} type={type} placeholder={placeholder} trim={trim} disabled={disabled} min={min} max={max} />
        <div className="input-error">{iserror ? error : ''}</div>
      </div>
    </Fragment>
  );
};

export default InputField;
