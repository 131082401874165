import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import WebinarInfo from 'components/widgets/EventInfo/WebinarInfo';
import { finishViewWebinar } from 'ducks/webinar';
import './ViewWebinar.scss';

class ViewWebinar extends Component {
  render() {
    const { webinar, finishViewWebinar } = this.props;

    return (
      <div className="view-webinar-step">
        <WebinarInfo webinar={webinar} finishViewWebinar={finishViewWebinar} />
      </div>
    );
  }
}

ViewWebinar.propTypes = {
  webinar: PropTypes.object,
  finishViewWebinar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ webinar }) => ({
  webinar: webinar.data,
});

const mapDispatchToProps = dispatch => ({
  finishViewWebinar: () => dispatch(finishViewWebinar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewWebinar);
