import api from './api';

export default class InsuranceApi {
  getInsuranceCompanies() {
    return api.get(`/insurance/companies?order_by=name&order_type=asc`);
  }

  getInsurance() {
    return api.get('me/insurance');
  }

  setInsurance(params) {
    return api.patch('me/insurance', params);
  }

  getInsurancePlanTypes() {
    return api.get('/enums/insurance/plan_types');
  }
}
