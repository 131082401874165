import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import Button from 'components/forms/controls/Button';
import CheckboxGroup from 'components/forms/controls/CheckboxGroup';
import Textarea from 'components/forms/controls/Textarea';
import { notEmptyObject, assistantText } from 'services/validate';

import {
  POST_WEBINAR_ADDITIONAL_QUESTIONS,
  POST_WEBINAR_SCHEDULE_APPOINTMENT,
} from 'services/api/webinarService';

class WebinarContactForm extends Component {
  static caption = 'Please select one';
  static items = [
    {
      name: POST_WEBINAR_ADDITIONAL_QUESTIONS,
      label: 'I have additional questions',
    },
    {
      name: POST_WEBINAR_SCHEDULE_APPOINTMENT,
      label: 'I want to schedule a genetic counseling appointment',
    },
  ];
  render() {
    const { handleSubmit, submitting, onCancel } = this.props;

    return (
      <form className="option-form contact" onSubmit={handleSubmit}>
        <div className="fields">
          <Field
            name="action"
            type="text"
            component={CheckboxGroup}
            singleItem={true}
            label={WebinarContactForm.caption}
            items={WebinarContactForm.items}
            validate={[notEmptyObject(WebinarContactForm.caption, true), assistantText]}
          />
          <Field
            name="extra"
            component={Textarea}
            rows={6}
            label="Please provide more information"
          />
        </div>
        <div className="buttons-container">
          <span className="cancel-btn" onClick={onCancel}>
            Cancel
          </span>
          <Button type="submit" className="submit-btn" disabled={submitting}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

WebinarContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default reduxForm({
  form: 'webinar-contact-form',
})(WebinarContactForm);
