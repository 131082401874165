import api from './api';
import type {
  EligibilityConfig,
  EligibilityCheckResults,
  PartnerEligibilityInfo,
} from './eligibilityApi';
import { SessionStore } from 'services/storage';
import { processEligibilityError } from 'services/api/transformers/errorTransformers';

const GM_ELIGIBILITY_RESULTS = 'GM_ELIGIBILITY_RESULTS';
const GM_ELIGIBILITY_REFERRAL = 'GM_ELIGIBILITY_REFERRAL';

export const IlluminaReferral = 'Illumina';

class EligibilityService {
  getEligibilityConfig(referral: string): Promise<Array<EligibilityConfig>> {
    return api.eligibility.getEligibilityConfig(referral);
  }

  checkEligibility(referral: string, params: Object): Promise<EligibilityCheckResults> {
    return api.eligibility.checkEligibility(referral, params).catch(processEligibilityError);
  }

  saveReferral(referral: string) {
    SessionStore.set(GM_ELIGIBILITY_REFERRAL, referral);
  }

  getReferral() {
    return SessionStore.get(GM_ELIGIBILITY_REFERRAL);
  }

  clearReferral() {
    SessionStore.remove(GM_ELIGIBILITY_REFERRAL);
  }

  saveVerificationData(eligibility_token: string, additionalData: Array) {
    SessionStore.set(GM_ELIGIBILITY_RESULTS, { eligibility_token, additionalData });
  }

  clearVerificationData() {
    SessionStore.remove(GM_ELIGIBILITY_RESULTS);
  }

  getVerificationData() {
    return SessionStore.get(GM_ELIGIBILITY_RESULTS);
  }

  getPartnerInfo(partnerName: string): Promise<PartnerEligibilityInfo> {
    return api.eligibility.getPartnerInfo(partnerName);
  }
}

export default new EligibilityService();
