import api from './api';

class SurveyService {
  async getSurveys() {
    return api.survey.getSurveys().then(
      surveys =>
        surveys
          ? surveys.map(o => ({
              surveyAlias: o.alias,
              token: o.token,
              surveyType: o.survey_type,
              isSurveyJSSurvey: o.survey_type == 'survey_js',
              isZingtreeSurvey: o.survey_type == 'zingtree',
              zingtreeSessionId: o.zingtree_session_id,
              zingtreeTreeId: o.zingtree_tree_id,
              isZingtreeCompleted: o.is_completed,
            }))
          : []
    );
  }
  async getSurveyResults(alias, token) {
    const response = await api.survey.getSurveyResults(alias, token);
    return response;
  }
}

export default new SurveyService();
