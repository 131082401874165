import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { required, email, matchPassword, validZipCode, dateOfBirth, phoneOrEmpty } from 'services/validate';
import webinarService from 'services/api/webinarService';

import warn from 'components/forms/PasswordStrength/warn';
import Alert from 'components/widgets/Alert/Alert';

import './SimpleWebinarForm.scss';

import RadioButtonGroup from 'components/forms/controls/RadioButtonGroup';
import {
  cvcController,
  zipController,
  creditCardController,
  phoneController,
} from 'services/utils';
import InputField from '../../../../../components/forms/controls/V2/InputField';
import InputMaskField from '../../../../../components/forms/controls/V2/InputMaskField';
import ButtonV2 from '../../../../../components/forms/controls/ButtonV2';
import ExpirationMonth from '../../../../../components/forms/controls/ExpirationMonth';
import StatePicker from '../../../../../components/forms/controls/StatePicker';

const TextFieldStyle = {
  style: { fontSize: '14px', lineHeight: '20px', fontFamily: 'Open Sans', color: 'red' },
};

const genders = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];

class SimpleWebinarForm extends Component {
  state = { isRegistered: false, formError: null };

  onSubmit = values => {
    this.setState({ formError: null });
    const fixedValues = {
      ...values,
      dob: moment(values.dob).format('YYYY-MM-DD'),
      number: values.number.replace(/ /g, ''),
    };
    return webinarService
      .registerWithoutAccount(this.props.webinarId, fixedValues)
      .then(() => {
        if (this.props.onRegister) this.props.onRegister();
      })
      .catch(e => {
        if (e.submissionError) throw e.submissionError;
        if (e.code === 'already_registered') {
          this.setState({ formError: 'You are already registered for this webinar.' });
        } else {
          this.setState({ formError: 'Error: ' + ((e && e.message) || ' Something went wrong.') });
        }
        throw e;
      });
  };

  render() {
    const { handleSubmit, submitting, valid } = this.props;
    return (
      <section className="simple-webinar-form">
        <form
          className="gm-create-account-form"
          autoComplete="off"
          onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-wrapper">
            <div className="title-block">
              <div className="sub-title">
                Let's start by having you provide some basic information,<br />
                so you can access your available services
              </div>
            </div>

            <div className="gm-create-account-fields">
              <div className="row-section">
                <div className="col-section">
                  <div className="label-section required">Email Address</div>
                  <Field
                    name="email"
                    type="email"
                    label="Email"
                    component={InputField}
                    fullWidth={true}
                    validate={[required('Email'), email]}
                    style={TextFieldStyle}
                  />
                </div>
              </div>
              <div className="row-section">
                <div className="col-section">
                  <div className="label-section required">first Name</div>
                  <Field
                    name="first_name"
                    type="text"
                    label="First Name"
                    component={InputField}
                    validate={[required('First Name')]}
                    style={TextFieldStyle}
                  />
                </div>
                <div className="col-section">
                  <div className="label-section required">Last Name</div>
                  <Field
                    name="last_name"
                    type="text"
                    label="Last Name"
                    component={InputField}
                    validate={[required('Last Name')]}
                    style={TextFieldStyle}
                  />
                </div>
              </div>
              <div className="row-section">
                <div className="col-section">
                  <div className="label-section required">Date of birth</div>
                  <Field
                    name="dob"
                    placeholder="MM/DD/YYYY"
                    component={InputMaskField}
                    mask="99/99/9999"
                    maskChar={null}
                    validate={[required('Date of Birth'), dateOfBirth()]}
                  />
                </div>
                <div className="col-section">
                  <div className="label-section required">Phone number</div>
                  <Field
                    name="phone"
                    className="signup-field phone-field"
                    type="text"
                    label="Phone"
                    component={InputMaskField}
                    validate={[phoneOrEmpty,required('Phone')]}
                    mask="+1 999 999-9999"
                    maskChar={null}
                  />
                </div>
              </div>
              <div className="row-section">
                <div className="col-section">
                  <div className="label-section required">Date of birth</div>
                  <Field
                    name="gender"
                    component={RadioButtonGroup}
                    children={genders}
                    validate={[required('Biological Sex')]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-wrapper">
            <div className="title-block">
              <div className="title">Payment information</div>
              <div className="sub-title">
                We will use this credit card to charge you for employee program services
              </div>
            </div>

            <div className="gm-create-account-fields">
              <div className="row-section">
                <div className="col-section">
                  <div className="label-section required">Name on Card</div>
                  <Field
                    name="name"
                    type="text"
                    label="Name on card"
                    component={InputField}
                    fullWidth={true}
                    validate={[required('Name on card')]}
                    style={TextFieldStyle}
                  />
                </div>
              </div>
              <div className="row-section">
                <div className="col-section">
                  <div className="label-section required">Credit card</div>

                  <Field
                    name="number"
                    type="text"
                    label="Credit card number"
                    component={InputMaskField}
                    style={TextFieldStyle}
                    fullWidth={true}
                    validate={[required('Card number')]}
                    fieldController={creditCardController}
                  />
                </div>
                <div className="col-section">
                  <Field
                    name="exp_month"
                    type="text"
                    className="exp-month"
                    component={ExpirationMonth}
                    label="Expiration date"
                    validate={[required('Month', true)]}
                  />
                </div>
              </div>
              <div className="row-section">
                <div className="col-section">
                <div className="label-section required">CVC</div>
                  <Field
                    name="cvc"
                    type="text"
                    className="cvc"
                    component={InputMaskField}
                    style={TextFieldStyle}
                    label="CVC"
                    validate={[required('CVC')]}
                    fieldController={cvcController}
                  />
                </div>
              </div>
            </div>

            <div className="gm-create-account-label">Billing Address</div>
            <div className="row-section">
              <div className="col-section">
              <div className="la  bel-section required">Address line</div>

                <Field
                  name="address_line1"
                  type="text"
                  component={InputField}
                  style={TextFieldStyle}
                  label="Street Address Line 1"
                  fullWidth={true}
                  validate={[required('Street Address Line 1')]}
                />
              </div>
              <div className="col-section">
              <div className="label-section required">Address line 2</div>

                <Field
                  name="address_line2"
                  type="text"
                  component={InputField}
                  style={TextFieldStyle}
                  label="Street Address Line 2"
                  fullWidth={true}
                />
              </div>
            </div>

            <div className="row-section">
              <div className="col-section">
              <div className="label-section required">City</div>

                <Field
                  name="city"
                  type="text"
                  component={InputField}
                  label="City"
                  validate={[required('City')]}
                />
              </div>
              <div className="col-section">

                <Field
                  name="state"
                  type="text"
                  component={StatePicker}
                  label="State"
                  validate={[required('State')]}
                  disabled={false}
                />
              </div>
            </div>
            <div className="row-section">
              <div className="col-section">
                  <div className="label-section required">Zip Code</div>

                <Field
                  name="zip"
                  type="text"
                  className="credit-card-billing-address-zip-code"
                  component={InputMaskField}
                  style={TextFieldStyle}
                  label="Zip code"
                  validate={[required('Zip code'), validZipCode('Zip code')]}
                  fieldController={zipController}
                />
              </div>
            </div>
          </div>

          <Alert message={this.state.formError} />
          <div
            className={classnames('gm-create-account-field submit-button', {
              disabled: submitting,
            })}>
            <div className="gm-create-account-control">
              <ButtonV2 className="forgot-button" type="submit" disabled={submitting || !valid}>
                <span>REGISTER</span>
              </ButtonV2>
            </div>
          </div>
        </form>
      </section >
    );
  }
}

SimpleWebinarForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  webinarId: PropTypes.number.isRequired,
  onRegister: PropTypes.func,
};

export default reduxForm({
  form: 'simple-webinar-form',
  validate: matchPassword,
  warn,
})(SimpleWebinarForm);
