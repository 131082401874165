import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import Modal from 'components/modals/ModalDialog/ModalDialog';
import WebinarNotAvailableForm from './WebinarNotAvailableForm';
import './WebinarNotAvailable.scss';
import { sendIndicatedInterest } from 'ducks/webinar';

export class WebinarNotAvailable extends Component {
  sendEmail(data) {
    this.props.submitInterestMail(this.props.webinarId, data).then(() => {
      this.props.onHide();
      browserHistory.push('/invitae');
    });
  }

  render() {
    const { show, onHide } = this.props;
    return (
      <Modal className="md850 webinar-na" show={show} onHide={onHide}>
        <div className="na-body">
          <h3 className="na-title">Unfortunately, registration for this program has closed</h3>
          <h4 className="na-desc">
            To be added to the waiting list, or to be notified if we are enrolling more
            participants, please share your email address below
          </h4>
          <WebinarNotAvailableForm onSubmit={this.sendEmail.bind(this)} onCancel={onHide} />
        </div>
      </Modal>
    );
  }
}

WebinarNotAvailable.propTypes = {
  webinarId: PropTypes.number,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  submitInterestMail: PropTypes.func.isRequired,
  submittingMail: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  submitInterestMail: (id, data) => dispatch(sendIndicatedInterest(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebinarNotAvailable);
