import React, { Component } from 'react';
import ZingTreeShell from 'components/scp/zingtreeshell/ZingTreeShell';
import { browserHistory } from 'react-router';
import './EducationModulePage.scss';
import api from 'services/api/api';
import { connect } from 'react-redux';
import { meLoadProfile } from 'ducks/user';
import _ from 'lodash';
import { showLoader, closeLoader } from 'ducks/ui';
import usersService from 'services/api/usersService';
import authService from 'services/api/authService';
import { createSCPEncounter, loadCurrentSCPEncounter } from 'ducks/scpencounters';
import { isPartnerSoftRegisterOptionalFlow } from 'services/misc/ReferralSource';
import gaTrack, { GA_TR_EDUCATION_PAGE } from 'services/gaTrack';

class EducationModulePage extends Component {
  constructor(props) {
    super(props);
    this.onResultNodeReach = this.onResultNodeReach.bind(this);
    this.onFailureNodeReach = this.onFailureNodeReach.bind(this);
    this.state = {
      surveySessionId: null,
      surveyTreeId: null,
    };
  }

  componentDidMount() {
    gaTrack(GA_TR_EDUCATION_PAGE);
    if (api.hasToken()) {
      if (api.isNonSCPUser()) {
        browserHistory.push('/patient/home');
      } else if (api.isSCPUser()) {
        this.loadEncounterIfRequired();
      }
    } else {
      if (api.hasEducationSurvey()) {
        const { surveySessionId, surveyTreeId } = api.getEducationSurvey();
        this.setState({ surveySessionId, surveyTreeId });
      }
      if (isPartnerSoftRegisterOptionalFlow()) {
        window.addEventListener('beforeunload', this.preventNavigation);
      }
    }
  }
  loadEncounterIfRequired() {
    const { dispatch, scpEncounters, _currentUser } = this.props;
    const { currentScpEncounterLoaded, currentScpEncounter } = scpEncounters;
    if (currentScpEncounterLoaded == true) {
      if (!_.isNil(currentScpEncounter)) {
        browserHistory.push('/patient/home');
      } else {
        // EHR-7268: manage optional soft registration flow
        // Flow : patient created from GMI.
        dispatch(showLoader());
        if (!_.isNil(_currentUser) && _currentUser.partnerSoftRegistrationRequired) {
          usersService.getSCPEducationTree().then(data => {
            const { surveySessionId, surveyTreeId } = data;
            this.setState({ surveySessionId, surveyTreeId });
            dispatch(closeLoader());
          });
        } else {
          dispatch(createSCPEncounter())
            .then(() => {
              dispatch(meLoadProfile());
              dispatch(closeLoader());
            })
            .catch(() => {
              usersService.getSCPEducationTree().then(data => {
                const { surveySessionId, surveyTreeId } = data;
                this.setState({ surveySessionId, surveyTreeId });
                dispatch(closeLoader());
              });
            });
        }
      }
    } else {
      dispatch(showLoader());
      dispatch(loadCurrentSCPEncounter()).then(data => {
        if (!_.isNil(data)) {
          dispatch(closeLoader());
          browserHistory.push('/patient/home');
        } else {
          // EHR-7268: manage optional soft registration flow
          // Flow : patient created from GMI.
          dispatch(showLoader());
          if (!_.isNil(_currentUser) && _currentUser.partnerSoftRegistrationRequired) {
            usersService.getSCPEducationTree().then(data => {
              const { surveySessionId, surveyTreeId } = data;
              this.setState({ surveySessionId, surveyTreeId });
              dispatch(closeLoader());
            });
          } else {
            dispatch(createSCPEncounter())
              .then(() => {
                dispatch(meLoadProfile());
                dispatch(closeLoader());
              })
              .catch(() => {
                usersService.getSCPEducationTree().then(data => {
                  const { surveySessionId, surveyTreeId } = data;
                  this.setState({ surveySessionId, surveyTreeId });
                  dispatch(closeLoader());
                });
              });
          }
        }
      });
    }
  }
  componentDidUpdate(preProps) {
    if (
      preProps.scpEncounters.currentScpEncounterLoaded !==
        this.props.scpEncounters.currentScpEncounterLoaded &&
      this.props.scpEncounters.currentScpEncounterLoaded == true
    ) {
      const { currentScpEncounter } = this.props.scpEncounters;
      if (!_.isNil(currentScpEncounter) && currentScpEncounter.needConsent) {
        browserHistory.push('/patient/testrequest/address');
      } else if (!_.isNil(currentScpEncounter) && currentScpEncounter.isAppointmentSubType) {
        browserHistory.push('/scheduling/select-service');
      } else if (!_.isNil(currentScpEncounter)) {
        browserHistory.push('/patient/home');
      }
    }
  }

  preventNavigation(ev) {
    ev.preventDefault();
    setTimeout(function() {
      setTimeout(function() {
        browserHistory.push('/scp/softregistration');
      }, 100);
    }, 1);
    return (ev.returnValue = 'Are you sure you want to close?');
  }

  componentWillUnmount() {
    if (!api.hasToken() && isPartnerSoftRegisterOptionalFlow()) {
      window.removeEventListener('beforeunload', this.preventNavigation);
    }
  }

  onResultNodeReach = () => {
    const { dispatch } = this.props;
    dispatch(showLoader());
    if (api.hasToken()) {
      setTimeout(() => {
        dispatch(createSCPEncounter()).then(() => {
          dispatch(meLoadProfile()); //we need to call me again as with scpencounter user speciality will be updated.
          dispatch(closeLoader());
        });
      }, 5000);
    } else if (isPartnerSoftRegisterOptionalFlow()) {
      setTimeout(() => {
        dispatch(closeLoader());
        browserHistory.push('/scp/softregistration');
      }, 5000);
    } else {
      setTimeout(() => {
        dispatch(closeLoader());
        browserHistory.push('/scp/fullregistration');
      }, 5000);
    }
  };

  onFailureNodeReach = data => {
    console.log('do nothing with data', data);
  };

  render() {
    const { surveySessionId, surveyTreeId } = this.state;
    return (
      <div className="scp-education-page">
        {!_.isEmpty(surveySessionId) &&
          !_.isEmpty(surveyTreeId) && (
            <ZingTreeShell
              tree_id={surveyTreeId}
              session_id={surveySessionId}
              onResultNodeReach={this.onResultNodeReach}
              onFailureNodeReach={this.onFailureNodeReach}
              className={'min-height'}
            />
          )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { scpEncounters } = state;
  const _currentUser = authService.getCurrentUser(state);
  return {
    scpEncounters,
    _currentUser,
  };
};

export default connect(mapStateToProps)(EducationModulePage);
