import React from 'react';
const ArrowRight = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Atom_Icons_ChevronLarge"
    width={props.width}
    height={props.height}
    data-name="Atom/Icons/ChevronLarge"
    viewBox="0 0 28 28">
    <path
      id="prefix__Chevron-large"
      d="M10.293-19.293a1 1 0 0 1 0-1.414 1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414L15.586-14z"
      transform="translate(0 28)"
      className={props.className}
    />
    <g id="prefix__Atom_Colors_Grey-5" data-name="Atom/Colors/Grey-5">
      <path id="prefix__Rectangle" d="M0 0H28V28H0z" style={{ fill: 'none' }} />
    </g>
  </svg>
);

export default ArrowRight;
