import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import classnames from 'classnames';
import Button from 'components/forms/controls/ButtonV2';

import { findCouponItem, clearSelectedService, selectCountryByUser } from 'ducks/scheduling';
import HardcodedDescriptors, {
  TestConsultation,
  ReviewConsultation,
  FollowUpConsultation,
  CurbsideConsultation,
  ExpeditedResultsReviewConsultation,
  AllHardcodedConsultations,
  getHardcodedDescriptorByType,
} from 'constants/ServiceTypes';
import { GetStateTimezone } from 'services/geo';
import schedulingService from 'services/api/schedulingService';
import { formatPrice } from 'services/utils';
import SelectServicePanel from './SelectServicePanelV2';
import './SelectServicePage.scss';
import authService from 'services/api/authService';
import gaTrack, { GA_TR_SCHEDULING_SELECT_SERVICE_PAGE } from '../../../../../services/gaTrack';
import {
  setDefaultStateAndTimezone,
  setDefaultVseeSpecialty,
  selectProviderByUser,
  selectModalityByUser,
} from 'ducks/scheduling';

class SelectServicePage extends Component {
  static selectHint = 'Choose this option if you are considering genetic testing';

  static getPath(name) {
    const param = (HardcodedDescriptors[name] && HardcodedDescriptors[name].urlParameter) || name;
    return `/scheduling/${param}/calendar`;
  }

  state = {
    selected: null,
    preSelected: null,
    isSpecialtySelected: false,
  };

  componentDidMount() {
    gaTrack(GA_TR_SCHEDULING_SELECT_SERVICE_PAGE);
    window.scrollTo(0, 0);
    const {
      consultationTypes,
      scheduling,
      dispatch,
      gotoCalendar,
      user,
      shippingAddress,
      homeAddress,
    } = this.props;
    const { calendarCountry } = scheduling;

    const { OutreachAppointment, isOutreachAppointmentFlow } =
      schedulingService.getOutreachAppointmentData();

    const allowedCountries = _.get(user, ['allowedCountries'], ['US']);
    if (!calendarCountry && allowedCountries && allowedCountries.length > 0) {
      dispatch(selectCountryByUser(allowedCountries[0]));
    }

    if (consultationTypes.length > 1) {
      this.props.dispatch(clearSelectedService());
    }

    if (consultationTypes.length === 1) {
      const type = _.get(consultationTypes[0], 'type', '');
      this.setState({ selected: type, preSelected: type });
    }

    dispatch(selectModalityByUser(false));

    const isFollowUpConsultation = _.find(consultationTypes, (obj) => {
      return obj.type === FollowUpConsultation;
    });
    if (isOutreachAppointmentFlow && isFollowUpConsultation) {
      const countryIsNonUS = _.get(user, ['countryIsNonUS'], false);
      const address = countryIsNonUS ? homeAddress : shippingAddress;
      if (address && !_.isEmpty(address)) {
        const timezone = GetStateTimezone(address.addressState);
        dispatch(
          setDefaultStateAndTimezone(address.addressCountry, address.addressState, timezone)
        );
      }
      if (!_.isNil(OutreachAppointment)) {
        dispatch(setDefaultVseeSpecialty(OutreachAppointment.vsee_specialty));
        dispatch(selectProviderByUser(OutreachAppointment.provider.id));
      } else {
        dispatch(setDefaultVseeSpecialty(user.vsee_specialty));
      }
      const type = getHardcodedDescriptorByType(FollowUpConsultation);
      const urlParameter = type && type.urlParameter;
      gotoCalendar(urlParameter);
    }

    const preSelected = authService.getPreselectedService();
    if (preSelected) {
      const testType =
        (HardcodedDescriptors[preSelected] && HardcodedDescriptors[preSelected].type) ||
        preSelected;
      this.setState({ selected: testType, preSelected: testType });
    }
    const partnerSpecialties = user?.partnerSpecialties || [];
    if (consultationTypes.length == 1 && partnerSpecialties.length == 1) {
      const specialityName = _.get(partnerSpecialties, ['0', 'name'], '');
      dispatch(setDefaultVseeSpecialty(specialityName));
      const consultationType = _.get(consultationTypes, ['0', 'type'], '');
      const type = getHardcodedDescriptorByType(consultationType);
      const urlParameter = type && type.urlParameter;
      gotoCalendar(urlParameter);
    }
  }

  componentWillUnmount() {
    const { webinar, appliedCoupon, setCouponCode, clearCoupon } = this.props;
    if (this.purchaseClicked || schedulingService.resultsReviewCouponIsApplied(webinar)) {
      if (appliedCoupon && appliedCoupon.code) setCouponCode(appliedCoupon.code, true);
    } else clearCoupon(true);
  }

  onPurchaseClick = (path) => {
    this.purchaseClicked = true;
    browserHistory.push(path);
  };

  onFormSubmit = () => {
    const { consultationTypes } = this.props;
    const selectedConsultation = consultationTypes.find((a) => a.type == this.state.selected);
    if (selectedConsultation) {
      const type = getHardcodedDescriptorByType(selectedConsultation.type);
      this.onPurchaseClick(SelectServicePage.getPath(type?.urlParameter));
    }
  };

  setSelected = (selected) => {
    this.setState({ selected: selected });
  };

  getPrice(name) {
    const { consultationTypes, appliedCoupon, hiddenCouponApplied, purchasedServicePackages } =
      this.props;
    const type = HardcodedDescriptors[name].type || name;
    const getProductPrice = (product) => {
      const regularPrice = formatPrice(product.price);
      const couponItem = findCouponItem({
        appliedCoupon,
        selectedServiceDescriptor: { isPackage: false },
        selectedProduct: product,
        purchasedServicePackages: purchasedServicePackages,
      });

      if (!couponItem) {
        const prepaid = false;
        return {
          price: prepaid ? formatPrice(prepaid.payment.totalCharge) : regularPrice,
        };
      }

      const couponPrice = formatPrice(couponItem.price);
      return hiddenCouponApplied && !isNaN(couponItem.price)
        ? { price: couponPrice }
        : { price: couponPrice, oldPrice: regularPrice };
    };

    const consultation = consultationTypes.find((t) => t.type === type);
    return consultation ? getProductPrice(consultation) : { price: null, oldPrice: null };
  }

  getHardcodedConsultation(name) {
    const { consultationTypes, isMobile } = this.props;
    const type = HardcodedDescriptors[name].type;
    if (isMobile && this.state.selected && this.state.selected != type) {
      return null;
    }
    return consultationTypes.find((t) => t.type === type);
  }

  getExtraConsulations() {
    const { consultationTypes, isMobile } = this.props;
    const { selected } = this.state;
    const ignoredTypes = AllHardcodedConsultations.map((name) => HardcodedDescriptors[name].type);
    if (isMobile && selected) {
      return consultationTypes.filter((t) => !ignoredTypes.includes(t.type) && t.type == selected);
    }
    return consultationTypes.filter((t) => !ignoredTypes.includes(t.type));
  }
  setIsSpecialitySelected = (value) => {
    this.setState({
      isSpecialtySelected: value,
    });
  };

  renderPageContent = () => {
    const { scheduling } = this.props;
    const { calendarState, calendarTimezone, calendarCountry } = scheduling;
    const disabled = !calendarState || !calendarTimezone || !calendarCountry;

    const prices = {
      curbside_chat15: this.getPrice(CurbsideConsultation),
      test: this.getPrice(TestConsultation),
      review: this.getPrice(ReviewConsultation),
      follow_up: this.getPrice(FollowUpConsultation),
      expedited_results_review: this.getPrice(ExpeditedResultsReviewConsultation),
    };
    const { webinar, showControls, insuranceEnabled, user, toPreviousStep } = this.props;
    const proceedWithFollowUp = schedulingService.resultsReviewCouponIsApplied(webinar);

    const curbsideConsultation = this.getHardcodedConsultation(CurbsideConsultation);
    const testConsultation = this.getHardcodedConsultation(TestConsultation);
    const reviewConsultation = this.getHardcodedConsultation(ReviewConsultation);
    const followUpConsultation = this.getHardcodedConsultation(FollowUpConsultation);
    const expeditedResultsReviewConsultation = this.getHardcodedConsultation(
      ExpeditedResultsReviewConsultation
    );
    const extraConsultations = this.getExtraConsulations();
    const isMobile = _.get(this, ['props', 'isMobile'], false);
    const isSpecialtySelected =
      this.state.isSpecialtySelected && this.state.isSpecialtySelected == this.state.selected;
    // TODO: Encapsulate service properties accessing into SelectServicePanel
    return (
      <div>
        <div className={classnames('selector-panels-container v2', { 'is-mobile': isMobile })}>
        <div>
          {!_.isEmpty(extraConsultations) && (
            <div className="extra-consultations-container">
              <h2 className="panels-group-title mgt2per">Select Visit Details</h2>
              <div className="panels-group-description display-flex">
                <div className="cdt-icon-contain" />
                <div className="panels-group-description-header">
                  <h4>Select, discuss, and order your genetic health screen</h4>
                </div>
                <div className="panels-group-description-content">
                  Schedule a consultation to review and assess your personal and family medical
                  history, answer questions regarding your selected genetic health screen, and
                  authorize your genetic test. Each selection includes cost of: consultation, test
                  authorization, and genetic test.
                </div>
              </div>
              {extraConsultations.map((extraConsultation) => (
                <SelectServicePanel
                  key={extraConsultation.name}
                  header={extraConsultation.name}
                  type={extraConsultation.type}
                  iconClassName=""
                  description={extraConsultation.description}
                  price={extraConsultation.price}
                  path={SelectServicePage.getPath(extraConsultation.type)}
                  disabled={proceedWithFollowUp}
                  onPurchaseClick={this.onPurchaseClick}
                  showControls={showControls}
                  insuranceEnabled={insuranceEnabled}
                  setSelected={this.setSelected}
                  selected={this.state.selected}
                  preSelected={this.state.preSelected}
                  zeroPriceText={null}
                  isPredefined={extraConsultation.isPredefined}
                  isMobile={isMobile}
                  setIsSpecialitySelected={this.setIsSpecialitySelected}
                  isSpecialtySelected={this.state.isSpecialtySelected}
                />
              ))}
            </div>
          )}
          {(testConsultation ||
            reviewConsultation ||
            curbsideConsultation ||
            expeditedResultsReviewConsultation) && (
            <div>
              <h2 className="panels-group-title">Select Visit Details</h2>
              {curbsideConsultation && (
                <SelectServicePanel
                  header={curbsideConsultation.description}
                  type={curbsideConsultation.type}
                  iconClassName="icon-testing-guidance"
                  description={curbsideConsultation.detailedDescription}
                  selectHint={SelectServicePage.selectHint}
                  price={prices[CurbsideConsultation].price}
                  oldPrice={prices[CurbsideConsultation].oldPrice}
                  path={SelectServicePage.getPath(CurbsideConsultation)}
                  disabled={proceedWithFollowUp}
                  onPurchaseClick={this.onPurchaseClick}
                  showControls={showControls}
                  insuranceEnabled={insuranceEnabled}
                  setSelected={this.setSelected}
                  selected={this.state.selected}
                  preSelected={this.state.preSelected}
                  zeroPriceText={null}
                  isPredefined={curbsideConsultation.isPredefined}
                  isMobile={isMobile}
                  setIsSpecialitySelected={this.setIsSpecialitySelected}
                  isSpecialtySelected={this.state.isSpecialtySelected}
                />
              )}
              {testConsultation && (
                <SelectServicePanel
                  header={testConsultation.description}
                  type={testConsultation.type}
                  iconClassName="icon-testing-guidance"
                  description={testConsultation.detailedDescription}
                  selectHint={SelectServicePage.selectHint}
                  price={prices[TestConsultation].price}
                  oldPrice={prices[TestConsultation].oldPrice}
                  path={SelectServicePage.getPath(TestConsultation)}
                  disabled={proceedWithFollowUp}
                  onPurchaseClick={this.onPurchaseClick}
                  showControls={showControls}
                  insuranceEnabled={insuranceEnabled}
                  setSelected={this.setSelected}
                  selected={this.state.selected}
                  preSelected={this.state.preSelected}
                  zeroPriceText={null}
                  isPredefined={testConsultation.isPredefined}
                  isMobile={isMobile}
                  setIsSpecialitySelected={this.setIsSpecialitySelected}
                  isSpecialtySelected={this.state.isSpecialtySelected}
                />
              )}
              {reviewConsultation && (
                <SelectServicePanel
                  header={reviewConsultation.description}
                  type={reviewConsultation.type}
                  iconClassName="icon-results-review"
                  description={reviewConsultation.detailedDescription}
                  selectHint={SelectServicePage.selectHint}
                  price={prices[ReviewConsultation].price}
                  oldPrice={prices[ReviewConsultation].oldPrice}
                  path={SelectServicePage.getPath(ReviewConsultation)}
                  onPurchaseClick={this.onPurchaseClick}
                  showControls={showControls}
                  insuranceEnabled={insuranceEnabled}
                  setSelected={this.setSelected}
                  selected={this.state.selected}
                  preSelected={this.state.preSelected}
                  zeroPriceText={null}
                  isPredefined={reviewConsultation.isPredefined}
                  isMobile={isMobile}
                  setIsSpecialitySelected={this.setIsSpecialitySelected}
                  isSpecialtySelected={this.state.isSpecialtySelected}
                />
              )}
              {expeditedResultsReviewConsultation && (
                <SelectServicePanel
                  header={expeditedResultsReviewConsultation.description}
                  type={expeditedResultsReviewConsultation.type}
                  iconClassName="icon-results-review"
                  description={expeditedResultsReviewConsultation.detailedDescription}
                  selectHint={SelectServicePage.selectHint}
                  price={prices[ExpeditedResultsReviewConsultation].price}
                  oldPrice={prices[ExpeditedResultsReviewConsultation].oldPrice}
                  path={SelectServicePage.getPath(ExpeditedResultsReviewConsultation)}
                  onPurchaseClick={this.onPurchaseClick}
                  showControls={showControls}
                  insuranceEnabled={insuranceEnabled}
                  setSelected={this.setSelected}
                  selected={this.state.selected}
                  preSelected={this.state.preSelected}
                  zeroPriceText={null}
                  isPredefined={expeditedResultsReviewConsultation.isPredefined}
                  isMobile={isMobile}
                  setIsSpecialitySelected={this.setIsSpecialitySelected}
                  isSpecialtySelected={this.state.isSpecialtySelected}
                />
              )}
            </div>
          )}
          {followUpConsultation && (
            <div>
              <h2 className="panels-group-title">Follow up</h2>
              <SelectServicePanel
                header={followUpConsultation.description}
                type={followUpConsultation.type}
                iconClassName="icon-follow-up"
                description={followUpConsultation.detailedDescription}
                price={prices[FollowUpConsultation].price}
                oldPrice={prices[FollowUpConsultation].oldPrice}
                path={SelectServicePage.getPath(FollowUpConsultation)}
                onPurchaseClick={this.onPurchaseClick}
                showControls={showControls}
                insuranceEnabled={insuranceEnabled}
                setSelected={this.setSelected}
                selected={this.state.selected}
                preSelected={this.state.preSelected}
                zeroPriceText={
                  _.get(user, 'affiliation') === 'Northshore'
                    ? '*Pricing varies based on insurance, but in most instances this would be a fully covered benefit.'
                    : null
                }
                isPredefined={followUpConsultation.isPredefined}
                isMobile={isMobile}
                setIsSpecialitySelected={this.setIsSpecialitySelected}
                isSpecialtySelected={this.state.isSpecialtySelected}
              />
            </div>
          )}
</div>
          {!isMobile ? (
            <div className="action-buttons">
              {this.props.toPreviousStep && (
                <button onClick={toPreviousStep} className="btn-back">
                  Back
                </button>
              )}
              <button
                onClick={this.onFormSubmit}
                disabled={disabled || !isSpecialtySelected}
                className="btn-next"
              >
                {'Next'}
              </button>
            </div>
          ) : toPreviousStep && !this.state.selected ? (
            <div className="buttons">
              <Button className="button-back-to-address v2" onClick={toPreviousStep}>
                Back
              </Button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="scp-schedule-wizard scp-select-service-page full-height">
        {this.props.scheduling.isSchedulingLoaded && this.renderPageContent()}
      </div>
    );
  }
}

SelectServicePage.propTypes = {
  user: PropTypes.object,
  webinar: PropTypes.object,
  consultationTypes: PropTypes.array.isRequired,
  setCouponCode: PropTypes.func.isRequired,
  clearCoupon: PropTypes.func.isRequired,
  showControls: PropTypes.bool.isRequired,
  scheduling: PropTypes.object.isRequired,
  appliedCoupon: PropTypes.object,
  couponError: PropTypes.object,
  isCheckingCouponCode: PropTypes.bool,
  hiddenCouponApplied: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  redirectAddress: PropTypes.string.isRequired,
  insuranceEnabled: PropTypes.bool,
  zeroPriceText: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user.me,
  webinar: state.webinar.data,
  scheduling: state.scheduling,
  purchasedServicePackages: _.get(state.scheduling, 'purchasedServicePackages', []),
  consultationTypes: state.scheduling.consultationTypes,
  showControls:
    !state.myGuide.guideProvider.loading &&
    (state.scheduling.couponError ||
      !state.scheduling.sessionCouponCode ||
      (!_.isEmpty(state.scheduling.appliedCoupon) &&
        state.scheduling.sessionCouponCode === state.scheduling.appliedCoupon.code)),
  redirectAddress:
    !_.isEmpty(state.webinar.data) && _.isEmpty(state.appointment.data) ? '/webinar' : '/patient',
});

export default connect(mapStateToProps)(SelectServicePage);
