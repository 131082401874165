import _ from 'lodash';
import { fetchTestingProgress } from 'ducks/guides/testingGuide';
import { fetchReviewProgress } from 'ducks/guides/reviewGuide';
import { fetchFollowUpProgress } from 'ducks/guides/followUpGuide';
import {
  CurbsideConsultation,
  TestConsultationType,
  ReviewConsultationType,
  FollowUpConsultationType,
} from 'constants/ServiceTypes';

export const SCHEDULE_YOUR_APPOINTMENT_STEP = 'gm/my-guide/SCHEDULE_YOUR_APPOINTMENT_STEP';
export const GET_READY_FOR_YOUR_APPOINTMENT_STEP =
  'gm/my-guide/GET_READY_FOR_YOUR_APPOINTMENT_STEP';
export const MEET_WITH_YOUR_GENETIC_COUNSELOR = 'gm/my-guide/MEET_WITH_YOUR_GENETIC_COUNSELOR';

// Action types
const SETUP_GUIDE = 'gm/my-guide/SETUP_GUIDE';

const testingGuide = { name: 'testingGuide', fetchProgress: fetchTestingProgress };

export const GUIDES = {
  [CurbsideConsultation]: testingGuide,
  [TestConsultationType]: testingGuide,
  [ReviewConsultationType]: { name: 'reviewGuide', fetchProgress: fetchReviewProgress },
  [FollowUpConsultationType]: { name: 'followUpGuide', fetchProgress: fetchFollowUpProgress },
};

export function getGuideByConsultationType(consultationType, guides, defaultGuide = null) {
  return guides[consultationType] || defaultGuide;
}

export default function reducer(state = { activeGuideName: '' }, action = {}) {
  const getGuide = consultation => getGuideByConsultationType(consultation, GUIDES, testingGuide);
  switch (action.type) {
    case SETUP_GUIDE:
      return {
        ...state,
        activeGuideName:
          action.payload &&
          action.payload.consultation &&
          getGuide(action.payload.consultation.type) &&
          getGuide(action.payload.consultation.type).name,
      };
    default:
      return state;
  }
}

export function finishTheStep(stepIdToFinish, state = {}) {
  const STEPS = [
    SCHEDULE_YOUR_APPOINTMENT_STEP,
    GET_READY_FOR_YOUR_APPOINTMENT_STEP,
    MEET_WITH_YOUR_GENETIC_COUNSELOR,
  ];
  const steps = _.isEmpty(state)
    ? STEPS.map(stepId => ({
        id: stepId,
        done: stepId === stepIdToFinish,
      }))
    : state.steps.map(s => ({
        id: s.id,
        done: s.done || s.id === stepIdToFinish,
      }));
  const activeStep = steps.find(step => !step.done);
  return {
    steps,
    activeStepId: activeStep ? activeStep.id : null,
  };
}

export function setupGuide(appointment) {
  return { type: SETUP_GUIDE, payload: appointment };
}

export function fetchMyGuideProgress(appointment, params) {
  if (appointment) {
    return dispatch => {
      const guide = getGuideByConsultationType(appointment.consultation.type, GUIDES, testingGuide);
      dispatch(guide.fetchProgress(appointment, params));
    };
  }
}
