import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import '../../public/ClinicalTrialContainer/ClinicalTrailContainer.scss';
import ClinicalConsentContainer from './ClinicalConsentContainer';
import { loginWithoutApi } from '../../../../ducks/auth/email';
import ThankYouPage from './ThankYouPage';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import api from '../../../../services/api/api';
import { setConsentSource } from 'services/misc/ReferralSource';

const CONSENT_PAGE = 'CONSENT_PAGE';
const THANK_YOU_PAGE = 'THANK_YOU_PAGE';
const ALREADY_CONSENETED = 'ALREADY_CONSENETED';

class ClinicalConsentContainerBeforeLogin extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeStage: CONSENT_PAGE,
  };
  componentDidMount() {
    const { dispatch, location } = this.props;
    const token = location.query && location.query.token;
    if (token || api.hasToken()) {

      const consentsource = location.query.consentsource;
      if (consentsource) {
        setConsentSource(consentsource);
      }

      dispatch(loginWithoutApi(token|| api.getToken()));
      browserHistory.push('/clinical-consent');
    }
    else{
      browserHistory.push('/login');
    }
  }

  updateThankYouStage = () =>{
    this.setState({
      activeStage:THANK_YOU_PAGE
    });
  }
  updateAlreadyConsentedStage = () =>{
    this.setState({
      activeStage:ALREADY_CONSENETED
    });
  }


  render() {
    const { me } = this.props;
    const { activeStage } = this.state;
    if (!me) {
      return null;
    }

    return (
      <div>
        {activeStage == CONSENT_PAGE && (
          <ClinicalConsentContainer
          updateThankYouStage={this.updateThankYouStage}
          updateAlreadyConsentedStage={this.updateAlreadyConsentedStage}
           />
        )}
        {activeStage == THANK_YOU_PAGE &&
        <ThankYouPage
        message="Your response has been recorded. Thank you for authorizing us to recontact you." />}

        {activeStage == ALREADY_CONSENETED &&
        <ThankYouPage
        message="You&apos;ve already provided authorization for us to recontact you."/>}
        <GlobalLoader />
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      me: state.user.me,
    };
  },
  (dispatch) => {
    return { dispatch };
  }
)(ClinicalConsentContainerBeforeLogin);
