import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppointmentInfoWithYellowHint from './AppointmentInfoWithYellowHint';
import Button from 'components/forms/controls/Button';
import './GetReadyForYourAppointment.scss';
import classnames from 'classnames';

class GetReadyForYourAppointment extends Component {
  render() {
    const {
      appointment,
      isMobile,
      gotoAppointmentInfoForMobile,
      gotoVideoInstructionForMobile,
      showAppointmentInfoForMobile,
      showVideoInstructionForMobile,
    } = this.props;
    const isDevelopment = process.env.GM_ENV === 'development';

    return (
      <div className={classnames('scp-my-guide-step2', 'ninetyDays')}>
        {isDevelopment &&
          appointment &&
          !appointment.byPhone && (
            <Button
              className="cta red-cta"
              href={`/appointments/${appointment.id}/waiting-room`}
              target="_blank">
              <b>
                <u>DEBUG:</u>
              </b>{' '}
              Start your appointment
            </Button>
          )}
        <AppointmentInfoWithYellowHint
          showTitle={false}
          isMobile={isMobile}
          gotoAppointmentInfoForMobile={gotoAppointmentInfoForMobile}
          gotoVideoInstructionForMobile={gotoVideoInstructionForMobile}
          showAppointmentInfoForMobile={showAppointmentInfoForMobile}
          showVideoInstructionForMobile={showVideoInstructionForMobile}
        />
      </div>
    );
  }
}

GetReadyForYourAppointment.propTypes = {
  appointment: PropTypes.object,
  isMobile: PropTypes.bool,
  gotoAppointmentInfoForMobile: PropTypes.func,
  gotoVideoInstructionForMobile: PropTypes.func,
  showAppointmentInfoForMobile: PropTypes.bool,
  showVideoInstructionForMobile: PropTypes.bool,
};

const mapStateToProps = state => ({
  appointment: state.appointment.data,
});

export default connect(mapStateToProps)(GetReadyForYourAppointment);
