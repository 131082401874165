import React from 'react';

import './Footer.scss';
import FacebookIcon from './Facebook';
import TwitterIcon from './Twitter';
import LinkedinIcon from './Linkedin';

class Footer extends React.Component {
  render() {
    return (
      <footer className="scp-footer-conteiner">
        <div className="social-media">
          <a href="https://www.facebook.com/genomemedical" target="_blank">
            <FacebookIcon />
          </a>
          <a href="https://twitter.com/genomemed" target="_blank">
            <TwitterIcon />
          </a>
          <a href="https://www.linkedin.com/company/genome-medical/" target="_blank">
            <LinkedinIcon />
          </a>
        </div>
        <p className="copyright">
          Copyright ©. Genome Medical, Inc. {new Date().getFullYear()}. All rights reserved.
        </p>
      </footer>
    );
  }
}

export default Footer;
