import React from 'react';
import './InsuranceDeductible.scss';
import uploadCardIcon from './upload-card-icon.png';
import { currentYear } from 'services/utils';

const year = currentYear();

export default function InsuranceDeducatible() {
  return (
    <div className="insurance-dedectible-card">
      <span className="badge-icon">!</span>
      <div className="container">
        <div className="left">
          <img className="visual-content" src={uploadCardIcon} alt="insurance" />
        </div>
        <div className="right">
          <div className="card-title">Insurance Deductibles Reset on January 1st, {year}. </div>
          <div className="verify-text">
            You may pay higher than average out-of-pocket costs for your regular healthcare expenses
            until you meet your deductible. For most people, this impacts the first few months of
            the year. For information on your specific benefits, please contact your insurance
            company.
          </div>
        </div>
      </div>
    </div>
  );
}
