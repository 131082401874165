// @flow
import api, { X_API_AUTHORIZATION_HEADER } from './api';

export default class AppointmentsAPI {
  getAppointmentsFiles(): Promise<Array<Object>> {
    return api.get('/appointments/files');
  }
  //
  // getAppointmentFiles(appointmentId: number): Promise<Array<Object>> {
  //   return api.get(`/appointments/${appointmentId}/files`);
  // }
  //
  // uploadAppointmentFiles(
  //   appointmentId: number,
  //   formData: FormData,
  //   onUploadProgress: Function
  // ): Promise<any> {
  //   return api.postMultipartFormData(
  //     `/appointments/${appointmentId}/files`,
  //     formData,
  //     onUploadProgress
  //   );
  // }
  //
  // deleteAppointmentFile(appointmentId: number, fileId: number): Promise<any> {
  //   return api.delete(`/appointments/${appointmentId}/files/${fileId}`);
  // }

  getAppointments(): Promise<Array<Object>> {
    return api.get('/appointments');
  }

  cancelAppointment(appointmentId: number): Promise<any> {
    return api.delete(`/appointments/${appointmentId}`);
  }

  getAppointment(appointmentId: number): Promise<Object> {
    return api.get(`/appointments/${appointmentId}`);
  }

  rescheduleAppointment(appointmentId: number, params: Object): Promise<any> {
    return api.patch(`/appointments/${appointmentId}`, params);
  }

  getCurrentAppointment(): Promise<Object> {
    return api.get('/appointments/current');
  }

  getCalendarUrl(appointmentId: number, calendar: string, accessToken: string): Promise<Object> {
    return api.get(`/appointments/${appointmentId}/${calendar}?_token=${accessToken}`);
  }

  getWaitingRoomUrl(appointmentId: number): Promise<Object> {
    return api.get(`/appointments/${appointmentId}/waiting-room`);
  }

  bookAppointment(params: Object, accessToken: ?string): Promise<any> {
    if (!accessToken) return api.post('/appointments/book', params);

    return api.post('/appointments/book', params, {
      headers: { [X_API_AUTHORIZATION_HEADER]: accessToken },
    });
  }

  switchToAppointmentByPhone(appointmentId: number): Promise<any> {
    return api.post(`/appointments/${appointmentId}/switch-to-phone`);
  }

  switchAppointmentModality(appointmentId: number, params: Object): Promise<any> {
    return api.patch(`/appointments/${appointmentId}/switch-modality`, params);
  }

  indicateInterestInFollowUp(appointmentId: number): Promise<any> {
    return api.post(`/appointments/${appointmentId}/indicate-interest-in-follow-up`);
  }

  cancelAppointmentTimeSlotReservation(params: Object): Promise<any> {
    return api.delete('/appointments/reservation', params);
  }

  reserveAppointmentTimeSlot(params: Object) {
    return api.post('/appointments/reservation', params);
  }

  getUserServicePackages() {
    return api.get('/service-packages');
  }

  requestAssistance(appointmentId: number, message: String) {
    return api.post(`/appointments/${appointmentId}/request-assistance`, { message });
  }

  completeGCNSurvey(appointmentId: number) {
    return api.patch(`/appointments/${appointmentId}/gcn-completed`);
  }
}
