import Context from '../Context';

/**
 * @class ActionBase
 * @classdesc Abstract action class
 */
export default class ActionBase {
  // eslint-disable-next-line no-unused-vars
  canExecute(context: Context = null): boolean {
    throw new Error('Abstract method.');
  }

  // eslint-disable-next-line no-unused-vars
  execute(context: Context = null): void {
    throw new Error('Abstract method.');
  }
}
