import _ from 'lodash';
import moment from 'moment-timezone';

import schedulingService, { GM_HELIX_COUPON_CODE } from 'services/api/schedulingService';
import { GetStateTimezone } from 'services/geo';
import { HelixReferral } from 'services/misc/ReferralSource';
import { showLoader, closeLoader } from './ui';
import { loadAppointments } from './appointments';

import HardcodedDescriptors, { CONSULTATION, SERVICE_PACKAGE } from 'constants/ServiceTypes';
import { setModalMode } from 'ducks/modals';
import ModalNames from '../constants/ModalNames';

const GEOLOCATE_STATE_AND_TIMEZONE = 'gm/scheduling/GEOLOCATE_STATE_AND_TIMEZONE';
const COUNTRY_SELECTED_BY_USER = 'gm/scheduling/COUNTRY_SELECTED_BY_USER';
const STATE_SELECTED_BY_USER = 'gm/scheduling/STATE_SELECTED_BY_USER';
const TIMEZONE_SELECTED_BY_USER = 'gm/scheduling/TIMEZONE_SELECTED_BY_USER';
const SPECIALITY_SELECTED_BY_USER = 'gm/scheduling/SPECIALITY_SELECTED_BY_USER';
const PROVIDER_SELECTED_BY_USER = 'gm/scheduling/PROVIDER_SELECTED_BY_USER';
const DEFAULT_SPECIALITY_SELECTED_BY_USER = 'gm/scheduling/DEFAULT_SPECIALITY_SELECTED_BY_USER';
const EXCEPTIONAL_SPECIALTY = 'gm/scheduling/EXCEPTIONAL_SPECIALTY';
const RESET_EXCEPTIONAL_SPECIALTY = 'gm/scheduling/RESET_EXCEPTIONAL_SPECIALTY';
const MODALITY_SELECTED_BY_USER = 'gm/scheduling/MODALITY_SELECTED_BY_USER';
const SET_DEFAULT_STATE_AND_TIMEZONE = 'gm/scheduling/SET_DEFAULT_STATE_AND_TIMEZONE';
const SET_DEFAULT_VSEE_SPECIALTY = 'gm/scheduling/SET_DEFAULT_VSEE_SPECIALTY';
const CHOOSE_APPOINTMENT = 'gm/scheduling/CHOOSE_APPOINTMENT';
const CANCEL_SELECTED_APPOINTMENT = 'gm/scheduling/CANCEL_SELECTED_APPOINTMENT';
const RESERVE_APPOINTMENT_START = 'gm/scheduling/RESERVE_APPOINTMENT_START';
const RESERVE_APPOINTMENT_COMPLETE = 'gm/scheduling/RESERVE_APPOINTMENT_COMPLETE';
const SCHEDULE_FAILURE = 'gm/scheduling/SCHEDULE_FAILURE';
const UNRESERVE_APPOINTMENT_START = 'gm/scheduling/UNRESERVE_APPOINTMENT_START';
const UNRESERVE_APPOINTMENT = 'gm/scheduling/UNRESERVE_APPOINTMENT';
const START_SCHEDULING_LOADING = 'gm/scheduling/START_SCHEDULING_LOADING';
const COMPLETE_SCHEDULING_LOADING = 'gm/scheduling/COMPLETE_SCHEDULING_LOADING';
const CLEAR_SCHEDULING_INFO = 'gm/scheduling/CLEAR_SCHEDULING_INFO';
const SET_AVAILABLE_APPOINTMENTS = 'gm/scheduling/SET_AVAILABLE_APPOINTMENTS';
const LOAD_AVAILABLE_APPOINTMENTS_START = 'gm/scheduling/LOAD_AVAILABLE_APPOINTMENTS_START';
const LOAD_AVAILABLE_APPOINTMENTS_COMPLETE = 'gm/scheduling/LOAD_AVAILABLE_APPOINTMENTS_COMPLETE';
const LOAD_AVAILABLE_APPOINTMENTS_FAILURE = 'gm/scheduling/LOAD_AVAILABLE_APPOINTMENTS_FAILURE';

const SET_AVAILABLE_DATE = 'gm/scheduling/SET_AVAILABLE_DATE';
const LOAD_AVAILABLE_DATE_START = 'gm/scheduling/LOAD_AVAILABLE_DATE_START';
const LOAD_AVAILABLE_DATE_COMPLETE = 'gm/scheduling/LOAD_AVAILABLE_DATE_COMPLETE';
const LOAD_AVAILABLE_DATE_FAILURE = 'gm/scheduling/LOAD_AVAILABLE_DATE_FAILURE';
const CLEAR_AVAILABLE_DATES = 'gm/scheduling/CLEAR_AVAILABLE_CLEAR_AVAILABLE_DATES';

const CLEAR_AVAILABLE_APPOINTMENTS = 'gm/scheduling/CLEAR_AVAILABLE_APPOINTMENTS';
const CALENDAR_DATE_CHANGED = 'gm/scheduling/CALENDAR_DATE_CHANGED';
const ENTER_COUPON_START = 'gm/scheduling/ENTER_COUPON_START';
const ENTER_COUPON_SUCCESS = 'gm/scheduling/ENTER_COUPON_SUCCESS';
const ENTER_COUPON_FAILURE = 'gm/scheduling/ENTER_COUPON_FAITURE';
const CLEAR_COUPON = 'gm/scheduling/CLEAR_COUPON';
const SET_SESSION_COUPON_CODE = 'gm/scheduling/SET_SESSION_COUPON_CODE';
const SET_COUPON_CODE_READ_ONLY = 'gm/scheduling/SET_COUPON_CODE_READ_ONLY';
const SET_SELECTED_SERVICE_AND_PRODUCT = 'gm/scheduling/SET_SELECTED_SERVICE_AND_PRODUCT';
const CLEAR_SELECTED_SERVICE_AND_PRODUCT = 'gm/scheduling/CLEAR_SELECTED_SERVICE_AND_PRODUCT';
const CLEAR_SCHEDULING_ERROR = 'gm/scheduling/CLEAR_SCHEDULING_ERROR';
const RESCHEDULING_FAILURE = 'gm/scheduling/RESCHEDULING_FAILURE';
const CLEAR_RESERVED_APPOINTMENT = 'gm/scheduling/CLEAR_RESERVED_APPOINTMENT';
const SET_PAY_WITH_INSURANCE = 'gm/scheduling/SET_PAY_WITH_INSURANCE';
const SET_SOURCE_SUCCESS = 'gm/scheduling/SET_SOURCE_SUCCESS';

const CLEAR_STATE = 'CLEAR_STATE'; // temp

const LATER_APPOINTMENT_WARNING = 'New appointment must be later than current appointment.';

const initialState = {
  calendarCountry: null,
  calendarState: null,
  calendarTimezone: null,
  calendarSpeciality: null,
  calendarProvider: undefined,
  calendarModality: null,
  calendarStartTime: moment(new Date()).startOf('day').toDate(),
  selectedServiceDescriptor: null,
  selectedProduct: null,
  isSchedulingLoading: false,
  isSchedulingLoaded: false,
  isLoadingAvailability: false,
  isAvailabilityLoaded: false,
  isUnreserving: false,
  consultationTypes: [],
  servicePackages: [],
  purchasedServicePackages: [],
  reasonsForVisit: [],
  counselors: [],
  slots: [],
  reservations: [],
  selectedAppointment: null,
  reservedAppointment: schedulingService.getReservedAppointment(),
  schedulingError: null,
  isCheckingCouponCode: false,
  appliedCoupon: null,
  sessionCouponCode: schedulingService.getSavedCouponCode(),
  readOnlyCouponCode: false,
  couponError: null,
  OutreachAppointment: null,
  isOutreachAppointmentFlow: false,
  payWithInsuranceState: null,
  availableDates: [],
  isLoadingAvailableDates: false,
  isAvailableDatesLoaded: false,
};

function mergeCouponSuccess(state, data) {
  if (state.appliedCoupon && state.appliedCoupon.code === data.code && state.appliedCoupon.items) {
    return {
      code: data.code,
      items: [...state.appliedCoupon.items, ...data.items],
    };
  }
  return {
    ...data,
    couponError: null,
  };
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SELECTED_SERVICE_AND_PRODUCT:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_SELECTED_SERVICE_AND_PRODUCT:
      return {
        ...state,
        selectedServiceDescriptor: initialState.selectedServiceDescriptor,
        selectedProduct: initialState.selectedProduct,
      };
    case STATE_SELECTED_BY_USER:
      return {
        ...state,
        calendarState: action.payload,
        calendarTimezone: GetStateTimezone(action.payload),
      };
    case COUNTRY_SELECTED_BY_USER:
      return {
        ...state,
        calendarCountry: action.payload,
        calendarState: null,
        calendarTimezone: null,
      };
    case GEOLOCATE_STATE_AND_TIMEZONE:
      return {
        ...state,
        ...(!state.calendarState
          ? { calendarState: action.payload.state, calendarTimezone: action.payload.timezone }
          : {}),
      };
    case SET_DEFAULT_STATE_AND_TIMEZONE:
      return {
        ...state,
        calendarCountry: action.payload.country,
        calendarState: action.payload.state,
        calendarTimezone: action.payload.timezone || GetStateTimezone(action.payload.state),
      };
    case SET_DEFAULT_VSEE_SPECIALTY:
      return {
        ...state,
        calendarSpeciality: GetSpeciality(action.payload.vsee_specialty),
      };
    case CALENDAR_DATE_CHANGED:
      return { ...state, calendarStartTime: action.payload };
    case TIMEZONE_SELECTED_BY_USER:
      return { ...state, calendarTimezone: action.payload };
    case SPECIALITY_SELECTED_BY_USER:
      return { ...state, calendarSpeciality: action.payload };
    case PROVIDER_SELECTED_BY_USER:
      return { ...state, calendarProvider: action.payload };
    case DEFAULT_SPECIALITY_SELECTED_BY_USER:
      return { ...state, calendarSpeciality: GetSpeciality(action.payload) };
    case EXCEPTIONAL_SPECIALTY:
      return { ...state, exceptionalSpecialty: action.payload };
    case RESET_EXCEPTIONAL_SPECIALTY:
      return { ...state, exceptionalSpecialty: null };
    case MODALITY_SELECTED_BY_USER:
      return { ...state, calendarModality: action.payload };
    case CHOOSE_APPOINTMENT:
      return { ...state, selectedAppointment: action.payload, schedulingError: null };
    case START_SCHEDULING_LOADING:
      return { ...state, isSchedulingLoading: true, isSchedulingLoaded: false };
    case COMPLETE_SCHEDULING_LOADING:
      return { ...state, ...action.payload, isSchedulingLoaded: true, isSchedulingLoading: false };
    case CLEAR_SCHEDULING_INFO:
      return {
        ...state,
        consultationTypes: [],
        servicePackages: [],
        purchasedServicePackages: [],
        reasonsForVisit: [],
        counselors: [],
        isSchedulingLoaded: false,
        isSchedulingLoading: false,
      };
    case LOAD_AVAILABLE_DATE_START:
      return { ...state, isLoadingAvailableDates: true, isAvailabilityLoaded: false };
    case SET_AVAILABLE_DATE:
      return { ...state, availableDates: action.payload };
    case LOAD_AVAILABLE_DATE_COMPLETE:
      return { ...state, isLoadingAvailableDates: false, isAvailabilityLoaded: true };
    case LOAD_AVAILABLE_DATE_FAILURE:
      return { ...state, isLoadingAvailableDates: false, isAvailabilityLoaded: false };
    case CLEAR_AVAILABLE_DATES:
      return { ...state, availableDates: [], isAvailabilityLoaded: false };

    case LOAD_AVAILABLE_APPOINTMENTS_START:
      return { ...state, isLoadingAvailability: true, isAvailabilityLoaded: false };
    case SET_AVAILABLE_APPOINTMENTS:
      return { ...state, ...action.payload };
    case LOAD_AVAILABLE_APPOINTMENTS_COMPLETE:
      return { ...state, isLoadingAvailability: false, isAvailabilityLoaded: true };
    case LOAD_AVAILABLE_APPOINTMENTS_FAILURE:
      return { ...state, isLoadingAvailability: false, isAvailabilityLoaded: false };
    case CLEAR_AVAILABLE_APPOINTMENTS:
      return { ...state, slots: [], reservations: [], isAvailabilityLoaded: false };
    case CANCEL_SELECTED_APPOINTMENT:
      return { ...state, selectedAppointment: null };

    case RESERVE_APPOINTMENT_START:
      return { ...state, reservedAppointment: null, schedulingError: null };
    case RESERVE_APPOINTMENT_COMPLETE:
      return {
        ...state,
        reservedAppointment: state.selectedAppointment,
        isReserved: true,
        selectedAppointment: null,
      };
    case SCHEDULE_FAILURE:
      return { ...state, schedulingError: action.payload };

    case UNRESERVE_APPOINTMENT_START:
      return { ...state, isUnreserving: true };

    case CLEAR_RESERVED_APPOINTMENT:
    case UNRESERVE_APPOINTMENT:
      return { ...state, reservedAppointment: null, isUnreserving: false };

    case CLEAR_STATE:
      return { ...state, calendarState: null, calendarTimezone: null };

    case SET_SESSION_COUPON_CODE:
      return {
        ...state,
        sessionCouponCode: action.payload,
        couponError: null,
      };
    case SET_COUPON_CODE_READ_ONLY:
      return {
        ...state,
        readOnlyCouponCode: action.payload,
      };

    case ENTER_COUPON_START: {
      return { ...state, isCheckingCouponCode: true };
    }
    case ENTER_COUPON_SUCCESS: {
      const appliedCoupon = mergeCouponSuccess(state, action.payload);
      return {
        ...state,
        appliedCoupon,
        sessionCouponCode: appliedCoupon.code,
        isCheckingCouponCode: false,
        couponError: null,
      };
    }
    case ENTER_COUPON_FAILURE: {
      return {
        ...state,
        couponError: action.payload,
        isCheckingCouponCode: false,
      };
    }
    case CLEAR_COUPON: {
      return {
        ...state,
        appliedCoupon: null,
        couponError: null,
        sessionCouponCode: null,
        readOnlyCouponCode: false,
      };
    }
    case RESCHEDULING_FAILURE:
      return { ...state, schedulingError: action.payload };
    case CLEAR_SCHEDULING_ERROR:
      return { ...state, schedulingError: null };
    case SET_PAY_WITH_INSURANCE:
      return {
        ...state,
        payWithInsuranceState: action.payload,
      };
    default:
      return state;
  }
}

export function GetSpeciality(vsee_specialty) {
  let temp_vsee_specialty = null;
  if (vsee_specialty !== 'No_Default') {
    temp_vsee_specialty =
      vsee_specialty && vsee_specialty.includes('_Willing')
        ? vsee_specialty.replace('_Willing', '')
        : vsee_specialty;
  }
  return temp_vsee_specialty;
}
// ------------- ACTIONS --------------------

export function setSchedulingLoadingStart() {
  return { type: START_SCHEDULING_LOADING };
}

export function setSchedulingLoadingComplete(data) {
  return { type: COMPLETE_SCHEDULING_LOADING, payload: data };
}

export function selectStateByUser(usaState) {
  return { type: STATE_SELECTED_BY_USER, payload: usaState };
}

export function selectCountryByUser(country) {
  return { type: COUNTRY_SELECTED_BY_USER, payload: country };
}

export function geolocateStateAndTimezone(usaState) {
  return { type: GEOLOCATE_STATE_AND_TIMEZONE, payload: usaState };
}

export function selectTimezoneByUser(timezone) {
  return { type: TIMEZONE_SELECTED_BY_USER, payload: timezone };
}

export function selectSpecialityByUser(speciality) {
  return { type: SPECIALITY_SELECTED_BY_USER, payload: speciality };
}

export function selectModalityByUser(modality) {
  return { type: MODALITY_SELECTED_BY_USER, payload: modality };
}

export function selectProviderByUser(provider) {
  return { type: PROVIDER_SELECTED_BY_USER, payload: provider };
}

export function selectDefaultSpecialityByUser(speciality) {
  return { type: DEFAULT_SPECIALITY_SELECTED_BY_USER, payload: speciality };
}

export function setExceptionalSpecialty(specialty) {
  return { type: EXCEPTIONAL_SPECIALTY, payload: specialty };
}

export function resetExceptionalSpecialty() {
  return { type: RESET_EXCEPTIONAL_SPECIALTY, payload: null };
}

export function chooseAppointment(appointment) {
  return { type: CHOOSE_APPOINTMENT, payload: appointment };
}

export function cancelSelectedAppointment() {
  return { type: CANCEL_SELECTED_APPOINTMENT };
}

export function calendarDateChanged(newDate) {
  return { type: CALENDAR_DATE_CHANGED, payload: newDate };
}

export function setDefaultStateAndTimezone(country, state, timezone, vsee_specialty) {
  return {
    type: SET_DEFAULT_STATE_AND_TIMEZONE,
    payload: { country, state, timezone, vsee_specialty },
  };
}

export function setDefaultVseeSpecialty(vsee_specialty) {
  return { type: SET_DEFAULT_VSEE_SPECIALTY, payload: { vsee_specialty } };
}

export function setOutreachAppointment(appointment) {
  return schedulingService.setOutreachAppointmentData(appointment);
}
export function setPayWithInsurance(value) {
  return { type: SET_PAY_WITH_INSURANCE, payload: value };
}
export function resetOutreachAppointment() {
  schedulingService.clearOutreachAppointmentData();
  return { type: PROVIDER_SELECTED_BY_USER, payload: undefined };
}

export function clearState() {
  return { type: CLEAR_STATE }; // temp!
}

export function setReserveAppointmentStart() {
  return { type: RESERVE_APPOINTMENT_START };
}

export function setReserveAppointmentComplete() {
  return { type: RESERVE_APPOINTMENT_COMPLETE };
}

export function clearReservedAppointment() {
  return { type: CLEAR_RESERVED_APPOINTMENT };
}

export function setAvailableAppointmentLoadingStart() {
  return { type: LOAD_AVAILABLE_APPOINTMENTS_START };
}

export function setAppointmentUnreservingStart() {
  return { type: UNRESERVE_APPOINTMENT_START };
}

export function setAppointmentUnreserving() {
  return { type: UNRESERVE_APPOINTMENT };
}

export function setSchedulingFailure(message) {
  return { type: SCHEDULE_FAILURE, payload: message };
}

export function setCouponEnteringStart() {
  return { type: ENTER_COUPON_START };
}

export function setCouponEnteringComplete(data) {
  return { type: ENTER_COUPON_SUCCESS, payload: data };
}

export function setCouponEnteringFailure(message) {
  return { type: ENTER_COUPON_FAILURE, payload: message };
}

export function setCouponClearStart() {
  return { type: CLEAR_COUPON };
}

export function clearSchedulingError() {
  return { type: CLEAR_SCHEDULING_ERROR };
}

export function setAvailableAppointmentLoadingComplete() {
  return {
    type: LOAD_AVAILABLE_APPOINTMENTS_COMPLETE,
  };
}

export function clearSchedulingInfo() {
  return {
    type: CLEAR_SCHEDULING_INFO,
  };
}

export function setAvailableAppointments(data) {
  return {
    type: SET_AVAILABLE_APPOINTMENTS,
    payload: {
      slots: data.availableSlots || [],
      reservations: data.reservations || [],
    },
  };
}

export function clearAvailableAppointments() {
  return {
    type: CLEAR_AVAILABLE_APPOINTMENTS,
  };
}

export function setAvailableAppointmentLoadingFailure() {
  return { type: LOAD_AVAILABLE_APPOINTMENTS_FAILURE };
}

//Dates
export function setAvailableDatesLoadingStart() {
  return { type: LOAD_AVAILABLE_DATE_START };
}

export function setAvailableDates(data) {
  return {
    type: SET_AVAILABLE_DATE,
    payload: data,
  };
}

export function setAvailableDatesLoadingComplete() {
  return {
    type: LOAD_AVAILABLE_DATE_COMPLETE,
  };
}

export function setAvailableDatesLoadingFailure() {
  return { type: LOAD_AVAILABLE_DATE_FAILURE };
}

export function clearAvailableDates() {
  return {
    type: CLEAR_AVAILABLE_DATES,
  };
}

export function loadScheduling() {
  return (dispatch) => {
    dispatch(setSchedulingLoadingStart());
    return schedulingService.loadScheduling().then((data) => {
      dispatch(setSchedulingLoadingComplete(data));
    });
  };
}

export function loadSchedulingIfNeeded() {
  return (dispatch, getState) => {
    const { isSchedulingLoaded, isSchedulingLoading } = getState().scheduling;
    if (isSchedulingLoaded || isSchedulingLoading) {
      return Promise.resolve();
    }
    return dispatch(loadScheduling());
  };
}

export function canLoadAvailableAppointments(scheduling) {
  const {
    isSchedulingLoaded,
    calendarState,
    calendarTimezone,
    selectedProduct,
    selectedServiceDescriptor,
  } = scheduling;
  return !!(
    isSchedulingLoaded &&
    selectedProduct &&
    selectedServiceDescriptor &&
    calendarState &&
    calendarTimezone
  );
}

export const loadAvailableAppointments = (() => {
  let availableAppointmentsCounter = 0;

  return () => (dispatch, getState) => {
    const {
      calendarCountry,
      calendarStartTime,
      calendarTimezone,
      calendarState,
      calendarSpeciality,
      calendarProvider,
      selectedServiceDescriptor,
      selectedProduct,
      consultationTypes,
    } = getState().scheduling;
    if (calendarCountry && calendarTimezone && calendarState && calendarSpeciality) {
      dispatch(setAvailableAppointmentLoadingStart());
      availableAppointmentsCounter += 1;
      const localCounter = availableAppointmentsCounter;
      schedulingService
        .loadAvailableAppointments(
          calendarStartTime,
          calendarTimezone,
          selectedServiceDescriptor.isPackage
            ? getConsultation(selectedServiceDescriptor, selectedProduct, consultationTypes).id
            : selectedProduct.id,
          calendarState,
          calendarSpeciality,
          calendarProvider,
          calendarCountry
        )
        .then((r) => {
          if (availableAppointmentsCounter === localCounter) {
            // we compare counters in order to skip old responses if there was newer requests
            dispatch(setAvailableAppointments(r));
            dispatch(setAvailableAppointmentLoadingComplete());
          }
        })
        .catch((error) => {
          dispatch(setAvailableAppointmentLoadingFailure());
          throw error;
        });
    }
  };
})();

//Dates
export const loadAvailableDates = (() => {
  return () => (dispatch, getState) => {
    const {
      calendarStartTime,
      calendarTimezone,
      calendarState,
      calendarSpeciality,
      selectedServiceDescriptor,
      selectedProduct,
      consultationTypes,
      calendarProvider,
    } = getState().scheduling;
    const calendarMonth = moment(calendarStartTime).format('YYYY-MM');
    if (calendarTimezone && calendarState && calendarSpeciality) {
      dispatch(setAvailableDatesLoadingStart());
      dispatch(clearSchedulingError());

      schedulingService
        .loadAvailableDates(
          calendarTimezone,
          selectedServiceDescriptor.isPackage
            ? getConsultation(selectedServiceDescriptor, selectedProduct, consultationTypes).id
            : selectedProduct.id,
          calendarState,
          calendarSpeciality,
          calendarMonth,
          calendarProvider
        )
        .then((r) => {
          dispatch(setAvailableDates(r));
          dispatch(setAvailableDatesLoadingComplete());
        })
        .catch((error) => {
          dispatch(setAvailableDatesLoadingFailure());
          if (error.code == 'pioneer_configuration_error') {
            dispatch(setModalMode(ModalNames.AppointmentReschedulingFailedModal, { open: true }));
          }
          throw error;
        });
    }
  };
})();

//Times
export const loadAvailableTimes = (date) => {
  return (dispatch, getState) => {
    const {
      calendarTimezone,
      calendarState,
      calendarSpeciality,
      selectedServiceDescriptor,
      selectedProduct,
      consultationTypes,
      calendarProvider,
    } = getState().scheduling;
    if (calendarTimezone && calendarState && calendarSpeciality) {
      dispatch(setAvailableAppointmentLoadingStart());
      schedulingService
        .loadAvailableTimes(
          moment(date).format('MM/DD/YYYY'),
          calendarTimezone,
          selectedServiceDescriptor.isPackage
            ? getConsultation(selectedServiceDescriptor, selectedProduct, consultationTypes).id
            : selectedProduct.id,
          calendarState,
          calendarSpeciality,
          calendarProvider
        )
        .then((r) => {
          dispatch(setAvailableAppointments(r));
          dispatch(setAvailableAppointmentLoadingComplete());
        })
        .catch((error) => {
          dispatch(setAvailableAppointmentLoadingFailure());
          throw error;
        });
    }
  };
};

export function updateTimezone() {
  return (dispatch, getState) => {
    const { slots, reservations, calendarTimezone } = getState().scheduling;
    const normalizedSlots = schedulingService.getTimezoneNormalizedSlots(
      {
        availableSlots: slots,
        reservations: reservations,
      },
      calendarTimezone
    );
    dispatch(setAvailableAppointments(normalizedSlots));
  };
}

export function unreserveAppointment() {
  return (dispatch) => {
    dispatch(setAppointmentUnreservingStart());
    return schedulingService
      .unreserveAppointment()
      .then(() => dispatch(setAppointmentUnreserving()))
      .catch(() => dispatch(setAppointmentUnreserving()));
  };
}

export function reserveAppointment() {
  return async (dispatch, getState) => {
    const { selectedAppointment } = getState().scheduling;
    dispatch(setReserveAppointmentStart());
    dispatch(showLoader());
    try {
      await dispatch(checkAppointmentsOrderOnSchedule(false));
      await schedulingService.reserveAppointment(selectedAppointment);
      dispatch(setReserveAppointmentComplete());
      dispatch(closeLoader());
    } catch (e) {
      dispatch(closeLoader());
      if (e && e.status === 409)
        dispatch(
          setSchedulingFailure(
            'We’re sorry — a few moments ago, ' +
              'another patient was scheduled for that time. Please select a new appointment time.'
          )
        );
      else if (e && e.status === 404)
        dispatch(
          setSchedulingFailure(
            'We’re sorry — the genetics expert you requested ' +
              'isn’t available at that time. Please select another time for your appointment.'
          )
        );
      else if (e && e.message) {
        dispatch(setSchedulingFailure(e.message));
      }
      throw e;
    }
  };
}

export function rescheduleAppointment() {
  return async (dispatch, getState) => {
    dispatch(clearSchedulingError());
    dispatch(showLoader());
    dispatch(setCouponEnteringStart());
    const {
      scheduling: { selectedAppointment },
      appointment: {
        data: { id },
      },
    } = getState();

    try {
      await dispatch(checkAppointmentsOrderOnSchedule(true));
      await schedulingService.rescheduleAppointment(id, selectedAppointment);
      window.location = '/patient';
    } catch (error) {
      dispatch(closeLoader());
      if (error.code == 'pioneer_configuration_error') {
        dispatch(setModalMode(ModalNames.AppointmentReschedulingFailedModal, { open: true }));
      } else {
        dispatch(setModalMode(ModalNames.PioneerConfigrationModal, { open: true }));
        dispatch({ type: RESCHEDULING_FAILURE, payload: error.message });
      }

      throw error;
    }
  };
}

function checkAppointmentsOrderOnSchedule(isReschedule) {
  return async (dispatch, getState) => {
    await dispatch(loadAppointments());
    const {
      scheduling: { selectedAppointment },
      appointments,
    } = getState();
    const liveAppointments = _(appointments.data)
      .filter(
        (a) =>
          a.latestStatus &&
          (a.latestStatus.status === 'completed' || a.latestStatus.status === 'booked')
      )
      .sortBy((a) => +a.mStartTime)
      .value();
    let prevAppointment = null;
    if (isReschedule && liveAppointments.length > 1) {
      // has appointment prior to current
      prevAppointment = liveAppointments.slice(-2)[0];
    } else if (!isReschedule && liveAppointments.length > 0) {
      // has previous completed appointment
      prevAppointment = liveAppointments.slice(-1)[0];
    }
    if (prevAppointment && !canSchedule(selectedAppointment, prevAppointment))
      throw new Error(LATER_APPOINTMENT_WARNING);
  };
}

/**
 * Gets coupon info from the back-end about products from the "products" array.
 * Adds this info to store
 * @param {string} code Coupon code
 * @param {*} saveCoupon indicates that coupon code will be saved in sessionStorage
 */
export function submitCouponCode(code, saveCoupon = true) {
  return (dispatch, getState) => {
    const { servicePackages, consultationTypes, purchasedServicePackages } = getState().scheduling;
    const packageProducts = servicePackages.map((s) => ({
      productType: SERVICE_PACKAGE,
      productId: s.id,
    }));
    const consultationProducts = consultationTypes.map((c) => ({
      productType: CONSULTATION,
      productId: c.id,
    }));
    const products = packageProducts.concat(consultationProducts);

    dispatch(setCouponEnteringStart());
    return schedulingService
      .enterCouponCode(code, products, saveCoupon)
      .then((data) => {
        const { selectedServiceDescriptor, selectedProduct } = getState().scheduling;
        const appliedCoupon = mergeCouponSuccess(getState(), data);

        /* If coupon is not applicable to current selected service, it shouldn't be applied */
        if (
          appliedCoupon &&
          selectedServiceDescriptor &&
          selectedProduct &&
          !findCouponItem({
            appliedCoupon,
            selectedServiceDescriptor,
            selectedProduct,
            purchasedServicePackages,
          })
        ) {
          schedulingService.clearCouponCode();
          dispatch(
            setCouponEnteringFailure({
              fullMessage: 'This coupon is not applicable for selected service',
            })
          );
          return;
        }
        dispatch(setCouponEnteringComplete(data));
      })
      .catch((error) => {
        schedulingService.clearCouponCode();
        dispatch(setCouponEnteringFailure(error));
      });
  };
}

/**
 *
 * @param {*} products
 */
export function checkSavedCouponCode() {
  return submitCouponCode(null);
}

export function clearCoupon() {
  schedulingService.clearCouponCode();
  return setCouponClearStart();
}

export function setSessionCouponCode(code, save) {
  return (dispatch) => {
    if (save) schedulingService.saveCouponCode(code);
    dispatch({ type: SET_SESSION_COUPON_CODE, payload: code });
  };
}

export function setCouponCodeReadOnly(readOnly = false) {
  return (dispatch) => {
    dispatch({ type: SET_COUPON_CODE_READ_ONLY, payload: readOnly });
  };
}

export function checkHelixReferral() {
  return (dispatch, getState) => {
    const { appointment, user } = getState();
    if (user.me && user.me.referral === HelixReferral && _.isEmpty(appointment.data)) {
      dispatch(setSessionCouponCode(GM_HELIX_COUPON_CODE, true));
    }
  };
}

export function setSelectedServiceAndProduct(serviceDescriptor, product) {
  // internal action
  return {
    type: SET_SELECTED_SERVICE_AND_PRODUCT,
    payload: {
      selectedServiceDescriptor: serviceDescriptor,
      selectedProduct: product,
    },
  };
}

export function clearSelectedService() {
  return { type: CLEAR_SELECTED_SERVICE_AND_PRODUCT };
}

export function setSelectedService(serviceType) {
  return (dispatch, getState) => {
    const {
      scheduling: { servicePackages, consultationTypes },
    } = getState();
    const serviceDescriptor = composeServiceDescriptor(
      serviceType,
      servicePackages,
      consultationTypes
    );
    return dispatch(
      setSelectedServiceAndProduct(
        serviceDescriptor,
        dispatch(getSelectedProduct(serviceDescriptor))
      )
    );
  };
}

export function composeServiceDescriptor(type, servicePackages, consultationTypes) {
  // for Hardcoded consultation types: type argument potentially NOT EQUAL consultation.type
  // ('test' & 'test_guidance' for example), to get consultation.type: HardcodedDescriptors[type].type
  const hardcodedDescriptor = HardcodedDescriptors[type];
  const hardcodedConsultation =
    hardcodedDescriptor && consultationTypes.find((c) => c.type === hardcodedDescriptor.type);
  const consultation = hardcodedConsultation || consultationTypes.find((c) => c.type === type);
  const pack = !consultation && servicePackages.find((p) => p.alias === type);
  return (
    (pack && composeDescriptorFromPackage(pack)) ||
    (consultation && composeDescriptorFromConsultation(consultation))
  );
}

export function composeDescriptorFromConsultation(consultation) {
  return {
    name: consultation.name,
    type: consultation.type,
    productName: consultation.name,
  };
}

export function composeDescriptorFromPackage(pack) {
  return {
    isPackage: true,
    name: pack.name,
    type: pack.alias,
    productName: pack.name,
  };
}

export function getSelectedProduct(serviceDescriptor) {
  return (dispatch, getState) => {
    if (!serviceDescriptor) return null;
    const { consultationTypes, servicePackages, purchasedServicePackages } = getState().scheduling;

    const activePackages = !_.isEmpty(purchasedServicePackages)
      ? purchasedServicePackages
      : servicePackages;
    if (serviceDescriptor.isPackage) {
      const selectedPackage =
        !_.isEmpty(activePackages) &&
        activePackages.find((p) => p.alias === serviceDescriptor.type);
      return selectedPackage
        ? { ...selectedPackage, isRegular: false, isCDT: false, isServicePackage: true }
        : null;
    } else {
      let selectedConsultation =
        (!_.isEmpty(consultationTypes) &&
          consultationTypes.find((p) => p.type === serviceDescriptor.type)) ||
        null;
      if (!_.isNil(selectedConsultation)) {
        if (
          selectedConsultation.isPredefined &&
          purchasedServicePackages &&
          purchasedServicePackages.length > 0
        ) {
          return {
            ...selectedConsultation,
            isRegular: false,
            isCDT: false,
            isServicePackage: true,
          };
        } else if (
          selectedConsultation.isPredefined &&
          purchasedServicePackages &&
          purchasedServicePackages.length == 0
        ) {
          return {
            ...selectedConsultation,
            isRegular: false,
            isCDT: true,
            isServicePackage: false,
          };
        } else {
          return {
            ...selectedConsultation,
            isRegular: true,
            isCDT: false,
            isServicePackage: false,
          };
        }
      } else return null;
    }
  };
}

export function getFirstPackageConsultation(product, consultationTypes) {
  // get consultation info from consultationTypes cause consultationType contains proper
  // partner consultation for scheduling and it must contains actual price
  if (!product) return null;
  if (!_.isEmpty(consultationTypes)) {
    return consultationTypes.length === 1
      ? _.first(consultationTypes)
      : _.first(product.consultations) &&
          consultationTypes.find((t) => t.type === _.first(product.consultations).type);
  }
  return _.first(product.consultations);
}

export function getConsultation(serviceDescriptor, product, consultationTypes = null) {
  if (!serviceDescriptor || !product) return null;
  if (!_.isEmpty(product.consultations)) {
    return getFirstPackageConsultation(product, consultationTypes);
  }
  return product;
}

export function updateSelectedServiceForReschedule() {
  return (dispatch, getState) => {
    const {
      scheduling: {
        selectedProduct,
        selectedServiceDescriptor,
        isSchedulingLoaded,
        servicePackages,
        consultationTypes,
      },
      appointment,
      userServicePackages,
    } = getState();

    if (
      (selectedProduct && selectedServiceDescriptor) ||
      !isSchedulingLoaded ||
      !appointment.loaded ||
      _.isEmpty(appointment.data) ||
      !appointment.data.isFuture ||
      !userServicePackages.loaded
    ) {
      return;
    }

    const appointmentId = appointment.data.id;
    let servicePackage, product, serviceDescriptor;
    if (!_.isEmpty(userServicePackages.data))
      servicePackage = userServicePackages.data.find((p) =>
        p.prepaidConsultations.find((c) => c.appointmentId === appointmentId)
      );
    if (servicePackage) {
      product = servicePackages.find((p) => p.id === servicePackage.id);
    } else {
      product = consultationTypes.find(
        (c) => appointment.data.consultation && c.id === appointment.data.consultation.id
      );
    }
    if (product) {
      serviceDescriptor = composeServiceDescriptor(
        product.type || product.alias,
        servicePackages,
        consultationTypes
      );
      return dispatch(setSelectedServiceAndProduct(serviceDescriptor, product));
    }
  };
}

export function findCouponItem({
  appliedCoupon,
  selectedServiceDescriptor,
  selectedProduct,
  purchasedServicePackages,
}) {
  if (_.isNil(selectedProduct) || _.isNil(selectedServiceDescriptor)) return null;

  let item = null;
  if (appliedCoupon && appliedCoupon.items) {
    if (
      selectedProduct &&
      selectedProduct.isPredefined &&
      _.get(purchasedServicePackages, ['0', 'id'])
    ) {
      const firstservicepackageid = _.get(purchasedServicePackages, ['0', 'id']);
      item = appliedCoupon.items.find(
        (o) => o.productId === firstservicepackageid && o.productType === SERVICE_PACKAGE
      );
    }
    if (_.isNil(item)) {
      item = appliedCoupon.items.find(
        (o) =>
          o.productId === selectedProduct.id &&
          o.productType ===
            (selectedServiceDescriptor && selectedServiceDescriptor.isPackage
              ? SERVICE_PACKAGE
              : CONSULTATION)
      );
    }
    return item || null;
  }
  return null;
}

// Returns a price of a product modified by coupon code (JSdoc's screwing up here =(  )
// TODO: should be refactored! properties via dispatch are not safe
export function getNewPrice() {
  return (dispatch, getState) => {
    const { scheduling, userServicePackages } = getState();
    const coupon = findCouponItem(scheduling);
    if (coupon) return coupon.price;

    const { selectedServiceDescriptor } = scheduling;
    if (selectedServiceDescriptor && !selectedServiceDescriptor.isPackage) {
      const product = dispatch(getSelectedProduct(selectedServiceDescriptor));
      const prepaid =
        userServicePackages.data.length > 0 &&
        userServicePackages.data[0].prepaidConsultations.find(
          (c) => c.consultationId === product.id && !c.appointmentId
        );

      if (prepaid) {
        return prepaid.payment.totalCharge;
      }
    }
    return null;
  };
}

export function clearCouponIfNotApplicable() {
  return (dispatch, getState) => {
    const {
      scheduling: { appliedCoupon, selectedServiceDescriptor, selectedProduct },
      userServicePackages,
    } = getState();
    let needClearCoupon = false;
    // clear coupon if consultation is from the purchased package
    needClearCoupon |= !!(
      userServicePackages.data &&
      userServicePackages.data.find((p) =>
        p.prepaidConsultations.find((c) => c.appointmentId == null)
      )
    );

    // clear coupon code if it's not found tor current selected product
    needClearCoupon |= !findCouponItem({
      appliedCoupon,
      selectedServiceDescriptor,
      selectedProduct,
    });
    if (needClearCoupon) {
      return dispatch(clearCoupon());
    }
  };
}

/**
 * Checks that new selected appointment is AFTER the previous appointment
 * @param {*} newAppointment
 * @param {*} previousAppointmnt
 */
function canSchedule(newAppointment, previousAppointmnt) {
  return (
    _.isEmpty(previousAppointmnt) ||
    moment(newAppointment.start).isAfter(moment(previousAppointmnt.startTime))
  );
}

function setSourceESuccess() {
  return { type: SET_SOURCE_SUCCESS };
}

export function setSessionSourceType(source) {
  schedulingService.saveSourceType(source);
  return setSourceESuccess();
}
