import React from 'react';
import { Field } from 'redux-form';
import _, { get } from 'lodash';
import classnames from 'classnames';
import {
    required,
} from 'services/validate';
import 'element-theme-default';
import RadioButtonGroup from 'components/forms/controls/RadioButtonGroup';

const AOEForm = (props) => {
    const { handleChange, initialState } = props;

    const setEnumsOptions = (options) => {
        return _.map(options, opt => {
            return {
                label: opt,
                value: opt
            }
        })
    }
    return (
        <div className='row-section aoe-form'>
            {initialState?.sexAssignedAtBirth == 'female'&&
              <div className="col-section" >
                        <div className={classnames("label-section", {
                            "required": true
                        })}> Are you currently pregnant?</div>
                        <div className="input-section">
                            <Field
                                name={`aoeForm.PREGNANT`}
                                type="select"
                                required={true}
                                placeholder={'Select'}
                                component={RadioButtonGroup}
                                children={setEnumsOptions(['Yes','No'])}
                                validate={[required('Are you currently pregnant?')]}
                                onChange={(e, value) => handleChange('PREGNANT', value)}
                                
                            />
                        </div>
                    </div>
}
                {initialState?.aoeForm?.PREGNANT == 'Yes' &&
                    <div className="col-section" >
                        <div className={classnames("label-section", {
                            "required": true
                        })}> What trimester are you in?</div>
                        <div className="input-section">
                            <Field
                                name={`aoeForm.TRIMESTER`}
                                type="select"
                                required={true}
                                placeholder={'Select'}
                                component={RadioButtonGroup}
                                children={setEnumsOptions(['First','Second','Third'])}
                                validate={[required('What trimester are you in?')]}
                                onChange={(e, value) => handleChange('TRIMESTER', value)}
                                
                            />
                        </div>
                    </div>
                    }
        </div>
    );
}

export default AOEForm
