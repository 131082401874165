const MENU_TOGGLED = 'gm/ui/MENU_TOGGLED';
const SHOW_LOADER = 'gm/ui/SHOW_LOADER';
const CLOSE_LOADER = 'gm/ui/CLOSE_LOADER';

export default function reducer(
  state = { loaderActive: false, loaderIndex: 0, activeLayout: 'public' },
  action = {}
) {
  switch (action.type) {
    case MENU_TOGGLED:
      return { ...state, mobileMenuOpened: action.payload };
    case SHOW_LOADER:
      return { ...state, loaderActive: true, loaderIndex: action.payload };
    case CLOSE_LOADER:
      return { ...state, loaderActive: false };
    default:
      return state;
  }
}

export function toggleMenu(isOpened) {
  return { type: MENU_TOGGLED, payload: isOpened };
}

export const showLoader = () => (dispatch, getState) => {
  const currentIndex = getState().ui.loaderIndex;
  setTimeout(() => {
    const { loaderIndex, loaderActive } = getState().ui;
    if (loaderActive && loaderIndex === currentIndex) dispatch({ type: CLOSE_LOADER });
  }, 10000);
  return dispatch({ type: SHOW_LOADER, payload: currentIndex + 1 });
};

export const closeLoader = () => dispatch => {
  return dispatch({ type: CLOSE_LOADER });
};

export const loaderSession = (dispatch, promise) => {
  dispatch(showLoader());
  return promise.then(() => dispatch(closeLoader())).catch(error => {
    dispatch(closeLoader());
    throw error;
  });
};
