import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import AppointmentModalTemplate from 'components/modals/SCP/AppointmentModalTemplate/AppointmentModalTemplate';
import WarningIcon from '../../../../images/warning.svg';

const CancelAppointmentModal = props => {
  const modalHeader =
    props.isMobile == true ? (
      <Fragment>
        <img src={WarningIcon} alt="Warning" class="warning-icon" /> Cancel Appointment
      </Fragment>
    ) : (
      'Do you want to cancel this appointment?'
    );
  return (
    <AppointmentModalTemplate
      {...props}
      invertAction={true}
      header={modalHeader}
      confirmText="Keep This Appointment"
      cancelText="Cancel this appointment"
      isRescheduling={true} // to prevent showing price
    />
  );
};

CancelAppointmentModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  appointment: PropTypes.object,
};

export default CancelAppointmentModal;
