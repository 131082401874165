import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ModalDialog from 'components/modals/ModalDialog';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import Alert from 'components/widgets/Alert/Alert';
import WarningIcon from '../../../../images/warning.svg';

import './PhoneAppointmentModal.scss';

const PhoneAppointmentModal = props => {
  const modalHeader =
    props.isMobile == true ? (
      <Fragment>
        <img src={WarningIcon} alt="Warning" class="warning-icon" />Switch to phone appointment
      </Fragment>
    ) : (
      'Would you prefer a phone appointment?'
    );
  return (
    <ModalDialog
      className={`md850 scp-phone-appointment-modal ${props.isMobile ? 'is-mobile' : ''}`}
      modalHeader={modalHeader}
      show={props.show}
      onHide={props.onHide}>
      <div className="wrapper">
        <div className="phone-appointment-content" data-hj-suppress>
          <span>
            No microphone or camera on your device? You can have your genetics consultation over the
            phone instead. Please confirm your number and we will call you when it is time to start
            your appointment.
          </span>
          <div className="phone-number">{props.phone}</div>
          <span>
            If your device has the equipment you need but you are having trouble with installation
            or registration, please review these{' '}
            <a className="zoom-instruction-link" href="https://zoom.us/download" target="_blank">
              step-by-step installation instructions.
            </a>
          </span>
        </div>
        {props.error || props.phoneError ? (
          <Alert message={props.error || props.phoneError} />
        ) : null}
        <div className="buttons-container">
          <ButtonV2 onClick={props.onHide} className="button-stay-with-zoom">
            Stay With Zoom
          </ButtonV2>
          {props.isMobile ? (
            <ButtonV2
              onClick={() => {
                props.onConfirm('phone');
                props.onAfterConfirm && props.onAfterConfirm();
              }}
              className="button-to-phone">
              Opt for phone appointment
            </ButtonV2>
          ) : (
            <span
              className="button-to-phone"
              onClick={() => {
                props.onConfirm('phone');
                props.onAfterConfirm && props.onAfterConfirm();
              }}>
              Opt for phone appointment
            </span>
          )}
        </div>
      </div>
    </ModalDialog>
  );
};

PhoneAppointmentModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  error: PropTypes.string,
  onConfirm: PropTypes.func,
  onAfterConfirm: PropTypes.func,
  isMobile: PropTypes.bool,
};

PhoneAppointmentModal.defaultProps = {
  isMobile: false,
};

export default PhoneAppointmentModal;
