import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import Application from 'services/application/Application';
import api from 'services/api/api';
import authService from 'services/api/authService';
import PublicLayout from 'containers/layouts/PublicLayout';
import PublicLayoutV2 from 'containers/layouts/PublicLayoutV2';
import PatientLayout from 'containers/layouts/PatientLayout';
import WebinarLayout from 'containers/layouts/WebinarLayout';
import SCPPatientLayout from 'containers/layouts/SCPPatientLayout';
import SCPNavigatorLayout from 'containers/layouts/SCPNavigatorLayout';
import SCPEducationLayout from 'containers/layouts/SCPEducationLayout';
import { isFetching, appointmentsLoaded, hasAppointment } from './LayoutProps';
import CheckAuthActionPage from 'containers/pages/CheckAuthActionPage';
import { setConsentSource } from 'services/misc/ReferralSource';

export const WEBINAR_LAYOUT_TYPE = 'webinar';
export const PUBLIC_LAYOUT_TYPE = 'public';
export const PUBLIC_LAYOUT_TYPE_V2 = 'publicV2';
export const SCP_PATIENT_LAYOUT_TYPE = 'scp_patient';
export const SCP_NAVIGATOR_LAYOUT_TYPE = 'scp_navigator';
export const SCP_EDUCATION_LAYOUT_TYPE = 'scp_education';

export class GeneralLayout extends Component {
  componentDidMount() {
    if (this.props.update) this.props.update(this.context.router);
    const consentsource =
      this.props.location && this.props.location.query && this.props.location.query.consentsource;
    if (consentsource) {
      setConsentSource(consentsource);
    }
  }

  /**
   * To prevent blincking while requests are procceding
   */
  shouldComponentUpdate(nextProps) {
    return !nextProps.isFetching;
  }

  get actionToken() {
    return (
      this.props.location && this.props.location.query && this.props.location.query.action_token
    );
  }

  componentDidUpdate() {
    if (this.props.update) this.props.update(this.context.router);
  }

  isLoggedIn() {
    return !!this.props.currentUser && !this.props.holdLoggedIn;
  }

  render() {
    const { isLayoutReady, layoutType, children } = this.props;

    if (this.actionToken) return <CheckAuthActionPage actionToken={this.actionToken} />;
    if (layoutType === SCP_EDUCATION_LAYOUT_TYPE) {
      return <SCPEducationLayout>{children}</SCPEducationLayout>;
    }
    if (layoutType === SCP_NAVIGATOR_LAYOUT_TYPE) {
      return <SCPNavigatorLayout>{children}</SCPNavigatorLayout>;
    }
    if (
      !api.hasToken() ||
      layoutType === PUBLIC_LAYOUT_TYPE ||
      layoutType === PUBLIC_LAYOUT_TYPE_V2
    ) {
      if (layoutType === PUBLIC_LAYOUT_TYPE_V2) {
        return <PublicLayoutV2>{children}</PublicLayoutV2>;
      } else if (!this.isLoggedIn() && layoutType === PUBLIC_LAYOUT_TYPE) {
        return <PublicLayoutV2>{children}</PublicLayoutV2>;
      } else if (layoutType === SCP_PATIENT_LAYOUT_TYPE) {
        return <SCPPatientLayout>{children}</SCPPatientLayout>;
      }
      return <PublicLayout>{children}</PublicLayout>;
    }

    if (isLayoutReady) {
      if (layoutType === WEBINAR_LAYOUT_TYPE) return <WebinarLayout>{children}</WebinarLayout>;
      if (layoutType === SCP_PATIENT_LAYOUT_TYPE)
        return <SCPPatientLayout>{children}</SCPPatientLayout>;
      return <PatientLayout>{children}</PatientLayout>;
    }

    return <div />;
  }
}

GeneralLayout.contextTypes = {
  router: PropTypes.object.isRequired,
};

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired,
  layoutType: PropTypes.string.isRequired,
  isLayoutReady: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  meLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  update: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default (layoutType) => {
  return compose(
    connect((state) => {
      const _currentUser = authService.getCurrentUser(state);
      const _appointmentsLoaded = _currentUser && appointmentsLoaded(state);
      const _hasAppointment = _currentUser && hasAppointment(state);
      const _isWebinarFetched = state.webinar.loaded;
      const _holdLoggedIn = state.user.holdLoggedIn;
      const _isFetching = isFetching(state);

      const _isLayoutReady =
        (_currentUser && !_currentUser.confirmed && (_isWebinarFetched || _hasAppointment)) ||
        _hasAppointment ||
        (_appointmentsLoaded && _isWebinarFetched);

      return {
        layoutType,
        currentUser: _currentUser,
        holdLoggedIn: _holdLoggedIn,
        isLayoutReady: !!_isLayoutReady && !_holdLoggedIn,
        hasAppointment: _hasAppointment,
        isFetching: _isFetching,
        scheduling: state.scheduling,
        update: (router) => Application.for(router, state, layoutType).update(),
      };
    }),
    withRouter
  )(GeneralLayout);
};
