import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Header from 'components/scp/header/NavigatorHeader';
import Footer from 'components/scp/footer/NavigatorFooter';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import ReleaseNotice from 'components/widgets/SCP/ReleaseVersionNotice/ReleaseNotice';

export class SCPNavigatorLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className="main-container main-navigator-container">
        <Header />
        <ReleaseNotice />
        {this.props.children}
        <Footer />
        <GlobalLoader />
      </div>
    );
  }
}

export default SCPNavigatorLayout;
