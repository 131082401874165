import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import './CustomCheckbox.scss';

class CustomCheckbox extends Component {
  handleClick = () => this.props.handleOnClick(this.props.value);

  render() {
    const { isSingle, className, value, checked } = this.props;
    return (
      <div className={classnames('custom-checkbox', className)} onClick={this.handleClick}>
        <div className={classnames('custom-checkbox-rect', isSingle)}>
          {checked && <i className="ok glyphicon glyphicon-ok" />}
        </div>
        <div className="custom-checkbox-label">{value.label}</div>
      </div>
    );
  }
}

CustomCheckbox.propTypes = {
  isSingle: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default CustomCheckbox;
