import React from 'react';
import './SendSetPaswwordError.scss';

const SendSetPaswwordError = () => (
  <section className="eligibility-check-wrapper">
    <div className="">
      <form className="check-resend-link-form" autoComplete="off">
        <div className="form-wrapper">
          <i className="genome-logo" />
          <div>
            <span className="reset-title">Something went wrong</span>
            <div className="reset-desc">
              Please contact us at <a href="tel:+18776880992">(877) 688-0992</a> or send an email at{' '}
              <a href="mailto:clinical@genomemedical.com">clinical@genomemedical.com</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
);

export default SendSetPaswwordError;
