import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import './PrivacyPolicyForm.scss';
import Button from '../../controls/ButtonV2';
import Privacy from 'components/Policy/Privacy';

class PrivacyPolicyForm extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { handleSubmit, onSubmit } = this.props;
    return (
      <section className="privacy-policy-form-container">
        <section className="privacy-wrapper">
          <section className="privacy-header">
            <div className="genome-header-logo">
              <img className="genome-header-img" src={require('images/logo.svg')} />
            </div>
          </section>
          <section className="privacy-body">
            <Privacy />
            <div className="agree-container">
              <p>
                By clicking the <span className="agree-continue">"I Agree and Continue"</span>{' '}
                button below, you confirm you have reviewed and agree to the above{' '}
                <span className="font-medium">Notice of Privacy Practices</span>
              </p>
              <div className="buttons-containers">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="action-buttons">
                    <button onClick={this.props.goBack} className="btn-back">
                      Back
                    </button>
                    <button type="submit" className="btn-next">
                      I Agree & Continue
                    </button>
                  </div>

                  {/* <div className="rights-buttons">
                    <Button type="submit" className="agree-button">
                      I Agree and Continue
                    </Button>
                    <a
                      className="button-back"
                      key="privacy-back"
                      onClick={() => this.props.goBack()}>
                      Go Back
                    </a>
                  </div> */}
                </form>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

PrivacyPolicyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
};

export default reduxForm({
  form: 'privacy-policy-form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PrivacyPolicyForm);
