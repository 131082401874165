import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class DatePicker extends Component {
  handleChange = value => {
    this.props.input.onChange(value ? value.format('YYYY-MM-DD') : null);
  };

  render() {
    const { className, label, meta: { touched, error }, input, disabled } = this.props;
    const value = input.value ? moment(input.value) : null;
    return (
      <div className={classnames('gm-form-field', className, { error: touched && error })}>
        <label className="gm-form-label">{(touched && error) || label}</label>
        <div className={classnames('gm-form-control')}>
          <ReactDatePicker
            selected={value}
            onChange={this.handleChange}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="MM/DD/YYYY"
            placeholderText="MM/DD/YYYY"
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

DatePicker.propTypes = {
  input: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
};

export default DatePicker;
