import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import './Link.scss';

const Link = ({ to, onClick, className, children, blank }) => {
  const classes = classnames('gm-link', className);
  if (!to && !onClick) return <span className={classes}>{children}</span>;

  let anchorProps = { href: to || '#', className: classes };
  if (!to) anchorProps.onClick = onClick;
  if (to && blank) anchorProps.target = '_blank';

  return <a {...anchorProps}>{children}</a>;
};

Link.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  blank: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Link;
