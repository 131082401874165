import api from './api';

export type PartnerSurveyTree = {
  tree_id: string,
  session_id: string,
};

export default class ZingtreeApi {
  getPartnerSurveyTree(partner_id: Number, tree_type: String): Promise<Array<PartnerSurveyTree>> {
    return api.get(`/partners/${partner_id}/scp/survey-tree?tree_type=${tree_type}`);
  }
}
