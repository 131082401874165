import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { getConsultation } from 'ducks/scheduling';
import schedulingService, { GM_HELIX_COUPON_CODE } from 'services/api/schedulingService';
import { formatPrice, getConsultationDuration } from 'services/utils';
import './CouponApplierForm.scss';

class CouponApplierForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isCheckingCouponCode: PropTypes.bool,
    validCoupon: PropTypes.object,
    invalidCoupon: PropTypes.object,
    couponError: PropTypes.object,
    clearCoupon: PropTypes.func.isRequired,
    scheduling: PropTypes.object,
    newPrice: PropTypes.number,
    setCouponCode: PropTypes.func.isRequired,
    code: PropTypes.string,
    appliedCoupon: PropTypes.object,
    insurance: PropTypes.object,
    companies: PropTypes.array,
  };

  componentWillUnmount() {
    if (this.props.couponError) this.props.clearCoupon();
  }

  renderField = ({ input, meta: { touched, error }, isCouponApplied }) => (
    <div
      className={classnames('gm-form-field coupon-field', {
        applied: isCouponApplied,
        error: touched && error,
      })}>
      <div className={classnames('gm-form-control coupon-control')}>
        <input {...input} disabled={isCouponApplied} />
        <i className="fa fa-check" />
      </div>
    </div>
  );

  get isPackage() {
    const { scheduling: { selectedServiceDescriptor } } = this.props;
    return selectedServiceDescriptor && selectedServiceDescriptor.isPackage;
  }

  get caption() {
    const { scheduling: { selectedServiceDescriptor, selectedProduct } } = this.props;
    let caption = '';
    if (this.isPackage && selectedServiceDescriptor) {
      caption = selectedServiceDescriptor.productName;
    } else {
      const consultation = getConsultation(selectedServiceDescriptor, selectedProduct);
      const duration = consultation && getConsultationDuration(consultation);
      if (duration) caption += consultation.name + ', ' + duration + ' minutes';
    }
    return caption;
  }

  get price() {
    const { newPrice, scheduling: { reservedAppointment } } = this.props;
    if (!reservedAppointment) {
      return '';
    }
    if (this.payWithInsurance) {
      return '$' + formatPrice(this.insurancePrice);
    }
    const servicePrice = reservedAppointment.consultation && reservedAppointment.consultation.price;
    return '$' + formatPrice(newPrice != null ? newPrice : servicePrice);
  }

  get payWithInsurance() {
    return !_.isEmpty(this.props.insurance) && schedulingService.getUseInsurance();
  }

  get insurancePrice() {
    const { insurance, companies } = this.props;
    const insuranceCompany = companies.find(c => c.id === insurance.insuranceCompanyId);

    return insuranceCompany ? insuranceCompany.price : '';
  }

  submitCouponCode = data => {
    this.props.setCouponCode(data.code, true);
  };

  render() {
    const { handleSubmit, appliedCoupon, couponError, code, newPrice } = this.props;

    const { isCheckingCouponCode } = this.props.scheduling;
    const helixCouponApplied = appliedCoupon && appliedCoupon.code === GM_HELIX_COUPON_CODE;
    const haveError = !isCheckingCouponCode && couponError && code;
    const isApplied = !isCheckingCouponCode && appliedCoupon && !helixCouponApplied;
    const showPromoCodeForm = !this.payWithInsurance;

    return (
      <div className={classnames('coupon-applier', { applied: isApplied })}>
        <div className="coupon-service-name">{this.caption}</div>
        <div className="coupon-container">
          <div className="total-block">
            <span className="price-label">
              {this.payWithInsurance ? 'Visit deposit:' : 'Visit fee'}{' '}
            </span>
            <span className="price-value">{this.price}</span>
          </div>
          {showPromoCodeForm && (
            <form className="coupon-form" onSubmit={handleSubmit(this.submitCouponCode)} noValidate>
              <div className="coupon-label">Promo code</div>
              <div className="coupon-field-block">
                <Field component={this.renderField} name="code" isCouponApplied={isApplied} />
                {!isApplied && (
                  <button
                    type="submit"
                    className="coupn-apply-btn gm-link"
                    onClick={this.applyClicked}>
                    APPLY
                  </button>
                )}
                {isApplied && (
                  <div className="coupon-applied-block">
                    <div className="coupon-label-applied">Coupon code applied</div>
                    <div className="gm-link undo-coupon" onClick={this.props.clearCoupon}>
                      UNDO
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
        {haveError && (
          <div className="coupon-error">
            {couponError.fullMessage || "Can't apply this coupon code"}
          </div>
        )}
        {isApplied &&
          (newPrice === 0 || (!this.props.insurance && newPrice == null)) && (
            <div className="no-payment">
              <img className="confirm-icon" src={require('./icon-confirm.svg')} /> {' '}
              <span className="no-payment-text">
                No additional payment details required at this time. Please proceed to the next
                step.
              </span>
            </div>
          )}
      </div>
    );
  }
}

const COUPON_INPUT_FORM = 'CouponInputForm';

const form = reduxForm({
  form: COUPON_INPUT_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
});

const selector = formValueSelector(COUPON_INPUT_FORM);

const mapStateToProps = state => {
  let code = (state.scheduling.appliedCoupon && state.scheduling.appliedCoupon.code) || '';
  if (code === GM_HELIX_COUPON_CODE) code = '';

  return {
    scheduling: state.scheduling,
    initialValues: {
      code: code,
    },
    code: selector(state, 'code'),
  };
};

export default connect(mapStateToProps)(form(CouponApplierForm));
