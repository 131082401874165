import _ from 'lodash';
import moment from 'moment';
import {
  finishTheStep,
  SCHEDULE_YOUR_APPOINTMENT_STEP,
  GET_READY_FOR_YOUR_APPOINTMENT_STEP,
  MEET_WITH_YOUR_GENETIC_COUNSELOR,
} from './guideProvider';

// Action types
const FETCH_MY_GUIDE_PROGRESS_START = 'gm/my-guide/review/FETCH_MY_GUIDE_PROGRESS_START';
const FINISH_MY_GUIDE_STEP_1 = 'gm/my-guide/review/FINISH_MY_GUIDE_STEP_1';
const FINISH_MY_GUIDE_STEP_2 = 'gm/my-guide/review/FINISH_MY_GUIDE_STEP_2';
const FINISH_MY_GUIDE_STEP_3 = 'gm/my-guide/review/FINISH_MY_GUIDE_STEP_3';
const FETCH_MY_GUIDE_PROGRESS_FINISH = 'gm/my-guide/review/FETCH_MY_GUIDE_PROGRESS_FINISH';

export default function reducer(state = finishTheStep(), action = {}) {
  switch (action.type) {
    case FETCH_MY_GUIDE_PROGRESS_START:
      return Object.assign({}, state, { fetchingProgress: true });

    case FINISH_MY_GUIDE_STEP_1:
      return Object.assign(
        {},
        state,
        finishTheStep(SCHEDULE_YOUR_APPOINTMENT_STEP, state, action.payload)
      );

    case FINISH_MY_GUIDE_STEP_2:
      return Object.assign(
        {},
        state,
        finishTheStep(GET_READY_FOR_YOUR_APPOINTMENT_STEP, state, action.payload)
      );

    case FINISH_MY_GUIDE_STEP_3:
      return Object.assign(
        {},
        state,
        finishTheStep(MEET_WITH_YOUR_GENETIC_COUNSELOR, state, action.payload)
      );

    case FETCH_MY_GUIDE_PROGRESS_FINISH:
      return Object.assign({}, state, { fetchingProgress: false });

    default:
      return state;
  }
}

export function fetchMyGuideProgressStart() {
  return { type: FETCH_MY_GUIDE_PROGRESS_START };
}

export function finishMyGuideStep1() {
  return { type: FINISH_MY_GUIDE_STEP_1 };
}

export function finishMyGuideStep2() {
  return { type: FINISH_MY_GUIDE_STEP_2 };
}

export function finishMyGuideStep3() {
  return { type: FINISH_MY_GUIDE_STEP_3 };
}

export function fetchMyGuideProgressFinish() {
  return { type: FETCH_MY_GUIDE_PROGRESS_FINISH };
}

export function fetchReviewProgress(appointment) {
  return dispatch => {
    if (_.isEmpty(appointment) || appointment.isMissed) {
      dispatch(fetchMyGuideProgressFinish());
      return;
    }

    dispatch(fetchMyGuideProgressStart());

    if (appointment.id) {
      dispatch(finishMyGuideStep1());
    }

    if (
      appointment.isPast ||
      appointment.isComplete ||
      appointment.isStartSoon ||
      moment()
        .add(15, 'minutes')
        .isAfter(appointment.mStartTime)
    ) {
      dispatch(finishMyGuideStep2());
    }

    if (appointment.isComplete) {
      dispatch(finishMyGuideStep3());
    }

    dispatch(fetchMyGuideProgressFinish());
  };
}
