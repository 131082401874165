import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import get from 'lodash/get';

import './PatientCards.scss';
import verifyEmailIcon from './verify-email.svg';

export default class VerifyEmailSent extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.closeModal();
    }, 10000);
  }

  render() {
    return (
      <Modal bsClass="modal" show={get(this, ['props', 'show'], false)} role="verify-email-sent">
        <img src={verifyEmailIcon} alt="Verify email sent" />
        <div className="title">Email Sent!</div>
        <div className="text" data-hj-suppress>
          We have sent another email verification to <b>{get(this, ['props', 'email'], '')}</b>.
        </div>
        <div className="text with-margin">
          If you do not see it, you may need to check your <b>spam folder</b>.
        </div>
        <a href="javascript:void(0)" onClick={this.props.closeModal}>
          Go back
        </a>
      </Modal>
    );
  }
}
