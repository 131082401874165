import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getConsultation, findCouponItem } from 'ducks/scheduling';
import schedulingService, { GM_HELIX_COUPON_CODE } from 'services/api/schedulingService';
import { formatPrice } from 'services/utils';
import './CouponApplierForm.scss';
import { Input, Button } from 'gm-element-react';

class CouponApplierForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isCheckingCouponCode: PropTypes.bool,
    validCoupon: PropTypes.object,
    invalidCoupon: PropTypes.object,
    couponError: PropTypes.object,
    clearCoupon: PropTypes.func.isRequired,
    scheduling: PropTypes.object,
    newPrice: PropTypes.number,
    setCouponCode: PropTypes.func.isRequired,
    appliedCoupon: PropTypes.object,
    insurance: PropTypes.object,
    companies: PropTypes.array,
  };
  state = {
    ShowPromoCode: false,
    code: '',
  };

  componentWillUnmount() {
    if (this.props.couponError) this.props.clearCoupon();
  }

  renderField = ({ input, meta: { touched, error }, isCouponApplied }) => (
    <div
      className={classnames('gm-form-field coupon-field', {
        applied: isCouponApplied,
        error: touched && error,
      })}>
      <div className={classnames('gm-form-control coupon-control')}>
        <input {...input} disabled={isCouponApplied} />
        <i className="fa fa-check" />
      </div>
    </div>
  );

  get isPackage() {
    const { scheduling: { selectedServiceDescriptor } } = this.props;
    return selectedServiceDescriptor && selectedServiceDescriptor.isPackage;
  }

  get speciality() {
    const { scheduling: { reservedAppointment } } = this.props;
    if (!reservedAppointment) {
      return '';
    }
    return (
      reservedAppointment.vsee_specialty &&
      reservedAppointment.vsee_specialty.replace('_Willing', '').replace('_', ' ')
    );
  }

  get caption() {
    const { scheduling: { selectedServiceDescriptor, selectedProduct } } = this.props;
    let caption = '';
    if (this.isPackage && selectedServiceDescriptor) {
      caption = selectedServiceDescriptor.productName;
    } else {
      const consultation = getConsultation(selectedServiceDescriptor, selectedProduct);
      caption += consultation.name;
    }
    return caption;
  }

  get price() {
    const { newPrice, scheduling: { reservedAppointment } } = this.props;
    if (!reservedAppointment) {
      return '';
    }
    if (this.payWithInsurance) {
      return '$' + formatPrice(this.insurancePrice);
    }
    const servicePrice = reservedAppointment.consultation && reservedAppointment.consultation.price;
    return '$' + formatPrice(newPrice != null ? newPrice : servicePrice);
  }
  get visitPrice() {
    const { scheduling: { reservedAppointment } } = this.props;
    if (!reservedAppointment) {
      return '';
    }
    if (this.payWithInsurance) {
      return '$' + formatPrice(this.insurancePrice);
    }
    const servicePrice = reservedAppointment.consultation && reservedAppointment.consultation.price;
    return '$' + formatPrice(servicePrice);
  }

  get payWithInsurance() {
    return !_.isEmpty(this.props.insurance) && schedulingService.getUseInsurance();
  }

  get insurancePrice() {
    const { insurance, companies } = this.props;
    const insuranceCompany = companies.find(c => c.id === insurance?.primaryInsurance?.insuranceCompanyId);

    return insuranceCompany ? insuranceCompany.price : '';
  }

  submitCouponCode = () => {
    this.props.setCouponCode(this.state.code, true);
  };

  togglePromoCode = value => {
    this.setState({ ShowPromoCode: value });
  };

  get getDiscountValue() {
    const { scheduling, scheduling: { reservedAppointment } } = this.props;
    const coupon = findCouponItem(scheduling);
    let discountValue = coupon.discountValue;
    const consulationPrice = _.get(reservedAppointment, ['consultation', 'price']);
    if (consulationPrice !== undefined) {
      discountValue = discountValue >= consulationPrice ? consulationPrice : discountValue;
    }
    return '-$' + discountValue;
  }

  render() {
    const { appliedCoupon, couponError,readOnlyCouponCode, isMobile } = this.props;

    const { isCheckingCouponCode } = this.props.scheduling;
    const helixCouponApplied = appliedCoupon && appliedCoupon.code === GM_HELIX_COUPON_CODE;
    const haveError = !isCheckingCouponCode && couponError && this.state.code;
    const isApplied = !isCheckingCouponCode && appliedCoupon && !helixCouponApplied;
    const showPromoCodeForm = !this.payWithInsurance && this.state.ShowPromoCode && !isApplied;
    const showPromoCodeText = !this.payWithInsurance && !this.state.ShowPromoCode && !isApplied;
    return (
      <div className={classnames('scp-coupon-applier v2',{
        'ismobile': isMobile
      })}>
        {!isMobile && <div className="code-summary">Summary</div>}

        {!isMobile && <div className="visit-container" data-hj-suppress>
          <div className="coupon-service-name">{this.caption}</div>
          <div className="speciality-name">{this.speciality}</div>
        </div>}

        <div className="coupon-container">
          <div className="total-block">
            <div className="visit-deposit-block">
              <div className="price-label">
                <span className="visit-deposit-label">
                  {' '}
                  {this.payWithInsurance ? 'Visit Deposit' : 'Visit fee'}{' '}
                </span>
                {showPromoCodeText && (
                  <div className="promo-code-label" onClick={() => this.togglePromoCode(true)}>
                    Enter Promo Code{' '}
                  </div>
                )}
              </div>
              <span className={`${isApplied ? 'total-values' : 'price-value'}`}>
                {this.visitPrice}
              </span>
            </div>
          </div>
          {showPromoCodeForm &&
            !isApplied && (
              <Fragment>
                <div className="form-row">
                  <div className="form-col">
                    <div className="label-section required">Promo Code</div>
                    <div className="promo-code-input">
                      <div className={haveError ? 'error' : ' promo-input-text'}>
                        <Input
                          type={'text'}
                          placeholder={'1234 5678 9012 3456'}
                          value={this.state.code}
                          name="code"
                          onChange={value => {
                            this.setState({ code: value });
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              this.submitCouponCode();
                            }
                          }}
                        />
                        <div className="input-error">
                          {haveError
                            ? couponError.fullMessage || "Can't apply this coupon code"
                            : ''}
                        </div>
                      </div>

                      <Button
                        type="button"
                        className="coupn-apply-btn"
                        disabled={_.isEmpty(this.state.code)}
                        onClick={() => this.submitCouponCode()}>
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          <div className="coupon-field-block">
            {isApplied && (
              <div className="coupon-applied-block">
                <div className="coupon-label-applied">
                  <div className="coupon-label-code">{appliedCoupon.code}</div>
                  {
                    !readOnlyCouponCode &&
                    <div className="gm-link undo-coupon" onClick={this.props.clearCoupon}>
                    Remove
                  </div>
                  }

                </div>
                <div className="coupon-applied-code">{this.getDiscountValue}</div>
              </div>
            )}
          </div>
          {isApplied && (
            <div className="total-block pad-bt-24">
              <span className="price-label">Visit Fee Total </span>
              <span className="price-value">{this.price}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let code = (state.scheduling.appliedCoupon && state.scheduling.appliedCoupon.code) || '';
  if (code === GM_HELIX_COUPON_CODE) code = '';

  return {
    scheduling: state.scheduling,
  };
};

export default connect(mapStateToProps)(CouponApplierForm);
