import _ from 'lodash';
import { SubmissionError } from 'redux-form';

function processErrorInternal(error, map) {
  if (error.fields) {
    let result = {};
    for (const key in error.fields) {
      if (map[key]) result[map[key]] = error.fields[key];
      else result[key] = error.fields[key];
    }
    error.submissionError = new SubmissionError(result);
  }
  throw error;
}

export function processCreateAccountError(error, user) {
  if (error.status === 400) {
    if (!error.fields) {
      error.fields = {};
    }
    error.fields['email'] = 'This email has already been used.';
  }
  if (error.status === 422 && error.rawFields) {
    if (_.get(error, 'rawFields.users_guardian')) {
      const usersGuardian = error.rawFields.users_guardian;
      const map = {
        first_name: 'guardianFirstName',
        last_name: 'guardianLastName',
        phone: 'guardianPrimaryPhone',
        relationship: 'guardianRelationship',
        dob: 'guardianDob',
      };
      let result = {};
      for (const key in usersGuardian) {
        if (usersGuardian[key]) {
          if (map[key]) result[map[key]] = _.values(usersGuardian[key]);
          else result[key] == _.values(usersGuardian[key]);
        }
      }
      error.fields = Object.assign({}, error.fields, result);
      delete error.fields.users_guardian;
    }
  }

  if (_.get(error, 'rawFields.phone')) {
    const phoneErrors = error.rawFields.phone;
    const map = !_.get(user, 'isPatient', false)
      ? {
          primary: 'guardianPrimaryPhone',
          secondary: 'guardianSecondaryPhone',
        }
      : {
          primary: 'primaryPhone',
          secondary: 'secondaryPhone',
        };
    let result = {};
    for (const key in phoneErrors) {
      if (phoneErrors[key]) {
        if (map[key]) result[map[key]] = _.values(phoneErrors[key]);
        else result[key] == _.values(phoneErrors[key]);
      }
    }
    error.fields = Object.assign({}, error.fields, result);
    delete error.fields.phone;
  }

  return processErrorInternal(error, {
    first_name: 'firstName',
    last_name: 'lastName',
    email: 'email',
    abn_date: 'abnDate',
    abn_option: 'abnOption',
    abn_signature: 'abnSignature',
  });
}

export function processSetPasswordError(error) {
  return processErrorInternal(error, {
    password: 'password',
    abn_date: 'abnDate',
    abn_option: 'abnOption',
    abn_signature: 'abnSignature',
  });
}

export function processInsuranceError(error) {
  const map ={
    company_name: 'companyName',
    group_no: 'groupNo',
    insurance_company_id: 'insuranceCompanyId',
    is_policyholder: 'isPolicyholder',
    phone: 'phone',
    plan_name: 'planName',
    insurance_no: 'insuranceNo',
    insurance_address: 'insuranceAddress',
    insurance_city: 'insuranceCity',
    insurance_state: 'insuranceState',
    insurance_zipcode: 'insuranceZipCode',
    policyholder_biological_sex: 'policyholderBiolocialSex',
    policyholder_first_name: 'policyholderFirstName',
    policyholder_last_name: 'policyholderLastName',
  };

  if (error.rawFields && error.rawFields.primary) {
    let result = {primaryInsurance:{}};
    const primaryFields =error.rawFields.primary;
    for (const key in primaryFields) {
      if (map[key]) result['primaryInsurance'][map[key]] = _.values(primaryFields[key]);
      else result['primaryInsurance'][key] = _.values(primaryFields[key]);
    }
    error.submissionError = new SubmissionError(result);
    throw error;
  }
  else {
    return processErrorInternal(error, map);
  }

}
export function processSetPaymentError(error) {
  if (error.status === 424 && error.data && error.data.parameter) {
    if (!error.fields) error.fields = {};
    error.fields[error.data.parameter] = error.message;
  }
  return processErrorInternal(error, {
    address_line1: 'billingAddressLine1',
    address_line2: 'billingAddressLine2',
    cvc: 'cardCVC',
    exp_month: 'cardExpirationMonth',
    exp_year: 'cardExpirationYear',
    name: 'cardholderName',
    number: 'cardNumber',
    zip: 'billingAddressZipCode',
    city: 'city',
    state: 'state',
  });
}

export function processSetAddressError(error) {
  if (error.status === 424 && error.data && error.data.parameter) {
    if (!error.fields) error.fields = {};
    error.fields[error.data.parameter] = error.message;
  }
  let errorAttributes = {
    full_name: 'addressFullName',
    type: 'addressType',
    address_line1: 'addressLine1',
    address_line2: 'addressLine2',
    zip: 'addressZipCode',
    city: 'addressCity',
    state: 'addressState',
  };
  if (error.status === 422 && error.rawFields) {
    if (_.get(error, 'rawFields', 'home')) {
      let validationErrors = _.get(error, 'rawFields.home');
      const addressMap = {
        full_name: 'addressFullName',
        type: 'addressType',
        address_line1: 'addressLine1',
        address_line2: 'addressLine2',
        zip: 'addressZipCode',
        city: 'addressCity',
        state: 'addressState',
      };
      let result = {};
      for (const key in validationErrors) {
        if (validationErrors[key]) {
          if (addressMap[key]) result[addressMap[key]] = _.values(validationErrors[key]);
          else result[key] == _.values(validationErrors[key]);
        }
      }
      error.fields = Object.assign({}, error.fields, result);
      delete error.fields['home'];
    }
    if (_.get(error, 'rawFields', 'home-delivery')) {
      let validationErrors = _.get(error, 'rawFields.home-delivery');
      const addressMap = {
        full_name: 'addressFullName',
        type: 'addressType',
        address_line1: 'addressLine1',
        address_line2: 'addressLine2',
        zip: 'addressZipCode',
        city: 'addressCity',
        state: 'addressState',
      };
      let result = {};
      for (const key in validationErrors) {
        if (validationErrors[key]) {
          if (addressMap[key]) result[addressMap[key]] = _.values(validationErrors[key]);
          else result[key] == _.values(validationErrors[key]);
        }
      }
      error.fields = Object.assign({}, error.fields, result);
      delete error.fields['home-delivery'];
    }
    if (_.get(error, 'rawFields', 'home-delivery-billing')) {
      let validationErrors = _.get(error, 'rawFields.home-delivery-billing');
      const addressMap = {
        full_name: 'addressFullName',
        type: 'addressType',
        address_line1: 'addressLine1',
        address_line2: 'addressLine2',
        zip: 'addressZipCode',
        city: 'addressCity',
        state: 'addressState',
      };
      let result = {};
      for (const key in validationErrors) {
        if (validationErrors[key]) {
          if (addressMap[key]) result[addressMap[key]] = _.values(validationErrors[key]);
          else result[key] == _.values(validationErrors[key]);
        }
      }
      error.fields = Object.assign({}, error.fields, result);
      delete error.fields['home-delivery-billing'];
    }
    if (_.get(error, 'rawFields', 'home-billing')) {
      let validationErrors = _.get(error, 'rawFields.home-billing');
      const addressMap = {
        full_name: 'addressFullName',
        type: 'addressType',
        address_line1: 'addressLine1',
        address_line2: 'addressLine2',
        zip: 'addressZipCode',
        city: 'addressCity',
        state: 'addressState',
      };
      let result = {};
      for (const key in validationErrors) {
        if (validationErrors[key]) {
          if (addressMap[key]) result[addressMap[key]] = _.values(validationErrors[key]);
          else result[key] == _.values(validationErrors[key]);
        }
      }
      error.fields = Object.assign({}, error.fields, result);
      delete error.fields['home-billing'];
    }
    if (_.get(error, 'rawFields.delivery')) {
      let validationErrors = _.get(error, 'rawFields.delivery');
      const addressMap = {
        full_name: 'shippingAddressFullName',
        type: 'shippingAddressType',
        address_line1: 'shippingAddressLine1',
        address_line2: 'shippingAddressLine2',
        zip: 'shippingAddressZipCode',
        city: 'shippingAddressCity',
        state: 'shippingAddressState',
      };
      let result = {};
      for (const key in validationErrors) {
        if (validationErrors[key]) {
          if (addressMap[key]) result[addressMap[key]] = _.values(validationErrors[key]);
          else result[key] == _.values(validationErrors[key]);
        }
      }
      error.fields = Object.assign({}, error.fields, result);
      delete error.fields.delivery;
    }
    if (_.get(error, 'rawFields.billing')) {
      let validationErrors = _.get(error, 'rawFields.billing');
      const addressMap = {
        full_name: 'addressFullName',
        type: 'addressType',
        address_line1: 'billingAddressLine1',
        address_line2: 'billingAddressLine2',
        zip: 'billingAddressZipCode',
        city: 'city',
        state: 'state',
      };
      let result = {};
      for (const key in validationErrors) {
        if (validationErrors[key]) {
          if (addressMap[key]) result[addressMap[key]] = _.values(validationErrors[key]);
          else result[key] == _.values(validationErrors[key]);
        }
      }
      error.fields = Object.assign({}, error.fields, result);
      delete error.fields.billing;
      errorAttributes['city'] = 'city';
      errorAttributes['state'] = 'state';
    }
  }
  return processErrorInternal(error, errorAttributes);
}

export function processBookingError(error) {
  if (error.status === 404)
    error.fullMessage =
      'We’re sorry — the genetics expert you requested isn’t available at that time. Please select another time for your appointment.';
  if (error.status === 409)
    error.fullMessage =
      'We’re sorry — Another patient has reserved this time slot. (Our system holds a selected appointment time for a 20-minute period)';
  throw error;
}

export function processLoginError(error) {
  if (error.status === 401) {
    if (!error.fields) error.fields = {};
    error.fields['email'] = 'Invalid email or password';
  }
  return processErrorInternal(error, {
    email: 'email',
  });
}

export function processSoftLoginError(error) {
  return processErrorInternal(error, {
    email: 'email',
  });
}

export function processSoftLoginVerifyOTPError(error) {
  const { status } = error;

  if (status === 401 || status === 403 || status === 404) {
    if (!error.fields) {
      error.fields = {};
      error.fields['code'] = error.message;
    }
  } else {
    if (!error.fields) {
      error.fields = {};
      error.fields['code'] = 'Something went wrong';
    }
  }

  return processErrorInternal(error, {
    code: 'code',
  });
}

export function processUpdateProfileError(error, user) {
  if (error.status === 422 && error.rawFields) {
    if (_.get(error, 'rawFields.phone')) {
      const phoneErrors = error.rawFields.phone;
      const map = _.get(user, 'hasGuardian', false)
        ? {
            primary: 'guardianPrimaryPhone',
            secondary: 'guardianSecondaryPhone',
          }
        : {
            primary: 'primaryPhone',
            secondary: 'secondaryPhone',
          };
      let result = {};
      for (const key in phoneErrors) {
        if (phoneErrors[key]) {
          if (map[key]) result[map[key]] = _.values(phoneErrors[key]);
          else result[key] == _.values(phoneErrors[key]);
        }
      }
      error.fields = Object.assign({}, error.fields, result);
      delete error.fields.phone;
    }
  }
  return processErrorInternal(error, {
    first_name: 'firstName',
    last_name: 'lastName',
    dob: 'dob',
  });
}

export function processUpdateUserConsentError(error) {
  return processErrorInternal(error, {
    abn_date: 'abnDate',
    abn_signature: 'abnSignature',
  });
}

export function processChangePasswordError(error) {
  if (error.status === 403 && error.code === 'invalid_old_password') {
    if (!error.fields) error.fields = {};
    error.fields['old_password'] = 'Current password is invalid';
  }
  return processErrorInternal(error, {
    old_password: 'oldPassword',
    new_password: 'password',
  });
}

export function processForgotPasswordError(error) {
  if (error.code === 'account_not_confirmed')
    error.fullMessage = 'You need to confirm your account before resetting the password';
  return processErrorInternal(error, {
    email: 'email',
  });
}

export function processPostWebinarActionError(error) {
  if (error.fields && error.fields.post_action_feedback)
    error.fields['post_action_feedback'] = 'The field contains invalid characters.';
  return processErrorInternal(error, {
    post_action_feedback: 'extra',
  });
}

export function processRegisterWebinarError(error) {
  if (error.status === 422 && error.rawFields) {
    if (error.rawFields.payment_info) {
      error.fields = { ...error.rawFields, ...error.rawFields.payment_info };
      delete error.fields.payment_info;
    }
    error.fields = _.mapValues(error.fields, fieldError => Object.values(fieldError));
  }
  if (error.status === 424) {
    let field = 'number';
    if (error.data && error.data.parameter) field = error.data.parameter;
    error.fields = { [field]: error.message || 'Payment failed' };
  }
  return processErrorInternal(error, {
    email: 'email',
    first_name: 'first_name',
    last_name: 'last_name',
    gender: 'gender',
    dob: 'dob',
    number: 'number',
    cvc: 'cvc',
    name: 'name',
    exp_month: 'exp_month',
    exp_year: 'exp_year',
    address_line1: 'address_line1',
    address_line2: 'address_line2',
    state: 'state',
    city: 'city',
    zip: 'zip',
  });
}

export function processEligibilityError(error) {
  if (error.code === 'eligibility_verification_failed') {
    if (error.rawFields && error.rawFields.employeeId && error.rawFields.employeeId.not_exists) {
      error.fields = {
        ...(error.fields || {}),
        employeeId:
          'Your employee ID was not recognized. Please verify your employee ID and try again. If you need additional assistance please contact us.',
      };
    }
  }
  throw error;
}

export function processSelectionError(error) {
  if (error.code === 'invalid_selection') {
    if (error.rawFields) {
      error.fields = {
        ...(error.fields || {}),
      };
    }
  }
  throw error;
}

export function processCreateSoftAccountError(error) {
  if (error && error.status === 422 && error.code == 'invalid_request') {
    if (!error.fields) {
      error.fields = {};
    } else if (_.get(error, 'rawFields.email.unique', false)) {
      error.fields['email'] = 'This email has already been used';
      error.fields['alreadyExists'] = true;
    }
  }

  return processErrorInternal(error, {
    first_name: 'firstName',
    last_name: 'lastName',
    email: 'email',
    alreadyExists: 'alreadyExists',
  });
}

export function processEmailVerifyError(error) {
  if (error && error.status === 403 && error.code === 'incorrect_code') {
    if (!error.fields) {
      error.fields = {};
    }
    error.fields['code'] = 'Incorrect code. Click “resend the code’ link above to get a new code';
  }
  if (error && error.status === 403 && error.code === 'exceeded_tries') {
    if (!error.fields) {
      error.fields = {};
    }
    error.fields['code'] = error.message;
  }
  if (error && error.status === 403 && error.code === 'code_expired') {
    if (!error.fields) {
      error.fields = {};
    }
    error.fields['code'] = error.message;
  }
  if (error && error.status === 404 && error.code === 'not_found') {
    if (!error.fields) {
      error.fields = {};
    }
    error.fields['code'] = error.message;
  }

  return processErrorInternal(error, {
    code: 'code',
  });
}

export function processSetSCPOrderConsentError(error) {
  if (error.status === 424 && error.data && error.data.parameter) {
    if (!error.fields) error.fields = {};
    error.fields[error.data.parameter] = error.message;
  }
  return processErrorInternal(error, {
    scp_order_consent: 'scpOrderConsent',
  });
}

export function processSetMyParterError(error) {
  return processErrorInternal(error, {});
}

export function processGrailGalleriConsent(error) {
  if (_.get(error, ['rawFields', 'delivery'], null)) {
    const delivery = error.rawFields.delivery;
    const map = {
      full_name: 'addressFullName',
      type: 'addressType',
      address_line1: 'addressLine1',
      address_line2: 'addressLine2',
      city: 'addressCity',
      state: 'addressState',
      zip: 'addressZipCode',
      user_addresses_id: 'addressId',
    };
    let result = {};
    for (const key in delivery) {
      if (delivery[key]) {
        if (map[key]) result[map[key]] = _.values(delivery[key]);
        else result[key] == _.values(delivery[key]);
      }
    }
    error.fields = Object.assign({}, error.fields, result);
    delete error.fields.delivery;
  }

  return processErrorInternal(error, {});
}


export function processGeneTestOrderError(error) {
  if (error.status === 422 && error.rawFields) {
    const addressMap = {
      address_line1: 'addressLine1',
      address_line2: 'addressLine2',
      zip: 'addressZipCode',
      city: 'addressCity',
      state: 'addressState'
    };
    const shippingAddressMap = {
      address_line1: 'shippingAddressLine1',
      address_line2: 'shippingAddressLine2',
      zip: 'shippingAddressZipCode',
      city: 'shippingAddressCity',
      state: 'shippingAddressState'
    };
    const patientMap = {
        first_name: 'firstName',
        last_name: 'lastName',
        email: 'email',
        phones: 'primaryPhone',
        dob: 'dob',
        sex_assigned_at_birth: 'sexAssignedAtBirth',
    }
    const insurnaceMap = {
      insurance_company_id: 'insuranceCompanyId',
      company_name: 'companyName',
      insurance_no: 'insuranceNo',
      group_no: 'groupNo',
      is_policyholder: 'isPolicyholder',
      policyholder_biological_sex: 'policyholderBiolocialSex',
      policyholder_relationship: 'policyholderRelationship',
      policyholder_dob: 'policyholderDob',
      policyholder_first_name: 'policyholderFirstName',
      policyholder_last_name: 'policyholderLastName'
    };

    const useMapObj = {
      patient:patientMap,
      insurance:insurnaceMap,
      delivery:shippingAddressMap,
      home:addressMap,
      'home-delivery':addressMap
    }

    const ErrorObjNames=['patient','insurance','delivery','home','home-delivery'];

    for(const errName of ErrorObjNames){
      let rawfieldsErrorObj =_.get(error,['rawFields',errName]);
      if(errName == 'insurance')
        rawfieldsErrorObj= _.get(error,['rawFields','insurnace','primary-lab'],{});
      else if(errName == 'delivery' || errName == 'home' || errName == 'home-delivery')
        rawfieldsErrorObj= _.get(error,['rawFields','user_address',errName],{});
      if(rawfieldsErrorObj && !_.isEmpty(rawfieldsErrorObj)){
        const mapObj= useMapObj[errName];
        let result = formatgeneTestErrors(mapObj,rawfieldsErrorObj);  
        delete error.fields[errName];
        if(errName == 'home-delivery'){
          error.fields = Object.assign({}, error.fields, {
            'home':result, 'delivery':result});
        }
        else
          error.fields = Object.assign({}, error.fields, {[errName]:result});
        
      }
    }
  }
  return error;
}

export function formatgeneTestErrors (mappingKeys,error){
  let result ={};
  for(const key in error) {
    const value = error[key];
    if(mappingKeys[key])
      result[mappingKeys[key]] = _.isObject(value) ? _.values(value)[0] : value;
    else 
    result[key] = _.isObject(value) ? _.values(value)[0] : value;
  }
  return result;
}