import storage, { SessionStore } from 'services/storage';

const GM_TEST_REQUEST_DATA = 'GM_TEST_REQUEST_DATA';
const GM_GENETIC_TEST_UUID = 'GM_GENETIC_TEST_UUID';
const GM_TEST_REQUEST_LAB_UUID = 'GM_TEST_REQUEST_LAB_UUID';
const GM_GENETIC_TEST_OBJECT = 'GM_GENETIC_TEST_OBJECT';


class TestRequestService {
  getTestRequestData() {
    return SessionStore.get(GM_TEST_REQUEST_DATA);
  }

  saveTestRequestData(obj) {
    SessionStore.set(GM_TEST_REQUEST_DATA, obj);
  }

  clearTestRequestData() {
    SessionStore.remove(GM_TEST_REQUEST_DATA);
  }

  getGeneticTestUUID() {
    return SessionStore.get(GM_GENETIC_TEST_UUID);
  }

  saveGeneticTestUUID(obj) {
    SessionStore.set(GM_GENETIC_TEST_UUID, obj);
  }

  clearGeneticTestUUID() {
    SessionStore.remove(GM_GENETIC_TEST_UUID);
  }

  getTestRequestLabUUID() {
    return SessionStore.get(GM_TEST_REQUEST_LAB_UUID);
  }

  saveTestRequestLabUUID(obj) {
    SessionStore.set(GM_TEST_REQUEST_LAB_UUID, obj);
  }

  clearTestRequestLabUUID() {
    SessionStore.remove(GM_TEST_REQUEST_LAB_UUID);
  }

  getGeneticTestDetail() {
    return SessionStore.get(GM_GENETIC_TEST_OBJECT);
  }

  saveGeneticTestDetai(obj) {
    SessionStore.set(GM_GENETIC_TEST_OBJECT, obj);
  }

  clearGeneticTestDetai() {
    SessionStore.remove(GM_GENETIC_TEST_OBJECT);
  }


}

export default new TestRequestService();
