import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { reduxForm, setSubmitFailed, stopSubmit } from 'redux-form';
import Alert from 'components/widgets/Alert/Alert';

import CreditCardDetail from './CreditCardDetail';
import BillingAddress from './BillingAddress';
import './PaymentInfoForm.scss';
import { HOME } from '../../../../services/utils';
import { hydrateHomeAddressFields } from '../../../../services/api/transformers/addressTransformers';


export const PAYMENT_FORM_NAME = 'payment-information-form';

class PaymentForm extends Component {
  state = {
    initialState: {},
    validateCreditCard: false, 
    stripe: undefined,
    elements: undefined
  };
  componentDidMount(){
    const { initialValues, getStripeIntent,email,errors,dispatch } = this.props;
    if (initialValues) {
      this.setState(
        {
          initialState: initialValues,
        });
    }
    getStripeIntent({email});
    if(errors && errors.home){
      dispatch(stopSubmit(PAYMENT_FORM_NAME,errors.home));
      dispatch(setSubmitFailed(PAYMENT_FORM_NAME,Object.keys(errors.home)));
    }
  }

  handleComponentState = (label, value) => {
    this.setState({ [label]: value })
  }

  onFormSubmit = async data => {
    const {elements,stripe} =this.state;
    const addresses= this.setAddress();
    const {onSubmit} =this.props;
    if(stripe){
      stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
            payment_method_data: {
          }
        }
      }).then(function (result) {
        if (!result.error) {
          onSubmit(addresses);
        }
      });
    }
  };

  setAddress = () => {
    const { allAddress, user } = this.props;
    let newAddress = [...allAddress];
      let homeAddress = hydrateHomeAddressFields({
        ...this.state.initialState,
        fullName: _.get(user,['fullName'], ''), 
      });

      const oldHomeAddressIndex = newAddress.findIndex(
        el => el.addressType && el.addressType.includes(HOME)
      );
      if (oldHomeAddressIndex > -1) {
        const oldAddressObj = newAddress[oldHomeAddressIndex];
        let oldType = [..._.get(oldAddressObj, 'addressType', [])];
        if (oldType.length > 1) {
          let homeIndex = oldType.indexOf(HOME);
        if (homeIndex > -1) {
          oldType.splice(homeIndex, 1);
          newAddress[oldHomeAddressIndex] = { ...oldAddressObj, addressType: oldType };
          newAddress.push({ ...homeAddress, addressType: [HOME],addressId:undefined })
        }
        }
        else {
          newAddress[oldHomeAddressIndex] = {
            ...homeAddress,
            addressType: [HOME]
          }
        }
      }
      else {
        newAddress.push({ ...homeAddress, addressType: [HOME],addressId:undefined });
      }
      return newAddress;
  };

  handleChange = (value, key) => {
    this.setState(
      { initialState: Object.assign({}, this.state.initialState, { [key]: value }) }
    );
  };

  btDisabled = (submitting, valid) => {
    return submitting || !valid || !this.state.validateCreditCard
  }

  render() {
    const { isMobile,stripeIntent,stripeIntentLoading,countryIsNonUS,allowedCountries,testDetail } = this.props;
    const clientSecret = _.get(stripeIntent, ['secret']);
    let testPrice = _.get(testDetail,['price'],'');
    if(testPrice) {
      testPrice = parseInt(testPrice);
    }

    const {
      submitting,
      handleSubmit,
      toPreviousStep,
      formError,
      valid,
      CountriesStateEnums: { countries,states }
    } = this.props;
    return (
      <Fragment>
        <div className="scp-schedule-wizard test-request-payment-stage">
          <h2 className="gm-select-service__header">Enter Payment Information</h2>
          <form
            className="scp-payment-personal-form"
            onSubmit={handleSubmit(this.onFormSubmit)}>
            <div className="payment-sections">
              <div className="payment-section">

                <Fragment>
                  <Alert message={formError} />
                  {!stripeIntentLoading &&
                  <CreditCardDetail
                    clientSecret={clientSecret}
                    loading={false}
                    handleChange={this.handleChange}
                    handleComponentState={this.handleComponentState}
                    isMobile={isMobile}
                    billingDetails={{
                        country:this.props.initialValues.addressCountry,
                        postal_code:
                          this.props.initialValues.addressZipCode
                    }}
                  />
  }
                  <BillingAddress
                    handleChange={this.handleChange}
                    states={states}
                    countries={countries}
                    allowedCountries={allowedCountries}
                    initialState={this.state.initialState}
                    countryIsNonUS={countryIsNonUS}
                  />
                </Fragment>
              </div>
              <div>
                <div className="test-authorization-section">
                  <div className="code-summary">Summary</div>
                  <div className="test-authorization-header">Test Authorization Fee</div>
                  <div className='test-authorization-detail-section'>
                    <div className='test-authorization-description'>
                      This is an administrative fee that does not cover the cost of your test. Your test fee may vary depending on your insurance coverage.
                    </div>
                    <div className='test-authorization-fee'>
                      {testPrice && `$${testPrice}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-buttons">
              {toPreviousStep && (
                <button
                  onClick={toPreviousStep}
                  className="btn-back">
                  Back
                </button>
              )}
              <button
                disabled={this.btDisabled(submitting, valid)}
                type="submit"
                className="btn-next">
                Next
              </button>

            </div>

          </form>
        </div>
      </Fragment>
    );
  }
}

PaymentForm.propTypes = {
  formError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  toPreviousStep: PropTypes.func.isRequired,
};

export default reduxForm({
  form: PAYMENT_FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(PaymentForm);
