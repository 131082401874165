import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import appointmentsService from 'services/api/appointmentsService';
import Alert from 'components/widgets/Alert/Alert';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import StatusMessages from 'constants/StatusMessages';
import { loaderSession } from 'ducks/ui';
import api from 'services/api/api';
import { addAction, REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';
import gaTrack, { GA_TR_APPOINTMENT_WAITING_ROOM_PAGE } from 'services/gaTrack';

import './WaitingRoom.scss';

class WaitingRoom extends Component {
  state = { statusMessage: null };

  componentDidMount() {
    gaTrack(GA_TR_APPOINTMENT_WAITING_ROOM_PAGE);
    if (api.hasToken()) {
      this.gotoWaitingRoom();
    } else {
      const url = `/appointments/${this.props.params.appointmentId}/waiting-room`;
      this.props.dispatch(addAction(REDIRECT_AFTER_LOGIN, url));
      browserHistory.push('/login');
    }
  }

  showError = msg => {
    this.setState({ statusMessage: msg });
  };

  hideErrorAndGoHome = () => {
    this.setState({ statusMessage: null });
    browserHistory.push('/patient');
  };

  gotoWaitingRoom() {
    loaderSession(
      this.props.dispatch,
      appointmentsService
        .goToWaitingRoom(+this.props.params.appointmentId)
        .then(url => {
          window.location = url;
        })
        .catch(() => this.showError(StatusMessages.wrongWaitingRoom))
    );
  }

  render() {
    if (!api.hasToken()) return null;
    return (
      <div className="scp-waiting-room-status">
        <Alert
          type="danger"
          message={this.state.statusMessage}
          fade={true}
          onClose={this.hideErrorAndGoHome}
        />
        <GlobalLoader />
      </div>
    );
  }
}

WaitingRoom.propTypes = {
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

export default connect()(WaitingRoom);
