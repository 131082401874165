import _ from 'lodash';

export default class Context {
  constructor(router: Object, state: Object, layoutType: Object = null) {
    this.getRouter = () => router;
    this.getState = () => state;
    this.getLayoutType = () => layoutType;
    this.getPath = () => _(router.routes).findLast(r => !!r.path);
    this.getParams = () => ({ router, state, layoutType });
  }
}
