import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import authService from 'services/api/authService';
import PageTitle from 'components/widgets/PageTitle/PageTitle';
import ProgressTrack from 'components/widgets/ProgressTrack/ProgressTrack';
import ViewWebinar from './steps/ViewWebinar';
import PostWebinarOptions from './steps/PostWebinarOptions/PostWebinarOptions';
import YourNextSteps from './steps/YourNextSteps';
import {
  REGISTER_FOR_THE_PROGRAM_STEP,
  VIEW_WEBINAR_STEP,
  POST_WEBINAR_OPTIONS_STEP,
  YOUR_NEXT_STEPS_STEP,
  SCHEDULE_FOLLOW_UP_STEP,
} from 'ducks/webinar';
import { POST_WEBINAR_NO_TESTING, POST_WEBINAR_TESTING } from 'services/api/webinarService';
import './WebinarGuidePage.scss';

const stepTitles = {
  [REGISTER_FOR_THE_PROGRAM_STEP]: 'Register for the employee program',
  [VIEW_WEBINAR_STEP]: 'View Webinar',
  [POST_WEBINAR_OPTIONS_STEP]: 'Post-Webinar Options',
  [YOUR_NEXT_STEPS_STEP]: 'Your Next Steps',
};

const modifiedStepTitles = {
  ...stepTitles,
  [YOUR_NEXT_STEPS_STEP]: 'Your Next Step',
  [SCHEDULE_FOLLOW_UP_STEP]: 'Schedule Follow Up',
};

const stepComponents = {
  [REGISTER_FOR_THE_PROGRAM_STEP]: null,
  [VIEW_WEBINAR_STEP]: ViewWebinar,
  [POST_WEBINAR_OPTIONS_STEP]: PostWebinarOptions,
  [YOUR_NEXT_STEPS_STEP]: YourNextSteps,
  [SCHEDULE_FOLLOW_UP_STEP]: YourNextSteps,
};

class WebinarGuidePage extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    currentWebinar: PropTypes.object,
    webinarGuide: PropTypes.object,
    fetchProgress: PropTypes.func,
    showWebinarGuide: PropTypes.bool,
    extraWebinarStep: PropTypes.bool,
  };

  static hydrateSteps = (steps, activeStepId, modified = false) => {
    return steps.map(step => ({
      id: step.id,
      title: modified ? modifiedStepTitles[step.id] : stepTitles[step.id],
      done: step.done,
      active: step.id === activeStepId,
    }));
  };

  shouldComponentUpdate(nextProps) {
    return !nextProps.webinar || !nextProps.webinar.guide.fetchingProgress;
  }

  componentDidUpdate() {
    this.checkNoTestingRedirect();
  }

  checkNoTestingRedirect = () => {
    const { currentWebinar } = this.props;
    if (currentWebinar && currentWebinar.postAction === POST_WEBINAR_NO_TESTING)
      browserHistory.push('/patient');
  };

  render() {
    const { currentUser, showWebinarGuide, webinarGuide, extraWebinarStep } = this.props;
    const title =
      'Welcome back' + (currentUser && currentUser.firstName ? `, ${currentUser.firstName}.` : '.');
    const steps = webinarGuide.steps;
    const activeStepId = webinarGuide.activeStepId;
    const stepComponent = activeStepId && stepComponents[activeStepId];

    return (
      <div className="webinar-guide-page full-height">
        {showWebinarGuide && (
          <div data-hj-suppress>
            <PageTitle title={title}>
              Use this roadmap to keep track of your progress toward discovering the health-related
              insights in your genome.
            </PageTitle>
            <ProgressTrack
              steps={WebinarGuidePage.hydrateSteps(steps, activeStepId, extraWebinarStep)}
            />
            {stepComponent && React.createElement(stepComponent)}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: authService.getCurrentUser(state),
  webinarGuide: state.webinar.guide,
  extraWebinarStep:
    state.webinar.data &&
    (state.webinar.data.postAction === POST_WEBINAR_TESTING ||
      state.webinar.data.testResultsAvailable),
  showWebinarGuide: !!(
    state.webinar.data && state.webinar.data.postAction !== POST_WEBINAR_NO_TESTING
  ),
});

export default connect(mapStateToProps)(WebinarGuidePage);
