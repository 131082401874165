import usersService from 'services/api/usersService';

const LOAD = 'gm/me/outreachAppointment/LOAD';
const LOAD_COMPLETE = 'gm/me/outreachAppointment/LOAD_COMPLETE';
const SET_ME_OUTREACH = 'gm/me/outreachAppointment/SET_ME_OUTREACH';

const initialState = {
  loading: false,
  loaded: false,
  data: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case LOAD_COMPLETE:
      return {
        ...state,
        loading: false,
      };

    case SET_ME_OUTREACH:
      return {
        ...initialState,
        data: action.payload,
        loaded: true,
      };

    default:
      return state;
  }
}

export function startLoading() {
  return { type: LOAD };
}

export function setOutreach(outreach) {
  return { type: SET_ME_OUTREACH, payload: outreach };
}

export function loadMeOutreach() {
  return dispatch => {
    dispatch(startLoading());
    return usersService.getMeOutreach().then(outreach => {
      return dispatch(setOutreach(outreach));
    });
  };
}
