import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory, withRouter, Link } from 'react-router';
import './LoginPage.scss';
import { connect } from 'react-redux';
import { login } from 'ducks/auth/email';
import api from 'services/api/api';
import authService from 'services/api/authService';
import track, { TR_USER_LOGIN, TR_USER_VISIT_LOGIN_PAGE } from 'services/track';
import LoginForm from './LoginForm';
import gaTrack, { GA_TR_USER_LOGIN_PAGE } from '../../../../services/gaTrack';
import { addAction, REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';

class LoginPage extends Component {
  componentDidMount() {
    if (api.hasToken()) {
      browserHistory.push('/patient/home');
    }
    if (this.switchToSCPFlow) {
      const { dispatch } = this.props;
      dispatch(addAction(REDIRECT_AFTER_LOGIN, '/welcome'));
    }

    track(TR_USER_VISIT_LOGIN_PAGE);
    gaTrack(GA_TR_USER_LOGIN_PAGE);
  }

  get initialValues() {
    if (this.props.router.location.state) {
      return this.props.router.location.state.query;
    }

    return {};
  }

  get switchToSCPFlow() {
    const obj = authService.getSCPPreRegistrationData();
    return obj && obj.switchToScp && obj.switchToScp == true;
  }
  get isforgetLinkShow() {
    return !this.switchToSCPFlow;
  }

  onLogin = (data) => {
    track(TR_USER_LOGIN, { email: data['email'] });
    return this.props.dispatch(login(data));
  };

  render() {
    return (
      <div className="login-page">
        <div className="form-wrapper">
          <i className="genome-logo" />
          <span className="login-title">Patient Sign-in</span>
          <div className="login-desc">Access your Genome Medical account by signing in below</div>
          <LoginForm
            onSubmit={this.onLogin}
            initialValues={this.initialValues}
            isforgetLinkShow={this.isforgetLinkShow}
          />
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

export default connect()(withRouter(LoginPage));
