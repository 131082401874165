
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import webinarService from 'services/api/webinarService';
import ScheduleIntroImage from './schedule-intro-image.svg';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import fetchHOC from 'components/high-order-components/FetchHOC';
import { showLoader, closeLoader } from 'ducks/ui';
import './SchedulingIntroPage.scss'
import { getReferralPartnerName } from '../../../../services/misc/ReferralSource';

const WITH_PAYMENT = "with_payment_option";
const WITHOUT_PAYMENT = "without_payment_option"

class SchedulingIntroPage extends Component {
  state = {
    showPaymentStep: WITH_PAYMENT,
    loading:false
  }

  componentDidMount() {
    const {partnerName} =this.props;
    const partner = partnerName || getReferralPartnerName()||'DTC_Proactive';
    if(partner){
      this.setState({loading:true});
      this.props.showLoader();
    this.props.partners.fetch().then(() => {
      const { partners } = this.props;
      const partnersData = _.get(partners, 'data', []);
      let knownPartner = partnersData.find(a => a.name == partner);
      if(knownPartner?.introPageVersion == 'na'){
        this.props.closeLoader();
        this.props.setActiveStage();
      }
      this.setState({ showPaymentStep: knownPartner?.introPageVersion,loading:false });
        this.props.closeLoader();
    })
    .catch(()=>{
      this.setState({loading:false});
      this.props.closeLoader();
    })
  }
  }
  render() {
    return this.state.loading?<div />:(
      <Fragment>
        <div className="schedule-intro-page">
          <div className="intro-container">
            <div className="Text-container">
              <div className="heading">Scheduling with Genome Medical</div>
              <div className="description">
                <p>Genome Medical is the nation’s largest telehealth genetics medical practice.</p>
                <br />
                <p>
                  We understand that initiating care with a new practice can be overwhelming. We’re here to help you get the genetics care you need.</p>
              </div>
              <div className="subheading">
                So here’s what to expect
              </div>
              <div className="ul-content">
                <div className="li-section">
                  <div className="steps">
                    1
                  </div>
                  <div>
                    <div className="label-step">Create your account</div>
                    <div className="sub-label">so you can schedule in our system</div>
                  </div>
                </div>
                <div className="li-section">
                  <div className="steps">
                    2
                  </div>
                  <div>
                    <div className="label-step">Complete consent paperwork </div>
                    <div className="sub-label">to help us to provide tailored quality care </div>
                  </div>
                </div>
                <div className="li-section">
                  <div className="steps">
                    3
                  </div>
                  <div>
                    <div className="label-step">Schedule your appointment</div>
                    <div className="sub-label">at a time that works for you  </div>
                  </div>
                </div>
                {this.state.showPaymentStep != WITHOUT_PAYMENT &&
                  <div className="li-section">
                    <div className="steps">
                      4
                    </div>
                    <div>
                      <div className="label-step">Review your payment options</div>
                      <div className="sub-label">For your genetic counseling visit</div>
                    </div>
                  </div>
                }


              </div>

              <div>
                <ButtonV2
                  onClick={this.props.setActiveStage}
                >
                  Get Started
                </ButtonV2>
              </div>
              <hr className="divider" />
              <div className="intro-footer">
                <div className="title-question"> Questions?</div>
                <div className="description-question">Call us at <span className="phone">
                  <a href="tel:+18776880992">(877) 688-0992</a>
                </span>{' '} or email us at <a href="mailto: clinical@genomemedical.com">
                    clinical@genomemedical.com
                  </a></div>
              </div>
            </div>
            <div className="image-container">
              <img src={ScheduleIntroImage} />
            </div>
          </div>
        </div>

      </Fragment>
    );
  }
}


export default connect(
  null,
  dispatch => ({
    showLoader: () => dispatch(showLoader()),
    closeLoader: () => dispatch(closeLoader()),

  })
)(
  fetchHOC({
    partners: () => webinarService.getPartners(),
  })(SchedulingIntroPage)
);
