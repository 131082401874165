import _ from 'lodash';
import React, { Fragment } from 'react';
import classnames from 'classnames';
import './ConfirmationPage.scss';
import NateraImg from './natera_desktop.svg';
import NateraMobImg from './natera_mobile.svg';
import gmiLogo from '../../../../images/minimized_logo.png';

const ConfirmationPage = (props) => {
  const { isMobile } = props;

  return (
    <div>
      <div className="scp-mob-schedule-breadcrumb">
        <div className={classnames('m-breadcrumb-content', 'm-scheduling-breadcrumb-content')}>
          <Fragment>
            <div className="logo">
              <img src={gmiLogo} className="header-logo" />
            </div>
          </Fragment>
        </div>
      </div>
      <div className={classnames('test-request-wizard test-confirm-form')}>
        <h2 className="gm-select-service__header">{'Your test has been requested!'}</h2>
        <div className="natera-cont">
          <div className="natera-detail">
            <div className="img-section">
              <img src={isMobile ? NateraMobImg : NateraImg} />
            </div>
            <div className="expect-next">
              <div className="expect-heading">Here’s what to expect next: </div>
              <div className="expect-desc">
                <ul>
                  <li>
                    You will receive an email to confirm your Genome Medical account. This will
                    allow you to access your results and schedule future genetic counseling visits.
                  </li>
                  <li>
                    You will receive an email from Genome Medical once a physician has reviewed your
                    test request.
                  </li>
                  <li>
                    Once the test has been ordered, you should receive a saliva kit in the mail from
                    Natera within 5 days. Natera will email you to confirm kit shipment.
                  </li>
                  <li>
                    Please follow the instructions in the kit and send it back to Natera within five
                    days of sample collection.
                  </li>
                  <li>
                    When your results are ready, Genome Medical will contact you via email. Results
                    are ready approximately two weeks from the time your sample arrives at the lab.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="still-have-question">
            <div className="still-label"> Still have questions? </div>
            <div className="reach-out">
              For questions related to your test request or genetic counseling services, please
              contact Genome Medical at{' '}
              <a key="mail-to" href="mailto:clinical@genomemedical.com">
                clinical@genomemedical.com
              </a>{' '}
              or 877-688-0992.
            </div>

            <div className="reach-out">
              For questions related to the status of your saliva testing kit or test related
              billing, please contact Natera at{' '}
              <a key="mail-to" href="mailto:tests-online@natera.com">
                tests-online@natera.com
              </a>{' '}
              or 855-774-2097.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
