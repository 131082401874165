import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import EventInfo from './EventInfo';

class AppointmentInfo extends Component {
  static propTypes = {
    title: PropTypes.string,
    appointment: PropTypes.object.isRequired,
    children: PropTypes.node,
    hidePrice: PropTypes.bool,
    newPrice: PropTypes.number,
    className: PropTypes.string,
    isWebinar: PropTypes.bool,
  };

  render() {
    const { title, appointment, children, newPrice, hidePrice, isWebinar } = this.props;
    const className = classnames('appointment-info', this.props.className);
    return (
      <EventInfo
        className={className}
        title={title}
        date={appointment.startTime}
        photo={appointment.provider.photoBig}>
        {children}
        <AppointmentSummary
          appointment={appointment}
          hidePrice={hidePrice}
          newPrice={newPrice}
          isWebinar={isWebinar}
        />
      </EventInfo>
    );
  }
}

export default AppointmentInfo;
