//@flow
import _ from 'lodash';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import ProgressBar from './ProgressBar';
import StatusMessages from 'constants/StatusMessages';
import classnames from 'classnames';
import cloudImage from 'images/upload-cloud.svg';
import crossImageOK from 'images/cross-purple.svg';
import crossImageError from 'images/cross-red.svg';
import './DropzoneField.scss';

type Timestamp = number;

export type UploadingStatusType = {
  uploading: boolean,
  uploadingProgress: Array<number>,
  uploadingResult: boolean,
  removingFiles: Array<number | string>,
};

export type FileToDownloadType = {
  id: number | string,
  name: string,
  extension?: string,
  createdAt?: Date,
  size?: number,
  error?: boolean,
};

export type FileFormType = {
  id?: number | string,
  name: string,
  type: string,
  size: number,
  lastModified: Timestamp,
  lastModifiedDate: Date,
  preview?: string,
};

export type FileType = FileFormType | FileToDownloadType;

const BYTES_IN_MB = 1024 * 1024;

class DropzoneField extends Component {
  static defaultProps = {
    accept: 'text/html,application/pdf,.doc,.xls,.jpeg,.jpg,.gif,.png',
    maxSize: 50 * BYTES_IN_MB,
    extraFiles: [],
    autoupload: true,
  };

  state: {
    formFiles: Array<FileType>,
    uploadingStarted: Boolean,
    someFilesRejected: Boolean,
  } = {
    formFiles: [],
    uploadingStarted: false,
    someFilesRejected: false,
  };

  shouldComponentUpdate(nextProps) {
    return !nextProps.loading;
  }

  props: {
    children: Element,
    newFileNames?: Array,
    autoupload: Boolean,
    multiple: Boolean,
    dropzoneOnDrop: Function,
    setAcceptedFiles: Function,
    getFileLink: Function,
    onRemove: Function,
    uploadingStatus: UploadingStatusType,
    loading?: Boolean,
    extraFiles: Array<FileType>,
    className?: string,
    accept?: string,
    maxSize?: Number,
    FileTypeText?: string,
  };

  onDrop = (formFiles: Array<FileType>) => {
    this.setState({ formFiles });
  };

  /**
   * the method is used with prop autoupload === false
   */
  onRemoveFormFile = index => {
    const formFiles = [
      ...this.state.formFiles.slice(0, index),
      ...this.state.formFiles.slice(index + 1),
    ];
    this.setState({ formFiles }, () => {
      if (this.props.setAcceptedFiles) this.props.setAcceptedFiles(this.state.formFiles);
    });
  };

  getFileNameBeforeUpload = (file, index) => {
    const { newFileNames } = this.props;
    return (newFileNames && newFileNames[index]) || file.name;
  };

  renderRemoveIcon(file, index) {
    const { autoupload, uploadingStatus, extraFiles } = this.props;
    const { uploadingResult } = uploadingStatus;
    const hasError =
      (uploadingResult && uploadingResult.status === false) || this.state.someFilesRejected;
    const onRemove = autoupload
      ? () => this.props.onRemove(file)
      : () => this.onRemoveFormFile(index);
    return file.id || (_.isEmpty(extraFiles) && !autoupload && !uploadingStatus.uploading) ? (
      <img
        src={hasError ? crossImageError : crossImageOK}
        className="fa-times-circle"
        alt=""
        onClick={onRemove}
      />
    ) : null;
  }

  renderFileAndProgress(file, index, params) {
    const { hasError, isRemoving } = params;
    const { uploadingStatus, extraFiles } = this.props;
    const { uploadingProgress } = uploadingStatus;
    const className = classnames('file-progress-container', {
      ['removing-file']: isRemoving,
      ['file-error']: hasError,
    });
    return (
      <div className={className}>
        {!file.id && <div className="filename">{this.getFileNameBeforeUpload(file, index)}</div>}
        {file.id && (
          <div className="filename">
            <a href={this.props.getFileLink(file.id)} className="file-link">
              {file.fileName}
            </a>
          </div>
        )}
        <ProgressBar
          className="dropzone-progressbar"
          percent={uploadingProgress[index - extraFiles.length] || (file.id ? 100 : 0)}
          strokeColor={isRemoving ? '#9B9B9B' : undefined}
          trailColor={hasError ? 'red' : undefined}
        />
        {this.renderRemoveIcon(file, index - extraFiles.length)}
        {file.error && <span>{file.error}</span>}
      </div>
    );
  }

  renderChildField() {
    const { children } = this.props;
    if (React.Children.count(children) > 1) return null;
    return <div className="child-fields">{children}</div>;
  }

  render() {
    const {
      className,
      dropzoneOnDrop,
      extraFiles,
      autoupload,
      multiple,
      children,
      accept,
      maxSize,
      fileTypeText,
    } = this.props;
    const {
      uploading,
      uploadingProgress,
      uploadingResult,
      removingFiles,
    } = this.props.uploadingStatus;
    const formFiles: Array<FileType> = ((uploading || !autoupload) && this.state.formFiles) || [];
    const files: Array<FileType> = [...extraFiles, ...formFiles];
    const showDropzone = _.isEmpty(files) ? true : false;
    return (
      <div className={classnames('gm-form-field', 'scp-dropzone-container', className)}>
        <Dropzone
          className={classnames('dropzone-rectangle', showDropzone ? '' : 'hide')}
          activeClassName="dropzone-active"
          rejectClassName="dropzone-reject"
          multiple={multiple}
          accept={accept}
          maxSize={maxSize}
          onDrop={(acceptedFiles: Array<FileType>, rejectedFiles: Array<FileType>, e) => {
            this.setState({ someFilesRejected: !_.isEmpty(rejectedFiles) });
            if (!uploading) {
              this.onDrop(acceptedFiles);
              dropzoneOnDrop && dropzoneOnDrop(acceptedFiles, rejectedFiles, e);
            }
          }}>
          <div className="dropzone-tip">
            {/* Drag files to upload or <span className="link">select from your drive</span> */}
            <div>
              <div className="dropzone-tip-wrapper">
                <div className="dropzone-tip-img">
                  {' '}
                  <img className="drop-zone-img" src={cloudImage} />
                </div>
                <div className="dropzone-tip-text">
                  Drop file here or{' '}
                  <a href="#" className="dropzone-tip-link">
                    click to upload
                  </a>
                </div>
                <div className="dropzone-tip-text-mobile">
                  <a href="#" className="dropzone-tip-link">
                    Browse Files
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Dropzone>
        {showDropzone &&
          fileTypeText &&
          !(
            uploadingResult.error ||
            (this.state.someFilesRejected && StatusMessages.rejectFiles)
          ) && <div className="file-type-text">{fileTypeText}</div>}
        {(uploadingResult.status === false || this.state.someFilesRejected) && (
          <div>
            <span className="error">
              {uploadingResult.error ||
                (this.state.someFilesRejected && StatusMessages.rejectFiles)}
            </span>
          </div>
        )}

        {Array.isArray(files) && (
          <ul className="dropzone-uploaded-files">
            {files.map((file, i) => {
              const isRemoving: boolean =
                !!file.id && _(removingFiles).includes(file.id ? file.id : undefined);
              const hasError = file.error || uploadingProgress[i - extraFiles.length] < 0;
              return (
                <li key={file.id || `new-${i}`} className="uploaded-file">
                  {this.renderFileAndProgress(file, i, { isRemoving, hasError })}
                  {children && this.renderChildField(i)}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default DropzoneField;
