import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import biopharmaIcon from './biopharma.png';
import authService from 'services/api/authService';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';

class BioPharmaTrailAuthorization extends Component {

  render() {
    return (
      <Fragment>
         <div className="biopharma-authorization">
        <div className="content mb15">
          <div className="static">
            <div className="left">
              <div className="title">Patient Authorization For Recontact</div>
              <div className="text" data-hj-suppress>
                While Genome Medical has certain rights under HIPAA to use your data to provide care and for research purposes, we may also contact you for the purposes of clinical trials, treatments or products that may be relevant to your health condition.
              </div>
            </div>
            <div className="right">
              <img className="biopharma-img" src={biopharmaIcon} alt="no appointment" />
            </div>
          </div>
          <div className="action-items left">
            <ButtonV2 path="/patient/consent" className="no-appointment">
            Authorize
            </ButtonV2>
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: authService.getCurrentUser(state)
  };
};

export default connect(mapStateToProps)(BioPharmaTrailAuthorization);
