import React, { Component } from 'react';
import { Select } from 'gm-element-react';
import _ from 'lodash';

export default class SelectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsEnum: props.options,
        };
    }
    componentDidUpdate(preProps) {
        if (preProps.options != this.props.options) {
            this.setState({ optionsEnum: this.props.options });
        }
    }

    render() {
        const {
            value,
            className,
            onChange,
            disabled = false,
            placeholder,
            multiple = false,
            typeahead,
            clearable,
            options,
            renderOption,
        } = this.props;
        const { optionsEnum } = this.state;
        return (
            <Select
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder || 'Select'}
                multiple={multiple}
                className={className}
                filterable={typeahead}
                clearable={disabled?false:clearable}
                filterMethod={value => {
                    if (!_.isNil(typeahead) && typeahead) {
                        if (_.isEmpty(value) || _.isNil(value)) {
                            this.setState({ optionsEnum: options });
                        } else {
                            let _options = options.filter(
                                o => o.label && _.toString(o.label).toLowerCase().startsWith(value.toLowerCase())
                            );
                            this.setState({ optionsEnum: _options });
                        }
                    }
                }}>
                {(optionsEnum || []).map(el => {
                    return renderOption ? (
                        <Select.Option key={el.value} label={el.label} value={el.value}>
                            {renderOption(el)}
                        </Select.Option>
                    ) : (
                        <Select.Option key={el.value} label={el.label} value={el.value} />
                    );
                })}
            </Select>
        );
    }
}
