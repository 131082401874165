import authService from 'services/api/authService';
import api from 'services/api/api';
import { login } from './email';
import _ from 'lodash';

const FORGOT_PASSWORD_REQUEST = 'gm/auth/password/FORGOT_PASSWORD_REQUEST';
const FORGOT_PASSWORD_SUCCESS = 'gm/auth/password/FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILURE = 'gm/auth/password/FORGOT_PASSWORD_FAILURE';
const RESET_PASSWORD_REQUEST = 'gm/auth/password/RESET_PASSWORD_REQUEST';
const RESET_PASSWORD_SUCCESS = 'gm/auth/password/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILURE = 'gm/auth/password/RESET_PASSWORD_FAILURE';
const SET_PASSWORD_REQUEST = 'gm/auth/password/SET_PASSWORD_REQUEST';
const SET_PASSWORD_SUCCESS = 'gm/auth/password/SET_PASSWORD_SUCCESS';
const SET_PASSWORD_FAILURE = 'gm/auth/password/SET_PASSWORD_FAILURE';
const SEND_PASSWORD_LINK_REQUEST = 'gm/auth/password/SEND_PASSWORD_LINK_REQUEST';
const SEND_PASSWORD_LINK_SUCCESS = 'gm/auth/password/SEND_PASSWORD_LINK_SUCCESS';
const SEND_PASSWORD_LINK_FAILURE = 'gm/auth/password/SEND_PASSWORD_LINK_FAILURE';
const CLEAR_FORGOT_PASSWORD = 'gm/auth/password/CLEAR_FORGOT_PASSWORD';
const CHECK_PASSWORD_LINK_REQUEST = 'gm/auth/password/CHECK_PASSWORD_LINK_REQUEST';
const CHECK_PASSWORD_LINK_SUCCESS = 'gm/auth/password/CHECK_PASSWORD_LINK_SUCCESS';
const CHECK_PASSWORD_LINK_FAILURE = 'gm/auth/password/CHECK_PASSWORD_LINK_FAILURE';

const initialState = {
  forgotPasswordEmailSent: false,
  forgotPasswordError: null,
  resetPasswordError: null,
  authLinkIsValid: false,
  authLinkError: null,
  sendPasswordLinkError: null,
  authEmail: null,
  CheckPasswordData: {},
  setPasswordExpiredError: null,
  resetPasswordExpiredError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // forgot password
    case CLEAR_FORGOT_PASSWORD:
    case FORGOT_PASSWORD_REQUEST:
      return { ...state, forgotPasswordEmailSent: false, forgotPasswordError: null };
    case FORGOT_PASSWORD_FAILURE:
      return { ...state, forgotPasswordEmailSent: false, forgotPasswordError: action.payload };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPasswordEmailSent: true };
    // reset password
    case RESET_PASSWORD_REQUEST:
      return { ...state, resetPasswordError: null, resetPasswordExpiredError: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordError: null, resetPasswordExpiredError: null };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordError: action.payload.message,
        resetPasswordExpiredError: action.payload.error,
      };
    // set password
    case SET_PASSWORD_REQUEST:
      return { ...state, resetPasswordError: null, setPasswordExpiredError: null };
    case SET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordError: null, setPasswordExpiredError: null };
    case SET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordError: action.payload.message,
        setPasswordExpiredError: action.payload.error,
      };
    // send set password link
    case SEND_PASSWORD_LINK_REQUEST:
      return { ...state, sendPasswordLinkError: null };
    case SEND_PASSWORD_LINK_SUCCESS:
      return { ...state, sendPasswordLinkError: null };
    case SEND_PASSWORD_LINK_FAILURE:
      return { ...state, sendPasswordLinkError: action.payload };
    // checkLink
    case CHECK_PASSWORD_LINK_REQUEST:
      return { ...state, authLinkIsValid: null, authLinkError: null };
    case CHECK_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        authLinkIsValid: true,
        authEmail: action.payload.email,
        CheckPasswordData: action.payload.data,
      };
    case CHECK_PASSWORD_LINK_FAILURE:
      return {
        ...state,
        authLinkIsValid: false,
        authLinkError: action.payload.error,
        authEmail: action.payload.email,
      };
    default:
      return state;
  }
}

export function clearForgotPassword() {
  return { type: CLEAR_FORGOT_PASSWORD };
}

function forgotPasswordRequest() {
  return { type: FORGOT_PASSWORD_REQUEST };
}

function forgotPasswordSuccess() {
  return { type: FORGOT_PASSWORD_SUCCESS };
}

function forgotPasswordFailure(error) {
  return { type: FORGOT_PASSWORD_FAILURE, payload: error };
}

function resetPasswordRequest(values) {
  return { type: RESET_PASSWORD_REQUEST, payload: values };
}

function resetPasswordSuccess(data) {
  return { type: RESET_PASSWORD_SUCCESS, payload: data };
}

function resetPasswordFailure(message, error) {
  return { type: RESET_PASSWORD_FAILURE, payload: { message, error } };
}

function setPasswordRequest(values) {
  return { type: SET_PASSWORD_REQUEST, payload: values };
}

function setPasswordSuccess() {
  return { type: SET_PASSWORD_SUCCESS };
}

function setPasswordFailure(message, error) {
  return { type: SET_PASSWORD_FAILURE, payload: { message, error } };
}

function sendSetPasswordLinkRequest() {
  return { type: SEND_PASSWORD_LINK_REQUEST };
}

function sendSetPasswordLinkSuccess() {
  return { type: SEND_PASSWORD_LINK_SUCCESS };
}

function sendSetPasswordLinkFailure(error) {
  return { type: SEND_PASSWORD_LINK_FAILURE, payload: error };
}

function setCheckPasswordRequest() {
  return { type: CHECK_PASSWORD_LINK_REQUEST };
}

function setCheckPasswordLinkSuccess(data) {
  let email = data && data.email;
  if (!email) email = null;
  return { type: CHECK_PASSWORD_LINK_SUCCESS, payload: { email, data } };
}

function setCheckPasswordLinkFailure(error) {
  let email = error && error.data && error.data.email;
  if (!email) email = null;
  const newerror = {
    error: {
      code: error.code,
      status: error.status,
      message: error.message,
    },
    email: email,
  };
  return { type: CHECK_PASSWORD_LINK_FAILURE, payload: newerror };
}

export function checkPasswordLink({ token, type }) {
  return dispatch => {
    dispatch(setCheckPasswordRequest());
    return authService
      .checkLink({ token, type })
      .then(data => dispatch(setCheckPasswordLinkSuccess(data)))
      .catch(error => {
        dispatch(
          setCheckPasswordLinkFailure({
            code: error.code,
            status: error.status,
            message: error.message,
            data: error.data,
          })
        );
        throw error;
      });
  };
}

export function sendSetPasswordLink({ email, captcha }) {
  return dispatch => {
    dispatch(sendSetPasswordLinkRequest());
    return authService
      .resendSetPasswordActivateEmail({ email, captcha })
      .then(() => dispatch(sendSetPasswordLinkSuccess()))
      .catch(error => {
        dispatch(
          sendSetPasswordLinkFailure({
            code: error.code,
            status: error.status,
            message: error.message,
          })
        );
        throw error;
      });
  };
}

export function forgotPassword({ email, captcha }) {
  return dispatch => {
    dispatch(forgotPasswordRequest());
    return authService
      .forgotPassword({ email, captcha })
      .then(() => dispatch(forgotPasswordSuccess()))
      .catch(error => {
        if (error.submissionError) {
          dispatch(forgotPasswordFailure());
          throw error.submissionError;
        }
        return dispatch(forgotPasswordFailure(error.fullMessage || error.message));
      });
  };
}

/**
 * Reset password for user when the reset is requested
 */
export function resetPassword({ email, resetPasswordToken, password }) {
  return dispatch => {
    dispatch(resetPasswordRequest());
    return authService
      .resetPassword({ token: resetPasswordToken, password })
      .then(() => {
        dispatch(resetPasswordSuccess());
        if (email) return dispatch(login({ email, password }));
      })
      .catch(error => {
        if (!_.isNil(error.fields) && !_.isEmpty(error.fields)) {
          dispatch(
            resetPasswordFailure(_.get(error, ['fields', 'password'], 'Invalid Request'), error)
          );
        } else {
          dispatch(resetPasswordFailure(error.message, error));
        }
        throw error;
      });
  };
}

/**
 * Set password for a new user
 */
export function setPassword(values) {
  return dispatch => {
    dispatch(setPasswordRequest());
    return authService
      .setPassword({ token: values.resetPasswordToken, ...values })
      .then(data => {
        dispatch(setPasswordSuccess());
        api.setToken(data.token);
        return data;
      })
      .catch(error => {
        if (error.submissionError) {
          throw error.submissionError;
        }
        dispatch(setPasswordFailure(error.message, error));
        throw error;
      });
  };
}
