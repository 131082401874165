import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter, browserHistory } from 'react-router';
import classnames from 'classnames';
import api from 'services/api/api';
import authService from 'services/api/authService';
import { logout } from 'ducks/auth/email';
import gmiLogo from '../../../images/logo.svg';
import MobileMenu from './MobileMenu/MobileMenu';
import arrow from './arrow.svg';
import GrailStatusEnums from 'constants/GrailStatus';
import schedulingService from 'services/api/schedulingService';
import './Header.scss';
import _ from 'lodash';
import { ConsentStatus } from '../../../constants/CommonConstants';
import { MANUAL_CONSENT_OUTREACH } from '../../../ducks/misc/redirectActions';

class Header extends React.Component {
  state = { isMobile: false };
  static propTypes = {
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
  };

  componentDidMount() {
    this.setState({ isMobile: schedulingService.isMobile() });
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setViewMode();
  };

  setViewMode() {
    const { isMobile } = this.state;
    if (isMobile && !schedulingService.isMobile()) {
      this.setState({ isMobile: false });
    } else if (!isMobile && schedulingService.isMobile()) {
      this.setState({ isMobile: true });
    }
  }

  isLoggedIn() {
    return !!this.props.currentUser && !this.props.holdLoggedIn;
  }

  isSchedulePath(path) {
    return /^\/scheduling/.test(path);
  }

  getNavigationItems() {
    const {
      location: { pathname },
      currentUser,
    } = this.props;

    if ((api.hasToken() && !this.isLoggedIn()) || !api.hasToken()) return [];
    if (!this.hideMenuAndProfile) return [];

    const items = [
      {
        name: 'Home',
        path: '/patient/home',
        active: pathname === '/patient/home',
      },
      {
        name: 'Education',
        path: '/patient/education',
        active: pathname === '/patient/education',
        hidden: api.isNonSCPUser(),
      },
      {
        name: 'Documents',
        path: '/patient/results',
        active: pathname === '/patient/results',
      },
      {
        name: 'Appointments',
        path: '/patient',
        active:
          ['/patient', '/patient/reschedule', '/patient/questionnaire'].includes(pathname) ||
          this.isSchedulePath(pathname),
        hidden: currentUser && currentUser.isGrailFlowAffiliation,
      },
    ];

    return items
      .filter((item) => !item.hidden)
      .map((item) =>
        Object.assign(item, {
          active: item.active === undefined ? pathname.includes(item.path) : item.active,
        })
      );
  }

  renderItems(itemsList) {
    if (!itemsList || itemsList.length === 0) return <li />;
    return itemsList.map((li, i) => (
      <li className={classnames({ active: li.active })} key={i}>
        <Link to={li.path}>
          {li.name}
          <div className="line" />
        </Link>
      </li>
    ));
  }

  goToProfile() {
    browserHistory.push('/patient/profile');
  }

  onLogout() {
    this.props.dispatch(logout());
  }
  get hideMenuAndProfile() {
    const { currentUser, redirectActions } = this.props;
    const isManualOutreach = redirectActions.actions[MANUAL_CONSENT_OUTREACH];
    const userConsent = _.get(
      currentUser,
      ['latestPatientToReconatctStatusObject','user_consent'],
      null
    );
    const userConsentStatus = _.get(userConsent, ['status']);
    if (
      currentUser &&
      (_.isNil(userConsent) || (isManualOutreach && userConsentStatus == ConsentStatus.SKIPPED))
    ) {
      return false;
    } else {
      return (
        (currentUser &&
          currentUser.grailStatus != GrailStatusEnums.QUALIFICATION_REQUIRED &&
          currentUser.grailStatus != GrailStatusEnums.SWITCH_AFFILIATION) ||
        (currentUser && currentUser.affiliationIsIllumina)
      );
    }
  }

  renderProfile() {
    const { currentUser } = this.props;
    return (
      <div className="menu-container">
        <div tabIndex="0" className="user-full-name">
          <p>
            <span data-hj-suppress>
              {currentUser && currentUser.fullName ? currentUser.fullName : ''}
            </span>{' '}
            <img src={arrow} />
          </p>
          <ul>
            {this.hideMenuAndProfile && (
              <li onClick={() => this.goToProfile()}>
                <Link>Profile</Link>
              </li>
            )}
            <li onClick={() => this.onLogout()}>
              <Link>Signout</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const itemsList = this.getNavigationItems();
    const {
      location: { pathname },
    } = this.props;
    const isSchedulingWizardPage = pathname.startsWith('/scheduling');
    const isMobile = _.get(this, ['state', 'isMobile'], false);

    return (
      <div
        className="scp-header-container"
        style={{ display: isSchedulingWizardPage && isMobile ? 'none' : 'block' }}
      >
        <div className="nav-container">
          <div className="logo-container">
            <Link to="/home">
              <img src={gmiLogo} className="header-logo" />
            </Link>
          </div>
          <div className="navigation-container">
            <ul className="header-list">{this.renderItems(itemsList)}</ul>
          </div>
          {this.isLoggedIn() && this.renderProfile()}
        </div>
        {!isSchedulingWizardPage && (
          <MobileMenu
            items={itemsList}
            isLoggedIn={this.isLoggedIn()}
            onLogout={() => this.onLogout()}
            pathname={pathname}
            hideMenuAndProfile={this.hideMenuAndProfile}
          />
        )}
      </div>
    );
  }
}

Header.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: authService.getCurrentUser(state),
    holdLoggedIn: state.user.holdLoggedIn,
    redirectActions: state.redirectActions,
  };
};

export default compose(connect(mapStateToProps), withRouter)(Header);
