import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import authService from 'services/api/authService';
import { verifyEmail, resendCode, clearFormError } from 'ducks/registrationWizard';
import { refreshToken } from 'ducks/user';
import api from 'services/api/api';
import _ from 'lodash';
import EmailVerificationForm from 'containers/pages/public/Scp/Registration/EmailVerificationForm';
import SoftRegistrationPageHeader from 'containers/pages/public/Scp/Registration/SoftRegistrationPageHeader';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import Footer from 'components/scp/footer/Footer';
import { showLoader, closeLoader } from 'ducks/ui';

class EmailVerificationContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(clearFormError());
    if (api.isOTCVerifiedRequired() && !_.isNil(api.getSoftRegistrationUUID())) {
      this.setState({ uuid: api.getSoftRegistrationUUID() }, () => {
        this.resendVerificationCode();
      });
    }
  }

  submitEmailVerification = values => {
    const { dispatch, doForceUpdate } = this.props;
    const { uuid } = this.state;
    dispatch(showLoader());
    return verifyEmail(uuid, values, dispatch)
      .then(() => {
        dispatch(refreshToken()).then(() => {
          dispatch(closeLoader());
          doForceUpdate();
        });
      })
      .catch(err => {
        dispatch(closeLoader());
        throw err;
      });
  };

  resendVerificationCode = () => {
    const { dispatch } = this.props;
    const { uuid } = this.state;
    return resendCode(uuid, dispatch);
  };

  render() {
    const { formError } = this.props.registrationWizard;
    return (
      <div className="scp-email-verification-soft-reg-container">
        <div className="scp-soft-rgstrtion-container">
          <div className="soft-rgstrtion-wrapper">
            <SoftRegistrationPageHeader />
            <EmailVerificationForm
              onSubmit={this.submitEmailVerification}
              formError={formError}
              softRegistrationData={{ ...this.props._currentUser }}
              resendVerificationCode={this.resendVerificationCode}
            />
          </div>
        </div>
        <Footer />
        <GlobalLoader />
      </div>
    );
  }
}

EmailVerificationContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const _currentUser = authService.getCurrentUser(state);
    const registrationWizard = state.registrationWizard;
    return { _currentUser, registrationWizard };
  },
  dispatch => {
    return { dispatch };
  }
)(EmailVerificationContainer);
