import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/forms/controls/Button';
import Modal from 'components/modals/SCP/ModalDialog';
import SuccessIcon from 'components/widgets/SCP/TestInfoBox/success.svg';
import './ABNDownloadModal.scss';

const ABNDownloadModal = props => {
  const { onConfirm, onCancel, show, confirmText, roleModal, description } = props;
  const confirmButtonText = confirmText ? confirmText : 'Ok';
  return (
    <Modal
      className="abn-download-modal scp-md464"
      modalHeader={null}
      hideHeader={true}
      onHide={onCancel}
      show={show}
      roleModal={roleModal}>
      <div className="abn-download-modal-body">
        <div className="checkmark-center">
          <img src={SuccessIcon} alt="Success" />
        </div>
        <div className="title">Completed</div>
        {description}
        <div className="confirm-buttons">
          <Button onClick={onConfirm}>{confirmButtonText}</Button>
        </div>
      </div>
    </Modal>
  );
};

ABNDownloadModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  text: PropTypes.string,
  confirmButtonText: PropTypes.string,
  roleModal: PropTypes.string,
  modalHeader: PropTypes.string,
};

export default ABNDownloadModal;
