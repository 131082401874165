import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';
import classnames from 'classnames';
import {
  dateIsPast,
  optionalEmail,
  optionalPatientName,
} from 'services/validate';
import InputField from 'components/forms/controls/V2/InputField';
import CheckBoxField from 'components/forms/controls/V2/CheckBoxField';
import InputMaskField from 'components/forms/controls/V2/InputMaskField';

const PartnerForm = (props) => {

  const {
    handleChange,
    initialState
  } = props;

  const partnerAuthEnable = () =>{
    const partnerFirstName = _.get(initialState,['aoeForm','partnerFirstName']);
    const partnerLastName = _.get(initialState,['aoeForm','partnerLastName']);
    const partnerDOB = _.get(initialState,['aoeForm','partnerDOB']);
    const partnerEmail = _.get(initialState,['aoeForm','partnerEmail']);
    if(partnerFirstName || partnerLastName || partnerDOB || partnerEmail)
      return true;
    return false;
  };

  return (
    <div className="form-section">
      <div className="info-section">
        <h3 className="header">Partner Details</h3>
        <div className="helper-text">Please enter details for your reproductive partner so that your reports can be joined.</div>
      </div>
      <div className="body-section">
        <div className="row-section">
          <div className="col-section">
            <div className="label-section"> Partner's First Name</div>
            <div className="input-section">
              <Field
                name="aoeForm.partnerFirstName"
                type="text"
                placeholder={`Partner's First Name`}
                trim={true}
                component={InputField}
                validate={[optionalPatientName]}
                onChange={(e, value) => handleChange('partnerFirstName', value)}
              />
            </div>
          </div>
          <div className="col-section">
            <div className="label-section">Partner's Last Name</div>
            <div className="input-section">
              <Field
                name="aoeForm.partnerLastName"
                type="text"
                placeholder={`Partner's Last Name`}
                trim={true}
                component={InputField}
                validate={[optionalPatientName]}
                onChange={(e, value) => handleChange('partnerLastName', value)}
              />
            </div>
          </div>
        </div>
        <div className="row-section">
          <div className="col-section">
            <div className="label-section"> Partner's Date of Birth</div>
            <div className="input-section">
              <Field
                name="aoeForm.partnerDOB"
                placeholder="MM/DD/YYYY"
                component={InputMaskField}
                mask="99/99/9999"
                maskChar={null}
                validate={[dateIsPast()]}
                onChange={(e, value) => handleChange('partnerDOB', value)}
                
              />
            </div>
          </div>
          <div className="col-section">
            <div className="label-section">Partner's Email</div>
            <div className="input-section">
              <Field
                name="aoeForm.partnerEmail"
                type="email"
                placeholder="Partner's Email"
                trim={true}
                component={InputField}
                validate={[optionalEmail]}
                onChange={(e, value) => handleChange('partnerEmail', value)}
              />
            </div>
          </div>

        </div>
        <div className="row-section">
          <div className="col-section full-col consent-text">
            <div className={classnames("input-section consent-to-text partner-auth",
              { "is-disabled":!partnerAuthEnable() } 
            )}>
              <Field
                name={'aoeForm.partnerAuth'}
                type="checkbox"
                label={
                  <Fragment>
                    <span className="checkmark" />
                    <div className="label-section">I would like to share my Horizon test results with my partner and their healthcare provider for treatment purposes.</div>
                  </Fragment>
                }
                component={CheckBoxField}
                onChange={(e, value) => handleChange('partnerCS_PART_AUTH', value)}
                disabled={!partnerAuthEnable()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};


export default PartnerForm;
