import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';

import CareCoordinator from 'components/widgets/CareCoordinator/CareCoordinator';
import StepsPanel from 'components/widgets/StepsPanel/StepsPanel';
import FaxForm from '../FaxForm/FaxForm';
import referralScheme, {
  sectionProviderInfo,
  sectionReferralInfo,
  sectionPatientInfo,
  sectionAdditionalInfo,
} from 'constants/ReferralsScheme';
import Button from 'components/forms/controls/Button';
import Alert from 'components/widgets/Alert/Alert';
import ReferAdditionalInformation from './../Steps/ReferAdditionalInformation/ReferAdditionalInformation';
import ReferPatientInformation from './../Steps/ReferPatientInformation/ReferPatientInformation';
import ReferProviderInformation from './../Steps/ReferProviderInformation/ReferProviderInformation';
import ReferReferralInformation from './../Steps/ReferReferralInformation/ReferReferralInformation';
import {
  gotoStage,
  saveAnswers,
  fillFromStorage,
  submitAnswers,
  clearError,
} from 'ducks/referrals';
import { preprocessAnswer } from 'services/utils';
import './ReferralFormFrame.scss';

class ReferralFormFrame extends Component {
  static propTypes = {
    className: PropTypes.string,
    answers: PropTypes.object,
    fillFromStorage: PropTypes.func,
    referStage: PropTypes.string,
    gotoStage: PropTypes.func,
    saveAnswers: PropTypes.func,
    submitAnswers: PropTypes.func,
    error: PropTypes.string,
    clearError: PropTypes.func,
  };

  static stages = [
    sectionProviderInfo,
    sectionReferralInfo,
    sectionPatientInfo,
    sectionAdditionalInfo,
  ];

  componentDidMount() {
    if (!this.props.answers) this.props.fillFromStorage();
  }

  getStages = currentStageIndex =>
    ReferralFormFrame.stages.map((key, i) => ({
      header: referralScheme[key].section,
      done: i < currentStageIndex,
    }));

  getActiveStageIndex = () =>
    [sectionProviderInfo, sectionReferralInfo, sectionPatientInfo, sectionAdditionalInfo].indexOf(
      this.props.referStage
    );

  goStageClick = index =>
    this.getActiveStageIndex() > index && this.props.gotoStage(ReferralFormFrame.stages[index]);

  submitAnswers = ({ data, metadata, scheme, order, nextSection }) => {
    const { sectionKey, section } = scheme;
    const answers = _.keys(data)
      .filter(name => !!scheme.questions[name])
      .map(name => ({
        text: scheme.questions[name],
        section,
        sectionKey,
        name,
        index: order.indexOf(name),
        answer: preprocessAnswer(data[name]),
        metadata: (metadata && metadata[name]) || data[name],
      }));
    this.props.saveAnswers(sectionKey, _.keyBy(answers, a => a.name)).then(() => {
      nextSection ? this.props.gotoStage(nextSection) : this.props.submitAnswers();
    });
  };

  renderStage = () => {
    switch (this.props.referStage) {
      case sectionProviderInfo:
        return (
          <ReferProviderInformation
            onSubmit={this.submitAnswers}
            gotoStage={this.props.gotoStage}
            initialValues={this.getInitialValues(sectionProviderInfo)}
          />
        );
      case sectionReferralInfo:
        return (
          <ReferReferralInformation
            onSubmit={this.submitAnswers}
            gotoStage={this.props.gotoStage}
            initialValues={this.getInitialValues(sectionReferralInfo)}
          />
        );
      case sectionPatientInfo:
        return (
          <ReferPatientInformation
            onSubmit={this.submitAnswers}
            gotoStage={this.props.gotoStage}
            initialValues={this.getInitialValues(sectionPatientInfo)}
          />
        );
      case sectionAdditionalInfo:
        return (
          <ReferAdditionalInformation
            onSubmit={this.submitAnswers}
            gotoStage={this.props.gotoStage}
            initialValues={this.getInitialValues(sectionAdditionalInfo)}
          />
        );
    }
  };

  getInitialValues = sectionKey => {
    const { answers } = this.props;
    if (!answers) return {};
    const sectionAnswers = answers[sectionKey];
    if (!sectionAnswers) return {};
    return _(sectionAnswers)
      .keyBy(a => a.name)
      .mapValues(v => v.metadata)
      .value();
  };

  renderError = () => (
    <div className="ref-error-block">
      <Alert className="error-message" message={this.props.error} />
      <Button onClick={this.props.clearError}>Back</Button>
    </div>
  );

  render() {
    const { className, answers, error } = this.props;
    const activeStageIndex = this.getActiveStageIndex();
    return error ? (
      this.renderError()
    ) : (
      <div className={classnames('ref-frame', className)}>
        <div className="steps">
          <StepsPanel
            className="qn-steps"
            children={this.getStages(activeStageIndex)}
            activeStep={activeStageIndex}
            showAll={true}
            onClickHandler={this.goStageClick}
          />
          <CareCoordinator className="ref-care-coord" type="referral" />
        </div>
        <div className="ref-form form-frame">{answers && this.renderStage()}</div>
        <div className="fax-panel">
          <FaxForm />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ referrals }) => ({
    referStage: referrals.referStage || sectionProviderInfo,
    answers: referrals.answers,
    isUploading: referrals.isUploading,
    error: referrals.error,
  }),
  dispatch => ({
    saveAnswers: (sectionKey, answers) => dispatch(saveAnswers(sectionKey, answers)),
    gotoStage: stage => dispatch(gotoStage(stage)),
    fillFromStorage: () => dispatch(fillFromStorage()),
    submitAnswers: () => dispatch(submitAnswers()),
    clearError: () => dispatch(clearError()),
  })
)(ReferralFormFrame);
