import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import './YellowHint.scss';

const YellowHint = ({ className, icon, children }) => (
  <div className={classnames('scp-yellow-hint', className)}>
    {icon && <img src={icon} className="hint-icon" />}
    <div className="hint-content">{children}</div>
  </div>
);

YellowHint.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
};

export default YellowHint;
