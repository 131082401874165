import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import AppointmentInfoWithCancel from 'components/widgets/EventInfo/AppointmentInfoWithCancel';
import Button from 'components/forms/controls/Button';
import { setRegistrationStep } from 'services/utils';
import { goToReschedule } from 'services/navigation';
import './ThanksForSchedulingForm.scss';
import google from './icon-google.png';
import apple from './icon-apple.png';
import microsoft from './icon-microsoft.png';

const webinarData = {
  title: 'Thank you for registering for the Access Your Genome program. Please confirm your email.',
  desc: 'Check your inbox and follow the instructions to confirm your email.',
};
const appointmentData = {
  title: 'Thank you for scheduling an appointment.',
  desc:
    'Please check your inbox for an email with details about your next steps. To ensure that your genetics consultation ' +
    'is as helpful as possible, we’ll need you to complete a few simple items before the appointment. ',
};

class ThanksForSchedulingForm extends Component {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    calendars: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    submitting: PropTypes.bool,
    newPrice: PropTypes.number,
    isPackage: PropTypes.bool,
    isWebinar: PropTypes.bool,
    isChangeable: PropTypes.bool,
  };

  componentDidMount() {
    setRegistrationStep('confirm');
  }

  googleCalendarUrl = () =>
    this.props.calendars &&
    this.props.calendars['google-calendar'] &&
    this.props.calendars['google-calendar'].url;
  appleCalendarUrl = () =>
    this.props.calendars &&
    this.props.calendars['apple-calendar'] &&
    this.props.calendars['apple-calendar'].url;
  outlookCalendarUrl = () =>
    this.props.calendars &&
    this.props.calendars['outlook-calendar'] &&
    this.props.calendars['outlook-calendar'].url;

  renderForm() {
    return (
      <form onSubmit={this.props.onSubmit} className="thank-you-step-form">
        <div className="buttons">
          <Button className="button button-next button-next__wide" type="submit">
            GO TO MY GUIDE
          </Button>
        </div>
      </form>
    );
  }

  renderCalendars() {
    return (
      <div className="save-event-to-calendar">
        Save event to your calendar
        <div className="save-event-icons">
          <div className="calendar-event">
            <a href={this.googleCalendarUrl()} target="_blank">
              <img src={google} />
              <span className="link">Google</span>
            </a>
          </div>
          <div className="calendar-event">
            <a href={this.appleCalendarUrl()} target="_blank">
              <img src={apple} />
              <span className="link">Apple</span>
            </a>
          </div>
          <div className="calendar-event">
            <a href={this.outlookCalendarUrl()} target="_blank">
              <img src={microsoft} />
              <span className="link">Microsoft</span>
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { appointment, newPrice, isPackage, isWebinar, isChangeable, onCancel } = this.props;
    if (_.isEmpty(appointment)) return null;
    const descData = isWebinar ? webinarData : appointmentData;
    return (
      <div
        className={classnames('registration-wizard thank-you-step', {
          'webinar-confirm': isWebinar,
        })}>
        <div className="description-block">
          <h3>{descData.title}</h3>
          <div className="description">{descData.desc}</div>
        </div>
        <div className="appointment-summary">
          <AppointmentInfoWithCancel
            appointment={appointment}
            newPrice={newPrice}
            hidePrice={isPackage}
            isWebinar={isWebinar}
            isChangeable={isChangeable}
            onCancel={onCancel}
            onReschedule={goToReschedule}
            className="appointment-info__no-title"
          />
        </div>
        {this.googleCalendarUrl() &&
          this.appleCalendarUrl() &&
          this.outlookCalendarUrl() &&
          this.renderCalendars()}
        {this.renderForm()}
      </div>
    );
  }
}

export default ThanksForSchedulingForm;
