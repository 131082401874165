import api from './api';
import type { SelectionConfig, SelectionCheckResults, PartnerSelectionInfo } from './selectionApi';
import { SessionStore } from 'services/storage';
import { processSelectionError } from 'services/api/transformers/errorTransformers';

const GM_SELECTION_RESULTS = 'GM_SELECTION_RESULTS';
const GM_SELECTION_REFERRAL = 'GM_SELECTION_REFERRAL';

export const SelectionReferral = 'Genentech';

class SelectionService {
  getSelectionConfig(referral: string): Promise<Array<SelectionConfig>> {
    return api.selection.getSelectionConfig(referral);
  }

  checkSelection(referral: string, params: Object): Promise<SelectionCheckResults> {
    return api.selection.checkSelection(referral, params).catch(processSelectionError);
  }

  saveReferral(referral: string) {
    SessionStore.set(GM_SELECTION_REFERRAL, referral);
  }

  getReferral() {
    return SessionStore.get(GM_SELECTION_REFERRAL);
  }

  clearReferral() {
    SessionStore.remove(GM_SELECTION_REFERRAL);
  }

  saveVerificationData(selection_token: string, additionalData: Array) {
    SessionStore.set(GM_SELECTION_RESULTS, { selection_token, additionalData });
  }

  clearVerificationData() {
    SessionStore.remove(GM_SELECTION_RESULTS);
  }

  getVerificationData() {
    return SessionStore.get(GM_SELECTION_RESULTS);
  }

  getPartnerInfo(partnerName: string): Promise<PartnerSelectionInfo> {
    return api.eligibility.getPartnerInfo(partnerName);
  }
}

export default new SelectionService();
