import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { setOutreachAppointment } from 'ducks/scheduling';
import { loadAppointments } from 'ducks/appointments';

class OutreachAppointment extends Component {
  state = {};

  componentDidMount() {
    this.navigateToScheduling();
  }

  componentDidUpdate() {
    this.navigateToScheduling();
  }

  setOutreachAndRedirectToScheduling(appt) {
    const { dispatch } = this.props;
    setOutreachAppointment(appt);
    browserHistory.push(`/scheduling/select-service`);
  }

  setOutreachAndRedirectToHome() {
    browserHistory.push(`/patient/home`);
  }

  navigateToScheduling = () => {
    const { appointmentsLoading, appointmentsLoaded, appointments, params } = this.props;
    if (params && params.appointmentId) {
      if (appointmentsLoading == false && appointmentsLoaded) {
        const appointment = _.find(appointments || [], (appt) => {
          return appt.id == params.appointmentId;
        });
        if (appointment) {
          this.setOutreachAndRedirectToScheduling(appointment);
        } else {
          this.setOutreachAndRedirectToHome();
        }
      } else this.props.loadAppointments();
    } else {
      this.setOutreachAndRedirectToScheduling();
    }
  };

  render() {
    return <div />;
  }
}

OutreachAppointment.propTypes = {
  appointments: PropTypes.array,
  appointmentsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  appointmentsLoaded: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  appointments: state.appointments.data,
  appointmentsLoading: state.appointments.loading,
  appointmentsLoaded: state.appointments.loaded,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadAppointments: () => dispatch(loadAppointments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutreachAppointment);
