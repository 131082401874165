import PropTypes from 'prop-types';
import React from 'react';
import BsButton from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import classnames from 'classnames';

import './ControlledButtonGroup.scss';

class StepsPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.array,
    activeStepIndex: PropTypes.number,
    showAll: PropTypes.bool,
    vertical: PropTypes.bool,
    onClickHandler: PropTypes.func,
  };

  render() {
    const { showAll, onClickHandler, className, vertical } = this.props;
    return (
      <ButtonGroup vertical={vertical} className={classnames('controlled-button-group', className)}>
        {this.props.children.map((item, i) => {
          const active = i === this.props.activeStepIndex;
          const className = classnames('controlled-button', {
            ['controlled-button__active']: active,
          });
          return !showAll && !item.done ? null : (
            <BsButton key={i} onClick={() => onClickHandler(i)} className={className}>
              {item.header}
            </BsButton>
          );
        })}
      </ButtonGroup>
    );
  }
}

export default StepsPanel;
