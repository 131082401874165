// @flow
import api from './api';
import type { UpdateSCPEncounter } from './encountersApiTypes';
export default class EncountersAPI {
  getEncounters(): Promise {
    return api.get('/scp/encounters');
  }

  updateSCPEncounter(id: number, params: UpdateSCPEncounter): Promise<any> {
    return api.patch('/scp/encounters/' + id, params);
  }
  createSCPEncounter(): Promise<any> {
    return api.post('/scp/encounters');
  }
}
