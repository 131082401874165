import { browserHistory } from 'react-router';
import Context from '../Context';
import ActionBase from './ActionBase';

/**
 * @class RedirectAction
 * @classdesc Base class for conditional redirect actions
 */
export default class RedirectAction extends ActionBase {
  from: string = null;
  to: string = null;

  constructor(from: string, to: string) {
    super();
    this.from = from;
    this.to = to;
  }

  canExecute(context: Context = null): boolean {
    const router = context.getRouter();
    return this.from === null || router.location.pathname === this.from;
  }

  // eslint-disable-next-line no-unused-vars
  execute(context: Context = null): void {
    if (this.to !== null) {
      browserHistory.push(this.to);
    }
  }
}
