import React, { Fragment } from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import _ from 'lodash';
import './InputMaskField.scss';

const InputMaskField = ({
  input,
  meta: { touched, error },
  type,
  mask,
  maskChar,
  placeholder,
  extraclassName,
  disabled,
}) => {
  const iserror = touched && error && !_.isNil(error) && !_.isEmpty(error);
  const currentClassName = classnames(
    'input-mask-field-wrapper',
    iserror ? 'input-mask-error-field' : 'input-mask-field',
    extraclassName
  );
  return (
    <Fragment>
      <div className={currentClassName}>
        <InputMask
          {...input}
          type={type}
          mask={mask}
          maskChar={maskChar}
          placeholder={placeholder}
          disabled={disabled}
        />
        <div className="input-error">{iserror ? error : ''}</div>
      </div>
    </Fragment>
  );
};

export default InputMaskField;
