import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PaymentForm from './PaymentForm';
import CouponApplierForm from '../CouponApplierForm/CouponApplierForm';
import { setRegistrationStep } from 'services/utils';
import gaTrack, { GA_TR_SCHEDULING_BILLING_PAGE } from '../../../../services/gaTrack';

import './PaymentInformationForm.scss';

export const PAYMENT_FORM_NAME = 'payment-information-form';

class PaymentInformationForm extends Component {
  static defaultProps = {
    needRedirectToBilling: true,
  };

  componentDidMount() {
    if (this.props.needRedirectToBilling) {
      setRegistrationStep('billing');
    }
    gaTrack(GA_TR_SCHEDULING_BILLING_PAGE);
  }

  render() {
    const { loading, isWebinar } = this.props;

    if (loading) return null;

    return (
      <div className="registration-wizard payment-stage">
        {!isWebinar && <CouponApplierForm {...this.props} />}
        <PaymentForm {...this.props} />
      </div>
    );
  }
}

PaymentInformationForm.propTypes = {
  loading: PropTypes.bool,
  isWebinar: PropTypes.bool,
  newPrice: PropTypes.number,
  initialValues: PropTypes.object,
  needRedirectToBilling: PropTypes.bool,
  onSkip: PropTypes.func,
  toPreviousStep: PropTypes.func,
  insurance: PropTypes.object,
  companies: PropTypes.object,
};

export default PaymentInformationForm;
