// layouts
import ConnectedPublicLayout from './layouts/PublicLayout';
import ConnectedPatientsLayout from './layouts/PatientLayout';
import WebinarLayout from './layouts/WebinarLayout';
// Redirect
import RedirectFromOutside from './pages/RedirectFromOutside';
// public
import RegistrationEntryPoint from './pages/public/RegistrationEntryPoint/RegistrationEntryPoint';

import RegistrationWizardPage from './pages/public/RegistrationWizardPage/RegistrationWizardPage';
import ConnectedWebinarRegistrationWizardPage from './pages/public/RegistrationWizardPage/WebinarRegistrationWizardPage';
import ForgotPasswordPage from './pages/public/ForgotPasswordPage/ForgotPasswordPage';
import SetPasswordPage from './pages/public/SetPasswordPage/SetPasswordPage';
import PrivacyPage from './pages/public/PrivacyPage/PrivacyPage';
import ConsentToTreatmentPage from './pages/public/ConsentToTreatmentPage/ConsentToTreatmentPage';
import ReferralIframe from './pages/public/ReferralsPage/ReferralsPageIframe';
import LoginPage from './pages/public/LoginPage/LoginPage';
import SoftLoginContainer from './pages/public/Scp/SoftLogin/SoftLoginContainer';
import GrailGalleriPage from './pages/patient/GrailGalleriPage/GrailRequestTestContainer';

// patient layout
import WelcomePage from './pages/patient//WelcomePage/WelcomePage';
import QuestionnairePage from './pages/patient/QuestionnairePage/QuestionnairePage';

// Webinar
import WebinarGuidePage from './pages/webinar/WebinarGuidePage';
import SimpleWebinarPage from './pages/webinar/SimpleWebinar/SimpleWebinarPage/SimpleWebinarPage';
import ThankuYouSelectionPage from './pages/public/RegistrationWizardPage/ThankYouSelectionPage';
import SCPWaitingRoom from './pages/patient/SCP/WaitingRoom/WaitingRoom';

//SCP registraction
import SoftRegistration from './pages/public/Scp/Registration/SoftRegistrationPage';
import FullRegistration from './pages/public/Scp/Registration/FullRegistrationPage';
import SCPSchedulingWizardPage from './pages/public/Scp/RegistrationWizardPage/SchedulingWizardPage';

//Education
import EducationPage from './pages/public/Scp/Education/EducationPage';

//SCP Automated Test Request
import SCPShippingWizardPage from './pages/public/Scp/ShippingWizardPage/ShippingWizardPage';

//SCP Test Order Request Confirmation
import SCPTestOrderRequestConfirmContainer from './pages/public/Scp/TestOrderRequestConfirm/TestOrderRequestConfirmContainer';

import SCPNavigatorPage from './pages/public/Scp/NavigatorPage/NavigatorPage';
import SCPEducationModulePage from './pages/public/Scp/EducationModulePage/EducationModulePage';

//SCP Menu pages
import SCPMyGuidePage from './pages/patient/SCP/MyGuidePage/MyGuidePage';
import SCPRegistrationEntryPoint from './pages/public/Scp/RegistrationEntryPoint/RegistrationEntryPoint';
import SCPMyHealthPage from './pages/patient/SCP/MyHealthPage/MyHealthPage';
import SCPProfile from './pages/patient/SCP/ProfilePage/ProfilePage';
import SCPHome from './pages/patient/SCP/Home/Home';
import SCPReschedulePage from './pages/patient/SCP/ReschedulePage/ReschedulePage';
import OutreachAppointment from './pages/public/Scp/OutreachAppointment/OutreachAppointment';

import RedirectToSchedule from './pages/patient/RedirectToSchedule/RedirectToSchedule';
import GrailWelcomePage from './pages/public/SwitchAffiliation/GrailWelcomePage';
import SwitchAffiliation from './pages/public/SwitchAffiliation/SwitchAffiliation';
import ClinicalTrialContainer from './pages/patient/ClinicalTrialContainer/ClinicalTrialContainer';
import ClinicalConsentContainerBeforeLogin from './pages/patient/ClinicalConsentContainerBeforeLogin/index';
import TestRequestRegistrationEntryPoint from './pages/public/TestRequestRegistrationEntryPoint/TestRequestRegistrationEntryPoint';
import TestRequestWizardPage from './pages/public/TestRequestWizardPage/TestRequestWizardPage';

//Lazy loading
export default {
  // layouts
  PublicLayout: ConnectedPublicLayout,
  PatientLayout: ConnectedPatientsLayout,
  WebinarLayout,
  //
  RegistrationEntryPoint,
  RegistrationWizardPage,
  WebinarRegistrationWizardPage: ConnectedWebinarRegistrationWizardPage,
  ForgotPasswordPage,
  SetPasswordPage,
  PrivacyPage,
  ConsentToTreatmentPage,
  // patients
  WelcomePage,
  QuestionnairePage,
  RedirectFromOutside,
  ReferralIframe,
  LoginPage,
  GrailGalleriPage,
  // Webinar guide
  WebinarGuidePage,
  SimpleWebinarPage,
  ThankuYouSelectionPage,
  //SCP
  SoftRegistration,
  FullRegistration,
  SCPSchedulingWizardPage,
  SCPShippingWizardPage,
  SCPNavigatorPage,
  SCPMyGuidePage,
  SCPRegistrationEntryPoint,
  SCPEducationModulePage,
  SCPProfile,
  EducationPage,
  SCPMyHealthPage,
  SCPTestOrderRequestConfirmContainer,
  SCPHome,
  SoftLoginContainer,
  SCPReschedulePage,
  SCPWaitingRoom,
  OutreachAppointment,
  RedirectToSchedule,
  GrailWelcomePage,
  SwitchAffiliation,
  ClinicalTrialContainer,
  ClinicalConsentContainerBeforeLogin,
  TestRequestWizardPage,
  TestRequestRegistrationEntryPoint
};
