import _ from 'lodash';
import { patientPhones } from './authTransformer';
import { dehydrateAddress } from './addressTransformers';
import moment from 'moment';

export function dehydrateRegister(params) {
  return _.omitBy(
    {
      first_name: params.firstName,
      last_name: params.lastName,
      email: params.email,
      phones: params && params.primaryPhone && patientPhones(params),
      dob:
        params && params.dob && moment(params.dob, 'MM/DD/YYYY', true).isValid()
          ? moment(params.dob).format('YYYY-MM-DD')
          : params.dob,
      sex_assigned_at_birth: params.sexAssignedAtBirth,
      consent_to_treat: 'consented_via_portal',
      has_guardian: false,
      is_medicare_beneficiary: false,
      is_unborn: false,
      partner_id: params.partnerId,
      user_address: params.userAddresses && dehydrateAddresses(params.userAddresses),
      insurance: params.insurance &&
        !params.insurance.isSelfPay && [
          {
            ...dehydrateLabInsurance(params.insurance),
          },
        ],
    },
    _.isUndefined
  );
}

export function dehydrateLabInsurance(data) {
  const policyholder_dob =
    data.policyholderDob && moment(data.policyholderDob, 'MM/DD/YYYY', true).isValid()
      ? moment(data.policyholderDob).format('YYYY-MM-DD')
      : data.policyholderDob;
  return {
    is_policyholder: data.isPolicyholder || '',
    policyholder_relationship: data.policyholderRelationship || '',
    policyholder_dob: policyholder_dob,
    policyholder_name: data.policyholderName || '',
    policyholder_first_name: data.policyholderFirstName || '',
    policyholder_last_name: data.policyholderLastName || '',
    policyholder_biological_sex: data.policyholderBiolocialSex || '',
    insurance_no: data.insuranceNo || '',
    company_name: data.companyName || '',
    group_no: data.groupNo || '',
    visit_insurance: data.visitInsurance,
    lab_insurance: data.labInsurance,
    insurance_type: 'primary',
  };
}

export function dehydratePayment(data) {
  return _.omitBy(
    {
      stripe_setup_intent_id: data.stripeSetupIntent,
      use_insurance: data.useInsurance,
    },
    _.isUndefined
  );
}

export function dehydrateGeneTestOrder(data) {
  return _.omitBy(
    {
      test_uuid: data.testUUID,
      lab_uuid: data.labUUID,
      subtype: 'partner_initiated/pre_test',
      fields: data.fields,
    },
    _.isUndefined
  );
}

export const dehydrateAddresses = (data) => {
  return data.map((d) => dehydrateAddress(d));
};
