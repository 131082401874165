import api from 'services/api/api';
import usersService from 'services/api/usersService';
import schedulingService, { reasonForVisitField } from 'services/api/schedulingService';
import eligibilityService from 'services/api/eligibilityService';
import selectionService from 'services/api/selectionService';
import webinarService from 'services/api/webinarService';
import insuranceService from 'services/api/insuranceService';
import { showLoader, closeLoader } from './ui';
import authService from 'services/api/authService';
import { loadCurrentAppointment } from 'ducks/appointment';
import {
  me,
  updateUserAndPaymentMethod,
  loadInsurance,
  loadInsuranceSuccess,
  loadAllAdddressSuccess,
  loadAllAddress,
  updateHoldLoggedIn,
} from 'ducks/user';
import { clearSchedulingInfo, setDefaultStateAndTimezone } from 'ducks/scheduling';
import { loadConsetDocuments } from 'ducks/documents';
import {
  reduceSignupFormData,
  reduceSoftSignupResponseData,
  reduceEmailVerifyResponseData,
} from 'services/utils';
import { loadCurrentWebinar } from './webinar';
import { PAYMENTNOTSET } from '../constants/CommonConstants';
import documentsService from '../services/api/documentsService';
import { getShippingAddress } from 'services/utils';
import { GetStateTimezone } from 'services/geo';
import _ from 'lodash';

const GO_TO_STAGE = 'gm/registration-wizard/GO_TO_STAGE';
const SUBMIT_REQUEST = 'gm/registration-wizard/SUBMIT_REQUEST';
const SUBMIT_SUCCESS = 'gm/registration-wizard/SUBMIT_SUCCESS';
const SUBMIT_FAILURE = 'gm/registration-wizard/SUBMIT_FAILURE';
const CLEAR_FORM_ERROR = 'gm/registration-wizard/CLEAR_FORM_ERROR';
const SET_CLIENT_DATA = 'gm/registration-wizard/SET_CLIENT_DATA';
const SET_PAYMENT_BY = 'gm/registration-wizard/SET_PAYMENT)BY';
const BOOK_APPOINTMENT_TIMED_OUT_FAILURE =
  'gm/registration-wizard/BOOK_APPOINTMENT_TIMED_OUT_FAILURE';

const activeStage = schedulingService.getSchedulingStage();
const wizardData = schedulingService.getWizardData();
const clientData = wizardData ? wizardData.clientData : {};
const paymentData = wizardData ? wizardData.paymentData : {};
const initialState = {
  activeStage,
  clientData,
  paymentData,
  formError: null,
  paymentby: PAYMENTNOTSET,
  bookAppointmentTimedOutError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GO_TO_STAGE:
      return {
        ...state,
        activeStage: action.payload,
        formError: null,
      };
    case SUBMIT_REQUEST:
    case SUBMIT_SUCCESS:
    case CLEAR_FORM_ERROR:
      return {
        ...state,
        formError: null,
      };
    case SUBMIT_FAILURE:
      return {
        ...state,
        formError: action.payload,
        paymentData: {},
      };
    case BOOK_APPOINTMENT_TIMED_OUT_FAILURE:
      return {
        ...state,
        bookAppointmentTimedOutError: action.payload,
      };
    case SET_CLIENT_DATA:
      return {
        ...state,
        clientData: action.payload,
      };
    case SET_PAYMENT_BY:
      return {
        ...state,
        paymentby: action.payload,
      };
    default:
      return state;
  }
}

export function setSubmitRequest() {
  return { type: SUBMIT_REQUEST };
}

export function setSubmitSuccess() {
  return { type: SUBMIT_SUCCESS };
}

export function clearFormError() {
  return { type: CLEAR_FORM_ERROR };
}

export function setSubmitFailure(errorMessage) {
  return { type: SUBMIT_FAILURE, payload: errorMessage };
}

export function setBookAppointmentTimedOutFailure(errorMessage) {
  return { type: BOOK_APPOINTMENT_TIMED_OUT_FAILURE, payload: errorMessage };
}

export function setClientData(data) {
  return { type: SET_CLIENT_DATA, payload: data };
}

export function setStage(stage) {
  return { type: GO_TO_STAGE, payload: stage };
}

export function setPaymentby(data) {
  return { type: SET_PAYMENT_BY, payload: data };
}

export function verifyEligibility(referral, values, dispatch) {
  dispatch(setSubmitRequest());
  return eligibilityService
    .checkEligibility(referral, { ...values })
    .then(response => {
      const { eligibility, eligibility_token, additionalData } = response;
      dispatch(setSubmitSuccess());
      if (eligibility) {
        eligibilityService.saveVerificationData(eligibility_token, additionalData);
      }
      return Promise.resolve({ ...response, action: 'redirect_to_register' });
    })
    .catch(error => {
      if (referral == 'Illumina') {
        if (
          _.get(error, ['rawFields', 'relationship', '_required'], '') ==
          'This employee has been already registered with the same relationship'
        ) {
          return Promise.resolve({ action: 'redirect_to_login' });
        }
        dispatch(setSubmitFailure(error));
        return Promise.resolve({ action: 'nothing_to_do' });
      }
      if (error.submissionError) {
        throw error.submissionError;
      }
      dispatch(setSubmitFailure(error));
      throw error;
    });
}

export function verifySelection(referral, values, dispatch) {
  dispatch(setSubmitRequest());
  return selectionService
    .checkSelection(referral, { ...values })
    .then(response => {
      const { selection, selection_token } = response;
      const additionalData = { ...values };
      dispatch(setSubmitSuccess());
      if (selection) {
        selectionService.saveVerificationData(selection_token, additionalData);
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      if (error.submissionError) {
        throw error.submissionError;
      }
      dispatch(setSubmitFailure(error));
      throw error;
    });
}

export function submitAccount(values, dispatch, istokenstored = true, isconsentDownload = false) {
  dispatch(setSubmitRequest());
  if (isconsentDownload === true && istokenstored === true) dispatch(updateHoldLoggedIn(true));
  return authService
    .register({ ...values, timezone: 'America/Los_Angeles' }, istokenstored)
    .then(() => {
      const data = reduceSignupFormData(values);
      schedulingService.setWizardClientData(data);
      schedulingService.setReasonForVisit(data.areaInterest);
      dispatch(setClientData(data));
    })
    .then(() => dispatch(clearSchedulingInfo()))
    .then(
      () =>
        isconsentDownload === true && istokenstored === true
          ? dispatch(loadConsetDocuments(0))
          : Promise.resolve()
    )
    .then(() => (istokenstored === true ? dispatch(me()) : Promise.resolve()))
    .catch(error => {
      dispatch(updateHoldLoggedIn(false));
      if (error.submissionError) {
        throw error.submissionError;
      }
      dispatch(setSubmitFailure(error.message));
      throw error;
    });
}

export function saveSoftAccount(values, dispatch) {
  dispatch(setSubmitRequest());
  return authService
    .softRegister(values)
    .then(res => {
      dispatch(setSubmitSuccess());
      const data = reduceSoftSignupResponseData(res);
      return data;
    })
    .catch(error => {
      if (
        error &&
        error.status == 405 &&
        (error.code == 'eligibility_required' || error.code == 'not_allowed')
      ) {
        throw error;
      }
      if (error.submissionError) {
        throw error.submissionError;
      }
      dispatch(setSubmitFailure(error.message));
      throw error;
    });
}

export function updateSoftAccount(uuid, values, dispatch) {
  dispatch(setSubmitRequest());
  return authService
    .updateSoftRegister(uuid, values)
    .then(res => {
      dispatch(setSubmitSuccess());
      return reduceSoftSignupResponseData(res);
    })
    .catch(error => {
      if (error.submissionError) {
        throw error.submissionError;
      }
      dispatch(setSubmitFailure(error.message));
      throw error;
    });
}

export function verifyEmail(uuid, values, dispatch) {
  dispatch(setSubmitRequest());
  return authService
    .verifyEmail(uuid, values)
    .then(res => {
      dispatch(setSubmitSuccess());
      const data = reduceEmailVerifyResponseData(res);
      return data;
    })
    .catch(error => {
      if (error.submissionError) {
        throw error.submissionError;
      }
      dispatch(setSubmitFailure(error.message));
      throw error;
    });
}

export function resendCode(uuid, dispatch) {
  dispatch(setSubmitRequest());
  return authService
    .resendCode(uuid)
    .then(res => {
      dispatch(setSubmitSuccess());
      return res;
    })
    .catch(error => {
      dispatch(setSubmitFailure(error.message));
      throw error;
    });
}

export function submitClientInformation(values, dispatch) {
  dispatch(setSubmitRequest());
  if (values[reasonForVisitField]) {
    schedulingService.setReasonForVisit(
      values[reasonForVisitField] && values[reasonForVisitField].label
    );
  }
  return usersService
    .updateMyProfile(values)
    .then(() => {
      schedulingService.setWizardClientData(values);
      dispatch(setClientData(values));
    })
    .catch(error => {
      if (error.submissionError) {
        throw error.submissionError;
      }
      dispatch(setSubmitFailure(error.fullMessage));
      throw error;
    });
}

export function submitInsurance(values) {
  return dispatch => {
    dispatch(setSubmitRequest());
    return insuranceService
      .updateInsurance(values)
      .then(() => {
        dispatch(loadInsuranceSuccess(values));
        return dispatch(loadInsurance());
      })
      .catch(error => {
        dispatch(setSubmitFailure(error.fullMessage));
        if (error.submissionError) throw error.submissionError;
        throw error;
      });
  };
}

export function createAddress(values, dispatch) {
  dispatch(showLoader());
  dispatch(setSubmitRequest());
  return usersService
    .createMyAddress(values)
    .then(data => {
      dispatch(loadAllAdddressSuccess(data));
      dispatch(loadAllAddress());
      dispatch(closeLoader());
      return data;
    })
    .catch(error => {
      dispatch(closeLoader());
      if (error.submissionError) throw error.submissionError;
      dispatch(setSubmitFailure(error.fullMessage));
      throw error;
    });
}

export function updateAddress(values, dispatch) {
  dispatch(showLoader());
  dispatch(setSubmitRequest());
  return usersService
    .updateMyAddress(values)
    .then(data => {
      dispatch(loadAllAdddressSuccess(data));
      dispatch(loadAllAddress());
      dispatch(closeLoader());
      return data;
    })
    .catch(error => {
      dispatch(closeLoader());
      if (error.submissionError) throw error.submissionError;
      dispatch(setSubmitFailure(error.fullMessage));
      throw error;
    });
}

export function submitPaymentInfo(values, dispatch) {
  dispatch(showLoader());
  dispatch(setSubmitRequest());
  return usersService
    .updateMyPaymentMethod(values)
    .then(() => dispatch(updateUserAndPaymentMethod()))
    .then(() => dispatch(closeLoader()))
    .catch(error => {
      dispatch(closeLoader());
      if (error.submissionError) throw error.submissionError;
      dispatch(setSubmitFailure(error.fullMessage));
      throw error;
    });
}

export function bookAppointment(values) {
  return (dispatch, getState) => {
    const {
      selectedServiceDescriptor,
      selectedProduct,
      calendarProvider,
      calendarModality,
      reservedAppointment
    } = getState().scheduling;
    dispatch(showLoader());
    return schedulingService
      .bookAppointment(
        selectedServiceDescriptor.isPackage && selectedProduct.id,
        values,
        _.get(reservedAppointment,'provider',calendarProvider),
        calendarModality
      )
      .then(() => {
        dispatch(setSubmitSuccess());
        if (api.hasToken()) {
          // update current appointment if user is logged in
          dispatch(loadCurrentAppointment()).then(() => {
            schedulingService.clearRegistrationData();
            schedulingService.clearReservationTime();
            authService.resetPreselectedService();
          });
        }
        return dispatch(closeLoader());
      })
      .catch(error => {
        dispatch(closeLoader());

        if(error.fullMessage) {
          dispatch(setSubmitFailure(error.fullMessage));
        } else if (!error.response) {
          dispatch(setBookAppointmentTimedOutFailure(error));
        }
        throw error;
      });
  };
}

export const bookWebinar = id => dispatch => {
  return webinarService
    .bookWebinar(id)
    .then(() => dispatch(loadCurrentWebinar()))
    .then(() => {
      dispatch(setSubmitSuccess());
      return dispatch(closeLoader());
    })
    .catch(error => {
      dispatch(closeLoader());
      dispatch(setSubmitFailure(error.fullMessage));
      throw error;
    });
};

export const submitAuditLogs = values => {
  return dispatch => {
    dispatch(showLoader());
    return documentsService
      .submitauditLogs(values)
      .then(() => {
        dispatch(closeLoader());
      })
      .catch(() => {
        dispatch(closeLoader());
      });
  };
};
