import appointmentsService from 'services/api/appointmentsService';
import { loadCurrentAppointment } from 'ducks/appointment';

const LOAD_FAILURE = `gm/switch_to_phone/LOAD_FAILURE`;
const RESET_ERROR = `gm/switch_to_phone/RESET_ERROR`;

export default (state = { error: null }, action = {}) => {
  switch (action.type) {
    case RESET_ERROR:
      return {
        error: null,
      };

    case LOAD_FAILURE:
      return {
        error: action.payload,
      };

    default:
      return state;
  }
};

//not in use
export const switchToPhone = id => dispatch => {
  return appointmentsService
    .switchToPhone(id)
    .then(() => dispatch(loadCurrentAppointment()))
    .catch(error => dispatch({ type: LOAD_FAILURE, payload: error.fullMessage || error.message }));
};

export const switchModality = (id, type) => dispatch => {
  return appointmentsService
    .switchModality(id, { type })
    .then(() => dispatch(loadCurrentAppointment()))
    .catch(error => dispatch({ type: LOAD_FAILURE, payload: error.fullMessage || error.message }));
};

export const resetSwitchToFormError = () => dispatch => dispatch({ type: RESET_ERROR });
