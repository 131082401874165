import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import CustomMaskedInput from './CustomMaskedInput';

const CommonField = ({
  className,
  horisontal,
  input,
  placeholder,
  label,
  type,
  mask,
  meta: { touched, error },
  sensitive,
  disabled,
  labelClass,
}) => (
  <div className={classnames('gm-form-field', className, { horisontal, error: touched && error })}>
    <label className={classnames('gm-form-label', labelClass, { unstyled: horisontal })}>
      {(!horisontal && touched && error) || label}
    </label>
    <div className={classnames('gm-form-control', type)}>
      {type === 'textarea' && (
        <textarea
          {...input}
          className={classnames(input.className, { 'mp-no-track mp-sensitive': sensitive })}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
      {type !== 'textarea' &&
        (mask ? (
          <CustomMaskedInput
            {...input}
            placeholder={placeholder}
            type={type}
            mask={mask}
            disabled={disabled}
            className={classnames(input.className, { 'mp-no-track mp-sensitive': sensitive })}
          />
        ) : (
          <input
            {...input}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            className={classnames(input.className, { 'mp-no-track mp-sensitive': sensitive })}
          />
        ))}
      {horisontal &&
        touched &&
        error && <label className="gm-form-label label-bottom">{error}</label>}
    </div>
  </div>
);

CommonField.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  horisontal: PropTypes.bool,
  sensitive: PropTypes.bool,
};

export default CommonField;
