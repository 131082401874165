import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import classnames from 'classnames';
import StepItem from './StepItem';
import './StepsPanel.scss';

class StepsPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.array,
    activeStepIndex: PropTypes.number,
    showAll: PropTypes.bool,
    onClickHandler: PropTypes.func,
  };

  render() {
    const { showAll, onClickHandler, className } = this.props;
    return (
      <div className={classnames('scp-stepspanel-container', className)}>
        <div className="steps-container">
          {this.props.children.map(
            (item, i) =>
              !showAll && !item.done ? null : (
                <StepItem
                  key={i}
                  numStep={i}
                  done={item.done}
                  active={i == this.props.activeStepIndex}
                  header={item.header}
                  onClickHandler={onClickHandler}
                  icon={get(item, 'icon', '')}
                />
              )
          )}
        </div>
      </div>
    );
  }
}

export default StepsPanel;
