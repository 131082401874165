import React from 'react';
import PropTypes from 'prop-types';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import upcomingAppt from './upcoming_appt.png';
import './UpcomingAppointment.scss';

export default function UpcomingAppointment(props) {
  return (
    <div className="upcoming-appointment">
      <div className="genome-logo" />
      <div className="grid">
        <div className="left">
          <p className="head">You Have An Upcoming Appointment</p>
          <p style={{ marginBottom: '24px' }}>
            Welcome back to Genome Medical!<br />
            <br />It looks like you have an upcoming genetic counseling appointment with us. If you
            are interested in learning about this type of testing, we recommend asking for more
            information at the time of your appointment, rather than continuing on this digital
            pathway.
          </p>
          <p className="title small" style={{ marginBottom: '10px' }}>
            Why?
          </p>
          <ul>
            <li>
              Your genetic counselor can make a customized set of testing recommendations based on
              your specific medical concerns
            </li>
            <li>
              Our care coordination team can more easily ensure that all of your tests can be
              ordered and processed
            </li>
          </ul>
        </div>
        <div className="right">
          <img src={upcomingAppt} alt="full-registration" />
        </div>
      </div>
      <div className="action-items">
        <ButtonV2 onClick={props.viewAppointment}>I Would Like To Keep My Appointment</ButtonV2>
        <div className="cancel-appointment">
          I’d rather{' '}
          <a href="javascript:void(0)" onClick={props.cancelAppointment}>
            cancel my appointment
          </a>{' '}
          and proceed with the questionnaire
        </div>
      </div>
    </div>
  );
}
UpcomingAppointment.propTypes = {
  viewAppointment: PropTypes.func.isRequired,
  cancelAppointment: PropTypes.func.isRequired,
};
