import React, { Component } from 'react';
import { connect } from 'react-redux';
import authService from 'services/api/authService';
import GrailQualificationContainer from 'containers/pages/public/GrailQualificationContainer/GrailQualificationContainer';
import SwitchAffiliation from './pages/public/SwitchAffiliation/SwitchAffiliation';
import _ from 'lodash';
import GrailStatusEnums from '../constants/GrailStatus';
import ClinicalTrialContainer from './pages/public/ClinicalTrialContainer/ClinicalTrialContainer';
import { MANUAL_CONSENT_OUTREACH } from '../ducks/misc/redirectActions';
import { ConsentStatus } from '../constants/CommonConstants';

export class AfterLoginGuard extends Component {
  doForceUpdate() {
    this.forceUpdate();
  }

  render() {
    const { children, _currentUser, redirectActions } = this.props;
    const isManualOutreach = redirectActions.actions[MANUAL_CONSENT_OUTREACH];
    const userConsent = _.get(
      _currentUser,
      ['latestPatientToReconatctStatusObject', 'user_consent'],
      null
    );
    const userConsentStatus = _.get(userConsent, ['status']);
    if (
      _currentUser &&
      (_.isNil(userConsent) || (isManualOutreach && userConsentStatus == ConsentStatus.SKIPPED))
    ) {
      return <ClinicalTrialContainer />;
    }
    if (
      _currentUser &&
      !_currentUser.affiliationIsIllumina &&
      !_.isNil(_currentUser.grailStatus) &&
      _currentUser.grailStatus == GrailStatusEnums.QUALIFICATION_REQUIRED
    ) {
      return <GrailQualificationContainer />;
    } else if (
      _currentUser &&
      !_.isNil(_currentUser.grailStatus) &&
      _currentUser.grailStatus == GrailStatusEnums.SWITCH_AFFILIATION
    ) {
      return <SwitchAffiliation />;
    }
    return <div>{children}</div>;
  }
}

export default connect(
  (state) => {
    const _currentUser = authService.getCurrentUser(state);
    return { _currentUser, redirectActions: state.redirectActions };
  },
  (dispatch) => {
    return { dispatch };
  }
)(AfterLoginGuard);
