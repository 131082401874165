import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import ReCaptcha from 'components/forms/controls/ReCaptcha';
import warn from '../PasswordStrength/warn';
import Alert from 'components/widgets/Alert/Alert';
import { required } from 'services/validate';
import './ResendPasswordLinkForm.scss';
import ButtonV2 from '../controls/ButtonV2';
import emailIcon from './email.svg';

class ResendPasswordLinkForm extends Component {
  renderResendSetPassword() {
    const { valid } = this.props;
    return this.props.emailIsResend ? (
      <div>
        <span className="reset-title">Almost Done</span>
        <div className="reset-desc">
          Please check your inbox for an email with instructions to set the password.
        </div>
        <div className="reset-icon">
          <img src={emailIcon} alt="Envelope" />
        </div>
      </div>
    ) : (
      <div>
        <span className="reset-title">
          {this.props.header ? this.props.header : 'Set Password Link Expired'}
        </span>
        <div className="reset-desc">
          You are trying to use an expired link. Please request a new one below to access your new
          Genome Medical account.
        </div>
        <Field
          className="captcha-field"
          name="captcha"
          component={ReCaptcha}
          label="Security challenge"
          horisontal={true}
          validate={[required()]}
        />
        <div className="outer-button submit-button">
          <ButtonV2
            disabled={!valid}
            className="submit-button"
            type="submit"
            onClick={this.props.handleSubmit}>
            Request New Link
          </ButtonV2>
        </div>
      </div>
    );
  }

  render() {
    return (
      <form className="resend-link-form" autoComplete="off">
        <div className="form-wrapper">
          <i className="genome-logo" />
          {this.renderResendSetPassword()}
          <Alert message={this.props.formError} />
        </div>
      </form>
    );
  }
}

ResendPasswordLinkForm.propTypes = {
  emailValue: PropTypes.string,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  emailIsResend: PropTypes.bool,
};

export default reduxForm({
  form: 'ResendPasswordLinkForm',
  warn,
})(ResendPasswordLinkForm);
