import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './CustomComboboxwithHelp.scss';
class CustomCombobox extends Component {
  handleChange = ({ value }) => {
    this.props.input.onChange(value);
  };

  renderOptions = props => {
    return (
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <span style={{ float: 'left', width: '95%' }}>{props.label}</span>
        {props.description != '' && (
          <span style={{ float: 'right', marginTop: '2px' }}>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`specialty-tooltip-top` + props.value}>
                  <div
                    style={{
                      padding: '5px',
                      textAlign: 'left',
                      fontSize: '12px',
                      fontColor: '#FFF',
                    }}>
                    {props.description}
                  </div>
                </Tooltip>
              }>
              <span className="radio-button-description">
                <span variant="secondary">
                  <span className="info-outline-icon" />
                </span>
              </span>
            </OverlayTrigger>
          </span>
        )}
      </div>
    );
  };

  render() {
    const {
      items,
      placeholder,
      className,
      label,
      disabled,
      input: { value },
      meta: { touched, error },
    } = this.props;

    const options = items.map(o => (o.label ? o : { value: o.value, label: o.value }));
    return (
      <div className={classnames('gm-form-field', className, { error: touched && error })}>
        <label className="gm-form-label">{(touched && error) || label}</label>
        <div className="gm-form-control">
          <Select
            className="gm-select"
            value={value}
            options={options}
            onChange={this.handleChange}
            placeholder={placeholder}
            clearable={false}
            disabled={disabled}
            backspaceRemoves={true}
            matchPos="start"
            optionRenderer={this.renderOptions}
          />
        </div>
      </div>
    );
  }
}

CustomCombobox.propTypes = {
  items: PropTypes.array,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CustomCombobox;
