import api from './api';
import type { PartnerSurveyTree } from './zingtreeApi';

class ZingtreeService {
  getPartnerSurveyTree(partner_id: Number, tree_type: String): Promise<Array<PartnerSurveyTree>> {
    return api.zingtree.getPartnerSurveyTree(partner_id, tree_type);
  }
}

export default new ZingtreeService();
