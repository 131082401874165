//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getDb from 'services/filesDb';
import DropzoneField from 'components/forms/documents/Dropzone/DropzoneField';
import base64 from 'base64-js';

export default class FilesField extends Component {
  static propTypes = {
    input: PropTypes.object,
  };

  constructor(props: Object) {
    super(props);
    this.state = {
      uploading: false,
      uploadingProgress: [],
      extraFiles: [],
    };
  }

  state: {
    uploading: boolean,
    uploadingProgress: number[],
    extraFiles: Object[],
  };

  componentDidMount() {
    this.getExtraFiles(this.updateExtraFiles);
  }

  getBase64Promise(file: Object) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = evt => {
        resolve(base64.fromByteArray(new Uint8Array(evt.target.result)));
      };
      reader.readAsArrayBuffer(file);
    });
  }

  updateExtraFiles = (extraFiles, flushUploading = false) => {
    if (flushUploading) {
      this.setState({ extraFiles, uploading: false });
    } else {
      this.setState({ extraFiles });
    }
    if (this.props.onFilesChanged) this.props.onFilesChanged(extraFiles);
  };

  onFilesAdd = (files: File[]) => {
    const data = [];
    const promises = [];
    const filesDb = getDb();
    for (let index = 0; index < files.length; index++) {
      promises.push(this.getBase64Promise(files[index]).then(file => data.push(file)));
    }
    Promise.all(promises).then(() => {
      const { input: { value, onChange } } = this.props;
      this.setState({ uploading: true, uploadingProgress: new Array(files.length).fill(0) });
      let values = value ? value.split(',') : [];
      filesDb
        .transaction(
          'rw',
          filesDb.files,
          function*() {
            for (let index = 0; index < files.length; index++) {
              let file = files[index];
              let fileId = yield filesDb.files.add({
                name: file.name,
                extension: '',
                createdAt: new Date(),
                size: file.size,
                file: data[index],
              });
              file.id = fileId;
              file.error = this.getFileError(file);
              let uploadingProgress = [...this.state.uploadingProgress];
              uploadingProgress[index] = 100;
              values.push(fileId);
              onChange(values.join(','));
              this.setState({ uploadingProgress });
            }
          }.bind(this)
        )
        .then(() => {
          this.updateExtraFiles(this.state.extraFiles.concat(files), true);
        });
    });
  };

  getFileLink = (fileId: string) => {
    return '#' + fileId;
  };

  getFileError = file => {
    return file.size > 1.8 * 1024 * 1024 ? 'Maximum file size limit (1.8 Mb) exceeded.' : null;
  };

  onFileRemove = (file: File) => {
    const filesDb = getDb();
    filesDb.transaction('rw', filesDb.files, () =>
      filesDb.files
        .where('id')
        .equals(file.id)
        .delete()
        .then(() => {
          let fileIds = this.props.input.value.split(',').map(Number);
          fileIds = fileIds.filter(fileId => fileId !== file.id);
          let fileIdsValue = fileIds.join(',');
          this.props.input.onChange(fileIdsValue);
          this.getExtraFiles(this.updateExtraFiles);
        })
    );
  };

  getExtraFiles(callback: Function) {
    const filesDb = getDb();
    filesDb
      .transaction('r', filesDb.files, () =>
        filesDb.files.toArray(files => {
          return files.map(file => ({
            id: file.id,
            name: file.name,
            extension: file.extension,
            createdAt: file.createdAt,
            size: file.size,
            error: this.getFileError(file),
          }));
        })
      )
      .then(callback);
  }

  render() {
    const { uploading, uploadingProgress, extraFiles } = this.state;

    return (
      <DropzoneField
        dropzoneOnDrop={this.onFilesAdd}
        multiple={true}
        getFileLink={this.getFileLink}
        onRemove={this.onFileRemove}
        extraFiles={extraFiles}
        uploadingStatus={{
          uploading,
          uploadingProgress,
          uploadingResult: true,
          removingFiles: [],
        }}
      />
    );
  }
}
