import PropTypes from 'prop-types';
import React from 'react';
import './SoftRegistrationPageHeader.scss';

export default function SoftRegistrationPageHeader({ title, children }) {
  return (
    <section className="soft-rgstrtion-instrtion-wrapper">
      {title && <h4>{title}</h4>}
      <div className="soft-rgstrtion-instrtion-container">
        <div className="genome-logo-wrapper">
          <i className="genome-logo" />
        </div>
        {children}
      </div>
    </section>
  );
}

SoftRegistrationPageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
