import React, { Component } from 'react';
import { connect } from 'react-redux';

import GrailGalleri from './GrailGalleri.js';
import GrailStatusEnums from 'constants/GrailStatus';
import GrailQualificationContainer from 'containers/pages/public/GrailQualificationContainer/GrailQualificationContainer';

class GrailRequestTestContainer extends Component {
  render() {
    const { user: { me: { grailStatus } } } = this.props;
    return (
      <div className="grail-galleri-container">
        {grailStatus == GrailStatusEnums.QUALIFICATION_REQUIRED && <GrailQualificationContainer />}
        {grailStatus == GrailStatusEnums.SHOW_CARD && <GrailGalleri />}
      </div>
    );
  }
}

export default connect(({ user }) => ({
  user,
}))(GrailRequestTestContainer);
