import { hydrateAppointmentNano } from './appointmentTransformers';
import { hydrateEncounter } from './encounterTransformers';
export const hydrateDocumentTypes = data => {
  return data.map(type => ({
    id: type.id,
    name: type.name,
  }));
};

export const hydrateDocument = data => {
  return {
    id: data.id,
    source: data.document_source && {
      id: data.document_source.id,
      name: data.document_source.name,
    },
    type: data.document_type && {
      id: data.document_type.id,
      name: data.document_type.name,
    },
    subType: data.document_subtype || null,
    creator: data.creator && {
      id: data.creator.id,
      uuid: data.creator.uuid,
    },
    documentState: data.document_state && {
      id: data.document_state.id,
      name: data.document_state.name,
    },
    isArchived: data.document_state && data.document_state.name == 'Archived',
    relatedEncounter: data.encounter && {
      consultation: data.encounter.consultation,
      DOS: data.encounter.date_of_service,
      provider: data.encounter.provider,
      type: data.encounter.type,
      displayConsultation:
        data.encounter.type == 'GCN' ? 'Genome Care Navigation' : data.encounter.consultation,
    },
    appointment: data.appointment && hydrateAppointmentNano(data.appointment),
    scpEcnounter:
      data.scp_encounter_document &&
      data.scp_encounter_document.scp_encounter &&
      hydrateEncounter(data.scp_encounter_document.scp_encounter),
    fileName: data.file_name,
    fileExtension: data.file_extension,
    fileSize: data.size,
    description: data.description,
    patientNotes: data.patientNotes,
    providerNotes: data.providerNotes,
    createdAt: data.created_at && new Date(data.created_at),
    modifiedAt: data.modified_at && new Date(data.modified_at),
    isReleased: data.is_released,
    isMalicious: data.is_malicious,
  };
};

export const hydrateDocuments = data => data.map(d => hydrateDocument(d));
