import servicePackagesService from 'services/api/servicePackagesService';

const LOAD_START = `gm/userServicePackages/LOAD_START`;
const LOAD_COMPLETE = `gm/userServicePackages/LOAD_COMPLETE`;
const LOAD_FAILURE = `gm/userServicePackages/LOAD_FAILURE`;

export default (state = { data: [], error: null, loading: false, loaded: false }, action = {}) => {
  switch (action.type) {
    case LOAD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LOAD_COMPLETE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case LOAD_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        loaded: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getPurchasedPackages = () => (dispatch, getState) => {
  if (getState().userServicePackages.loading) return Promise.resolve();
  dispatch({ type: LOAD_START });
  return servicePackagesService
    .getPurchasedPackages()
    .then(visits => dispatch({ type: LOAD_COMPLETE, payload: visits }))
    .catch(error => dispatch({ type: LOAD_FAILURE, payload: error.fullMessage || error.message }));
};
