import React, { useEffect } from 'react';

const ClinicalTrialConsentForm = (props) => {
  const { formId, onSubmit, url } = props;

  const iframeRef = React.useRef();

  useEffect(() => {
    const handleMessages = (content) => {
      if (!content) {
        return;
      }
      // Check if submission is completed.
      if (typeof content.data === 'object' && content.data.action === 'submission-completed') {
        onSubmit();
        window.removeEventListener('message', handleMessages, true);
        return;
      }

      // From now on we only handle style related messages
      if (typeof content.data !== 'string') {
        return;
      }
      const [method, value, targetForm] = content.data.split(':');
      switch (true) {
        case method === 'scrollIntoView':
          if (typeof iframeRef.current.scrollIntoView === 'function') {
            iframeRef.current.scrollIntoView();
          }
          break;
        default:
          break;
      }
    };

    if (window.addEventListener) {
      window.addEventListener('message', handleMessages, true); // Capture
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', handleMessages);
    }
    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', handleMessages, true);
      }
    };
  }, []);

  return (
    <iframe
      id={`iframe_${formId}`}
      title="JotForm Form"
      src={url}
      allowTransparency="true"
      allowFullScreen="true"
      allow="geolocation; microphone; camera"
      frameBorder="0"
      ref={iframeRef}
      className="iframe_class"
    />
  );
};

export default ClinicalTrialConsentForm;
