import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FullRegistrationForm from './FullRegistrationForm';
import './FullRegistrationPage.scss';
import {
  getReferralSource,
  getWarmReferralSource,
  getWarmReferralURL,
  clearWarmReferralSource,
  clearWarmReferralURL,
} from 'services/misc/ReferralSource';
import { showLoader, closeLoader } from 'ducks/ui';
import { submitAccount } from 'ducks/registrationWizard';
import { loadCurrentSCPEncounter } from '../../../../../ducks/scpencounters';
import authService from 'services/api/authService';
import api from 'services/api/api';
import _ from 'lodash';
import FullRegIllustration from './full-registration.png';
import gaTrack, { GA_TR_FULLREGISTRATION_PAGE } from 'services/gaTrack';

class FullRegistrationPage extends Component {
  componentDidMount() {
    gaTrack(GA_TR_FULLREGISTRATION_PAGE);
    const { uuid } = authService.getSCPPreRegistrationData();
    if (_.isEmpty(uuid)) {
      this.props.gotoLogin();
    }
  }
  submitAccountInformation = values => {
    const {
      dispatch,
      gotoShippingWizardPage,
      gotoSCPHomePage,
      gotoShedulingWizardPage,
    } = this.props;
    const { uuid } = authService.getSCPPreRegistrationData();
    values = {
      ...values,
      referral: getReferralSource(),
      consentToTreat: 'consented_via_portal',
      warm_referral_src: getWarmReferralSource(),
      warm_referral_url: getWarmReferralURL(),
      soft_registration_uuid: uuid,
    };
    dispatch(showLoader());
    return submitAccount(values, dispatch)
      .then(() => {
        clearWarmReferralSource();
        clearWarmReferralURL();
        authService.resetSCPPreRegistrationData();
        api.clearEducationSurvey();
        dispatch(loadCurrentSCPEncounter())
          .then(data => {
            if (!_.isNil(data) && data.needConsent) {
              gotoShippingWizardPage();
            } else if (!_.isNil(data) && data.isAppointmentSubType) {
              gotoShedulingWizardPage();
            } else {
              gotoSCPHomePage();
            }
            dispatch(closeLoader());
          })
          .catch(() => {
            dispatch(closeLoader());
          });
      })
      .catch(error => {
        dispatch(closeLoader());
        throw error;
      });
  };

  render() {
    const { formError } = this.props.registrationWizard;
    const { email, firstName, lastName, phone } = authService.getSCPPreRegistrationData();
    return (
      <div className="scp-full-registration-container">
        <div className="full-registration-wrapper">
          <section className="full-registration-instruction-wrapper">
            <div className="full-registration-instruction-container">
              <div className="genome-logo-wrapper">
                <i className="genome-logo" />
              </div>
              <div className="instruction-section" />
              <div className="instruction-section instruction-section-2" />
            </div>
          </section>
          <section className="full-registration-body">
            <div className="intro-video-wrapper">
              <img src={FullRegIllustration} alt="full-registration" className="intro-image" />
            </div>
            <div className="form-container">
              <i className="group-logo" />
              <h1>Here’s the final step</h1>
              <span className="rgstrtion-desc">
                Below, you will set up your Genome Medical account. This will give you access to our
                secure patient portal, which allows you to view any upcoming appointments or
                documentation, including a summary of the information you’ve just viewed.
              </span>
              <FullRegistrationForm
                initialValues={{
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  primaryPhone: phone,
                  agree: true,
                }}
                onSubmit={this.submitAccountInformation}
                formError={formError}
              />
            </div>
          </section>{' '}
          {/* full-registration-body */}
        </div>
        {/*full-registration-wrapper*/}
      </div>
    );
  }
}

FullRegistrationPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gotoShedulingWizardPage: PropTypes.func.isRequired,
  gotoSCPHomePage: PropTypes.func.isRequired,
  gotoLogin: PropTypes.func.isRequired,
  gotoShippingWizardPage: PropTypes.func.isRequired,
  registrationWizard: PropTypes.object,
};

const mapStateToProps = ({ registrationWizard }) => ({
  registrationWizard,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { registrationWizard } = stateProps;
  const { dispatch } = dispatchProps;
  const location = ownProps.location;
  return {
    dispatch,
    registrationWizard,
    location,
    gotoShedulingWizardPage: () => browserHistory.push(`/scheduling/select-service`),
    gotoSCPHomePage: () => browserHistory.push(`/patient/home`),
    gotoLogin: () => browserHistory.push(`/`),
    gotoShippingWizardPage: () => browserHistory.push(`/patient/testrequest/address`),
  };
};

export default connect(mapStateToProps, null, mergeProps)(withRouter(FullRegistrationPage));
