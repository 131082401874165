import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Footer from 'components/scp/footer/Footer';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import ReleaseNotice from '../../components/widgets/SCP/ReleaseVersionNotice/ReleaseNotice';

export class SCPEducationLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className="main-container main-education-container scp">
        <div className="main-navigator-wrapper">
          {/* <Header /> */}
          <ReleaseNotice />
          {this.props.children}
        </div>
        <Footer />
        <GlobalLoader />
      </div>
    );
  }
}

export default SCPEducationLayout;
