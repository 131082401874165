import Context from '../Context';
import RedirectAction from './RedirectAction';
import { isWebinar } from 'containers/layouts/LayoutProps';

export default class RedirectToDashboard extends RedirectAction {
  constructor() {
    super(null, '/patient');
  }

  canExecute(context: Context = null): boolean {
    const { state, layoutType } = context.getParams();
    const { me } = state.user;
    return me && me.confirmed && !isWebinar(state) && layoutType === 'webinar';
  }
}
