import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateProgressPercentage, clearProgressPercentage } from 'ducks/zingtree';
import { isProductionOrStaging } from 'services/utils';

class ZingTreeShell extends Component {
  constructor(props) {
    super(props);
    this.iFrameMessageHandler = this.iFrameMessageHandler.bind(this);
  }

  componentDidMount() {
    if (!window.iFrameResize) {
      const zingtreeScript = document.createElement('script');
      const url = 'https://zingtree.com/js/iframeResizerSmart.js';
      zingtreeScript.setAttribute('src', url);
      zingtreeScript.onload = () => window.iFrameResize();
      document.body.appendChild(zingtreeScript);
    } else {
      window.iFrameResize();
    }
    this.notifyListenerSetUp();
  }

  componentWillUnmount() {
    this.notifyListenerSetUp(false);
    this.props.clearProgressPercentage();
  }

  postmessageToIFrame = () => {
    if (!isProductionOrStaging()) {
      const iframe = document.getElementById('zt-frame');
      const initialSessionData = _.get(this.props, ['initialSessionData'], {});
      const sessoinData = {
        event: 'var-change',
        zingtree_session: this.props.session_id,
        data: {
          env: process.env.GM_ENV,
          ...initialSessionData,
        },
      };
      iframe.contentWindow.postMessage(JSON.stringify(sessoinData), '*');
    }
  };

  notifyListenerSetUp = (register = true) => {
    if (register) {
      window.addEventListener('message', this.iFrameMessageHandler);
    } else {
      window.removeEventListener('message', this.iFrameMessageHandler);
    }
  };

  iFrameMessageHandler = e => {
    if (e.origin && e.origin == 'https://zingtree.com') {
      if (_.get(e, ['data', 'resolution_state'])) {
        const data = e.data;
        if (data.resolution_state == 'Y' && this.props.onResultNodeReach) {
          this.props.onResultNodeReach(data);
        } else if (data.resolution_state == 'N' && this.props.onFailureNodeReach) {
          this.props.onFailureNodeReach(data);
        }
      } else if (_.isObject(_.get(e, ['data'], null))) {
        const type = _.get(e, ['data', 'type'], null);
        const subType = _.get(e, ['data', 'subType'], null);

        if (type && type === 'gmi-custom' && subType) {
          if (subType === 'progress-bar') {
            const percentage = _.get(e, ['data', 'data', 'percentage'], null);
            this.props.updateProgressPercentage(percentage);
          } else if (subType === 'chat') {
            window.zE('webWidget', 'open');
          } else if (subType === 'scroll-top') {
            window.scrollTo({ top: 16, behavior: 'smooth' });
          }
        } else if (_.get(e, ['data', 'data', 'resolutionState'])) {
          const data = e.data.data;
          if (data.resolutionState == 'Y' && this.props.onResultNodeReach) {
            this.props.onResultNodeReach(data);
          } else if (data.resolutionState == 'N' && this.props.onFailureNodeReach) {
            this.props.onFailureNodeReach(data);
          }
        }
      }
    }
  };

  render() {
    const tree_id = this.props.tree_id;
    const session_id = this.props.session_id;
    const className = _.get(this, ['props', 'className'], '');
    const URL = `https://zingtree.com/deploy/tree.php?z=embed&tree_id=${tree_id}&session_id=${session_id}`;
    return (
      <iframe
        id="zt-frame"
        width="100%"
        frameBorder="0"
        seamless
        src={URL}
        className={className}
        onLoad={this.postmessageToIFrame}
      />
    );
  }
}
ZingTreeShell.propTypes = {
  tree_id: PropTypes.string.isRequired,
  session_id: PropTypes.string.isRequired,
  onResultNodeReach: PropTypes.func.isRequired,
  onFailureNodeReach: PropTypes.func,
  updateProgressPercentage: PropTypes.func.isRequired,
  clearProgressPercentage: PropTypes.func.isRequired,
};

export default connect(null, dispatch => ({
  updateProgressPercentage: percentage => dispatch(updateProgressPercentage(percentage)),
  clearProgressPercentage: () => dispatch(clearProgressPercentage()),
}))(ZingTreeShell);
