import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import './ConsentForm.scss';
import Button from '../../controls/ButtonV2';
import Consent from 'components/Policy/Consent';
import gaTrack, { GA_TR_FINANCIAL_RESPONSIBILITY_PAGE } from 'services/gaTrack';

class ConsentForm extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    gaTrack(GA_TR_FINANCIAL_RESPONSIBILITY_PAGE);
  }

  render() {
    const { handleSubmit, onSubmit } = this.props;
    return (
      <section className="consent-form-container">
        <section className="consent-wrapper">
          <section className="consent-header">
            <div className="genome-header-logo">
              <img className="genome-header-img" src={require('images/logo.svg')} />
            </div>
          </section>
          <section className="consent-body">
            <Consent />
            <div className="agree-container">
              <p className="web">
                {' '}
                By clicking the <span className="agree-continue">"I Agree and Continue"</span>{' '}
                button below, you confirm you have reviewed and agree to the above{' '}
                <span className="font-medium">
                  Patient Consent And Financial Responsibility Agreement
                </span>
              </p>
              <div className="buttons-containers">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="rights-buttons">
                    <a
                      className="button-back"
                      key="consent-back"
                      onClick={() => this.props.goBack()}>
                      Go Back
                    </a>
                    <Button type="submit" className="agree-button">
                      I Agree and Continue
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

ConsentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
};

export default reduxForm({
  form: 'sign-up-form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ConsentForm);
