import React from 'react';

import './Consent.scss';

const Consent = () => {
  const BulletPointClassName = "bullet-point-v2";
  return (
    <article className="consent-story">
      <h2>PATIENT CONSENT AND FINANCIAL RESPONSIBILITY AGREEMENT </h2>
      <h2 className={BulletPointClassName}> Consent for Genome Medical Services </h2>
      <p className="bullet-descrption">
        Genome Medical Services California, P.C., Genome Medical Services, P.C., and its affiliated entities
        (collectively, “Genome Medical”) offers web-based telegenetics services. This Consent and Acknowledgement
        describes your rights and responsibilities with respect to accessing and receiving healthcare services via
        telemedicine technologies. By using these services, you agree to Genome Medical’s Terms and Conditions and
        Privacy Policy. By engaging in a video consult or phone consult, you certify that you are the patient or the
        patient’s parent/legal guardian and can provide valid consent for yourself and/or the patient for whom you
        are the parent/legal guardian, that you agree to the terms of this Consent and Acknowledgement, and that you
        have received and reviewed the Genome Medical Services, P.C. Notice of Privacy Practices, which is available at:
        <a href="https://portal.genomemedical.com/privacy"> https://portal.genomemedical.com/privacy </a>
        You also have the right to receive a paper copy of the Notice of Privacy Practices by contacting Genome Medical
        at <a href="tel:+18776880992">877.688.0992</a> or by email at
        <a href="mailto: clinical@genomemedical.com"> clinical@genomemedical.com</a>.
      </p>

      <p className="bullet-descrption">
        Your genetic counselor or physician (“Provider”) may be located in a different state than you are. Your Provider
        is required to follow state laws where you are based at the time of your consultation. Your state may influence the
        provider that you see (he/she must be licensed in the state you are in at the time of your consultation if a license
          is required by state law) and may influence other factors about your consultation like whether video is required by
          state law for telemedicine visits.
      </p>

      <p className="acknowledge-title">
        <span className="grey-text">I acknowledge and agree to the following: </span>
      </p>

      <ol className="bullet-descrption acknowledge">
      <li>
      Genome Medical is a telemedicine service that provides genetic consultation and counseling via interactive, live-streaming
      audio and video as well as by phone. Telemedicine involves the use of technology to enable remote communications between and
      among health care providers and patients. While telemedicine may improve access to care and lead to more efficient diagnosis,
      treatment, and care management, there are certain potential risks associated with telemedicine, as there are with any medical
      treatment or procedure. The potential risks associated with telemedicine include, but are not limited to, insufficient transmission
      of information that may affect appropriate decision-making and diagnosis by the health care provider; delays in diagnosis,
      consideration and/or communication due to deficiencies or failures of equipment or systems; failure of security protocols,
      resulting in a breach of privacy involving personal health information; or adverse results or reactions due to lack of access
      to complete medical records. In order to engage in Genome Medical’s tele- or video-consultations, you may need to download a free
      software package to your local device. The terms of use for that service are separate from the terms of Genome Medical Services.
      </li>
        <li>
          {' '}
          Genetic counseling is a clinical service used to evaluate and understand a person or family’s risk of an inherited medical condition.
          During a genetic telemedicine session, you and your Provider may:
          <ol>
            <li>Review your personal health history and family history;</li>
            <li>
              Discuss questions you have about possible genetic conditions in you or your family;
            </li>
            <li>
              Explore genetic testing options by helping you understand what tests are available, what testing can and cannot tell you,
              and the risks and benefits of genetic testing, and by providing guidance to help you decide if you would like to pursue
              genetic testing;
            </li>
            <li>
              Explain the results of any genetic testing you had and what they mean for you and your family; and/or
            </li>
            <li>
              Address the potential psychological and social impact of genetic conditions or genetic test results.
            </li>
          </ol>
        </li>
        <li>
          Genetic telemedicine services are voluntary. You are seeking genetic services either based on your own interest,
          or because your healthcare provider recommended you do so. You can end your consultation at any time. Your Provider
          may also end your consultation for any reason including, but not limited to, the Provider’s decision that the patient
          should be seen in person; your or the patient’s unwillingness or inability to properly use video; or concerns about the equipment.
        </li>
        <li>
          I consent to the collection of my specimens for the purpose of genetic testing as ordered by my Provider and genetic counseling.
          If you have concerns or questions about what you learn through your genetic tests, you should discuss them with your Provider, your
          local healthcare provider, or both.
        </li>
        <li>
          I understand that my sample will be sent to the referring laboratory to identify and test genetic conditions.
        </li>
        <li>
          I understand that genetic test results may be unclear or difficult to interpret due to current understanding of genetic disorders
          or conditions and/or technical limitations of the test and that the results are not intended to be used as the sole means for clinical
          diagnosis or patient care decisions.
        </li>
        <li>
          I may contact the laboratory who is processing my samples to request disposal of my sample up to 60 days after the completion of my test.
          I understand that my results will be shared with my Provider who ordered the test and for the purposes of providing genetic counseling.
          I understand that genetic tests may result in my learning information about myself that I do not anticipate and that genetic testing
          can involve possible medical, psychological or insurance consequences for me. My participation in this genetic testing is completely
          voluntary, and I understand my results will be sent to Genome Medical and its affiliates in accordance with my Genome Medical Authorization
          to Use and Disclose Health Information form.
        </li>
        <li>
          Your Provider may request assistance from other Genome Medical staff, a caregiver or you (if you are the parent/guardian) at the patient’s
          location to facilitate the services, or may request that you (if you are the parent/guardian) not be present for the video visit. In order
          to receive the best care possible, your Provider must collect certain information from you. For example, they will ask for your name and
          date of birth to verify your identity as well as information about your medical and family history in order to accurately assess your
          risk for genetic disease and make appropriate recommendations. Any information that you provide to your Provider must be truthful, accurate,
          and complete or the quality and effectiveness of the services provided may be adversely affected.
        </li>
        <li>
          Your medical records, genetic results, and in some cases raw genetic data will be stored by Genome Medical. Genome Medical will not
          release this information to any unauthorized party without your written consent, except as permitted by law. This information may be
          used and disclosed as described in our Notice of Privacy Practices.
        </li>
        <li>
          The details of your telehealth interaction, which may include oral, visual, and electronic communications between you and your Provider,
          may become part of your medical records, just as any other type of face-to- face interaction with a health care provider.
        </li>
        <li>
          Under certain circumstances, telehealth may not be as appropriate as face-to-face interaction, and your Provider may refer you to a local
          healthcare provider for follow-up or additional care.
        </li>
        <li>
          In some cases a genetic counseling student/trainee (“Trainee”) may participate in your care. You will be informed if a Trainee is
          participating in your care. Trainees will be supervised by your Provider, and your Provider will be present during your entire consultation.
          However, you may request at any time that the Trainee not participate in your consultation.
        </li>
        <li>
          The information provided by Providers are not intended to replace the medical advice and recommendations of your existing healthcare team.
          If you have non-genetic concerns about a current health condition, you should consult your local healthcare provider.
        </li>
        <li>
          Genome Medical may need to follow up with you in the future, and thereby you consent to be recontacted by email, phone, text, and/or written
          letter.  In the event that we contact you by email or text, you acknowledge that such communications are not always secure and may be
          intercepted and, despite these risks, agree to be contacted through these methods. If you prefer that we not contact you by email or text,
          please let us know by emailing us at <a href="mailto: clinical@genomemedical.com"> clinical@genomemedical.com</a>
        </li>
      </ol>

      <h3 className="grey-text">Assignment of Benefits</h3>
      <h3 className={BulletPointClassName}> Insurance/Health Plan </h3>
      <p className="bullet-descrption">
        If I qualify for benefits from any insurance company(s) or health plan(s), I assign those benefits to Genome Medical Services, P.C. to pay
        for care provided. I agree to sign any additional forms required by any insurance company or health plan (each a “Payor”) to confirm this
        assignment of benefits. I also authorize Genome Medical Services, P.C. to release all relevant information about me and my health care
        necessary to receive payment to the applicable Payor.
      </p>

      <h3 className="grey-text">Acknowledgment of Responsibility</h3>
      <h3 className={BulletPointClassName}> Payment Terms </h3>
      <p className="bullet-descrption">
        I agree to pay amounts I owe to Genome Medical Services, P.C. in accordance with its payment policies. Genome Medical Services, P.C.,
        to encourage the broadest possible access to care, has agreed to accept assignment of benefits from certain Payors and create institutional
        payment programs, and as a result, I may be personally responsible to those Payors or other institutions for payment of the services provided
        to me. I am responsible to pay all copays, deductibles, co-insurance and/or other charges, such as services that are not covered by my Payor
        or certain institutional partners.
      </p>
      <p className="bullet-descrption">
        I have had the opportunity to obtain answers to any questions regarding my financial responsibility, and understand and agree with the
        contents of this Financial Responsibility Form.
      </p>
      <p className="bullet-descrption">
        I have read and fully understand this informed Consent form in its entirety. I understand that I will have the opportunity to discuss the
        genetic counseling service, use of telemedicine, and genetic testing with my Provider, and may discontinue the service at any time.
      </p>
      <p className="bullet-descrption">
        I agree to the services as described and to the genetic testing ordered by my Provider. My electronic signature certifies that I have
        read and agree to all the provisions of this Consent and that I am entering into a valid and binding agreement as the individual named
        below or the authorized personal representative of that individual.
      </p>

      <h3 className="grey-text">Effective Date</h3>
      <p className="bullet-descrption">
        The effective date of this Notice is August 1, 2017
        <br />
        <br />
        This Notice was last updated on January 1, 2023
      </p>
    </article>
  );
};

export default Consent;
