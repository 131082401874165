import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import authService from 'services/api/authService';
import api from 'services/api/api';
import { meSuccess } from 'ducks/user';
import { sendSetPasswordLink } from 'ducks/auth/password';
import SetPasswordPage from 'containers/pages/public/SetPasswordPage/SetPasswordPage';
import PublicLayoutV2 from 'containers/layouts/PublicLayoutV2';
import SendSetPaswwordError from './SendSetPaswwordError';
import _ from 'lodash';
import ResendPasswordLinkForm from '../../components/forms/ResendPasswordLinkForm/ResendPasswordLinkForm';

const SET_PASSWORD = 'set_password';
const NO_ACTION = 'no_action';
const SEND_SET_PASSWORD_EMAIL = 'send_set_password_email';
const INVALID_TOKEN = 'invalid_token';

class CheckAuthActionPage extends Component {
  static propTypes = {
    actionToken: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = { action: null, resetToken: null, email: null, emailIsResend: false };

  componentDidMount() {
    authService
      .checkAction(this.props.actionToken)
      .then((data) => {
        // log out if we previously logged in
        if (data.action === SET_PASSWORD) api.setToken(null);
        this.props.dispatch(meSuccess(null));
        if (data.action)
          this.setState({ action: data.action, resetToken: data.resetToken, email: data.email });
      })
      .catch((err) => {
        if (_.get(err, ['code']) == 'invalid_token' && _.get(err, ['status']) == 403) {
          this.setState({ action: INVALID_TOKEN });
        }
        throw err;
      });
  }

  componentWillUpdate(nextProps, nextState) {
    if (!this.state.action && nextState.action === NO_ACTION) {
      browserHistory.replace(this.pathname);
    }
  }

  get pathname() {
    return window.location.pathname;
  }

  handleSubmitSendEmail = async ({ captcha }) => {
    const { dispatch } = this.props;
    const email = this.state.email;
    if (this.state.emailIsResend) this.setState({ emailIsResend: false });
    // eslint-disable-next-line
    try {
      await dispatch(sendSetPasswordLink({ email, captcha }));
      this.setState({ emailIsResend: true });
    } catch (e) {
      throw e;
    }
  };

  render() {
    const { sendPasswordLinkError } = this.props;
    const resendFormError = sendPasswordLinkError && sendPasswordLinkError.message;
    if (this.state.action === SET_PASSWORD)
      return (
        <PublicLayoutV2>
          <SetPasswordPage
            isFirstPassword={true}
            resetToken={this.state.resetToken}
            redirectPath={this.pathname}
            email={this.state.email}
          />
        </PublicLayoutV2>
      );
    else if (this.state.action == SEND_SET_PASSWORD_EMAIL)
      return (
        <PublicLayoutV2>
          <ResendPasswordLinkForm
            formError={resendFormError}
            onSubmit={this.handleSubmitSendEmail}
            isCheckAuthActionPage={true}
            emailIsResend={this.state.emailIsResend}
            header="Your Link Has Expired"
          />
        </PublicLayoutV2>
      );
    else if (this.state.action == INVALID_TOKEN)
      return (
        <PublicLayoutV2>
          <SendSetPaswwordError />
        </PublicLayoutV2>
      );
    return null;
  }
}

export default connect((state) => {
  const { sendPasswordLinkError } = state.auth.password;
  return {
    sendPasswordLinkError,
  };
})(CheckAuthActionPage);
