import api from './api';
import { hydrateInsurances, dehydrateInsurances } from './transformers/insuranceTransformers';
import { processInsuranceError } from './transformers/errorTransformers';

class InsuranceService {
  getInsuranceCompanies() {
    return api.insurance.getInsuranceCompanies().then(data => {
      data = data.map(company => ({
        id: company.id,
        name: company.name,
        price: company.consultation_price,
        nameOverrideRequired: company.name_override_required,
      }));
      data = data.sort(function(x, y) {
        return x.nameOverrideRequired === y.nameOverrideRequired
          ? 0
          : y.nameOverrideRequired ? -1 : 1;
      });
      return data;
    });
  }

  getInsurance() {
    return api.insurance.getInsurance().then(hydrateInsurances);
  }

  updateInsurance(params) {
    return api.insurance.setInsurance(dehydrateInsurances(params)).catch(processInsuranceError);
  }

  getInsurancePlanTypes() {
    return api.insurance.getInsurancePlanTypes();
  }
}

export default new InsuranceService();
