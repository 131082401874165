import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';

class ProgressBar extends Component {
  render() {
    const { className, percent, strokeColor, strokeWidth, trailColor } = this.props;
    return (
      <Line
        className={className}
        percent={percent}
        strokeWidth={strokeWidth}
        strokeColor={strokeColor}
        trailColor={trailColor}
      />
    );
  }
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  percent: PropTypes.number,
  strokeColor: PropTypes.string,
  trailColor: PropTypes.string,
  trailWidth: PropTypes.number,
  strokeWidth: PropTypes.number,
};

ProgressBar.defaultProps = {
  strokeWidth: 4,
  trailWidth: 4,
  strokeColor: '#00AEEF',
  trailColor: '#EBEBEB',
};

export default ProgressBar;
