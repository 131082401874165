import _ from 'lodash';
import axios from 'axios';
import documentsService from 'services/api/documentsService';
import { INS_FRONT_CARD } from '../constants/DocumentsConstants';

const LOAD_DOCUMENTS_START = `gm/documents/LOAD_DOCUMENTS_START`;
const LOAD_DOCUMENTS_SUCCESS = `gm/documents/LOAD_DOCUMENTS_SUCCESS`;
const LOAD_DOCUMENTS_FAILURE = `gm/documents/LOAD_DOCUMENTS_FAILURE`;

const UPLOAD = 'gm/documents/UPLOAD';
const UPLOAD_COMPLETE = 'gm/documents/UPLOAD_COMPLETE';
const SET_UPLOADING_PROGRESS = 'gm/documents/SET_UPLOADING_PROGRESS';

const INSURANCE_CARD_FRONT_UPLOAD = 'gm/documents/INSURANCE_CARD_FRONT_UPLOAD';
const INSURANCE_CARD_FRONT_UPLOAD_COMPLETE = 'gm/documents/INSURANCE_CARD_FRONT_UPLOAD_COMPLETE';
const SET_INSURANCE_CARD_FRONT_UPLOADING_PROGRESS =
  'gm/documents/SET_INSURANCE_CARD_FRONT_UPLOADING_PROGRESS';
const INSURANCE_CARD_FRONT_UPLOAD_RESET = 'gm/documents/INSURANCE_CARD_FRONT_UPLOAD_RESET';

const INSURANCE_CARD_BACK_UPLOAD = 'gm/documents/INSURANCE_CARD_BACK_UPLOAD';
const INSURANCE_CARD_BACK_UPLOAD_COMPLETE = 'gm/documents/INSURANCE_CARD_BACK_UPLOAD_COMPLETE';
const SET_INSURANCE_CARD_BACK_UPLOADING_PROGRESS =
  'gm/documents/SET_INSURANCE_CARD_BACK_UPLOADING_PROGRESS';
const INSURANCE_CARD_BACK_UPLOAD_RESET = 'gm/documents/INSURANCE_CARD_BACK_UPLOAD_RESET';

const START_REMOVING = 'gm/documents/START_REMOVING';
const REMOVING_COMPLETE = 'gm/documents/REMOVING_COMPLETE';
const REMOVE_FILE_FAST = 'gm/documents/REMOVE_FILE';

const SET_DOCUMENT_TYPES = 'gm/documents/SET_DOCUMENT_TYPES';
const LOAD_DOCUMENT_TYPES_FAILURE = `gm/documents/LOAD_DOCUMENT_TYPES_FAILURE`;

const RESET_UPLOADING_ERRORS = 'gm/documents/RESET_UPLOADING_ERRORS';

const LOAD_CONSENT_DOCS_START = 'gm/documents/LOAD_CONSENT_DOCS_START';
const LOAD_CONSENT_DOCS_SUCCESS = 'gm/documents/LOAD_CONSENT_DOCS_SUCCESS';
const LOAD_CONSENT_DOCS_FAILURE = 'gm/documents/LOAD_CONSENT_DOCS_FAILURE';

export const defaultState = {
  data: [],
  removingFiles: [],
  error: null,
  loading: false,
  loaded: false,
  uploading: false,
  uploadingProgress: {},
  uploadingResult: {},
  documentTypes: null,
  insuranceCardfrontuploaing: false,
  insuranceCardfrontUploadingProgress: null,
  insuranceCardFrontUploadingResult: null,
  insuranceCardbackuploaing: false,
  insuranceCardbackUploadingProgress: null,
  insuranceCardbackUploadingResult: null,
  consentDocsLoading: false,
  consentDocsLoaded: false,
  consentDocsError: null,
  consentDocs: [],
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case SET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.payload,
      };

    case LOAD_DOCUMENT_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case LOAD_DOCUMENTS_START:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case LOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case LOAD_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };

    case UPLOAD:
      return {
        ...state,
        uploading: true,
        uploadingProgress: {},
        uploadingResult: {},
      };

    case UPLOAD_COMPLETE:
      return {
        ...state,
        uploading: false,
        uploadingResult: action.payload,
      };

    case SET_UPLOADING_PROGRESS:
      return {
        ...state,
        uploadingProgress: {
          ...state.uploadingProgress,
          [action.payload.index]: action.payload.percent,
        },
      };

    case START_REMOVING:
      return {
        ...state,
        removingFiles: _(state.removingFiles).includes(action.payload.docId)
          ? [...state.removingFiles]
          : [...state.removingFiles, action.payload.docId],
      };

    case REMOVING_COMPLETE:
      return {
        ...state,
        removingFiles: [...state.removingFiles.filter(i => i !== action.payload.docId)],
      };

    case REMOVE_FILE_FAST:
      return {
        ...state,
        data: state.data && state.data.filter(f => f.id !== action.payload.id),
      };

    case RESET_UPLOADING_ERRORS:
      return {
        ...state,
        uploadingProgress: {},
        uploadingResult: {},
      };
    case INSURANCE_CARD_BACK_UPLOAD:
      return {
        ...state,
        insuranceCardbackuploaing: true,
        insuranceCardbackUploadingProgress: null,
        insuranceCardbackUploadingResult: null,
      };
    case INSURANCE_CARD_FRONT_UPLOAD:
      return {
        ...state,
        insuranceCardfrontuploaing: true,
        insuranceCardfrontUploadingProgress: null,
        insuranceCardFrontUploadingResult: null,
      };
    case SET_INSURANCE_CARD_FRONT_UPLOADING_PROGRESS:
      return {
        ...state,
        insuranceCardfrontUploadingProgress: action.payload,
      };
    case SET_INSURANCE_CARD_BACK_UPLOADING_PROGRESS:
      return {
        ...state,
        insuranceCardbackUploadingProgress: action.payload,
      };
    case INSURANCE_CARD_FRONT_UPLOAD_COMPLETE:
      return {
        ...state,
        insuranceCardfrontuploaing: false,
        insuranceCardFrontUploadingResult: action.payload,
      };
    case INSURANCE_CARD_BACK_UPLOAD_COMPLETE:
      return {
        ...state,
        insuranceCardbackuploaing: false,
        insuranceCardbackUploadingResult: action.payload,
      };
    case INSURANCE_CARD_FRONT_UPLOAD_RESET:
      return {
        ...state,
        insuranceCardfrontuploaing: false,
        insuranceCardFrontUploadingResult: null,
      };
    case INSURANCE_CARD_BACK_UPLOAD_RESET:
      return {
        ...state,
        insuranceCardbackuploaing: false,
        insuranceCardbackUploadingResult: null,
      };

    case LOAD_CONSENT_DOCS_START:
      return {
        ...state,
        consentDocsLoading: true,
        consentDocsLoaded: false,
        consentDocsError: null,
      };

    case LOAD_CONSENT_DOCS_SUCCESS:
      return {
        ...state,
        consentDocsLoading: false,
        consentDocsLoaded: true,
        consentDocsError: null,
        consentDocs: action.payload,
      };

    case LOAD_CONSENT_DOCS_FAILURE:
      return {
        ...state,
        consentDocsLoading: false,
        consentDocsLoaded: true,
        consentDocsError: action.payload,
      };

    default:
      return state;
  }
};

export function setDocumentTypes(docTypes) {
  return { type: SET_DOCUMENT_TYPES, payload: docTypes };
}

export function failureLoadDocumentTypes(error) {
  return { type: LOAD_DOCUMENT_TYPES_FAILURE, payload: error };
}

export function startRemovingFile(docId) {
  return { type: START_REMOVING, payload: { docId } };
}

export function removingComplete(docId) {
  return { type: REMOVING_COMPLETE, payload: { docId } };
}

export function removeFileFast(docId) {
  return {
    type: REMOVE_FILE_FAST,
    payload: { id: docId },
  };
}

export function startLoadDocuments() {
  return { type: LOAD_DOCUMENTS_START };
}

export function successLoadDocuments(docs) {
  return { type: LOAD_DOCUMENTS_SUCCESS, payload: docs };
}

export function failureLoadDocuments(error) {
  return { type: LOAD_DOCUMENTS_FAILURE, payload: error.fullMessage || error.message };
}

export function startUploading() {
  return { type: UPLOAD };
}

export function startUploadingInsuranceFront() {
  return { type: INSURANCE_CARD_FRONT_UPLOAD };
}

export function startUploadingInsuranceBack() {
  return { type: INSURANCE_CARD_BACK_UPLOAD };
}

export function completeUploading(status = true, error) {
  return { type: UPLOAD_COMPLETE, payload: { status, error } };
}

export function completeUploadingInsuranceCardFront(status = true, error) {
  return { type: INSURANCE_CARD_FRONT_UPLOAD_COMPLETE, payload: { status, error } };
}

export function completeUploadingInsuranceCardBack(status = true, error) {
  return { type: INSURANCE_CARD_BACK_UPLOAD_COMPLETE, payload: { status, error } };
}

export function resetnsuranceCardFront() {
  return { type: INSURANCE_CARD_FRONT_UPLOAD_RESET };
}

export function resetInsuranceCardBack() {
  return { type: INSURANCE_CARD_BACK_UPLOAD_RESET };
}

export function setUploadingProgress(index, percent) {
  return { type: SET_UPLOADING_PROGRESS, payload: { index, percent } };
}

export function setUploadingProgressInsuranceCardFront(percent) {
  return { type: SET_INSURANCE_CARD_FRONT_UPLOADING_PROGRESS, payload: percent };
}

export function setUploadingProgressInsuranceCardBack(percent) {
  return { type: SET_INSURANCE_CARD_BACK_UPLOADING_PROGRESS, payload: percent };
}

export function startLoadConsentDocuments() {
  return { type: LOAD_CONSENT_DOCS_START };
}

export function successLoadConsentDocuments(docs) {
  return { type: LOAD_CONSENT_DOCS_SUCCESS, payload: docs };
}

export function failureLoadConsentDocuments(error) {
  return { type: LOAD_CONSENT_DOCS_FAILURE, payload: error.fullMessage || error.message };
}

export function loadDocumentTypes() {
  return dispatch => {
    return documentsService
      .getDocumentTypes()
      .then(docTypes => dispatch(setDocumentTypes(docTypes)))
      .catch(error => dispatch(failureLoadDocumentTypes(error)));
  };
}

export function loadDocuments() {
  return dispatch => {
    dispatch(startLoadDocuments());
    return documentsService
      .getDocuments()
      .then(docs => dispatch(successLoadDocuments(docs)))
      .catch(error => {
        if (!axios.isCancel(error)) dispatch(failureLoadDocuments(error));
      });
  };
}

export function deleteDocument(docId) {
  return dispatch => {
    if (docId === undefined) return;
    dispatch(startRemovingFile(docId));
    return documentsService
      .deleteDocument(docId)
      .then(
        () => dispatch(removeFileFast(docId)),
        error => {
          dispatch(removingComplete(docId));
          throw error;
        }
      )
      .then(() => dispatch(removingComplete(docId)));
  };
}

export function uploadingErrorHandler(error) {
  return dispatch => {
    const errorMsg =
      (error.fields && (error.fields.file || error.fields.description)) ||
      error.fullMessage ||
      error.message;
    dispatch(completeUploading(false, errorMsg));
    return dispatch(loadDocuments());
  };
}
/**
 * There is using only one description, appointmentId, documentTypeId parameters for all documents
 * and you can specify new file name for each file in params.fileNames array
 */
export function uploadDocuments(data, params = {}) {
  const calcPercent = progressEvent => Math.floor(progressEvent.loaded * 100 / progressEvent.total);
  return dispatch => {
    const { appointmentId, documentTypeId, description, prefix } = params;
    const fileNames =
      params.fileNames && !Array.isArray(params.fileNames) ? [params.fileNames] : params.fileNames;
    const filesKeys = _.keys(data);
    const uploadingPromises = [];
    let partialError;
    filesKeys.forEach((key, index) => {
      if (typeof data[key] === 'object') {
        const uploadProgressHandler = e => dispatch(setUploadingProgress(index, calcPercent(e)));
        const name = (fileNames && fileNames[index]) || data[key].name;
        uploadingPromises.push(
          documentsService
            .uploadDocuments(
              data[key],
              `${prefix ? prefix : ''}${name}`,
              {
                appointmentId,
                documentTypeId,
                description,
              },
              uploadProgressHandler
            )
            .catch(error => {
              dispatch(setUploadingProgress(index, -1));
              partialError = error;
            })
        );
      }
    });
    dispatch(startUploading());
    return Promise.all(uploadingPromises)
      .then(() => {
        if (partialError) {
          dispatch(uploadingErrorHandler(partialError));
          throw partialError;
        } else {
          dispatch(completeUploading());
          return dispatch(loadDocuments());
        }
      })
      .catch(error => {
        throw error;
      });
  };
}

export function resetUploadingErrors() {
  return { type: RESET_UPLOADING_ERRORS };
}

export function uploadDocumentInsuranceCard(data, doctype, name, type = INS_FRONT_CARD) {
  const setUploadingProgress =
    type && type === INS_FRONT_CARD
      ? setUploadingProgressInsuranceCardFront
      : setUploadingProgressInsuranceCardBack;
  const startUploading =
    type && type === INS_FRONT_CARD ? startUploadingInsuranceFront : startUploadingInsuranceBack;
  const completeUploading =
    type && type === INS_FRONT_CARD
      ? completeUploadingInsuranceCardFront
      : completeUploadingInsuranceCardBack;
  const calcPercent = progressEvent => Math.floor(progressEvent.loaded * 100 / progressEvent.total);
  return dispatch => {
    const documentTypeId = doctype.id;
    if (typeof data === 'object') {
      const uploadProgressHandler = e => dispatch(setUploadingProgress(calcPercent(e)));
      dispatch(startUploading());
      return documentsService
        .uploadDocuments(
          data,
          name,
          {
            appointmentId: null,
            documentTypeId,
            description: null,
          },
          uploadProgressHandler
        )
        .then(() => {
          dispatch(completeUploading(true));
          return dispatch(loadDocuments());
        })
        .catch(error => {
          dispatch(setUploadingProgress(-1));
          const errorMsg =
            (error.fields && (error.fields.file || error.fields.description)) ||
            error.fullMessage ||
            error.message;
          dispatch(completeUploading(false, errorMsg));
          throw error;
        });
    }
  };
}

export function loadConsetDocuments() {
  return dispatch => {
    dispatch(startLoadConsentDocuments());
    return shouldGetConsentDocs(0)
      .then(docs => dispatch(successLoadConsentDocuments(docs)))
      .catch(error => dispatch(failureLoadConsentDocuments(error)));
  };
}

export function shouldGetConsentDocs(counter) {
  return new Promise((resolve, reject) => {
    return documentsService
      .getConsentDocuments()
      .then(data => {
        if (data.length > 0) {
          return resolve(data);
        } else if (counter == 10) {
          return resolve();
        } else {
          return resolve(shouldGetConsentDocs(++counter));
        }
      })
      .catch(error => {
        return reject(error);
      });
  });
}

export function downloadConsentDocuments() {
  return (dispatch, getState) => {
    const { consentDocs } = getState().documents;
    consentDocs.map(async doc => {
      await documentsService.downloadDocument(doc.id);
    });
    return Promise.resolve();
  };
}
