// @flow

import engine from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import sessionStorage from 'store/storages/sessionStorage';

export const localStore = engine.createStore([localStorage], []);
export const sessionStore = engine.createStore([sessionStorage], []);

class StoreClass {
  store: any;

  constructor(store: any) {
    this.store = store || localStore;
  }

  useSession(use: boolean = true) {
    if (!use || this instanceof SessionStoreClass) {
      return this;
    }
    return new SessionStoreClass(this);
  }

  get(key: string, optionalDefaultValue: ?string = null): ?string {
    return (
      localStore.get(key, optionalDefaultValue) ||
      sessionStore.get(key, optionalDefaultValue) ||
      undefined
    );
  }

  set(key: string, value: any) {
    this.store.set(key, value);
  }

  remove(key: string) {
    this.store.remove(key);
    sessionStore.remove(key);
  }

  clearAll() {
    this.store.clearAll();
  }
}

class SessionStoreClass extends StoreClass {
  constructor() {
    super(sessionStore);
  }
}

export const SessionStore = new SessionStoreClass();

export default new StoreClass();
