export const documentSourcesNames = {
  patient: 'patient',
  provider: 'provider',
};

export const documentTypesNames = {
  other: 'questionnaires',
  consultNotes: 'visit documentation',
  geneticTestResults: 'genetic test results',
  externalRecords: 'external records',
  insuranceCard: 'insurance card',
  insuranceDocument: 'insurance document',
  pedigree: 'pedigree',
  patientCommunication: 'patient communication',
  administrativeMisc: 'administrative misc',
};

export const documentTypesDescription = {
  [documentTypesNames.other]: 'questionnaires',
  [documentTypesNames.consultNotes]: 'visit documentation',
  [documentTypesNames.geneticTestResults]: 'Your genetic test results',
  [documentTypesNames.externalRecords]:
    'Your clinical records from other health care providers that are relevant to your Genome Medical visit',
  [documentTypesNames.pedigree]: 'Your genetically represented family tree',
  [documentTypesNames.patientCommunication]: 'Your communications with Genome Medical',
};

export const INS_FRONT_CARD = 'Front';
export const INS_BACK_CARD = 'Back';
