// @flow
import api from './api';
import { hydrateServicePackages } from './transformers/servicePackageTransformers';

class ServicePackagesService {
  getPurchasedPackages(): Promise<Array<Object>> {
    return api.servicePackages.getPurchasedPackages().then(hydrateServicePackages);
  }

  purchasePackage(
    packageId: number,
    couponCode: ?string,
    accessToken: ?string
  ): Promise<Array<Object>> {
    return api.servicePackages.purchasePackage(packageId, couponCode, accessToken);
  }
}

export default new ServicePackagesService();
