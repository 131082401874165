import React from 'react';
import StepsPanel from './StepsPanel';
import ControlledButtonGroup from '../../ControlledButtonGroup/ControlledButtonGroup';

import './ResponsiveStepsPanel.scss';

const ResponsiveStepsPanel = props => [
  <div className="scp-responsive-step-panel__mobile" key="m-steps">
    <ControlledButtonGroup {...props} />
  </div>,
  <div className="scp-responsive-step-panel__desktop" key="d-steps">
    <StepsPanel {...props} />
  </div>,
];

export default ResponsiveStepsPanel;
