import PropTypes from 'prop-types';
import React from 'react';
import './PageTitle.scss';

export default function PageTitle({ title, description, children }) {
  return (
    <div className="page-title">
      <h2 className="title">{title}</h2>
      <p className="desc">{description || children}</p>
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
};
