import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import api from 'services/api/api';
import { deleteUpcomingAppointment, loadCurrentAppointment } from 'ducks/appointment';
import AppointmentCancel from './AppointmentCancel';
import WelcomeBack from './WelcomeBack';
import UpcomingAppointment from './UpcomingAppointment';
import { loaderSession } from 'ducks/ui';
import { migratetoSCP } from 'ducks/user';
import _ from 'lodash';
import './WelcomePage.scss';
import gaTrack, { GA_TR_WELCOME_PAGE } from 'services/gaTrack';

const STAGE_DEFAULT = 'STAGE_DEFAULT';
const STAGE_CANCEL_APP = 'STAGE_CANCEL_APP';
const STAGE_WELCOME_BACK = 'STAGE_WELCOME_BACK';

class WelcomePage extends Component {
  state = {
    activeStage: STAGE_DEFAULT,
  };

  componentDidMount() {
    gaTrack(GA_TR_WELCOME_PAGE);
    if (api.hasToken() && api.isSCPUser()) {
      browserHistory.push(`/patient/home`);
    } else {
      const { needToLoad } = this.props;
      if (needToLoad) {
        this.loadCurrentAppointment();
      }
      this.checkAppointment();
    }
  }

  componentDidUpdate() {
    if (this.appointmentLoaded !== this.props.appointmentLoaded) {
      this.checkAppointment();
    }
  }

  chooseCancelAppointment = () => {
    this.setState({ activeStage: STAGE_CANCEL_APP });
  };

  cancelAppointment = () => {
    const { id } = this.props.appointment;
    this.props.dispatch(deleteUpcomingAppointment(id, false)).then(() => {
      this.setState({ activeStage: STAGE_WELCOME_BACK });
    });
  };

  loadCurrentAppointment = () => {
    const { loadAppointment, dispatch } = this.props;
    loaderSession(dispatch, loadAppointment());
  };

  appointmentLoaded = false;

  checkAppointment() {
    const { appointmentLoaded, appointment } = this.props;
    if (appointmentLoaded) {
      this.appointmentLoaded = appointmentLoaded;
      if (_.isEmpty(appointment) || appointment.isComplete || appointment.isMissed) {
        this.setState({ activeStage: STAGE_WELCOME_BACK });
      }
    }
  }

  get isUpcomingAppointment() {
    const { appointment } = this.props;
    return (
      !_.isEmpty(appointment) &&
      appointment.isUpcoming &&
      appointment.latestStatus &&
      appointment.latestStatus.status === 'booked'
    );
  }

  render() {
    if (!api.hasToken()) return null;
    const { appointment, doMigrateSCP } = this.props;
    const { activeStage } = this.state;
    return (
      <div className="welcome-page">
        <div className="welcome-page-container">
          {activeStage == STAGE_WELCOME_BACK ? (
            <WelcomeBack doMigrateSCP={doMigrateSCP} />
          ) : (
            this.isUpcomingAppointment &&
            (activeStage == STAGE_CANCEL_APP ? (
              <section>
                <AppointmentCancel
                  cancelAppointment={() => {
                    this.cancelAppointment();
                  }}
                  cancel={() => {
                    this.setState({ activeStage: STAGE_DEFAULT });
                  }}
                />
              </section>
            ) : (
              <UpcomingAppointment
                appointment={appointment}
                cancelAppointment={this.chooseCancelAppointment.bind(this)}
                viewAppointment={() => browserHistory.push('/patient')}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

WelcomePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  needToLoad: PropTypes.bool,
  appointmentLoaded: PropTypes.bool,
  appointmentLoading: PropTypes.bool,
  appointment: PropTypes.object,
  loadAppointment: PropTypes.func.isRequired,
  doMigrateSCP: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  appointment: state.appointment.data,
  appointmentLoaded: state.appointment.loaded,
  appointmentLoading: state.appointment.loading,
});

const mergeProps = (stateProps, dispatchProps) => {
  const { appointment, appointmentLoaded, appointmentLoading } = stateProps;
  const { dispatch } = dispatchProps;
  return {
    dispatch,
    appointment,
    appointmentLoaded,
    appointmentLoading,
    needToLoad: !(appointmentLoaded || appointmentLoading),
    loadAppointment: () => dispatch(loadCurrentAppointment()),
    doMigrateSCP: () => dispatch(migratetoSCP()),
  };
};

export default connect(mapStateToProps, null, mergeProps)(WelcomePage);
