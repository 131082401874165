import appointmentsService from 'services/api/appointmentsService';
import schedulingService from 'services/api/schedulingService';
import { clearReservedAppointment } from 'ducks/scheduling';
import { setupGuide } from 'ducks/guides/guideProvider';
import { showLoader, closeLoader } from './ui';
import { loadSurvey } from 'ducks/answers';

const LOAD_CURRENT_APPOINTMENT_PROGRESS_START =
  'gm/appointment/LOAD_CURRENT_APPOINTMENT_PROGRESS_START';
const LOAD_CURRENT_APPOINTMENT_SUCCESS = 'gm/appointment/LOAD_CURRENT_APPOINTMENT_SUCCESS';
const LOAD_CURRENT_APPOINTMENT_FAILURE = 'gm/appointment/LOAD_CURRENT_APPOINTMENT_FAILURE';
const DELETE_FAILURE = 'gm/appointment/DELETE_FAILURE';
const CLEAR_DELETING_ERROR = 'gm/appointment/CLEAR_DELETING_ERROR';

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  deletingError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CURRENT_APPOINTMENT_PROGRESS_START:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case LOAD_CURRENT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };

    case LOAD_CURRENT_APPOINTMENT_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        loaded: true,
      };

    case DELETE_FAILURE:
      return {
        ...state,
        deletingError: action.payload,
        loading: false,
        loaded: true,
      };
    case CLEAR_DELETING_ERROR:
      return { ...state, deletingError: null };

    default:
      return state;
  }
}

export function loadCurrentAppointmentStart() {
  return { type: LOAD_CURRENT_APPOINTMENT_PROGRESS_START };
}

export function loadCurrentAppointmentSuccess(appointment) {
  return { type: LOAD_CURRENT_APPOINTMENT_SUCCESS, payload: appointment };
}

export function loadCurrentAppointmentFailure(error) {
  return { type: LOAD_CURRENT_APPOINTMENT_FAILURE, payload: error };
}

export function clearDeletingError() {
  return { type: CLEAR_DELETING_ERROR };
}

export function loadCurrentAppointment() {
  return (dispatch, getState) => {
    if (getState().myGuide.guideProvider.loading) return;
    dispatch(loadCurrentAppointmentStart());
    return appointmentsService
      .getCurrentAppointment()
      .then(appointment => {
        if (appointment.isUpcoming) {
          dispatch(clearReservedAppointment());
        }
        dispatch(setupGuide(appointment));
        dispatch(loadCurrentAppointmentSuccess(appointment));
        dispatch(loadSurvey(appointment.id));
        return Promise.resolve(appointment);
      })
      .catch(error => dispatch(loadCurrentAppointmentFailure(error)));
  };
}

export function deleteUpcomingAppointment(id, reload = true) {
  return dispatch => {
    dispatch(showLoader());
    return appointmentsService
      .delete(id)
      .then(() => {
        schedulingService.clearSchedulingData();
        if (reload) {
          window.location.reload();
        } else {
          dispatch(closeLoader());
        }
      })
      .catch(err => {
        dispatch(closeLoader());
        dispatch({ type: DELETE_FAILURE, payload: err.message });
        throw err;
      });
  };
}

export function completeGCNSurvey(appointmentId, next = false) {
  return dispatch => {
    dispatch(showLoader());
    return appointmentsService
      .completeGCNSurvey(appointmentId)
      .then(() => {
        dispatch(closeLoader());
        dispatch(loadSurvey());
      })
      .catch(err => {
        if (next) {
          return dispatch(completeGCNSurvey(appointmentId));
        } else {
          dispatch(closeLoader());
          throw err;
        }
      });
  };
}
