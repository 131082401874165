import moment from 'moment';

/**
 * removes timezone from date string
 */
export function clearOffset(dateStr) {
  return dateStr.substr(0, 19);
}

export function getOffset(dateStr) {
  return dateStr.substr(19);
}

/**
 * transforms date string into JS date object
 */
export function getDateFromStr(dateStr) {
  return moment(clearOffset(dateStr)).toDate();
}
