import referralsService from 'services/api/referralsService';

import { sectionProviderInfo, thankYouStep } from 'constants/ReferralsScheme';
import { showLoader, closeLoader } from './ui';

const GOTO_STAGE = 'gm/ref/GOTO_STAGE';
const SAVE_ANSWERS = 'gm/ref/SAVE_ANSWERS';
const FILL_FROM_STORAGE = 'gm/ref/FILL_FROM_STORAGE';
const SUBMITTING_ERROR = 'gm/ref/SUBMITTING_ERROR';
const CLEAR_ERROR = 'gm/ref/CLEAR_ERROR';

export default function reducer(
  state = { referStage: null, answers: null, error: null },
  action = {}
) {
  switch (action.type) {
    case GOTO_STAGE:
      return { ...state, referStage: action.payload };
    case SAVE_ANSWERS:
      return {
        ...state,
        answers: { ...state.answers, [action.payload.sectionKey]: action.payload.answers },
      };
    case FILL_FROM_STORAGE:
      return { ...state, ...action.payload };
    case SUBMITTING_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}

export function setStage(stage) {
  return { type: GOTO_STAGE, payload: stage };
}

export function setStageAnswers(answers, referStage) {
  return { type: FILL_FROM_STORAGE, payload: { answers, referStage } };
}

export function setSectionAnswers(sectionKey, answers) {
  return { type: SAVE_ANSWERS, payload: { sectionKey, answers } };
}

export const gotoStage = stage => dispatch => {
  referralsService.setCurrentStage(stage);
  dispatch(setStage(stage));
  return Promise.resolve();
};

export const fillFromStorage = () => dispatch => {
  const answers = referralsService.loadAnswers();
  const referStage = referralsService.getCurrentStage();
  dispatch(setStageAnswers(answers, referStage));
  return Promise.resolve();
};

export const saveAnswers = (sectionKey, answers) => (dispatch, getState) => {
  dispatch(setSectionAnswers(sectionKey, answers));
  referralsService.saveAnswers(getState().referrals.answers);
  return Promise.resolve();
};

export const submitAnswers = () => dispatch => {
  dispatch(showLoader());
  return referralsService
    .submitAnswers()
    .then(referralsService.submitFiles)
    .then(referralsService.submitForm)
    .then(() => {
      referralsService.clearEverything();
      dispatch(fillFromStorage());
      return dispatch(gotoStage(thankYouStep));
    })
    .then(() => dispatch(closeLoader()))
    .catch(error => {
      dispatch({
        type: SUBMITTING_ERROR,
        payload: (error && (error.fullMessage || error.message)) || 'Error. Something went wrong',
      });
      dispatch(closeLoader());
    });
};

export const referNewPatient = () => dispatch => {
  referralsService.clearEverything();
  return dispatch(gotoStage(sectionProviderInfo));
};

export const clearError = () => dispatch =>
  dispatch({
    type: CLEAR_ERROR,
  });
