import React from 'react';

const Facebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9.899" height="20" viewBox="0 0 9.899 20">
    <path
      id="prefix__Path_21544"
      d="M1219.187 1187.8h-2.946v9.48h-4.367v-9.48h-2.074v-3.712h2.077v-2.4a4.1 4.1 0 0 1 4.406-4.407l3.235.014v3.6h-2.348a.889.889 0 0 0-.926 1.012v2.184h3.327zm.509.111"
      data-name="Path 21544"
      transform="translate(-1209.797 -1177.278)"
    />
  </svg>
);

export default Facebook;
