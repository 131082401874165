import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { getDateFromStr } from 'services/api/transformers/transformersUtils';
import CalendarDay from 'components/widgets/CalendarDay/CalendarDay';
import './EventInfo.scss';

class EventInfo extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    photo: PropTypes.string,
    children: PropTypes.node,
  };

  state = {
    imageLoadingFailed: false,
  };

  handleImageLoadingError = () => {
    this.setState({ imageLoadingFailed: true });
  };

  render() {
    const { className, title, date, photo, children } = this.props;
    const { imageLoadingFailed } = this.state;

    return (
      <div
        className={classnames('scp-event-info', className, {
          ['event-info__no-title']: !this.props.title,
        })}>
        {title && <h3 className="block-title">{title}</h3>}

        <div className="block-content">
          {imageLoadingFailed ? (
            date ? (
              <CalendarDay date={getDateFromStr(date)} className="photo cal-day" />
            ) : null
          ) : (
            <img className="photo" src={photo} onError={this.handleImageLoadingError} />
          )}
          <div className="event-fields">{children}</div>
        </div>
      </div>
    );
  }
}

export default EventInfo;
