import React, { Component } from 'react';
import 'components/forms/WizardForm/CreateAccountForm/ABNForm.scss';
import ABNForm from './ABNForm';
import gaTrack, { GA_TR_ABN_PAGE } from '../../../services/gaTrack';

class ABNPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    gaTrack(GA_TR_ABN_PAGE);
  }

  render() {
    return (
      <section className="abn-container">
        <section className="abn-wrapper">
          <section className="abn-header">
            <div className="genome-header-logo">
              <img className="genome-header-img" src={require('images/logo.svg')} />
            </div>
          </section>
          <ABNForm {...this.props} />
        </section>
      </section>
    );
  }
}

export default ABNPage;
