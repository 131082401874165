import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';

const months = _.range(1, 13).map(i => ({ value: i, label: i < 10 ? `0${i}` : `${i}` }));

class ExpirationMonth extends Component {
  handleChange = ({ value }) => {
    this.props.input.onChange(value);
  };

  render() {
    const { className, label, disabled, input: { value }, meta: { touched, error } } = this.props;
    return (
      <div className={classnames('gm-form-field', className, { error: touched && error })}>
        <label className="gm-form-label">{(touched && error) || label}</label>
        <div className="gm-form-control">
          <Select
            className="gm-select mp-no-track mp-sensitive"
            value={value}
            options={months}
            onChange={this.handleChange}
            placeholder="Month"
            clearable={false}
            searchable={false}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

ExpirationMonth.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ExpirationMonth;
