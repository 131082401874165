import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { logout } from '../../../../ducks/auth/email';
import FancyPageFrame from 'components/widgets/FancyPageFrame/V2/FancyPageFrame';
import SimpleWebinarMessage from '../../webinar/SimpleWebinar/SimpleWebinarMessage/V2/SimpleWebinarMessage';

class ThankYouPage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(logout(false));
  }

  render() {
    const { message,title } = this.props;
    return (
      <div className="simple-webinar">
        <FancyPageFrame className="thank-you">
          <SimpleWebinarMessage
            iconClass="success"
            subcomponent={
              <div>
                {message}
              </div>
            }
          />
        </FancyPageFrame>
      </div>
    );
  }
}

export default connect(
  () => {
    return {};
  },
  (dispatch) => {
    return { dispatch };
  }
)(ThankYouPage);
