// @flow
import api from './api';
import { Encounter, UpdateSCPOrderConsent } from 'models/Encounter';
import { hydrateEncounters, dehydrateEncounter } from './transformers/encounterTransformers';
import { processSetSCPOrderConsentError } from './transformers/errorTransformers';

class EncountersService {
  getEncounters(): Promise<Encounter> {
    return api.encounters.getEncounters().then(data => hydrateEncounters(data));
  }

  updateEncounter(id: Number, encounter: UpdateSCPOrderConsent): Promise<any> {
    return api.encounters
      .updateSCPEncounter(id, dehydrateEncounter(encounter))
      .catch(processSetSCPOrderConsentError);
  }

  createEncounter(): Promise<any> {
    return api.encounters.createSCPEncounter();
  }
}

export default new EncountersService();
