import React, { Component } from 'react';
import InputMask from 'react-input-mask';

/**
 *  For now this component is empty wrapper for MaskedInput.
 *  It's created to provide some custom mask behaviour. But not implemented yet.
 */
class CustomMaskedInput extends Component {
  render() {
    return <InputMask {...this.props} />;
  }
}

export default CustomMaskedInput;
