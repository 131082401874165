import React, { Fragment } from 'react';
import _ from 'lodash';
import Link from '../../../widgets/Link/Link';
import './ConsentDetail.scss';

const ConsentDetail = (props) => {
  const { type, extraClassname } = props;
  return (
    <div className={`form-section consent-detail ${extraClassname}`}>
      {!(type == 'review') && (
        <div className="info-section">
          <h3 className="header">Consent</h3>
        </div>
      )}
      <div className="body-section">
        <div className="label-section">
          <div className="certification-text">By clicking below, I certify the following:</div>
          <ul>
            <li>I am at least 18 years of age </li>
            <li>I am located in the US</li>
            <li>I have never had blood cancer</li>
            <li>I have not had a blood transfusion within the last four weeks</li>
            <li>I have never undergone a stem cell or bone marrow transplant</li>
          </ul>
          <p>
            Don’t meet the criteria? Explore other{' '}
            <Link blank={true} to="https://www.genomemedical.com/">
              {' '}
              genetic counseling options
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConsentDetail;
