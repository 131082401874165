import React, { useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const CheckoutForm = (props) => {

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (stripe) props.handleComponentState('stripe', stripe);
    if (elements) props.handleComponentState('elements', elements);
  }, [elements, stripe])

  const handleChange = (el) => {
    props.handleComponentState('validateCreditCard', el.complete);
  }

  const fields = {};
  return (
    <>
      <PaymentElement
      options={{
        fields,
        defaultValues: {...props.defaultValues}
      }}
        onChange={handleChange}
      />
    </>
  );
};

export default CheckoutForm;
