import {combineReducers} from 'redux';
import emailReducer from './email';
import passwordReducer from './password';

const rootReducer = combineReducers({
  email: emailReducer,
  password: passwordReducer
});

export default rootReducer;
