import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RBAC from 'components/high-order-components/RBAC/RBAC';

import './ConfirmationNotice.scss';

class ConfirmationNotice extends Component {
  state = { verifyProcessing: false };

  onVerifyEmail = () => {
    if (this.state.verifyProcessing) return;
    this.setState({ verifyProcessing: true });
    this.props.verifyEmail().then(() => {
      this.setState({ verifyProcessing: false });
    });
  };

  render() {
    const { email, requestEmailSended } = this.props;
    const linkClassName = classnames('header-confirmation-notice-link', {
      ['header-confirmation-notice-link__completed']: requestEmailSended,
      ['header-confirmation-notice-link__processing']: this.state.verifyProcessing,
    });
    const linkText = requestEmailSended
      ? 'Email has been sent successfully.'
      : 'Resend confirmation email';
    return (
      <div className="header-confirmation-notice" data-hj-suppress>
        <div className="header-confirmation-notice-text">
          <i className="header-confirmation-notice-icon fa fa-envelope" />
          <span>Please verify your email by clicking the confirmation link sent to {email}.</span>
          <span onClick={this.onVerifyEmail} className={linkClassName}>
            {linkText}
          </span>
        </div>
      </div>
    );
  }
}

ConfirmationNotice.propTypes = {
  email: PropTypes.string.isRequired,
  verifyEmail: PropTypes.func.isRequired,
  requestEmailSended: PropTypes.bool,
};

export default RBAC(['nonScp'], ConfirmationNotice);
