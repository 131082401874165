import React, { Component, Fragment } from 'react';
import { browserHistory } from 'react-router';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';
import noPrvdrIcon from './no-appt.png';
import {
  FOLLOWUP_HIGH_PRIORITY_CARD,
  FOLLOWUP_LOW_PRIORITY_CARD,
} from 'constants/HomeAppointmentCardGoals';

class FollowupAppointment extends Component {
  static propTypes = {
    goal: PropTypes.string,
    meOutreach: PropTypes.object,
    dispatch: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      followupAptmntExpand: false,
      imageLoadingFailed: false,
    };
  }

  componentDidMount() {
    if (this.props.goal == FOLLOWUP_HIGH_PRIORITY_CARD || this.props.isGrailFlowAffiliation) {
      this.setState({ followupAptmntExpand: true });
    }
  }

  handleImageLoadingError = () => {
    this.setState({ imageLoadingFailed: true });
  };

  goToScheduling() {
    const { meOutreach } = this.props;
    const encounterType = _.get(meOutreach, ['encounterType'], '');
    const appointmentID = _.get(meOutreach, ['appointmentID'], '');

    if (encounterType != 'visit' || appointmentID == null) browserHistory.push(`/patient/outreach`);
    else browserHistory.push(`/patient/outreach/${meOutreach.appointmentID}`);
  }

  followupAppointmentHeader = (meOutreach, provider, mStartTime) => {
    const encounterType = _.get(meOutreach, ['encounterType'], '');
    const appointmentID = _.get(meOutreach, ['appointmentID'], '');
    const testName = !_.isNil(_.get(meOutreach, ['testDisplayName'], ''))
      ? _.get(meOutreach, ['testDisplayName'], '')
      : '';
    let headerText = '';

    switch (encounterType) {
      case 'visit':
        if (appointmentID != null) {
          headerText = `Your past appointment with ${_.get(
            provider,
            'fullName',
            ''
          )} on ${' '} ${mStartTime && mStartTime.format('MMM Do, YYYY')}`;
        } else {
          headerText = `Your past appointment on ${' '} ${!_.isNil(meOutreach.DOS) &&
            moment(meOutreach.DOS).format('MMM Do, YYYY')}`;
        }
        break;
      case 'cc-intake':
      case 'group-session':
        headerText = `Your past appointment on ${' '} ${!_.isNil(meOutreach.DOS) &&
          moment(meOutreach.DOS).format('MMM Do, YYYY')}`;
        break;
      case 'scp':
        headerText = `Your ${testName} Test`;
        break;
      default:
        headerText;
    }
    return headerText;
  };

  followupAppointmentBody = (meOutreach, provider, mStartTime) => {
    const encounterType = _.get(meOutreach, ['encounterType'], '');
    const appointmentID = _.get(meOutreach, ['appointmentID'], '');
    const labName = !_.isNil(_.get(meOutreach, ['labDisplayName'], ''))
      ? _.get(meOutreach, ['labDisplayName'], '')
      : '';
    const testName = !_.isNil(_.get(meOutreach, ['testDisplayName'], ''))
      ? _.get(meOutreach, ['testDisplayName'], '')
      : '';
    let bodyText = '';

    switch (encounterType) {
      case 'visit':
        if (appointmentID != null) {
          bodyText = (
            <span>
              Your test results have come back for your previous appointment on {' '}
              <span className="bold">{mStartTime && mStartTime.format('MMM Do, YYYY')}.</span> If
              you'd like to discuss the results with your counselor {' '}
              <span className="bold">{_.get(provider, 'fullName', '')},</span> you can schedule an
              appointment.
            </span>
          );
        } else {
          bodyText = (
            <span>
              Your test results have come back for your previous appointment on {' '}
              <span className="bold">
                {!_.isNil(meOutreach.DOS) && moment(meOutreach.DOS).format('MMM Do, YYYY')}.
              </span>{' '}
              If you'd like to discuss the results with one of our Certified Genetic Counselors, you
              can schedule an appointment.
            </span>
          );
        }

        break;
      case 'cc-intake':
      case 'group-session':
        bodyText = (
          <span>
            Your test results have come back for your previous appointment on {' '}
            <span className="bold">
              {!_.isNil(meOutreach.DOS) && moment(meOutreach.DOS).format('MMM Do, YYYY')}.
            </span>{' '}
            If you'd like to discuss the results with one of our Certified Genetic Counselors, you
            can schedule an appointment.
          </span>
        );
        break;
      case 'scp':
        bodyText = (
          <span>
            Results for your <span className="bold">{testName}</span> by{' '}
            <span className="bold">{labName}</span> have come back. If you'd like to discuss the
            results with one of our Certified Genetic Counselors, you can schedule an appointment.
          </span>
        );
        break;
      default:
        bodyText;
    }
    return bodyText;
  };

  determineIsGrailCard = (meOutreach, affiliation) => {
    const encounterType = _.get(meOutreach, ['encounterType'], '');
    const testName = !_.isNil(_.get(meOutreach, ['testDisplayName'], ''))
      ? _.get(meOutreach, ['testDisplayName'], '')
      : '';
    const labName = !_.isNil(_.get(meOutreach, ['labDisplayName'], ''))
      ? _.get(meOutreach, ['labDisplayName'], '')
      : '';
    const visitStatus = _.get(meOutreach, 'visitStatus', null);
    return (
      typeof affiliation === 'string' &&
      affiliation.toLowerCase() === 'illumina' &&
      typeof encounterType === 'string' &&
      encounterType.toLowerCase() === 'cc-intake' &&
      typeof visitStatus === 'string' &&
      visitStatus.toLowerCase() === 'completed' &&
      typeof labName === 'string' &&
      labName.toLowerCase() === 'grail' &&
      typeof testName === 'string' &&
      testName.toLowerCase() === 'galleri'
    );
  };

  render() {
    const { meOutreach, goal } = this.props;
    const { provider, mStartTime } = { ...meOutreach.appointment };
    const encounterType = _.get(meOutreach, ['encounterType'], '');
    const appointmentID = _.get(meOutreach, ['appointmentID'], '');
    const isManualvisit = encounterType == 'visit' && appointmentID == null;
    return (
      <Fragment>
        <div className="followup-appointment-wrapper">
          <div
            className="header followup-appointment-header"
            onClick={() =>
              this.setState({ followupAptmntExpand: !this.state.followupAptmntExpand })}>
            <span>{this.followupAppointmentHeader(meOutreach, provider, mStartTime)}</span>
            <img
              src={require('images/common/arrow-gray.svg')}
              alt=""
              className="accordian-icon"
              style={{
                transform:
                  _.get(this, ['state', 'followupAptmntExpand'], false) === true
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
              }}
            />
          </div>
          <div
            className="content"
            style={{ display: this.state.followupAptmntExpand === true ? 'block' : 'none' }}>
            <div className="static">
              <div className="left">
                <span className="test-status">Results Ready</span>
                <div className="text" data-hj-suppress>
                  {this.followupAppointmentBody(meOutreach, provider, mStartTime)}
                </div>
              </div>
              {encounterType === 'visit' &&
                !isManualvisit && (
                  <div className="right">
                    {this.state.imageLoadingFailed ? (
                      <img className="provider-icon" src={noPrvdrIcon} alt="no provider" />
                    ) : (
                      <img
                        className="provider-icon"
                        src={_.get(provider, ['photoSmall'])}
                        onError={this.handleImageLoadingError}
                      />
                    )}
                  </div>
                )}
            </div>
            <div className="action-items left">
              <ButtonV2
                onClick={() => this.goToScheduling()}
                className={classnames({
                  'btn-fu-aptmnt-low-priority-card': goal == FOLLOWUP_LOW_PRIORITY_CARD,
                  'btn-fu-aptmnt-high-priority-card': goal == FOLLOWUP_HIGH_PRIORITY_CARD,
                })}>
                {encounterType === 'visit' && !isManualvisit
                  ? 'Schedule Follow Up Appointment'
                  : 'Schedule Appointment'}
              </ButtonV2>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FollowupAppointment;
