import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import './SimpleWebinarMessage.scss';

const SimpleWebinarMessage = ({ iconClass, title, subtitle, subcomponent }) => {
  return (
    <div className="sw-message">
      <div className={classnames('sw-message-icon', iconClass)} />
      {title && <div className="sw-message-title">{title}</div>}
      {subtitle && <div className="sw-message-subtitle">{subtitle}</div>}
      {subcomponent && <div className="sw-message-subtitle">{subcomponent}</div>}
    </div>
  );
};

SimpleWebinarMessage.propTypes = {
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subcomponent: PropTypes.object,
};

export default SimpleWebinarMessage;
