import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AlertElement from 'react-bootstrap/lib/Alert';
import classnames from 'classnames';

import './Alert.scss';

const FADE_OUT_TIME = 6000;

class Alert extends Component {
  static propTypes = {
    type: PropTypes.string,
    heading: PropTypes.string,
    message: PropTypes.string,
    fade: PropTypes.bool,
    onClose: PropTypes.func,
    fadeOutTime: PropTypes.number,
  };

  static defaultProps = {
    type: 'danger',
  };

  state = { show: true, fadeStart: false };

  componentDidMount() {
    const { fade, onClose, fadeOutTime } = this.props;
    if (fade) {
      setTimeout(() => {
        if (!this.state.show) return;
        if (onClose) onClose();
        else this.setState({ show: false });
      }, fadeOutTime || FADE_OUT_TIME);
    }
  }

  componentWillUnmount() {
    this.setState({ show: false });
  }

  render() {
    const { message, heading, type, fade, onClose, fadeOutTime, className } = this.props;
    const { show } = this.state;
    const customClassName = classnames('gm-alert', className, { ['fade-out']: fade });
    if ((!message && !heading) || !show) return null;
    return (
      <AlertElement
        className={customClassName}
        bsStyle={type}
        closeLabel="Close"
        onDismiss={onClose}
        style={{
          animation: fade ? `fade ${fadeOutTime || FADE_OUT_TIME}ms ease-in` : 'none',
        }}>
        {heading && <h4 className="heading">{heading}</h4>}
        {message && <p className="message">{message}</p>}
      </AlertElement>
    );
  }
}

export default Alert;
