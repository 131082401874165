import Dexie from 'dexie';
const db = new Dexie('GM_FilesDatabase');

export default () => {
  if (!db.isOpen()) {
    db.version(1).stores({ files: '++id,file' });

    db.version(2).stores({ files: '++id,name,ext,createdAt,file' }).upgrade(trans => {
      trans.files.toCollection().modify(file => {
        file.name = 'Unnamed';
        file.ext = '';
        file.createdAt = new Date();
      });
    });

    db.version(3).stores({ files: '++id,name,ext,createdAt,size,file' }).upgrade(trans => {
      trans.files.toCollection().modify(file => {
        file.size = file.size || 0;
      });
    });
    db.open();
  }
  return db;
};
