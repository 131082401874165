import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';

const startYear = new Date().getFullYear();
const years = _.range(startYear, startYear + 10).map(y => ({ value: y, label: y }));

class ExpirationYear extends Component {
  handleChange = ({ value }) => {
    this.props.input.onChange(value);
  };

  render() {
    const { className, label, disabled, input: { value }, meta: { touched, error } } = this.props;
    return (
      <div className={classnames('gm-form-field', className, { error: touched && error })}>
        <label className="gm-form-label">{(touched && error) || label}</label>
        <div className="gm-form-control">
          <Select
            className="gm-select mp-no-track mp-sensitive"
            value={value}
            options={years}
            onChange={this.handleChange}
            placeholder="Year"
            clearable={false}
            searchable={false}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

ExpirationYear.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ExpirationYear;
