import React, { Component } from 'react';
import classnames from 'classnames';

import MailtoLink from 'components/widgets/Link/MailtoLink';
import { GetStaticPath, isReferralIframe } from 'services/utils';
import './FaxForm.scss';

class FaxForm extends Component {
  renderIframePdf() {
    return (
      <div
        className={classnames('icon-container', {
          hide: !isReferralIframe,
        })}>
        <img
          className="clinician-photo"
          src={require('images/referral/pdf.png')}
          srcSet={`${require('images/referral/pdf@2x.png')} 2x`}
        />
        <a
          className="refer-link"
          target="_blank"
          href={GetStaticPath() + 'patient-referral-form.pdf'}>
          Genome Medical Patient Referral Form.pdf
        </a>
      </div>
    );
  }

  render() {
    let faxText = 'If you prefer to print and fax this form';
    if (isReferralIframe) {
      faxText += '—download our PDF.';
    }

    return (
      <div className="fax-form">
        <div className="block">
          <p className="fax-title">{faxText}</p>

          {this.renderIframePdf()}

          <i className={classnames('pdf-icon fa fa-file-pdf-o', { hide: isReferralIframe })} />
          <a
            className={classnames('refer-link', { hide: isReferralIframe })}
            target="_blank"
            href={GetStaticPath() + 'patient-referral-form.pdf'}>
            Genome Medical Patient Referral Form.pdf
          </a>
        </div>

        <p className="refer-contacts">
          You can send the completed PDF form to us at <MailtoLink /> or fax it to us at{' '}
          <strong>856-961-5323</strong>
        </p>
      </div>
    );
  }
}

export default FaxForm;
