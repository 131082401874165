import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import Button from 'components/forms/controls/Button';
import CommonField from 'components/forms/controls/CommonField';
import { required, email } from 'services/validate';

class WebinarNotAvailableForm extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
  };

  render() {
    const { handleSubmit, onCancel, submitting } = this.props;
    return (
      <form className="na-form" onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            name="email"
            label="E-mail*"
            component={CommonField}
            type="text"
            validate={[required('E-mail'), email]}
          />
        </FormGroup>
        <div className="na-button-container">
          <span className="na-cancel" onClick={onCancel}>
            CANCEL
          </span>
          <Button type="submit" className="na-button" disabled={submitting}>
            SUBMIT
          </Button>
        </div>
      </form>
    );
  }
}

WebinarNotAvailableForm.propTypes = {};

export default reduxForm({
  form: 'WebinarNotAvailableForm',
  // forceUnregisterOnUnmount: true,
  // enableReinitialize: true
})(WebinarNotAvailableForm);
