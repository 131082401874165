import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PrivacyPolicyForm from './PrivacyPolicyForm';
import ConsentForm from './ConsentForm';
import ABNPage from './ABNPage';
import ABNDownloadModal from 'components/modals/SCP/ABNDownLoadModal/ABNDownloadModal';
import { destroy } from 'redux-form';
import { updateConsent } from 'ducks/profile';
import { downloadConsentDocuments } from 'ducks/documents';
import { me } from 'ducks/user';
import { submitAuditLogs } from 'ducks/registrationWizard';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import gaTrack, { GA_TR_DOWNLOAD_PAGE } from '../../../../services/gaTrack';

const ABN_FORM = 'ABN_FORM';
const PRIVACY_FORM = 'PRIVACY_FORM';
const CONSENT_FORM = 'CONSENT_FORM';

class ConsentContainer extends Component {
  state = {
    activeStage: ABN_FORM,
    downloadModalVisible: false,
  };

  componentDidMount() {
    if (this.isABNConsentPending) {
      this.setState({ activeStage: ABN_FORM });
    } else if (this.isConsentPending) {
      this.setState({ activeStage: PRIVACY_FORM });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(destroy('consentForm'));
  }

  goback = goToStage => {
    this.setState({ activeStage: goToStage });
    return false;
  };

  submitToNext = () => {
    const { activeStage } = this.state;
    let nextStage = '';

    if (activeStage == ABN_FORM) {
      nextStage = PRIVACY_FORM;
    } else if (activeStage == PRIVACY_FORM) {
      nextStage = CONSENT_FORM;
    }

    return new Promise(resolve => {
      resolve();
    }).then(() => {
      this.setState({ activeStage: nextStage });
    });
  };

  submitConsentForm = values => {
    const { dispatch } = this.props;
    if (this.isConsentPending) {
      values = {
        ...values,
        consentToTreat: 'consented_via_portal',
      };
    }
    values =
      values.abnDate && moment(values.abnDate, 'MM/DD/YYYY', true).isValid()
        ? { ...values, abnDate: moment(values.abnDate).format('YYYY-MM-DD') }
        : { ...values };
    return dispatch(updateConsent(values, this.isABNConsentPending))
      .then(() => {
        if (this.isABNConsentPending) {
          gaTrack(GA_TR_DOWNLOAD_PAGE);
          this.setState({ downloadModalVisible: true });
        } else {
          dispatch(me());
        }
      })
      .catch(err => {
        if (this.isABNConsentPending) {
          this.setState({ activeStage: ABN_FORM });
        } else {
          this.setState({ activeStage: PRIVACY_FORM });
        }
        throw err;
      });
  };

  get isABNConsentPending() {
    const { isABNConsentPending } = this.props.me;
    return isABNConsentPending;
  }

  get isConsentPending() {
    const { isConsentPending } = this.props.me;
    return isConsentPending;
  }

  get fullName() {
    const { isUnborn, usersGuardian, firstName, lastName } = this.props.me;
    const PatientFullName = `${firstName || ''} ${lastName || ''}`;
    if (isUnborn) {
      const { lastName: guardianLastName } = usersGuardian;
      return `Baby ${guardianLastName || ''}`;
    }
    return PatientFullName;
  }

  get guardianFullName() {
    const { hasGuardian, firstName, lastName, usersGuardian } = this.props.me;

    const PatientFullName = `${firstName || ''} ${lastName || ''}`;
    if (hasGuardian) {
      const { firstName: guardianFirstName, lastName: guardianLastName } = usersGuardian;
      return `${guardianFirstName || ''} ${guardianLastName || ''}`;
    }
    return PatientFullName;
  }

  continueHandler = () => {
    const { dispatch } = this.props;
    const values = { reason: 'Continue' };
    return dispatch(submitAuditLogs(values)).then(() => {
      this.setState({ downloadModalVisible: false }, () => {
        dispatch(me());
      });
    });
  };

  downloadConsentDocs = () => {
    const { dispatch } = this.props;
    dispatch(downloadConsentDocuments());
  };
  renderTosModal = () => {
    return (
      <ABNDownloadModal
        roleModal={'scp-modal'}
        description={
          <div className="abn-content">
            A copy of your signed ABN document has been generated. You can{' '}
            <span
              onClick={() => {
                this.downloadConsentDocs();
              }}>
              download here{' '}
            </span>or access it through your patient portal.
          </div>
        }
        show={true}
        confirmText="Continue"
        onConfirm={() => this.continueHandler()}
        onHide={() => this.setState({ downloadModalVisible: false })}
      />
    );
  };

  get formError() {
    const { formError } = this.props;
    if (this.isABNConsentPending && this.state.activeStage == PRIVACY_FORM) {
      return undefined;
    }
    return formError;
  }

  render() {
    const { activeStage } = this.state;
    return (
      <Fragment>
        {activeStage === ABN_FORM && (
          <ABNPage
            onSubmit={this.isConsentPending ? this.submitToNext : this.submitConsentForm}
            formError={this.formError}
            fullName={this.fullName}
            guardianFullName={this.guardianFullName}
          />
        )}
        {activeStage === PRIVACY_FORM && (
          <PrivacyPolicyForm
            onSubmit={this.submitToNext}
            formError={this.formError}
            goBack={() => this.goback(ABN_FORM)}
            hideGoBack={!this.isABNConsentPending}
          />
        )}
        {activeStage === CONSENT_FORM && (
          <ConsentForm
            onSubmit={this.submitConsentForm}
            formError={this.formError}
            goBack={() => this.goback(PRIVACY_FORM)}
          />
        )}
        {this.state.downloadModalVisible && this.renderTosModal()}
        <GlobalLoader />
      </Fragment>
    );
  }
}

ConsentContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(
  state => {
    return {
      me: state.user.me,
      formError: state.profile.statusMessage,
    };
  },
  dispatch => {
    return { dispatch };
  }
)(ConsentContainer);
