import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import track from 'services/track';
import classnames from 'classnames';
import AppointmentServiceOptions from '../../../../../components/scp/scheduling/AppointmentDetails/AppointmentOptions/AppointmentServiceOptionsV2';
import _ from 'lodash';
import Button from 'components/forms/controls/ButtonV2';
import SelectSpeciality from './SelectSpeciality';
import { selectDefaultSpecialityByUser, selectSpecialityByUser } from 'ducks/scheduling';

class SelectServicePanel extends Component {
  state = {
    showSpeciality: false,
    tempSpeciality: '',
    previousSelectedSpeciality: '',
  };

  componentDidUpdate(prevProps) {
    if(prevProps.selected != this.props.selected && this.props.selected == this.props.type){
      const {
        user: { me },
      } = this.props;
      const { partnerSpecialties } = me;
      this.setIsSpecialitySelected(false);
      let selectedSpecialty;
      let defaultCalendarSpeciality = _.get(this.props, 'user.me.vsee_specialty', null);
      if (partnerSpecialties.length === 1) {
        selectedSpecialty = partnerSpecialties[0].name;
        this.setIsSpecialitySelected(this.props.selected);
      } else if (
        (partnerSpecialties || []).find((e) => e && e.name && e.name == defaultCalendarSpeciality)
      ) {
        selectedSpecialty = defaultCalendarSpeciality;
        this.setIsSpecialitySelected(this.props.selected);
      }
      this.props.dispatch(selectDefaultSpecialityByUser(selectedSpecialty));
      this.setShowSpeciality(false);
      this.setTempSepciality('');
    }
  }


  onClick = () => {
    this.props.setSelected(this.props.type);
  };

  onServiceClick = (header) => {
    if (header) track('Clicked on ' + header);
    return this.onClick();
  };

  setShowSpeciality = (value) => {
    this.setState({
      showSpeciality: value,
      previousSelectedSpeciality: value ? this.state.tempSpeciality : null,
    });
  };
  setPreviousSpeciality = (value) => {
    this.setState({
      previousSelectedSpeciality: value,
    });
  };

  setTempSepciality = (value) => {
    this.setState({
      tempSpeciality: value,
    });
  };

  setIsSpecialitySelected = (value) => {
    this.props.setIsSpecialitySelected(value);
  };

  formatPrice = (price) => {
    return isNaN(price)
      ? price
      : new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(price);
  };

  onFinalPurchaseClick = () => {
    this.props.onPurchaseClick(this.props.path);
  };

  closeAppointmentServiceOption = () => {
    this.props.setSelected(null);
  };

  handleSpecilitiesNext = () => {
    this.setIsSpecialitySelected(this.props.selected);
    this.props.dispatch(selectSpecialityByUser(this.state.tempSpeciality));
    this.setShowSpeciality(false);
  };

  handleSpecilitiesBack = () => {
    this.setTempSepciality(this.state.previousSelectedSpeciality);
    this.setShowSpeciality(false);
  };

  render() {
    const { header, description, iconClassName, type, isMobile, user } = this.props;
    const { me } = user;

    const { partnerSpecialties, vsee_specialty } = me;
    let selectedSpecialty;
    if (this.state.tempSpeciality) {
      selectedSpecialty = this.state.tempSpeciality;
    } else {
      let defaultCalendarSpeciality = vsee_specialty;
      if (partnerSpecialties.length === 1) {
        selectedSpecialty = partnerSpecialties[0].name;
      } else if (
        (partnerSpecialties || []).find((e) => e && e.name && e.name === defaultCalendarSpeciality)
      ) {
        selectedSpecialty = defaultCalendarSpeciality;
      }
    }

    const showAppointmentOptions = type === this.props.selected;
    return (
      <div className="panel-container">
        {showAppointmentOptions && this.state.showSpeciality ? (
          <SelectSpeciality
            iconClassName={iconClassName}
            isMobile={isMobile}
            partnerSpecialties={partnerSpecialties}
            selectedSpecialty={selectedSpecialty}
            setTempSepciality={this.setTempSepciality}
            handleSpecilitiesBack={this.handleSpecilitiesBack}
            handleSpecilitiesNext={this.handleSpecilitiesNext}
            tempSpeciality={this.state.tempSpeciality}
          />
        ) : (
          <div
            className={classnames('selector-panel v2', { unselected: !showAppointmentOptions })}
            onClick={() => {
              return !showAppointmentOptions ? this.onServiceClick(header) : () => {};
            }}
          >
            <div className="service-content">
              {!_.isEmpty(iconClassName) && ((isMobile && !showAppointmentOptions) || !isMobile) ? (
                <div
                  className={classnames(
                    'icon-contain',
                    iconClassName === 'icon-follow-up' ? 'has-follow-up' : ''
                  )}
                >
                  <div className={classnames('icon-section', iconClassName)} />
                </div>
              ) : (
                ''
              )}
              <div className="name-section">
                <h4
                  className={classnames('service-name', {
                    'service-name__no-icon': !iconClassName,
                  })}
                >
                  {header}
                </h4>
                {!_.isEmpty(description) ? (
                  <div className="service-description v2">{description || ''}</div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {showAppointmentOptions ? (
              <div className="appointment-content">
                <AppointmentServiceOptions
                  header={header}
                  type={type}
                  onFinalPurchaseClick={this.onFinalPurchaseClick}
                  path={this.props.path}
                  isArrowVisible={false}
                  selected={this.props.selected}
                  showAppointmentOptions={showAppointmentOptions}
                  key={'appointment_service_option' + this.props.key}
                  isMobile={isMobile}
                  setShowSpeciality={this.setShowSpeciality}
                  isSpecialtySelected={this.props.isSpecialtySelected}
                  selectedSpecialty={selectedSpecialty}
                />
              </div>
            ) : null}
          </div>
        )}
        {isMobile && showAppointmentOptions && (
          <div className="outer-button outline">
            <Button
              className=""
              variant="secondary"
              onClick={() => {
                this.state.showSpeciality
                  ? this.handleSpecilitiesBack()
                  : this.closeAppointmentServiceOption();
              }}
            >
              {'Back'}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

SelectServicePanel.propTypes = {
  header: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  oldPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  path: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.array,
  disabled: PropTypes.bool,
  onPurchaseClick: PropTypes.func.isRequired,
  showControls: PropTypes.bool.isRequired,
  insuranceEnabled: PropTypes.bool,
  is_payment_mode_insurance_only: PropTypes.bool,
  selected: PropTypes.string,
  preSelected: PropTypes.string,
  zeroPriceText: PropTypes.string,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  scheduling: state.scheduling,
  user: state.user,
});

SelectServicePanel.defaultProps = {
  isMobile: false,
};

export default connect(mapStateToProps)(SelectServicePanel);

