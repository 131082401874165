import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';

//import PageTitle from 'components/widgets/PageTitle/PageTitle';
import AppointmentDetails from 'components/scp/scheduling/AppointmentDetails/AppointmentDetails';
import {
  rescheduleAppointment,
  loadAvailableAppointments,
  updateSelectedServiceForReschedule,
  getNewPrice,
  clearCoupon,
  setDefaultVseeSpecialty,
  selectModalityByUser,
  loadAvailableDates,
  clearSchedulingError,
  setDefaultStateAndTimezone
} from 'ducks/scheduling';
import { SchedulingPlatformTypes } from 'constants/CommonConstants';
import ModalDialog from 'components/modals/ModalDialog';
import warningIcon from 'containers/pages/public/Scp/TestOrderRequestConfirm/warning-red.svg';
import { setModalMode } from '../../../../../ducks/modals';
import ModalNames from '../../../../../constants/ModalNames';
import AppointmentReschedulingFailedModal from 'components/modals/AppointmentReschedulingFailedModal';


class ReschedulePageBody extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    appointment: PropTypes.object.isRequired,
    scheduling: PropTypes.object.isRequired,
    loadAvailableAppointments: PropTypes.func.isRequired,
    updateSelectedService: PropTypes.func.isRequired,
    newPrice: PropTypes.number,
    clearCouponIfExist: PropTypes.func,
  };

  componentDidMount() {
    const {
      updateSelectedService,
      appointment,
      scheduling: { selectedProduct, selectedServiceDescriptor },
      clearCouponIfExist,
      dispatch,
    } = this.props;
    if (_.isEmpty(appointment) || !appointment.isFuture) {
      browserHistory.push('/patient');
      return;
    } else {
      dispatch(setDefaultVseeSpecialty(appointment.vsee_specialty));
      dispatch(selectModalityByUser(appointment.byPhone));
      dispatch(setDefaultStateAndTimezone(appointment.country,appointment.state,appointment.timezone))
    }
    if (!selectedServiceDescriptor || !selectedProduct) updateSelectedService();
    clearCouponIfExist();
    this.loadAppointmentsIfNeeded(this.props);
  }

  loadAppointmentsIfNeeded() {
    const {
      isSchedulingLoaded,
      calendarState,
      calendarTimezone,
      calendarSpeciality,
      selectedProduct,
    } = this.props.scheduling;
    const { me } =this.props.user;
    if (
      !this.appointmentsLoaded &&
      isSchedulingLoaded &&
      selectedProduct &&
      calendarState &&
      calendarTimezone &&
      calendarSpeciality
    ) {

      this.props.loadAvailableAppointments();
      this.appointmentsLoaded = true;
    }
  }

  rescheduleAppointment = () => this.props.dispatch(rescheduleAppointment());

  handleModalClose = () =>{
    this.props.dispatch(setModalMode(ModalNames.PioneerConfigrationModal,{open:false}));
    this.props.dispatch(clearSchedulingError());
  }

  render() {
    const { newPrice,modals,scheduling } = this.props;
    const { schedulingError } = scheduling;
    return (
      <div>
        {/* <PageTitle
          title="Reschedule your current appointment"
          description="If you need help, please call us at 1 (877) 688-0992, or chat with us."
        /> */}
        {this.props.scheduling.selectedProduct && (
          <AppointmentDetails
            scheduleAction={this.rescheduleAppointment}
            isRescheduling={true}
            newPrice={newPrice}
          />
        )}
        <ModalDialog
          className={`book-appointment-error-modal`}
          modalHeader={
            <Fragment>
              <img src={warningIcon} alt="Warning" class="warning-icon" />Appointment Scheduling Failed
            </Fragment>
          }
          show={modals.PioneerConfigrationModal.open}
          onHide={this.handleModalClose}>
          <div className="wrapper">
            <div className="book-appointment-error-content">
              <p>{schedulingError && schedulingError}</p>
            </div>
          </div>
        </ModalDialog>
        <AppointmentReschedulingFailedModal
            show={modals.AppointmentReschedulingFailedModal?.open}
            closeCallback={() => {
              this.props.dispatch(
                setModalMode('AppointmentReschedulingFailedModal', { open: false })
              );
            }}
          />
      </div>
    );
  }
}

export default connect(
  ({ scheduling, appointment, user, modals }) => ({ scheduling, user, appointment: appointment.data, modals }),
  dispatch => ({
    dispatch,
    newPrice: dispatch(getNewPrice()),
    loadAvailableAppointments: () => dispatch(loadAvailableAppointments()),
    updateSelectedService: () => dispatch(updateSelectedServiceForReschedule()),
    clearCouponIfExist: () => dispatch(clearCoupon()),
    loadAvailableDates: () => dispatch(loadAvailableDates()),

  })
)(ReschedulePageBody);
