import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import Button from '../../controls/ButtonV2';
import NateraBodyContent from './NateraBodyContent';
import './NateraConsents.scss'

class NateraConsentsForm extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { handleSubmit, onSubmit } = this.props;
        return (
            <section className="natera-form-container">
                <section className="natera-wrapper">
                    <section className="natera-header">
                        <div className="genome-header-logo">
                            <img className="genome-header-img" src={require('images/logo.svg')} />
                        </div>
                    </section>
                    <section className="natera-body">
                        <NateraBodyContent />
                        <div className="agree-container">
                            <div className="buttons-containers">
                                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
                                    <div className="action-buttons">
                                        <button onClick={this.props.goBack} className="btn-back">
                                            Back
                                        </button>
                                        <button type="submit" className="btn-next">
                                            I Agree & Continue
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </section>
            </section>
        );
    }
}

NateraConsentsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    formError: PropTypes.string,
};

export default reduxForm({
    form: 'natera-consnt-form',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(NateraConsentsForm);
