import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import './GlobalLoader.scss';
import gmiLoader from '../../../images/gmi-loader.gif';

export const Loader = ({ loaderActive }) => {
  return (
    <Modal bsClass="modal" show={loaderActive} role="global-loader">
      <div className="loader-body">
        <div className="thin-loader">
          <img src={gmiLoader} alt="" />
        </div>
      </div>
    </Modal>
  );
};

Loader.propTypes = {
  loaderActive: PropTypes.bool,
  loaderText: PropTypes.string,
};

export default connect(({ ui }) => ({ loaderActive: ui.loaderActive, loaderText: ui.loaderText }))(
  Loader
);
