const SELECT_SERVICE = 'gm/services/SELECT_SERVICE';

export default function reducer(state = {selectedServiceIdx: null}, action = {}) {
  switch (action.type) {
    case SELECT_SERVICE:
      return {...state, selectedServiceIdx: action.payload};
    default: return state;
  }
}

export function selectService(index) {
  return {type: SELECT_SERVICE, payload: index};
}
