import _ from 'lodash';
import webinarService, {
  POST_WEBINAR_TESTING,
  POST_WEBINAR_NO_TESTING,
} from 'services/api/webinarService';

export const REGISTER_FOR_THE_PROGRAM_STEP = 'gm/webinar/REGISTER_FOR_THE_PROGRAM_STEP';
export const VIEW_WEBINAR_STEP = 'gm/webinar/VIEW_WEBINAR_STEP';
export const POST_WEBINAR_OPTIONS_STEP = 'gm/webinar/POST_WEBINAR_OPTIONS_STEP';
export const YOUR_NEXT_STEPS_STEP = 'gm/webinar/YOUR_NEXT_STEPS_STEP';
export const SCHEDULE_FOLLOW_UP_STEP = 'gm/webinar/SCHEDULE_FOLLOW_UP_STEP';

const STEPS = [
  REGISTER_FOR_THE_PROGRAM_STEP,
  VIEW_WEBINAR_STEP,
  POST_WEBINAR_OPTIONS_STEP,
  YOUR_NEXT_STEPS_STEP,
];

// Action types
const LOAD_WEBINAR = 'gm/webinar/LOAD_WEBINAR';
const LOAD_WEBINAR_COMPLETE = 'gm/webinar/LOAD_WEBINAR_COMPLETE';
const LOAD_WEBINAR_ERROR = 'gm/webinar/LOAD_WEBINAR_ERROR';
const ADD_GUIDE_STEP = 'gm/webinar/ADD_GUIDE_STEP';
const FETCH_GUIDE_PROGRESS_START = 'gm/webinar/FETCH_MY_GUIDE_PROGRESS_START';
const FINISH_GUIDE_STEP_2 = 'gm/webinar/FINISH_GUIDE_STEP_2';
const FINISH_GUIDE_STEP_3 = 'gm/webinar/FINISH_GUIDE_STEP_3';
const FINISH_GUIDE_STEP_4 = 'gm/webinar/FINISH_GUIDE_STEP_4';
const FETCH_GUIDE_PROGRESS_FINISH = 'gm/webinar/FETCH_MY_GUIDE_PROGRESS_FINISH';

const FINISH_SEND_INTEREST_MAIL = 'gm/webinar/FINISH_SEND_INTEREST_MAIL';

function finishTheStep(stepIdToFinish, state = {}) {
  const steps = _.isEmpty(state)
    ? STEPS.map(stepId => ({
        id: stepId,
        done: stepId === stepIdToFinish,
      }))
    : state.steps.map(s => ({
        id: s.id,
        done: s.done || s.id === stepIdToFinish,
      }));
  const activeStep = steps.find(step => !step.done);
  return {
    steps,
    activeStepId: activeStep ? activeStep.id : null,
  };
}

export const getInitialState = () => ({
  data: undefined, // please keep undefined
  loading: false,
  loaded: false,
  guide: finishTheStep(REGISTER_FOR_THE_PROGRAM_STEP),
});

export default function(state = getInitialState(), action = {}) {
  switch (action.type) {
    case LOAD_WEBINAR:
      return { ...state, loading: true, loaded: false };
    case LOAD_WEBINAR_COMPLETE:
      return {
        ...state,
        data: {
          ...action.payload,
        },
        loading: false,
        loaded: true,
      };
    case LOAD_WEBINAR_ERROR:
      return { ...state, data: null, loading: false, loaded: true };
    case ADD_GUIDE_STEP:
      return {
        ...state,
        guide: {
          ...state.guide,
          steps: [...state.guide.steps, { id: action.payload, done: false }],
        },
      };
    case FETCH_GUIDE_PROGRESS_START:
      return {
        ...state,
        guide: { ...state.guide, fetchingProgress: true },
      };
    case FINISH_GUIDE_STEP_2:
      return {
        ...state,
        guide: finishTheStep(VIEW_WEBINAR_STEP, state.guide),
      };
    case FINISH_GUIDE_STEP_3:
      return {
        ...state,
        guide: finishTheStep(POST_WEBINAR_OPTIONS_STEP, state.guide),
      };
    case FINISH_GUIDE_STEP_4:
      return {
        ...state,
        guide: finishTheStep(YOUR_NEXT_STEPS_STEP, state.guide),
      };
    case FETCH_GUIDE_PROGRESS_FINISH:
      return {
        ...state,
        guide: { ...state.guide, fetchingProgress: false },
      };
    default:
      return state;
  }
}

export function loadCurrentWebinar() {
  return (dispatch, getState) => {
    if (getState().webinar.loading) return;
    dispatch({ type: LOAD_WEBINAR });
    return webinarService
      .loadCurrentWebinar()
      .then(webinar => {
        dispatch({ type: LOAD_WEBINAR_COMPLETE, payload: webinar });
        if (webinar.postAction) {
          if (webinar.postAction === POST_WEBINAR_NO_TESTING) {
            const error = new Error('Patient opted out this webinar');
            error.isOptOut = true;
            throw error;
          } else {
            dispatch(fetchGuideProgressStart());
            dispatch(finishViewWebinar());
            if (webinar.postAction === POST_WEBINAR_TESTING) {
              dispatch(addScheduleGuideStep());
              dispatch(finishPostWebinarOptions());
            }
            dispatch(fetchGuideProgressFinish());
          }
        }
        if (webinar.testResultsAvailable) {
          dispatch(addScheduleGuideStep());
          dispatch(fetchGuideProgressStart());
          dispatch(finishViewWebinar());
          dispatch(finishPostWebinarOptions());
          dispatch(finishNextSteps());
          dispatch(fetchGuideProgressFinish());
        }
      })
      .catch(error => {
        dispatch(loadWebinarError());
        if (error.status !== 404) throw error;
      });
  };
}

export function sendPostAction(id, action, extra) {
  return dispatch => {
    return webinarService
      .postAction(id, action, extra)
      .then(() => dispatch(loadCurrentWebinar()))
      .then(() => {
        if (action === POST_WEBINAR_TESTING) {
          dispatch(addScheduleGuideStep());
          dispatch(fetchGuideProgressStart());
          dispatch(finishPostWebinarOptions());
          dispatch(fetchGuideProgressFinish());
        }
      })
      .catch(error => {
        if (error.submissionError) throw error.submissionError;
        throw error;
      });
  };
}

export function sendIndicatedInterest(id, params) {
  return dispatch => {
    return webinarService
      .indicateInterest(id, params)
      .then(() => {
        dispatch(fetchGuideProgressStart());
        dispatch(finishSetInterestMail());
        dispatch(fetchGuideProgressFinish());
      })
      .catch(error => {
        throw error;
      });
  };
}

function addScheduleGuideStep() {
  return dispatch => {
    if (!STEPS.includes(SCHEDULE_FOLLOW_UP_STEP)) {
      STEPS.push(SCHEDULE_FOLLOW_UP_STEP);
      dispatch(addGuideStep(SCHEDULE_FOLLOW_UP_STEP));
    }
  };
}

function addGuideStep(stepId) {
  return { type: ADD_GUIDE_STEP, payload: stepId };
}

export function fetchGuideProgressStart() {
  return { type: FETCH_GUIDE_PROGRESS_START };
}

export function finishViewWebinar() {
  return { type: FINISH_GUIDE_STEP_2 };
}

export function finishPostWebinarOptions() {
  return { type: FINISH_GUIDE_STEP_3 };
}

export function finishNextSteps() {
  return { type: FINISH_GUIDE_STEP_4 };
}

export function finishSetInterestMail() {
  return { type: FINISH_SEND_INTEREST_MAIL };
}

export function loadWebinarError() {
  return { type: LOAD_WEBINAR_ERROR };
}

export function fetchGuideProgressFinish() {
  return { type: FETCH_GUIDE_PROGRESS_FINISH };
}
