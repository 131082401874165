import _ from 'lodash';
import React from 'react';
import Button from 'components/forms/controls/ButtonV2';
import classnames from 'classnames';
import './ConfirmAppointmentForm.scss';
import { reduxForm } from 'redux-form';
import Alert from '../../../widgets/Alert/Alert';
import ConsentDetail from '../SignupForm/ConsentDetail';

const ConfirmAppointmentForm = (props) => {
  const { handleSubmit, toPreviousStep, formError, testDetail } = props;
  let testDisplayName = _.get(testDetail, ['testDisplayName'], '');
  if (_.get(testDetail, ['PatientDisplayName'])) {
    testDisplayName = _.get(testDetail, ['PatientDisplayName']);
  }
  const testTypeName = `${_.get(testDetail, ['labDisplayName'], '')} ${testDisplayName}`;
  let testPrice = _.get(testDetail, ['price'], '');
  if (testPrice) {
    testPrice = parseInt(testPrice);
  }

  return (
    <div className={classnames('test-request-wizard scp-confirm-form')}>
      <h2 className="gm-select-service__header">{'Review Request'}</h2>
      <div className="confirm-form-content">
        <div className="confirm-form-items">
          <div className="confirm-content-item">
            <div className="gm-service-text__grey">Order Type</div>
            <div className="gm-service-text">Test Request</div>
          </div>

          <div className="confirm-content-item">
            <div className="gm-service-text__grey">Test Type</div>
            <div className="gm-service-text">{testTypeName}</div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className={classnames('review-form')}>
        <div className="cancellation-policy-section">
          <div className="visit-amount-section" data-hj-suppress>
            <div className="total-fee-section">
              <span>Test Authorization Fee:</span>
              <span className="visit-fee">{testPrice && `$${testPrice}`}</span>
            </div>
          </div>
        </div>
        <ConsentDetail type="review" extraClassname="review" />
        {formError && <Alert message={formError} />}

        <div className="buttons">
          {toPreviousStep && (
            <Button className="button button-back" onClick={toPreviousStep}>
              Back
            </Button>
          )}
          <Button className="button button-next book-appointment" type="submit">
            Request Test
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'review-all-detail-form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ConfirmAppointmentForm);
