import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import InputField from 'components/forms/controls/V2/InputField';
import { required } from 'services/validate';
import './EmailVerificationForm.scss';
import _ from 'lodash';
import Alert from 'components/widgets/Alert/Alert';
import gaTrack, { GA_TR_SOFTREGISTRATION_VERIFY_PAGE } from 'services/gaTrack';

class EmailVerificationForm extends Component {
  state = {
    attempt: 0,
    disableResendCode: false,
  };
  componentDidMount() {
    gaTrack(GA_TR_SOFTREGISTRATION_VERIFY_PAGE);
  }

  onSubmit = values => {
    return this.props.onSubmit(values);
  };

  handleResendCode = () => {
    this.setState({ disableResendCode: true });
    return this.props
      .resendVerificationCode()
      .then(res => {
        this.setState(prevState => {
          return { attempt: prevState.attempt + 1, disableResendCode: false };
        });
        return res;
      })
      .catch(error => {
        this.setState({ disableResendCode: false });
        return error;
      });
  };

  render() {
    const { handleSubmit, submitting, valid, softRegistrationData, formError } = this.props;
    const { attempt, disableResendCode } = this.state;
    return (
      <section className="email-verification-wrapper">
        <form className="email-verification-form" onSubmit={handleSubmit(this.onSubmit)}>
          {formError && <Alert message={formError} />}
          <div className="row-section">
            <div className="col-section">
              <div className="header-text">
                {attempt > 0 ? 'We sent another code' : 'Verify your email address'}
              </div>
              <div className="header-sub-text" data-hj-suppress>
                Enter the verification code we sent to{' '}
                <span className="email-text">{_.get(softRegistrationData, 'email', '')}</span>. If
                you do not see it, check your spam folder.
              </div>
              <div className="resend-code">
                <Link
                  className={`${disableResendCode ? 'is-disabled' : ''}`}
                  onClick={disableResendCode ? null : this.handleResendCode}>
                  Resend the code
                </Link>
              </div>
            </div>
            <div className="col-section verification-input-container">
              <div className="label-section required">Verification Code</div>
              <div className="input-section">
                <Field
                  name="code"
                  type="text"
                  placeholder="123456"
                  trim={true}
                  component={InputField}
                  validate={[required('Verification Code')]}
                />
              </div>
            </div>
          </div>
          <div className="row-section">
            <div className="email-verification-button-container">
              <ButtonV2
                className="verification-button"
                type="submit"
                disabled={submitting || !valid}>
                Verify
              </ButtonV2>
            </div>
          </div>
        </form>
      </section>
    );
  }
}

EmailVerificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  softRegistrationData: PropTypes.object,
  resendVerificationCode: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'EmailVerification',
})(EmailVerificationForm);
