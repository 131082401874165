import _ from 'lodash';
const automatedApptPretest = 'automated_appt_pretest';
const automatedPretest = 'automated_pretest';
const automatedGCReview = 'automated_gcreview';
const apptResults = 'appt_results';
const apptPretest = 'appt_pretest';
const partnerInitiatedPreTest = 'partner_initiated/pre_test';
const testOrderSubTypes = [automatedPretest, automatedGCReview];
const appointmentSubTypes = [automatedApptPretest, apptResults, apptPretest];
export function hydrateEncounter(res) {
  const isTestOrderSubType = testOrderSubTypes.includes(res.subtype);
  const isAppointmentSubType = appointmentSubTypes.includes(res.subtype);
  const isTROEncounter = res.subtype == partnerInitiatedPreTest;
  const isGCNEncounter = isTestOrderSubType || isAppointmentSubType;

  return {
    id: res.id,
    labName: res.lab_name,
    testName: res.test_name,
    subType: res.subtype,
    isTestOrderSubType,
    isAppointmentSubType,
    isTROEncounter,
    isGCNEncounter,
    scpOrderConsent: res.scp_order_consent,
    needConsent: !!(isTestOrderSubType && !res.scp_order_consent),
    order: res.order && hydrateTestOrder(res.order),
    needAppointment: !!(isAppointmentSubType && isAppointmentRequired(res)),
    createdAt: res.created_at && new Date(res.created_at),
    useInsuranceForTest: res.use_insurance_for_test,
  };
}

export function dehydrateEncounter(data) {
  return _.omitBy(
    {
      scp_order_consent: data.scpOrderConsent,
    },
    _.isUndefined
  );
}

export const hydrateEncounters = data => data.map(d => hydrateEncounter(d));

export const hydrateTestOrder = res => {
  return {
    dateTestAuthorized: res.date_test_authorized,
    dateTestOrdered: res.date_test_ordered,
    orderingPhysician: res.ordering_physician,
    orderingPhysicianNpi: res.ordering_physician_npi,
    status: res.status,
  };
};

export const isAppointmentRequired = res => {
  const { appointments } = res;
  const isExist = _.find(appointments, function(appointment) {
    return ['booked', 'completed'].includes(appointment.latest_status.status);
  });
  return !isExist;
};
