import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/forms/controls/Button';
import Modal from 'components/modals/SCP/ModalDialog';

import './ConfirmActionModal.scss';

const ConfirmActionModal = props => {
  const { onConfirm, onCancel, show, confirmText, header, roleModal, modalHeader } = props;
  const confirmButtonText = confirmText ? confirmText : 'Ok';
  return (
    <Modal
      className="scp-confirm-modal scp-md368"
      modalHeader={modalHeader}
      onHide={onCancel}
      show={show}
      roleModal={roleModal}>
      <div className="scp-confirm-modal-body">
        <h2 className="confirm-title">{header}</h2>
        <div className="confirm-buttons">
          <span className="cancel-button" onClick={onCancel}>
            Cancel
          </span>
          <Button onClick={onConfirm}>{confirmButtonText}</Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmActionModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  text: PropTypes.string,
  confirmButtonText: PropTypes.string,
  roleModal: PropTypes.string,
  modalHeader: PropTypes.string,
};

export default ConfirmActionModal;
