import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import warn from 'components/forms/PasswordStrength/warn';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import InputField from 'components/forms/controls/V2/InputField';
import SelectField from 'components/forms/controls/V2/SelectField';
import CheckBoxField from 'components/forms/controls/V2/CheckBoxField';
import PhoneNumberField from 'components/forms/controls/V2/PhoneNumberField';
import InputMaskField from 'components/forms/controls/V2/InputMaskField';
import {
  required,
  matchPassword,
  phoneOrEmpty,
  strongPassword,
  dateOfBirth,
  patientName,
} from 'services/validate';
import 'components/forms/controls/V2/CheckBoxField.scss';
import './FullRegistrationForm.scss';
import moment from 'moment';
import PasswordRequirementsTooltip from 'components/forms/PasswordStrength/PasswordRequirementsTooltip';
import { Tooltip } from 'gm-element-react';
import Alert from 'components/widgets/Alert/Alert';
import { PhoneTypes } from 'constants/CommonConstants';

class FullRegistrationForm extends Component {
  state = {
    primaryPhoneType: null,
  };

  onSubmit = values => {
    values =
      values.dob && moment(values.dob, 'MM/DD/YYYY', true).isValid()
        ? { ...values, dob: moment(values.dob).format('YYYY-MM-DD') }
        : { ...values };
    return this.props.onSubmit({ ...values });
  };

  handlePrimaryPhoneType = value => {
    this.setState({ primaryPhoneType: value }, () => {
        if (value) {
          this.props.change('primaryPhoneConsentToText', true);
        } else {
          this.props.change('primaryPhoneConsentToText', false);
        }
    });
  };

  render() {
    const { handleSubmit, submitting, valid, formError } = this.props;
     let patientConsentToText = this.state.primaryPhoneType;

    return (
      <form className="full-registraion-form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="row-section">
          <div className="col-section col-width-50">
            <div className="password-field-wrapper">
              <div className="label-section required">Password</div>
              <Tooltip
                className="set-pwd-tooltip"
                effect="dark"
                content={<PasswordRequirementsTooltip />}
                placement="top">
                <img className="arrow-left" src={require('images/more-info.svg')} />
              </Tooltip>
            </div>
            <div className="input-section">
              <Field
                name="password"
                type="password"
                placeholder="********"
                trim={true}
                component={InputField}
                validate={[required('Password'), strongPassword]}
              />
            </div>
          </div>

          <div className="col-section col-width-50">
            <div className="label-section required">Confirm Password</div>
            <div className="input-section">
              <Field
                name="passwordRepeat"
                type="password"
                placeholder="********"
                trim={true}
                component={InputField}
                validate={[required('Confirm Password')]}
              />
            </div>
          </div>
        </div>
        <div className="row-section">
          <div className="col-section col-width-50">
            <div className="label-section required">Date of Birth</div>
            <div className="input-section">
              <Field
                name="dob"
                placeholder="MM/DD/YYYY"
                component={InputMaskField}
                mask="99/99/9999"
                maskChar={null}
                validate={[required('Date of Birth'), dateOfBirth()]}
              />
            </div>
          </div>

          <div className="col-section col-width-50">
            <div className="label-section required">Sex Assigned At Birth</div>
            <div className="input-section">
              <Field
                name="sexAssignedAtBirth"
                type="select"
                component={SelectField}
                placeholder="Select"
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Intersex', value: 'intersex' },
                ]}
                validate={[required('Sex Assigned At Birth')]}
              />
            </div>
          </div>
        </div>

        <div className="row-section">
          <div className="col-section col-width-50">
            <div className="label-section required">First Name</div>
            <div className="input-section">
              <Field
                name="firstName"
                type="text"
                disabled={true}
                placeholder="Elindoro"
                trim={true}
                component={InputField}
                validate={[required('First Name'), patientName]}
              />
            </div>
          </div>

          <div className="col-section col-width-50">
            <div className="label-section required">Last Name</div>
            <div className="input-section">
              <Field
                name="lastName"
                type="text"
                disabled={true}
                placeholder="Saltalamacchia"
                trim={true}
                component={InputField}
                validate={[required('Last Name'), patientName]}
              />
            </div>
          </div>
        </div>
        <div className="row-section">
          <div className="col-section">
            <div className="label-section required">Email</div>
            <div className="input-section">
              <Field
                name="email"
                type="text"
                disabled={true}
                placeholder="Elindoro@email.com"
                trim={true}
                component={InputField}
                validate={[required('Email')]}
              />
            </div>
          </div>
        </div>
        <div className="row-section">
          <div className="col-section col-width-50">
            <div className="label-section required">Primary Phone</div>
            <div className="input-section">
              <Field
                name="primaryPhone"
                type="text"
                placeholder="+1 (123) 456 - 7890"
                trim={true}
                component={PhoneNumberField}
                mask="+1 (999) 999 - 9999"
                maskChar={null}
                disabled={false}
                validate={[phoneOrEmpty, required('Phone Number')]}
              />
            </div>
          </div>
          <div className="col-section col-width-50">
            <div className="label-section">Primary Phone Type</div>
            <div className="input-section">
              <Field
                name="primaryPhoneType"
                type="select"
                component={SelectField}
                placeholder="Select"
                options={PhoneTypes}
                onChange={(e, value) => this.handlePrimaryPhoneType(value)}
              />
            </div>
          </div>
        </div>
        {patientConsentToText && (
          <div className="row-section consent-to-text">
            <div className="col-section full-col">
              <div className="label-section">Consent to Text</div>
              <div className="input-section consent-to-text">
                <Field
                  name={'primaryPhoneConsentToText'}
                  type="checkbox"
                  label={
                    <Fragment>
                      <span className="checkmark" />
                      <div className="label-section">
                        {`By checking this box, I agree to receive automated text notifications from
                        Genome Medical. The notifications may be about appointments, test results,
                        and more.`
                          }
                      </div>
                    </Fragment>
                  }
                  component={CheckBoxField}
                />
              </div>
            </div>
          </div>
        )}
        {formError && <Alert message={formError} />}
        <ButtonV2 className="submit-button" type="submit" disabled={submitting || !valid}>
          Create Account
        </ButtonV2>
      </form>
    );
  }
}

FullRegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  referral: PropTypes.string,
  valid: PropTypes.bool,
};

export default reduxForm({
  form: 'FullRegistration',
  validate: matchPassword,
  warn,
})(FullRegistrationForm);
