
export const ELIGIBILITY_CHECK = 'ELIGIBILITY_CHECK';
export const SELECTION_DETAILS = 'SELECTION_DETAILS';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const STAGE_APPOINTMENT_DETAILS = 'STAGE_APPOINTMENT_DETAILS';
export const STAGE_CLIENT_INFO = 'STAGE_CLIENT_INFO';
export const STAGE_PAYMENT_INFO = 'STAGE_PAYMENT_INFO';
export const STAGE_INSURANCE_INFO = 'STAGE_INSURANCE_INF';
export const STAGE_REVIEW_PAYMENT = 'STAGE_REVIEW_PAYMENT';
export const STAGE_SHIPPING_ADDRESS = 'STAGE_SHIPPING_ADDRESS';
export const STAGE_THANK_YOU = 'STAGE_THANK_YOU';
export const STAGE_HOME_ADDRESS = 'STAGE_HOME_ADDRESS';

export const STAGE_WEBINAR_CLIENT_INFO = 'STAGE_WEBINAR_CLIENT_INFO';
export const STAGE_WEBINAR_PAYMENT_INFO = 'STAGE_WEBINAR_PAYMENT_INFO';
export const STAGE_WEBINAR_ALMOST_DONE = 'STAGE_WEBINAR_ALMOST_DONE';

export const STAGE_SELECT_SERVICE = 'STAGE_SELECT_SERVICE';
export const STAGE_APPOINTMENT_CONFIRM = 'STAGE_APPOINTMENT_CONFIRM';

const commonScheduling = [
  {
    id: ELIGIBILITY_CHECK,
    name: 'Eligibility check',
    path: 'eligibility',
    noProgress: true,
  },
  {
    id: SELECTION_DETAILS,
    name: 'Selection details',
    path: 'selection',
    noProgress: true,
  },
  {
    id: CREATE_ACCOUNT,
    name: 'Create account',
    path: 'account',
    noProgress: true,
  },
  {
    id: STAGE_SELECT_SERVICE,
    name: 'Select Service',
    path: 'select-service',
  },
  {
    id: STAGE_APPOINTMENT_DETAILS,
    name: 'Schedule Appointment',
    path: 'calendar',
  },
  {
    id: STAGE_INSURANCE_INFO,
    name: 'Insurance',
    path: 'insurance',
  },
  {
    id: STAGE_PAYMENT_INFO,
    name: 'Payment Information',
    path: 'billing',
  },
  {
    id: STAGE_REVIEW_PAYMENT,
    name: 'Payment Review',
    path: 'payment-review',
  },
  {
    id: STAGE_SHIPPING_ADDRESS,
    name: 'Shipping Address',
    path: 'shipping-address',
  },
  {
    id: STAGE_APPOINTMENT_CONFIRM,
    name: 'Review and Confirm',
    path: 'confirm',
  },
];

export const webinarScheduling = [
  {
    id: CREATE_ACCOUNT,
    name: 'Create account',
  },
  {
    id: STAGE_WEBINAR_CLIENT_INFO,
    name: 'Your information',
  },
  {
    id: STAGE_WEBINAR_PAYMENT_INFO,
    name: 'Payment Information',
  },
  {
    id: STAGE_WEBINAR_ALMOST_DONE,
    name: 'Confirm Email',
  },
];

export const scpScheduling = [
  {
    id: ELIGIBILITY_CHECK,
    name: 'Eligibility check',
    path: 'eligibility',
    noProgress: true,
  },
  {
    id: SELECTION_DETAILS,
    name: 'Selection details',
    path: 'selection',
    noProgress: true,
  },
  {
    id: CREATE_ACCOUNT,
    name: 'Create account',
    path: 'account',
    noProgress: true,
  },
  {
    id: STAGE_SELECT_SERVICE,
    name: 'Service',
    path: 'select-service',
  },
  {
    id: STAGE_APPOINTMENT_DETAILS,
    name: 'Time',
    path: 'calendar',
  },
  {
    id: STAGE_INSURANCE_INFO,
    name: 'Insurance',
    path: 'insurance',
  },
  {
    id: STAGE_PAYMENT_INFO,
    name: 'Payment',
    path: 'billing',
  },
  {
    id: STAGE_REVIEW_PAYMENT,
    name: 'Payment Review',
    path: 'payment-review',
  },
  {
    id: STAGE_SHIPPING_ADDRESS,
    name: 'Shipping Address',
    path: 'shipping-address',
  },
  {
    id: STAGE_APPOINTMENT_CONFIRM,
    name: 'Review',
    path: 'confirm',
  }];

export default commonScheduling;
