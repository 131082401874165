import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import authService from 'services/api/authService';
import { setSessionCouponCode, setCouponCodeReadOnly } from 'ducks/scheduling';
import {
  TestConsultation,
  ReviewConsultation,
  CurbsideConsultation,
  GetStartedConsultation,
} from 'constants/ServiceTypes';
import api from 'services/api/api';
import { addAction, REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';

class RedirectToSchedule extends Component {
  state = { statusMessage: null };

  componentDidMount() {
    // const { params, location: { query }, dispatch } = this.props;

    const { params, location: { query },  dispatch } = this.props;
    if(params.service)
        this.savePreselectedService(params.service);
    if(query.code) {
      dispatch(setSessionCouponCode(query.code, true));
      dispatch(setCouponCodeReadOnly(true));
    }
    if (api.hasToken()) {
        this.goToSelectService();
    } else {
      this.props.dispatch(addAction(REDIRECT_AFTER_LOGIN, '/scheduling/select-service'));
      this.gotoLogin();
    }
  }

  savePreselectedService(preSelectedservice){
  let service='';
    if (preSelectedservice) {
      switch (preSelectedservice) {
        case 'testing-guidance':
          service = TestConsultation;
          break;
        case 'results-review':
          service = ReviewConsultation;
          break;
        case 'curbside-chat15':
          service = CurbsideConsultation;
          break;
        case 'getstarted':
          service = GetStartedConsultation;
          break;
      }
      authService.savePreselectedService(service);
    }
  }

  gotoHomeAddress() {
    browserHistory.push(`/scheduling/home-address`);
  }


  goToSelectService() {
    browserHistory.push(`/scheduling/select-service`);
  }

  gotoLogin() {
    browserHistory.push(`/login`)
  }

  render() {

    return (
      <div />
    );
  }
}

RedirectToSchedule.propTypes = {
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

export default connect()(RedirectToSchedule);
