import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getConsultation, findCouponItem } from 'ducks/scheduling';
import schedulingService, { GM_HELIX_COUPON_CODE } from 'services/api/schedulingService';
import { formatPrice } from 'services/utils';
import './ViewOnlyCouponApplierForm.scss';

class ViewOnlyCouponApplierForm extends Component {
  static propTypes = {
    isCheckingCouponCode: PropTypes.bool,
    scheduling: PropTypes.object,
    newPrice: PropTypes.number,
    appliedCoupon: PropTypes.object,
    insurance: PropTypes.object,
    companies: PropTypes.array,
  };


  get isPackage() {
    const { scheduling: { selectedServiceDescriptor } } = this.props;
    return selectedServiceDescriptor && selectedServiceDescriptor.isPackage;
  }

  get speciality() {
    const { scheduling: { reservedAppointment } } = this.props;
    if (!reservedAppointment) {
      return '';
    }
    return (
      reservedAppointment.vsee_specialty &&
      reservedAppointment.vsee_specialty.replace('_Willing', '').replace('_', ' ')
    );
  }

  get caption() {
    const { scheduling: { selectedServiceDescriptor, selectedProduct } } = this.props;
    let caption = '';
    if (this.isPackage && selectedServiceDescriptor) {
      caption = selectedServiceDescriptor.productName;
    } else {
      const consultation = getConsultation(selectedServiceDescriptor, selectedProduct);
      caption += consultation.name;
    }
    return caption;
  }

  get price() {
    const { newPrice, scheduling: { reservedAppointment } } = this.props;
    if (!reservedAppointment) {
      return '';
    }
    if (this.payWithInsurance) {
      return '$' + formatPrice(this.insurancePrice);
    }
    const servicePrice = reservedAppointment.consultation && reservedAppointment.consultation.price;
    return '$' + formatPrice(newPrice != null ? newPrice : servicePrice);
  }
  get visitPrice() {
    const { scheduling: { reservedAppointment } } = this.props;
    if (!reservedAppointment) {
      return '';
    }
    if (this.payWithInsurance) {
      return '$' + formatPrice(this.insurancePrice);
    }
    const servicePrice = reservedAppointment.consultation && reservedAppointment.consultation.price;
    return '$' + formatPrice(servicePrice);
  }

  get payWithInsurance() {
    return !_.isEmpty(this.props.insurance) && schedulingService.getUseInsurance();
  }

  get insurancePrice() {
    const { insurance, companies } = this.props;
    const insuranceCompany = companies.find(c => c.id === insurance?.primaryInsurance?.insuranceCompanyId);

    return insuranceCompany ? insuranceCompany.price : '';
  }

  get getDiscountValue() {
    const { scheduling, scheduling: { reservedAppointment } } = this.props;
    const coupon = findCouponItem(scheduling);
    let discountValue = coupon.discountValue;
    const consulationPrice = _.get(reservedAppointment, ['consultation', 'price']);
    if (consulationPrice !== undefined) {
      discountValue = discountValue >= consulationPrice ? consulationPrice : discountValue;
    }
    return '-$' + discountValue;
  }

  render() {
    const { appliedCoupon } = this.props;

    const { isCheckingCouponCode } = this.props.scheduling;
    const helixCouponApplied = appliedCoupon && appliedCoupon.code === GM_HELIX_COUPON_CODE;
    const isApplied = !isCheckingCouponCode && appliedCoupon && !helixCouponApplied;
    return (
      <div className={classnames('scp-coupon-applier')}>
        <div className="code-summary">Summary</div>
        <div className="visit-container" data-hj-suppress>
          <div className="coupon-service-name">{this.caption}</div>
          <div className="speciality-name">{this.speciality}</div>
        </div>

        <div className="coupon-container">
          <div className="total-block">
            <div className="visit-deposit-block">
              <div className="price-label">
                <span className="visit-deposit-label">
                  {' '}
                  {this.payWithInsurance ? 'Visit Deposit' : 'Visit fee'}{' '}
                </span>
              </div>
              <span className={`${isApplied ? 'total-values' : 'price-value'}`}>
                {this.visitPrice}
              </span>
            </div>
          </div>
          <div className="coupon-field-block">
            {isApplied && (
              <div className="coupon-applied-block">
                <div className="coupon-label-applied">
                  <div className="coupon-label-code">{appliedCoupon.code}</div>

                </div>
                <div className="coupon-applied-code">{this.getDiscountValue}</div>
              </div>
            )}
          </div>
          {isApplied && (
            <div className="total-block pad-bt-24">
              <span className="price-label">Visit Fee Total </span>
              <span className="price-value">{this.price}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let code = (state.scheduling.appliedCoupon && state.scheduling.appliedCoupon.code) || '';
  if (code === GM_HELIX_COUPON_CODE) code = '';

  return {
    scheduling: state.scheduling,
  };
};

export default connect(mapStateToProps)(ViewOnlyCouponApplierForm);
