import React from 'react';

const Facebook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.083"
    height="15.509"
    viewBox="0 0 19.083 15.509">
    <path
      id="prefix__Path_21545"
      d="M1222.8 1297.471a7.814 7.814 0 0 1-2.249.616 3.931 3.931 0 0 0 1.722-2.166 7.841 7.841 0 0 1-2.487.95 3.918 3.918 0 0 0-6.672 3.572 11.117 11.117 0 0 1-8.07-4.091 3.92 3.92 0 0 0 1.212 5.228 3.9 3.9 0 0 1-1.773-.49v.05a3.917 3.917 0 0 0 3.141 3.839 3.9 3.9 0 0 1-1.031.138 3.945 3.945 0 0 1-.737-.07 3.919 3.919 0 0 0 3.657 2.719 7.9 7.9 0 0 1-5.8 1.622 11.134 11.134 0 0 0 17.141-9.381q0-.255-.012-.506a7.946 7.946 0 0 0 1.954-2.027zm0 0"
      data-name="Path 21545"
      transform="translate(-1203.717 -1295.635)"
    />
  </svg>
);

export default Facebook;
