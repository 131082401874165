import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import Advisor from 'components/widgets/Advisor/Advisor';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import ReleaseNotice from 'components/widgets/SCP/ReleaseVersionNotice/ReleaseNotice';

class WebinarLayout extends Component {
  static propTypes = {
    children: PropTypes.element,
  };

  render() {
    return (
      <div className="main-container">
        <Header />
        <ReleaseNotice />
        <div className="webinar-layout full-height">
          {this.props.children}
          <Advisor />
        </div>
        <Footer />
        <GlobalLoader />
      </div>
    );
  }
}

export default WebinarLayout;
