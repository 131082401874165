import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import InputField from 'components/forms/controls/V2/InputField';
import SelectField from 'components/forms/controls/V2/SelectField';
import Button from 'components/forms/controls/ButtonV2';
import { required, validPostalOrZipCode } from 'services/validate';
import { HOME, getCountriesOption, getCountryStates } from 'services/utils';
import { hydrateHomeAddressFields } from '../../../../../services/api/transformers/addressTransformers';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { closeLoader } from '../../../../../ducks/ui';
import Alert from '../../../../../components/widgets/Alert/Alert';
import { clearFormError, setSubmitFailure } from '../../../../../ducks/registrationWizard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class CreditCardForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    title: PropTypes.string,
    isCreateMode: PropTypes.bool,
  };

  state = {
    updatingCard: false,
    initialState: {},
    validateCreditCard: false,
    stripe: undefined,
    elements: undefined,
  };

  componentDidMount() {
    const { initialValues, getStripeIntent } = this.props;
    if (initialValues) {
      this.setState({
        initialState: { ...initialValues },
      });
    }
    getStripeIntent();
  }

  handleComponentState = (label, value) => {
    this.setState({ [label]: value });
  };

  setAddress = () => {
    const { allAddress, user } = this.props;
    let newAddress = [...allAddress];
    let homeAddress = hydrateHomeAddressFields({
      ...this.state.initialState,
      fullName: _.get(user, ['me', 'fullName'], ''),
    });

    const oldHomeAddressIndex = newAddress.findIndex(
      (el) => el.addressType && el.addressType.includes(HOME)
    );
    if (oldHomeAddressIndex > -1) {
      const oldAddressObj = newAddress[oldHomeAddressIndex];
      let oldType = [..._.get(oldAddressObj, 'addressType', [])];
      if (oldType.length > 1) {
        let homeIndex = oldType.indexOf(HOME);
        if (homeIndex > -1) {
          oldType.splice(homeIndex, 1);
          newAddress[homeIndex] = { ...oldAddressObj, addressType: oldType };
          newAddress.push({ ...homeAddress, addressType: [HOME] });
        }
      } else {
        newAddress[oldHomeAddressIndex] = {
          ...homeAddress,
          addressType: [HOME],
        };
      }
    } else {
      newAddress.push({ ...homeAddress, addressType: [HOME] });
    }
    return newAddress;
  };

  handleChange = (value, key) => {
    this.setState({ initialState: Object.assign({}, this.state.initialState, { [key]: value }) });
  };

  handleCountryChange = (value, key) => {
    if (this.state.initialState.addressCountry !== value) {
      let obj = {
        [key]: value,
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZipCode: '',
      };
      if (this.props?.initialValues?.addressCountry == value) {
        obj['addressLine1'] = _.get(this.props, ['initialValues', 'addressLine1'], '');
        obj['addressLine2'] = _.get(this.props, ['initialValues', 'addressLine2'], '');
        obj['addressCity'] = _.get(this.props, ['initialValues', 'addressCity'], '');
        obj['addressState'] = _.get(this.props, ['initialValues', 'addressState'], '');
        obj['addressZipCode'] = _.get(this.props, ['initialValues', 'addressZipCode'], '');
      }
      this.setState({ initialState: Object.assign({}, this.state.initialState, obj) }, () => {
        this.props.untouch('addressLine1');
        this.props.untouch('addressLine2');
        this.props.untouch('addressCity');
        this.props.untouch('addressState');
        this.props.untouch('addressZipCode');
        this.props.initialize({
          ...this.state.initialState,
        });
      });
    }
  };

  onFormSubmit = async (data) => {
    const { stripe, elements } = this.state;
    const { dispatch, onSubmit, handlePaymentMethod, user } = this.props;
    dispatch(clearFormError());
    const addresses = this.setAddress();
    await onSubmit({ addresses: addresses, paymentInfo: data }).then((res) => {
      if (stripe) {
        stripe
          .confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
              payment_method_data: {},
            },
          })
          .then(function (result) {
            if (result.error) {
              dispatch(setSubmitFailure(result.error.message));
              dispatch(closeLoader());
            } else {
              handlePaymentMethod({
                ...data,
                setupIntentId: _.get(user, ['stripeIntent', 'id']),
                addressId: _.get(res, 'addressId', ''),
              });
            }
          });
      }
    });
  };

  render() {
    const {
      user: { stripeIntent, stripeIntentLoading },
      isMobile,
    } = this.props;
    const clientSecret = _.get(stripeIntent, ['secret']);
    const options = {
      clientSecret: clientSecret,
      appearance: {
        variables: {
          fontFamily: "'RionaSans', Arial, Helvetica, sans-serif",
        },
        rules: {
          '.Label': {
            fontSize: isMobile ? '14px' : '12px',
            color: '#262837',
            textTransform: 'capitalize',
          },
          '.Input': {
            padding: isMobile ? '13px 12px 13px 16px' : '8px 8px',
            fontSize: isMobile ? '16px' : '13px',
            border: 'solid 1px #bac3ce',
          },

          '.Input:focus': {
            borderColor: '#70cbf1',
          },
          '.Input--invalid': {
            backgroundColor: 'rgba(254, 145, 137, 0.5)',
            boxShadow: 'none',
          },
          '.Error': {
            fontSize: '12px',
            color: '#ff0000',
          },
        },
      },
    };

    const {
      handleSubmit,
      submitting,
      title,
      invalid,
      onClickHandler,
      isCreateMode,
      user,
      CountriesStateEnums: { countries, states },
    } = this.props;
    const countryIsNonUS = _.get(user, ['me', 'countryIsNonUS'], false);
    const allowedCountries = _.get(user, ['me', 'allowedCountries'], ['US']);
    const StateLabel = this.state.initialState.addressCountry === 'CA' ? 'Province' : 'State';
    const zipCodeLabel =
      this.state.initialState.addressCountry === 'CA' ? 'Postal Code' : 'Zip Code';

    return (
      <form className="gm-form" onSubmit={handleSubmit(this.onFormSubmit)}>
        <div className="back visible" onClick={onClickHandler}>
          Back
        </div>
        <p className="title">{`${isCreateMode ? 'Add Card' : 'Update Card'}`}</p>
        <p className="form-title">{title}</p>
        <p className="cc-title headingv2">Credit or Debit Card</p>
        <Alert message={this.props.formError} />

        {!stripeIntentLoading && (
          <div className="line-fields">
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                handleComponentState={this.handleComponentState}
                defaultValues={{
                  billingDetails: {
                    name: _.get(user, ['me', 'fullName'], ''),
                    address: {
                      country: this.props.initialValues.addressCountry,
                      postal_code: this.props.initialValues.addressZipCode,
                    },
                  },
                }}
              />
            </Elements>
          </div>
        )}
        <p className="form-title headingv2">Billing Address</p>
        <div className="billing-address">
          {countryIsNonUS && (
            <div className="form-row">
              <div className="form-col full-col">
                <div className="label-section required">Country</div>
                <Field
                  name="addressCountry"
                  component={SelectField}
                  placeholder="Select"
                  options={getCountriesOption(countries, allowedCountries)}
                  validate={[required('Country')]}
                  onChange={(e, value) => this.handleCountryChange(value, 'addressCountry')}
                />
              </div>
            </div>
          )}
          <div className="form-row">
            <div className="form-col full-col">
              <div className="label-section required">Address Line 1</div>
              <Field
                name="addressLine1"
                placeholder="123 Main Street"
                type="text"
                component={InputField}
                validate={[required('Address Line 1')]}
                onChange={(e, value) => this.handleChange(value, 'addressLine1')}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col full-col">
              <div className="label-section">Address Line 2</div>
              <Field
                name="addressLine2"
                placeholder="Apt 123"
                type="text"
                component={InputField}
                onChange={(e, value) => this.handleChange(value, 'addressLine2')}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col city-col">
              <div className="label-section required">City</div>
              <Field
                name="addressCity"
                type="text"
                placeholder="Enter"
                component={InputField}
                validate={[required('City')]}
                onChange={(e, value) => this.handleChange(value, 'addressCity')}
              />
            </div>
            <div className="form-col state-col">
              <div className="label-section required">{StateLabel}</div>
              <Field
                name="addressState"
                component={SelectField}
                placeholder="Select"
                options={getCountryStates(
                  states,
                  this.state.initialState.addressCountry,
                  'code',
                  'code'
                )}
                validate={[required(StateLabel)]}
                onChange={(e, value) => this.handleChange(value, 'addressState')}
                typeahead={true}
                clearable={true}
              />
            </div>
            <div className="form-col zip-col">
              <div className="label-section required">{zipCodeLabel}</div>
              <Field
                name="addressZipCode"
                type="text"
                placeholder="Enter"
                component={InputField}
                label="Zipcode"
                validate={[
                  required(zipCodeLabel),
                  validPostalOrZipCode(
                    zipCodeLabel,
                    this.state.initialState.addressCountry ||
                      this.props.initialValues.addressCountry
                  ),
                ]}
                onChange={(e, value) => this.handleChange(value, 'addressZipCode')}
              />
            </div>
          </div>
        </div>
        <Button
          type="submit"
          className="btn-save v2"
          disabled={invalid || submitting || !this.state.validateCreditCard}
        >
          {!isCreateMode ? 'Update Card' : 'Add New Card'}
        </Button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'credit-card-form',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
})(CreditCardForm);
