import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { Link, browserHistory } from 'react-router';
import classnames from 'classnames';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'components/forms/controls/Button';

import burgerIcon from './menu-icon.svg';
import closeIcon from './close-icon.svg';
import './MobileMenu.scss';

export default class MobileMenu extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    onLogin: PropTypes.func,
    onLogout: PropTypes.func.isRequired,
    hideSignIn: PropTypes.bool,
  };

  state = { menuOpened: false };

  getItems = () => {
    const { items } = this.props;
    if (!items || items.length === 0) return <li />;
    return [{ name: 'Home', path: '/' }, ...this.props.items].map((o, i) => (
      <li
        className={classnames('item', { active: o.active })}
        key={i}
        onClick={this.onLinkClick}
        data-link={o.path}>
        {o.name}
      </li>
    ));
  };

  openMenu = () => {
    this.setState({ menuOpened: true });
  };

  closeMenu = () => {
    this.setState({ menuOpened: false });
  };

  onLinkClick = e => {
    const path = e.currentTarget.getAttribute('data-link');
    this.closeMenu();
    browserHistory.push(path);
  };

  onLoginClick = () => {
    this.closeMenu();
    this.props.onLogin();
  };

  get hasItems() {
    return !_.isEmpty(this.props.items);
  }

  render() {
    const { hideSignIn } = this.props;

    return (
      <header className="mobile-menu">
        <Link to="/" className="header-logo" />
        {this.hasItems && <img className="open-button" src={burgerIcon} onClick={this.openMenu} />}
        <Modal
          className="menu-modal md-full"
          show={this.state.menuOpened}
          onHide={this.closeMenu}
          animation={false}
          bsClass="mobile-menu-modal modal">
          <Modal.Body>
            <div className="modal-panel">
              <img className="close-button" src={closeIcon} onClick={this.closeMenu} />
            </div>
            <div className="menu-list">
              <ul>{this.getItems()}</ul>
              {!hideSignIn && (
                <div className="item-signin">
                  {this.props.isLoggedIn && (
                    <Button type="signin" className="signin-button" onClick={this.props.onLogout}>
                      SIGN OUT
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </header>
    );
  }
}
