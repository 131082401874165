import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { CurbsideConsultation, CURBSIDE_15_CONSULATION_DURATION } from 'constants/ServiceTypes';
import { getSCPFormattedAppointmentType } from 'services/utils';
import { GetTimeZoneById } from 'services/geo';


class AppointmentSummary extends Component {
  get formattedDateAndTime() {
    const { start, end, timezone, consultation } = this.props.appointment;
    const formattedDate = moment(start).format('MMMM D, dddd');
    const formattedStart = moment(start).format('h:mm');

    if (this.props.isWebinar) {
      return `${formattedDate} ${formattedStart}, ${GetTimeZoneById(timezone)}`;
    }

    const correctedEnd =
      consultation.type === CurbsideConsultation
        ? moment(start).add(CURBSIDE_15_CONSULATION_DURATION, 'minutes')
        : moment(end);
    const formattedEnd = moment(correctedEnd).format('h:mm A');

    return `${formattedDate} ${formattedStart + ' '} - ${' ' + formattedEnd}, ${GetTimeZoneById(
      timezone
    )}`;
  }

  get formattedDate() {
    const { start } = this.props.appointment;
    const formattedDate = moment(start).format('dddd, MMMM D, YYYY');

    return `${formattedDate}`;
  }

  get formattedTimeAndTimezone() {
    const { start, timezoneAbb } = this.props.appointment;
    const formattedStart = moment(start).format('h:mm');

    return `${formattedStart + ' '} ${timezoneAbb}`;
  }

  get counselorStateString() {
    const { appointment, isWebinar, enums } = this.props;
    const CountriesStateEnums = enums?.countryState?.data?.states || [];
    const selectedCountryStates = (CountriesStateEnums).filter(a => a.countryCode == appointment.country);

    if (isWebinar) {
      return 'Name of Presenter';
    }
    const state = selectedCountryStates.find((s) => s.code === appointment.state);
    return state
      ? `Your ${state?.name} Certified Genetic Counselor`
      : 'Your Certified Genetic Counselor';
  }

  get specialistString() {
    const { provider } = this.props.appointment;
    return `${provider.full_name || provider.fullName}${
      `${provider?.title ? ',' : ''} ${provider?.title || ''}`
    }`;
  }

  get actualConsultation() {
    const { consultationTypes, appointment } = this.props;
    return (
      consultationTypes &&
      consultationTypes.find(
        (c) =>
          appointment.data &&
          appointment.data.consultation &&
          c.type === appointment.data.consultation.type
      )
    );
  }

  render() {
    const { appointment, isWebinar, isMobile } = this.props;
    if (_.isEmpty(appointment)) return null;
    return (
      <div className="info" data-hj-suppress>
        {isMobile ? (
          <div>
            Are you sure you want to cancel your scheduled appointment on{' '}
            <span className="appointment-date">{this.formattedDate}</span> at{' '}
            <span className="appointment-time-timezone">{this.formattedTimeAndTimezone}</span> with{' '}
            <span className="provider-name">{this.specialistString}</span>?
          </div>
        ) : (
          <Fragment>
            {isWebinar && <p className="caption">Webinar Name</p>}
            {isWebinar && <p className="value">{appointment.consultation.name}</p>}

            <p className="caption">Date and Time</p>
            <p className="value">{this.formattedDateAndTime}</p>

            {!isWebinar && <p className="caption">Appointment Type</p>}
            {!isWebinar && (
              <p className="value">{getSCPFormattedAppointmentType(appointment.consultation)}</p>
            )}

            <p className="caption">{this.counselorStateString}</p>
            <p className="value">{this.specialistString}</p>
          </Fragment>
        )}
      </div>
    );
  }
}

AppointmentSummary.propTypes = {
  consultationTypes: PropTypes.array.isRequired,
  appointment: PropTypes.object.isRequired,
  newPrice: PropTypes.number,
  hidePrice: PropTypes.bool,
  isWebinar: PropTypes.bool,
  isMobile: PropTypes.bool,
  enums: PropTypes.object.isRequired
};

AppointmentSummary.defaultProps = {
  isMobile: false,
};

export default AppointmentSummary;
