import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import ModalDialog from '../ModalDialog';

import './AppointmentSchedulingFailedModal.scss';

export const AppointmentSchedulingFailedModal = ({ className, closeCallback, show }) => {
  return (
    <ModalDialog
      className={classnames('error-modal', className)}
      show={show}
      onHide={closeCallback}
      modalHeader={
        <>
          <div className="error-header">
            <i className="material-icons icon">error</i>
            <span>Appointment Scheduling Failed</span>
          </div>
        </>
      }>
      <div className="error-body">
        <p>We were unable to schedule your appointment due to an internal error.</p>
        <p>
          Please call <a href="tel:+18776880992">(877) 688-0992</a> or email{' '}
          <a href="mailto:clinical@genomemedical.com">clinical@genomemedical.com</a> so we can get
          your appointment scheduled as soon as possible.
        </p>
      </div>
    </ModalDialog>
  );
};

AppointmentSchedulingFailedModal.propTypes = {
  className: PropTypes.string,
  closeCallback: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
