import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import VerifyEmailSent from './VerifyEmailSent';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './VerifyEmail.scss';
import verifyEmailIcon from './verify-email.svg';
import RBAC from 'components/high-order-components/RBAC/RBAC';

class VerifyEmail extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    verifyEmail: PropTypes.func.isRequired,
    requestEmailSent: PropTypes.bool.isRequired,
  };

  state = { inProgress: false, showModal: false };

  onVerifyEmail = () => {
    if (!get(this, ['state', 'inProgress'], false)) {
      this.setState({ inProgress: true });

      this.props.verifyEmail().then(() => {
        this.setState({ inProgress: false, showModal: true });
      });
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const email = get(this, ['props', 'email'], '');
    const showModal =
      get(this, ['state', 'showModal'], false) && get(this, ['props', 'requestEmailSent'], false);

    return (
      <div className="resend-confirmation-email">
        <span className="verify-email-badge-icon">!</span>
        <div className="content">
          <div className="left">
            <img className="visual-content" src={verifyEmailIcon} alt="Verify your email" />
          </div>
          <div className="right">
            <div className="verify-text" data-hj-suppress>
              Please verify your email by clicking the confirmation link sent to{' '}
              <span className="email">{email}</span>.
            </div>
            <ButtonV2
              className="resend-email"
              onClick={this.onVerifyEmail}
              disabled={get(this, ['state', 'inProgress'], false)}>
              Resend Confirmation Email
            </ButtonV2>
          </div>
        </div>
        {showModal && (
          <VerifyEmailSent show={showModal} email={email} closeModal={this.closeModal} />
        )}
      </div>
    );
  }
}

export default RBAC(['nonScp'], VerifyEmail);
