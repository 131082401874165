import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { setModalMode } from 'ducks/modals';
import ModalNames from 'constants/ModalNames';
import CancelAppointmentModal from 'components/modals/SCP/CancelAppointmentModal/CancelAppointmentModal';
import PhoneAppointmentModal from 'components/modals/SCP/PhoneAppointmentModal/PhoneAppointmentModal';
import VideoAppointmentModal from 'components/modals/SCP/VideoAppointmentModal/VideoAppointmentModal';
import { deleteUpcomingAppointment } from 'ducks/appointment';
import { switchModality, resetSwitchToFormError } from 'ducks/misc/switchToPhone';

class AppointmentModalsSet extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func.isRequired,
    appointment: PropTypes.object.isRequired,
    error: PropTypes.string,
    modals: PropTypes.object.isRequired,
  };

  state = {
    phoneError: false,
  };

  closeModal = name => {
    this.setState({ phoneError: false });
    this.props.dispatch(setModalMode(name, { open: false }));
  };

  cancelAppointment = () => {
    const { id } = this.props.appointment;
    this.props.dispatch(deleteUpcomingAppointment(id));
  };

  switchModality = type => {
    const { id } = this.props.appointment;
    const phone = _.get(this.props, 'user.phone', '');
    if (!_.isEmpty(phone)) {
      this.props.dispatch(switchModality(id, type)).then(() => {
        if (type == 'video') this.closeModal(ModalNames.videoAppointment);
        else this.closeModal(ModalNames.phoneAppointment);
      });
    } else {
      this.setState({ phoneError: true });
    }
  };

  renderPhoneAppointmentModal(child, index) {
    const { modals } = this.props;

    return (
      <PhoneAppointmentModal
        phoneError={
          _.get(this.state, 'phoneError', false)
            ? 'Cannot update to phone; no patient phone number recorded.'
            : ''
        }
        key={index}
        {...child.props}
        show={modals.phoneAppointment.open}
        onConfirm={this.switchModality}
        onHide={() => {
          this.props.dispatch(resetSwitchToFormError());
          this.closeModal(ModalNames.phoneAppointment);
        }}
      />
    );
  }

  renderVideoAppointmentModal(child, index) {
    const { modals } = this.props;

    return (
      <VideoAppointmentModal
        phoneError={_.get(this.state, 'phoneError', false) ? 'Cannot update to video.' : ''}
        key={index}
        {...child.props}
        show={modals.videoAppointment.open}
        onConfirm={this.switchModality}
        onHide={() => {
          this.props.dispatch(resetSwitchToFormError());
          this.closeModal(ModalNames.videoAppointment);
        }}
      />
    );
  }

  renderCancelAppointmentModal(child, index) {
    const { appointment, modals } = this.props;
    if (_.isEmpty(appointment)) {
      return null;
    }
    return (
      <CancelAppointmentModal
        key={index}
        {...child.props}
        show={modals.cancelAppointment.open}
        onConfirm={this.cancelAppointment}
        onCancel={() => this.closeModal(ModalNames.cancelAppointment)}
        appointment={appointment}
      />
    );
  }

  renderOneChild = (child, index) => {
    if (child.type === PhoneAppointmentModal) {
      return this.renderPhoneAppointmentModal(child, index);
    } else if (child.type === VideoAppointmentModal) {
      return this.renderVideoAppointmentModal(child, index);
    } else if (child.type === CancelAppointmentModal) {
      return this.renderCancelAppointmentModal(child, index);
    }
    return null;
  };

  render() {
    const { children } = this.props;
    const count = React.Children.count(children) > 1;
    if (count) {
      return <div>{children.map((child, index) => this.renderOneChild(child, index))}</div>;
    }
    return this.renderOneChild(children, 0);
  }
}

export default AppointmentModalsSet;
