import api from './api';

export type SelectionConfig = {
  type: string,
  name: string,
  label: string,
};

export type SelectionCheckResults = {
  selection: false,
  selection_token: string,
};

export type PartnerSelectionInfo = {
  id: number,
  name: string,
  restricted_workflow: boolean,
  price_per_webinar_package: number,
  selection_required: boolean,
};

export default class SelectionApi {
  getSelectionConfig(partnerName: string): Promise<Array<SelectionConfig>> {
    return api.get(`/selection/${partnerName}/config`);
  }

  checkSelection(partnerName: string, params: Object): Promise<SelectionCheckResults> {
    return api.post(`/selection/${partnerName}/check`, params);
  }

  getPartnerInfo(partnerName: string): Promise<PartnerSelectionInfo> {
    return api.get(`/partners/${partnerName}`);
  }
}
