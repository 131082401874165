//React & Redux
import React from 'react';

//UI Libraries
import { Radio } from 'gm-element-react';

//Lodash
import get from 'lodash/get';

//Styles
import './RadioButtonGroup.scss';

class RadioButtonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //bindings
  }
  //functions

  render() {
    const internalKey = get(this, 'props.internalKey', null) || 'internal';
    const displayKey = get(this, 'props.displayKey', null) || 'external';
    const data = get(this, 'props.data', null) || [];
    const currentValue = get(this, 'props.currentValue');
    const onChangeFunc = get(this, 'props.onChangeFunc', null);
    const customStyling = get(this, 'props.customStyling', null) || {};
    const rowVariant = get(this, 'props.rowVariant', false);
    const rowVariantClass = rowVariant ? ' radioButtonRowVariantContainer' : '';
    const containerClass = 'radioButtonGroupContainer' + rowVariantClass;
    const rowVariantRadioClass = rowVariant ? ' rowVariantRadioButton' : '';
    const radioButtonNonBlankClass =
      'singleSelectRadioRow singleSelectModalRow radioButtonRowContainer' + rowVariantRadioClass;
    const rowVariantLabelClass = rowVariant ? ' rowVariantRadioLabel' : '';
    const radioButtonLabelClass = 'singleSelectRadioLabel' + rowVariantLabelClass;
    return (
      <div className={containerClass} style={{ ...customStyling }}>
        {Array.isArray(data) &&
          data.map(el => {
            const internalValue = get(el, internalKey);
            const externalValue = get(el, displayKey);
            const checked = currentValue === internalValue;
            const isBlank = get(el, 'isBlank', null);
            const isSelected = get(el, 'isSelected', false);
            if (isBlank) {
              return (
                <Radio
                  checked={isSelected}
                  className="singleSelectRadioRow radioButtonRowContainer"
                  value={true}
                  onChange={() => {}}
                  style={{ minHeight: '48px' }}
                />
              );
            } else {
              return (
                <div onClick={() => onChangeFunc(internalValue)}>
                  <Radio
                    value={internalValue}
                    checked={checked}
                    key={internalValue}
                    className={radioButtonNonBlankClass}>
                    <span
                      className={radioButtonLabelClass}
                      style={{ color: checked ? '#5B43BD' : 'black' }}>
                      {externalValue || '--'}
                    </span>
                  </Radio>
                </div>
              );
            }
          })}
      </div>
    );
  }
}

export default RadioButtonGroup;
