import React, { Component, Fragment } from 'react';
import { Link, browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveSoftAccount, clearFormError, verifyEligibility } from 'ducks/registrationWizard';
import { isPartnerSoftRegisterOptionalFlow } from 'services/misc/ReferralSource';
import { loaderSession } from 'ducks/ui';
import SoftRegistractionForm from './SoftRegistrationForm';
import SoftRegistrationPageHeader from './SoftRegistrationPageHeader';
import EligibilityCheckForm from 'components/forms/WizardForm/EligibilityCheck/EligibilityCheckForm';
import InfoModal from 'components/modals/SCP/InfoModal/InfoModal';
import authService from 'services/api/authService';
import eligibilityService from 'services/api/eligibilityService';
import api from 'services/api/api';
import _ from 'lodash';
import './SoftRegistrationPage.scss';

const SOFT_ACCOUNT_EXISTS_FLOW = 'SOFT_ACCOUNT_EXISTS';
const FULL_ACCOUNT_EXISTS_FLOW = 'FULL_ACCOUNT_EXISTS';
const SWITCH_TO_SCP_FLOW = 'SWITCH_TO_SCP';
const DEFAULT_FLOW = 'DEFAULT_FLOW';

const STAGE_SOFT_REGISTRATION = 'STAGE_SOFT_REGISTRATION';
const STAGE_ELIGIBILITY_CHECK = 'STAGE_ELIGIBILITY_CHECK';
const workflow = [
  {
    id: STAGE_SOFT_REGISTRATION,
    name: 'register',
    default: true,
  },
  {
    id: STAGE_ELIGIBILITY_CHECK,
    name: 'Eligibility check',
    path: 'eligibility',
    default: false,
  },
];

class SoftRegistractionPage extends Component {
  state = {
    returningUser: false,
    activeFlow: DEFAULT_FLOW,
    softRegistrationData: {},
    returningUserEmail: null,
    switchnotallowed: false,
  };

  componentDidMount() {
    this.validateStage();
  }

  componentDidUpdate() {
    this.validateStage();
  }
  validateStage = () => {
    const { selectedStage, gotoLoginPage, gotoSoftRegisterStage } = this.props;
    const { softRegistrationData } = this.state;
    if (selectedStage === STAGE_SOFT_REGISTRATION && !api.hasNavigatorSurvey()) {
      gotoLoginPage();
    } else if (selectedStage === STAGE_ELIGIBILITY_CHECK && _.isEmpty(softRegistrationData)) {
      gotoSoftRegisterStage();
    }
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearFormError());
    authService.resetSCPSetPasswordToken();
  }

  handleReturningUser = returningUserEmail => {
    this.setState({
      returningUser: true,
      activeFlow: FULL_ACCOUNT_EXISTS_FLOW,
      returningUserEmail,
    });
  };

  handleSoftAccountExists = values => {
    this.setState({
      returningUser: true,
      activeFlow: SOFT_ACCOUNT_EXISTS_FLOW,
      returningUserEmail: values.email,
      softRegistrationData: values,
    });
  };

  handleSwitchToScp = returningUserEmail => {
    this.setState({ returningUser: true, activeFlow: SWITCH_TO_SCP_FLOW, returningUserEmail });
  };

  handleEligibiltyNotAllowed = () => {
    this.setState({
      switchnotallowed: true,
    });
  };

  clearReturningUser = () => {
    this.setState({
      returningUser: false,
      activeFlow: DEFAULT_FLOW,
      returningUserEmail: null,
    });
  };

  clearSwitchingNotAllowed = () => {
    this.setState({
      switchnotallowed: false,
    });
  };

  identifyFlow = res => {
    if (!_.isEmpty(res)) {
      const { softAccountExists, fullAccountExists, switchToScp } = res;
      if (fullAccountExists) {
        return FULL_ACCOUNT_EXISTS_FLOW;
      } else if (softAccountExists) {
        return SOFT_ACCOUNT_EXISTS_FLOW;
      } else if (switchToScp) {
        return SWITCH_TO_SCP_FLOW;
      }
    }
    return DEFAULT_FLOW;
  };

  isEmailAlreadyExists = (serverErrors, returningUserEmail) => {
    if (!_.isEmpty(serverErrors)) {
      const { errors } = serverErrors;
      if (!_.isEmpty(errors)) {
        const { alreadyExists } = errors;
        if (alreadyExists) {
          this.handleReturningUser(returningUserEmail);
        }
      }
    }
  };

  submitEligibilityCheck = values => {
    const { dispatch } = this.props;
    const { softRegistrationData } = this.state;
    const referral = eligibilityService.getReferral();
    return loaderSession(
      dispatch,
      verifyEligibility(referral, values, dispatch).then(() => {
        return this.submitSoftRegisterInfo({ ...softRegistrationData });
      })
    );
  };

  gotoVerificationPage = values => {
    const { dispatch, gotoVerifyStage } = this.props;
    dispatch(clearFormError());
    this.setState({ softRegistrationData: values });
    gotoVerifyStage();
  };

  gotoEligibilityPage = values => {
    const { dispatch, gotoEligibilityStage } = this.props;
    dispatch(clearFormError());
    this.setState({ softRegistrationData: values });
    gotoEligibilityStage();
  };

  goBack = () => {
    const { dispatch, gotoSoftRegisterStage } = this.props;
    dispatch(clearFormError());
    gotoSoftRegisterStage();
  };

  handleReturningSubmit = () => {
    const { gotoLoginPage, gotoSCPHome, gotoWelcome, gotoEducationPage } = this.props;
    const { activeFlow, softRegistrationData } = this.state;
    api.clearNavigatorSurvey();
    if (activeFlow == SOFT_ACCOUNT_EXISTS_FLOW) {
      authService.saveSCPPreRegistrationData(softRegistrationData);
      api.setEducationSurvey({
        surveyTreeId: softRegistrationData.surveyTreeId,
        surveySessionId: softRegistrationData.surveySessionId,
      });
      api.clearNavigatorSurvey();
      gotoEducationPage();
    } else if (activeFlow == SWITCH_TO_SCP_FLOW) {
      if (api.hasToken() && api.isNonSCPUser()) {
        gotoWelcome();
      } else if (api.hasToken() && api.isSCPUser()) {
        gotoSCPHome();
      } else {
        authService.saveSCPPreRegistrationData({ switchToScp: true });
        gotoLoginPage();
      }
    } else if (activeFlow == FULL_ACCOUNT_EXISTS_FLOW) {
      gotoLoginPage();
    }
  };

  renderNotEligibile = () => {
    return (
      <InfoModal
        className="info-modal no-close-modal"
        title={<span className="info-title">It Appears you Are Not Eligible</span>}
        description={
          <div>
            You are not eligible for this program. Please contact your program administrator or
            contact us at{' '}
            <a href="mailto: clinical@genomemedical.com">clinical@genomemedical.com</a>
          </div>
        }
        submitText="Contact Us"
        submitCallback={() => {
          window.location.href = 'mailto:clinical@genomemedical.com';
        }}
        cancelCallback={() => this.clearSwitchingNotAllowed()}
      />
    );
  };

  renderReturningUser = () => {
    const { returningUserEmail, activeFlow } = this.state;

    return (
      <InfoModal
        className="info-modal"
        title={<span className="info-title">Returning User?</span>}
        description={
          [SOFT_ACCOUNT_EXISTS_FLOW].includes(activeFlow) ? (
            <div data-hj-suppress>
              The email <span className="info-email">{returningUserEmail}</span> is already
              registered in our system. By clicking below, we will take you to where you left off in
              the previous session.
            </div>
          ) : (
            <div data-hj-suppress>
              The email <span className="info-email">{returningUserEmail}</span> is already
              registered in our system. Please sign in to resume your progress from where you have
              left off
            </div>
          )
        }
        footer={
          [FULL_ACCOUNT_EXISTS_FLOW, SOFT_ACCOUNT_EXISTS_FLOW].includes(activeFlow) && (
            <Link onClick={() => this.clearReturningUser()}>Go back to account creation</Link>
          )
        }
        submitText="Sign In To Resume"
        submitCallback={() => this.handleReturningSubmit()}
        cancelCallback={() => this.clearReturningUser()}
      />
    );
  };

  submitSoftRegisterInfo = values => {
    const { dispatch } = this.props;
    const eligibilityData = eligibilityService.getVerificationData();
    const { surveyTreeId, surveySessionId } = api.getNavigatorSurvey();
    const partnerId = authService.getAffiliationId();
    if (eligibilityData) {
      const { eligibility_token, additionalData } = eligibilityData;
      values = { ...values, eligibility: { eligibility_token, additionalData } };
    }
    if (isPartnerSoftRegisterOptionalFlow() && api.hasEducationSurvey()) {
      const educationSurvey = api.getEducationSurvey();
      values = {
        ...values,
        educationSurveyTreeId: educationSurvey.surveyTreeId,
        educationSurveySessionId: educationSurvey.surveySessionId,
      };
    }
    return loaderSession(
      dispatch,
      saveSoftAccount(
        {
          ...values,
          treeId: surveyTreeId,
          sessionId: surveySessionId,
          partnerId: partnerId,
        },
        dispatch
      )
        .then(res => {
          const { uuid } = res;
          const flow = this.identifyFlow(res);
          if (flow == DEFAULT_FLOW) {
            authService.saveSCPPreRegistrationData({ ...values, uuid });
            api.clearNavigatorSurvey();
            if (isPartnerSoftRegisterOptionalFlow()) {
              api.clearEducationSurvey();
              this.props.gotoFullRegisterPage();
            } else {
              api.setEducationSurvey({
                surveyTreeId: res.surveyTreeId,
                surveySessionId: res.surveySessionId,
              });
              this.props.gotoEducationPage();
            }
          } else if (flow == FULL_ACCOUNT_EXISTS_FLOW) {
            if (authService.getSCPSetPasswordToken() && res.surveyTreeId && res.surveySessionId) {
              api.setEducationSurvey({
                surveyTreeId: res.surveyTreeId,
                surveySessionId: res.surveySessionId,
              });
              api.clearNavigatorSurvey();
              const url = `/password/set/${authService.getSCPSetPasswordToken()}`;
              browserHistory.push(url);
            } else {
              this.handleReturningUser(values.email);
            }
          } else if (flow == SOFT_ACCOUNT_EXISTS_FLOW) {
            this.handleSoftAccountExists({
              ...values,
              uuid,
              surveyTreeId: res.surveyTreeId,
              surveySessionId: res.surveySessionId,
            });
          } else if (flow == SWITCH_TO_SCP_FLOW) {
            this.handleSwitchToScp(values.email);
          }
          eligibilityService.clearVerificationData();
        })
        .catch(error => {
          if (error && error.status == 405 && error.code == 'eligibility_required') {
            this.gotoEligibilityPage(values);
          } else if (error && error.status == 405 && error.code == 'not_allowed') {
            this.handleEligibiltyNotAllowed();
          } else {
            this.isEmailAlreadyExists(error, values.email);
            throw error;
          }
        })
    );
  };

  setDropOutData = () => {
    const partnerId = authService.getAffiliationId();
    authService.saveSCPDropOutData({
      partnerId: partnerId,
      checkUserExist: true,
    });
  };

  render() {
    const { softRegistrationData, returningUser, switchnotallowed } = this.state;
    const { selectedStage, dispatch } = this.props;
    const { formError } = this.props.registrationWizard;
    return (
      <div className="scp-soft-rgstrtion-container">
        {returningUser && this.renderReturningUser()}
        {switchnotallowed == true && this.renderNotEligibile()}
        {selectedStage == STAGE_SOFT_REGISTRATION && (
          <Fragment>
            <div className="soft-rgstrtion-wrapper">
              <SoftRegistrationPageHeader title="What’s next?">
                <div className="instrtion-section">
                  <ul>
                    <li>You will have an opportunity to learn more about carrier screening</li>
                    <li>
                      You will have an opportunity for our clinical genetics team to review your
                      information and guide you to next steps.
                    </li>
                  </ul>
                </div>
                <div className="already-account-wrapper">
                  Already have an account?{' '}
                  <Link className="login-link" onClick={this.setDropOutData} to="/login">
                    Sign in
                  </Link>
                </div>
              </SoftRegistrationPageHeader>
              <SoftRegistractionForm
                onSubmit={this.submitSoftRegisterInfo}
                formError={_.isObject(formError) ? _.get(formError, 'message') : formError}
                initialValues={softRegistrationData}
              />
            </div>
          </Fragment>
        )}
        {selectedStage == STAGE_ELIGIBILITY_CHECK &&
          !_.isEmpty(softRegistrationData) && (
            <EligibilityCheckForm
              partner={eligibilityService.getReferral()}
              onSubmit={this.submitEligibilityCheck}
              formError={formError}
              isSCPFLOW={true}
              clearErrors={() => {
                dispatch(clearFormError());
              }}
            />
          )}
      </div>
    );
  }
}

SoftRegistractionPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gotoEducationPage: PropTypes.func.isRequired,
  gotoLoginPage: PropTypes.func.isRequired,
  gotoSCPSoftLoginPage: PropTypes.func.isRequired,
  gotoVerifyStage: PropTypes.func.isRequired,
  gotoSoftRegisterStage: PropTypes.func.isRequired,
  gotoSCPHome: PropTypes.func.isRequired,
  gotoWelcome: PropTypes.func.isRequired,
  selectedStage: PropTypes.string.isRequired,
  gotoFullRegisterPage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ registrationWizard }) => ({
  registrationWizard,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { registrationWizard } = stateProps;
  const { dispatch } = dispatchProps;
  const defaultStage = workflow.find(s => s.default == true);
  const currentStage = workflow.find(s => s.path === ownProps.params.step);
  const selectedStage = currentStage ? currentStage.id : defaultStage.id;
  return {
    dispatch,
    registrationWizard,
    gotoEducationPage: () => browserHistory.push(`/scp/education`),
    gotoLoginPage: () => browserHistory.push(`/`),
    gotoSCPSoftLoginPage: () => browserHistory.push(`/scp/softlogin`),
    gotoVerifyStage: () => browserHistory.push(`/scp/softregistration/verify`),
    gotoEligibilityStage: () => browserHistory.push(`/scp/softregistration/eligibility`),
    gotoSoftRegisterStage: () => browserHistory.push(`/scp/softregistration`),
    gotoSCPHome: () => browserHistory.push(`/patient/home`),
    gotoWelcome: () => browserHistory.push(`/welcome`),
    gotoFullRegisterPage: () => browserHistory.push(`/scp/fullregistration`),
    selectedStage,
  };
};

export default connect(mapStateToProps, null, mergeProps)(SoftRegistractionPage);
