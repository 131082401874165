import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth';
import userReducer from './user';
import registrationWizardReducer from './registrationWizard';
import schedulingReducer from './scheduling';
import servicesReducer from './services';
import uiReducer from './ui';
import profileReducer from './profile';
import documentsReducer from './documents';
import modalsReducer from './modals';
import appointmentReducer from './appointment';
import appointmentsReducer from './appointments';
import calendarReducer from './calendar';
import switchToPhoneReducer from './misc/switchToPhone';
import redirectActionsReducer from './misc/redirectActions';
import answersReducer from './answers';
import guideProviderReducer from './guides/guideProvider';
import testingGuideReducer from './guides/testingGuide';
import reviewGuideReducer from './guides/reviewGuide';
import followUpGuideReducer from './guides/followUpGuide';
import referralsReducer from './referrals';
import webinarReducer from './webinar';
import userServicePackagesReducer from './misc/userServicePackages';
import surveyScript from './misc/surveyScript';
import scpEncountersReducer from './scpencounters';
import zingtreeReducer from './zingtree';
import meOutreachReducer from './meOutreach';
import enumsReducer from './enums';
import appMetaReducer from './appmeta';
import geneticTestReducer from './geneticTest'

// Add the reducer to your store on the `routing` key
const rootReducer = combineReducers({
  routing: routerReducer,
  auth: authReducer,
  user: userReducer,
  registrationWizard: registrationWizardReducer,
  scheduling: schedulingReducer,
  services: servicesReducer,
  ui: uiReducer,
  form: formReducer,
  profile: profileReducer,
  modals: modalsReducer,
  calendar: calendarReducer,
  appointments: appointmentsReducer,
  appointment: appointmentReducer,
  switchToPhone: switchToPhoneReducer,
  redirectActions: redirectActionsReducer,
  documents: documentsReducer,
  answers: answersReducer,
  referrals: referralsReducer,
  myGuide: combineReducers({
    guideProvider: guideProviderReducer,
    testingGuide: testingGuideReducer,
    reviewGuide: reviewGuideReducer,
    followUpGuide: followUpGuideReducer,
  }),
  webinar: webinarReducer,
  userServicePackages: userServicePackagesReducer,
  surveyScript: surveyScript,
  scpEncounters: scpEncountersReducer,
  zingtree: zingtreeReducer,
  meOutreach: meOutreachReducer,
  enums: enumsReducer,
  appmeta: appMetaReducer,
  geneticTest:geneticTestReducer
});

export default rootReducer;
