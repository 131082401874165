import { me, meLoadProfile } from '../user';
import authService from 'services/api/authService';
import schedulingService from 'services/api/schedulingService';
import StatusMessages from 'constants/StatusMessages';
import { clearSchedulingInfo } from 'ducks/scheduling';
import { showLoader, closeLoader } from 'ducks/ui';
import { reduceSoftSignupResponseData } from 'services/utils';
import { browserHistory } from 'react-router';
import api from 'services/api/api';

const REQUEST_START = 'gm/auth/email/REQUEST_START';
const REQUEST_SUCCESS = 'gm/auth/email/REQUEST_SUCCESS';
const REQUEST_FAILURE = 'gm/auth/email/REQUEST_FAILURE';

const initialState = {
  authError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        authError: null,
      };
    case REQUEST_FAILURE:
      return {
        ...state,
        authError:
          action.payload.errorMessage ||
          action.payload.message ||
          action.payload.fullMessage ||
          StatusMessages.registerDefaultError,
      };
    default:
      return state;
  }
}

export function setRequestStart() {
  return { type: REQUEST_START };
}

export function setRequestSuccess() {
  return { type: REQUEST_SUCCESS };
}

export function setRequestFailure(message) {
  return { type: REQUEST_FAILURE, payload: message };
}

export function login({ email, password, rememberMe }) {
  return (dispatch) => {
    dispatch(setRequestStart());
    return authService
      .login({ email, password }, rememberMe)
      .then(() => {
        dispatch(clearSchedulingInfo());
        dispatch(setRequestSuccess());
        dispatch(me());
      })
      .catch((error) => {
        if (error.submissionError) throw error.submissionError;
        dispatch(setRequestFailure(error));
        throw error;
      });
  };
}

export function logout(redirect = true) {
  return (dispatch) => {
    dispatch(setRequestStart());
    return authService
      .logout()
      .then(() => {
        schedulingService.clearSchedulingData();
        schedulingService.clearSourceType();
        authService.resetGrailDisqualification();
        dispatch(setRequestSuccess());
        if (redirect) {
          browserHistory.push('/');
          window.location.reload(true);
        }
      })
      .catch(({ error }) => dispatch(setRequestFailure(error)));
  };
}

export function activateEmail(activationToken) {
  return (dispatch) => {
    dispatch(setRequestStart());
    dispatch(showLoader());
    return authService
      .verifyAccount({ token: activationToken })
      .then(() => {
        dispatch(setRequestSuccess());
        dispatch(closeLoader());
        return dispatch(me());
      })
      .catch((error) => {
        dispatch(setRequestFailure(error));
        dispatch(closeLoader());
        throw error;
      });
  };
}

export function softLogin(values) {
  return (dispatch) => {
    dispatch(setRequestStart());
    return authService
      .softLogin(values)
      .then((data) => {
        const d = reduceSoftSignupResponseData(data);
        dispatch(setRequestSuccess());
        return d;
      })
      .catch((error) => {
        if (error.submissionError) throw error.submissionError;
        dispatch(setRequestFailure(error));
        throw error;
      });
  };
}

export function softLoginVerifyOTP({ uuid, code }) {
  return (dispatch) => {
    dispatch(setRequestStart());
    return authService
      .softLoginVerifyOTP({ uuid, code })
      .then((data) => {
        dispatch(setRequestSuccess());
        return data;
      })
      .catch((error) => {
        if (error.submissionError) throw error.submissionError;
        dispatch(setRequestFailure(error));
        throw error;
      });
  };
}

export function softLoginResendOTP({ uuid }) {
  return (dispatch) => {
    dispatch(setRequestStart());
    return authService
      .softLoginResendOTP({ uuid })
      .then((data) => {
        dispatch(setRequestSuccess());
        return data;
      })
      .catch((error) => {
        if (error.submissionError) throw error.submissionError;
        dispatch(setRequestFailure(error));
        throw error;
      });
  };
}

export function loginWithoutApi(token) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      api.setToken(null);
      api.setToken(token);
      api.clearNavigatorSurvey();
      const data = await dispatch(meLoadProfile());
      dispatch(closeLoader());      
      return data;
    } catch (error) {
      dispatch(closeLoader());
      throw error;
    }
  };
}
