import React, { Component } from 'react';
import _ from 'lodash';
import minimizedLogo from './logo-minimized.png';
import arrowImage from '../../../../../images/common/chevron-large.svg';
import classnames from 'classnames';
import './EducationList.scss';

class EducationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledPrevious: true,
      disabledNext: false,
      slideItem: 1,
      currentIndex: [0, 1],
    };
  }

  componentDidMount() {
    this.setButtonState();
  }

  handleExternalRedirect = url => {
    window.open(url, '_blank');
  };

  prevPage = () => {
    const { currentIndex, slideItem } = this.state;
    const newIndex = currentIndex.map(a => a - slideItem);
    this.setState({ currentIndex: newIndex }, () => {
      this.setButtonState();
    });
  };

  nextPage = () => {
    const { currentIndex, slideItem } = this.state;
    const newIndex = currentIndex.map(a => a + slideItem);
    this.setState({ currentIndex: newIndex }, () => {
      this.setButtonState();
    });
  };

  getFilteredData = () => {
    const { currentIndex } = this.state;
    const { featuredVideos } = this.props;
    const data = [];
    featuredVideos.forEach((item, index) => {
      if (currentIndex.includes(index)) {
        data.push(item);
      }
    });
    return data;
  };

  setButtonState = () => {
    const { currentIndex, slideItem } = this.state;
    const { featuredVideos } = this.props;
    this.setState({
      disabledPrevious: currentIndex.includes(0),
      disabledNext: currentIndex.includes(featuredVideos.length - slideItem),
    });
  };

  render() {
    const { disabledNext, disabledPrevious } = this.state;
    const { topVideos } = this.props;
    const filteredData = this.getFilteredData();
    return (
      <section className="education-section">
        <section className="featured-video-section">
          <div className="row-section">
            <div className="col-section">
              <div className="featured-video-content">
                <h6 className="featured-video-title">Featured</h6>
                <h3 className="featured-video-sub-title">
                  {_.get(this.props, 'featuredTitle', '')}
                </h3>
                <p className="featured-video-description">
                  {_.get(this.props, 'featuredDescription', '')}
                </p>
                <div className="featured-video-items">
                  <div className="featured-video-items-left-nav">
                    <img
                      className={classnames('left-arrow', disabledPrevious ? 'disabled' : '')}
                      src={arrowImage}
                      alt=""
                      onClick={disabledPrevious ? null : this.prevPage}
                    />
                  </div>
                  {filteredData &&
                    filteredData.map((data, index) => (
                      <div className="featured-video-item" key={`featured-video-item-${index}`}>
                        <img
                          className="featured-video-item-image"
                          src={data.image}
                          alt=""
                          onClick={() => this.handleExternalRedirect(data.videoURL)}
                        />
                        <span className="featured-video-item-tag">
                          <span className="featured-video-item-tag-text">Video</span>
                        </span>
                        <span className="featured-video-timer">
                          <span className="featured-video-timer-text">{data.videoDuration}</span>
                        </span>
                        <div className="featured-video-item-name">{data.name}</div>
                        <div className="featured-video-item-title">{data.title}</div>
                        <div className="featured-video-item-date">{data.date}</div>
                      </div>
                    ))}
                  <div className="featured-video-items-right-nav">
                    <img
                      className={classnames('right-arrow', disabledNext ? 'disabled' : '')}
                      src={arrowImage}
                      alt=""
                      onClick={disabledNext ? null : this.nextPage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-section">
              <div className="featured-video-thumb">
                <img
                  className="featured-video-thumb-image"
                  src={_.get(this.props, 'featuredVideoImage', '')}
                  alt=""
                  onClick={() =>
                    this.handleExternalRedirect(_.get(this.props, 'featuredVideoURL', ''))}
                />
                <span className="featured-video-thumb-logo">
                  <img className="featured-video-thumb-logo-image" src={minimizedLogo} alt="" />
                </span>
                <span className="featured-video-thumb-item-tag">
                  <span className="featured-video-thumb-item-tag-text">Video</span>
                </span>
                <span className="featured-video-thumb-timer">
                  <span className="featured-video-thumb-timer-text">
                    {_.get(this.props, 'featuredVideoDuration', '')}
                  </span>
                </span>
                <div className="featured-video-thumb-name">Genome Medical</div>
                <div className="featured-video-thumb-title">
                  {_.get(this.props, 'featuredVideoTitle', '')}
                </div>
                <p className="featured-video-thumb-description">
                  {_.get(this.props, 'featuredVideoDescription', '')}
                </p>
                <div className="featured-video-thumb-date">
                  {_.get(this.props, 'featuredVideoDate', '')}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="top-video-section">
          <div className="row-section">
            <div className="col-section">
              <div className="top-video-content">
                <h6 className="top-video-title">All Videos</h6>
                <div className="top-video-items">
                  {topVideos &&
                    topVideos.map((data, index) => (
                      <div className="top-video-item" key={`top-video-item-${index}`}>
                        <img
                          className="top-video-item-image"
                          src={data.image}
                          alt=""
                          onClick={() => this.handleExternalRedirect(data.videoURL)}
                        />
                        <span className="top-video-item-tag">
                          <span className="top-video-item-tag-text">Video</span>
                        </span>
                        <span className="top-video-thumb-timer">
                          <span className="top-video-thumb-timer-text">{data.videoDuration}</span>
                        </span>
                        <span className="top-video-item-logo">
                          <img className="top-video-item-logo-image" src={data.image2} alt="" />
                        </span>
                        <div className="top-video-item-title">{data.title}</div>
                        <div className="top-video-item-heading">{data.name}</div>
                        <div className="top-video-item-date">{data.date}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default EducationList;
