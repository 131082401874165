import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';

export class PatientLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className="main-container">
        <Header />
        {this.props.children}
        <Footer />
        <GlobalLoader />
      </div>
    );
  }
}

export default PatientLayout;
