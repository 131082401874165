import PropTypes from 'prop-types';
import React from 'react';
import './PasswordRequirementsTooltip.scss';

const PasswordRequirementsTooltip = () => {
  return (
    <div>
      <div className="password-requirement-title"> Password Requirements </div>
      <ul className="password-requirement-list">
        <li> Length at least 8 characters</li>
        <li>At least one number</li>
        <li>At least one special character (!,.?@#$%^*/=-+)</li>
        <li>At least one uppercase letter</li>
        <li>At least one lowercase letter</li>
      </ul>
    </div>
  );
};

PasswordRequirementsTooltip.propTypes = {
  leftArrow: PropTypes.bool,
  warning: PropTypes.object.isRequired,
};

export default PasswordRequirementsTooltip;
