import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ZingTreeShell from 'components/scp/zingtreeshell/ZingTreeShell';
import { browserHistory } from 'react-router';
import './NavigatorPage.scss';
import fetchHOC from 'components/high-order-components/FetchHOC';
import authService from 'services/api/authService';
import api from 'services/api/api';
import zingtreeService from '../../../../../services/api/zingtreeService';
import { isPartnerSoftRegisterOptionalFlow } from 'services/misc/ReferralSource';
import _ from 'lodash';
import { connect } from 'react-redux';
import { showLoader, closeLoader } from 'ducks/ui';
import gaTrack, { GA_TR_NAVIGATOR_PAGE } from 'services/gaTrack';

class NavigatorPage extends Component {
  constructor(props) {
    super(props);
    this.onResultNodeReach = this.onResultNodeReach.bind(this);
    this.onFailureNodeReach = this.onFailureNodeReach.bind(this);
  }
  componentDidMount() {
    gaTrack(GA_TR_NAVIGATOR_PAGE);
    const { config } = this.props;
    const partnerId = authService.getAffiliationId();
    if (partnerId) {
      if (!config.data && !config.loading)
        config.fetch({ partner_id: partnerId, tree_type: 'navigation' });
    } else {
      /* if partner is not available. redirect to login page. */
      browserHistory.push('/');
    }
  }

  onResultNodeReach = treedata => {
    const { dispatch } = this.props;
    const { config } = this.props;
    const surveyTreeId = _.get(config, ['data', 'tree_id'], '');
    const surveySessionId = _.get(config, ['data', 'session_id'], '');

    if (!_.isEmpty(surveyTreeId) && !_.isEmpty(surveySessionId)) {
      api.setNavigatorSurvey({
        surveyTreeId,
        surveySessionId,
      });
    }

    const education_tree_id = _.get(treedata, 'module_assignment_id', '');
    const eduction_session_id = uuidv4().replace(/-/g, '');
    let defaultURL = '/scp/softregistration';
    if (
      isPartnerSoftRegisterOptionalFlow() &&
      !_.isEmpty(education_tree_id) &&
      !_.isEmpty(eduction_session_id)
    ) {
      defaultURL = '/scp/education';
      api.setEducationSurvey({
        surveyTreeId: education_tree_id,
        surveySessionId: eduction_session_id,
      });
    }
    dispatch(showLoader());
    setTimeout(() => {
      dispatch(closeLoader());
      browserHistory.push(defaultURL);
    }, 5000);
  };

  onFailureNodeReach = () => {};

  render() {
    const { config } = this.props;
    return (
      <div className="scp-navigator-page">
        {_.get(config, ['data', 'tree_id']) && _.get(config, ['data', 'session_id']) ? (
          <ZingTreeShell
            tree_id={_.get(config, ['data', 'tree_id'])}
            session_id={_.get(config, ['data', 'session_id'])}
            onResultNodeReach={this.onResultNodeReach}
            onFailureNodeReach={this.onFailureNodeReach}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(
  fetchHOC({
    config: ({ partner_id, tree_type }) =>
      zingtreeService.getPartnerSurveyTree(partner_id, tree_type),
  })(NavigatorPage)
);
