import { browserHistory } from 'react-router';

export function goToReschedule() {
  browserHistory.push('/patient/reschedule');
}

export function goToSCPReschedule() {
  browserHistory.push('/patient/reschedule');
}

export function goToSelectService() {
  browserHistory.push('/scheduling/select-service');
}
