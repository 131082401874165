import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import InputField from 'components/forms/controls/V2/InputField';
import CheckBoxField from 'components/forms/controls/V2/CheckBoxField';

import { required, email } from 'services/validate';
import './LoginForm.scss';
import _ from 'lodash';

class LoginForm extends Component {
  static propTypes = {
    onForgotPassword: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    isforgetLinkShow: PropTypes.bool,
  };

  onChangePassword = () => {
    if (_.get(this, ['props', 'submitFailed'], false)) {
      this.props.clearSubmitErrors();
    }
  };
  render() {
    const { handleSubmit, onForgotPassword, submitting, valid, isforgetLinkShow } = this.props;

    return (
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="label-field required">Email Address</div>
        <div className="input-section">
          <Field
            name="email"
            type="email"
            placeholder="Email Address"
            trim={true}
            component={InputField}
            validate={[required('Email'), email]}
          />
        </div>

        <div className="label-field required">Password</div>
        <div className="input-section">
          <Field
            name="password"
            type="password"
            placeholder="Password"
            trim={true}
            component={InputField}
            validate={[required('Password')]}
            onChange={() => this.onChangePassword()}
          />
        </div>

        <div className="gm-login-form-inline">
          <div className="form-inline-right">
            <Field
              name="rememberMe"
              type="checkbox"
              label={
                <Fragment>
                  <span className="checkmark" />
                  <div className="label-section">Remember Me</div>
                </Fragment>
              }
              component={CheckBoxField}
            />
          </div>
          {isforgetLinkShow && (
            <div className="form-inline-right">
              <Link
                to="/password/forgot"
                className="reset-password-link"
                onClick={onForgotPassword}>
                Forgot password?
              </Link>
            </div>
          )}
        </div>

        <ButtonV2 className="submit-button" type="submit" disabled={submitting || !valid}>
          Sign In
        </ButtonV2>

        <div className="no-account-text">
          Don’t have an account?
          <Link to="register/account" className="reset-password-link">
            {' '}
            Sign Up
          </Link>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'login',
})(LoginForm);
