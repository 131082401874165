import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import CalendarDay from 'components/widgets/SCP/CalendarDay/CalendarDay';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import Modal from 'components/modals/ModalDialog';
import Alert from 'components/widgets/Alert/Alert';
import AppointmentSummary from 'components/widgets/SCP/EventInfo/AppointmentSummary';
import './AppointmentModalTemplate.scss';

class AppointmentModalTemplate extends Component {
  render() {
    const {
      header,
      error,
      enable,
      appointment,
      confirmText,
      cancelText,
      newPrice,
      invertAction,
      show,
      onCancel,
      onConfirm,
      isPackage,
      isRescheduling,
      isMobile,
    } = this.props;

    const date = new Date(appointment.startTime);
    return (
      <Modal
        className={`scp-appointment-modal md850 ${isMobile ? 'is-mobile' : ''}`}
        modalHeader={header}
        onHide={onCancel}
        show={show}>
        <div className="outer-block">
          <div className="block" data-hj-suppress>
            {!isMobile && <CalendarDay className="cal-day" date={date} />}
            {!_.isEmpty(appointment) && (
              <AppointmentSummary
                appointment={appointment}
                newPrice={newPrice}
                hidePrice={isPackage || isRescheduling}
                isMobile={isMobile}
              />
            )}
          </div>
          <Alert message={error} />
          <div className="buttons">
            <ButtonV2
              className="confirm"
              onClick={invertAction ? onCancel : onConfirm}
              disabled={!enable}>
              {confirmText}
            </ButtonV2>
            {isMobile ? (
              <ButtonV2
                onClick={invertAction ? onConfirm : onCancel}
                className="button-cancel-appointment">
                {cancelText}
              </ButtonV2>
            ) : (
              <span className="reject" onClick={invertAction ? onConfirm : onCancel}>
                {cancelText}
              </span>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

AppointmentModalTemplate.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  error: PropTypes.string,
  enable: PropTypes.bool,
  invertAction: PropTypes.bool,
  newPrice: PropTypes.number,
  isPackage: PropTypes.bool,
  isRescheduling: PropTypes.bool,
  isMobile: PropTypes.bool,
};

AppointmentModalTemplate.defaultProps = {
  isMobile: false,
};

export default AppointmentModalTemplate;
