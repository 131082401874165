// @flow
import api from './api';
import type {
  RegisterRequest,
  RegisterResponse,
  SoftRegisterRequest,
  SoftRegisterResponse,
  VerifyEmailRequest,
  LoginRequest,
  SoftLoginRequest,
  softLoginVerifyOTPRequest,
  softLoginResendOTPRequest,
  LoginResponse,
  VerifyAccountRequest,
  RequestAccountActivationEmailRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  RefreshAccessTokenRequest,
  RefreshAccessTokenResponse,
  ChangePasswordRequest,
  CheckActionRequest,
  CheckActionResponse,
  CheckLinkRequest,
  ResendEmailRequest,
} from './authApiTypes';

export default class AuthAPI {
  register(params: RegisterRequest): Promise<RegisterResponse> {
    return api.post('/auth/email/register', params);
  }

  login(params: LoginRequest): Promise<LoginResponse> {
    params.email = params.email.toLowerCase();
    return api.post('/auth/email/login', params);
  }

  softLogin(params: SoftLoginRequest): Promise<any> {
    return api.post('/auth/email/soft-login', params);
  }

  softLoginVerifyOTP(params: softLoginVerifyOTPRequest): Promise<any> {
    const { uuid, code } = params;
    return api.post(`/auth/email/soft-login/${uuid}/code/verify`, { code });
  }

  softLoginResendOTP(params: softLoginResendOTPRequest): Promise<any> {
    const { uuid } = params;
    return api.post(`/auth/email/soft-login/${uuid}/code/resend`);
  }

  verifyAccount(params: VerifyAccountRequest): Promise<any> {
    return api.post('/auth/email/verify', params);
  }

  requestAccountActivateEmail(params: RequestAccountActivationEmailRequest): Promise<any> {
    return api.post('/auth/email/request-verification', params);
  }

  forgotPassword(params: ForgotPasswordRequest): Promise<any> {
    params.email = params.email.toLowerCase();
    return api.post('/auth/password/forgot', params);
  }

  setPassword(params: ResetPasswordRequest): Promise<any> {
    return api.post('/auth/password/set', params);
  }

  resetPassword(params: ResetPasswordRequest): Promise<any> {
    return api.post('/auth/password/reset', params);
  }

  refreshAccessToken(params: RefreshAccessTokenRequest): Promise<RefreshAccessTokenResponse> {
    return api.post('/auth/token/refresh', params);
  }

  changePassword(params: ChangePasswordRequest): Promise<any> {
    return api.post('/auth/password/change', params);
  }

  checkAction(params: CheckActionRequest): CheckActionResponse {
    return api.post('/auth/check-action', params);
  }

  checkLink(params: CheckLinkRequest): Promise<any> {
    const { token, type } = params;
    return api.get(`/auth/link/check?token=${token}&type=${type}`);
  }

  resendSetPasswordActivateEmail(params: ResendEmailRequest): Promise<any> {
    return api.post('/auth/password/resend-setpassword-email', params);
  }

  softRegister(params: SoftRegisterRequest): Promise<SoftRegisterResponse> {
    return api.post('/auth/email/soft-register', params);
  }

  updateSoftRegister(uuid, params: SoftRegisterRequest): Promise<SoftRegisterResponse> {
    return api.patch(`/auth/email/soft-register/${uuid}`, params);
  }

  verifyEmail(uuid, params: VerifyEmailRequest): Promise<any> {
    return api.post(`/auth/email/soft-register/${uuid}/code/verify`, params);
  }

  resendCode(uuid): Promise<any> {
    return api.post(`/auth/email/soft-register/${uuid}/code/resend`);
  }
}
