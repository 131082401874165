import _ from 'lodash';

export function hydrateInsurances(data) {
  const primaryIns = _.find((data || []), ins => {
    return ins.insurance_type == 'primary' && ins.visit_insurance == true;
  });
  const secondaryIns = _.find((data || []), ins => {
    return ins.insurance_type == 'secondary';
  });
  return {
    primaryInsurance: primaryIns ? hydrateInsurance(primaryIns) : {},
    secondaryInsurance: secondaryIns ? hydrateInsurance(secondaryIns) : {},
    isSecondaryInsuranceAvailable: !_.isEmpty(secondaryIns ? hydrateInsurance(secondaryIns) : {}),
  };
}

export function hydrateInsurance(data) {
  return {
    isPolicyholder: data.is_policyholder,
    policyholderRelationship: data.policyholder_relationship,
    policyholderDob: data.policyholder_dob,
    policyholderName: data.policyholder_name,
    policyholderFirstName: data.policyholder_first_name,
    policyholderLastName: data.policyholder_last_name,
    policyholderBiolocialSex: data.policyholder_biological_sex,
    insuranceNo: data.insurance.insurance_no,
    companyName: data.insurance.company_name,
    phone: data.insurance.phone,
    planName: data.insurance.plan_name,
    groupNo: data.insurance.group_no,
    insuranceCompanyId: data.insurance.insurance_company_id,
    insuranceAddress: data.insurance.insurance_address,
    insuranceCity: data.insurance.insurance_city,
    insuranceState: data.insurance.insurance_state,
    insuranceZipCode: data.insurance.insurance_zipcode,
    athenaInsurancePackageName: data.insurance.athena_insurance_package_name,
    athenaInsurancePackageID: data.insurance.athena_insurance_package_id,
    employer: data.insurance.employer,
    insurance_type:data.insurance_type
  };
}

function isInsuranceEmpty(InsuranceDetails) {
  return _.every(InsuranceDetails, (value, key) => {
    let result = false;
    if (key == "isPolicyholder" && value == true){
      result = true;
    } else if (key == "insurance_type"){
      result = true;
    }else if (typeof value == 'string') {
      result = _.isEmpty(value);
    }
    else {
      result = _.isNil(value);
    }
    return result;
  })
}

export function dehydrateInsurances(data) {
  let insurance = {
    insurance: []
  }
  insurance.insurance.push({
    ...dehydrateInsurance(data.primaryInsurance),
    insurance_type: 'primary'
  })
  if (!isInsuranceEmpty(data.secondaryInsurance)) {
    insurance.insurance.push({
      ...dehydrateInsurance(data.secondaryInsurance),
      insurance_type: 'secondary'
    })
  }
  return insurance;
}

export function dehydrateInsurance(data) {
  return {
    insurance_company_id: data.insuranceCompanyId || '',
    is_policyholder: data.isPolicyholder || '',
    policyholder_relationship: data.policyholderRelationship || '',
    policyholder_dob: data.policyholderDob || '',
    policyholder_name: data.policyholderName || '',
    policyholder_first_name: data.policyholderFirstName || '',
    policyholder_last_name: data.policyholderLastName || '',
    policyholder_biological_sex: data.policyholderBiolocialSex || '',
    insurance_no: data.insuranceNo || '',
    company_name: data.companyName || '',
    phone: data.phone || '',
    plan_name: data.planName || '',
    group_no: data.groupNo || '',
    insurance_address: data.insuranceAddress || '',
    insurance_city: data.insuranceCity || '',
    insurance_state: data.insuranceState || '',
    insurance_zipcode: data.insuranceZipCode || '',
    employer: data.employer || '',
  };
}
