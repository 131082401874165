//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import RadioButtonGroup from './RadioButtonGroup';
class SingleSelectBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //bindings
    this.handleChange = this.handleChange.bind(this);
    this.getCurrentValue = this.getCurrentValue.bind(this);
  }
  //functions

  componentDidMount() {
    if (get(this, 'bodyElement', null)) this.props.setBodyElement(get(this, 'bodyElement', null));
  }

  handleChange(e) {
    if (get(this, 'props.hasSetStateFunc', false)) {
      let value = e;
      if (value === 'false') {
        this.props.setInternalValue(false);
      } else if (value === 'true') {
        this.props.setInternalValue(true);
      } else if (
        get(this, 'props.convertStrsToNums', false) &&
        typeof value === 'string' &&
        value.match(/^-?\d+$/gi)
      ) {
        this.props.setInternalValue(Number(value));
      } else {
        this.props.setInternalValue(value);
      }
    }
  }

  getCurrentValue() {
    if (this.props.internalValue === undefined) {
      return get(this, 'props.currentValue', null);
    } else return get(this, 'props.internalValue', null);
  }

  render() {
    return (
      <div
        className="singleSelectModalBodyContainer"
        ref={el => (this.bodyElement = el)}
        onScroll={() => this.props.scrollHandler(['top', 'bottom'])}>
        <RadioButtonGroup
          data={get(this, 'props.data', []) || []}
          currentValue={this.getCurrentValue()}
          onChangeFunc={this.handleChange}
        />
      </div>
    );
  }
}

export default SingleSelectBody;
