import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import Button from 'components/forms/controls/Button';
import MailtoLink from 'components/widgets/Link/MailtoLink';
import { FollowUpConsultation } from 'constants/ServiceTypes';
import { CONTACT_PHONE } from 'services/constants';
import './YourNextSteps.scss';

class YourNextSteps extends Component {
  scheduleFollowUp = () => {
    browserHistory.push(`/scheduling/${FollowUpConsultation}/calendar`);
  };

  render() {
    const { webinar } = this.props;

    return (
      <div className="my-guide-step4">
        <h3>Your Next Step</h3>
        {webinar.testResultsAvailable ? (
          <div>
            <p>
              Your test results are in and have been sent to your email. If you have any questions,
              or you’re not sure about what to do next, please contact us{' '}
              <strong>{CONTACT_PHONE}</strong>, or <MailtoLink />, or chat box below
            </p>
            <Button onClick={this.scheduleFollowUp}>Schedule Your Appointment</Button>
          </div>
        ) : (
          <p>
            Your genetic care coordinator will contact you to{' '}
            <strong>order your tests and arrange follow up consultation</strong>.<br />
            If you have any questions, or you’re not sure about what to do next, please contact us{' '}
            <strong>{CONTACT_PHONE}</strong>, or <MailtoLink />, or chat box.
          </p>
        )}
      </div>
    );
  }
}

YourNextSteps.propTypes = {
  webinar: PropTypes.object,
  consultationTypes: PropTypes.array.isRequired,
  scheduling: PropTypes.object.isRequired,
};

const mapStateToProps = ({ scheduling, webinar }) => ({
  scheduling,
  consultationTypes: scheduling.consultationTypes,
  webinar: webinar.data,
});

export default connect(mapStateToProps)(YourNextSteps);
