import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import './UserMenu.scss';

const UserMenu = ({ user, onLogout /*showInvoices*/ }) => {
  const roles = {
    patient: 'Patient',
  };
  const liteAccount = !user.fullName;
  return (
    <div className="header-user-menu">
      <div data-hj-suppress className={classnames('user-name', { 'lite-account': liteAccount })}>
        {liteAccount ? user.email : user.fullName}
      </div>
      {!liteAccount && (
        <div data-hj-suppress className="user-role">
          {roles[user.role]}
        </div>
      )}
      <div className="user-hidden-menu">
        <div className="user-menu-point">
          <Link to="/patient/profile">Profile</Link>
        </div>
        <div className="user-menu-point" onClick={onLogout}>
          <a>Sign Out</a>
        </div>
      </div>
    </div>
  );
};

UserMenu.propTypes = {
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  //showInvoices: PropTypes.bool,
};

export default UserMenu;
