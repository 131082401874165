import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import ModalDialog from '../ModalDialog';
import Button from 'components/forms/controls/Button';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './InfoModal.scss';

const InfoModal = ({
  className,
  title,
  description,
  submitText = 'OK',
  cancelText,
  submitCallback,
  isSetPasswordSource,
  cancelCallback,
}) => {
  return (
    <ModalDialog
      className={classnames('info-modal', 'md850', className)}
      show={true}
      onHide={cancelCallback || submitCallback}>
      <div className="info-body">
        {title && <div className="info-title">{title}</div>}
        {description ? <div className="info-desc">{description}</div> : <br />}
        <div className="buttons">
          {cancelText && (
            <span className="cancel-button" onClick={cancelCallback}>
              {cancelText}
            </span>
          )}
          {isSetPasswordSource ? (
            <ButtonV2 className="submit-button" onClick={submitCallback}>
              {submitText}
            </ButtonV2>
          ) : (
            <Button onClick={submitCallback}>{submitText}</Button>
          )}
        </div>
      </div>
    </ModalDialog>
  );
};

InfoModal.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  submitCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.string,
  isSetPasswordSource: PropTypes.bool,
};

export default InfoModal;
