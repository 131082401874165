import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ButtonV2 from 'components/forms/controls/ButtonV2';

import './PatientCards.scss';
import testOrderIcon from './test-order.svg';

const GrailGalleri = props => {
  const { grailGalleriOrderCreatedAt } = props;
  return (
    <div className="upcoming-appointment">
      <div className="content galleri-content mb15">
        <div className="static">
          <div className="left">
            <div className="galleri-benefit-badge">
              <span>The Galleri multi-cancer early detection test</span>
            </div>
            <div className="text">
              {grailGalleriOrderCreatedAt ? (
                <Fragment>
                  Your Galleri test request was successfully submitted on{' '}
                  {grailGalleriOrderCreatedAt}.
                  <div className="mt-10">
                    You do not need to schedule an appointment for this request to be reviewed and
                    ordered (if appropriate) by a Genome Medical physician. Should you have any
                    questions or you prefer to schedule a pre-test appointment, please call{' '}
                    <a href="tel:+18776880992">(877) 688-0992</a> to speak with a Clinical Care
                    Coordinator.
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  The Galleri multi-cancer early detection test detects more than 50 types of
                  cancer, many of which are not commonly screened for today. You can request the
                  test today by clicking ‘Request the Galleri Test’ below and answering a few
                  questions.{' '}
                  <div className="mt-10">
                    You are not required to schedule a pre-test appointment to request the test.
                    Should you have any questions or you prefer to schedule a pre-test appointment,
                    please call <a href="tel:+18776880992">1 (877) 688-0992</a> to speak with a
                    Clinical Care Coordinator.
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <div className="right">
            <img className="visual-content" src={testOrderIcon} alt="Grail Galleri" />
          </div>
        </div>
        <div className="action-items left">
          {!grailGalleriOrderCreatedAt && (
            <ButtonV2 path="/patient/grailgalleri">Request the Galleri test</ButtonV2>
          )}
        </div>
      </div>
    </div>
  );
};

GrailGalleri.propTypes = {
  grailGalleriOrderCreatedAt: PropTypes.isRequired,
};

export default GrailGalleri;
