import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ModalDialog from 'components/modals/ModalDialog';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import Alert from 'components/widgets/Alert/Alert';
import WarningIcon from '../../../../images/warning.svg';

import './VideoAppointmentModal.scss';

const VideoAppointmentModal = props => {
  const modalHeader =
    props.isMobile == true ? (
      <Fragment>
        <img src={WarningIcon} alt="Warning" class="warning-icon" />Switch to video appointment
      </Fragment>
    ) : (
      'Would you prefer a video appointment?'
    );
  return (
    <ModalDialog
      className={`md850 scp-video-appointment-modal ${props.isMobile ? 'is-mobile' : ''}`}
      modalHeader={modalHeader}
      show={props.show}
      onHide={props.onHide}>
      <div className="wrapper">
        <div className="phone-appointment-content" data-hj-suppress>
          <span>
            If you have a microphone and a camera on your device, you can have your genetics
            consultation over video using Zoom.
          </span>
          <div className="phone-number">{}</div>
          <span>
            If your device has the equipment you need but you are having trouble with installation
            or registration, please review these{' '}
            <a className="zoom-instruction-link" href="https://zoom.us/download" target="_blank">
              step-by-step installation instructions.
            </a>
          </span>
        </div>
        {props.error || props.phoneError ? (
          <Alert message={props.error || props.phoneError} />
        ) : null}
        <div className="buttons-container">
          <ButtonV2 onClick={props.onHide} className="button-stay-with-zoom">
            Stay with phone appointment
          </ButtonV2>
          {props.isMobile ? (
            <ButtonV2
              onClick={() => {
                props.onConfirm('video');
                props.onAfterConfirm && props.onAfterConfirm();
              }}
              className="button-to-phone">
              Opt for video appt over Zoom
            </ButtonV2>
          ) : (
            <span
              className="button-to-phone"
              onClick={() => {
                props.onConfirm('video');
                props.onAfterConfirm && props.onAfterConfirm();
              }}>
              Opt for video appointment over Zoom
            </span>
          )}
        </div>
      </div>
    </ModalDialog>
  );
};

VideoAppointmentModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  error: PropTypes.string,
  onConfirm: PropTypes.func,
  onAfterConfirm: PropTypes.func,
  isMobile: PropTypes.bool,
};

VideoAppointmentModal.defaultProps = {
  isMobile: false,
};

export default VideoAppointmentModal;
