import api from './api';

export default class geneticTestApi {
  getLabTestInfo(labName,testName,partnerID) {
    let URL = `/lab/${labName}/genetic-test/${testName}`;
    if(partnerID){
      URL+= `?partner_id=${partnerID}`
    }
    return api.get(URL);
  }

}
