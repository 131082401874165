export const sectionProviderInfo = 'providerInfo';
export const sectionReferralInfo = 'referralInfo';
export const sectionPatientInfo = 'patientInfo';
export const sectionAdditionalInfo = 'additionalInfo';
export const thankYouStep = 'thankYouStep';

export default {
  [sectionProviderInfo]: {
    sectionKey: sectionProviderInfo,
    section: 'Provider information',
    questions: {
      physicianName: 'Physician Name',
      officeContactName: 'Office contact name',
      officeContactEmail: 'Office contact email address',
      providerPhone: 'Phone',
      npiNumber: 'NPI number',
      alternativeEmail: 'Preferred Alternate Email or Fax',
    },
  },
  [sectionReferralInfo]: {
    sectionKey: sectionReferralInfo,
    section: 'Referral Information',
    questions: {
      reasonForReferral: 'Reason for referral to  Genome Medical',
      clinicalArea: 'Clinical area (select all that apply)',
      previouslyHadGeneticTesting:
        'Has this patient or a family member previously had genetic testing?',
      positiveResult:
        'Was the patient or family member found to carry a "positive" result (i.e. an inherited genetic mutation was reported by the laboratory?)',
    },
  },
  [sectionPatientInfo]: {
    sectionKey: sectionPatientInfo,
    section: 'Patient Information',
    questions: {
      patientName: 'Name',
      dateOfBirth: 'Date of birth',
      patientPhone: 'Patient phone',
      patientEmail: 'Patient email address',
      patientState: 'State that patient would be located in during consultation',
      givenPermission:
        'My patient has given permission to be contacted directly by Genome Medical.',
      canEmail: 'Can we email this patient?',
      canPhone: 'Can we call this patient by phone?',
    },
  },
  [sectionAdditionalInfo]: {
    sectionKey: sectionAdditionalInfo,
    section: 'Additional Information',
    questions: {
      insuranceCompany: 'Insurance Company',
      policyHolderName: 'Policy Holder’s Name',
      policyHolderDOB: 'Policy Holder’s DOB',
      policyID: 'Policy ID ',
      patientAddressLine1: 'Patient’s home address Line1',
      streetAddressLine2: 'Street Address Line 2',
      additionalCity: 'City',
      additionalState: 'State',
      zipCode: 'Zip Code',
      ancestry: 'Ancestry',
      captcha: 'Security challenge',
    },
  },
};

export const answers = {
  clinicalArea: [
    { name: 'oncology', label: 'Oncology' },
    { name: 'cardiacGenetics', label: 'Cardiac genetics' },
    { name: 'reproductiveHealth', label: 'Reproductive health' },
    { name: 'pediatrics', label: 'Pediatrics' },
    { name: 'proactiveCare', label: 'Proactive/preventative care' },
    { name: 'other', label: 'Other' },
  ],
  ancestry: [
    { name: 'asian', label: 'Asian' },
    { name: 'black', label: 'Black/African American' },
    { name: 'white', label: 'White/Caucasian' },
    { name: 'jewish', label: 'Jewish/Ashkenazi Jewish' },
    { name: 'hispanic', label: 'Hispanic' },
    { name: 'native', label: 'Native American' },
    { name: 'pacific', label: 'Pacific Islander' },
    { name: 'other', label: 'Other' },
  ],
};
