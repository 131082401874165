import moment from 'moment';

import { clearOffset } from './transformersUtils';
import { hydrateProvider, hydrateConsultation } from './schedulingTransformers';
import { hydratePayment } from './paymentsTransformers';

export const hydrateStatuses = data => data.map(d => hydrateStatus(d));

export const hydrateStatus = data => ({
  status: data.status,
  created: data.created_at,
  reason: data.reason,
});

export const hydrateAppointments = data => data.map(d => hydrateAppointment(d));

export const hydrateAppointmentNano = data => ({
  id: data.id,
  startTime: clearOffset(data.start_time),
  endTime: clearOffset(data.end_time),
  mStartTime: moment(data.start_time),
  mEndTime: moment(data.end_time),
  timezone: data.timezone,
  timezoneAbb: data.timezone_abbreviation,
  actualStartTime: data.actual_start_time,
  actualEndTime: data.actual_end_time,
  state: data.state,
  reasonForVisit: data.reason_for_visit,
  byPhone: data.by_phone,
  interestedInFollowUp: data.interested_in_follow_up,
  testResultsAvailable: data.test_results_available,
  isFuture: data.is_future,
  isProgress: data.is_in_progress,
  isPast: data.is_past,
  provider: hydrateProvider(data.provider),
  consultation: hydrateConsultation(data.consultation),
  vsee_specialty: data.vsee_specialty,
  country: data.country
});

export const hydrateAppointment = data => ({
  ...hydrateAppointmentNano(data),
  latestStatus: data.latest_status && hydrateStatus(data.latest_status),
  payment: data.payment && hydratePayment(data.payment),
  isUpcoming: data.is_future || data.is_in_progress,
  isComplete: data.latest_status && data.latest_status.status === 'completed',
  isMissed: data.is_past && moment().isAfter(moment(data.end_time).add(2, 'h')),
  isStartSoon:
    data.is_future &&
    moment()
      .add(15, 'minutes')
      .isAfter(moment(data.start_time)),
  type: data.type,
});
