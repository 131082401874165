import React from 'react';

import './Privacy.scss';

const Privacy = () => {
  const PTagClassName="v2";
  return (
    <article className="privacy-story grey-text">
      <h2 className="center-header v2"> GENOME MEDICAL SERVICES </h2>
      <h3 className="center-header grey-text v2">Notice of Privacy Practices </h3>
      <p className={PTagClassName}>This notice describes how medical information about you may be used and disclosed and how you can get access to this information. Please review it carefully.

      </p>

      <h3 className="grey-text" >About us</h3>

      <p className={PTagClassName}>
        In this Notice, we use terms like “we”, “us” or “our” or “GMS” to refer to Genome
        Medical Services, P.C. and its providers. We may use and share your protected health
        information to provide you with the health care services, to treat you, to pay for your care,
        and to conduct our business operations (e.g., quality assurance, compliance, and utilization review),
        and as otherwise described in this Notice.
      </p>

      <h3 className="grey-text" >What is “Protected Health Information” or “PHI?”</h3>
      <p className={PTagClassName}>
        “Protected health information,” or “PHI” for short, is information that identifies who you are and
        relates to your past, present, or future physical or mental health or condition, the provision of
        health care to you, or past, present, or future payment for the provision of health care to you.
        PHI does not include information about you that is in a summary form that does not identify who you are.
      </p>

      <h3 className="grey-text" >Purpose of this Notice</h3>

      <p className={PTagClassName}>
        In the course of doing business, we gather and maintain PHI about our patients. We respect the privacy
        of your PHI and understand the importance of keeping this information confidential and secure. We are
        required by law to maintain the privacy of your PHI by implementing reasonable and appropriate safeguards.
        We are also required to explain to you by this Notice our legal duties and privacy practices with respect to PHI.
        We are also required by law to notify affected individuals following a breach of unsecured PHI.
      </p>

      <h3 className="grey-text" >Uses and Disclosures of PHI We May Make Without Your Authorization</h3>

      <p className={PTagClassName}>
        The following categories describe different ways in which we may use or disclose your PHI. The examples
        provided under the categories below are not intended to be comprehensive, but instead, to identify some
        of the more common types of uses and disclosures of PHI within the category.
      </p>

      <h3 className="bullet-point grey-text">1. Treatment, Payment and Health Care Operations</h3>
      <p className="font-bold v2" >
        We may use and disclose PHI for treatment, payment and health care operations activities,
        as described more fully below.  We are not required to obtain your authorization for these activities.
      </p>

      <p className={PTagClassName}>
          <span className="font-bold"> a. Treatment.</span> We provide telemedicine and genetic testing and counseling
          services, and we use your PHI to provide you with these health care services. We disclose PHI to authorized healthcare
          professionals who need access to your test results in order to treat you. <br />

          <span className="font-bold"> b. Payment.</span> We may use or disclose PHI to obtain payment for the services we have provided.
          For example, we may use and disclose your PHI to bill you or your health insurer for your genetic tests. <br />

          <span className="font-bold"> c. Health Care Operation.</span> We may use and disclose your PHI for activities necessary to support
          our business, such as, but not limited to, review the quality of care you received or to perform internal audits.

      </p>

      <h3 className="bullet-point grey-text">
        2. Other Types of Use and Disclosures We May Make Without Your Authorization
      </h3>

      <p className="font-bold v2" >
        We may also use and disclose your PHI without your authorization for the following purposes when the conditions set forth
        by applicable law for each respective type of disclosure are met:
      </p>

      <p className={PTagClassName}>
          a. When required by law – In some circumstances, we are required by federal or state
          laws to disclose certain PHI to others, such as public agencies; <br />
          b. For public health activities – Such as reports about communicable diseases or defective medical devices; <br />
          c. Reports about child and other types of abuse or neglect, or domestic violence; <br />
          d. For health oversight activities – Such as reports to governmental agencies that are responsible for overseeing the health care system; <br />
          e. For lawsuits and other proceedings – Such as in connection with court proceedings or proceedings before administrative agencies; <br />
          f. For law enforcement purposes – For example in response to a warrant or to report a crime on the premises; <br />
          g. Reports to coroners, medical examiners, or funeral directors – To assist them in performance of their legal duties; <br />
          h. For tissue or organ donations – To organ procurement or transplant organizations in connection with a transplant or organ, eye, or tissue donation; <br />
          i. For research – To medical researchers with an approval of an institutional review board (IRB) or privacy board that oversees studies on human subjects or in certain other narrow circumstances;<br />
          j. To avert a serious threat to the health or safety of you or other members of the public; <br />
          k. For specialized government functions and activities; and <br />
          l. In connection with services provided under workers’ compensation laws.

      </p>

      <h3 className="grey-text" >Uses and Disclosures Requiring You to Have the Opportunity to Agree or Object</h3>
      <p className={PTagClassName}>
        Before we make certain uses and disclosures of your PHI without your written authorization, we must provide
        you with an opportunity to agree or object. We may disclose your PHI to your family members or other persons
        if they are involved in your care or payment for that care. We may disclose your PHI to notify and assist disaster
        relief organizations in their relief efforts. We will provide you with the opportunity to agree or to object prior
        to these disclosures if you are present or otherwise available prior to these uses or disclosures. If you cannot agree
        or object because you are incapacitated or otherwise unavailable, we will use our professional judgement to determine
        whether, based on the circumstances, the disclosure appears to be in your best interests; if it is, we will disclose only
        PHI that is directly relevant to such person's involvement in your care or payment for your care.
      </p>

      <h3 className="grey-text" >Uses and Disclosures Requiring Your Authorization</h3>
      <p className={PTagClassName}>
        All other uses and disclosures of your PHI that are not described in this Notice, including certain marketing activities
        or for the sale of PHI, we will first obtain your written authorization.  You may revoke your authorization, in writing,
        at any time.  If you revoke your authorization, we will no longer use or disclose PHI except to the extent we have taken
        action in reliance on your authorization.  In addition, with very limited exceptions, we must obtain your written authorization
        in order to disclose any notes recorded by a mental health professional about you in a counseling session.
      </p>
      <p className={PTagClassName}>
        To revoke your authorization, please notify us in writing at <a href="mailto: clinical@genomemedical.com">clinical@genomemedical.com</a> or <a href="mailto: legal@genomemedical.com">legal@genomemedical.com</a>
      </p>

      <h3 className="grey-text" >Your Rights Regarding Your PHI</h3>
      <p className="font-bold v2" >
        You have the following rights regarding your PHI. To exercise any of these rights, please contact us using the contact
        information provided at the end of this Notice.
      </p>

      <p className={PTagClassName}>
        <span className="font-bold">Right to Request Restrictions:</span> You have the right to request restrictions on our use or
        disclosure of your PHI to carry out treatment, payment, or health care operations. You may also ask that we limit the information
        we give to someone who is involved in your care, such as a family or friend. Please note that we are not required to agree
        to your request except (unless otherwise required by law) where you have paid for an item or service in full out-of-pocket and
        request that we not disclose information about that item or service to your health plan. If we do agree, we will honor your
        limits unless it is an emergency situation.
      </p>

      <p className={PTagClassName}>
        <span className="font-bold">Right to Receive Confidential Communications or Communications by Alternative Means or at
        an Alternative Location:</span> You have the right to request that we communicate with you by another means or at a
        different address. For example, you may request that we contact you at home rather than at work.  Your request must
        be made in writing and include information on how payment, if any, will be handled and specify an alternative address
        or method of contact. We will accommodate all such reasonable requests.
      </p>

      <p className={PTagClassName} >
        <span className="font-bold">Right to Inspect and Copy:</span> You have the right to request to inspect and receive a
        copy of your PHI that we maintain in a designated record set (our medical and billing records or information used to
        make decisions about you).  Your request must be made in writing.  We may charge a reasonable fee for the cost of
        producing and mailing the copies.  In certain situations, we may deny your request and will tell you why we are denying
        it. In some cases you may have the right to ask for a review of our denial.
      </p>

      <p className={PTagClassName} >
        <span className="font-bold">Right to Amend:</span> You have the right to request that we amend your PHI that is
        maintained in a designated record set if you believe the information is incorrect or incomplete. Your request must
        be made in writing and include a detailed description of what information you seek to amend and the reasons that
        support your request.  We may deny your request in certain situations.  We will notify you in writing as to whether
        we accept or deny your request for an amendment.
      </p>

      <p className={PTagClassName} >
        <span className="font-bold">Right to Receive an Accounting of Disclosures:</span> You have the right to request an
        “accounting” of certain disclosures of your PHI.  The accounting lists instances where we disclosed your PHI and
        to whom that disclosure was made. The accounting does not include disclosures for treatment, payment, and health
        care operations; disclosures made to or authorized by you; and certain other disclosures.  Your request for an
        accounting of disclosures must be made in writing and you may request an accounting for disclosures made up to
        six years before your request.  You may receive one such accounting per year at no charge.  If you request another
        accounting during the same 12 month period, we may charge you a reasonable fee; however, we will notify you of the cost
        involved before processing the accounting.
      </p>

      <p className={PTagClassName} >
        <span className="font-bold">Right to Request a Paper Copy of this Notice:</span> You have a right to request a paper
        copy of this Notice at any time, even if you have agreed to receive the Notice electronically. You may contact us for
        a copy, and one will be provided to you at no charge.
      </p>

      <p className={PTagClassName} >
        <span className="font-bold">Right to Complain:</span> We must follow the privacy practices set forth in this Notice
        while in effect. If you have any questions about this Notice, wish to exercise your rights, or file a complaint, please
        direct your inquiries to <a href="mailto: clinical@genomemedical.com">clinical@genomemedical.com</a> or <a href="mailto: legal@genomemedical.com">legal@genomemedical.com</a> You also have the right to directly complain
        to the Secretary of the United States Department of Health and Human Service. We will not retaliate against you for
        filing a complaint against us.
      </p>


      <h3 className="grey-text" >Rights Reserved by Personal Care</h3>
      <p className={PTagClassName} >
        We reserve the right to revise our privacy practices consistent with law and make them applicable to all of your PHI
        that we maintain, regardless of when it was received or created. If we make material or important changes to our privacy
        practices, we will promptly revise our Notice and make available to you a copy of the revised Notice on our website and
        upon your request. You may request a copy of the Notice currently in effect at any time.
      </p>

      <h3 className="grey-text" >State-Specific Requirements</h3>
      <p className={PTagClassName} >
        When applicable state law imposes more stringent requirements or limitations, including regarding certain sensitive
        medical conditions (e.g., HIV/AIDS, genetic information, and mental health information), we will follow the more stringent
        applicable state law.
      </p>

      <h3 className="grey-text" >Effective Date</h3>
      <p className={PTagClassName} >
        The effective date of this Notice is August 1, 2017
        <br />
        <br />
        This Notice was last updated on January 1, 2023
      </p>
    </article>
  );
};

export default Privacy;
