import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router';
//import Button from '../controls/Button';
//import CommonField from 'components/forms/controls/CommonField';
import ButtonV2 from '../controls/ButtonV2';
import InputField from 'components/forms/controls/V2/InputField';
import ReCaptcha from 'components/forms/controls/ReCaptcha';
import { required, email } from 'services/validate';
import './MustBeRefactored.scss';
import './ForgotPasswordForm.scss';

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit, submitting, valid } = this.props;

    return (
      <form className="forgot-pass-form" onSubmit={handleSubmit} noValidate>
        <div className="label-field required">Email Address</div>
        <div className="input-section">
          <Field
            name="email"
            type="email"
            placeholder="Email Address"
            trim={true}
            component={InputField}
            validate={[required('Email'), email]}
          />

          <Field name="captcha" component={ReCaptcha} bottomError={true} validate={[required()]} />

          <ButtonV2 className="reset-button" type="submit" disabled={submitting || !valid}>
            Reset Password
          </ButtonV2>
          <div className="form-inline-center">
            <Link to="/login" className="back-signin">
              Back to Sign In
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
};

export default reduxForm({
  form: 'ForgotPasswordForm',
})(ForgotPasswordForm);
