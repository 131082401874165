import moment from 'moment';
import _ from 'lodash';
export const hydrateInfo = (data) => ({
  consultationTypes: (data.consultations || []).map((o) => hydrateConsultation(o)),
  servicePackages: (data.service_packages || []).map((o) => hydrateServicePackage(o)),
  purchasedServicePackages: (data.purchased_service_packages || []).map((o) =>
    hydrateServicePackage(o)
  ),
  reasonsForVisit: (data.reasons || []).map((o) => hydrateReason(o)),
  counselors: (data.providers || []).map((o) => hydrateProvider(o)),
});

export const hydrateProvider = (data) => {
  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    fullName: data.full_name,
    title: data.title,
    suffix: data.suffix,
    photoBig: data.photo_big,
    photoSmall: data.photo_small,
    type: data.type,
  };
};

export const hydrateReason = (data) => {
  const { id, name } = data;
  return { id, name };
};

export const hydrateConsultation = (data) => {
  const { id, type, name, duration, description, price } = data;
  return {
    id,
    type,
    name,
    duration,
    description,
    detailedDescription: data.detailed_description,
    price,
    isPredefined: data.is_predefined,
  };
};

export const hydrateServicePackage = (data) => {
  const { id, name, description, alias, price, consultations } = data;
  return {
    id,
    name,
    description,
    alias,
    price,
    consultations: consultations.map((o) => hydrateConsultation(o)),
  };
};

function prepareDate(date) {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

export const dehydrateAvailability = (data) => {
  return _.omitBy(
    {
      start_time: prepareDate(data.start),
      end_time: prepareDate(data.end),
      timezone: data.timezone,
      consultation_id: data.consultationId,
      state: data.state,
      specialty: data.specialty,
      provider_id: data.providerId,
      month: data.month,
      country:data.country
    },
    _.isUndefined
  );
};

export const dehydrateAvailableDates = (data) => {
  return _.omitBy(
    {
      timezone: data.timezone,
      consultation_id: data.consultationId,
      state: data.state,
      specialty: data.specialty,
      provider_id: data.providerId,
      month: data.month,
      date: data.date,
    },
    _.isNil
  );
};

export const hydrateAvailability = (data) => {
  const { available_slots, reservations } = data;
  return {
    availableSlots: available_slots.map((slot) => ({
      start: moment(slot.start),
      end: moment(slot.end),
      providerId: slot.provider_id,
      vsee_specialty: slot.vsee_specialty,
    })),
    reservations: reservations.map((slot) => ({
      id: slot.id,
      start: moment(slot.start_time),
      end: moment(slot.end_time),
      status: slot.status,
      providerId: slot.provider_id,
      vsee_specialty: slot.vsee_specialty,
    })),
  };
};

export const hydrateAvailableTimes = (data) => {
  return {
    availableSlots:
      data &&
      data.map((slot) => ({
        start: moment(slot.start),
        end: moment(slot.end),
        providerId: slot.provider_id,
        duration: slot.duration,
      })),
    reservations: [],
  };
};
