import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router';
import classnames from 'classnames';
import _ from 'lodash';

import { required, email, strongPassword, matchPassword, shouldBeTrue } from 'services/validate';
import Button from 'components/forms/controls/Button';
import PasswordStrength from 'components/forms/PasswordStrength/PasswordStrength';
import warn from 'components/forms/PasswordStrength/warn';
import Alert from 'components/widgets/Alert/Alert';
import { getReferralSource } from 'services/misc/ReferralSource';
import { setRegistrationStep } from 'services/utils';

import './SimpleSignUpForm.scss';

/**
 * This is old version of SignUp form. It was kept for webinar registering.
 * It will be removed in future.
 */

class SimpleSignUpForm extends Component {
  static renderEmailField({ label, input, meta: { touched, error } }) {
    return (
      <div className="gm-create-account-field">
        <div className="gm-create-account-label">{label}</div>
        <div className="gm-create-account-control">
          <input type="text" {...input} />
          <div className="gm-create-account-error">{touched && error}</div>
        </div>
      </div>
    );
  }

  static renderPasswordField({ input, meta: { warning, active, touched } }) {
    const showHint = (active || touched) && _.find(warning, w => w);
    return (
      <div className="gm-create-account-relative-wrapper">
        <div className="gm-create-account-field">
          <div className="gm-create-account-label">Password</div>
          <div className="gm-create-account-control">
            <input type="password" {...input} />
          </div>
        </div>

        <div className="password-hint-container">
          {showHint && <PasswordStrength warning={warning || {}} leftArrow={true} />}
        </div>
      </div>
    );
  }

  static renderPasswordRepeatField({ input, meta: { touched, error } }) {
    delete input['value'];
    return (
      <div className="gm-create-account-field">
        <div className="gm-create-account-label">Repeat password</div>
        <div className="gm-create-account-control">
          <input type="password" {...input} />
          <div className="gm-create-account-error">{touched && error}</div>
        </div>
      </div>
    );
  }

  static renderAgreeField({ input, meta: { touched, error } }) {
    return (
      <div className="gm-create-account-field checkbox-field">
        <div className="checkbox-flex">
          <div className="gm-create-account-control">
            <input type="checkbox" {...input} />
          </div>
          <div className="agree-text">
            I agree to Genome Medical's<br />
            <Link className="gm-link" to="/privacy" target="_blank">
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link className="gm-link" to="/consent" target="_blank">
              Informed Consent
            </Link>
          </div>
        </div>
        <div className="gm-create-account-error">
          {touched && error && 'Please agree to the terms of service'}
        </div>
      </div>
    );
  }

  componentDidMount() {
    setRegistrationStep('account');
  }

  onSubmit = values =>
    this.props.onSubmit({
      ...values,
      referral: this.props.referral || getReferralSource(),
      consentToTreat: 'consented_via_portal',
    });

  render() {
    const { handleSubmit, submitting, formError } = this.props;
    return (
      <section className="simple-signup full-height no-footer">
        <h2 className="sign-up-title">
          Thank you for your interest in our genetic expert services.
        </h2>
        <h3 className="sign-up-desc">
          Let’s start by having you provide an email address and create a password, which will allow
          you to access your account and get help along the way.
        </h3>
        <img
          className="ribbon-img"
          src={require('images/gray-ribbon.png')}
          srcSet={`${require('images/gray-ribbon@2x.png')} 2x`}
        />
        <div className="form-wrapper">
          <h2 className="form-header">SIGN UP</h2>
          <form
            className="gm-create-account-form"
            autoComplete="off"
            onSubmit={handleSubmit(this.onSubmit)}>
            <div className="gm-create-account-fields">
              <Field
                name="email"
                type="email"
                component={SimpleSignUpForm.renderEmailField}
                label="E-mail*"
                validate={[required('E-mail'), email]}
              />
              <Field
                name="password"
                component={SimpleSignUpForm.renderPasswordField}
                validate={strongPassword}
              />
              <Field
                name="passwordRepeat"
                component={SimpleSignUpForm.renderPasswordRepeatField}
                validate={[required('Repeat password')]}
              />
              <Field
                name="agree"
                component={SimpleSignUpForm.renderAgreeField}
                validate={[shouldBeTrue]}
              />
              <Alert message={formError} />
              <div
                className={classnames('gm-create-account-field submit-button', {
                  disabled: submitting,
                })}>
                <div className="gm-create-account-control">
                  <Button type="submit" disabled={submitting}>
                    SIGN UP
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <p className="login-link">
          <Link className="gm-link" to="/">
            Login if you already have an account
          </Link>
        </p>
        <p className="postscriptum">
          * Register with an email account where you feel comfortable receiving personal health
          information
        </p>
      </section>
    );
  }
}

SimpleSignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  referral: PropTypes.string,
};

export default reduxForm({
  form: 'simple-signup-form',
  validate: matchPassword,
  warn,
})(SimpleSignUpForm);
