import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import documentsService from 'services/api/documentsService';
import {
  deleteDocument,
  uploadDocuments,
  loadDocumentTypes,
  resetUploadingErrors,
} from 'ducks/documents';
import DropzoneField from './Dropzone/DropzoneField';
import { findItemByName } from 'services/utils';
import { documentSourcesNames, documentTypesNames } from 'constants/DocumentsConstants';

export const CONNECTED_DROPZONE_ACCEPTED_FILETYPES = `text/plain, application/pdf, image/jpeg, image/png,
  image/bmp, image/tiff, image/gif, application/vnd.ms-works, .doc, .docx, .pdf, .ppt, .xls, .xlsx`;

class ConnectedDropzone extends Component {
  static propTypes = {
    description: PropTypes.string,
    appointmentId: PropTypes.number.isRequired,
    documents: PropTypes.array.isRequired,
    uploadingStatus: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    documentTypes: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    prefix: PropTypes.string,
    accept: PropTypes.string,
  };

  componentDidMount() {
    this.props.dispatch(resetUploadingErrors());
    this.props.dispatch(loadDocumentTypes());
  }

  getFileLink = fileId => {
    return documentsService.downloadDocument(fileId);
  };

  uploadDocuments = filesData => {
    const { appointmentId, description, documentTypes, prefix, dispatch } = this.props;
    const documentType = findItemByName(documentTypes, documentTypesNames.geneticTestResults);
    const documentTypeId = documentType && documentType.id;
    return dispatch(
      uploadDocuments(filesData, {
        appointmentId,
        documentTypeId,
        description,
        prefix,
      })
    );
  };

  deleteDocument = doc => {
    if (doc.id !== undefined) this.props.dispatch(deleteDocument(doc.id));
  };

  isUserAppointmentDocument = doc => {
    const { source } = doc;
    const { appointmentId } = this.props;
    return (
      source.name === documentSourcesNames.patient &&
      doc.appointment &&
      appointmentId === doc.appointment.id
    );
  };

  render() {
    const { documents, uploadingStatus, loading, documentTypes } = this.props;
    if (!documentTypes) return null;
    return (
      <DropzoneField
        accept={CONNECTED_DROPZONE_ACCEPTED_FILETYPES}
        dropzoneOnDrop={this.uploadDocuments}
        multiple={true}
        getFileLink={this.getFileLink}
        extraFiles={documents && documents.filter(d => this.isUserAppointmentDocument(d))}
        onRemove={this.deleteDocument}
        showFilesInProcess={uploadingStatus.uploading}
        uploadingStatus={uploadingStatus}
        loading={loading}
        disableClick={uploadingStatus.uploading}
      />
    );
  }
}

export default connect(({ appointment, documents }) => ({
  appointmentId: appointment.data.id,
  documentTypes: documents.documentTypes,
  documents: documents.data,
  loading: documents.loading,
  uploadingStatus: {
    uploading: documents.uploading,
    uploadingProgress: documents.uploadingProgress,
    uploadingResult: documents.uploadingResult,
    removingFiles: documents.removingFiles,
  },
}))(ConnectedDropzone);
