import _ from 'lodash';
import api from './api';
import { hydrateDocuments, hydrateDocumentTypes } from './transformers/documentsTransformers';

class DocumentsService {
  getDocumentTypes() {
    return api.documents.getDocumentTypes().then(data => hydrateDocumentTypes(data));
  }

  getDocuments() {
    return api.documents.getDocuments().then(data => hydrateDocuments(data));
  }

  getFileFormData(data, name, extraFields) {
    const formData = new FormData();
    formData.append('file', data, name);
    if (extraFields) {
      _(extraFields).forOwn((value, key) => {
        if (key && value) formData.append(key, value);
      });
    }
    return formData;
  }

  uploadDocuments(data, name, params = {}, onUploadProgress) {
    const { appointmentId, documentTypeId, description } = params;
    const extraFileds = {
      appointment_id: appointmentId,
      document_type_id: documentTypeId,
      description: description,
    };
    const fileFormData = this.getFileFormData(data, name, extraFileds);
    return api.documents.uploadDocuments(fileFormData, onUploadProgress);
  }

  deleteDocument(documentId) {
    return api.documents.deleteDocument(documentId);
  }

  getDocumentLink = documentId => {
    return api.documents.getDocumentLink(documentId).then(response => {
      const contentType = response.headers['content-type'];
      return `data:${contentType};base64,${Buffer.from(response.data, 'binary').toString(
        'base64'
      )}`;
    });
  };

  getConsentDocuments() {
    return api.documents.downloadForms().then(data => hydrateDocuments(data));
  }
  submitauditLogs(data) {
    return api.documents.submitauditLogs(data);
  }

  downloadDocument(documentId) {
    return api.documents.downloadDocument(documentId).then(res => {
      const disposition = res.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          let fileName = matches[1].replace(/['"]/g, '');
          const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          return res;
        }
      }
    });
  }
}

export default new DocumentsService();
