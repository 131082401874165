import React, { Component } from 'react';
import _ from 'lodash';

export default function fetchHOC(config = {}) {
  return WrappedComponent => {
    return class FetchComponent extends Component {
      constructor(props) {
        super(props);

        this.state = {};
        _.forEach(config, (resourceConfig, resourceName) => {
          if (typeof resourceConfig === 'function') {
            resourceConfig = { fetch: resourceConfig };
          }

          this.state[resourceName] = {
            loaded: false,
            loading: false,
            initialData: resourceConfig.initialData,
            data: resourceConfig.initialData,
            fetch: this.fetch(resourceName, resourceConfig),
            reset: () => this.setData(resourceName, resourceConfig.initialData),
          };
        });
      }

      setData(resourceName, data) {
        this.setState({
          [resourceName]: {
            ...this.state[resourceName],
            data,
            loaded: true,
            loading: false,
          },
        });
      }

      setLoading(resourceName, loading) {
        this.setState({
          [resourceName]: {
            ...this.state[resourceName],
            loading,
          },
        });
      }

      fetch(resourceName, resourceConfig) {
        return args => {
          this.setLoading(resourceName, true);
          return resourceConfig.fetch(args).then(
            response => {
              return this.setData(resourceName, response);
            },
            () => this.setLoading(resourceName, false)
          );
        };
      }

      render() {
        return <WrappedComponent {...this.props} {...this.state} />;
      }
    };
  };
}
