import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import InputField from 'components/forms/controls/V2/InputField';

import { required, email, validName, phoneOrEmpty } from 'services/validate';
import './LoginForm.scss';
import _ from 'lodash';
import Alert from 'components/widgets/Alert/Alert';
import gaTrack, { GA_TR_SOFTLOGIN_PAGE } from 'services/gaTrack';
import PhoneNumberField from 'components/forms/controls/V2/PhoneNumberField';

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    clearSubmitErrors: PropTypes.func,
  };
  componentDidMount() {
    gaTrack(GA_TR_SOFTLOGIN_PAGE);
  }

  onChangePassword = () => {
    if (_.get(this, ['props', 'submitFailed'], false)) {
      this.props.clearSubmitErrors();
    }
  };

  render() {
    const { handleSubmit, submitting, invalid, formError } = this.props;

    return (
      <form className="soft-login-form" onSubmit={handleSubmit}>
        <div className="label-field required">Email Address</div>
        <div className="input-section">
          <Field
            name="email"
            type="email"
            placeholder="email@email.com"
            trim={true}
            component={InputField}
            validate={[required('Email'), email]}
          />
        </div>

        <div className="label-field required">First Name</div>
        <div className="input-section">
          <Field
            name="firstName"
            type="text"
            placeholder="John"
            trim={true}
            component={InputField}
            validate={[required('First Name'), validName('First Name')]}
          />
        </div>

        <div className="label-field required">Last Name</div>
        <div className="input-section">
          <Field
            name="lastName"
            type="text"
            placeholder="Smith"
            trim={true}
            component={InputField}
            validate={[required('Last Name'), validName('Last Name')]}
          />
        </div>
        <div className="label-field required">Phone Number</div>
        <div className="input-section">
          <Field
            name="phone"
            type="text"
            placeholder="+1 (123) 456 - 7890"
            trim={true}
            component={PhoneNumberField}
            mask="+1 (999) 999 - 9999"
            maskChar={null}
            validate={[phoneOrEmpty, required('Phone Number')]}
          />
        </div>
        <Alert message={formError} />

        <ButtonV2 className="submit-button" type="submit" disabled={invalid || submitting}>
          Sign In
        </ButtonV2>

        <div className="contact-us">
          Do you have any questions?
          <a href="mailto:info@genomemedical.com" className="link">
            Contact Us
          </a>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'soft-login',
})(LoginForm);
