import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import CreateAccountContainer from './CreateAccountContainer';
import api from 'services/api/api';
import eligibilityService from 'services/api/eligibilityService';
import selectionService from 'services/api/selectionService';
import {
  setStage,
  submitAccount,
  verifyEligibility,
  verifySelection,
  submitAuditLogs,
} from 'ducks/registrationWizard';
import { updateHoldLoggedIn } from 'ducks/user';
import { downloadConsentDocuments } from 'ducks/documents';
import commonScheduling, {
  ELIGIBILITY_CHECK,
  CREATE_ACCOUNT,
  SELECTION_DETAILS,
} from 'services/wizardWorkflows';
import EligibilityCheckForm from 'components/forms/WizardForm/EligibilityCheck/EligibilityCheckForm';
import { closeLoader, loaderSession, showLoader } from 'ducks/ui';
import { getRaceEnums, getEthnicityEnums } from 'ducks/enums';
import SelectionForm from 'components/forms/WizardForm/SelectionForm/SelectionForm';
import ABNDownloadModal from 'components/modals/SCP/ABNDownLoadModal/ABNDownloadModal';
import './RegistrationWizardPage.scss';
import {
  setWarmReferralSource,
  clearWarmReferralSource,
  setWarmReferralURL,
  clearWarmReferralURL,
} from 'services/misc/ReferralSource';
import gaTrack, { GA_TR_DOWNLOAD_PAGE } from 'services/gaTrack';
import authService from 'services/api/authService';
import _ from 'lodash';
import { goToSelectService } from '../../../../services/navigation';

class RegistrationWizardPage extends Component {
  static setRegistrationStage(props) {
    const { dispatch, selectedStage, registrationWizard } = props;
    const { activeStage } = registrationWizard;
    if (activeStage !== selectedStage) dispatch(setStage(selectedStage));
  }
  state = {
    downloadModalVisible: false,
    continueHandler: () => {},
  };

  componentDidMount() {
    const { location: { query } } = this.props;
    if (query && query.referral_url) {
      let warm_referral_src = query.referral_url
        .replace('http://', '')
        .replace('https://', '')
        .replace('www.', '')
        .split(/[/?#]/)[0];
      setWarmReferralSource(warm_referral_src);
      setWarmReferralURL(query.referral_url);
    }
    this.checkRedirectAndStage();
  }

  componentDidUpdate() {
    this.checkRedirectAndStage();
  }

  checkRedirect() {
    const { selectedStage, holdLoggedIn } = this.props;
    const preSelected = authService.getPreselectedService();
    const referral = eligibilityService.getReferral();
    const selectionreferral = selectionService.getReferral();
    // Skip create account step for registered users
    if (
      api.hasToken() &&
      (selectedStage === CREATE_ACCOUNT ||
        selectedStage === ELIGIBILITY_CHECK ||
        selectedStage === SELECTION_DETAILS) &&
      !holdLoggedIn
    ) {
      preSelected ? goToSelectService() : this.props.gotoHome();
      return true;
    }

    // Skip eligibility and selection check if partner alias not specified
    if (
      (selectedStage === ELIGIBILITY_CHECK && !referral) ||
      (selectedStage === SELECTION_DETAILS && !selectionreferral)
    ) {
      this.props.gotoAccount();
      return true;
    }

    return false;
  }

  checkRedirectAndStage() {
    if (!this.checkRedirect()) {
      RegistrationWizardPage.setRegistrationStage(this.props);
    }
  }

  submitEligibilityCheck = values => {
    const { dispatch, gotoAccount, gotoLogin } = this.props;
    const referral = eligibilityService.getReferral();
    dispatch(showLoader());
    return verifyEligibility(referral, values, dispatch).then(res => {
      dispatch(closeLoader());
      if (_.get(res, ['action'], '') == 'redirect_to_login') {
        gotoLogin();
        return;
      } else if (_.get(res, ['action']) == 'nothing_to_do') {
        return;
      }
        gotoAccount();
    })
    .catch(()=>{
      dispatch(closeLoader());
    })
  };

  submitSelection = values => {
    const { dispatch, gotoAccount } = this.props;
    const referral = selectionService.getReferral();
    dispatch(showLoader());
    return verifySelection(referral, values, dispatch).then(() => {
      dispatch(closeLoader());
        gotoAccount();
    })
    .catch(err=>{
      dispatch(closeLoader());
      throw err;
    })

  };

  submitAccountInformation = values => {
    const { dispatch, gotoHomeAddress, gotoThankuPage, gotoHome } = this.props;
    const preSelected = authService.getPreselectedService();
    const eligibilityData = eligibilityService.getVerificationData();
    const selectionData = selectionService.getVerificationData();
    if (eligibilityData) {
      const { eligibility_token, additionalData } = eligibilityData;
      values = { ...values, eligibility: { eligibility_token, additionalData } };
    }
    if (selectionData) {
      const { selection_token, additionalData } = selectionData;
      values = { ...values, selection: { selection_token, additionalData } };
    }
    const istokenstored = values.referral && values.referral === 'Genentech' ? false : true;
    const isABNFlow =
      values && values.isMedicareBeneficiary && values.isMedicareBeneficiary === true;
    return loaderSession(
      dispatch,
      submitAccount(values, dispatch, istokenstored, isABNFlow).then(() => {
        const selectionreferral = selectionService.getReferral();
        clearWarmReferralSource();
        clearWarmReferralURL();
        let handler = () => {};
        if (selectionreferral && selectionData) {
          const { additionalData } = selectionData;
          if (additionalData) handler = gotoThankuPage;
          else handler = preSelected ? goToSelectService : gotoHome;
        } else {
          handler = preSelected ? goToSelectService : gotoHome;
        }
        if (istokenstored && isABNFlow) {
          gaTrack(GA_TR_DOWNLOAD_PAGE);
          this.setState({ downloadModalVisible: true, continueHandler: handler });
        } else {
          handler();
        }
      })
    );
  };

  renderTosModal = () => {
    return (
      <ABNDownloadModal
        roleModal={'scp-modal'}
        description={
          <div className="abn-content">
            A copy of your signed ABN document has been generated. You can{' '}
            <span
              onClick={() => {
                this.downloadConsentDocs();
              }}>
              download here{' '}
            </span>or access it through your patient portal.
          </div>
        }
        show={true}
        confirmText="Continue"
        onConfirm={() => {
          this.continueHandler();
        }}
        onHide={() => this.setState({ downloadModalVisible: false })}
      />
    );
  };

  continueHandler = () => {
    const { dispatch } = this.props;
    const values = { reason: 'Continue' };
    const { continueHandler } = this.state;
    return dispatch(submitAuditLogs(values)).then(() => {
      this.setState({ downloadModalVisible: false }, () => {
        dispatch(updateHoldLoggedIn(false));
        continueHandler();
      });
    });
  };

  downloadConsentDocs = () => {
    const { dispatch } = this.props;
    dispatch(downloadConsentDocuments());
  };

  render() {
    const { formError, activeStage } = this.props.registrationWizard;
    const {showMedicareQuestion} = this.props.location.query;
    return (
      <div className="scheduling-page full-height no-footer">
        {activeStage === ELIGIBILITY_CHECK && (
          <EligibilityCheckForm
            partner={eligibilityService.getReferral()}
            onSubmit={this.submitEligibilityCheck}
            formError={formError}
          />
        )}
        {activeStage === CREATE_ACCOUNT && (
          <CreateAccountContainer
            submitAccountInformation={this.submitAccountInformation}
            formError={formError}
            races={this.props.races}
            ethnicities={this.props.ethnicities}
            showMedicareQuestion={showMedicareQuestion}
          />
        )}
        {activeStage === SELECTION_DETAILS && (
          <SelectionForm
            partner={selectionService.getReferral()}
            onSubmit={this.submitSelection}
            formError={formError}
          />
        )}
        {this.state.downloadModalVisible && this.renderTosModal()}
      </div>
    );
  }
}

RegistrationWizardPage.propTypes = {
  registrationWizard: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  gotoAccount: PropTypes.func.isRequired,
  gotoHomeAddress: PropTypes.func.isRequired,
  selectedStage: PropTypes.string,
  loading: PropTypes.bool,
  gotoThankuPage: PropTypes.func.isRequired,
  location: PropTypes.object,
  getRaceEnums: PropTypes.func.isRequired,
  getEthnicityEnums: PropTypes.func.isRequired,
  races: PropTypes.object.isRequired,
  ethnicities: PropTypes.object.isRequired,
};

const mapStateToProps = ({ registrationWizard, user, enums }) => ({
  registrationWizard,
  holdLoggedIn: user.holdLoggedIn,
  races: enums.races,
  ethnicities: enums.ethnicities,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { registrationWizard, holdLoggedIn, races, ethnicities } = stateProps;
  const { dispatch } = dispatchProps;
  const selectedService = ownProps.params.service;
  const location = ownProps.location;
  return {
    dispatch,
    registrationWizard,
    holdLoggedIn,
    races,
    ethnicities,
    selectedService,
    location,
    selectedStage: commonScheduling.find(s => s.path === ownProps.params.step).id,
    gotoAccount: () =>
      browserHistory.push(
        selectedService ? `/scheduling/${selectedService}/account` : '/scheduling/account'
      ),
    gotoHomeAddress: () =>
      browserHistory.push(
        //selectedService ? `/scheduling/${selectedService}/calendar` : '/scheduling/select-service'
        '/scheduling/home-address'
      ),
    gotoHome: () => browserHistory.push('/patient/home'),
    gotoLogin: () => browserHistory.push('/'),
    gotoThankuPage: () => browserHistory.push(`/selection/thankyou`),
    getRaceEnums: () => dispatch(getRaceEnums()),
    getEthnicityEnums: () => dispatch(getEthnicityEnums()),
  };
};

export default connect(mapStateToProps, null, mergeProps)(RegistrationWizardPage);
