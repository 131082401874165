import api from './api';

class AppMetaService {
  getCurrentMeta() {
    return api.appmeta.get().then(res => {
      return res;
    });
  }
}

export default new AppMetaService();
