//React & Redux
import React from 'react';

//UI Libraries
import { Radio } from 'gm-element-react';

//Lodash
import get from 'lodash/get';

//Styles
import './SelectSpecialityRadioGroup.scss';

class SelectSpecialityRadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //bindings
  }
  //functions

  render() {
    const data = get(this, 'props.data', null) || [];
    const currentValue = get(this, 'props.currentValue');
    const onChangeFunc = get(this, 'props.onChangeFunc', null);
    const customStyling = get(this, 'props.customStyling', null) || {};
    const rowVariant = get(this, 'props.rowVariant', false);
    const rowVariantClass = rowVariant ? ' radioButtonRowVariantContainer' : '';
    const containerClass = 'radioButtonGroupContainer' + rowVariantClass;
    const rowVariantRadioClass = rowVariant ? ' rowVariantRadioButton' : '';
    const radioButtonNonBlankClass =
      'singleSelectRadioRow singleSelectModalRow radioButtonRowContainer' + rowVariantRadioClass;
    const rowVariantLabelClass = rowVariant ? ' rowVariantRadioLabel' : '';
    const radioButtonLabelClass = 'singleSelectRadioLabel' + rowVariantLabelClass;
    return (
      <div className={containerClass} style={{ ...customStyling }}>
        {Array.isArray(data) &&
          data.map((el) => {
            const checked = currentValue === el.name;
            return (
              <div onClick={() => onChangeFunc(el.name)}>
                <Radio
                  value={el.name}
                  checked={checked}
                  key={el.name}
                  className={radioButtonNonBlankClass}
                >
                  <div className={radioButtonLabelClass}>
                    <span style={{ color: checked ? '#5B43BD' : 'black' }}>
                      {el.display_name || '--'}
                    </span>
                    <div>{el.description}</div>
                  </div>
                </Radio>
              </div>
            );
          })}
      </div>
    );
  }
}

export default SelectSpecialityRadioGroup;
