import PropTypes from 'prop-types';
import React from 'react';
import './ConsentToTreatment.scss';
import gaTrack, { GA_TR_CONSENT_PAGE } from '../../../../services/gaTrack';
import Consent from 'components/Policy/Consent';

class ConsentToTreatmentPage extends React.Component {
  static propTypes = {
    children: PropTypes.element,
  };

  componentDidMount() {
    gaTrack(GA_TR_CONSENT_PAGE);
  }

  render() {
    return (
      <div className="privacy-container full-height">
        <div className="sheet">
          <section className="consent-header">
            <div className="genome-header-logo">
              <img className="genome-header-img" src={require('images/logo.svg')} />
            </div>
          </section>
          <Consent />
        </div>
      </div>
    );
  }
}

export default ConsentToTreatmentPage;
