import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import 'components/forms/WizardForm/CreateAccountForm/PrivacyPolicyForm.scss';
import Button from 'components/forms/controls/ButtonV2';
import Privacy from 'components/Policy/Privacy';
import Alert from 'components/widgets/Alert/Alert';
import gaTrack, { GA_TR_PRIVACY_PRACTICE_PAGE } from '../../../../services/gaTrack';
class PrivacyPolicyForm extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    gaTrack(GA_TR_PRIVACY_PRACTICE_PAGE);
  }

  render() {
    const { handleSubmit, onSubmit, hideGoBack, formError } = this.props;
    return (
      <section className="privacy-policy-form-container">
        <section className="privacy-wrapper">
          <section className="privacy-header">
            <div className="genome-header-logo">
              <img className="genome-header-img" src={require('images/logo.svg')} />
            </div>
          </section>
          <section className="privacy-body">
            <Privacy />
            <div className="agree-container">
              <p>
                By clicking the <span className="agree-continue">"I Agree and Continue"</span>{' '}
                button below, you confirm you have reviewed and agree to the above{' '}
                <span className="font-medium">Notice of Privacy Practices</span>
              </p>
              <Alert message={formError} />
              <div className="buttons-containers">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="rights-buttons">
                    <Button type="submit" className="agree-button">
                      I Agree and Continue
                    </Button>
                    {!hideGoBack && (
                      <a
                        className="button-back"
                        key="privacy-back"
                        onClick={() => this.props.goBack()}>
                        Go Back
                      </a>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

PrivacyPolicyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
};

export default reduxForm({
  form: 'consentForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PrivacyPolicyForm);
