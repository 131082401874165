import { showLoader, closeLoader } from './ui';
import encountersService from 'services/api/encountersService';
import _ from 'lodash';
import { loadDocuments } from 'ducks/documents';
import { loadLabTest } from './geneticTest';
const GET_CURRENT_SCP_ENCOUNTER_REQUEST = `gm/encounter/GET_CURRENT_SCP_ENCOUNTER_REQUEST`;
const GET_CURRENT_SCP_ENCOUNTER_SUCCESS = `gm/encounter/GET_CURRENT_SCP_ENCOUNTER_SUCCESS`;
const SET_SCP_ENCOUNTERS = `gm/encounter/SET_SCP_ENCOUNTERS`;
const GET_CURRENT_SCP_ENCOUNTER_FAILURE = `gm/encounter/GET_CURRENT_SCP_ENCOUNTER_FAILURE`;
const RESET_CURRENT_SCP_ENCOUNTER = `gm/encounter/RESET_CURRENT_SCP_ENCOUNTER`;

const UPDATE_SCP_ENCOUNTER_REQUEST = 'gm/encounter/UPDATE_SCP_ENCOUNTER_REQUEST';
const UPDATE_SCP_ENCOUNTER_SUCCESS = 'gm/encounter/UPDATE_SCP_ENCOUNTER_SUCCESS';
const UPDATE_SCP_ENCOUNTER_FAILURE = 'gm/encounter/UPDATE_SCP_ENCOUNTER_FAILURE';

const initialState = {
  currentScpEncounter: null,
  currentScpEncounterLoaded: false,
  currentScpEncounterLoading: false,
  formError: null,
  scpEncounters: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CURRENT_SCP_ENCOUNTER_REQUEST:
      return {
        ...state,
        currentScpEncounterLoaded: false,
        currentScpEncounterLoading: true,
        error: null,
        scpEncounters: [],
      };
    case GET_CURRENT_SCP_ENCOUNTER_SUCCESS:
      return {
        ...state,
        currentScpEncounter: action.payload,
        currentScpEncounterLoading: false,
        error: null,
        currentScpEncounterLoaded: true,
        scpEncounters: [],
      };
    case SET_SCP_ENCOUNTERS:
      return {
        ...state,
        scpEncounters: action.payload,
      };
    case GET_CURRENT_SCP_ENCOUNTER_FAILURE:
      return {
        ...state,
        currentScpEncounterLoading: false,
        scpEncounters: [],
      };
    case RESET_CURRENT_SCP_ENCOUNTER:
      return {
        currentScpEncounter: null,
        currentScpEncounterLoaded: false,
        currentScpEncounterLoading: false,
        formError: null,
        scpEncounters: [],
      };
    case UPDATE_SCP_ENCOUNTER_REQUEST:
    case UPDATE_SCP_ENCOUNTER_SUCCESS:
      return {
        ...state,
        formError: null,
      };
    default:
      return state;
  }
}

export function loadCurrentSCPEncounterRequest() {
  return { type: GET_CURRENT_SCP_ENCOUNTER_REQUEST };
}

export function loadCurrentSCPEncounterSuccess(data) {
  return { type: GET_CURRENT_SCP_ENCOUNTER_SUCCESS, payload: data };
}

export function loadCurrentSCPEncounterFailure(data) {
  return { type: GET_CURRENT_SCP_ENCOUNTER_FAILURE, payload: data };
}

export function resetCurrentSCPEncounter() {
  return { type: RESET_CURRENT_SCP_ENCOUNTER };
}

export function setSCPEncounters(data) {
  return { type: SET_SCP_ENCOUNTERS, payload: data };
}

export function updateSCPEncountersRequest() {
  return { type: UPDATE_SCP_ENCOUNTER_REQUEST };
}

export function updateSCPEncountersSuccess() {
  return { type: UPDATE_SCP_ENCOUNTER_SUCCESS };
}

export function updateSCPEncountersFailure(errorMessage) {
  return { type: UPDATE_SCP_ENCOUNTER_FAILURE, payload: errorMessage };
}

export function loadCurrentSCPEncounter() {
  return async (dispatch) => {
    dispatch(loadCurrentSCPEncounterRequest());
    try {
      const data = await encountersService.getEncounters();
      const encounter = _.get(data, '0', null);
      dispatch(loadCurrentSCPEncounterSuccess(_.get(data, '0', null)));
      dispatch(setSCPEncounters(data));
      if (encounter && encounter.labName && encounter.testName) {
        dispatch(showLoader());
        await dispatch(
          loadLabTest({
            labName: encounter?.labName,
            testName: encounter?.testName,
          })
        );
        dispatch(closeLoader());
      }

      return await _.get(data, '0', null);
    } catch (error) {
      dispatch(closeLoader());
      dispatch(loadCurrentSCPEncounterFailure(error));
    }
  };
}

export function updateSCPEncounters(id, values, dispatch) {
  dispatch(showLoader());
  dispatch(updateSCPEncountersRequest());
  return encountersService
    .updateEncounter(id, values)
    .then(() => {
      dispatch(updateSCPEncountersSuccess());
      /* setting up delay as BE is not updated with latest change due to ETL. */
      setTimeout(() => {
        dispatch(loadCurrentSCPEncounter());
        return dispatch(closeLoader());
      }, 2000);
    })
    .catch((error) => {
      dispatch(updateSCPEncountersFailure(error.fullMessage));
      dispatch(closeLoader());
      if (error.submissionError) throw error.submissionError;
      throw error;
    });
}

export function createSCPEncounter() {
  return (dispatch) => {
    return encountersService.createEncounter().then(() => {
      dispatch(loadDocuments());
      return dispatch(loadCurrentSCPEncounter());
    });
  };
}
