import _ from 'lodash';
import api from 'services/api/api';
import Context from '../Context';
import RedirectAction from './RedirectAction';
import { webinarPartnersReferrals } from 'services/misc/ReferralSource';
import {
  isWebinar,
  hasAppointment,
  isPartnerWebinarAvailable,
} from 'containers/layouts/LayoutProps';
import { REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';

export default class RedirectAfterLogin extends RedirectAction {
  constructor() {
    super(null, null);

    this.isLogged = api.hasToken();
    this.outsideRedirectTo = null;
    this.hasDroppedWebinar = (state) => {
      const { referral, confirmed } = state.user.me;
      return (
        referral &&
        !confirmed &&
        !hasAppointment(state) &&
        webinarPartnersReferrals.includes(referral)
      );
    };

    this.holdLoggedIn = (state) => {
      const { holdLoggedIn } = state.user;
      return holdLoggedIn;
    };
  }

  setOutsideRedirect(context: Context) {
    const { redirectActions } = context.getState();
    this.outsideRedirectTo = redirectActions.actions[REDIRECT_AFTER_LOGIN];
  }

  execute(context: Context = null): void {
    const { state } = context.getParams();
    const { me } = state.user;
    const { referral, hasPaymentMethod } = me;

    const partner = referral && referral.toLowerCase();

    /**
     * User will be redirected to proper registration step inside WebinarRegistrationWizard component
     */
    if (this.hasDroppedWebinar(state)) {
      /**
       * User was going to register the webinar but still not complete the registration.
       * Let him continue the registration.
       */
      isPartnerWebinarAvailable(partner).then((enabled) => {
        this.to =
          enabled && hasPaymentMethod ? `/webinar/${partner}/signup/personal` : `/${partner}`;
      });
    } else if (me && isWebinar(state)) {
      this.to = '/webinar';
    } else if (this.outsideRedirectTo) {
      this.to = this.outsideRedirectTo;
    } else {
      this.to = '/patient/home';
    }

    if (this.to) {
      this.isLogged = true;
      super.execute();
    }
  }

  canExecute(context: Context = null): boolean {
    const { state, router } = context.getParams();
    const { me } = state.user;
    const { email } = state.registrationWizard.clientData;
    const astrikeScopeAvailable = api.hasTokenIncludeScope('*');
    // User already logged or not signed yet
    if (!astrikeScopeAvailable || this.isLogged || _.isEmpty(me) || !_.isNil(email)) return false;

    if (this.holdLoggedIn(state)) return false;

    const { referral } = state.user.me;
    const partner = referral && referral.toLowerCase();

    this.setOutsideRedirect(context);

    return !(
      this.hasDroppedWebinar(state) && router.location.pathname.startsWith(`/webinar/${partner}`)
    );
  }
}
