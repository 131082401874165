import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Tooltip } from 'gm-element-react';

import './CheckBoxField.scss';

const CheckBoxField = ({
  input: { name, value, onChange },
  extraclassName,
  label,
  disabled,
  description,
}) => {
  const currentClassName = classnames('checkbox-wrapper', 'input-field', extraclassName);
  return (
    <Fragment>
      <div className={currentClassName}>
        <label className="guardian-label">
          <input
            type="checkbox"
            name={name}
            disabled={disabled}
            checked={value}
            onChange={onChange}
          />
          {label}
        </label>
        {description && (
          <div className="checkbox-description">
            <Tooltip className="checkbox-tooltip" content={description} placement="right">
              <div className="info-outline-icon" />
            </Tooltip>
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default CheckBoxField;
