import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Select from 'react-select';

class ReasonForVisitSelect extends Component {
  getOptions = () => {
    return [
      { value: 'cancer', label: 'Cancer Genetics' },
      { value: 'cardiac', label: 'Cardiovascular Genetics' },
      { value: 'reproductive', label: 'Reproductive Genetics' },
      { value: 'proactive', label: 'Proactive Health' },
      { value: 'other', label: 'Other' }, // this value is hardcoded in validator. DO NOT change
    ];
  };

  render() {
    const { label, disabled, input: { value, onChange }, meta: { touched, error } } = this.props;
    return (
      <div
        className={classnames('gm-form-field', this.props.className, { error: touched && error })}>
        <label className="gm-form-label">{(touched && error) || label}</label>
        <div className="gm-form-control">
          {value && value.value === 'other' ? (
            <Select.Creatable
              ref={element => (this.selectElement = element)}
              value={null}
              options={this.getOptions(value)}
              onChange={onChange}
              placeholder="Please provide details about how we can help you"
              clearable={true}
              searchable={true}
              promptTextCreator={label => label}
              disabled={disabled}
            />
          ) : (
            <Select
              value={value}
              options={this.getOptions()}
              onChange={onChange}
              placeholder="Select one"
              clearable={false}
              searchable={false}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    );
  }
}

ReasonForVisitSelect.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ReasonForVisitSelect;
