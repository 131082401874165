import PropTypes from 'prop-types';
import React, { Component } from 'react';
import gaTrack, { GA_TR_SCHEDULING_PAYMENT_REVIEW_PAGE } from '../../../../../services/gaTrack';
import CouponApplierForm from './ViewOnlyCouponApplierForm/ViewOnlyCouponApplierForm';
import { Input, Button } from 'gm-element-react';
import './ReviewPaymentSummaryForm.scss';

export const PAYMENT_FORM_NAME = 'scp-payment-information-form';

class ReviewPaymentSummaryForm extends Component {
  componentDidMount() {
    gaTrack(GA_TR_SCHEDULING_PAYMENT_REVIEW_PAGE);
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, goNext } = this.props;

    if (loading) return null;

    return (
      <div className="scp-schedule-wizard scp-payment-review-stage">
        <h2 className="gm-select-service_header">Review Payment Summary</h2>
        <div
          className="scp-payment-personal-form"
        >
          <div className="coupon-section">
            <CouponApplierForm {...this.props} />
          </div>
          <div className="action-buttons">
          {true && (
            <button
              onClick={this.props.toPreviousStep}
              disabled={this.props.isLoading}
              className="btn-back">
              Back
            </button>
          )}
          <button
            onClick={goNext}
            className="btn-next">
            {'Next'}
          </button>
        </div>
        </div>
      </div>
    );
  }
}

export default ReviewPaymentSummaryForm;
