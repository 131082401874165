import _ from 'lodash';
import moment from 'moment-timezone';

import { shuffle } from 'services/utils';
import { getDateFromStr } from 'services/api/transformers/transformersUtils';
import { MAX_HOUR, MIN_HOUR } from './Calendar';

export const EMPTY = 'EMPTY';

const forbiddenTimeSpan = process.env.GM_ENV === 'production' ? 8 * 60 * 60000 : 8 * 60 * 60000; // allow schedule appointments after 24 hours from the current time

/**
 * returns new date with updated time
 */
function updateDate(date, hours = 0, minutes = 0, seconds = 0, ms = 0) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, ms);
}

/**
 * this function splits events if "start" and "end" are on the different days
 */
function splitEvents(o) {
  if (o.start.getDay() !== o.end.getDay()) {
    const newSlot = _.clone(o);
    o.end = updateDate(o.start, 23, 59, 59, 999);
    newSlot.start = updateDate(newSlot.end);
    return [o, newSlot];
  }
  return o;
}

/**
 * corrects start and end dates to fit MIN_HOUR and MAX_HOUR settings
 */
function normalizeTime(slot) {
  if (
    slot.start.getHours() >= MAX_HOUR ||
    slot.end.getHours() < MIN_HOUR ||
    (slot.end.getHours() === MIN_HOUR && slot.end.getMinutes() === 0)
  )
    return null;
  if (slot.start.getHours() < MIN_HOUR) slot.start = updateDate(slot.start, MIN_HOUR);
  if (
    slot.end.getHours() > MAX_HOUR ||
    (slot.end.getHours() === MAX_HOUR && slot.end.getMinutes() > 0)
  )
    slot.end = updateDate(slot.end, MAX_HOUR);
  //console.log(slot.start + '  -  ' + slot.end);
  return slot;
}

/**
 * filter out events that are finished BEFORE the current time plus forbidden interval
 * @param {*} currentTime
 * @param {*} o
 */
function filterOutOfDateEvents(currentTime, o) {
  return +o.realEnd >= currentTime + forbiddenTimeSpan;
}

function fixStartTimeOfClosestEvent(currentTime, o) {
  if (o.realStart > currentTime && o.realStart < currentTime + forbiddenTimeSpan) {
    const delta = o.realStart - +o.start;
    let newStart = new Date(currentTime - delta + forbiddenTimeSpan);
    o.start = updateDate(
      newStart,
      newStart.getHours() + (newStart.getMinutes() >= 30 ? 1 : 0),
      newStart.getMinutes() < 30 ? 30 : 0
    );
  }
  return o;
}

function flatten(array) {
  return [].concat.apply([], array);
}

function removeReservations(slots, reservations) {
  //TODO: this function should be rewritten to support reservations with any duration
  const slotsByDay = _.groupBy(slots, (s) => +updateDate(s.start));
  reservations.forEach((r) => {
    const rStart = +r.start;
    const dayStart = +updateDate(r.start);
    const dayEnd = +updateDate(r.end);
    _({
      [dayStart]: slotsByDay[dayStart],
      [dayEnd]: slotsByDay[dayEnd],
    }).forIn((slots, day) => {
      if (slots)
        slotsByDay[day] = flatten(
          slots.map((s) => {
            //if (s.sid === r.providerId) {
            if (+s.start === rStart) {
              s.start.setMinutes(s.start.getMinutes() + s.duration);
            } else if (rStart + s.duration * 60000 === +s.end) {
              s.end.setMinutes(s.end.getMinutes() - s.duration);
            }
            // }
            return s;
          })
        );
    });
  });
  return flatten(Object.values(slotsByDay));
}

export function CreateEmptySlotEvents({ eventsList, reservations, onSlotClick, startDate }) {
  const currentTime = +new Date();
  // in this flow THERE ARE mutations. they're deliberate
  const filteredEvents = _(eventsList)
    .map((o) => {
      const start = getDateFromStr(o.start);
      const end = getDateFromStr(o.end);
      // dummyTzOffset is an offset between real user timezone and calendar selected timezone
      const dummyTzOffset = start - moment(o.start).valueOf();
      return {
        dummyTzOffset: dummyTzOffset,
        start, // JS Date
        end, // JS Date
        sid: o,
        duration: o.duration,
        onSlotClick,
        type: EMPTY,
        vsee_specialty: o.vsee_specialty,
        get realStart() {
          return this.start - this.dummyTzOffset;
        },
        get realEnd() {
          return this.end - this.dummyTzOffset;
        },
      };
    })
    .map((o) => splitEvents(o)) // split event if start and end dates have different days
    .flatten()
    .filter((o) => filterOutOfDateEvents(currentTime, o))
    .map((o) => fixStartTimeOfClosestEvent(currentTime, o))
    .map((o) => normalizeTime(o)) // fix time to fit MIN_HOUR and MAX_HOUR
    .filter((o) => o && o.end - o.start >= o.duration * 60000)
    .value(); // remove time that is less than duration
  //.map(o => log(o));
  // events captured bye other clients. they can be "reserved" or "booked"
  const reservedEvents = reservations.map((o) => ({
    start: getDateFromStr(o.start),
    end: getDateFromStr(o.end),
    providerId: o.providerId,
  }));
  const pureEvents = removeReservations(filteredEvents, reservedEvents);

  // shuffle the array to give more chances for random providers choice if they're overlap
  // however it's still not 100% guaranteed
  shuffle(pureEvents);

  return pureEvents;
}
