import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Loading, Tooltip } from 'gm-element-react';
import './MyHealthPage.scss';
import maliciousIcon from './malicious.png';
import downloadIcon from 'images/common/download-purple.svg';
import deleteIcon from 'images/common/delete-red.svg';
import pdfIcon from 'images/common/pdf-red.svg';
import docIcon from 'images/common/doc.svg';
import excelIcon from 'images/common/excel.svg';
import imgIcon from 'images/common/img.svg';
import otherIcon from 'images/common/other.svg';
import './DocumentCard.scss';

class DocumentCard extends Component {
  state = {};

  downloadDocument(id) {
    this.props.downloadDocument(id);
  }

  deleteDocument(id) {
    this.props.onDelete(id);
  }

  renderFileIcon(extension) {
    const iconMapper = {
      '(gif|jpe?g|tiff?|png|webp|bmp|img)$': imgIcon,
      '(pdf)$': pdfIcon,
      '(doc)$': docIcon,
      '(excel|xls|xlsx)$': excelIcon,
    };
    let icon = otherIcon;

    for (const pattern in iconMapper) {
      const regex = new RegExp(pattern, 'i');
      if (regex.test(extension)) {
        icon = iconMapper[pattern];
        break;
      }
    }

    return <img src={icon} className="doc-icon" alt={`${extension} icon`} />;
  }

  renderTextWithTooltip = (text, length = 40) => {
    if (!text) {
      return '--';
    }
    if (text.length <= length) {
      return text;
    }
    return (
      <Tooltip
        className="descriptionTooltip"
        placement="top"
        content={text}
        effect="dark">{`${text.substring(0, length)}...`}</Tooltip>
    );
  };

  docSubtypeDisplayName = () => {
    const doc = _.get(this, 'props.document', null) || {};
    const docType = _.get(doc, 'type.name', null) || '--';
    const docSubtype = _.get(doc, 'subType', null);
    let result = docSubtype;
    if (docType === 'Visit documentation') {
      if (docSubtype == 'Results Summary') result = 'Clinical Action Plan - Results';
      else if (docSubtype == 'Visit Summary') result = 'Clinical Action Plan - Visit';
    }
    return result;
  };

  render() {
    const variant = _.get(this, 'props.variant', null) || 'gmDoc';
    const isGMTypeDoc = new Set(['gmDoc']).has(variant);
    const doc = _.get(this, 'props.document', null) || {};
    const docType = _.get(doc, 'type.name', null) || '--';
    const docSubtype = _.get(doc, 'subType', null);
    const docSubtypeDisplayName = this.docSubtypeDisplayName();
    const docTitle = (!_.isNil(docSubtype) ? docSubtypeDisplayName : docType) || '--';
    const docProvider = _.get(doc, 'relatedEncounter.provider', null) || '--';

    const docDate = _.get(doc, 'createdAt', null);
    const docCreatedDateFormatted = moment(docDate).isValid()
      ? moment(docDate).format('MMM D, YYYY')
      : '--';
    const docDateOfService = _.get(doc, 'relatedEncounter.DOS', null);
    const docDateOfServiceFormatted = moment(docDateOfService).isValid()
      ? moment(docDateOfService).format('MMM D, YYYY')
      : '--';
    const isArchived = _.get(doc, 'isArchived', null);

    const docIsMalicious = _.get(doc, 'isMalicious', false);
    const checkingIsDocMalicous = _.isNull(docIsMalicious);
    const tooltipMessage = docIsMalicious
      ? 'This file contains a virus and is blocked'
      : checkingIsDocMalicous ? 'Scanning for viruses' : isArchived ? 'Document is archived' : null;
    let containerClass = 'document-card';
    const docId = doc.id;
    const docDescription = doc.description;
    const docUploadeddate = moment(docDate).isValid()
      ? moment(docDate).format('MMM D, YYYY')
      : '--';
    const docFileExtension = _.get(doc, 'fileExtension', null);
    if (docIsMalicious) containerClass += ' document-card-malicious';
    if (isArchived) containerClass += ' document-card-archived';

    const isAdministrativeType = _.get(doc, ['type', 'name'], '') == 'Administrative misc';
    const isRelatedEncounter = !_.isNil(doc.relatedEncounter);
    const isOtherDoc = isAdministrativeType && !isRelatedEncounter;

    return (
      <section className={containerClass}>
        <div className="document-card-row doc-type-title">
          {this.renderFileIcon(docFileExtension, docIsMalicious)}
          <div className="doc-type">
            <Tooltip
              placement="top"
              content={tooltipMessage}
              effect="dark"
              disabled={!tooltipMessage}>
              {docTitle}
            </Tooltip>
          </div>
          {docIsMalicious && (
            <img src={maliciousIcon} className="malicious-icon" alt="malicious icon" />
          )}
          {checkingIsDocMalicous && <Loading loading={true} className="document-card-loader" />}
        </div>
        {isGMTypeDoc ? (
          !isOtherDoc ? (
            <Fragment>
              <div className="document-card-row">
                <div className="doc-column-container">
                  <span className="col-label">Created On</span>
                  <span className="col-value">{docCreatedDateFormatted}</span>
                </div>
                {isRelatedEncounter && (
                  <div className="doc-column-container">
                    <span className="col-label">Service Date</span>
                    <span className="col-value">{docDateOfServiceFormatted}</span>
                  </div>
                )}
                {isRelatedEncounter && (
                  <div className="doc-column-container">
                    <span className="col-label">Provider</span>
                    <span className="col-value">{docProvider}</span>
                  </div>
                )}

                {docDescription &&
                  isAdministrativeType && (
                    <div className="doc-column-container">
                      <div className="col-label">Description</div>
                      <div className="col-value">{this.renderTextWithTooltip(docDescription)}</div>
                    </div>
                  )}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="document-card-row display-block">
                <div className="doc-column-container">
                  <span className="col-label">Created On</span>
                  <span className="col-value">{docCreatedDateFormatted}</span>
                </div>
                {docDescription && (
                  <div className="doc-column-container">
                    <div className="col-label">Description</div>
                    <div className="col-value">{this.renderTextWithTooltip(docDescription)}</div>
                  </div>
                )}
              </div>
            </Fragment>
          )
        ) : (
          <div className="document-card-row flex-column display-block">
            <div className="doc-column-container upload-doc-col">
              <span className="col-label">Uploaded On</span>
              <span className="col-value">{docUploadeddate}</span>
            </div>
            <div className="doc-column-container">
              <div className="col-label">Description</div>
              <div className="col-value">{this.renderTextWithTooltip(docDescription)}</div>
            </div>
          </div>
        )}
        {!docIsMalicious && (
          <div className="document-card-row">
            <span onClick={() => this.downloadDocument(docId)} className="download-container">
              <img src={downloadIcon} className="download-icon" />
              <span className="download-text">Download</span>
            </span>
            {!isGMTypeDoc && (
              <span onClick={(e) => checkingIsDocMalicous ? e.preventDefault() : this.deleteDocument(docId)} className={`delete-container ${checkingIsDocMalicous ? 'is-disabled':''}`}>
                <img src={deleteIcon} className="delete-icon" />
                <span className="delete-text">Delete</span>
              </span>
            )}
          </div>
        )}
      </section>
    );
  }
}

DocumentCard.propTypes = {
  document: PropTypes.object.isRequired,
  downloadDocument: PropTypes.func,
};

export default DocumentCard;
