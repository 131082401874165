import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import Header from 'components/header/Header';
import { REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';

export class PublicLayout extends Component {
  static propTypes = {
    children: PropTypes.element,
    redirectActions: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.object,
  };

  componentDidUpdate() {
    const { redirectActions: { actions } } = this.props;
    if (this.context.router.params.activationToken) return;
    if (actions[REDIRECT_AFTER_LOGIN] && !this.redirected) this.redirectAfterSignIn();
  }

  redirected = false;

  redirectAfterSignIn = () => {
    this.redirected = true;
    browserHistory.push({
      pathname: '/login',
      state: { query: this.context.router.location.query },
    });
  };

  render() {
    const { children } = this.props;
    return (
      <div className="main-container">
        <Header signInClick={this.redirectAfterSignIn} currentPage={window.location.pathname} />
        {children}
        <GlobalLoader />
      </div>
    );
  }
}

PublicLayout.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(state => ({
  redirectActions: state.redirectActions,
}))(PublicLayout);
