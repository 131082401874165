import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import './ProgressTrack.scss';

class ProgressTrack extends Component {
  render() {
    const { steps } = this.props;

    return (
      <div className="scp-progress-track">
        <div className="progress-step fake" />
        {steps.map(step => (
          <div
            key={step.id}
            className={classnames('progress-step', {
              done: step.done,
              active: step.active,
            })}>
            <div className="step-circle" />
            <div className="step-title">{step.title}</div>
            <div className="step-line" />
          </div>
        ))}
      </div>
    );
  }
}

ProgressTrack.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProgressTrack;
