import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, setSubmitFailed, stopSubmit } from 'redux-form';
import SelectField from 'components/forms/controls/V2/SelectField';
import InputField from 'components/forms/controls/V2/InputField';
import RadioButtonGroup from 'components/forms/controls/V2/RadioButtonGroup';

import { required, dateIsPast } from 'services/validate';
import './LabInsuranceForm.scss';
import classnames from 'classnames';
import InputMaskField from '../../controls/V2/InputMaskField';
import CheckBoxField from '../../controls/V2/CheckBoxField';

export const LAB_INSURANCE_FORM_NAME = 'lab-insurance-form';

const biologicalSexVariants = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

class LabInsuranceForm extends Component {
  static RelationVariants = [
    { label: 'Self', value: true },
    { label: 'Dependent', value: false },
  ];

  static DependantVariants = [
    { label: 'Spouse or Partner', value: 'spouse' },
    { label: 'Child', value: 'child' },
    { label: 'Parent', value: 'parent' },
    { label: 'Other', value: 'other' },
  ];

  state = {
    initialValues: {},
  };

  componentDidMount() {
    const { initialValues, errors, dispatch } = this.props;
    this.setState({
      initialValues,
    });

    if (errors && errors.insurance) {
      dispatch(stopSubmit(LAB_INSURANCE_FORM_NAME, errors.insurance));
      dispatch(setSubmitFailed(LAB_INSURANCE_FORM_NAME, Object.keys(errors.insurance)));
    }
  }

  handleChange = (value, key) => {
    let refinitialValues = _.cloneDeep(this.state.initialValues);
    refinitialValues[key] = value;
    this.setState({ initialValues: refinitialValues });
  };

  showDependentField = () => {
    if (!_.isEmpty(this.state.initialValues) && !_.isNil(this.state.initialValues.isPolicyholder)) {
      return !this.state.initialValues.isPolicyholder;
    }
  };

  handleFormSubmit = (data) => {
    const req = {
      ...data,
      insuranceType: 'primary',
      visitInsurance: false,
      labInsurance: true,
    };
    this.props.onSubmit(req);
  };

  renderDependentFields() {
    const insuranceIsSelfPay = this.insuranceIsSelfPay();
    const labelRequiredClassName = !insuranceIsSelfPay ? 'label-section required' : 'label-section';
    return (
      <Fragment>
        <div className="form-row">
          <div className="form-col">
            <div className={labelRequiredClassName}>Your Relationship To Policyholder</div>
            <Field
              name="policyholderRelationship"
              component={SelectField}
              className="narrow-field"
              placeholder="Select"
              options={(LabInsuranceForm.DependantVariants || []).map((obj) => {
                return {
                  label: obj.label,
                  value: obj.value,
                  key: obj.value,
                };
              })}
              validate={!insuranceIsSelfPay ? [required('Relationship to policyholder')] : []}
              onChange={(e, value) => this.handleChange(value, 'policyholderRelationship')}
              disabled={insuranceIsSelfPay}
            />
          </div>
          <div className="form-col">
            <div className="label-section">Policyholder’s Date of Birth</div>
            <Field
              name="policyholderDob"
              type="text"
              placeholder="MM/DD/YYYY"
              component={InputMaskField}
              onChange={(e, value) => this.handleChange(value, 'policyholderDob')}
              mask="99/99/9999"
              maskChar={null}
              validate={[dateIsPast('Policyholder’s Date of Birth is invalid')]}
              disabled={insuranceIsSelfPay}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-col">
            <div className={labelRequiredClassName}>Policyholder’s First Name</div>
            <Field
              name="policyholderFirstName"
              type="text"
              placeholder="John"
              component={InputField}
              validate={!insuranceIsSelfPay ? [required('First name')] : []}
              onChange={(e, value) => this.handleChange(value, 'policyholderFirstName')}
              disabled={insuranceIsSelfPay}
            />
          </div>
          <div className="form-col">
            <div className={labelRequiredClassName}>Policyholder’s Last Name</div>
            <Field
              name="policyholderLastName"
              type="text"
              placeholder="Doe"
              component={InputField}
              validate={!insuranceIsSelfPay ? [required('Last name')] : []}
              onChange={(e, value) => this.handleChange(value, 'policyholderLastName')}
              disabled={insuranceIsSelfPay}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  insuranceIsSelfPay = () => {
    const {
      initialValues: { isSelfPay },
    } = this.state;
    let _isSelfPay = this.props.initialValues?.isSelfPay;
    if (typeof isSelfPay == 'boolean') {
      _isSelfPay = isSelfPay;
    }
    return !!_isSelfPay;
  };

  render() {
    const { submitting, toPreviousStep, handleSubmit, valid, onSubmit } = this.props;
    const insuranceIsSelfPay = this.insuranceIsSelfPay();
    const labelRequiredClassName = !insuranceIsSelfPay ? 'label-section required' : 'label-section';
    const disbaledClass = insuranceIsSelfPay ? 'disabled' : '';
    return (
      <div className="scp-schedule-wizard">
        <h2 className="gm-select-service__header">Enter Insurance Information</h2>
        <form className="insurance-form" onSubmit={handleSubmit(this.handleFormSubmit)}>
          <>
            <div className="scp-insurance-fields">
              <div className="insurance-group">
                <div className="scp-insurance-sections">
                  <div className="scp-insurance-section insurance-name-section">
                    <div className={labelRequiredClassName}>
                      <span>Insurance Name</span>
                    </div>
                    <div className="form-row insurance-form-form">
                      <Field
                        name="companyName"
                        component={InputField}
                        label="Insurance Name"
                        placeholder="Select insurance"
                        validate={!insuranceIsSelfPay ? [required('Insurance')] : []}
                        onChange={(e, value) => {
                          this.handleChange(value, 'companyName');
                        }}
                        disabled={insuranceIsSelfPay}
                      />
                    </div>
                    <Field
                      name="isSelfPay"
                      type="checkbox"
                      extraclassName="self-pay-checkbox"
                      label={
                        <Fragment>
                          <span className="checkmark" />
                          <div className="label-section">Don't bill insurance</div>
                        </Fragment>
                      }
                      component={CheckBoxField}
                      onChange={(e, value) => {
                        this.handleChange(!!value, 'isSelfPay');
                      }}
                    />
                    <div className={labelRequiredClassName}>
                      <span>Insurance ID #</span>
                    </div>
                    <div className="form-row">
                      <Field
                        name="insuranceNo"
                        type="text"
                        placeholder="1234-567890"
                        component={InputField}
                        validate={!insuranceIsSelfPay ? [required('Insurance ID')] : []}
                        onChange={(e, value) => this.handleChange(value, 'insuranceNo')}
                        disabled={insuranceIsSelfPay}
                      />
                    </div>
                    <div className={labelRequiredClassName}>
                      <span>Group #</span>
                    </div>
                    <div className="form-row">
                      <Field
                        name="groupNo"
                        type="text"
                        placeholder="1234-567890"
                        component={InputField}
                        validate={!insuranceIsSelfPay ? [required('Group number')] : []}
                        onChange={(e, value) => this.handleChange(value, 'groupNo')}
                        disabled={insuranceIsSelfPay}
                      />
                    </div>

                    <div className={`${labelRequiredClassName} ${disbaledClass}`}>
                      <span>Are you the primary policyholder or a dependent?</span>
                    </div>
                    <div className={`form-row ${disbaledClass}`}>
                      <Field
                        name="isPolicyholder"
                        className={classnames('insurance-form-radiogroup fz15px')}
                        component={RadioButtonGroup}
                        children={LabInsuranceForm.RelationVariants}
                        validate={
                          !insuranceIsSelfPay ? [required('Policyholder or dependent')] : []
                        }
                        onChange={(e, value) => this.handleChange(value, 'isPolicyholder')}
                        disabled={insuranceIsSelfPay}
                      />
                    </div>

                    <div className={labelRequiredClassName}>
                      <span>Policyholder’s Biological Sex</span>
                    </div>
                    <div className="form-row">
                      <Field
                        name="policyholderBiolocialSex"
                        component={SelectField}
                        className={classnames('narrow-field')}
                        placeholder="Select"
                        options={(biologicalSexVariants || []).map((obj) => {
                          return {
                            label: obj.label,
                            value: obj.value,
                            key: obj.value,
                          };
                        })}
                        validate={!insuranceIsSelfPay ? [required('Biological sex')] : []}
                        onChange={(e, value) =>
                          this.handleChange(value, 'policyholderBiolocialSex')
                        }
                        disabled={insuranceIsSelfPay}
                      />
                    </div>
                    {this.showDependentField() && this.renderDependentFields()}
                  </div>
                </div>
              </div>
              <div className="lab-insurance-description">
                Natera will use this information to bill your insurance carrier for genetic testing.
                Select "Don't bill insurance" if you wish to self pay. We will collect a $15 Test
                Authorization Fee separately.
              </div>
            </div>
          </>

          <div className="action-buttons">
            {toPreviousStep && (
              <button onClick={toPreviousStep} className="btn-back">
                Back
              </button>
            )}
            <button disabled={submitting || !valid} type="submit" className="btn-next">
              Next
            </button>
          </div>
        </form>
      </div>
    );
  }
}

LabInsuranceForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default reduxForm({
  form: LAB_INSURANCE_FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(LabInsuranceForm);
