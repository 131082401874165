import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from 'components/forms/controls/Button';
import AppointmentInfoWithYellowHint from './AppointmentInfoWithYellowHint';

class MeetWithYourGeneticCounselor extends Component {
  constructor(props) {
    super(props);
    const minutesToStart =
      this.props.appointment.mStartTime &&
      this.props.appointment.mStartTime.diff(moment(), 'minute') + 1;
    this.state = { minutesToStart: minutesToStart };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const minutesToStart =
        this.props.appointment.mStartTime &&
        this.props.appointment.mStartTime.diff(moment(), 'minute') + 1;
      this.setState({ minutesToStart: minutesToStart });
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getTimeBeforeStart() {
    const minutes = this.state.minutesToStart;
    return moment.duration(minutes, 'minutes').humanize(true);
  }

  render() {
    const {
      appointment,
      isMobile,
      gotoAppointmentInfoForMobile,
      gotoVideoInstructionForMobile,
      showAppointmentInfoForMobile,
      showVideoInstructionForMobile,
    } = this.props;
    const appointmentType = appointment.byPhone ? 'Phone ' : 'Video';

    return (
      <div className="my-guide-step">
        {!showVideoInstructionForMobile && (
          <h3>
            {this.state.minutesToStart > 0
              ? `Your ${appointmentType} appointment starts ${this.getTimeBeforeStart()}`
              : `Your ${appointmentType} appointment is in progress...`}
          </h3>
        )}
        {!showVideoInstructionForMobile &&
          !appointment.byPhone && (
            <div>
              <Button
                className="cta"
                href={`/appointments/${appointment.id}/waiting-room`}
                target="_blank">
                Start your appointment
              </Button>

              <p>
                If you have problems entering your appointment, please call{' '}
                <strong>
                  <a href="tel:+18776880992">(877) 688-0992</a>
                </strong>
              </p>
            </div>
          )}
        <AppointmentInfoWithYellowHint
          showTitle={false}
          isMobile={isMobile}
          gotoAppointmentInfoForMobile={gotoAppointmentInfoForMobile}
          gotoVideoInstructionForMobile={gotoVideoInstructionForMobile}
          showAppointmentInfoForMobile={showAppointmentInfoForMobile}
          showVideoInstructionForMobile={showVideoInstructionForMobile}
        />
      </div>
    );
  }
}

MeetWithYourGeneticCounselor.propTypes = {
  appointment: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  gotoAppointmentInfoForMobile: PropTypes.func,
  gotoVideoInstructionForMobile: PropTypes.func,
  showAppointmentInfoForMobile: PropTypes.bool,
  showVideoInstructionForMobile: PropTypes.bool,
};

const mapStateToProps = state => ({
  appointment: state.appointment.data,
});

export default connect(mapStateToProps)(MeetWithYourGeneticCounselor);
