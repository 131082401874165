import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import { GetTimeZoneById } from 'services/geo';

class WebinarSummary extends Component {
  static propTypes = {
    className: PropTypes.string,
    webinar: PropTypes.object.isRequired,
  };

  getFormattedStartsAt() {
    const { startsAt, timezone } = this.props.webinar;
    return (
      moment.tz(startsAt, timezone).format('MMMM D, dddd h:mm A, ') + GetTimeZoneById(timezone)
    );
  }

  render() {
    const { className, webinar } = this.props;
    if (!webinar) return null;
    return (
      <div className={className}>
        <p className="caption">Webinar Name</p>
        <p className="value">{webinar.title}</p>
        <p className="caption">Date and time</p>
        <p className="value">{this.getFormattedStartsAt()}</p>
        <p className="caption">Name of presenter</p>
        <p className="value">{webinar.presenter.fullName}</p>
      </div>
    );
  }
}

export default WebinarSummary;
