import api from './api';

export type EligibilityConfig = {
  type: string,
  name: string,
  label: string,
};

export type EligibilityCheckResults = {
  eligibility: false,
  eligibility_token: string,
};

export type PartnerEligibilityInfo = {
  id: number,
  name: string,
  restricted_workflow: boolean,
  price_per_webinar_package: number,
  eligibility_required: boolean,
};

export default class EligibilityApi {
  getEligibilityConfig(partnerName: string): Promise<Array<EligibilityConfig>> {
    return api.get(`/eligibility/${partnerName}/config`);
  }

  checkEligibility(partnerName: string, params: Object): Promise<EligibilityCheckResults> {
    return api.post(`/eligibility/${partnerName}/check`, params);
  }

  getPartnerInfo(partnerName: string): Promise<PartnerEligibilityInfo> {
    return api.get(`/partners/${partnerName}`);
  }
}
