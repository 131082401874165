const warn = (values) => {
  const password = values.password || '';
  const res = {
    password: {
      length8: password.length < 8,
      number: !password.match(/\d/),
      specialCharacter: !password.match(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/),
      capitalizedLetter: !password.match(/[A-Z]/),
      lowercaseLetter: !password.match(/[a-z]/)
    }
  };
  //console.log('RES', res);
  return res;
};

export default warn;
