import React, { Component } from 'react';
import { switchAffiliation } from 'ducks/user';
import { connect } from 'react-redux';
import _ from 'lodash';
import { showLoader, closeLoader } from 'ducks/ui';

class SwitchAffiliation extends Component {
  componentDidMount() {
    const { affiliationIsNovartisGalleri } = this.props;
    this.props.showLoader();
    setTimeout(() => {
      this.props
        .switchAffiliation(affiliationIsNovartisGalleri)
        .then(() => {
          this.props.closeLoader();
          window.location = '/patient/switchaffiliation';
        })
        .catch(() => {
          this.props.closeLoader();
          window.location = `/patient/home`;
        });
    }, 500);
  }

  render() {
    return <div style={{ minHeight: '100vh' }} />;
  }
}

const mapStateToProps = state => ({
  appointment: state.appointment.data,
  affiliationIsNovartisGalleri: _.get(state, ['user', 'me', 'affiliationIsNovartisGalleri'], false),
});

const mergeProps = (stateProps, dispatchProps) => {
  const { affiliationIsNovartisGalleri } = stateProps;
  const { dispatch } = dispatchProps;
  return {
    dispatch,
    switchAffiliation: data => dispatch(switchAffiliation(data)),
    showLoader: () => dispatch(showLoader()),
    closeLoader: () => dispatch(closeLoader()),
    affiliationIsNovartisGalleri,
  };
};

export default connect(mapStateToProps, null, mergeProps)(SwitchAffiliation);
