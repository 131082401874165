// @flow
import api from './api';
import { hydrateRaces, hydrateEthnicities,hydrateCountriesState } from './transformers/enumsTransformers';

class EnumsServices {
  getRaces() {
    return api.get('/enums/races').then(hydrateRaces);
  }

  getEthnicities() {
    return api.get('/enums/ethnicities').then(hydrateEthnicities);
  }

  getCountryState(partnerUUID) {
    return api.get('/country-state?partner_uuid='+partnerUUID).then(hydrateCountriesState)
  }
}

export default new EnumsServices();
