import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import appointmentsService from 'services/api/appointmentsService';
import { setModalMode } from 'ducks/modals';
import { isAppointmentFromPackage } from 'containers/layouts/LayoutProps';
import { APPOINTMENT_TYPE_PHONE, APPOINTMENT_TYPE_VIDEO } from 'constants/CommonConstants';
import AppointmentInfoForMobile from './AppointmentInfoForMobile';
import VideoInstruction from './VideoInstruction';

class AppointmentContainerForMobile extends Component {
  state = {
    imageLoadingFailed: false,
    videoURL: '',
  };

  componentDidUpdate() {}

  componenDidMount() {}

  render() {
    const {
      user,
      appointment,
      appointmentType,
      gotoAppointmentInfoForMobile,
      gotoVideoInstructionForMobile,
      showAppointmentInfoForMobile,
      showVideoInstructionForMobile,
      openPhoneAppointmentModal,
      openVideoAppointmentModal,
      onCancel,
      canSwitchToPhone,
      canSwitchToVideo,
      isChangeable,
      onReschedule,
      toogleModal,
      videoModal,
    } = this.props;

    const upcoming = !_.isEmpty(appointment) && appointment.data;
    const isLoading = _.isEmpty(upcoming) || _.isEmpty(_.get(this.props, 'user.me', {}));

    if (isLoading) return null;

    return (
      <div className="scp-appointment-info-with-yellow-hint">
        {showAppointmentInfoForMobile && (
          <AppointmentInfoForMobile
            user={user}
            appointment={_.get(appointment, 'data', {})}
            appointmentType={appointmentType}
            gotoVideoInstruction={gotoVideoInstructionForMobile}
            canSwitchToPhone={canSwitchToPhone}
            canSwitchToVideo={canSwitchToVideo}
            openPhoneAppointmentModal={openPhoneAppointmentModal}
            openVideoAppointmentModal={openVideoAppointmentModal}
            onCancel={onCancel}
            isChangeable={isChangeable}
            onReschedule={onReschedule}
          />
        )}
        {showVideoInstructionForMobile && (
          <VideoInstruction
            gotoAppointmentInfo={gotoAppointmentInfoForMobile}
            user={user}
            toogleModal={toogleModal}
            videoModal={videoModal}
          />
        )}
      </div>
    );
  }
}

AppointmentContainerForMobile.propTypes = {
  user: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  appointment: PropTypes.object.isRequired,
  redirectActions: PropTypes.object.isRequired,
  switchToPhone: PropTypes.object.isRequired,
  canSwitchToPhone: PropTypes.bool.isRequired,
  canSwitchToVideo: PropTypes.bool.isRequired,
  isChangeable: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  isPackage: PropTypes.bool,
  appointmentType: PropTypes.string,
  phone: PropTypes.string.isRequired,
  deletingError: PropTypes.string,
  gotoAppointmentInfoForMobile: PropTypes.func,
  gotoVideoInstructionForMobile: PropTypes.func,
  showAppointmentInfoForMobile: PropTypes.bool,
  showVideoInstructionForMobile: PropTypes.bool,
  openPhoneAppointmentModal: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
};

const getAppointmentWithFullPayment = appointment => {
  if (_.isEmpty(appointment)) return null;
  return {
    ...appointment,
    start: appointment.startTime,
    end: appointment.endTime,
    consultation: {
      ...appointment.consultation,
      price: appointment.payment ? appointment.payment.totalCharge : 0.0,
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const {
    redirectActions,
    switchToPhone,
    phone,
    isChangeable,
    canSwitchToPhone,
    canSwitchToVideo,
    appointment,
    user,
    modals,
    videoModal,
    appointmentType,
  } = stateProps;
  const {
    showAppointmentInfoForMobile,
    showVideoInstructionForMobile,
    gotoAppointmentInfoForMobile,
    gotoVideoInstructionForMobile,
    openPhoneAppointmentModal,
    openVideoAppointmentModal,
    onCancel,
    onReschedule,
  } = ownProps;

  return {
    toogleModal: (name, data) => dispatch(setModalMode(name, data)),
    showAppointmentInfoForMobile,
    showVideoInstructionForMobile,
    gotoAppointmentInfoForMobile,
    gotoVideoInstructionForMobile,
    openPhoneAppointmentModal,
    openVideoAppointmentModal,
    onCancel,
    onReschedule,
    redirectActions,
    switchToPhone,
    phone,
    isChangeable,
    canSwitchToPhone,
    canSwitchToVideo,
    appointment,
    modals,
    user,
    dispatch,
    videoModal,
    appointmentType,
  };
};

const mapStateToProps = state => {
  const { user, modals, switchToPhone, redirectActions, appointment } = state;
  return {
    user,
    modals,
    switchToPhone,
    redirectActions,
    deletingError: appointment.deletingError,
    appointment: {
      loading: appointment.loading,
      loaded: appointment.loaded,
      data: getAppointmentWithFullPayment(appointment.data),
    },
    canSwitchToPhone: appointmentsService.canSwitchToPhone(appointment.data),
    canSwitchToVideo: appointmentsService.canSwitchToVideo(appointment.data),
    isChangeable: appointmentsService.isChangeable(appointment.data),
    isPackage: isAppointmentFromPackage(state),
    appointmentType:
      appointment.loaded && !_.isEmpty(appointment.data)
        ? appointment.data.byPhone ? APPOINTMENT_TYPE_PHONE : APPOINTMENT_TYPE_VIDEO
        : null,
    phone: !_.isEmpty(user.me) ? user.me.phone || '' : '',
    videoModal: state.modals.videoModal,
  };
};

export default connect(mapStateToProps, null, mergeProps)(AppointmentContainerForMobile);
