import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import authService from 'services/api/authService';
import { grailQuesionnaire } from 'ducks/user';
import GrailQualificationQnAFrom from './GrailQualificationQnAFrom';
import './grailqualificationcontainer.scss';

class GrailQualificationContainer extends Component {
  onSubmit = value => {
    const { dispatch, _currentUser: { affiliation } } = this.props;
    const reqObj = {
      'Have you been diagnosed or treated for cancer within the past three years (this excludes basal or squamous cell carcinomas of the skin)?':
        value.q1,
      'Are you currently pregnant?': value.q2,
      'Have you ever tested positive for HIV?': value.q3,
      'Do you have a history of a solid organ transplant and are you currently taking immunosuppressive medications?':
        value.q4,
      'Do you have a family history of a first degree relative who has had cancer?': value.q5,
      'What is your current smoking status?': value.q6,
      'Height (ft)': _.toInteger(value.q7),
      'Height (in)': _.toInteger(value.q8),
      'Weight (lbs)': _.toInteger(value.q9),
    };
    return dispatch(grailQuesionnaire(reqObj, affiliation));
  };

  render() {
    const {_currentUser: { affiliationIsIllumina }}=this.props;
    return (
      <div className="scp-email-verification-soft-reg-container">
        <div className="scp-soft-rgstrtion-container">
          <div className="soft-rgstrtion-wrapper qualification-wrapper">
            <GrailQualificationQnAFrom
              initialValues={{
                q1: '',
                q2: '',
                q3: '',
                q4: '',
                q5: '',
                q6: '',
                q7: '',
                q8: '',
              }}
              formError={this.props.grailGalleriFailure}
              onSubmit={this.onSubmit}
              affiliationIsIllumina={affiliationIsIllumina}
            />
          </div>
        </div>
      </div>
    );
  }
}

GrailQualificationContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const _currentUser = authService.getCurrentUser(state);
    return {
      _currentUser,
      grailGalleriFailure: state.user.grailQuestionnaireFailure,
    };
  },
  dispatch => {
    return { dispatch };
  }
)(GrailQualificationContainer);
