import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'components/forms/controls/Select';
import stateArrowIcon from './state-select-arrow.png';

import { selectStateByUser, selectTimezoneByUser, selectSpecialityByUser, selectCountryByUser } from 'ducks/scheduling';
import Button from 'components/forms/controls/Button';
import './AppointmentServiceOptionsV2.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { GetStateWiseTimezone } from 'services/geo';

import ButtonV2 from 'components/forms/controls/ButtonV2';

import 'components/forms/controls/IconModalPopup.scss';
import { getCountriesOption } from '../../../../../services/utils';


class AppointmentServiceOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStateSelectActive: false,
      hadConsultation: false,
    };
  }

  componentWillUnmount() {
    this.geoLocationPromise && this.geoLocationPromise.cancel();
  }

  onCountryChanged(e) {
    this.props.dispatch(selectCountryByUser(e));
  }
  onStateChanged(e) {
    this.props.dispatch(selectStateByUser(e));
  }

  onStateFocus() {
    this.setState({ isStateSelectActive: true });
  }

  onStateBlur() {
    this.setState({ isStateSelectActive: false });
  }

  onTimezoneChanged(e) {
    this.props.dispatch(selectTimezoneByUser(e));
  }

  isTimeZoneSelectShaded() {
    return !this.props.scheduling.calendarState;
  }

  renderArrow() {
    return (
      <div className="state-arrow-container">
        <img src={stateArrowIcon} className="state-arrow" />
        <p className="state-arrow-text">
          Please select your state and time zone <br /> to schedule an appointment
        </p>
      </div>
    );
  }

  renderOptionsWithoutIcon = (props) => {
    return (
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <span style={{ float: 'left', width: '95%' }}>{props.display_name}</span>
      </div>
    );
  };

  getCountryStates = (country) => {
    const { CountriesStateEnums: { states } } = this.props;
    const selectedCountryStates = (states || []).filter(a => a.countryCode == country);
    return selectedCountryStates.map(state => {
      return {
        label: state.name,
        value: state.code,
        key: state.code,
      }
    })
  }
  renderCanadaLanguage = () =>{
    return   <div className="canada-language">
    <div className="canada-title"> Canadian Restriction</div>
    <div className="description">
      <p>Genome Medical provides genetic counseling services to patients in the U.S. and Canada. Due to licensing restrictions, our board-certified, U.S.-based physicians are unable to order genetic tests for patients who reside outside of the United States.
      </p>
      <br/>
      <p>
        Please continue this registration process to meet with one of our licensed genetic counselors who will be happy to help you understand the genetic testing process and possible tests that could be coordinated through your Canadian primary care provider, or to review any genetic test results you have already received.</p>
    </div>
  </div>
  }

  render() {
    const { scheduling, user, isMobile, setShowSpeciality,CountriesStateEnums: { states, countries } } = this.props;
    const { me } = user;
    const { partnerSpecialties } = me;
    const { calendarState, calendarTimezone,calendarCountry } = scheduling;
    const disabled = !calendarState || !calendarTimezone;
    const timezones = GetStateWiseTimezone(calendarState,calendarCountry);
    const disabledTimezone = timezones && timezones.length<=1;
    const isCalendarTimeZoneDisabled = this.isTimeZoneSelectShaded() || disabledTimezone;
    const allowedCountries = _.get(user, ['me', 'allowedCountries'], ['US']);
    const isSpecialtySelected = this.props.isSpecialtySelected && this.props.isSpecialtySelected == this.props.selected
    const countryIsNonUS = _.get(user,['me','countryIsNonUS'],false);

    return (
      <>
      <div className="scp-appointment v2">
        <div className="scp-appointment-service-options">
        {countryIsNonUS &&<div className="select-country">
            <p className="title">
            Country at Time of Visit
            </p>
            <div className={classnames('select-wrapper', 'input-field')}>
              <Select
                value={calendarCountry}
                placeholder={'Selecrt...'}
                options={getCountriesOption(countries, allowedCountries)||[]}
                onChange={this.onCountryChanged.bind(this)}
              />
            </div>
          </div>
  }

          <div className="select-state">
            <p className="title">
            {calendarCountry == "CA"?"Province at Time of Visit":"State at Time of Visit"}
            </p>
            <div className={classnames('select-wrapper', 'input-field')}>
              <Select
                value={calendarState}
                placeholder={'AL'}
                options={this.getCountryStates(calendarCountry)||[]}
                onChange={this.onStateChanged.bind(this)}
                typeahead={true}
                clearable={true}
              />
            </div>
          </div>

          <div className="select-timezone">
            <p className="title">Time Zone at Time of Visit</p>
            <div className={classnames('select-wrapper', 'input-field')}>
              <Select
                value={calendarTimezone}
                placeholder={'Select your timezone'}
                options={timezones || []}
                disabled={isCalendarTimeZoneDisabled}
                onChange={this.onTimezoneChanged.bind(this)}
              />
            </div>
          </div>
          <div className="select-speciality">
            <div className="title">Specialty</div>
            <div className={classnames('select-wrapper', 'input-field')}>
              {!this.props.selectedSpecialty ? (
                <ButtonV2 className="select-speciality-btn" onClick={() => setShowSpeciality(true)}>
                  Select a specialty
                </ButtonV2>
              ) : (
                <div className="input-icon-container">
                  <Select
                    value={this.props.selectedSpecialty}
                    placeholder={'Select...'}
                    options={partnerSpecialties.map((el) => {
                      return { ...el, label: el.display_name, value: el.name };
                    })}
                    renderOption={(el) => this.renderOptionsWithoutIcon(el)}
                    disabled={true}
                  />
                    <ButtonV2
                      className="select-speciality-btn edit"
                      onClick={() => this.props.setShowSpeciality(true)}
                    >
                      Edit Specialty
                    </ButtonV2>
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobile && calendarCountry == "CA" && this.renderCanadaLanguage()}
        {isMobile && <div className="btn-schedule-container">
          <Button
            type="submit"
            onClick={this.props.onFinalPurchaseClick}
            disabled={disabled || !isSpecialtySelected}
            className="btn-schedule-appointment"
          >
            {'Next'}
          </Button>
        </div>
  }
        <div className="service-help">
          Still unsure about which specialty to choose? Call{' '}
          <a href="tel:+18776880992">(877) 688-0992</a> to be scheduled with the right provider.
        </div>

      </div>
      {!isMobile && calendarCountry == "CA" && this.renderCanadaLanguage()}
    </>
    );
  }
}

const mapStateToProps = (state) => ({
  scheduling: state.scheduling,
  user: state.user,
  CountriesStateEnums:_.get(state,['enums','countryState','data'],{})

});

AppointmentServiceOptions.propTypes = {
  scheduling: PropTypes.object.isRequired,
  isArrowVisible: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default connect(mapStateToProps)(AppointmentServiceOptions);
