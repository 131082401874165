import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import ReasonForVisitSelect from './ReasonForVisitSelect';
import Button from 'components/forms/controls/Button';
import CommonField from 'components/forms/controls/CommonField';
import WizardStepForm from '../WizardStepForm/WizardStepForm';
import Alert from 'components/widgets/Alert/Alert';
import { required, phone } from 'services/validate';
import { reasonForVisitField } from 'services/api/schedulingService';
import { setRegistrationStep } from 'services/utils';
import { PHONE_MASK_BRACKETS } from 'constants/CommonConstants';

import './ClientInformationForm.scss';

class ClientInformationForm extends Component {
  static reasonForVisitRequired = value =>
    value && value.value !== 'other' ? undefined : 'Required';
  static validReasonForVisit = value =>
    /^(?=.*[A-Z0-9])[\w.,!"\'\/$ ]+$/i.test(value && value.label)
      ? undefined
      : 'This field can contain only letters, digits and punctuation marks.';

  componentDidMount() {
    setRegistrationStep('personal');
  }

  render() {
    const {
      handleSubmit,
      submitting,
      formError,
      initialValues,
      toPreviousStep,
      customDescription,
      showReasonForVisit,
      alwaysEnabled,
    } = this.props;

    return (
      <div className="registration-wizard">
        <div className="description-block">
          <h3>Your information</h3>
          <div className="description">
            {customDescription ||
              'We will only use this information to communicate with you about your appointment. ' +
                'We will never share it with 3rd party organizations or send you spam. All fields are required.'}
          </div>
        </div>
        <WizardStepForm
          className="client-info-form"
          onSubmit={handleSubmit}
          initialValues={initialValues}
          alwaysEnabled={alwaysEnabled}>
          <div className="client-info-fields">
            <Field
              name="firstName"
              type="text"
              component={CommonField}
              label="First name*"
              validate={[required('First name')]}
            />
            <Field
              name="lastName"
              type="text"
              component={CommonField}
              label="Last name*"
              validate={[required('Last name')]}
            />
            <Field
              name="phone"
              type="phone"
              component={CommonField}
              mask={PHONE_MASK_BRACKETS}
              placeholder="+1-XXX-XXX-XXXX"
              label="Phone*"
              validate={[required('Phone'), phone]}
            />
            {showReasonForVisit && (
              <Field
                name={reasonForVisitField}
                component={ReasonForVisitSelect}
                label="I'm interested in this Genome Medical service:"
                validate={[
                  ClientInformationForm.reasonForVisitRequired,
                  ClientInformationForm.validReasonForVisit,
                ]}
              />
            )}
            <Alert message={formError} />
          </div>
          <div className="buttons">
            {toPreviousStep && (
              <a className="button button-back" onClick={toPreviousStep}>
                Previous step
              </a>
            )}
            <Button className="button button-next" type="submit" disabled={submitting}>
              NEXT
            </Button>
          </div>
        </WizardStepForm>
      </div>
    );
  }
}

ClientInformationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  toPreviousStep: PropTypes.func,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  initialValues: PropTypes.object,
  customDescription: PropTypes.string,
  showReasonForVisit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  alwaysEnabled: PropTypes.bool,
};

export default reduxForm({
  form: 'client-info-form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  touchOnChange: true,
})(ClientInformationForm);
