import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ModalNames from 'constants/ModalNames';
import Button from 'components/forms/controls/ButtonV2';
import ConsultationVideo from 'components/widgets/SCP/TestInfoBox/ConsultationVideo';
import VideoModal from 'components/modals/SCP/VideoModal/VideoModal';
import './VideoInstruction.scss';

class VideoInstruction extends Component {
  state = {
    videoURL: '',
  };

  gotoAppointmentInfo = () => {
    this.props.gotoAppointmentInfo();
  };

  showPreTestConsultationVideo = () => {
    const { homeInfo } = _.get(this, 'props.user', {});
    const consultation = _.get(homeInfo, 'consultation', []);
    const consultationType = _.get(consultation, ['0', 'consultationType'], '');
    if (consultation.length == 1) {
      return ['testing_guidance'].includes(consultationType);
    } else {
      return consultation.find(el =>
        ['testing_guidance', 'results_review', 'follow_up'].includes(el.consultationType)
      );
    }
  };

  openModal = videoURL => {
    this.setState({ videoURL: videoURL }, () => {
      this.props.toogleModal(ModalNames.videoModal, { open: true });
    });
  };

  closeModal = () => {
    this.props.toogleModal(ModalNames.videoModal, { open: false });
  };

  render() {
    const { videoURL } = this.state;
    const { videoModal } = this.props;
    return (
      <div className="is-mobile video-instruction-container">
        <div className="appointment-help-container">
          <Button
            className="btn-back-appointment-info"
            type="text"
            onClick={this.gotoAppointmentInfo}>
            <i className="fa fa-chevron-left" aria-hidden="true" />
            Back
          </Button>
          <h3>Video Instruction</h3>
          <ul className="appointment-help">
            <li>
              <b className="title">Download Zoom</b>
              <p className="description">
                You can download the Zoom Desktop Client for &nbsp;<a
                  target="_blank"
                  href={'https://zoom.us/client/latest/zoomusInstaller.pkg'}>
                  Mac
                </a>, &nbsp;<a
                  target="_blank"
                  href={'https://zoom.us/client/latest/ZoomInstaller.exe'}>
                  Windows
                </a>, &nbsp;<a
                  target="_blank"
                  href={
                    'https://chrome.google.com/webstore/detail/zoom/hmbjbjdpkobdjplfobhljndfdfdipjhg?hl=en'
                  }>
                  ChromeOS
                </a>{' '}
                and &nbsp;<a target="_blank" href={'https://zoom.us/download?os=linux'}>
                  Linux
                </a>, as well as the Zoom Mobile App for &nbsp;<a
                  target="_blank"
                  href={'https://itunes.apple.com/us/app/id546505307'}>
                  iOS
                </a>{' '}
                and &nbsp;<a target="_blank" href={'https://zoom.us/client/latest/zoom.apk'}>
                  Android
                </a>.
              </p>
            </li>
            <li>
              <b className="title">Start a test meeting</b>
              <p className="description">
                You can{' '}
                <a target="_blank" href={'http://zoom.us/test'}>
                  join a test Zoom meeting
                </a>{' '}
                to familiarize yourself with the Zoom and test your microphone/speakers before
                joining a Zoom meeting.
              </p>
            </li>
            <li>
              <b className="title">Join consultation</b>
              <p className="description">
                When you are within 15 minutes of the appointment, a button will appear to launch
                the video.
              </p>
            </li>
          </ul>
        </div>
        <div className={this.showPreTestConsultationVideo() ? 'consultation-video-container' : ''}>
          {this.showPreTestConsultationVideo() && (
            <ConsultationVideo ConsultationType="pre" openModal={this.openModal} />
          )}
        </div>
        <VideoModal
          videoURL={videoURL}
          show={videoModal && videoModal.open}
          onCancel={this.closeModal}
        />
      </div>
    );
  }
}

VideoInstruction.propTypes = {
  ConsultationType: PropTypes.string,
  gotoAppointmentInfo: PropTypes.func.isRequired,
  videoModal: PropTypes.object,
};

export default VideoInstruction;
