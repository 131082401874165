import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import EventInfo from './EventInfo';
import AppointmentSummary from './AppointmentSummary';

class AppointmentInfo extends Component {
  static propTypes = {
    title: PropTypes.string,
    appointment: PropTypes.object.isRequired,
    children: PropTypes.node,
    hidePrice: PropTypes.bool,
    newPrice: PropTypes.number,
    className: PropTypes.string,
    isWebinar: PropTypes.bool,
    enums: PropTypes.object.isRequired
  };

  render() {
    const { title, appointment, children, newPrice, hidePrice, isWebinar, enums } = this.props;
    const className = classnames('appointment-info', this.props.className);
    return (
      <EventInfo
        className={className}
        title={title}
        date={appointment.startTime}
        photo={appointment.provider.photoBig}>
        <AppointmentSummary
          appointment={appointment}
          hidePrice={hidePrice}
          newPrice={newPrice}
          isWebinar={isWebinar}
          enums={enums}

        />
        {children}
      </EventInfo>
    );
  }
}

export default AppointmentInfo;
