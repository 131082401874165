import _ from 'lodash';

export const dehydrateAddresses = data => {
  return { data: data.map(d => dehydrateAddress(d)) };
};

export function dehydrateAddress(data) {
  return _.omitBy(
    {
      full_name: data.addressFullName,
      type: data.addressType,
      address_line1: data.addressLine1,
      address_line2: data.addressLine2,
      state: data.addressState,
      city: data.addressCity,
      zip: data.addressZipCode,
      country: data.addressCountry,
      user_addresses_id: data.addressId,
    },
    _.isUndefined
  );
}

export function hydrateAddress(data) {
  return {
    addressUUID: data.id,
    addressFullName: data.full_name,
    addressType: data.type,
    addressLine1: data.address_line1,
    addressLine2: data.address_line2,
    addressCity: data.city,
    addressState: data.state,
    addressZipCode: data.zip,
    addressId: data.user_addresses_id,
    addressCountry: data.country
  };
}

export const hydrateAddresses = data => data.map(d => hydrateAddress(d));

export function hydrateHomeAddressFields(values) {
  return _.omitBy(
    {
      addressFullName: values.fullName,
      addressType: values.addressType,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressState: values.addressState,
      addressCity: values.addressCity,
      addressZipCode: values.addressZipCode,
      addressId: values.addressId,
      addressCountry: values.addressCountry

    },
    _.isUndefined
  );
}

export function hydrateShippingAddressFields(values) {
  return _.omitBy(
    {
      addressFullName: values.fullName,
      addressType: values.addressType,
      addressLine1: values.shippingAddressLine1,
      addressLine2: values.shippingAddressLine2,
      addressState: values.shippingAddressState,
      addressCity: values.shippingAddressCity,
      addressZipCode: values.shippingAddressZipCode,
      addressId: values.addressId,
      addressCountry: values.shippingAddressCountry
    },
    _.isUndefined
  );
}

export function hydrateBillingAddressFields(values) {
  return _.omitBy(
    {
      addressFullName: values.fullName,
      addressType: values.addressType,
      addressLine1: values.billingAddressLine1,
      addressLine2: values.billingAddressLine2,
      addressState: values.state,
      addressCity: values.city,
      addressZipCode: values.billingAddressZipCode,
      addressId: values.addressId,
      addressCountry: values.billingAddressCountry

    },
    _.isUndefined
  );
}
