import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/lib/Modal';

import ModalCloseButton from './ModalCloseButton';
import './ModalDialog.scss';

class ModalDialog extends Component {
  static propTypes = {
    className: PropTypes.string,
    bsSize: PropTypes.string,
    show: PropTypes.bool.isRequired,
    children: PropTypes.element,
    onHide: PropTypes.func.isRequired,
    hideHeader: PropTypes.bool,
    modalHeader: PropTypes.string,
  };

  render() {
    const {
      className,
      bsSize,
      show,
      onHide,
      hideHeader,
      children,
      modalHeader,
      roleModal,
    } = this.props;

    return (
      <Modal
        className={className}
        bsSize={bsSize}
        bsClass="modal"
        role={roleModal || 'dialog'}
        show={show}
        onHide={onHide}>
        {!hideHeader && (
          <Modal.Header>
            {modalHeader}
            <ModalCloseButton onClose={onHide} />
          </Modal.Header>
        )}
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  }
}

export default ModalDialog;
