import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TestInfoBox.scss';
import './ConsultationVideo.scss';

const preTestConsultation = {
  image:
    'https://i.vimeocdn.com/video/954695758-41e98f5bf160c611c720cdcbb0463dd2925d1e5284319d846a95257533f6e1c9-d',
  video: 'https://player.vimeo.com/video/456320418',
  duration: '2:03',
  title: 'What to Expect from a Pre-Test Consultation',
  description:
    'We start by assessing your risk for genetic disease, and then we help guide you in making an informed choice about genetic testing.',
};

const postTestConsultation = {
  image:
    'https://i.vimeocdn.com/video/954692710-9ca8d9226d3ba8a752ee8787928641a7f2e1edaaad918927b0b9c7f996648bb2-d',
  video: 'https://player.vimeo.com/video/456320643',
  duration: '1:53',
  title: 'What to Expect from a Post-Test Consultation',
  description:
    'Learn how a genetic expert can help you get a clear understanding of your genetic test results and answer that nagging “Now what?” question you may have.',
};

class ConsultationVideo extends Component {
  render() {
    const { ConsultationType, openModal } = this.props;
    const data = ConsultationType == 'pre' ? preTestConsultation : postTestConsultation;

    return (
      <div className="scp-test-info-box consultation-video" onClick={() => openModal(data.video)}>
        <div className="c-video">
          <span className="c-video-pill">
            <span className="v-text">{'Video'}</span>
          </span>
          <span className="c-play">
            <i className="fa fa-play" />
          </span>
          <img src={data.image} alt="" className="c-image" />
          <span className="c-duration-pill">
            <span className="d-text">{data.duration}</span>
          </span>
        </div>
        <div className="c-body">
          <div className="c-title">{data.title}</div>
          <div className="c-description">{data.description}</div>
        </div>
      </div>
    );
  }
}

ConsultationVideo.propTypes = {
  ConsultationType: PropTypes.string,
  openModal: PropTypes.func,
};

export default ConsultationVideo;
