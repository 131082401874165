import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReferralFormFrame from 'components/referrals/ReferralFormFrame/ReferralFormFrame';
import ThankYouPage from 'components/referrals/ThankYouPage/ThankYouPage';
import { thankYouStep } from 'constants/ReferralsScheme';
import { referNewPatient } from 'ducks/referrals';

import './ReferralsPageIframe.scss';
import gaTrack, { GA_TR_REFERRING_PROVIDER_INFO_PAGE } from '../../../../services/gaTrack';

class ReferralIframe extends Component {
  componentDidMount() {
    gaTrack(GA_TR_REFERRING_PROVIDER_INFO_PAGE);
  }
  render() {
    return (
      <div className="referrals-page full-height referral-iframe">
        {this.props.referStage === thankYouStep ? (
          <ThankYouPage referNewPatient={this.props.referNewPatient} />
        ) : (
          <ReferralFormFrame className="referral-block" />
        )}
      </div>
    );
  }
}

ReferralIframe.propTypes = {
  referStage: PropTypes.string,
  referNewPatient: PropTypes.func,
};

export default connect(
  ({ referrals }) => ({
    referStage: referrals.referStage,
  }),
  dispatch => ({
    referNewPatient: () => dispatch(referNewPatient()),
  })
)(ReferralIframe);
