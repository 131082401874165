import React, { Fragment } from 'react';
import { Radio } from 'gm-element-react';
import classnames from 'classnames';
import _ from 'lodash';
import './RadioButtonField.scss';

const RadioButtonField = ({
  input,
  meta: { touched, error },
  extraclassName,
  disabled,
  options,
}) => {
  const iserror = touched && error && !_.isNil(error) && !_.isEmpty(error);
  const currentClassName = classnames(
    'radio-group-wrapper',
    iserror ? 'input-error-field' : 'input-field',
    extraclassName
  );
  return (
    <Fragment>
      <div className={currentClassName}>
        <Radio.Group {...input} disabled={disabled}>
          {(_.isArray(options) ? options : []).map(el => {
            return (
              <Radio.Button
                key={el.value}
                value={el.value}
                disabled={el.disabled}
                className={classnames(el.disabled ? 'pill-disabled' : '')}>
                {' '}
                {el.label}
              </Radio.Button>
            );
          })}
        </Radio.Group>
        <div className="input-error">{iserror ? error : ''}</div>
      </div>
    </Fragment>
  );
};

export default RadioButtonField;
