import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Footer from 'components/scp/footer/Footer';
import ReleaseNotice from 'components/widgets/SCP/ReleaseVersionNotice/ReleaseNotice';

import { REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';

export class PublicLayoutV2 extends Component {
  static propTypes = {
    children: PropTypes.element,
    redirectActions: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.object,
  };

  componentDidUpdate() {
    const { redirectActions: { actions } } = this.props;
    if (this.context.router.params.activationToken) return;
    if (actions[REDIRECT_AFTER_LOGIN] && !this.redirected) this.redirectAfterSignIn();
  }

  redirected = false;

  redirectAfterSignIn = () => {
    this.redirected = true;
    browserHistory.push({
      pathname: '/login',
      state: { query: this.context.router.location.query },
    });
  };

  render() {
    const { children } = this.props;
    return (
      <div className="main-container scp">
        <ReleaseNotice />
        {children}
        <Footer />
        <GlobalLoader />
      </div>
    );
  }
}

PublicLayoutV2.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(state => ({
  redirectActions: state.redirectActions,
}))(PublicLayoutV2);
