import React, { Component } from 'react';
import PropTypes from 'prop-types';

import exclamationIcon from '../../../../images/exclamation.svg';
import genomeLogo from '../../../../images/logo.svg';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import Link from 'components/widgets/Link/Link';
import './AppointmentCancel.scss';

export default class AppointmentCancel extends Component {
  render() {
    return (
      <div className="cancel-appointment-wrapper">
        <div className="cancel-appointment-container">
          <div className="cancel-appointment-header">
            <img className="cancel-appointment-logo" src={genomeLogo} alt="" />
          </div>
          <div className="cancel-appointment-body">
            <div className="cancel-appointment-icon">
              <img className="cancel-appointment-icon-image" src={exclamationIcon} alt="" />
            </div>
            <div className="cancel-appointment-title">Cancel My Appointment</div>
            <div className="cancel-appointment-desc">
              By clicking “Cancel My Appointment”, you are requesting that we cancel your upcoming
              appointment. By continuing, you will be taken to the home page of our portal.
            </div>
          </div>
          <div className="cancel-appointment-footer">
            <div className="cancel-appointment-confirm-button">
              <ButtonV2 className="btn-default" onClick={this.props.cancelAppointment}>
                <span>Cancel My Appointment</span>
              </ButtonV2>
            </div>
            <div className="cancel-appointment-back-button">
              <Link className="go-back" href="javascript:void(0)" onClick={this.props.cancel}>
                Go back
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AppointmentCancel.propTypes = {
  cancelAppointment: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
