import React from 'react';

const Linkedin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.92" height="15.705" viewBox="0 0 15.92 15.705">
    <g id="prefix__Group_22127" data-name="Group 22127" transform="translate(-1432.85 -1178.14)">
      <g id="prefix__Group_22126" data-name="Group 22126" transform="translate(1432.85 1178.14)">
        <path
          id="prefix__Path_21543"
          d="M1448.77 1187.882v5.962h-3.457v-5.563c0-1.4-.5-2.351-1.751-2.351a1.89 1.89 0 0 0-1.773 1.264 2.369 2.369 0 0 0-.115.843v5.807h-3.457s.047-9.422 0-10.4h3.457v1.473c-.007.012-.017.023-.023.034h.023v-.034a3.433 3.433 0 0 1 3.116-1.717c2.275 0 3.98 1.486 3.98 4.679zm-15.92 5.962h3.456v-10.4h-3.456zm0 0l3.559-13.925a1.78 1.78 0 1 1-1.78-1.78 1.78 1.78 0 0 1 1.78 1.781z"
          data-name="Path 21543"
          transform="translate(-1432.85 -1178.14)"
        />
      </g>
    </g>
  </svg>
);

export default Linkedin;
