const UPDATE_PROGRESS_PERCENTAGE = 'gm/zingtree/UPDATE_PROGRESS_PERCENTAGE';
const CLEAR_PROGRESS_PERCENTAGE = 'gm/zingtree/CLEAR_PROGRESS_PERCENTAGE';

const initialState = {
  progressPercentage: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PROGRESS_PERCENTAGE:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_PROGRESS_PERCENTAGE:
      return {
        ...state,
        progressPercentage: 0,
      };
    default:
      return state;
  }
}

export function updateProgressPercentage(progressPercentage) {
  return {
    type: UPDATE_PROGRESS_PERCENTAGE,
    payload: {
      progressPercentage,
    },
  };
}

export function clearProgressPercentage() {
  return {
    type: CLEAR_PROGRESS_PERCENTAGE,
  };
}
