import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, isEmpty, head } from 'lodash';
import classnames from 'classnames';
import schedulingService from 'services/api/schedulingService';
import _ from 'lodash';
import SelectedServiceDetail from '../scp/scheduling/AppointmentDetails/SelectedServiceDetail';

import './NewCalendar.scss';
import DaySelect from './DaySelect';

export default class NewCalendar extends Component {
  static propTypes = {
    startDate: PropTypes.isRequired,
    slots: PropTypes.array.isRequired,
    onDateChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    toPreviousStep: PropTypes.func.isRequired,
    isRescheduling: PropTypes.bool.isRequired,
    selectedDate: PropTypes.isRequired,
    availableSlots: PropTypes.object.isRequired,
    selectedSlot: PropTypes.object.isRequired,
    isDateSelected: PropTypes.bool.isRequired,
    updateCalendar: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (
      !get(this, ['props', 'isLoading'], false) &&
      get(this, ['props', 'isLoading'], false) !== get(prevProps, ['isLoading'], false)
    ) {
      const slots = this.getAvailableSlots();
        const dates = Object.keys(slots).sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        if (!isEmpty(dates)) this.updateSlots(moment(head(dates)));
    }
  }

  getAvailableSlots = () => {
    let propsSlots = get(this, ['props', 'slots'], []).filter(
      (slot) => !moment(slot.start).isSame(slot.end)
    );
    let slots = {};
    let date;
    for (const slot of propsSlots) {
      if (
        moment(slot.start).isBetween(
          this.props.startDate,
          moment(this.props.startDate).add(7,'days')
        ) &&
        moment(slot.start).isBetween(
          moment(slot.start).set({ hour: 6, minute: 59, second: 0, millisecond: 0 }),
          moment(slot.start).set({ hour: 21, minute: 0, second: 0, millisecond: 0 })
        )
      ) {
        date = moment(slot.start).format('YYYY-MM-DD');
        if (!slots[date]) slots[date] = [slot];
        else slots[date].push(slot);
      }
    }

    return slots;
  };

  filterDates = (date) => {
    const slots = this.getAvailableSlots();

    if (this.props.isLoading) return false;

      const dates = Object.keys(slots);
      const calendarDate = moment(date).format('YYYY-MM-DD');

      return dates.indexOf(calendarDate) !== -1;
  };

  createTimePeriodsSlot = (slots, date) => {
    const compareDate = date.clone();
    const timesOfDay = [];
    for (const slot of slots) {
      if (
        moment(slot.start).isBetween(
          moment(compareDate).set({ hour: 6, minute: 59, second: 0, millisecond: 0 }),
          moment(compareDate).set({ hour: 21, minute: 0, second: 0, millisecond: 0 })
        )
      )
      timesOfDay.push(slot);
    }
    return {
      timesOfDay: timesOfDay.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()),
    };
  };

  updateSlots = (date) => {
      const slots = this.getAvailableSlots();
      const currentDate = moment(date).format('YYYY-MM-DD');
      const availableSlots = this.createTimePeriodsSlot(slots[currentDate], date);
      this.props.updateCalendar({
        selectedDate: moment(date),
        availableSlots: availableSlots,
        selectedSlot: {},
        isDateSelected: true,
      });
  };

  renderSlots = () => {
    const timesOfDay = get(this, ['props', 'availableSlots', 'timesOfDay'], []);

    if (isEmpty(timesOfDay) && !this.props.isLoading) {
      return (
        <div className="slots-container no-slots">
          There are no available appointments on the date you selected. Please pick another date to
          view available slots.
        </div>
      );
    }

    return (
      <div className="slots-container">
        {!isEmpty(timesOfDay) && (
          <div>
            <div className="header">Choose a Time</div>
            <div className="slots">
              {timesOfDay.map((selectedSlot,i) => (
                <div
                  key={`${moment(selectedSlot.start).valueOf()}-${i}`}
                  className={classnames({
                    selected:
                      moment(selectedSlot.start).valueOf() ===
                      moment(this.props.selectedSlot.start).valueOf(),
                  })}
                  onClick={() => this.props.updateCalendar({ selectedSlot })}>
                  {moment(selectedSlot.start).format('h:mm A')}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  handleMonthChange = async (date) => {
    await this.props.clearCalendar();
    const startDate = schedulingService.getStartOfMonth(date);
    this.props.onDateChange(startDate);
  };

  handleNext = async (date) => {
    await this.props.clearCalendar();
    const startDate = moment(date).toDate();
    this.props.onDateChange(startDate);
  };

  handlePrevious = async (date) => {
    await this.props.clearCalendar();
    const startDate = moment(date).toDate()
    this.props.onDateChange(startDate);
  };

  goNext = () => {
    const { start, end, sid, onSlotClick } = this.props.selectedSlot;
    onSlotClick(sid, start, end);
  };

  render() {
    const {isMobile} =this.props;
    const slots =this.getAvailableSlots();
    return (
      <div className="new-calendar-container">
        <DaySelect
        startDate={this.props.startDate}
        selectedDate={this.props.selectedDate}
        updateSlots={this.updateSlots}
        handleNext={this.handleNext}
        handlePrevious={this.handlePrevious}
        slots={slots}
        />

        <div className="calendar-group">
          {this.renderSlots()}
        </div>
          <SelectedServiceDetail isMobile={isMobile} selectedSlot={this.props.selectedSlot} selectedDate={this.props.selectedDate}/>
        <div className="action-buttons">
          {this.props.toPreviousStep && (
            <button
              onClick={this.props.toPreviousStep}
              disabled={this.props.isLoading}
              className="btn-back">
              Back
            </button>
          )}
          <button
            onClick={this.goNext}
            disabled={this.props.isLoading || isEmpty(this.props.selectedSlot)}
            className="btn-next">
            {this.props.isRescheduling ? 'Confirm' : 'Next'}
          </button>
        </div>
      </div>
    );
  }
}
