import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, isEqual, isNil } from 'lodash';
import { browserHistory } from 'react-router';
import classnames from 'classnames';

import { loadAllAddress, createTROEncounter, me } from 'ducks/user';
import { resetCurrentSCPEncounter } from 'ducks/scpencounters';
import { createAddress, updateAddress } from 'ducks/registrationWizard';
import { showLoader } from 'ducks/ui';
import { getShippingAddress } from 'services/utils';
import Button from 'components/forms/controls/ButtonV2';
import { getStatesWithoutUSTerritories } from 'services/geo';
import ConsentForm from './ConsentForm';
import CustomCheckbox from 'components/forms/controls/CustomCheckbox/CustomCheckbox';

import './GrailGalleri.scss';
import GrailStatusEnums from '../../../../constants/GrailStatus';

const states = getStatesWithoutUSTerritories();
const APPLICABLE_FIELDS = {
  checkbox1: 'I have had a solid organ transplant',
  checkbox2: 'I have had a bone marrow transplant',
  checkbox3: 'I am currently pregnant',
  checkbox4: 'None of the above',
};
const APPLICABLE_FIELDS_KEYS = {
  checkbox1: 'Solid organ transplant',
  checkbox2: 'Bone marrow transplant',
  checkbox3: 'Currently pregnant',
  checkbox4: 'None of the above',
};

class GrailGalleri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
    };
  }

  componentDidMount() {
    const grailstatus = get(this, ['props', 'user', 'me', 'grailStatus'], false);
    if (isNil(grailstatus) || grailstatus != GrailStatusEnums.SHOW_CARD)
      browserHistory.push('/patient/home');

    if (isEmpty(get(this, ['props', 'user', 'allAddress'], []))) this.props.loadAllAddress();
  }

  toggleCheckbox = key => {
    if (key === 'checkbox4') {
      if (!this.state.checkbox4) {
        this.setState({
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
          checkbox4: true,
        });
      } else {
        this.setState({
          checkbox4: false,
        });
      }
    } else {
      this.setState({
        [key]: !this.state[key],
        checkbox4: false,
      });
    }
  };

  onSubmit = async address => {
    let {
      user: { allAddress, me: { grailGalleriLabUUID, grailGalleriTestUUID } },
      createTROEncounter,
      me,
      resetCurrentSCPEncounter,
      showLoader,
      dispatch,
    } = this.props;
    const { checkbox1, checkbox2, checkbox3, checkbox4 } = this.state;
    const troResult = {
      lab_uuid: grailGalleriLabUUID,
      test_uuid: grailGalleriTestUUID,
      fields: {},
    };
    const shippingAddress = { ...address };

    if (checkbox1) {
      troResult.fields[APPLICABLE_FIELDS_KEYS.checkbox1] = ['yes'];
    } else {
      troResult.fields[APPLICABLE_FIELDS_KEYS.checkbox1] = ['no'];
    }
    if (checkbox2) {
      troResult.fields[APPLICABLE_FIELDS_KEYS.checkbox2] = ['yes'];
    } else {
      troResult.fields[APPLICABLE_FIELDS_KEYS.checkbox2] = ['no'];
    }
    if (checkbox3) {
      troResult.fields[APPLICABLE_FIELDS_KEYS.checkbox3] = ['yes'];
    } else {
      troResult.fields[APPLICABLE_FIELDS_KEYS.checkbox3] = ['no'];
    }
    if (checkbox4) {
      troResult.fields[APPLICABLE_FIELDS_KEYS.checkbox4] = ['yes'];
    } else {
      troResult.fields[APPLICABLE_FIELDS_KEYS.checkbox4] = ['no'];
    }

    delete shippingAddress.consent_signature;
    delete shippingAddress.consented_at;

    let sameFound = false;

    allAddress = allAddress.map(address => {
      if (isEqual(shippingAddress.addressType, address.addressType)) {
        sameFound = true;
        address = { ...shippingAddress };
      }

      return address;
    });

    if (!sameFound) allAddress.push(shippingAddress);

    const addressUpdateFunction = shippingAddress.addressId ? updateAddress : createAddress;

    return addressUpdateFunction([...allAddress], dispatch).then(data => {
      showLoader();

      return createTROEncounter(troResult).then(() => {
        setTimeout(() => {
          resetCurrentSCPEncounter();
          me().then(res => {
            browserHistory.push('/patient/home');
            return res;
          });
        }, 500);
        return data;
      });
    });
  };

  render() {
    const { checkbox1, checkbox2, checkbox3, checkbox4 } = this.state;
    const isAnyCheckboxChecked = checkbox1 || checkbox2 || checkbox3 || checkbox4;

    return (
      <div className="grail-galleri-container">
        <div className="grail-galleri">
          <Button path="/patient/home">&lt; Back to Home Page</Button>
          <div className="header">
            <h1>GALLERI</h1>
            <p>Multi-cancer early detection test</p>
            <div className="content-title">
              Please complete the form below to request the Galleri test
            </div>
            <div className="content-text main-text">
              Once your form is submitted, a Genome Medical physician will review your information
              and determine if the test is right for you. If appropriate, your test will be ordered,
              this typically takes 1-2 business days. At that time, you will receive an email with
              details on next steps.
            </div>
          </div>
          <div className="fields-container">
            <div className="fields-title required">CHECK IF APPLICABLE</div>
            <div className="checkboxes">
              <CustomCheckbox
                className={classnames({
                  active: checkbox1,
                })}
                value={{
                  label: APPLICABLE_FIELDS.checkbox1,
                }}
                checked={this.state.checkbox1}
                handleOnClick={() => this.toggleCheckbox('checkbox1')}
              />
              <CustomCheckbox
                className={classnames({
                  active: checkbox2,
                })}
                value={{
                  label: APPLICABLE_FIELDS.checkbox2,
                }}
                checked={this.state.checkbox2}
                handleOnClick={() => this.toggleCheckbox('checkbox2')}
              />
              <CustomCheckbox
                className={classnames({
                  active: checkbox3,
                })}
                value={{
                  label: APPLICABLE_FIELDS.checkbox3,
                }}
                checked={this.state.checkbox3}
                handleOnClick={() => this.toggleCheckbox('checkbox3')}
              />
              <CustomCheckbox
                className={classnames({
                  active: checkbox4,
                })}
                value={{
                  label: APPLICABLE_FIELDS.checkbox4,
                }}
                checked={this.state.checkbox4}
                handleOnClick={() => this.toggleCheckbox('checkbox4')}
              />
            </div>
          </div>
          <ConsentForm
            states={states}
            onSubmit={this.onSubmit}
            isAnyCheckboxChecked={isAnyCheckboxChecked}
            formError={this.props.formError}
            initialValues={{
              addressCity: '',
              addressId: undefined,
              addressLine1: '',
              addressLine2: '',
              addressState: '',
              addressType: ['delivery'],
              addressZipCode: '',
              addressCountry:'US',
              ...getShippingAddress([...this.props.user.allAddress]),
              addressFullName: get(this, ['props', 'user', 'me', 'fullName'], ''),
            }}
          />
        </div>
      </div>
    );
  }
}

GrailGalleri.propTypes = {
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  loadAllAddress: PropTypes.func.isRequired,
  createTROEncounter: PropTypes.func.isRequired,
  me: PropTypes.func.isRequired,
  resetCurrentSCPEncounter: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  formError: PropTypes.isRequired,
};

export default connect(
  ({ user }) => ({
    user,
    formError: user.grailGalleriFailure,
  }),
  dispatch => ({
    dispatch,
    loadAllAddress: () => dispatch(loadAllAddress()),
    createTROEncounter: data => dispatch(createTROEncounter(data)),
    me: () => dispatch(me()),
    resetCurrentSCPEncounter: () => dispatch(resetCurrentSCPEncounter()),
    showLoader: () => dispatch(showLoader()),
  })
)(GrailGalleri);
