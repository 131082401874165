import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'components/modals/ModalDialog/ModalDialog';
import WebinarContactForm from './WebinarContactForm';

class ContactRequest extends Component {
  render() {
    const { show, onHide, onSubmit } = this.props;
    return (
      <Modal className="md850 decline-modal" show={show} onHide={onHide}>
        <div className="decline-body">
          <div className="decline-title">Tell us how we can help</div>
          <div className="decline-desc">
            We'll be in touch shortly after we receive your request.
          </div>
          <WebinarContactForm onCancel={onHide} onSubmit={onSubmit} />
        </div>
      </Modal>
    );
  }
}

ContactRequest.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ContactRequest;
