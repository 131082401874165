import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import CommonField from 'components/forms/controls/CommonField';
import ExpirationMonth from 'components/forms/controls/ExpirationMonth';
import ExpirationYear from 'components/forms/controls/ExpirationYear';
import StatePicker from 'components/forms/controls/StatePicker';
import Button from 'components/forms/controls/Button';
import { required, validZipCode } from 'services/validate';
import { card } from 'creditcards';
import track, { TR_PAYMENT_SUBMIT } from 'services/track';
import { cvcController, cardNameController } from 'services/utils';
import FilledCardBlock from './FilledCardBlock';
import { PAYMENTBYINSURANCE } from '../../../../constants/CommonConstants';
import CheckBox from '../../controls/CheckBox';
import outNetworkIcon from './out-of-network.svg';
import holdingAptmntIcon from './holding-appointment.svg';
export const PAYMENT_FORM_NAME = 'payment-information-form';

class PaymentForm extends Component {
  state = {
    submissionError: null,
    updatingCard: false,
    isBillingAsHomeAddress: false,
    initialState: {},
  };

  formatCardNumber = value => card.format(card.parse(value));

  onFormSubmit = async data => {
    try {
      this.setState({ submissionError: null });
      const isZeroPrice = this.props.newPrice === 0;
      if (!isZeroPrice && (_.isEmpty(this.props.initialValues) || this.state.updatingCard)) {
        await this.props.onSubmit(data);
      } else {
        await this.props.onSkip();
      }
    } catch (e) {
      if (e && e instanceof SubmissionError) {
        let error = e.message + '. ';
        if (e.errors) {
          const values = Object.values(e.errors);
          if (values && values[0]) {
            error = values[0];
            this.setState({ submissionError: error });
          }
        }
      } else throw e;
    }
  };

  updateCard = () => {
    this.setState({ updatingCard: true });
  };

  get insurancePrice() {
    const { insurance, companies } = this.props;
    const insuranceCompany = companies.find(c => c.id === insurance.insuranceCompanyId);

    return insuranceCompany ? insuranceCompany.price : '';
  }

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues) {
      this.setState({
        initialState: initialValues,
      });
    }
  }

  setBillingAsHomeAddress = (e, value) => {
    const { homeAddress, initialValues } = this.props;
    this.setState({
      isBillingAsHomeAddress: value,
    });
    if (homeAddress) {
      if (value) {
        this.props.initialize({
          ...this.state.initialState,
          billingAddressLine1: homeAddress.addressLine1,
          billingAddressLine2: homeAddress.addressLine2 || '',
          city: homeAddress.addressCity,
          state: homeAddress.addressState,
          billingAddressZipCode: homeAddress.addressZipCode,
        });
      } else {
        this.props.initialize({
          ...this.state.initialState,
          billingAddressLine1: initialValues && initialValues.billingAddressLine1,
          billingAddressLine2: (initialValues && initialValues.billingAddressLine2) || '',
          city: initialValues && initialValues.city,
          state: initialValues && initialValues.state,
          billingAddressZipCode: initialValues && initialValues.billingAddressZipCode,
        });
      }
    }
  };

  handleChange = (value, key) => {
    this.setState({ initialState: Object.assign({}, this.state.initialState, { [key]: value }) });
  };

  render() {
    const {
      submitting,
      initialValues,
      newPrice,
      handleSubmit,
      toPreviousStep,
      formError,
      isWebinar,
    } = this.props;

    const isCardFilled = !this.state.updatingCard && !submitting && !_.isEmpty(initialValues);
    const isZeroPrice =
      newPrice === 0 ||
      (this.props.insurance &&
        this.insurancePrice === 0 &&
        this.props.paymentby === PAYMENTBYINSURANCE);
    const showOutNetworkBlock =
      this.props.paymentby === PAYMENTBYINSURANCE && this.insurancePrice > 0;

    return (
      <div className="payment-info-block">
        {showOutNetworkBlock && (
          <section className="out-of-ntwrk-container">
            <span className="icon-out-network-wrapper">
              <img src={outNetworkIcon} />
            </span>
            <div className="out-of-ntwrk-txt-wrapper">
              <h4>Out-of-network benefits</h4>
              <p className="out-of-ntwrk-text">
                If you have out-of-network benefits with your insurance plan, you may be eligible to
                receive a full or partial refund of your $50 deposit, depending on the outcome of
                your claim. However, if your insurance denies the claim or applies it toward your
                deductible/coinsurance, your $50 deposit will be applied toward the balance and you
                will receive a statement from Genome Medical. You will never pay more than our $250
                self-pay rate for your genetic counseling session.
              </p>
            </div>
          </section>
        )}
        {!(isCardFilled || isZeroPrice) &&
          !isWebinar && (
            <section className="holding-aptmnt-container">
              <img src={holdingAptmntIcon} className="holding-aptmnt-wrapper" />
              <div className="holding-aptmnt-txt-wrapper">
                <h4>Holding your appointment</h4>
                <p className="holding-aptmnt-text">
                  To hold your appointment, please provide your credit card information. You will
                  NOT be charged until you have completed your appointment. We request your credit
                  or debit card issuer to place an authorization request or hold on your card for
                  $0.50. The primary reason for an authorization request or hold is to validate the
                  card you are using for payment. Please always check the amount of availability on
                  your card in advance of paying by credit or debit card.
                </p>
              </div>
            </section>
          )}
        {!isZeroPrice &&
          !isCardFilled && (
            <div className="description-block">
              <h3>Credit or debit card</h3>
            </div>
          )}
        {isCardFilled &&
          !isZeroPrice && (
            <FilledCardBlock paymentMethod={initialValues} onUpdate={this.updateCard} />
          )}
        <form
          className="payment-personal-form"
          onSubmit={handleSubmit(this.onFormSubmit) || track(TR_PAYMENT_SUBMIT)}>
          {!(isCardFilled || isZeroPrice) && (
            <div className="form-body">
              <div className="card-section">
                <div className="card-block card-block-min-with">
                  <img
                    className="cards-img"
                    src={require('./icon-payment.png')}
                    srcSet={`${require('./icon-payment.png')} 1x, ${require('./icon-payment@2x.png')}`}
                  />
                  <div className="card-fields">
                    <Field
                      className="cardNumberField"
                      name="cardNumber"
                      type="text"
                      component={CommonField}
                      label="Card number"
                      validate={[required('Card number')]}
                      normalize={this.formatCardNumber}
                      sensitive={true}
                      onChange={(e, value) => this.handleChange(value, 'cardNumber')}
                    />
                    <div className="line-fields">
                      <Field
                        className="cardholderName"
                        name="cardholderName"
                        type="text"
                        component={CommonField}
                        label="Name on card"
                        validate={[required('Name on card')]}
                        sensitive={true}
                        normalize={cardNameController}
                        onChange={(e, value) => this.handleChange(value, 'cardholderName')}
                      />
                      <Field
                        name="cardExpirationMonth"
                        type="text"
                        className="exp-month"
                        component={ExpirationMonth}
                        label="Expiration date"
                        validate={[required('Month', true)]}
                        onChange={(e, value) => this.handleChange(value, 'cardExpirationMonth')}
                      />
                      <Field
                        name="cardExpirationYear"
                        type="text"
                        className="exp-year"
                        component={ExpirationYear}
                        validate={[required('Year', true)]}
                        onChange={(e, value) => this.handleChange(value, 'cardExpirationYear')}
                      />
                    </div>
                    <div className="bottom-row">
                      <div className="card-error">{this.state.submissionError || formError}</div>
                      <Field
                        className="cvc"
                        name="cardCVC"
                        type="password"
                        component={CommonField}
                        label="Security code"
                        validate={[required('Security code')]}
                        sensitive={true}
                        normalize={cvcController}
                        onChange={(e, value) => this.handleChange(value, 'cardCVC')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="description-block">
                <h3>Billing address</h3>
              </div>
              <div className="billing-fields">
                <div className="billing-row">
                  <Field
                    name="billingAddressLine1"
                    type="text"
                    component={CommonField}
                    label="Street Address Line 1*"
                    validate={[required('Billing address')]}
                    disabled={this.state.isBillingAsHomeAddress}
                  />
                  <Field
                    name="billingAddressLine2"
                    type="text"
                    component={CommonField}
                    label="Street Address Line 2"
                    disabled={this.state.isBillingAsHomeAddress}
                  />
                </div>
                <div className="billing-row">
                  <Field
                    className="city-field"
                    name="city"
                    type="text"
                    component={CommonField}
                    label="City*"
                    validate={[required('City')]}
                    disabled={this.state.isBillingAsHomeAddress}
                  />
                  <Field
                    className="state-field"
                    name="state"
                    type="text"
                    component={StatePicker}
                    label="State*"
                    validate={[required('State')]}
                    disabled={this.state.isBillingAsHomeAddress}
                  />
                  <Field
                    name="billingAddressZipCode"
                    type="text"
                    className="zip-code"
                    component={CommonField}
                    label="Zip code*"
                    validate={[required('Zip code'), validZipCode('Zip code')]}
                    disabled={this.state.isBillingAsHomeAddress}
                  />
                </div>
              </div>
              <div className="billing-fields">
                <div className="billing-row home-address-row">
                  <Field
                    name="sameAsHomeAddress"
                    component={CheckBox}
                    label="Same as home address"
                    type="checkbox"
                    onChange={this.setBillingAsHomeAddress}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="buttons">
            {toPreviousStep && (
              <div className="outer-button button button-next" style={{ textAlign: 'left' }}>
                <a
                  className="button button-back"
                  onClick={toPreviousStep}
                  style={{ display: 'contents' }}>
                  PREVIOUS
                </a>
              </div>
            )}
            <Button className="button button-next" type="submit" disabled={submitting}>
              NEXT
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

PaymentForm.propTypes = {
  alwaysEnabled: PropTypes.bool,
  formError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  insurance: PropTypes.object,
  companies: PropTypes.array,
  isWebinar: PropTypes.bool,
  loading: PropTypes.bool,
  needRedirectToBilling: PropTypes.bool,
  newPrice: PropTypes.number,
  onSkip: PropTypes.func,
  submitting: PropTypes.bool,
  toPreviousStep: PropTypes.func.isRequired,
  paymentby: PropTypes.string,
};

export default reduxForm({
  form: PAYMENT_FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(PaymentForm);
