import PropTypes from 'prop-types';
import React from 'react';

import './CalendarDay.scss';

export default function CalendarDay({ className, date }) {
  if (!date) return null;
  const day = date.getDate();
  if (isNaN(day)) return null;
  return (
    <div className={className}>
      <div className="calendar-day">
        <div className="month">
          {
            [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ][date.getMonth()]
          }
        </div>
        <div className="day">{day}</div>
      </div>
    </div>
  );
}

CalendarDay.propTypes = {
  className: PropTypes.string,
  date: PropTypes.object.isRequired,
};
