import testRequestService from '../services/api/testRequestService';

const initialValues={
  register:{},
  lab_insurance:{},
  address:[],
  payment:null,
  stage:undefined,
  formatedAOE:null
}


export function setTestRequestData(values) {
  const res = testRequestService.getTestRequestData();
  return testRequestService.saveTestRequestData({
    ...initialValues,
    ...res,
    ...values
  }); 
}

export function getTestRequestData() {
  const res = testRequestService.getTestRequestData();
  return {
    ...initialValues,
    ...res
  }
}

export function clearTestRequestData() {
  const res = testRequestService.clearTestRequestData();

}
