import axios from 'axios';
import { isFunction } from 'lodash';
import api from './api';

const CancelToken = axios.CancelToken;
let cancel;

export default class DocumentsAPI {
  getDocumentTypes(): Promise<Array<Object>> {
    return api.get('/documents/types');
  }

  getDocuments(): Promise<Array<Object>> {
    if (isFunction(cancel)) cancel();

    return api.get('/documents', {
      cancelToken: new CancelToken(c => (cancel = c)),
    });
  }

  uploadDocuments(formData: FormData, onUploadProgress: Function): Promise<any> {
    return api.postMultipartFormData('/documents', formData, onUploadProgress);
  }

  deleteDocument(documentId: number): Promise<any> {
    return api.delete(`/documents/${documentId}`);
  }

  downloadForms(): Promise<Array<Object>> {
    return api.get('/documents/download-forms');
  }

  submitauditLogs(params: Object): Promise<any> {
    return api.post('/documents/audit-logs', params);
  }

  downloadDocument(documentId: Number): Promise<any> {
    return api.getResponseTypeData(`/documents/${documentId}?action=download`, {
      responseType: 'blob',
    });
  }

  getDocumentLink(documentId: Number): Promise<any> {
    return api.getResponseTypeData(`/documents/${documentId}`, {
      responseType: 'arraybuffer',
    });
  }
}
