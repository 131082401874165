import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
export function GetStates() {
  return [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
    { value: 'AA', label: 'Armed Forces Americas' },
    { value: 'AE', label: 'Armed Forces Europe' },
    { value: 'AP', label: 'Armed Forces Pacific' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'GU', label: 'Guam' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'VI', label: 'Virgin Islands' },
  ];
}

const statesTimezones = {
  AL: ['America/Chicago'],
  AK: ['America/Anchorage','Pacific/Honolulu'],
  AZ: ['America/Phoenix', 'America/Los_Angeles'],
  AR: ['America/Chicago'],
  CA: ['America/Los_Angeles'],
  CO: ['America/Denver'],
  CT: ['America/New_York'],
  DE: ['America/New_York'],
  DC: ['America/New_York'],
  FL: ['America/New_York','America/Chicago'],
  GA: ['America/New_York'],
  HI: ['Pacific/Honolulu'],
  ID: [ 'America/Denver','America/Los_Angeles'],
  IL: ['America/Chicago'],
  IN: ['America/New_York','America/Chicago'],
  IA: ['America/Chicago'],
  KS: [ 'America/Chicago','America/Denver'],
  KY: ['America/New_York','America/Chicago'],
  LA: ['America/Chicago'],
  ME: ['America/New_York'],
  MD: ['America/New_York'],
  MA: ['America/New_York'],
  MI: ['America/New_York','America/Chicago'],
  MN: ['America/Chicago'],
  MS: ['America/Chicago'],
  MO: ['America/Chicago'],
  MT: ['America/Denver'],
  NE: ['America/Chicago','America/Denver',],
  NV: ['America/Los_Angeles', 'America/Denver'],
  NH: ['America/New_York'],
  NJ: ['America/New_York'],
  NM: ['America/Denver'],
  NY: ['America/New_York'],
  NC: ['America/New_York'],
  ND: ['America/Chicago','America/Denver',],
  OH: ['America/New_York'],
  OK: ['America/Chicago'],
  OR: ['America/Los_Angeles', 'America/Denver'],
  PA: ['America/New_York'],
  RI: ['America/New_York'],
  SC: ['America/New_York'],
  SD: ['America/Chicago','America/Denver'],
  TN: ['America/Chicago', 'America/New_York'],
  TX: [ 'America/Chicago','America/Denver'],
  UT: ['America/Denver'],
  VT: ['America/New_York'],
  VA: ['America/New_York'],
  WA: ['America/Los_Angeles'],
  WV: ['America/New_York'],
  WI: ['America/Chicago'],
  WY: ['America/Denver'],
  AS: ['Pacific/Pago_Pago'],
  GU: ['Pacific/Guam'],
  MP: ['Pacific/Guam'],
  PR: ['America/Puerto_Rico'],
  VI: ['America/Puerto_Rico'],

  // CANADA State Timezones
  AB: ['America/Denver'],
  BC: ['America/Denver', 'America/Los_Angeles'],
  MB: ['America/Chicago'],
  NB: ['America/Halifax'],
  NL: ['America/St_Johns', 'America/Halifax'],
  NS: ['America/Halifax'],
  NT: ['America/Denver'],
  NU: ['America/New_York', 'America/Chicago', 'America/Denver'],
  ON: ['America/New_York', 'America/Chicago'],
  PE: ['America/Halifax'],
  QC: ['America/Halifax', 'America/New_York'],
  SK: ['America/Chicago', 'America/Denver'],
  YT: ['America/Los_Angeles'],
};

const countryWiseTimezones ={
  US:[
    { value: 'Pacific/Pago_Pago', label: 'Samoa Standard Time' },
    { value: 'Pacific/Guam', label: 'Chamorro Time' },
    { value: 'Pacific/Honolulu', label: 'Hawaii Time' },
    { value: 'America/Anchorage', label: 'Alaska Time' },
    { value: 'America/Los_Angeles', label: 'Pacific Time' },
    { value: 'America/Denver', label: 'Mountain Time' },
    { value: 'America/Phoenix', label: 'Mountain Time - Arizona' },
    { value: 'America/Chicago', label: 'Central Time' },
    { value: 'America/New_York', label: 'Eastern Time' },
  ],
  'CA':[
    { value: 'America/Denver', label: 'Mountain Time' },
    { value: 'America/Los_Angeles', label: 'Pacific Time' },
    { value: 'America/Chicago', label: 'Central Time' },
    { value: 'America/Halifax', label: 'Atlantic Time' },
    { value: 'America/St_Johns', label: 'Newfoundland Time - St. Johns' },
    { value: 'America/New_York', label: 'Eastern Time' },
  ]
}

const timezones = {
  'America/Anchorage': 'Alaska Time',
  'America/Chicago': 'Central Time',
  'America/Denver': 'Mountain Time',
  'America/Los_Angeles': 'Pacific Time',
  'America/New_York': 'Eastern Time',
  'America/Phoenix': 'Mountain Time - Arizona',
  'America/Puerto_Rico': 'Atlantic Standard Time',
  'Pacific/Pago_Pago': 'Samoa Standard Time',
  'Pacific/Guam': 'Chamorro Time',
  'Pacific/Honolulu': 'Hawaii Time',
  'America/Halifax': 'Atlantic Time',
  'America/St_Johns': 'Newfoundland Time - St. Johns',
};

export function GetTimeZoneById(id) {
  return timezones[id] || id + ' time zone';
}

export function GetStateTimezone(state) {
  if (!state) return null;
  return _.get(statesTimezones,[state,0],'');
}
export function issMultiTimezoneState(state) {
  const states = [
    'AK',
    'AZ',
    'FL',
    'ID',
    'IN',
    'KS',
    'KY',
    'MI',
    'ND',
    'NE',
    'NV',
    'OR',
    'SD',
    'TX',
    'TN',
  ];
  return states.includes(state);
}

export function isTimezoneAvailableInState(state) {
  return !_.isNil(statesTimezones[state]);
}

export function GetStateWiseTimezone(state,country='US') {
  if (!state) return null;
  if (isTimezoneAvailableInState(state)) {
    const _timezones = statesTimezones[state];
    return _timezones.map(tz=>{
      return {
        value:tz,
        label:timezones[tz]
      }
    })
  }
  return countryWiseTimezones[country];
}

export function isDisabledTimezone(state) {
  return !issMultiTimezoneState(state) && isTimezoneAvailableInState(state);
}

export function LocateStateAndTimezone() {
  return new Promise(
    r => navigator && navigator.geolocation.getCurrentPosition(position => r(position))
  )
    .then(position => Promise.all([LocateState(position), LocateTimezone(position)]))
    .then(([state, timezone]) => ({ state, timezone }))
    .catch(error => Promise.reject(error));
}

function LocateState(position) {
  const apiKey = GetGoogleApiKey();
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position
      .coords.longitude}&key=${apiKey}`
  )
    .then(response => (response.status === 200 ? response.json() : Promise.reject()))
    .then(
      data =>
        new Promise((resolve, reject) => {
          let country, state;
          data.results.forEach(o => {
            o.address_components.forEach(a => {
              if (
                a.types.indexOf('country') >= 0 &&
                a.types.indexOf('political') >= 0 &&
                a.short_name
              )
                country = a.short_name;
              if (
                a.types.indexOf('administrative_area_level_1') >= 0 &&
                a.types.indexOf('political') >= 0 &&
                a.short_name
              )
                state = a.short_name;
              if (country && state && country.toUpperCase() === 'US') {
                resolve(state.toUpperCase());
                return;
              }
            });
          });
          reject();
        })
    );
}

export function GetGoogleApiKey() {
  return process.env.GOOGLE_API_KEY;
}

function LocateTimezone(position) {
  const apiKey = GetGoogleApiKey();
  const timeStamp = Math.round(new Date() / 1000);
  return fetch(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${position.coords
      .latitude},${position.coords.longitude}&timestamp=${timeStamp}&key=${apiKey}`
  )
    .then(response => (response.status === 200 ? response.json() : Promise.reject()))
    .then(
      data =>
        new Promise((resolve, reject) => {
          if (data && data.status === 'OK') resolve(data.timeZoneId);
          else reject();
        })
    );
}

export function getStatesWithoutUSTerritories() {
  const excludeStates = ['AS', 'GU', 'MP', 'PR', 'VI'];
  let states = GetStates();
  return states.filter(state => {
    return !_.includes(excludeStates, state.value);
  });
}
