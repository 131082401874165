export const GA_TR_VISIT_REGISTRATION_PAGE = 'GA_TR_VISIT_REGISTRATION_PAGE';
export const GA_TR_REFERRING_PROVIDER_INFO_PAGE = 'GA_TR_REFERRING_PROVIDER_INFO_PAGE';
export const GA_TR_PARTNER_ELGIBILITY_PAGE = 'GA_TR_PARTNER_ELGIBILITY_PAGE';
export const GA_TR_AFFILIATION_REGISTRATION_PAGE = 'GA_TR_AFFILIATION_REGISTRATION_PAGE';
export const GA_TR_CONSENT_PAGE = 'GA_TR_CONSENT_PAGE';
export const GA_TR_USER_FORGOT_PAGE = 'GA_TR_USER_FORGOT_PAGE';
export const GA_TR_USER_LOGIN_PAGE = 'GA_TR_USER_LOGIN_PAGE';
export const GA_TR_MY_GUIDE_PAGE = 'GA_TR_MY_GUIDE_PAGE';
export const GA_TR_USER_PROFILE_PAGE = 'GA_TR_USER_PROFILE_PAGE';
export const GA_TR_QUESTIONNRIE_PAGE = 'GA_TR_QUESTIONNRIE_PAGE';
export const GA_TR_PATIENT_SURVEY_PAGE = 'GA_TR_PATIENT_SURVEY_PAGE';
export const GA_TR_RESCHEDULE_APPOINTMENT_PAGE = 'GA_TR_RESCHEDULE_APPOINTMENT_PAGE';
export const GA_TR_USER_RESET_PAGE = 'GA_TR_USER_RESET_PAGE';
export const GA_TR_MY_HEALTH_PAGE = 'GA_TR_MY_HEALTH_PAGE';
export const GA_TR_SCHEDULING_HOME_ADDRESS_PAGE = 'GA_TR_SCHEDULING_HOME_ADDRESS_PAGE';
export const GA_TR_SCHEDULING_CALENDAR_PAGE = 'GA_TR_SCHEDULING_CALENDAR_PAGE';
export const GA_TR_SCHEDULING_CONFIRM_PAGE = 'GA_TR_SCHEDULING_CONFIRM_PAGE';
export const GA_TR_SCHEDULING_BILLING_PAGE = 'GA_TR_SCHEDULING_BILLING_PAGE';
export const GA_TR_SCHEDULING_PAYMENT_REVIEW_PAGE = 'GA_TR_SCHEDULING_PAYMENT_REVIEW_PAGE';
export const GA_TR_SCHEDULING_SHIPPING_ADDRESS_PAGE = 'GA_TR_SCHEDULING_SHIPPING_ADDRESS_PAGE';
export const GA_TR_SCHEDULING_INSURANCE_PAGE = 'GA_TR_SCHEDULING_INSURANCE_PAGE';
export const GA_TR_SCHEDULING_SELECT_SERVICE_PAGE = 'GA_TR_SCHEDULING_SELECT_SERVICE_PAGE';
export const GA_TR_USER_SET_PASSWORD_PAGE = 'GA_TR_USER_SET_PASSWORD_PAGE';
export const GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE = 'GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE';
export const GA_TR_APPOINTMENT_WAITING_ROOM_PAGE = 'GA_TR_APPOINTMENT_WAITING_ROOM_PAGE';
export const GA_TR_THANKYOU_PAGE = 'GA_TR_THANKYOU_PAGE';
export const GA_TR_ACCESS_YOUR_GENOME_PAGE = 'GA_TR_ACCESS_YOUR_GENOME_PAGE';
export const GA_TR_HOME_PAGE = 'GA_TR_HOME_PAGE';
export const GA_TR_PATIENT_EDUCATION_PAGE = 'GA_TR_PATIENT_EDUCATION_PAGE';
export const GA_TR_TESTREQUEST_ADDRESS_PAGE = 'GA_TR_TESTREQUEST_ADDRESS_PAGE';
export const GA_TR_TESTREQUEST_REVIEW_PAGE = 'GA_TR_TESTREQUEST_REVIEW_PAGE';
export const GA_TR_TESTORDER_PAGE = 'GA_TR_TESTORDER_PAGE';
export const GA_TR_WELCOME_PAGE = 'GA_TR_WELCOME_PAGE';
export const GA_TR_SCP_AFFILIATION_REGISTRATION_PAGE = 'GA_TR_SCP_AFFILIATION_REGISTRATION_PAGE';
export const GA_TR_NAVIGATOR_PAGE = 'GA_TR_NAVIGATOR_PAGE';
export const GA_TR_SOFTREGISTRATION_PAGE = 'GA_TR_SOFTREGISTRATION_PAGE';
export const GA_TR_SOFTREGISTRATION_ELIGIBILITY_PAGE = 'GA_TR_SOFTREGISTRATION_ELIGIBILITY_PAGE';
export const GA_TR_SOFTREGISTRATION_VERIFY_PAGE = 'GA_TR_SOFTREGISTRATION_VERIFY_PAGE';
export const GA_TR_EDUCATION_PAGE = 'GA_TR_EDUCATION_PAGE';
export const GA_TR_FULLREGISTRATION_PAGE = 'GA_TR_FULLREGISTRATION_PAGE';
export const GA_TR_SOFTLOGIN_PAGE = 'GA_TR_SOFTLOGIN_PAGE';
export const GA_TR_SOFTLOGIN_VERIFY_PAGE = 'GA_TR_SOFTLOGIN_VERIFY_PAGE';

export const GA_TR_ABN_PAGE = 'GA_TR_ABN_PAGE';
export const GA_TR_PRIVACY_PRACTICE_PAGE = 'GA_TR_PRIVACY_PRACTICE_PAGE';
export const GA_TR_FINANCIAL_RESPONSIBILITY_PAGE = 'GA_TR_FINANCIAL_RESPONSIBILITY_PAGE';
export const GA_TR_DOWNLOAD_PAGE = 'GA_TR_DOWNLOAD_PAGE';
export const GA_TR_REDIRECT_TO_PORTAL = 'GA_TR_REDIRECT_TO_PORTAL';

let gaObject = [];
gaObject[GA_TR_VISIT_REGISTRATION_PAGE] = { event: 'portal page', portalStep: '/register/account' };
gaObject[GA_TR_REFERRING_PROVIDER_INFO_PAGE] = { event: 'portal page', portalStep: '/referral' };
gaObject[GA_TR_PARTNER_ELGIBILITY_PAGE] = {
  event: 'portal page',
  portalStep: '/register/eligibility',
};
gaObject[GA_TR_AFFILIATION_REGISTRATION_PAGE] = {
  event: 'portal page',
  portalStep: '/registration/affiliation',
};
gaObject[GA_TR_CONSENT_PAGE] = { event: 'portal page', portalStep: '/consent' };
gaObject[GA_TR_USER_FORGOT_PAGE] = { event: 'portal page', portalStep: '/password/forgot' };
gaObject[GA_TR_USER_LOGIN_PAGE] = { event: 'portal page', portalStep: '/login' };

gaObject[GA_TR_MY_GUIDE_PAGE] = { event: 'portal page', portalStep: '/patient' };
gaObject[GA_TR_USER_PROFILE_PAGE] = { event: 'portal page', portalStep: '/patient/profile' };
gaObject[GA_TR_QUESTIONNRIE_PAGE] = { event: 'portal page', portalStep: '/patient/questionnaire' };
gaObject[GA_TR_PATIENT_SURVEY_PAGE] = { event: 'portal page', portalStep: '/patient/survey' };
gaObject[GA_TR_RESCHEDULE_APPOINTMENT_PAGE] = {
  event: 'portal page',
  portalStep: '/patient/reschedule',
};
gaObject[GA_TR_USER_RESET_PAGE] = { event: 'portal page', portalStep: '/password/reset' };
gaObject[GA_TR_MY_HEALTH_PAGE] = { event: 'portal page', portalStep: '/patient/results' };
gaObject[GA_TR_SCHEDULING_HOME_ADDRESS_PAGE] = {
  event: 'portal page',
  portalStep: '/scheduling/home-address',
};
gaObject[GA_TR_SCHEDULING_CALENDAR_PAGE] = {
  event: 'portal page',
  portalStep: '/scheduling/calendar',
};
gaObject[GA_TR_SCHEDULING_CONFIRM_PAGE] = {
  event: 'portal page',
  portalStep: '/scheduling/confirm',
};
gaObject[GA_TR_SCHEDULING_BILLING_PAGE] = {
  event: 'portal page',
  portalStep: '/scheduling/billing',
};
gaObject[GA_TR_SCHEDULING_INSURANCE_PAGE] = {
  event: 'portal page',
  portalStep: '/scheduling/insurance',
};
gaObject[GA_TR_SCHEDULING_SELECT_SERVICE_PAGE] = {
  event: 'portal page',
  portalStep: '/scheduling/select-service',
};
gaObject[GA_TR_USER_SET_PASSWORD_PAGE] = { event: 'portal page', portalStep: '/password/set' };
gaObject[GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE] = {
  event: 'portal page',
  portalStep: '/appointments/switch-to-phone',
};
gaObject[GA_TR_APPOINTMENT_WAITING_ROOM_PAGE] = {
  event: 'portal page',
  portalStep: '/appointments/waiting-room',
};
gaObject[GA_TR_THANKYOU_PAGE] = { event: 'portal page', portalStep: '/selection/thankyou' };
gaObject[GA_TR_ACCESS_YOUR_GENOME_PAGE] = {
  event: 'portal page',
  portalStep: '/access-your-genome-program',
};
gaObject[GA_TR_HOME_PAGE] = {
  event: 'portal page',
  portalStep: '/home',
};
gaObject[GA_TR_PATIENT_EDUCATION_PAGE] = { event: 'portal page', portalStep: '/patient/education' };
gaObject[GA_TR_TESTREQUEST_ADDRESS_PAGE] = {
  event: 'portal page',
  portalStep: '/patient/testrequest/address',
};
gaObject[GA_TR_TESTREQUEST_REVIEW_PAGE] = {
  event: 'portal page',
  portalStep: '/patient/testrequest/review',
};
gaObject[GA_TR_TESTORDER_PAGE] = { event: 'portal page', portalStep: '/patient/testorder' };
gaObject[GA_TR_WELCOME_PAGE] = { event: 'portal page', portalStep: '/welcome' };
gaObject[GA_TR_SCP_AFFILIATION_REGISTRATION_PAGE] = {
  event: 'portal page',
  portalStep: '/gcn/registration/affiliation',
};
gaObject[GA_TR_NAVIGATOR_PAGE] = { event: 'portal page', portalStep: '/gcn/navigator' };
gaObject[GA_TR_SOFTREGISTRATION_PAGE] = {
  event: 'portal page',
  portalStep: '/gcn/softregistration',
};
gaObject[GA_TR_SOFTREGISTRATION_ELIGIBILITY_PAGE] = {
  event: 'portal page',
  portalStep: '/gcn/softregistration/eligibility',
};
gaObject[GA_TR_SOFTREGISTRATION_VERIFY_PAGE] = {
  event: 'portal page',
  portalStep: '/gcn/softregistration/verify',
};
gaObject[GA_TR_EDUCATION_PAGE] = { event: 'portal page', portalStep: '/gcn/education' };
gaObject[GA_TR_FULLREGISTRATION_PAGE] = {
  event: 'portal page',
  portalStep: '/gcn/fullregistration',
};
gaObject[GA_TR_SOFTLOGIN_PAGE] = { event: 'portal page', portalStep: '/gcn/softlogin' };
gaObject[GA_TR_SOFTLOGIN_VERIFY_PAGE] = {
  event: 'portal page',
  portalStep: '/gcn/softlogin/verify',
};

gaObject[GA_TR_ABN_PAGE] = {
  event: 'portal page',
  portalStep: '/advance-beneficiary',
};
gaObject[GA_TR_PRIVACY_PRACTICE_PAGE] = {
  event: 'portal page',
  portalStep: '/privacy-practices',
};
gaObject[GA_TR_FINANCIAL_RESPONSIBILITY_PAGE] = {
  event: 'portal page',
  portalStep: '/financial-responsibility',
};
gaObject[GA_TR_DOWNLOAD_PAGE] = {
  event: 'portal page',
  portalStep: '/download',
};

gaObject[GA_TR_REDIRECT_TO_PORTAL] = {
  event: 'redirect to portal',
  location: document.referrer,
};

const gaTrack = eventName => {
  if (window && window.dataLayer && gaObject[eventName]) {
    window.dataLayer.push(gaObject[eventName]);
  }
};

export default gaTrack;
