import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { orderStatuses } from 'constants/TestOrderConstants';
import SuccessIcon from './success.svg';
import WarningIcon from './warning.svg';
import FailureIcon from './failure.svg';
import './TestInfoBox.scss';

class TestInfoBox extends Component {
  constructor(props) {
    super(props);
    this._status = '';
    this._dateDescription = '';
    this._isTROEncounter = false;
  }

  get warningStatus() {
    let {
      encounter: { createdAt, order },
    } = this.props;
    const status = _.get(order, 'status');
    const allowedStatus = [orderStatuses.awaitingApproval];

    if (allowedStatus.indexOf(status.toLowerCase()) !== -1) {
      return (
        <Fragment>
          <div className="status warning" data-hj-suppress>
            <img src={WarningIcon} alt="Warning" /> {this._status}
          </div>
          {status == orderStatuses.awaitingApproval && this._isTROEncounter && (
            <Fragment>
              <div className="date-description mgt32px">{this._dateDescription}</div>
              <div className="date-populated">
                {(moment(createdAt).isValid() && moment(createdAt).format('MMMM D, YYYY')) || ''}
              </div>
            </Fragment>
          )}
        </Fragment>
      );
    } else return null;
  }

  get successStatus() {
    const {
      encounter: {
        order: { dateTestAuthorized, dateTestOrdered },
      },
    } = this.props;
    let {
      encounter: { order },
    } = this.props;
    const status = _.get(order, 'status');
    const allowedStatus = [
      orderStatuses.approved,
      orderStatuses.sentToLab,
      orderStatuses.resultsReady,
    ];

    if (allowedStatus.indexOf(status.toLowerCase()) !== -1) {
      let orderDate = status === orderStatuses.approved ? dateTestAuthorized : dateTestOrdered;

      return (
        <Fragment>
          <div className="status success" data-hj-suppress>
            <img src={SuccessIcon} alt="Success" /> {this._status}
          </div>
          <div className="date-description">
            {status === orderStatuses.approved && <img src={SuccessIcon} alt="Success" />}{' '}
            {this._dateDescription}
          </div>
          <div className="date-populated">
            {(moment(orderDate).isValid() && moment(orderDate).format('MMMM D, YYYY')) || ''}
          </div>
        </Fragment>
      );
    } else return null;
  }

  get failureStatus() {
    let {
      encounter: { isTROEncounter, order },
    } = this.props;
    const status = _.get(order, 'status');
    const allowedStatus = [orderStatuses.cancelled];

    if (allowedStatus.indexOf(status.toLowerCase()) !== -1) {
      return (
        <Fragment>
          <div className="status failure" data-hj-suppress>
            <img src={FailureIcon} alt="Failure" /> {this._status}
          </div>
          <i>{isTROEncounter && orderStatuses.cancelled == status ? '' : this._dateDescription}</i>
        </Fragment>
      );
    } else return null;
  }

  get topHalf() {
    const {
      encounter: { testName, labName },
      geneticTestLab,
    } = this.props;
    let displayTestName = testName;
    if (_.get(geneticTestLab, ['patient_display_name']))
      displayTestName = _.get(geneticTestLab, ['patient_display_name'], testName);

    return (
      <Fragment>
        {this._status && this._dateDescription ? (
          <div className="top-half" data-hj-suppress>
            <div className="test-name">{displayTestName}</div>
            <div className="lab-name">{labName}</div>
          </div>
        ) : (
          <div className="no-orders">There are no requested tests</div>
        )}
      </Fragment>
    );
  }

  get bottomHalf() {
    return (
      <Fragment>
        {this._status && this._dateDescription && (
          <div className="bottom-half">
            <div className="status-text">Status</div>
            {this.warningStatus || this.successStatus || this.failureStatus}
            {!this._isTROEncounter && (
              <div className="footer">
                <Link to="/patient/testorder">View Details</Link>
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }

  get renderTestInfo() {
    if (
      _.isNil(this.props.encounter) ||
      _.get(this, ['props', 'encounter', 'isAppointmentSubType']) === true ||
      this.props.geneticTestLoading
    ) {
      return null;
    }
    let {
      encounter: { isTROEncounter, order },
    } = this.props;
    let status = _.get(order, 'status');
    status = status === null ? JSON.stringify(status) : status;
    const { className } = this.props;

    if (isTROEncounter && status == orderStatuses.rejected) {
      return null;
    }

    const mapper = {
      [orderStatuses.awaitingApproval]: {
        statusText: isTROEncounter ? 'Awaiting Review' : 'Awaiting Approval',
        dateDescription: 'Requested on',
      },
      [orderStatuses.approved]: {
        statusText: 'Approved',
        dateDescription: isTROEncounter ? 'Authorized on' : 'Approved Date',
      },
      [orderStatuses.sentToLab]: {
        statusText: 'Sent to Lab',
        dateDescription: isTROEncounter ? 'Lab order placed on' : 'Date Test Ordered',
      },
      [orderStatuses.resultsReady]: {
        statusText: 'Results Ready',
        dateDescription: isTROEncounter ? 'Test ordered on' : 'Date Test Ordered',
      },
      [orderStatuses.cancelled]: {
        statusText: 'Order Canceled',
        dateDescription: 'We will contact you for next steps',
      },
      [orderStatuses.null]: {
        statusText: null,
        dateDescription: null,
      },
    };

    if (status && mapper[status]) {
      const { statusText, dateDescription } = mapper[status];

      this._status = statusText;
      this._dateDescription = dateDescription;
      this._isTROEncounter = isTROEncounter;
      const isClickable = !this._isTROEncounter && this._status && this._dateDescription;
      return (
        <div className={classnames('scp-test-info-box', className)}>
          <div
            className="content"
            style={{ cursor: isClickable ? 'pointer' : 'auto' }}
            onClick={() => {
              if (isClickable) browserHistory.push('/patient/testorder');
            }}
          >
            <div className="header">Test Status</div>
            {this.topHalf}
            {this.bottomHalf}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { type } = this.props;
    return <Fragment>{type == 'test' && this.renderTestInfo}</Fragment>;
  }
}

TestInfoBox.propTypes = {
  className: PropTypes.string,
  encounter: PropTypes.object,
  type: PropTypes.string,
};

export default TestInfoBox;
