import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const Textarea = ({
  className,
  input,
  placeholder,
  label,
  rows,
  meta: { touched, error },
  disabled,
}) => (
  <div className={classnames('gm-form-field', className, { error: touched && error })}>
    <label className={classnames('gm-form-label')}>{(touched && error) || label}</label>
    <div className="gm-form-control">
      <textarea {...input} rows={rows} placeholder={placeholder} disabled={disabled} />
    </div>
  </div>
);

Textarea.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number.isRequired,
};

export default Textarea;
