import appointmentsService from 'services/api/appointmentsService';

const GET_CALENDAR_REQUEST = `gm/appointment/GET_CALENDAR_REQUEST`;
const GET_CALENDAR_SUCCESS = `gm/appointment/GET_CALENDAR_SUCCESS`;
const GET_CALENDAR_FAILURE = `gm/appointment/GET_CALENDAR_FAILURE`;

const calendars = ['google-calendar', 'outlook-calendar'];

const getInitialState = () => ({
  calendars: calendars.reduce((memo, calendar) => {
    memo[calendar] = {
      url: null,
      error: null,
    };
    return memo;
  }, {}),
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case GET_CALENDAR_REQUEST:
      return Object.assign({}, state, {
        calendars: Object.assign({}, state.calendars, {
          [action.payload.calendar]: { url: null, error: null },
        }),
      });
    case GET_CALENDAR_SUCCESS:
      return Object.assign({}, state, {
        calendars: Object.assign({}, state.calendars, {
          [action.payload.calendar]: { url: action.payload.url, error: null },
        }),
      });
    case GET_CALENDAR_FAILURE:
      return Object.assign({}, state, {
        calendars: Object.assign({}, state.calendars, {
          [action.payload.calendar]: { url: null, error: action.payload.error },
        }),
      });
    default:
      return state;
  }
};

export const getCalendarRequest = calendar => ({
  type: GET_CALENDAR_REQUEST,
  payload: { calendar },
});

export const getCalendarSuccess = (calendar, { url }) => ({
  type: GET_CALENDAR_SUCCESS,
  payload: { calendar, url },
});

export const getCalendarFailure = (calendar, error) => ({
  type: GET_CALENDAR_FAILURE,
  payload: { calendar, error },
});

export const getCalendars = () => (dispatch, getState) => {
  calendars.forEach(calendar => {
    dispatch(getCalendarRequest(calendar));
    const appointmentId = getState().appointment.data.id;
    appointmentsService
      .getCalendar(calendar, appointmentId)
      .then(({ url }) => dispatch(getCalendarSuccess(calendar, { url })))
      .catch(error => dispatch(getCalendarFailure(calendar, error)));
  });
};
