import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { browserHistory } from 'react-router';
import Button from 'components/forms/controls/ButtonV2';
import { connect } from 'react-redux';
import api from 'services/api/api';
import './SwitchAffiliation.scss';
import CheckBoxField from '../../../../components/forms/controls/V2/CheckBoxField';
import { Field, reduxForm } from 'redux-form';
import Alert from 'components/widgets/Alert/Alert';
import { showLoader, closeLoader } from 'ducks/ui';
import { updateConsent } from 'ducks/profile';
import StatusMessages from 'constants/StatusMessages';

class GrailWelcomePage extends Component {
  state = {
    message: null,
  };

  changeAffiliation = () => {
    browserHistory.push(`/patient/home`);
  };

  onSubmit = data => {
    const { dispatch, updateConsent } = this.props;
    const reqData = {
      consentForRecontactRegardingGalleri:
        data.consentForRecontactRegardingGalleri == true ? true : false,
    };
    this.onCloseAlert();
    dispatch(showLoader());
    return updateConsent(reqData)
      .then(res => {
        this.setState({ message: StatusMessages.updateConsent });
        dispatch(closeLoader());
        return res;
      })
      .catch(err => {
        dispatch(closeLoader());
        throw err;
      });
  };
  onCloseAlert = () => {
    this.setState({
      message: null,
    });
  };

  render() {
    const { handleSubmit } = this.props;
    if (!api.hasToken()) return null;
    return (
      <div className="scp-email-verification-soft-reg-container">
        <div className="scp-soft-rgstrtion-container">
          <div className="soft-rgstrtion-wrapper">
            <div className="switch-affili-container">
              <div>
                <div className="header">
                  <div className="genome-header-logo">
                    <img className="genome-header-img" src={require('images/galleri-logo.svg')} />
                  </div>
                </div>
                <div className="content-title">
                  <p> Thank you for requesting the </p>
                  <p> Galleri multi-cancer early detection test</p>
                </div>
                <div className="content-text">
                  <Alert
                    message={this.state.message}
                    fade={false}
                    type="success"
                    onClose={this.onCloseAlert}
                  />
                  <form onSubmit={handleSubmit(this.onSubmit)}>
                    <p>
                      <b>
                        At this time you <u>do not meet</u> clinical eligibility requirements for
                        the Galleri test
                      </b>, as determined by the Genome Medical clinical team. As a result, your
                      Genome Medical provider was unable to order the Galleri test for you. Clinical
                      eligibility may change in the future.{this.props
                        .affiliationIsNovartisGalleri && (
                        <span>
                          {' '}
                          If you are a legal guardian or power of attorney for someone else and you
                          are trying to order the Galleri test for that person, please call us at
                          <a className="cc-phone" href="tel:877-688-0992">
                            877-688-0992{' '}
                          </a>{' '}
                          to coordinate testing.
                        </span>
                      )}
                      <Field
                        name="consentForRecontactRegardingGalleri"
                        component={CheckBoxField}
                        type="checkbox"
                        onChange={this.consentForRecontactRegardingGalleri}
                        label={
                          <Fragment>
                            <span className="checkmark" />
                            <span className="label-section">
                              I would like to be notified when eligibility requirements are updated.
                            </span>
                          </Fragment>
                        }
                      />
                    </p>
                    <div className="save-btn-segment">
                      <Button type="submit" className="no-appointment">
                        Save
                      </Button>
                    </div>
                  </form>
                  {!this.props.affiliationIsNovartisGalleri && (
                    <p
                      style={{
                        border: '2px solid #d9d9d9',
                        borderRadius: 8,
                        padding: '13px 0 13px 13px',
                      }}>
                      Genome Medical offers other genetic services, not related to the Galleri test,
                      that can provide you with valuable information about your chance of developing
                      cancer. If you're interested in speaking with a genetic counselor to undergo a
                      cancer risk assessment, please click the link below or call us directly at{' '}
                      <a className="cc-phone" href="tel:877-688-0992">
                        877-688-0992
                      </a>.
                      <br />
                      <p style={{ marginTop: 15, marginBottom: 0 }}>
                        If you are a legal guardian or power of attorney for someone else and you
                        are trying to order the Galleri test for that person, please call us at the
                        number above to coordinate testing.
                      </p>
                    </p>
                  )}
                </div>
                {!this.props.affiliationIsNovartisGalleri && (
                  <div className="footer-btn-segment">
                    <Button onClick={() => this.changeAffiliation()} className="no-appointment">
                      SCHEDULE A GENETIC CONSULTATION
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GrailWelcomePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    initialValues: {
      consentForRecontactRegardingGalleri: state.user.me.consentForRecontactRegardingGalleri,
    },
    affiliationIsNovartisGalleri: state.user.me.affiliationIsNovartisGalleri,
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  const { dispatch } = dispatchProps;
  const { initialValues, affiliationIsNovartisGalleri } = stateProps;
  return {
    initialValues,
    dispatch,
    affiliationIsNovartisGalleri,
    updateConsent: data => dispatch(updateConsent(data)),
  };
};
const GrailWelcomeForm = reduxForm({
  form: 'grailconnect',
})(GrailWelcomePage);

export default connect(mapStateToProps, null, mergeProps)(GrailWelcomeForm);
