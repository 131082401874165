import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppointmentInfo from 'components/widgets/EventInfo/AppointmentInfo';

import './AppointmentInfoWithCancel.scss';

class AppointmentInfoWithCancel extends Component {
  render() {
    const { onCancel, onReschedule, isChangeable } = this.props;
    return (
      <AppointmentInfo {...this.props}>
        <div className="app-cancel-block">
          {isChangeable && (
            <div>
              <span onClick={onCancel} className="link">
                Cancel appointment
              </span>
              <span onClick={onReschedule} className="link reschedule-link">
                Reschedule
              </span>
            </div>
          )}
        </div>
      </AppointmentInfo>
    );
  }
}

AppointmentInfoWithCancel.propTypes = {
  isChangeable: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  title: PropTypes.string,
  appointment: PropTypes.object.isRequired,
  hidePrice: PropTypes.bool,
  newPrice: PropTypes.number,
  className: PropTypes.string,
};

export default AppointmentInfoWithCancel;
