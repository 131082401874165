import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import Link from 'components/widgets/Link/Link';
import MailtoLink from 'components/widgets/Link/MailtoLink';
import Button from 'components/forms/controls/Button';
import CommonField from 'components/forms/controls/CommonField';
import ButtonsGroup from 'components/forms/controls/ButtonsGroup';
import CheckboxGroup from 'components/forms/controls/CheckboxGroup';
import ReferralsScheme, {
  sectionProviderInfo,
  sectionReferralInfo,
  sectionPatientInfo,
  answers,
} from 'constants/ReferralsScheme';
import { required, referText } from 'services/validate';
import { getLabelsFromValue } from 'services/utils';

class ReferReferralInformation extends Component {
  static fieldsOrder = [
    'reasonForReferral',
    'clinicalArea',
    'previouslyHadGeneticTesting',
    'positiveResult',
  ];

  onSubmit = data =>
    this.props.onSubmit({
      data: {
        ...data,
        clinicalArea: getLabelsFromValue(data.clinicalArea, answers.clinicalArea),
      },
      scheme: ReferralsScheme[sectionReferralInfo],
      nextSection: sectionPatientInfo,
      order: ReferReferralInformation.fieldsOrder,
      metadata: {
        clinicalArea: data.clinicalArea,
      },
    });

  isPositiveResultVisible = () => {
    const { previouslyHadGeneticTesting: o } = this.props;
    return o && o.toLowerCase && o.toLowerCase() === 'yes';
  };

  goBack = () => {
    this.props.gotoStage(sectionProviderInfo);
  };

  render() {
    const { submitting, className, initialValues } = this.props;
    const { questions } = ReferralsScheme[sectionReferralInfo];

    return (
      <div className={className}>
        <h3 className="section-name">Information about your referral</h3>

        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="reasonForReferral"
            type="text"
            component={CommonField}
            label={questions.reasonForReferral + '*'}
            validate={[required(questions.reasonForReferral), referText]}
            initialValues={initialValues.reasonForReferral}
          />

          <Field
            name="clinicalArea"
            type="text"
            className="mt40"
            component={CheckboxGroup}
            label={questions.clinicalArea + '*'}
            items={answers.clinicalArea}
            initialValues={initialValues.clinicalArea}
            validate={[required(questions.clinicalArea, true), referText]}
          />

          <Field
            name="previouslyHadGeneticTesting"
            component={ButtonsGroup}
            label={questions.previouslyHadGeneticTesting}
            className="buttons-2"
            answersType="yn"
            initialValues={initialValues.previouslyHadGeneticTesting}
          />

          {this.isPositiveResultVisible() && (
            <div>
              <Field
                name="positiveResult"
                component={ButtonsGroup}
                label={questions.positiveResult}
                className="buttons-2"
                answersType="yn"
                initialValues={initialValues.positiveResult}
              />

              <p className="mb20 mt20">
                Please email the report to <MailtoLink />  or fax it to <Link>650-636-9220</Link>
              </p>

              <p>
                Please email or fax any additional relevant reports (imaging or laboratory) as well
                as clinical notes that could inform this patient's evaluation.
              </p>
            </div>
          )}
          <div className="buttons-block">
            <span className="back-button" onClick={this.goBack}>
              Back
            </span>
            <Button className="button-next" type="submit" disabled={submitting}>
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ReferReferralInformation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  previouslyHadGeneticTesting: PropTypes.string,
  initialValues: PropTypes.object,
  gotoStage: PropTypes.func,
};

const formName = 'refer-referral-information';
const selector = formValueSelector(formName);
export default compose(
  connect(state => ({
    previouslyHadGeneticTesting: selector(state, 'previouslyHadGeneticTesting'),
  })),
  reduxForm({
    form: formName,
    forceUnregisterOnUnmount: true,
  })
)(ReferReferralInformation);
