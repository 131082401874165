import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import webinarService from 'services/api/webinarService';
import authService from 'services/api/authService';
import eligibilityService from 'services/api/eligibilityService';
import {
  setReferralSource,
  clearReferralSource,
  setReferralPartnerName,
  setWarmReferralSource,
  setWarmReferralURL,
  clearWarmReferralSource,
  clearWarmReferralURL,
  setPartnerSoftRegisterOptioanlFlow,
  clearPartnerSoftRegisterOptioanlFlow,
} from 'services/misc/ReferralSource';
import fetchHOC from 'components/high-order-components/FetchHOC';
import _ from 'lodash';
import gaTrack, { GA_TR_SCP_AFFILIATION_REGISTRATION_PAGE } from 'services/gaTrack';

class RegistrationEntryPoint extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    partners: PropTypes.object.isRequired,
    location: PropTypes.object,
  };

  componentDidMount() {
    gaTrack(GA_TR_SCP_AFFILIATION_REGISTRATION_PAGE);
    const { params, location: { query } } = this.props;
    const partner = params.partner && params.partner.toLowerCase();

    this.clearSessionStorage();
    if (query && query.referral_url) {
      let warm_referral_src = query.referral_url
        .replace('http://', '')
        .replace('https://', '')
        .replace('www.', '')
        .split(/[/?#]/)[0];
      setWarmReferralURL(query.referral_url);
      setWarmReferralSource(warm_referral_src);
    }

    if (partner) {
      this.props.partners.fetch().then(() => {
        const { partners } = this.props;
        const partnersData = _.get(partners, 'data', []);
        let knownPartner = partnersData.find(
          a =>
            a.registration_url &&
            a.registration_url !== null &&
            a.registration_url.toLowerCase() === partner
        );
        if (_.isEmpty(knownPartner)) {
          // no idea what to do here.
          //TODO: maybe it makes sense to show some error
          this.redirectToLogin();
          return;
        } else if (
          knownPartner &&
          knownPartner.programType &&
          knownPartner.programType.toLowerCase() === 'scp'
        ) {
          if (knownPartner && knownPartner.display_name)
            setReferralSource(knownPartner.display_name);
          if (knownPartner && knownPartner.name) setReferralPartnerName(knownPartner.name);
          authService.saveAffiliationId(knownPartner.id);
          if (knownPartner.name === 'Illumina_scp') {
            eligibilityService.saveReferral(knownPartner.name);
          }
          if (knownPartner.softRegRequired === false) {
            setPartnerSoftRegisterOptioanlFlow(true);
          } else {
            clearPartnerSoftRegisterOptioanlFlow();
          }

          if (query.token) {
            authService.saveSCPSetPasswordToken(query.token);
          }
          this.redirectToNavigator();
        } else {
          browserHistory.replace(`/registration/${partner}`);
        }
      });
    } else {
      this.redirectToLogin();
    }
  }

  redirectToNavigator() {
    browserHistory.replace('/scp/navigator');
  }

  redirectToLogin() {
    browserHistory.replace('/');
  }

  clearSessionStorage() {
    clearReferralSource();
    authService.resetAffiliationId();
    clearWarmReferralSource();
    clearWarmReferralURL();
    clearPartnerSoftRegisterOptioanlFlow();
    authService.resetSCPSetPasswordToken();
  }

  render() {
    return <div />;
  }
}

export default connect()(
  fetchHOC({
    partners: () => webinarService.getPartners(),
  })(RegistrationEntryPoint)
);
