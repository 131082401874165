import geneticTestService from 'services/api/geneticTestService';

const LAB_TEST_START = 'gm/geneticTest/LAB_TEST_START';
const LAB_TEST_COMPLETE = 'gm/geneticTest/LAB_TEST_COMPLETE';
const LAB_TEST_FAILURE= 'gm/geneticTest/LAB_TEST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  data: [],
  error:null,

  aoeLoading:false,
  aoeLoaded:false,
  aoeData:{
  },
  aoeError:null,
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LAB_TEST_START:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case LAB_TEST_COMPLETE:
      return {
        ...state,
        loading: false,
        loaded:true,
        data:action.payload
      };

    case LAB_TEST_FAILURE:
      return {
        ...initialState,
        loading:false,
        loaded: false,
        data:action.payload
      };
     
    default:
      return state;
  }
}

export function setLabTestStart() {
  return { type: LAB_TEST_START };
}

export function setLabTestComplete(data) {
  return { type: LAB_TEST_COMPLETE, payload: data };
}

export function setLabTestFailure(message) {
  return { type: LAB_TEST_FAILURE, payload: message };
}


export const loadLabTest = (data) => {
  return (dispatch) => {
      dispatch(setLabTestStart());
      return geneticTestService.getLabTestInfo(data?.labName,data?.testName,data?.partnerID)
        .then((r) => {
          dispatch(setLabTestComplete(r));
          return r;
        })
        .catch((error) => {
          dispatch(setLabTestFailure(error));
          if (error.status !== 404) throw error;
        });
  };
};


