import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gmiLoader from '../../../../images/gmi-loader.gif';
import './WelcomeBack.scss';

export default class WelcomeBack extends Component {
  componentDidMount() {
    this.props
      .doMigrateSCP()
      .then(() => {
        window.location = `/patient/home`;
      })
      .catch(() => {
        window.location = `/patient/home`;
      });
  }

  render() {
    return (
      <div className="welcome-back-wrapper">
        <div className="welcome-back-container">
          <div className="welcome-back-body">
            <div className="welcome-back-title">Welcome back!</div>
            <div className="welcome-back-logo">
              <img src={gmiLoader} alt="" />
            </div>
            <div className="welcome-back-desc">
              <span className="welcome-back-loading">Loading…</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WelcomeBack.propTypes = {
  doMigrateSCP: PropTypes.func.isRequired,
};
