import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import Toolbar from './Toolbar';
import AppointmentOptionsV2 from '../../scp/scheduling/AppointmentDetails/AppointmentOptions/AppointmentOptionsV2';

class SCPPatientsToolbar extends Component {
  render() {
    return (
      <Fragment>
        <Toolbar
          left={[
            <AppointmentOptionsV2
              isArrowVisible={this.props.isArrowVisible}
              key={1}
              isRescheduling={this.props.isRescheduling}
              clearCalendar={this.props.clearCalendar}
            />,
          ]}
        />
      </Fragment>
    );
  }
}

SCPPatientsToolbar.propTypes = {
  isArrowVisible: PropTypes.bool.isRequired,
  flow: PropTypes.string,
  clearCalendar: PropTypes.func.isRequired,
};

export default SCPPatientsToolbar;
