import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import StatePickerSelect from '../StatePicker/StatePicker';

class StatePicker extends Component {
  handleChange = ({ value }) => {
    this.props.input.onChange(value);
  };

  render() {
    const { className, label, disabled, input: { value }, meta: { touched, error } } = this.props;
    return (
      <div className={classnames('gm-form-field', className, { error: touched && error })}>
        <label className="gm-form-label">{(touched && error) || label}</label>
        <div className="gm-form-control">
          <StatePickerSelect value={value} onChange={this.handleChange} disabled={disabled} />
        </div>
      </div>
    );
  }
}

StatePicker.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StatePicker;
